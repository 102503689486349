import { 
    IonButton, 
    IonButtons, 
    IonCol, 
    IonHeader, IonIcon, 
    IonItem, 
    IonLabel, 
    IonMenuButton, 
    IonRow, 
    IonToolbar, 
    isPlatform 
} from "@ionic/react";
import { arrowBackCircle, arrowForwardCircle } from "ionicons/icons";
import ProfileChip from "../Chips/ProfileChip/ProfileChip";
import React, { useContext, useState } from "react";
import { OrganizationContext } from "../../context/OrganizationContext";
import logo from "./../../assets/img/brand/LogoWithTextBlock.png";
import {useHistory} from "react-router";
import { Event } from "../../models";
import { isWindows } from "../../utilities/platform/Platform";
import SwitchOrganization from "../Organization/SwitchOrganization";

interface _Props {
    isEventPage?: Boolean
    event?: Event
    setOrganizationHandler?: Function
    forwardBtn?: string
    forwardBtnPath?: string
}

const Header: React.FC<_Props> = ({isEventPage, event, setOrganizationHandler, forwardBtn, forwardBtnPath}) => {
    const history = useHistory();

    const [stack, setStack] = useState<string[]>(["/"]);

    const organization = useContext(OrganizationContext);
    let logoUrl = "";
    if (organization?.logos?.length) {
      logoUrl = organization.logos[0]?.logoURL ?? "";
    }
    // For event staff making edits to an event
    const goToEventPage = () => {
        if (event) {
            const path = `/index/staff/event/${event.id}`;
            history.replace(path);
        }
    }

    // For specified pages that include a forward button - example edit class results and go forward to next class
    const goForward = () => {
        if (forwardBtnPath) {
            const updatedStack = stack.concat([forwardBtnPath]);
            setStack(updatedStack);
            history.push(forwardBtnPath);
        }
    }

    return (
        <React.Fragment>
            {(!isWindows() && isPlatform("mobile")) ?
                <IonHeader id="mobile-auth-header">
                    <IonToolbar id="mobile-auth-toolbar" color="white">
                        <IonButtons slot="start" color="white">
                            <IonButton>
                                <img
                                    className="nav-img"
                                    alt="RingSide Pro"
                                    src={logoUrl.length > 0 ? logoUrl : logo}
                                />
                            </IonButton>
                        </IonButtons>
                        <IonButtons slot="end" color="white">
                            <IonRow>
                                <IonCol size="4">
                                    <SwitchOrganization setOrganizationHandler={setOrganizationHandler}/>
                                </IonCol>
                                <IonCol size="4">
                                    <ProfileChip />
                                </IonCol>
                                <IonCol size="4">
                                    <IonMenuButton color="primary" className="switch-org-icon" />
                                </IonCol>
                            </IonRow>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                :
                <IonHeader id="non-mobile-auth-header" >
                    <IonToolbar id="non-mobile-auth-toolbar" className="ion-no-padding">
                        <IonItem 
                            id="previous-page-btn"
                            button
                            onClick={() => {
                                if (isEventPage) goToEventPage(); 
                                else {
                                    history.goBack();
                                }
                            }}
                            slot="start" 
                            color="tertiary" 
                            lines="none"
                        >
                            <IonIcon icon={arrowBackCircle} color="light" slot="start" />
                            <IonLabel className="text-capitalize description">{isEventPage ? "Back to Event Page" : "Previous Page"}</IonLabel>
                        </IonItem>
                        {(forwardBtn && forwardBtnPath) && 
                            <IonItem 
                                id="forward-btn"
                                button
                                onClick={() => {
                                    goForward();
                                }}
                                slot="end" 
                                color="primary"
                                lines="none"
                            >
                                <IonIcon icon={arrowForwardCircle} color="light" slot="start" />
                                <IonLabel className="text-capitalize description">{forwardBtn}</IonLabel>
                            </IonItem>
                        }
                    </IonToolbar>
                </IonHeader>
            }
        </React.Fragment>
    );
};

export default Header;
