import { BarnMember, UpdateOwnerInput, UpdateRiderInput, UpdateTrainerInput } from "../../API";
import { BarnPerson } from "../../interfaces/Person";
import { Result } from "../../interfaces/Result";
import { Owner, Person, Rider, Trainer } from "../../models";
import { deleteBarnMember, getBarnMemberByBarnIdPersonId } from "../barnMember/BarnMember";
import { deleteOwner, getOwnersByBarnId, getOwnersByCreatedBy, updateOwner } from "../owner/Owner";
import { getPersonByPersonId } from "../person/Person";
import { deleteRider, getRidersByBarnId, getRidersByCreatedBy, updateRider } from "../rider/Rider";
import { deleteTrainer, getTrainersByBarnId, getTrainersByCreatedBy, updateTrainer } from "../trainer/Trainer";

async function getBarnRiders(barnId: string) {
    let riders: Rider[] = [];

    const queryResult = await getRidersByBarnId(barnId);
    if (queryResult.isSuccess) {
        riders = riders.concat(queryResult.result);
    }

    return riders
}

async function getRiders(barnId: string, userId: string) {
    let riders: Rider[] = [];

    const queryResult = await getRidersByBarnId(barnId);
    if (queryResult.isSuccess) {
        riders = riders.concat(queryResult.result);
    }

    const queryResultByPerson = await getRidersByCreatedBy(userId);
    if (queryResultByPerson.isSuccess) {
        const riderArray = queryResultByPerson.result;
        riderArray.forEach((rider: Rider) => {
            const index = riders.findIndex(r => r.id === rider.id);
            if (index === -1) riders.push(rider);
        });
    }
    return riders
}

async function getBarnOwners(barnId: string) {
    let owners: Owner[] = [];

    const queryResult = await getOwnersByBarnId(barnId);
    if (queryResult.isSuccess) {
        owners = owners.concat(queryResult.result);
    }

    return owners;
}


async function getOwners(barnId: string, userId: string) {
    let owners: Owner[] = [];

    const queryResult = await getOwnersByBarnId(barnId);
    if (queryResult.isSuccess) {
        owners = owners.concat(queryResult.result);
    }

    const queryResultByPerson = await getOwnersByCreatedBy(userId);
    if (queryResultByPerson.isSuccess) {
        const ownerArray = queryResultByPerson.result;
        ownerArray.forEach((owner: Owner) => {
            const index = owners.findIndex(r => r.id === owner.id);
            if (index === -1) owners.push(owner);
        });
    }

    return owners;
}

async function getBarnTrainers(barnId: string) {
    let trainers: Trainer[] = [];

    const queryResult = await getTrainersByBarnId(barnId);
    if (queryResult.isSuccess) {
        trainers = trainers.concat(queryResult.result);
    }

    return trainers;
}

async function getTrainers(barnId: string, userId: string) {
    let trainers: Trainer[] = [];

    const queryResult = await getTrainersByBarnId(barnId);
    if (queryResult.isSuccess) {
        trainers = trainers.concat(queryResult.result);
    }

    const queryResultByPerson = await getTrainersByCreatedBy(userId);
    if (queryResultByPerson.isSuccess) {
        const trainerArray = queryResultByPerson.result;
        trainerArray.forEach((trainer: Trainer) => {
            const index = trainers.findIndex(r => r.id === trainer.id);
            if (index === -1) trainers.push(trainer);
        });
    }

    return trainers;
}

// async function getBarnMembers(barnId: string) {
//     let barnMembers: BarnMember[] = [];

//     const queryResult = await getBarnMembersByBarnId(barnId);
//     if (queryResult.isSuccess) {
//         barnMembers = barnMembers.concat(queryResult.result);
//     }

//     return barnMembers;
// }

export async function getStrictBarnPeople(barnId: string) {
    const riders = await getBarnRiders(barnId);
    const owners = await getBarnOwners(barnId);
    const trainers = await getBarnTrainers(barnId);

    const formattedPeople = await formatBarnPeople(riders, owners, trainers);
    return formattedPeople;
}

export async function getBarnPeople(barnId: string, userId: string) {
    const riders = await getRiders(barnId, userId);
    const owners = await getOwners(barnId, userId);
    const trainers = await getTrainers(barnId, userId);

    const formattedPeople = await formatBarnPeople(riders, owners, trainers);
    return formattedPeople;
}

export async function formatBarnPeople(riders?: Rider[], owners?: Owner[], trainers?: Trainer[]): Promise<BarnPerson[]> {
    let result: BarnPerson[] = [];

    let creatorMap = new Map();
    let barnPeopleMap = new Map();
    
    if (riders) {
        for (let i = 0; i < riders.length; i++) {
            const rider = riders[i];    
            // First, check to see if this creator has created other people / roles
            if (rider.createdBy) {
                const result: (BarnPerson[] | null | undefined) = barnPeopleMap.get(rider.createdBy);
                if (result) {
                    // If yes, check for someone with the same name and other roles                    
                    const index = result.findIndex(person => person.name === rider.name);
                    if (index > -1) {
                        // names are the same and creators are the same --> merge
                        const foundPerson = result[index];
                        const updatedPerson: BarnPerson = {
                            ...foundPerson,
                            riderId: rider.id,
                            rider: rider,
                            roles: foundPerson.roles + " rider"
                        };
                        let updatedArray = [
                            ...result.slice(0, index),
                            updatedPerson,
                            ...result.slice(index + 1)
                        ];
                        barnPeopleMap.set(rider.createdBy, updatedArray);
                    } else {
                        // need to add a new barn person to the creator list
                        const newBarnPerson: BarnPerson = {
                            name: rider.name,
                            createdBy: rider.createdBy,
                            creatorName: creatorMap.get(rider.createdBy) || "",
                            riderId: rider.id,
                            rider: rider,
                            location: rider.location,
                            roles: "rider",
                            personId: rider.personId
                        };
                        barnPeopleMap.set(rider.createdBy, result.concat([newBarnPerson]));
                    }
                } else {
                    // If no, start a new list of people created by this person
                    let name = "";
                    const queryResult = await getPersonByPersonId(rider.createdBy);
                    if (queryResult.isSuccess) {
                        const creator: Person = queryResult.result;
                        name = creator.firstName + " " + creator.lastName;
                        creatorMap.set(rider.createdBy, name);
                    }
                    const newBarnPerson: BarnPerson = {
                        name: rider.name,
                        createdBy: rider.createdBy,
                        creatorName: name,
                        riderId: rider.id,
                        rider: rider,
                        location: rider.location,
                        roles: "rider",
                        personId: rider.personId
                    };
                    barnPeopleMap.set(rider.createdBy, [newBarnPerson]);
                }
            } else {
                // Unknown creator
            }
        }
    }

    if (trainers) {
        for (let i = 0; i < trainers.length; i++) {
            const trainer = trainers[i];    
            // First, check to see if this creator has created other people / roles
            if (trainer.createdBy) {
                const result: (BarnPerson[] | null | undefined) = barnPeopleMap.get(trainer.createdBy);
                if (result) {
                    // If yes, check for someone with the same name and other roles                    
                    const index = result.findIndex(person => person.name === trainer.name);
                    if (index > -1) {
                        // names are the same and creators are the same --> merge
                        const foundPerson = result[index];
                        const updatedPerson: BarnPerson = {
                            ...foundPerson,
                            trainerId: trainer.id,
                            trainer: trainer,
                            roles: foundPerson.roles + " trainer"
                        };
                        let updatedArray = [
                            ...result.slice(0, index),
                            updatedPerson,
                            ...result.slice(index + 1)
                        ];
                        barnPeopleMap.set(trainer.createdBy, updatedArray);
                    } else {
                        // need to add a new barn person to the creator list
                        const newBarnPerson: BarnPerson = {
                            name: trainer.name,
                            createdBy: trainer.createdBy,
                            creatorName: creatorMap.get(trainer.createdBy) || "",
                            trainerId: trainer.id,
                            trainer: trainer,
                            location: trainer.location,
                            roles: "trainer",
                            personId: trainer.personId
                        };
                        barnPeopleMap.set(trainer.createdBy, result.concat([newBarnPerson]));
                    }
                } else {
                    // If no, start a new list of people created by this person
                    let name = "";
                    const queryResult = await getPersonByPersonId(trainer.createdBy);
                    if (queryResult.isSuccess) {
                        const creator: Person = queryResult.result;
                        name = creator.firstName + " " + creator.lastName;
                        creatorMap.set(trainer.createdBy, name);
                    }
                    const newBarnPerson: BarnPerson = {
                        name: trainer.name,
                        createdBy: trainer.createdBy,
                        creatorName: name,
                        trainerId: trainer.id,
                        trainer: trainer,
                        location: trainer.location,
                        roles: "trainer",
                        personId: trainer.personId
                    };
                    barnPeopleMap.set(trainer.createdBy, [newBarnPerson]);
                }
            } else {
                // Unknown creator
            }
        }
    }

    if (owners) {
        for (let i = 0; i < owners.length; i++) {
            const owner = owners[i];    
            // First, check to see if this creator has created other people / roles
            if (owner.createdBy) {
                const result: (BarnPerson[] | null | undefined) = barnPeopleMap.get(owner.createdBy);
                if (result) {
                    // If yes, check for someone with the same name and other roles                    
                    const index = result.findIndex(person => person.name === owner.name);
                    if (index > -1) {
                        // names are the same and creators are the same --> merge
                        const foundPerson = result[index];
                        const updatedPerson: BarnPerson = {
                            ...foundPerson,
                            ownerId: owner.id,
                            owner: owner,
                            roles: foundPerson.roles + " owner"
                        };
                        let updatedArray = [
                            ...result.slice(0, index),
                            updatedPerson,
                            ...result.slice(index + 1)
                        ];
                        barnPeopleMap.set(owner.createdBy, updatedArray);
                    } else {
                        // need to add a new barn person to the creator list
                        const newBarnPerson: BarnPerson = {
                            name: owner.name,
                            createdBy: owner.createdBy,
                            creatorName: creatorMap.get(owner.createdBy) || "",
                            ownerId: owner.id,
                            owner: owner,
                            roles: "owner",
                            location: owner.location,
                            personId: owner.personId
                        };
                        barnPeopleMap.set(owner.createdBy, result.concat([newBarnPerson]));
                    }
                } else {
                    // If no, start a new list of people created by this person
                    let name = "";
                    const queryResult = await getPersonByPersonId(owner.createdBy);
                    if (queryResult.isSuccess) {
                        const creator: Person = queryResult.result;
                        name = creator.firstName + " " + creator.lastName;
                        creatorMap.set(owner.createdBy, name);
                    }
                    const newBarnPerson: BarnPerson = {
                        name: owner.name,
                        createdBy: owner.createdBy,
                        creatorName: name,
                        ownerId: owner.id,
                        owner: owner,
                        roles: "owner",
                        location: owner.location,
                        personId: owner.personId
                    };
                    barnPeopleMap.set(owner.createdBy, [newBarnPerson]);
                }
            } else {
                // Unknown creator
            }
        }
    }

    barnPeopleMap.forEach(array => {
        if (array && array.length > 0) {
            for (let i = 0; i < array.length; i++) {
                const element = array[i];
                result.push(element);
            }
        }
    });

    const sorted = result.sort((a, b) => a.name.localeCompare(b.name));

    return sorted;
}

export async function removeBarnPerson(barnPerson: BarnPerson, barnId: string, userId: string) {
    let result: Result = {isSuccess: true, type: "BarnPerson", result: barnPerson, message: "Successfully removed the person from the barn."};
    if (barnPerson.riderId) {
        if (barnPerson.rider && barnPerson.rider.createdBy === userId) {
            const removeRiderResult = await deleteRider({id: barnPerson.riderId});
            if (!removeRiderResult.isSuccess) {
                result = {isSuccess: false, type: "BarnPerson", result: removeRiderResult, message: "Could not remove the person from the barn."};
                return result;
            }
        } else {
            const updateRiderInput: UpdateRiderInput = {
                id: barnPerson.riderId,
                barnId: "",
                barnName: "",
                // riderBarnId: ""
            };
            const updateRiderResult = await updateRider(updateRiderInput);
            if (!updateRiderResult.isSuccess) {
                result = {isSuccess: false, type: "BarnPerson", result: updateRiderResult, message: "Could not remove the person from the barn."};
                return result;
            }
        }
    }

    if (barnPerson.ownerId) {
        if (barnPerson.owner && barnPerson.owner.createdBy === userId) {
            const removeOwnerResult = await deleteOwner({id: barnPerson.ownerId});
            if (!removeOwnerResult.isSuccess) {
                result = {isSuccess: false, type: "BarnPerson", result: removeOwnerResult, message: "Could not remove the person from the barn."};
                return result;
            }
        } else {
            const updateOwnerInput: UpdateOwnerInput = {
                id: barnPerson.ownerId,
                barnId: ""
            };
            const updateOwnerResult = await updateOwner(updateOwnerInput);
            if (!updateOwnerResult.isSuccess) {
                result = {isSuccess: false, type: "BarnPerson", result: updateOwnerResult, message: "Could not remove the person from the barn."};
                return result;
            }
        }
    }

    if (barnPerson.trainerId) {
        if (barnPerson.trainer && barnPerson.trainer.createdBy === userId) {
            const removeTrainerResult = await deleteTrainer({id: barnPerson.trainerId});
            if (!removeTrainerResult.isSuccess) {
                result = {isSuccess: false, type: "BarnPerson", result: removeTrainerResult, message: "Could not remove the person from the barn."};
                return result;
            }
        } else {
            const updateTrainerInput: UpdateTrainerInput = {
                id: barnPerson.trainerId,
                barnId: "",
                barnName: "",
                // trainerBarnId: ""
            };
            const updateTrainerResult = await updateTrainer(updateTrainerInput);
            if (!updateTrainerResult.isSuccess) {
                result = {isSuccess: false, type: "BarnPerson", result: updateTrainerResult, message: "Could not remove the person from the barn."};
                return result;
            }
        }
    }

    if (barnPerson.personId) {
        const queryResult = await getBarnMemberByBarnIdPersonId(barnId, barnPerson.personId);
        if (queryResult.isSuccess) {
            const barnMember: BarnMember = queryResult.result;
            if (barnMember) {
                const deleteResult = await deleteBarnMember({id: barnMember.id});
                if (!deleteResult.isSuccess) {
                    result = {isSuccess: false, type: "BarnPerson", result: deleteBarnMember, message: "Could not remove the person from the barn."};
                    return result;
                }
            }
        }
    }

    return result;
}