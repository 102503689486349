import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import React, { useState } from "react";
import ReferralTypePoll from "../../../../components/Admin/DiscoverMethodPoll";
import { useHistory } from "react-router";
import AdminEventAuditorInfo from "../../../../components/EventAuditor/AdminEventAuditorInfo";
import FileBrowser from "../../../../components/FileBrowser/FileBrowser";
import { isCSV } from "../../../../utilities/files/File";
import { generateTwilioCostReport } from "../../../../utilities/reports/TwilioCostReports";
import Spinner from "../../../../components/Spinners/Spinner";
import ErrorAlert from "../../../../components/Errors/ErrorAlert";
import SuccessBanner from "../../../../components/Banners/SuccessBanner";

const AdminDashboardPage: React.FC = () => {
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const navigateToClinicsPage = () => {
        const path = "/index/admin/test-data/clinics";
        history.push(path);
    }

    const handleSelectedFile = async (file: File) => {
        try {
            setIsLoading(true);
            setError("");
            setSuccess("");
            if (isCSV(file)) {
                await generateTwilioCostReport(file);
                setSuccess("Successfully generated the report.")
            }
            else {
                setError("Please check that your upload is a .csv file.");
            }
            setIsLoading(false);
        } catch (error: any) {
            setError("Sorry, an error occurred.");
            setIsLoading(false);
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Admin Dashboard" />
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="6">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>How did you hear about us?</IonCardTitle>
                            <IonCardContent>
                                <ReferralTypePoll />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                    <IonCol sizeSm="12" sizeMd="6">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Twilio Cost Report</IonCardTitle>
                            <IonCardContent>
                            {error && <ErrorAlert width="12" error={error} />}
                            {success && <SuccessBanner width="12" success={success} />}
                                <IonRow className="ion-justify-content-center">
                                    <IonCol size="12" className="text-center">
                                        <p>Upload a CSV file from Twilio with invoice data from a single month.</p>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol size="12" className="text-center">
                                        {isLoading ?
                                            <Spinner />
                                            :
                                            <FileBrowser handleSelectedFile={handleSelectedFile}/>
                                        }
                                    </IonCol>
                                </IonRow>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Users Signed Up For Text Alerts</IonCardTitle>
                            <IonCardContent>
                                <AdminEventAuditorInfo />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Test Data</IonCardTitle>
                            <IonCardContent>
                                <IonButton onClick={navigateToClinicsPage}>Clinics</IonButton>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default AdminDashboardPage;