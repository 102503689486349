import {
    IonButton,
    IonCol,
    IonIcon,
    IonLabel,
    IonRow, 
    IonSegment, 
    IonSegmentButton
} from "@ionic/react";
import React, {useContext, useEffect, useState} from "react";
import { EventClass, EventClassEntry, EventResult, PrizeMoneyTable } from "../../models";
import { getEventClassEntriesByEventClassId, getEventClassEntryById } from "../../utilities/eventClassEntry/EventClassEntry";
import { Input, Table } from "reactstrap";
import { ResultSetRow } from "../../interfaces/EventResult";
import { createEventResult, deleteEventResult, getEventResultsByEventClassIdWithEventClassEntryRider } from "../../utilities/eventResult/EventResult";
import BasicTooltip from "../Tooltip/BasicTooltip";
import { PersonContext } from "../../context/PersonContext";
import Spinner from "../Spinners/Spinner";
import { caretUpOutline, caretDownOutline } from "ionicons/icons";
import { CreateEventResultInput } from "../../API";
import moment from "moment";
import { useHistory } from "react-router";
import SuccessBanner from "../Banners/SuccessBanner";
import ErrorBanner from "../Banners/ErrorBanner";
import { calculatePointsFromTable } from "../../utilities/pointTable/CaculatePointsFromTable";
import VerifyEventResultsTable from "./VerifyEventResultsTables";

interface _Props {
    eventClass: EventClass
    prizeMoneyTable?: (PrizeMoneyTable | null)
    isEditable?: Boolean
}

const EventKeyInResultsTable: React.FC<_Props> = ({eventClass, prizeMoneyTable, isEditable}) => {
    const user = useContext(PersonContext);
    const history = useHistory();

    const [eventClassEntries, setEventClassEntries] = useState<EventClassEntry[] | undefined>();
    const [allEventClassResults, setAllEventClassResults] = useState<EventResult[] | undefined>();
    const [eventClassResults, setEventClassResults] = useState<EventResult[] | undefined>();

    const [totalNumberPlacings, setTotalNumberPlacings] = useState(6);

    const [formattedRows, setFormattedRows] = useState<ResultSetRow[] | undefined>();
    const [currentResultSet, setCurrentResultSet] = useState("1");
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    async function getEventClassResults(eventClassId: string, resultSet?: string) {
        const queryResult = await getEventResultsByEventClassIdWithEventClassEntryRider(eventClassId);
        if (queryResult.isSuccess) {
            let eventResults: EventResult[] = queryResult.result;
            setAllEventClassResults(eventResults);
            if (resultSet) {
                eventResults = eventResults.filter(result => (result.resultSet ? result.resultSet === resultSet : true));
            }
            setEventClassResults(eventResults);
            return eventResults;
        } else {
            setError("Sorry, a problem occurred. No class results found. Please go back and try again.");
        }
    }

    async function getEventClassEntries(eventClassId: string) {
        const queryResult = await getEventClassEntriesByEventClassId(eventClassId);
        if (queryResult.isSuccess) {
            const eventClassEntries = queryResult.result;
            setEventClassEntries(eventClassEntries);
            return eventClassEntries;
        } else {
            setError("Sorry, a problem occurred. No event class entries found. Please go back and try again.");
        }
    }

    const getData = async (eventClassId: string) => {
        setIsLoading(true);
        await getEventClassEntries(eventClassId);
        const eventResults: EventResult[] | undefined = await getEventClassResults(eventClassId);
        await formatRows(eventResults);
        setIsLoading(false);
    }

    const handleResultSetChange = async (value: string) => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        setCurrentResultSet(value);
        const eventResults: EventResult[] | undefined = await getEventClassResults(eventClass.id, value);
        await formatRows(eventResults, value);
        setIsLoading(false);
    }

    const handleTotalNumberPlacingsChange = (value: number) => {
        let updatedRows: ResultSetRow[] = formattedRows || [];
        if (value === -1) {
            updatedRows = formattedRows?.slice(0, totalNumberPlacings-1) || [];
        } else if (value === 1) {
            const formattedRow: ResultSetRow = {
                eventClassId: eventClass.id,
                place: totalNumberPlacings + 1,
            };
            updatedRows.push(formattedRow);
        }
        setFormattedRows(updatedRows);
        setTotalNumberPlacings(totalNumberPlacings + value);
    }

    useEffect(() => {
        if (eventClass) {
            getData(eventClass.id);
        }
    }, [eventClass]);

    const formatRows = async (eventResults?: EventResult[], updatedSetNumber?: string) => {
        const setNumber = updatedSetNumber || currentResultSet;
        const newFormattedRows: ResultSetRow[] = [];
        for (let i = 1; i <= totalNumberPlacings; i++) {
            const filteredEventResult = eventResults?.find(result => result.place === i && (result.resultSet ? result.resultSet === setNumber : true));
            const formattedRow: ResultSetRow = {
                eventClassId: eventClass.id,
                place: i,
                entryId: filteredEventResult?.entryId,
                entryNumber: filteredEventResult?.entry.number,
                label: (filteredEventResult && filteredEventResult.eventClassEntry) ? await createSuccessLabel(filteredEventResult.eventClassEntry) : ""
            };
            newFormattedRows.push(formattedRow);
        }
        setFormattedRows(() => newFormattedRows);
    }

    const createSuccessLabel = async (eventClassEntry: EventClassEntry) => {
        let horseName = eventClassEntry.eventEntry?.horseName || "";
        let riderName = eventClassEntry.rider?.name || "";

        if (!riderName) {
            const queryResult = await getEventClassEntryById(eventClassEntry.id);
            if (queryResult.isSuccess) {
                const foundEventClassEntry: EventClassEntry = queryResult.result;
                riderName = foundEventClassEntry.rider?.name || "";
                horseName = foundEventClassEntry.eventEntry?.horseName ? foundEventClassEntry.eventEntry?.horseName : horseName;
            }
        }

        return horseName +  " - " + riderName;
    }

    const createNotEnteredLabel = () => {
        return "Error: this entry number was not found.";
    }

    const handleRowChange = async (index: number, entryNumber: string) => {
        setError("");
        if (formattedRows) {
            const currentRow = formattedRows[index];

            const found = eventClassEntries?.find(entry => entry.eventEntry?.number === parseInt(entryNumber));

            let horseName = "";
            if (found?.eventEntry?.horse?.name) horseName = found.eventEntry.horse.name;
            else if (found?.eventEntry?.horseName) horseName = found.eventEntry.horseName;
            
            let riderName = "";
            if (found?.rider?.name) riderName = found.rider.name;
            else if (found?.eventEntry?.rider?.name) riderName = found.eventEntry.rider.name;
            else if (found?.eventEntry?.riderName) riderName = found.eventEntry.riderName;

            const updatedRow: ResultSetRow = {
                ...currentRow,
                entryNumber: parseInt(entryNumber),
                entryId: (found && found.eventEntry) ? found.eventEntry.id : "",
                eventClassEntryId: found ? found.id : "",
                label: (entryNumber === "") ? "" : ((found && found.eventEntry) ? await createSuccessLabel(found) : createNotEnteredLabel()),
                horseName: horseName,
                riderName: riderName
            };
            const updatedRows = [
                ...formattedRows.slice(0, index),
                updatedRow,
                ...formattedRows.slice(index + 1)
            ];
            setFormattedRows(updatedRows);
        } 
    }

    const handleSave = async () => {
        setError("");
        setSuccess("");
        setIsLoading(true);

        if (formattedRows && formattedRows.length > 0) {

            // First, remove any current results from this result set
            if (eventClassResults) {
                for (let i = 0; i < eventClassResults.length; i++) {
                    const currentResult = eventClassResults[i];
                    if (currentResult.resultSet) {
                        if (currentResult.resultSet === currentResultSet) {
                            const deleteResult = await deleteEventResult({id: currentResult.id});
                            if (!deleteResult.isSuccess) {
                                setError("The outdated results could not be updated. Please try again or contact hello@ringsidepro.com.");
                                return;
                            }
                        } else {
                            // This result belongs to a different set, so leave it for now
                        }
                    } else {
                        // If a result does not have a result set, remove it and replace it
                        const deleteResult = await deleteEventResult({id: currentResult.id});
                        if (!deleteResult.isSuccess) {
                            setError("The outdated results could not be updated. Please try again or contact hello@ringsidepro.com.");
                            return;
                        }
                    }
                }
            }

            // Create the new results for this result set
            let newEventResults: EventResult[] = [];
            if (eventClassEntries) {
                // Loop through all of the entries in this class - even if they didn't place, save their result as place = 0
                for (let i = 0; i < eventClassEntries.length; i++) {
                    const currentEventClassEntry: EventClassEntry = eventClassEntries[i];
                    const currentFormattedResult = formattedRows.find(row => row.entryId === currentEventClassEntry.eventEntry?.id);
                    // If we are in the first result set, always save the results for all entries. If we are in a different result set, only add in the result if place is above 0
                    if ((currentResultSet === "1") || (currentFormattedResult && currentFormattedResult.place !== 0)) {
                        let input: CreateEventResultInput = {
                            eventId: eventClass.eventId,
                            entryId: currentEventClassEntry.eventEntry?.id || "",
                            eventResultEntryId: currentEventClassEntry.eventEntry?.id || "",
                            eventClassEntryId: currentEventClassEntry.id,
                            eventResultEventClassEntryId: currentEventClassEntry.id,
                            eventClassId: eventClass.id,
                            eventClassName: eventClass.name,
                            resultSet: currentResultSet,
                            place: currentFormattedResult?.place || 0,
                            time: currentFormattedResult?.time || undefined,
                            score: currentFormattedResult?.score || undefined,
                            prizeMoney: currentFormattedResult?.prizeMoney ? parseFloat(currentFormattedResult?.prizeMoney?.replace("$", "")) : 0,
                            eventResultPrizeMoneyTableId: prizeMoneyTable?.id || "",
                            didNotCompete: currentFormattedResult?.didNotCompete || false,
                            createdBy: user.id,
                            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                        };
                        const createResult = await createEventResult(input);
                        if (createResult.isSuccess) {
                            const eventResult = createResult.result;
                            newEventResults.push(eventResult);
                        } else {
                            setError("An error occurred while saving the results. Please try refreshing the screen and entering the results again.");
                            return;
                        }
                    }
                }
            }

            await formatRows(newEventResults);    
            setSuccess("The results have been saved.");       
        } else {
            setError("No results information was found.");
        }
        setIsLoading(false);
    }
    
    const handleNavigateToEntryPage = async (eventEntryId: string) => {
        const path = "/index/staff/event/entry/" + eventEntryId;
        history.push(path);
    }

    return (
       <>
            {isLoading ?
                <Spinner />
                :
                <>
                    {success && <SuccessBanner success={success} width="12" />}
                    {error && <ErrorBanner error={error} />}
                    <IonRow className="ion-justify-content-center">
                        <IonCol>
                            <IonSegment color="primary" mode="ios" value={currentResultSet} scrollable={true} onIonChange={e => {handleResultSetChange(e.detail.value || "1");}}>
                                <IonSegmentButton value="1">
                                    <IonLabel>Results Set 1</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="2">
                                    <IonLabel>Results Set 2</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="3">
                                    <IonLabel>Results Set 3</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="4">
                                    <IonLabel>Results Set 4</IonLabel>
                                </IonSegmentButton>
                            </IonSegment>
                        </IonCol>
                    </IonRow>
                    <IonRow className="bg-light">
                        <IonCol sizeXs="4" sizeMd="2" sizeLg="2">
                            <h3 className="ion-text-wrap"><BasicTooltip label="Place" tip="Scroll down and use the tool below to add more placings." /></h3>
                        </IonCol>
                        <IonCol sizeXs="8" sizeMd="3" sizeLg="2">
                            <h3 className="ion-text-wrap"><BasicTooltip label="Entry Number" tip="Enter a back number and use the TAB key to move to the next row." /></h3>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="7" sizeLg="8">
                            
                        </IonCol>
                    </IonRow>
                    {(formattedRows && formattedRows) ? (
                        <>
                            {formattedRows.map((row, index) => (
                                <>
                                    <IonRow key={index}>
                                        <IonCol sizeXs="4" sizeMd="2" sizeLg="2">
                                            <h2>{row.place}</h2>
                                        </IonCol>
                                        <IonCol sizeXs="8" sizeMd="3" sizeLg="2">
                                            <Input 
                                                className="small-input"
                                                type="text"
                                                value={row.entryNumber ? row.entryNumber.toString() : ""}
                                                valid={!!row.entryId}
                                                onChange={e => {
                                                    handleRowChange(index, e.target.value);
                                                }}
                                            />
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="7" sizeLg="8">
                                            <p className="ion-text-wrap link" onClick={() => row.entryId ? handleNavigateToEntryPage(row.entryId) : console.log("No entry id found.")}>{row.label}</p>
                                        </IonCol>
                                    </IonRow>
                                    <hr className="px-0 py-0 mx-0 my-0" />
                                </>
                            ))}
                        </>
                        )
                        :
                        <p>Loading...</p>
                    }
                    {(isEditable && formattedRows && formattedRows.length > 0) && (
                        <>
                            <IonRow>
                                <IonCol sizeXs="4" sizeMd="1">
                                    <p>Total Rows:</p> 
                                </IonCol>
                                <IonCol sizeXs="2" sizeMd="1">
                                    <Input 
                                        type="number"
                                        className="small-input"
                                        min={1}
                                        max={100}
                                        step={1}
                                        disabled={true}
                                        value={totalNumberPlacings}
                                        onChange={e => {
                                            handleTotalNumberPlacingsChange(parseInt(e.target.value));
                                        }}
                                    />
                                </IonCol>
                                <IonCol sizeXs="4" sizeMd="1">
                                    <IonRow>
                                        <span onClick={() => handleTotalNumberPlacingsChange(1)}><IonIcon icon={caretUpOutline}/></span>
                                    </IonRow>
                                    <IonRow>
                                        <span onClick={() => handleTotalNumberPlacingsChange(-1)}><IonIcon icon={caretDownOutline}/></span>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-align-items-center">
                                <IonCol sizeXs="12" sizeMd="6" offsetMd="3" className="ion-text-center">
                                    <IonButton color="success" expand="full" onClick={handleSave}>Save</IonButton>
                                    <p className="description text-info">(Save results before switching to another result set.)</p>
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                    <hr />
                    <VerifyEventResultsTable eventResults={allEventClassResults} isEditable={isEditable} />
                </>
            }
       </>
    );
};

export default EventKeyInResultsTable;
