import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventResultInput, CreateEventResultMutation, DeleteEventResultInput, DeleteEventResultMutation, GetEventResultQuery, ListEventResultsQuery, UpdateEventResultInput, UpdateEventResultMutation } from "../../API";
import moment from "moment";
import { sortEventResults } from "./SortEventResult";
import { EventResult } from "../../models";
import { eventClassResultsByEventClassIdWithEventClassEntryRider, eventClassResultsByEventClassIdWithPointTables } from "./EventResultCustomQueries";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventResult. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventResult.
*/
export async function createEventResult(input: CreateEventResultInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventResult", "No input", "Create EventResult received no input.");
   try {
        const fullInput: CreateEventResultInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEventResult, { input: fullInput }))) as GraphQLResult<CreateEventResultMutation>;
       else result = (await API.graphql({
           query: mutations.createEventResult,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateEventResultMutation>;
       const eventResult = result.data?.createEventResult;
       return formatResult(true, "EventResult", eventResult, "Successfully created the eventResult.");
   } catch (error: any) {
       return formatResult(false, "EventResult", error, "Error creating record in the database for type: eventResult");
   }
}

/**
* Handle updating a new record in the database for type: eventResult. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventResult.
*/
export async function updateEventResult(input: UpdateEventResultInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventResult", "No input", "Update EventResult received no input.");
   try {
        const fullInput: UpdateEventResultInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEventResult, { input: fullInput }))) as GraphQLResult<UpdateEventResultMutation>;
       else result = (await API.graphql({
           query: mutations.updateEventResult,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateEventResultMutation>;
       const eventResult = result.data?.updateEventResult;
       return formatResult(true, "EventResult", eventResult, "Successfully updated the eventResult.");
   } catch (error: any) {
       return formatResult(false, "EventResult", error, "Error updating record in the database for type: eventResult");
   }
}

/**
* Handle deleting a new record in the database for type: eventResult. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventResult.
*/
export async function deleteEventResult(input: DeleteEventResultInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventResult", "No input", "Delete EventResult received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEventResult, { input: input }))) as GraphQLResult<DeleteEventResultMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEventResult,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventResultMutation>;
       const eventResult = result.data?.deleteEventResult;
       return formatResult(true, "EventResult", eventResult, "Successfully deleted the eventResult.");
   } catch (error: any) {
       return formatResult(false, "EventResult", error, "Error deleting record in the database for type: eventResult");
   }
}

/**
* Get all records in the database for type: eventResult. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventResult object
*/
export async function getAllEventResults(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEventResults,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventResultsQuery>;

        let items = result.data?.listEventResults?.items as EventResult[];
        let nextToken = result.data?.listEventResults?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventResults,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventResultsQuery>;

            const nextItems = nextResult.data?.listEventResults?.items as EventResult[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventResults?.nextToken;
        }

        return formatResult(true, "EventResult", items, "Successfully got the eventResults.");
    } catch (error: any) {
        return formatResult(false, "EventResult", error, "Error reading record in the database for type: eventResults");
    }
}

/**
* Read a specific record in the database for type: eventResult. 
* 
* @param {string}  id                  The eventResult id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventResult object
*/
export async function getEventResultById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEventResult,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventResultQuery>;
       const eventResult = result.data?.getEventResult;
       return formatResult(true, "EventResult", eventResult, "Successfully got the eventResult.");
   } catch (error: any) {
       return formatResult(false, "EventResult", error, "Error reading record in the database for type: eventResult");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventResult. 
* 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventResult object
*/
export async function getEventResultsByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventResultsByEventId,
            variables: {
                eventId: eventId,
                limit: 1000
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventResultsByEventId?.items as EventResult[];
        let nextToken = result.data?.eventResultsByEventId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventResultsByEventId,
                variables: {
                    eventId: eventId,
                    limit: 1000,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventResultsByEventId?.items as EventResult[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventResultsByEventId?.nextToken;
        }

        return formatResult(true, "EventResult", items, "Successfully got the eventResults.");
    } catch (error: any) {
        return formatResult(false, "EventResult", error, "Error reading record in the database for type: eventResults");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventResult. 
* 
* @param {string}  eventClassId        The class id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventResult object
*/
export async function getEventResultsByEventClassId(eventClassId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventClassResultsByEventClassId,
            variables: {
                limit: 100,
                eventClassId: eventClassId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventClassResultsByEventClassId?.items as EventResult[];
        let nextToken = result.data?.eventClassResultsByEventClassId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventClassResultsByEventClassId,
                variables: {
                    limit: 100,
                    eventClassId: eventClassId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventClassResultsByEventClassId?.items as EventResult[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventClassResultsByEventClassId?.nextToken;
        }

        return formatResult(true, "EventResult", items, "Successfully got the eventResults.");
    } catch (error: any) {
        return formatResult(false, "EventResult", error, "Error reading record in the database for type: eventResults");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventResult. 
* 
* @param {string}  eventClassId        The class id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventResult object
*/
export async function getEventResultsByEventClassIdWithEventClassEntryRider(eventClassId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: eventClassResultsByEventClassIdWithEventClassEntryRider,
            variables: {
                limit: 1000,
                eventClassId: eventClassId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventClassResultsByEventClassId?.items as EventResult[];
        let nextToken = result.data?.eventClassResultsByEventClassId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: eventClassResultsByEventClassIdWithEventClassEntryRider,
                variables: {
                    limit: 100,
                    eventClassId: eventClassId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventClassResultsByEventClassId?.items as EventResult[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventClassResultsByEventClassId?.nextToken;
        }

        return formatResult(true, "EventResult", items, "Successfully got the eventResults.");
    } catch (error: any) {
        return formatResult(false, "EventResult", error, "Error reading record in the database for type: eventResults");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventResult. 
* 
* @param {string}  eventClassId        The class id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventResult object
*/
export async function getEventResultsByEventClassIdWithPointTables(eventClassId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: eventClassResultsByEventClassIdWithPointTables,
            variables: {
                limit: 100,
                eventClassId: eventClassId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventClassResultsByEventClassId?.items as EventResult[];
        let nextToken = result.data?.eventClassResultsByEventClassId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: eventClassResultsByEventClassIdWithPointTables,
                variables: {
                    limit: 100,
                    eventClassId: eventClassId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventClassResultsByEventClassId?.items as EventResult[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventClassResultsByEventClassId?.nextToken;
        }

        return formatResult(true, "EventResult", items, "Successfully got the eventResults.");
    } catch (error: any) {
        return formatResult(false, "EventResult", error, "Error reading record in the database for type: eventResults");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventResult. 
* 
* @param {string}  eventId             The event id 
* @param {string}  classId             The class id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventResult object
*/
export async function getEventResultByEventIdClassIdEntryId(eventId: string, classId: string, entryId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}, eventClassId: {eq: classId}, entryId: {eq: entryId}};
        const result = (await API.graphql({
            query: queries.listEventResults,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventResultsQuery>;

        let items = result.data?.listEventResults?.items as EventResult[];
        let nextToken = result.data?.listEventResults?.nextToken;

        while (nextToken && (!items || (items && items.length === 0))) {
            const nextResult = await API.graphql({
                query: queries.listEventResults,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventResultsQuery>;

            const nextItems = nextResult.data?.listEventResults?.items as EventResult[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventResults?.nextToken;
        }

        const eventResults = items;
        if (eventResults && eventResults[0]) return formatResult(true, "EventResult", eventResults[0], "Successfully got the eventResult.");
        else return formatResult(false, "EventResult", null, "Could not find the eventResult.");
    } catch (error: any) {
        return formatResult(false, "EventResult", error, "Error reading record in the database for type: eventResults");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventResult. 
* 
* @param {string}  divisionId          The event division id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventResult object
*/
export async function getEventResultsByEventDivisionId(divisionId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventDivisionResultsByEventDivisionId,
            variables: {
                limit: 100,
                eventDivisionId: divisionId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventDivisionResultsByEventDivisionId?.items as EventResult[];
        let nextToken = result.data?.eventDivisionResultsByEventDivisionId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventDivisionResultsByEventDivisionId,
                variables: {
                    limit: 100,
                    eventDivisionId: divisionId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventDivisionResultsByEventDivisionId?.items as EventResult[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventDivisionResultsByEventDivisionId?.nextToken;
        }

        return formatResult(true, "EventResult", items, "Successfully got the eventResults.");
    } catch (error: any) {
        return formatResult(false, "EventResult", error, "Error reading record in the database for type: eventResults");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventResult. 
* 
* @param {string}  eventId             The event id 
* @param {string}  entryId             The entry id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventResult object
*/
export async function getEventResultsByEventIdEntryId(eventId: string, entryId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}, entryId: {eq: entryId}};
        const result = (await API.graphql({
            query: queries.listEventResults,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventResultsQuery>;
        
        let items = result.data?.listEventResults?.items as EventResult[];
        let nextToken = result.data?.listEventResults?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventResults,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventResultsQuery>;

            const nextItems = nextResult.data?.listEventResults?.items as EventResult[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventResults?.nextToken;
        }

        const eventResults = items;
        const sorted = sortEventResults(eventResults);
        if (sorted) {
            return formatResult(true, "EventResult", sorted, "Successfully got the eventResults.");
        } else {
            return formatResult(true, "EventResult", eventResults, "Successfully got the eventResults.");
        }
    } catch (error: any) {
        return formatResult(false, "EventResult", error, "Error reading record in the database for type: eventResults");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventResult. 
* 
* @param {string}  entryId             The entry id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventResult object
*/
export async function getEventResultsByEntryId(entryId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventClassResultsByEntryId,
            variables: {
                limit: 1000,
                entryId: entryId,
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;
        
        let items = result.data?.eventClassResultsByEntryId?.items as EventResult[];
        let nextToken = result.data?.eventClassResultsByEntryId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventClassResultsByEntryId,
                variables: {
                    limit: 100,
                    entryId: entryId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventClassResultsByEntryId?.items as EventResult[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventClassResultsByEntryId?.nextToken;
        }

        const eventResults = items;
        const sorted = sortEventResults(eventResults);
        if (sorted) {
            return formatResult(true, "EventResult", sorted, "Successfully got the eventResults.");
        } else {
            return formatResult(true, "EventResult", eventResults, "Successfully got the eventResults.");
        }
    } catch (error: any) {
        return formatResult(false, "EventResult", error, "Error reading record in the database for type: eventResults");
    }
}


/**
* Get all records in the database that match the given criteria for type: eventResult. 
* 
* @param {string}  eventClassEntryId            The event class entry id 
* @param {string}  authMode                     API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventResult objects
*/
export async function getEventResultsByEventClassEntryId(eventClassEntryId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventClassResultsByEventClassEntryId,
            variables: {
                limit: 1000,
                eventClassEntryId: eventClassEntryId,
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;
        
        let items = result.data?.eventClassResultsByEventClassEntryId?.items as EventResult[];
        let nextToken = result.data?.eventClassResultsByEventClassEntryId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventClassResultsByEventClassEntryId,
                variables: {
                    limit: 100,
                    eventClassEntryId: eventClassEntryId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventClassResultsByEventClassEntryId?.items as EventResult[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventClassResultsByEventClassEntryId?.nextToken;
        }

        const eventResults = items;
        const sorted = sortEventResults(eventResults);
        if (sorted) {
            return formatResult(true, "EventResult", sorted, "Successfully got the eventResults.");
        } else {
            return formatResult(true, "EventResult", eventResults, "Successfully got the eventResults.");
        }
    } catch (error: any) {
        return formatResult(false, "EventResult", error, "Error reading record in the database for type: eventResults");
    }
}