import moment from "moment";
import { Event, EventClass, EventClassEntry, EventResult } from "../../models";
import { getEventClassesByEventId } from "../eventClass/EventClass";
import { sortEventClassesByNumber } from "../eventClass/SortEventClass";
import { getEventClassEntryById } from "../eventClassEntry/EventClassEntry";
import { getEventResultsByEventClassId } from "../eventResult/EventResult";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

export async function generateBasicClassResultsReport(event: Event) {
    // initialize jsPDF
    const doc = new jsPDF();

    // page title. and margin-top + margin-left
    doc.text(`${event.name}`, 14, 15);
    doc.setFontSize(10);
    doc.text(`Class Results Report`, 14, 20);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);

    // define the columns we want and their titles
    const tableColumn = ["Class Number", "Class Name", "Place", "Entry Number", "Horse", "Rider", "Trainer"];
    // define an empty array of rows
    const tableRows: string[][] = [];

    const queryResult = await getEventClassesByEventId(event.id);
    if (queryResult.isSuccess) {
        const eventClasses = queryResult.result as EventClass[];
        const sorted = sortEventClassesByNumber(eventClasses);
        if (sorted && sorted.length > 0) {
            for (let i = 0; i < sorted.length; i++) {
                const eventClass = sorted[i];
                const dataResult = await getEventResultsByEventClassId(eventClass.id);
                if (dataResult.isSuccess) {
                    const eventClassResults = dataResult.result;
                    if (eventClassResults.length > 0) {
                        const sortedResults = eventClassResults.sort((a: EventResult, b: EventResult) => (a.place || 0) - (b.place || 0));
                        for (let j = 0; j < sortedResults.length; j++) {
                            const result: EventResult = sortedResults[j];
                            let riderName = result.entry.riderName;
                            if (result.eventClassEntryId) {
                                const eventClassEntryResult = await getEventClassEntryById(result.eventClassEntryId);
                                if (eventClassEntryResult.isSuccess) {
                                    const eventClassEntry: EventClassEntry = eventClassEntryResult.result;
                                    if (eventClassEntry && eventClassEntry.rider && eventClassEntry.rider?.name) riderName = eventClassEntry.rider?.name;
                                }
                            }
                            const row = [
                                eventClass.number?.toString() || "",
                                eventClass.name || "",
                                result.place?.toString() || "",
                                result.entry.number?.toString() || "",
                                result.entry.horseName || "",
                                riderName || "",
                                result.entry.trainerName || ""
                            ];
                            tableRows.push(row);
                        }
                    }
                };
            }
        }
    }
     
    // startY is basically margin-top
    doc.autoTable(tableColumn, tableRows, { 
        theme: "grid",
        headStyles: {fillColor: "#73a4d3"},
        startY: 35 
    });

    // page footer
    doc.setFontSize(10);
    doc.text("Created using RingSide Pro: www.ringsidepro.com", 14, doc.internal.pageSize.height - 10);

    // we define the name of our PDF file.
    doc.save(`class_results.pdf`);
}