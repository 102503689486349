import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
    IonTextarea,
} from "@ionic/react";
import Header from "../../../components/Headers/Header";
import PageTitle from "../../../components/PageTitle/PageTitle";
import React, { useContext, useEffect, useState } from "react";
import SelectVideo from "../../../components/s3Object/SelectVideo";
import { PersonContext } from "../../../context/PersonContext";
import { Clinician, S3Object } from "../../../models";
import VideoForm from "../../../components/s3Object/VideoForm";
import { getAllClinicians } from "../../../utilities/clinician/Clinician";
import ErrorBanner from "../../../components/Banners/ErrorBanner";
import CartChip from "../../../components/Chips/CartChip";
import LargeSelectClinicians from "../../../components/Clinician/LargeSelectClinicians";
import { CartItem } from "../../../interfaces/Cart";
import { formatCliniciansAsCartItems } from "../../../utilities/cart/FormatCartItems";
import BasicTooltip from "../../../components/Tooltip/BasicTooltip";
import PaymentSummary from "../../../components/Clinic/PaymentSummary";
import { CreateFeedbackInput, CreateSubmissionFeedbackInput, CreateSubmissionInput, FeedbackStatus, SubmissionStatus } from "../../../API";
import { createSubmission } from "../../../utilities/submission/Submission";
import { createFeedback } from "../../../utilities/feedback/Feedback";
import { createSubmissionFeedback } from "../../../utilities/submissionFeedback/SubmissionFeedback";
import { createClinicCheckoutSession } from "../../../utilities/stripe/Checkout";
import { loadStripe } from "@stripe/stripe-js";
import Spinner from "../../../components/Spinners/Spinner";
import moment from "moment";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_test_sXlren9AWlEtWHVJwhKW6LbK00Q06Uoo4o");
// const stripePromise = loadStripe('pk_live_EJGhy2cBbcqIpfN95FhieHL5006DOpYcQi');

const NewSubmissionsPage: React.FC = () => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [currentStep, setCurrentStep] = useState(1);
    const [error, setError] = useState("");

    const [cartItems, setCartItems] = useState<CartItem[] | null | undefined>();

    const [clinicians, setClinicians] = useState<Clinician[] | null | undefined>();
    const [selectedClinicians, setSelectedClinicians] = useState<Clinician[] | null | undefined>();

    const [userVideos, setUserVideos] = useState<(S3Object | null)[]>();
    const [video, setVideo] = useState<S3Object | null | undefined>();

    const [description, setDescription] = useState("");

    useEffect(() => {
        const getClinicians = async () => {
            const queryResult = await getAllClinicians();
            if (queryResult.isSuccess) {
                setClinicians(queryResult.result);
            } else {
                setError("Sorry, we could not find any clinicians. Please try refreshing the page.");
            }
        };

        getClinicians();
    }, []);

    useEffect(() => {
        if (user && user.media) setUserVideos(user.media); 
    }, [user]);

    const handleSelectedClinicians = (selectedClinicians: Clinician[]) => {
        setSelectedClinicians(selectedClinicians);

        const cartItems = formatCliniciansAsCartItems(selectedClinicians);
        setCartItems(cartItems);

        setIsDisabled(!(selectedClinicians.length > 0));
    }

    const handleSelectedVideo = (allVideos: S3Object[], newVideo: S3Object) => {
        setVideo(newVideo);
        if (newVideo && newVideo.description) setDescription(newVideo.description);
        setIsDisabled(!(!!newVideo));
    }

    const calculateTotalPrice = () => {
        if (cartItems && cartItems.length > 0) {
            let total = 0;
            cartItems.forEach(item => {
                total = total + (item.basePrice * item.quantity);
            });
            return total;
        } else {
            setError("Something went wrong");
        }
    }

    const handleNext = () => {
        setCurrentStep(currentStep+1);
        setIsDisabled(true);
    }

    const handleBack = () => {
        setCurrentStep(currentStep-1);
    }

    const createNewSubmission = async () => {
        const total = calculateTotalPrice();
        if (total) {
            const createInput: CreateSubmissionInput = {
                title: video?.title || user.id,
                description: description,
                video: video,
                personId: user.id,
                totalPrice: total,
                status: SubmissionStatus.pending
            };
            const createSubmissionResult = await createSubmission(createInput);
            if (createSubmissionResult.isSuccess) {
                return createSubmissionResult.result;
            } else {
                setError(createSubmissionResult.message);
                return null;
            }
        } else {
            return null;
        }
    }

    const createNewFeedback = async (clinicianId: string) => {
        const createInput: CreateFeedbackInput = {
            clinicianId: clinicianId,
            status: FeedbackStatus.pending
        };
        const createFeedbackResult = await createFeedback(createInput);
        if (createFeedbackResult.isSuccess) {
            return createFeedbackResult.result;
        } else {
            setError(createFeedbackResult.message);
            return null;
        }
    }

    const createNewSubmissionFeedback = async (submissionId: string, feedbackId: string, clinician: Clinician) => {
        const createInput: CreateSubmissionFeedbackInput = {
            submissionId: submissionId,
            // submissionFeedbackSubmissionId: submissionId,
            feedbackId: feedbackId,
            // submissionFeedbackFeedbackId: feedbackId,
            personId: user.id,
            clinicianId: clinician.id,
            submissionFeedbackClinicianId: clinician.id,
            clinicianAmount: clinician.price,
            submissionStatus: SubmissionStatus.pending,
            feedbackStatus: FeedbackStatus.pending,
            dateSubmitted: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            dateCompleted: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
        const createSubmissionResult = await createSubmissionFeedback(createInput);
        if (createSubmissionResult.isSuccess) {
            return createSubmissionResult.result;
        } else {
            setError(createSubmissionResult.message);
            return null;
        }
    }

    const handleSubmissionCreation = async () => {
        const submission = await createNewSubmission(); 
        if (submission) {
            if (selectedClinicians && selectedClinicians.length > 0) {
                let isSuccess = true;
                for (let i = 0; i < selectedClinicians.length; i++) {
                    const clinician = selectedClinicians[i];
                    const feedback = await createNewFeedback(clinician.id);
                    if (feedback) {
                        const submissionFeedback = await createNewSubmissionFeedback(submission.id, feedback.id, clinician);
                        if (!submissionFeedback) {
                            isSuccess = false;
                            setError("Could not create submission feedback.");
                        }
                    }
                }
                if (isSuccess) return submission;
            } else {
                return submission;
            }
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);

        // Get Stripe.js instance
        const stripe = await stripePromise;

        // First, check that there is something in the cart
        if (cartItems && cartItems.length > 0) {
            // Next, verify that the user has a stripe id
            if (user.stripeId) {
                // Next, create a new submission in a pending state
                const submission = await handleSubmissionCreation();
                if (submission) {
                    // Next, redirect to Stripe Checkout
                    const sessionResult = await createClinicCheckoutSession(user.stripeId, submission.id, cartItems);
                    if (sessionResult.isSuccess) {
                        const session = sessionResult.result;
                        if (stripe) {
                            const result = await stripe.redirectToCheckout({
                                sessionId: session.id,
                            });
                            if (result.error) {
                                // If `redirectToCheckout` fails due to a browser or network
                                // error, display the localized error message to your customer
                                // using `result.error.message`.
                                if (result.error.message) setError(result.error.message);
                            }
                        }
                    } else {
                        setError(sessionResult.message);
                    }
                }
            } else {
                setError("Something went wrong. Please contact us at hello@ringsidepro.com");
            }
        } else {
            setError("You have nothing in your cart. Please go back and select the items for your cart.");
        }
        setIsLoading(false);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Submission" />
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeXs="12" sizeMd="10">
                        {error && <ErrorBanner error={error} />}
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>
                                <IonRow>
                                    <IonCol size="10">
                                        Step {currentStep} of 3
                                    </IonCol>
                                    <IonCol size="2">
                                        <CartChip items={cartItems} />
                                    </IonCol>
                                </IonRow>
                            </IonCardTitle>
                            <IonCardSubtitle>
                                {currentStep === 1 && <p>Choose one or more clinicians. Expand the row to see more about each clinician.</p>}
                                {currentStep === 2 && <p>Choose the video you would like to submit.</p>}
                            </IonCardSubtitle>
                            <IonCardContent>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol className="ion-text-center">
                                        {currentStep === 1 && (
                                            <>  
                                                <LargeSelectClinicians onSelect={handleSelectedClinicians} />
                                            </>
                                        )}
                                        {currentStep === 2 && (
                                            <>  
                                                {userVideos && (
                                                    <IonRow>
                                                        <IonCol>
                                                            <p>Select a video from your library.</p>
                                                            <SelectVideo selectedVideo={video} mediaList={userVideos} onSelect={handleSelectedVideo} />
                                                            <h3>OR</h3>
                                                        </IonCol>
                                                    </IonRow>
                                                )}
                                                <IonRow>
                                                    <IonCol>
                                                        <p>Upload a new video.</p>
                                                        <VideoForm onSubmit={handleSelectedVideo}/>
                                                    </IonCol>
                                                </IonRow>
                                                <hr/>
                                                {video && (
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonItem color="white">
                                                                <IonLabel position="stacked"><BasicTooltip label="Video Description" tip="Example: division you are showing in, exercise you are doing in your lesson, description of the horse, questions for the clinician, etc"/></IonLabel>
                                                                <IonTextarea 
                                                                    rows={5}
                                                                    value={description}
                                                                    placeholder="Click here to add a description of the video. Plus, this is where you can ask any questions you want the clinician to answer."
                                                                    aria-required={true}
                                                                    onIonChange={e => {
                                                                        setDescription(e.detail.value!);
                                                                    }}
                                                                ></IonTextarea>
                                                            </IonItem>
                                                        </IonCol>
                                                    </IonRow>
                                                )}
                                            </>
                                        )}
                                        {currentStep === 3 && (
                                            <> 
                                                <PaymentSummary items={cartItems}/>
                                                <IonRow>
                                                    <IonCol>
                                                        <p>You will be redirected to our Stripe page - a secure service for online payments. You will receive your feedback within 2 weeks.</p>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        {isLoading ?
                                                            <Spinner />
                                                            :
                                                            <IonButton color="tertiary" expand="full" onClick={handleSubmit}>Continue to Payment</IonButton>
                                                        }
                                                    </IonCol>
                                                </IonRow>
                                            </>
                                        )}
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-padding-top">
                                    {currentStep !== 1 && (
                                        <IonCol className="text-left">
                                            <IonButton onClick={handleBack}>Back</IonButton>
                                        </IonCol>
                                    )}
                                    {currentStep !== 3 && 
                                        <IonCol className="text-right">
                                            <IonButton disabled={isDisabled} onClick={handleNext}>Next</IonButton>
                                        </IonCol>
                                    }                                    
                                </IonRow>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default NewSubmissionsPage;