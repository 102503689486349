import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    isPlatform,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import moment from "moment";
import "react-phone-input-2/lib/style.css";
import { isWindows } from "../../../utilities/platform/Platform";
import { Membership, Organization, OrganizationMembershipType, Person, PersonalInformation, MembershipTypeSchedule, Barn, Rider, Team } from "../../../models";
import SelectFromAllBarns from "../../Barn/SelectFromAllBarns";
import { CreateMembershipInput, CreatePersonInput, CreatePersonalInformationInput, CreateRiderInput, TeamRole, UpdateMembershipInput, UpdateOrganizationMembershipTypeInput, UpdatePersonalInformationInput, UpdateRiderInput } from "../../../API";
import { createMembership, updateMembership, getMembershipsByOrganizationId } from "../../../utilities/membership/Membership";
import { createPerson, getPersonByPersonId } from "../../../utilities/person/Person";
import { createPersonalInformation, getPersonalInformationByPersonId, updatePersonalInformation } from "../../../utilities/personalInformation/PersonalInformation";
import Spinner from "../../Spinners/Spinner";
import { createRider, getRiderByPersonId, updateRider } from "../../../utilities/rider/Rider";
import CONSTANT from "../../../constant/constant";
import { getNextAvailableMembershipId } from "../../../utilities/organizationMembershipType/MembershipIdValues";
import { updateOrganizationMembershipType } from "../../../utilities/organizationMembershipType/OrganizationMembershipType";
import GradeLevel from "../GradeLevel";
import AELDivisionSelect from "../AELDivisionSelect";
import SelectPersonForMembership from "../SelectPersonForMembership";
import ErrorAlert from "../../Errors/ErrorAlert";
import WarningBanner from "../../Banners/WarningBanner";
import SelectTeam from "../../Team/SelectTeam";
import AELCoachTypeSelect from "../AELCoachTypeSelect";
import RequiredInputIndicator from "../../Forms/RequiredInputIndicator";

interface _Props {
    isDisabled?: (boolean | undefined)
    membershipType: OrganizationMembershipType;
    memberships: Membership[] | undefined;
    personId: string;
    user: Person | undefined;
    setMemberships: Function;
    organization: Organization | undefined;
}

const SingleMemberInfo: React.FC<_Props> = ({isDisabled, membershipType, memberships, setMemberships, personId, user, organization}) => {

    const membership: (Membership | undefined) = (memberships?.length) ? memberships[0] : undefined;
    const [previousMemberships, setPreviousMemberships] = useState<Membership[] | undefined>(); //An expired membership for this user

    const [error, setError] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [selectedTeamId, setSelectedTeamId] = useState<string | undefined | null>();
    const [selectedPerson, setSelectedPerson] = useState<Person | undefined | null>();
    
    const [rider, setRider] = useState<Rider>();
    const [personInfo, setPersonInfo] = useState<PersonalInformation>();
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [birthDate, setBirthDate] = useState<string>("");
    const [barn, setBarn] = useState<Barn | undefined | null>();
    const [gradeLevel, setGradeLevel] = useState<string>(membership && membership.gradeLevel ? membership.gradeLevel : "-");
    const [aelDivision, setAELDivision] = useState<string>("");
    const [backNumber, setBackNumber] = useState<number | undefined>(undefined);
    const [teamRole, setTeamRole] = useState<TeamRole | null | undefined>(null);
    
    const [isAELOrganization, setIsAELOrganization] = useState(false);

    const handleSelectPerson = async (newlySelectedPerson?: Person) => {
        // AEL memberships will require selecting a person OR selecting an option to create a new person
        setSelectedPerson(newlySelectedPerson);

        if (newlySelectedPerson) {
            // If a person was selected, pre-populate form fields with as much data as you can
            await getPrePopulatedDataForIndividualMembership(newlySelectedPerson);
        } else {
            // If the option to create a new person was selected, then clear any data out of the form
            clearForm();
        }
    }

    const handleSelectBarn = async (barn?: Barn) => {
        setError("");
        if (barn && user) {
            setBarn(barn);
        } else {
            setBarn(undefined);
        }
    };

    const getOrganizationMemberships = async (org: Organization) => {
        const queryResult = await getMembershipsByOrganizationId(org.id);
        if (queryResult.isSuccess) {
            setPreviousMemberships(queryResult.result);
        }
    }

    const getPreviousMembershipByPersonName = (personName: string) => {
        if (previousMemberships) {
            const found = previousMemberships.find(membership => membership.personName?.toLowerCase().trim() === personName.toLowerCase().trim());
            if (found) {
                if (found.backNumber) { 
                    setBackNumber(found.backNumber);
                }
                if (found.rider) setRider(found.rider);
                if (found.barn) setBarn(found.barn);
                if (found.gradeLevel) setGradeLevel(found.gradeLevel);
                return found;
            }
        }
    }

    const getPrePopulatedDataForPersonalInformation = async (currentPersonId: string) => {
        try {
            const queryResult = await getPersonalInformationByPersonId(currentPersonId);
            if (queryResult.isSuccess) {
                const personalInformation: PersonalInformation = queryResult.result;
                setPersonInfo(personalInformation);
                setBirthDate(personalInformation && personalInformation.dateOfBirth ? personalInformation.dateOfBirth : "");                 
            } else {
                // We will need to create the personal information in handleSubmit
                // No need to show an error to the user in this case
            }
        } catch (error: any) {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    const getPrePopulatedDataForRiderInformation = async (currentPersonId: string) => {
        try {
            const queryResult = await getRiderByPersonId(currentPersonId);
            if (queryResult.isSuccess) {
                const riderData: Rider = queryResult.result;
                setRider(riderData);
                const barn: (Barn | null | undefined) = riderData.barn;
                setBarn(barn);
            }
        } catch (error: any) {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    const createFullName = (firstName?: (string | null), lastName?: (string | null)) => {
        const fullNameFormatted = (firstName ? firstName.trim() : "") + 
        (firstName && lastName ? " " : "") + 
        (lastName ? lastName.trim() : "");
        return fullNameFormatted;
    }

    const getPersonFullName = (personData: Person) => {
        const fullNameFormatted = createFullName(personData.firstName, personData.lastName);
        return fullNameFormatted;
    }

    const getPrePopulatedDataForIndividualMembership = async (person?: Person) => {
        // Keep track of the Person record for this Membership
        let personOnMembership: Person | undefined = person;

        // If not AEL - person was not selected - so get the full record from the DB
        // If not AEL - then the person on the membership is assumed to be the current user
        if (!person) {
            const queryResult = await getPersonByPersonId(personId);
            if (queryResult.isSuccess) {
                personOnMembership = queryResult.result;
            }
        }

        // Now, get the data for the person on this membership to pre-populate the form as much as possible
        if (personOnMembership) {
            // First, try to get info by the current person id
            await getPrePopulatedDataForPersonalInformation(personOnMembership.id);
            await getPrePopulatedDataForRiderInformation(personOnMembership.id);
            if (!isAELOrganization) {
                const fullNameFormatted = getPersonFullName(personOnMembership);
                setFirstName(personOnMembership.firstName || "");
                setLastName(personOnMembership.lastName || "");

                // Check if this person has a previous membership - get info from there
                if (!membership?.personName && fullNameFormatted) {
                    getPreviousMembershipByPersonName(fullNameFormatted);
                }
                // Check if the email has not been filled out yet, then try to pre-populate it
                if (!membership?.personEmail && personOnMembership.email) {
                    setEmail(personOnMembership.email);
                }
            }
        }
    }

    const clearForm = () => {
        setRider(undefined);
        setPersonInfo(undefined);
        setFirstName("");
        setLastName("");
        setEmail("");
        setBirthDate("");
        setBarn(undefined);
        setGradeLevel("");
        setAELDivision("");
        setBackNumber(0);
    }

    const getPrePopulatedData = async () => {
        // Check if there is already a membership - pull in data from it
        if (membership) {
            if (membership.backNumber) setBackNumber(membership.backNumber);
            if (membership.gradeLevel) setGradeLevel(membership.gradeLevel);
            if (membership.aelDivision) setAELDivision(membership.aelDivision);
            if (membership.teamId) setSelectedTeamId(membership.teamId);
            const personIdOnMembership = membership.personId;
            if (personIdOnMembership) {
                const queryResult = await getPersonByPersonId(personIdOnMembership);
                if (queryResult.isSuccess) {
                    const person = queryResult.result;
                    setSelectedPerson(person);
                    await getPrePopulatedDataForIndividualMembership(person);
                }
            }
        } else {
            await getPrePopulatedDataForIndividualMembership();
        }
    };

    useEffect(() => {
        if (organization) getOrganizationMemberships(organization);
        getPrePopulatedData();
    }, [membershipType, organization]);

    useEffect(() => {
        if (membership) {
            getPrePopulatedData();
        } else {
            setSelectedTeamId("");
            clearForm();
        }

    }, [membership, memberships]);

    useEffect(() => {
        if (organization?.id === "ddc2fd3c-0bde-4b39-bbbb-5e00d2a35ba0") setIsAELOrganization(true);
    }, [organization]);

    const handleCreateNewPerson = async () => {
        const input: CreatePersonInput = {
            firstName: firstName,
            lastName: lastName,
            email: "",
            isVerified: false,
            roles: "rider;",
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
        const createPersonResult = await createPerson(input);
        if (createPersonResult.isSuccess) {
            const newPerson: Person = createPersonResult.result;
            setSelectedPerson(() => newPerson);

            // Create Personal Information for the New Person
            const personalInfoInput: CreatePersonalInformationInput = {
                personId: newPerson.id
            };
            await createPersonalInformation(personalInfoInput);

            return newPerson;
        } else {
            const message = "Sorry, an error occurred. The new person could not be created.";
            setError(message);
        }
    }

    const handlePersonalInformation = async (personOnMembership: Person) => {
        try {
            // First, check if we are collecting date of birth 
            if (membershipType.applicationFields?.dateOfBirth) {
                // Check if the person needs personal information updated or created
                // Note - if a New Person was created - check if the personalInformationId would be present
                let personOnMembershipInfoId = personOnMembership?.personalInformationId || personInfo?.id || "";
                if(personOnMembershipInfoId){
                    const personalInformationInput: UpdatePersonalInformationInput = {
                        id: personOnMembershipInfoId,
                        dateOfBirth: birthDate
                    };
                    const updatePersonInfoResult = await updatePersonalInformation(personalInformationInput);
                    if (updatePersonInfoResult.isSuccess) {
                        setPersonInfo(updatePersonInfoResult.result);
                    } else {
                        setError(updatePersonInfoResult.message);
                    }
                } else {
                    const personalInformationInput: CreatePersonalInformationInput = {
                        personId: personOnMembership.id,
                        dateOfBirth: birthDate
                    };
                    const createPersonInfoResult = await createPersonalInformation(personalInformationInput);
                    if (createPersonInfoResult.isSuccess) {
                        setPersonInfo(createPersonInfoResult.result);
                    } else {
                        setError(createPersonInfoResult.message);
                    }
                }
            }
        } catch (error) {
            const message = "Could not save the personal information: " + error;
            setError(message);
        }
    }

    const handleRider = async (personOnMembership: Person) => {
        try {
            const age = birthDate ? moment().diff(birthDate, 'years') : undefined;
            if (rider) {
                let riderInput: UpdateRiderInput = {
                    id: rider.id,
                    name: createFullName(firstName, lastName)
                };
                if (birthDate) riderInput["birthdate"] = birthDate;
                if (age) riderInput["age"] = age;
                if (barn) {
                    riderInput["barnId"] = barn.id;
                    riderInput["barnName"] = barn.name;
                }
                const updateRiderResult = await updateRider(riderInput);
                if (updateRiderResult.isSuccess) {
                    setRider(updateRiderResult.result);
                    return updateRiderResult.result;
                } else {
                    console.error(updateRiderResult.message);
                }
            } else {
                let riderInput: CreateRiderInput = {
                    name: createFullName(firstName, lastName),
                    personId: personOnMembership.id || selectedPerson?.id || personId,
                    createdBy: personId,
                    createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                    updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                };
                if (birthDate) riderInput["birthdate"] = birthDate;
                if (age) riderInput["age"] = age;
                if (barn) {
                    riderInput["barnId"] = barn.id;
                    riderInput["barnName"] = barn.name;
                }
                const createRiderResult = await createRider(riderInput);
                if (createRiderResult.isSuccess) {
                    setRider(createRiderResult.result);
                    return createRiderResult.result;
                } else {
                    console.error(createRiderResult.message);
                }
            }
        } catch (error) {
            const message = "Could not save the rider information: " + error;
            setError(message);
        }
    }

    const getDateMembershipEnds = () => {
        try {
            const schedule =  membershipType.schedule;
            const isStartAtTimeOfPurchase = membershipType.isStartAtTimeOfPurchase;
            const setStartDate = membershipType.setStartDate;
            let dateMembershipEnds;
            if(schedule === MembershipTypeSchedule.ANNUAL) {
                if(isStartAtTimeOfPurchase) {
                    // End date should be one year after today's date (because the membership starts on the date of purchase)
                    dateMembershipEnds = moment().add(1, "year").format("YYYY-MM-DD");
                } else {
                    // End date should be one year after the set start date
                    dateMembershipEnds = moment(setStartDate).year((new Date()).getFullYear()).add(1, "year").format("YYYY-MM-DD");
                }
            } else if (schedule === MembershipTypeSchedule.LIFE) {
                dateMembershipEnds = "";
            }
            return dateMembershipEnds;
        } catch (error) {
            const message = "Could not get the date the membership should end: " + error;
            setError(message);
        }
    }

    const handleUpdateMembershipType = async () => {
        // Check if the nextAvailableMembershipId needs to be updated
        if(membershipType.applicationOptions?.autoAssignIds && membershipType?.membershipIdValues) {
            const newMembershipId = getNextAvailableMembershipId(membershipType?.membershipIdValues, membershipType.nextAvailableMembershipId || "");
            const updateInput:  UpdateOrganizationMembershipTypeInput = {
                id: membershipType.id,
                nextAvailableMembershipId: newMembershipId
            };
            await updateOrganizationMembershipType(updateInput);
        }
    }

    const handleCreateMembership = async (personOnMembership: Person, riderData: Rider) => {
        try {
            const dateMembershipEnds = getDateMembershipEnds();
            let input: CreateMembershipInput = {
                name: organization && organization.name ? organization.name : "",
                membershipId: membershipType.nextAvailableMembershipId || "",
                membershipStatus: CONSTANT.MEMBERSHIP.APPLICATION_STATUS.IN_PROGRESS,
                organizationMembershipTypeId: membershipType.id,
                type: membershipType.name,
                amountPaid: 0,
                organizationName: organization?.name,
                organizationId: organization?.id,
                riderId: riderData && riderData.id ? riderData.id : null,
                dateMembershipEnds: dateMembershipEnds,
                personId: personOnMembership.id || selectedPerson?.id || personId,
                personName: createFullName(firstName, lastName),
                teamId: selectedTeamId || "",
                createdBy: personId
            };
            if (backNumber) input["backNumber"] = backNumber;
            if (membershipType.applicationFields?.barnName && barn) input["barnId"] = barn.id;
            if (gradeLevel) input["gradeLevel"] = gradeLevel;
            if (aelDivision) input["aelDivision"] = aelDivision;
            if (teamRole) input["aelTeamRole"] = teamRole;
            if (email) input["personEmail"] = email;
            const createMembershipResult = await createMembership(input);
            if (createMembershipResult.isSuccess) {
                await handleUpdateMembershipType();
                setMemberships([createMembershipResult.result]);
            } else {
                setError(createMembershipResult.message);
            }
        } catch (error) {
            const message = "Could not create the membership: " + error;
            setError(message);
        }
    }

    const handleUpdateMembership = async (membership: Membership, riderData: Rider) => {
        try {
            let input: UpdateMembershipInput = {
                id: membership.id,
                name: organization && organization.name ? organization.name : "",
                organizationMembershipTypeId: membershipType.id,
                type: membershipType.name,
                organizationName: organization?.name,
                organizationId: organization?.id,
                riderId: riderData && riderData.id ? riderData.id : null,
                personId: selectedPerson?.id || personId,
                personName: createFullName(firstName, lastName),
                teamId: selectedTeamId || "",
            };
            if (backNumber) input["backNumber"] = backNumber;
            if (membershipType.applicationFields?.barnName && barn) input["barnId"] = barn.id;
            if (gradeLevel) input["gradeLevel"] = gradeLevel;
            if (aelDivision) input["aelDivision"] = aelDivision;
            if (teamRole) input["aelTeamRole"] = teamRole;
            if (email) input["personEmail"] = email;
            const updateMembershipResult = await updateMembership(input);
            if (updateMembershipResult.isSuccess) {
                setMemberships([updateMembershipResult.result]);
            } else {
                setError(updateMembershipResult.message);
            }
        } catch (error) {
            const message = "Could not update the membership: " + error;
            setError(message);
        }
    }

    const handleMembership = async (personOnMembership: Person, riderData: Rider) => {
        if (membership) {
            await handleUpdateMembership(membership, riderData);
        } else {
            await handleCreateMembership(personOnMembership, riderData);
        }
    }

    const verifyIsValid = () => {
        if (!firstName) {
            setError("A required field is missing data: First Name");
            return false;
        }
        if (!lastName) {
            setError("A required field is missing data: Last Name");
            return false;
        }
        if(membershipType.applicationFields?.dateOfBirth && birthDate === "") {
            setError("A required field is missing data: Birth Date");
            return false;
        }
        if(membershipType.applicationFields?.gradeLevel && gradeLevel === "-") {
            setError("A required field is missing data: Grade Level");
            return false;
        }
        if(isAELOrganization && !membershipType.name.toLowerCase().includes("coach") && aelDivision === "") {
            setError("A required field is missing data: Division");
            return false;
        }
        if(isAELOrganization && membershipType.name.toLowerCase().includes("coach") && !teamRole) {
            setError("A required field is missing data: Member Role");
            return false;
        }
        return true;
    };

    const handleSubmit = async () => {
        const isValid = verifyIsValid();
        if (isValid) {
            setIsLoading(true);
            try {
                // Normally, the Person on the membership will be the currently logged in user
                // But, it could be someone that was selected from the Select Person dropdown (ex: for AEL memberships)
                let personOnMembership = selectedPerson || user;
                
                // AEL - Check for New Person selected
                if (isAELOrganization && !selectedPerson) {
                    personOnMembership = await handleCreateNewPerson();
                }

                // There must be a person to connect the membership back to
                if (personOnMembership) {
                    // First, create/update the PersonalInformation Object
                    await handlePersonalInformation(personOnMembership);
                    // Second, create/update the Rider Object
                    const riderData = await handleRider(personOnMembership);
                    // Third, create/update the membership Object
                    await handleMembership(personOnMembership, riderData); 
                }
            } catch (error: any) {
                const message = "Could not create or update the membership: " + error;
                setError(message);
            }
            setIsLoading(false);   
        }
    };

    return (
        <div className={isDisabled ? "bg-light" : ""}>
            {isLoading ?
                <Spinner />
                :
                <>
                    {error && <ErrorAlert error={error} />}

                    {/* If AEL, ask which person this membership will be for */}
                    {isAELOrganization && (
                        <>
                            <IonRow className="mt-0 mb-3">
                                <IonCol>
                                    {!isDisabled && <WarningBanner warning="If you cannot find your team, do NOT complete the application. You must wait until your coach completes the team registration." />}
                                    <SelectTeam selectedValue={selectedTeamId} isDisabled={isDisabled} onSelect={(team: Team) => setSelectedTeamId(team.id)} />
                                </IonCol>
                            </IonRow>

                            <hr />

                            <IonRow className="mb-3">
                                <IonCol sizeXs="12" sizeMd="12">
                                    <SelectPersonForMembership 
                                        user={user}
                                        membership={membership}
                                        selectedValue={selectedPerson?.id}
                                        includeNewPersonOption={true}
                                        onSelect={handleSelectPerson}
                                        isDisabled={!(!!selectedTeamId) || isDisabled}
                                    />
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                    {/* Individual Membership - Collect name and birthday */}
                    <IonRow className="mb-3">
                        {membershipType.applicationFields?.firstName && (
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white">
                                    <IonLabel position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                                        Member's First Name{" "}
                                        <RequiredInputIndicator />
                                    </IonLabel>
                                    <IonInput
                                        id="membership-app-basicInfo-firstName"
                                        type="text"
                                        value={firstName}
                                        aria-required={true}
                                        disabled={(isAELOrganization && !selectedTeamId) || isDisabled}
                                        onIonChange={(e) => {
                                            setError("");
                                            setFirstName(e.detail.value!)
                                            // handleEditFullName(e.detail.value!);
                                        }}
                                    />
                                </IonItem>
                            </IonCol>
                        )}
                        {membershipType.applicationFields?.lastName && (
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white">
                                    <IonLabel position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                                        Member's Last Name{" "}
                                        <RequiredInputIndicator />
                                    </IonLabel>
                                    <IonInput
                                        id="membership-app-basicInfo-lastName"
                                        type="text"
                                        value={lastName}
                                        disabled={(isAELOrganization && !selectedTeamId) || isDisabled}
                                        aria-required={true}
                                        onIonChange={(e) => {
                                            setError("");
                                            setLastName(e.detail.value!)
                                            // handleEditFullName(e.detail.value!);
                                        }}
                                    />
                                </IonItem>
                            </IonCol>
                        )}
                        {membershipType && membershipType.applicationFields?.dateOfBirth && (
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white">
                                    <IonLabel position="stacked">
                                        Member's Birth Date
                                        <RequiredInputIndicator />
                                    </IonLabel>
                                    <IonInput 
                                        id="membership-app-basicInfo-birth-date"
                                        type="date"
                                        value={birthDate}
                                        disabled={(isAELOrganization && !selectedTeamId) || isDisabled}
                                        aria-required={true}
                                        onIonChange={e => {
                                            setBirthDate(e.detail.value!)
                                        }}
                                    />
                                </IonItem>
                            </IonCol>
                        )}
                        {membershipType && membershipType.applicationFields?.gradeLevel && (
                            <IonCol sizeXs="12" sizeMd="6">
                                <GradeLevel
                                    isDisabled={(isAELOrganization && !selectedTeamId) || isDisabled}
                                    onSelect={setGradeLevel}
                                    selectedValue={gradeLevel}
                                />
                            </IonCol>
                        )}
                        {isAELOrganization && !membershipType.name.toLowerCase().includes("coach") && (
                            <IonCol sizeXs="12" sizeMd="6">
                                <AELDivisionSelect
                                    isDisabled={(isAELOrganization && !selectedTeamId) || isDisabled}
                                    onSelect={setAELDivision}
                                    selectedValue={aelDivision}
                                />
                            </IonCol>
                        )}
                        {isAELOrganization && membershipType.name.toLowerCase().includes("coach") && (
                            <IonCol sizeXs="12" sizeMd="6">
                                <AELCoachTypeSelect
                                    isDisabled={(isAELOrganization && !selectedTeamId) || isDisabled}
                                    onSelect={setTeamRole}
                                    selectedValue={teamRole}
                                />
                            </IonCol>
                        )}
                        {membershipType && membershipType.applicationFields?.barnName && (
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonLabel className="pl-3 text-darker" position="stacked">
                                    Barn{" "}
                                </IonLabel>
                                <SelectFromAllBarns
                                    barn={barn}
                                    selectedValue={barn?.id}
                                    isHeightRequired={true}
                                    height={"45px"}
                                    isDisabled={(isAELOrganization && !selectedTeamId) || isDisabled}
                                    onSelect={(barn: Barn) =>
                                        handleSelectBarn(barn)
                                    }
                                />
                            </IonCol>
                        )}
                        {membershipType && membershipType.applicationFields?.backNumber && (
                            <IonCol sizeXs="12" sizeMd="12">
                                <IonItem color="white">
                                    <IonLabel position="stacked">
                                        {isAELOrganization ? 
                                            <span>Member's Arm Number</span>
                                            :
                                            <span>Member's Back Number(s)</span>
                                        }
                                    </IonLabel>
                                    <IonInput 
                                        type="number"
                                        value={backNumber}
                                        aria-required={true}
                                        disabled={(isAELOrganization) ? ((!!selectedTeamId) ? isDisabled : true) : true}
                                        onIonChange={e => {
                                            setBackNumber(e.detail.value! ? parseInt(e.detail.value!) : 0);
                                        }}
                                    />
                                </IonItem>
                                {isAELOrganization ? 
                                    <p className="ion-text-wrap description">If the member has an arm number already, please enter it. If you do not know the arm number, it can be assigned later.</p>
                                    :
                                    <p className="ion-text-wrap description">(You cannot edit back numbers. The admin will handle this. This will show at most one back number - the admin will add any additional back numbers later.)</p>
                                }
                            </IonCol>
                        )}
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                            <IonButton
                                id="mt-single-member-info-save-btn"
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                                disabled={(isAELOrganization && !selectedTeamId) || isDisabled}
                            >
                                Save
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
            }
        </div>
    );
};

export default SingleMemberInfo;
