import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Spinner from "../Spinners/Spinner";
import { EventEntry, Membership, Person, Rider } from "../../models";
import { Table } from "reactstrap";
import moment from "moment";
import { createRider, deleteRider, getRidersByPersonId, getRidersByPersonName, updateRider } from "../../utilities/rider/Rider";
import { formatDisplayName, formatDisplayNameForUser } from "../../utilities/person/PersonNameFormat";
import { getPersonByPersonId } from "../../utilities/person/Person";
import { formatAddress } from "../../utilities/address/FormatAddress";
import { getEventEntriesByRiderId } from "../../utilities/eventEntry/EventEntry";
import { CreateRiderInput } from "../../API";
import { close, createOutline, trashBinOutline } from "ionicons/icons";
import { deleteContact } from "../../utilities/contact/Contact";
import { deleteAddress } from "../../utilities/address/Address";
import EditRiderForm from "./EditRiderForm";
import { getMembershipsByRiderId } from "../../utilities/membership/Membership";

interface FormattedRider {
    riderId: string
    riderName: string
    rider: Rider
    personId: string
    personName: string
    createdById: string
    createdByName: string
    birthdate: string
    age: string
    isProfessional: string
    barnId: string
    barnName: string
    contactId: string
    contactString: string
    location: string
    addressId: string
    addressString: string
    memberships: string
    entryCount: string,
    entries: string,
    showEntries: boolean,
    createdOn: string
    updatedOn: string
}
interface _Props {
    person: Person
}

const tableColumns = [
    "Delete",
    "Edit",
    "Rider (Id)",
    "Rider (Name)",
    "Person (Id)", 
    "Person (Name)", 
    "Created By (Id)", 
    "Created By (Name)", 
    "Birthdate",
    "Age",
    "Is Professional?",
    "Barn Id",
    "Barn Name",
    "Contact Id",
    "Contact",
    "Location",
    "Address Id",
    "Address",
    "Memberships",
    "Entries",
    "Created On",
    "Updated On"
];

const AdminEditRidersTable: React.FC<_Props> = ({person}) => {    
    
    const [formattedRiders, setFormattedRiders] = useState<FormattedRider[] | undefined | null>();  
    const [selectedFormattedRider, setSelectedFormattedRiders] = useState<FormattedRider | undefined | null>();     
    const [isLoading, setIsLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const getRiderMemberships = async (rider: Rider) => {
        const queryResult = await getMembershipsByRiderId(rider.id);
        if (queryResult.isSuccess) {
            const membershipList: Membership[] = queryResult.result;
            return membershipList;
        } else {
            return [];
        }
    }

    const getRiders = async (person: Person) => {
        setIsLoading(true);
        let allRiders: Rider[] = [];

        // Get riders that match this person's id
        const ridersQueryResult = await getRidersByPersonId(person.id);
        if (ridersQueryResult.isSuccess) {
            const riderArray: Rider[] = ridersQueryResult.result;
            allRiders = allRiders.concat(riderArray);
        }

        // Get riders that match 
        const name = formatDisplayName(person.firstName || "", "", person.lastName || "");
        const ridersByNameQueryResult = await getRidersByPersonName(name);
        if (ridersByNameQueryResult.isSuccess) {
            const riderArray: Rider[] = ridersByNameQueryResult.result;
            riderArray.forEach((rider) => {
                const foundRiderInAllArray = allRiders.find((r: Rider) => (rider.id === r.id));
                if (!foundRiderInAllArray) {
                    allRiders.push(rider);
                }
            });
        }

        const formattedRiderList = await formatRiders(allRiders);
        setFormattedRiders(formattedRiderList);

        setIsLoading(false);
    }

    const formatRiders = async (allRiders: Rider[]) => {
        let formattedRiders: FormattedRider[] = [];
        for (let i = 0; i < allRiders.length; i++) {
            const currentRider = allRiders[i];
            const formattedRider: FormattedRider = await formatRider(currentRider);
            formattedRiders.push(formattedRider);
        }
        return formattedRiders;
    }

    const formatRider = async (currentRider: Rider) => {
        const personId = currentRider.personId;
        let personName = "";
        if (personId) {
            if (personId === person.id) {
                personName = formatDisplayName(person.firstName || "", "", person.lastName || "");
            }
            else {
                const getPersonQueryResult = await getPersonByPersonId(personId);
                if (getPersonQueryResult.isSuccess) {
                    const newPerson: Person = getPersonQueryResult.result;
                    personName = formatDisplayName(newPerson.firstName || "", "", newPerson.lastName || "");
                }
            }
        }

        const createdById = currentRider.createdBy;
        let createdByName = "";
        if (createdById) {
            if (createdById === person.id) {
                createdByName = formatDisplayName(person.firstName || "", "", person.lastName || "");
            }
            else {
                const getPersonQueryResult = await getPersonByPersonId(createdById);
                if (getPersonQueryResult.isSuccess) {
                    const newPerson: Person = getPersonQueryResult.result;
                    createdByName = formatDisplayName(newPerson.firstName || "", "", newPerson.lastName || "");
                }
            }
        }

        const formattedContact = 
        "Personal Email: " + currentRider.contact?.personalEmail +
        "\nCell: " + currentRider.contact?.cell +
        "\nHome: " + currentRider.contact?.home +
        "\nWork: " + currentRider.contact?.work +
        "\nMailing: " + currentRider.contact?.mailingAddress;

        const fomattedAddress = currentRider.address ? formatAddress(currentRider.address) : "";

        let memberships = "";
        if (currentRider.memberships && currentRider.memberships.length > 0) {
            currentRider.memberships?.forEach((membership) => {
                memberships = memberships + (membership?.name || "") + "; ";
            })
        }
        if (memberships.length === 0) {
            const membershipList: Membership[] = await getRiderMemberships(currentRider);
            if (membershipList && membershipList.length > 0) {
                membershipList.forEach((membership) => {
                    memberships = memberships + (membership?.name || "") + "; ";
                })
            }
        }

        let entries = "";
        let entryCount = "";
        const entriesQuery = await getEventEntriesByRiderId(currentRider.id);
        if (entriesQuery.isSuccess) {
            const entriesList: EventEntry[] = entriesQuery.result;
            if (entriesList && entriesList.length > 0) {
                entryCount = "# entries: " + entriesList.length.toString();
                entriesList.forEach((entry) => {
                    entries = entries + entry.eventId + ";\n";
                })
            }
        }

        let formattedRider: FormattedRider = {
            riderId: currentRider.id,
            riderName: currentRider.name,
            rider: currentRider,
            personId: personId || "",
            personName: personName,
            createdById: createdById || "",
            createdByName: createdByName,
            birthdate: currentRider.birthdate || "",
            age: currentRider.age ? currentRider.age.toString() : "",
            isProfessional: currentRider.isProfessional ? "Yes" : "No",
            barnId: currentRider.barnId || "",
            barnName: currentRider.barnName || "",
            contactId: currentRider.contact?.id || "",
            contactString: formattedContact,
            location: currentRider.location || "",
            addressId: currentRider.addressId || "",
            addressString: fomattedAddress,
            memberships: memberships,
            entryCount: entryCount,
            entries: entries,
            showEntries: false,
            createdOn: moment(currentRider.createdOn).format("MM-DD-YYYY"),
            updatedOn: moment(currentRider.updatedOn).format("MM-DD-YYYY")
        };
        return formattedRider;
    }

    useEffect(() => {
        if (person) {
            getRiders(person);
        }
    }, [person]);

    const handleClickShowEntries = (formattedRider: FormattedRider) => {
        const updatedFormattedRider: FormattedRider = {
            ...formattedRider,
            showEntries: !formattedRider.showEntries
        };
        if (formattedRiders) {
            const index = formattedRiders?.findIndex(x => formattedRider.riderId === x.riderId);
            if (index > -1) {
                const updatedList: FormattedRider[] = [
                    ...formattedRiders.slice(0, index),
                    updatedFormattedRider,
                    ...formattedRiders.slice(index + 1),
                ];
                setFormattedRiders(updatedList);
            }
        }
    }

    const handleCreateRider = async () => {
        setIsLoading(true);
        let name = formatDisplayNameForUser(person);
        const riderInput: CreateRiderInput = {
            name: name,
            createdBy: person.id,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        }
        const createResult = await createRider(riderInput);
        if (createResult.isSuccess) {
            const newRider: Rider = createResult.result;
            const newFormattedRider = await formatRider(newRider);
            const updatedFormattedRiders = (formattedRiders || []).concat([newFormattedRider]);
            setFormattedRiders(updatedFormattedRiders);
        }
        setIsLoading(false);
    }
    
    const handleEditRider = async (r: Rider) => {
        setShowEditModal(false);
        setIsLoading(true);
        if (formattedRiders) {
            const index = formattedRiders?.findIndex(formattedRider => formattedRider.riderId === r.id);
            if (index > -1) {
                const updatedFormattedRider = await formatRider(r);
                const updatedList: FormattedRider[] = [
                    ...formattedRiders.slice(0, index),
                    updatedFormattedRider,
                    ...formattedRiders.slice(index + 1),
                ];
                setFormattedRiders(updatedList);
            }
        }
        setIsLoading(false);
    }

    const handleDeleteRider = async () => {
        setShowDeleteModal(false);
        setIsLoading(true);
        if (selectedFormattedRider) {
            // Wait to Delete the attached contact info - might be attached to other things
            // const contactId = selectedFormattedRider.contactId;
            // if (contactId) {
            //     await deleteContact({id: contactId});
            // }

            // Delete the attached address info - might be attached to other things
            // const addressId = selectedFormattedRider.addressId;
            // if (addressId) {
            //     await deleteAddress({id: addressId});
            // }

            // Delete the rider
            const riderId = selectedFormattedRider.riderId;
            if (riderId) {
                const deleteResult = await deleteRider({id: riderId});

                // Update the Rider Table
                if (deleteResult.isSuccess) {
                    if (formattedRiders) {
                        const index = formattedRiders?.findIndex(formattedRider => formattedRider.riderId === riderId);
                        if (index > -1) {
                            const updatedList: FormattedRider[] = [
                                ...formattedRiders.slice(0, index),
                                ...formattedRiders.slice(index + 1),
                            ];
                            setFormattedRiders(updatedList);
                        }
                    }
                }
            }
        }
        setIsLoading(false);
    }

    return (
        <>
            <IonCard mode="md" className="ion-padding bg-white">
                <IonCardTitle>Rider</IonCardTitle>
                <IonCardSubtitle>
                    <IonRow>
                        <IonCol sizeXs="12" sizeMd="6">
                            <p className="description">Get Riders by personId and personName.</p>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="6" className="ion-text-right">
                            <IonButton color="primary" onClick={() => handleCreateRider()}>
                                Add Rider
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonCardSubtitle>
                <IonCardContent>
                    {isLoading ?
                        <Spinner />
                        :
                        <>
                            {(formattedRiders && formattedRiders.length > 0) ?
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            {tableColumns.map((tc, i) => (
                                                <th key={i}>{tc}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formattedRiders.map((formattedRider, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <IonIcon icon={trashBinOutline} color="danger" onClick={() => {setSelectedFormattedRiders(formattedRider);setShowDeleteModal(true);}} />
                                                </td>
                                                <td>
                                                    <IonIcon icon={createOutline} color="tertiary" onClick={() => {setSelectedFormattedRiders(formattedRider);setShowEditModal(true);}} />
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedRider.riderId}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedRider.riderName}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedRider.personId}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedRider.personName}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedRider.createdById}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedRider.createdByName}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedRider.birthdate}</p>
                                                </td>
                                                <td>
                                                    <p className={(formattedRider.age ? "ion-text-danger" : "") + "ion-text-wrap"}>{formattedRider.age || (formattedRider.birthdate ? moment().diff(formattedRider.birthdate, 'years') : "")}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedRider.isProfessional}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap"><a href={"/index/barn/info/" + formattedRider.barnId}>{formattedRider.barnId}</a></p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedRider.barnName}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedRider.contactId}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedRider.contactString}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedRider.location}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedRider.addressId}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedRider.addressString}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedRider.memberships}</p>
                                                </td>
                                                <td onClick={() => handleClickShowEntries(formattedRider)}>
                                                    <p className="ion-text-wrap">{formattedRider.entryCount}</p>
                                                    {formattedRider.showEntries && (
                                                        <p className="ion-text-wrap">{formattedRider.entries}</p>
                                                    )}
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedRider.createdOn}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedRider.updatedOn}</p>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                :
                                <p>No riders found.</p>
                            }
                        </>
                    }
                </IonCardContent>
            </IonCard>
            {/* Delete Modal */}
            <IonModal backdropDismiss={false} isOpen={showDeleteModal} id="adminDeleteRiderFromTableModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Delete Rider
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowDeleteModal(false)}
                        >
                            <p id="closeAdminDeleteRiderFromTableModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <IonRow>
                        <IonCol>
                            <h4>Are you sure you want to delete the rider: </h4>
                            <p>Rider Id: {selectedFormattedRider?.riderId}</p>
                            <p>Rider Name: {selectedFormattedRider?.riderName}</p>
                            <p>Person Id: {selectedFormattedRider?.personId}</p>
                            <p>Person Name: {selectedFormattedRider?.personName}</p>
                            <p>Created By: {selectedFormattedRider?.createdByName}</p>
                            <p>Created On: {selectedFormattedRider?.createdOn}</p>
                            <p>Updated On: {selectedFormattedRider?.updatedOn}</p>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6">
                            <IonButton
                                expand="block"
                                color="light"
                                onClick={() => setShowDeleteModal(false)}
                            >
                                Cancel
                            </IonButton>
                        </IonCol>
                        <IonCol size="6">
                            <IonButton
                                expand="block"
                                color="danger"
                                onClick={() => handleDeleteRider()}
                            >
                                Delete
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonContent>
            </IonModal>
            {/* Edit Modal */}
            <IonModal backdropDismiss={false} isOpen={showEditModal} id="adminEditRiderFromTableModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Edit Rider
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowEditModal(false)}
                        >
                            <p id="closeAdminEditRiderFromTableModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {(selectedFormattedRider && selectedFormattedRider.rider) ?
                        <EditRiderForm rider={selectedFormattedRider.rider} onChange={(r: Rider) => handleEditRider(r)} />
                        :
                        <p>No rider found.</p>
                    }
                </IonContent>
            </IonModal>
        </>
    );
};

export default AdminEditRidersTable;