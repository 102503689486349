import moment from "moment";
import { Event, EventClass, EventClassEntry, EventDivision, EventResult, PointTable } from "../../models";
import { getEventClassEntryById } from "../eventClassEntry/EventClassEntry";
import { getEventDivisionsByEventId } from "../eventDivision/EventDivision";
import { getEventResultsByEventClassIdWithPointTables, getEventResultsByEventDivisionId } from "../eventResult/EventResult";
import { getEventClassesByEventDivisionId } from "../eventClass/EventClass";
import { getPointTableById } from "../pointTable/PointTable";
import { getRiderById } from "../rider/Rider";
import { addFooters } from "./ReportFooter";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

export async function generateBasicDivisionResultsReport(event: Event) {
    // initialize jsPDF
    const doc = new jsPDF();

    // page title. and margin-top + margin-left
    doc.text(`${event.name}`, 14, 15);
    doc.setFontSize(10);
    doc.text(`Division Results Report`, 14, 20);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);

    // define the columns we want and their titles
    const tableColumn = ["Division Name", "Place", "Entry Number", "Horse", "Rider", "Trainer"];
    // define an empty array of rows
    const tableRows: string[][] = [];

    const queryResult = await getEventDivisionsByEventId(event.id);
    if (queryResult.isSuccess) {
        const eventDivisions = queryResult.result as EventClass[];
        if (eventDivisions && eventDivisions.length > 0) {
            for (let i = 0; i < eventDivisions.length; i++) {
                const eventDivision = eventDivisions[i];
                const dataResult = await getEventResultsByEventDivisionId(eventDivision.id);
                if (dataResult.isSuccess) {
                    const eventDivisionResults = dataResult.result;
                    if (eventDivisionResults.length > 0) {
                        const sortedResults = eventDivisionResults.sort((a: EventResult, b: EventResult) => (a.place || 0) - (b.place || 0));
                        for (let j = 0; j < sortedResults.length; j++) {
                            const result: EventResult = sortedResults[j];
                            let riderName = result.entry.riderName;
                            if (result.eventClassEntryId) {
                                const eventDivisionEntryResult = await getEventClassEntryById(result.eventClassEntryId);
                                if (eventDivisionEntryResult.isSuccess) {
                                    const eventDivisionEntry: EventClassEntry = eventDivisionEntryResult.result;
                                    if (eventDivisionEntry.rider?.name) riderName = eventDivisionEntry.rider?.name;
                                }
                            }
                            const row = [
                                eventDivision.name || "",
                                result.place?.toString() || "",
                                result.entry.number?.toString() || "",
                                result.entry.horseName || "",
                                riderName || "",
                                result.entry.trainerName || ""
                            ];
                            tableRows.push(row);
                        }
                    }
                };
            }
        }
    }
     
    // startY is basically margin-top
    doc.autoTable(tableColumn, tableRows, { 
        theme: "grid",
        headStyles: {fillColor: "#73a4d3"},
        startY: 35 
    });

    // page footer
    doc.setFontSize(10);
    doc.text("Created using RingSide Pro: www.ringsidepro.com", 14, doc.internal.pageSize.height - 10);

    // we define the name of our PDF file.
    doc.save(`division_results.pdf`);
}

interface totalRow {
    pointTotal: number
    entryNumber: number
    riderName: string
    horseName: string
}

const calculatePoints = (placeValue: number, currentPointTable?: PointTable) => {
    if (currentPointTable) {
        let pointValue = "0";
        if (currentPointTable && currentPointTable.tiers && currentPointTable.tiers[0]) {
            const tier = currentPointTable.tiers[0];
            if (placeValue === 1) pointValue = tier["first"] || "0";
            if (placeValue === 2) pointValue = tier["second"] || "0";
            if (placeValue === 3) pointValue = tier["third"] || "0";
            if (placeValue === 4) pointValue = tier["fourth"] || "0";
            if (placeValue === 5) pointValue = tier["fifth"] || "0";
            if (placeValue === 6) pointValue = tier["sixth"] || "0";
            if (placeValue === 7) pointValue = tier["seventh"] || "0";
            if (placeValue === 8) pointValue = tier["eighth"] || "0";
            if (placeValue === 9) pointValue = tier["ninth"] || "0";
            if (placeValue === 10) pointValue = tier["tenth"] || "0";
            if (placeValue === 11) pointValue = tier["eleventh"] || "0";
            if (placeValue === 12) pointValue = tier["twelfth"] || "0";
        }
        return parseFloat(pointValue);
    }
}
export async function generateDivisionPointTotalsReport(event: Event, callbackFunction?: Function) {
    // initialize jsPDF
    const doc = new jsPDF();

    // page title. and margin-top + margin-left
    doc.text(`${event.name}`, 14, 15);
    doc.setFontSize(10);
    doc.text(`Division Point Totals Report`, 14, 20);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);

    // define the columns we want and their titles
    const tableColumn = ["Row #", "Division Name", "Point Total", "Entry Number", "Horse", "Rider"];
    let currentYIndex = 35;

    const queryDivisionResult = await getEventDivisionsByEventId(event.id);
    if (queryDivisionResult.isSuccess) {
        const eventDivisions: EventDivision[] = queryDivisionResult.result;
        if (eventDivisions && eventDivisions.length > 0) {
            for (let i = 0; i < eventDivisions.length; i++) {
                const currentEventDivision = eventDivisions[i];

                const progress = (i+1)/eventDivisions.length;
                const progressMsg = (progress*100).toFixed(0) + "% Complete";
                if (callbackFunction) callbackFunction(progressMsg);

                const tableRows: string[][] = [];
                let totalMap: Map<string, totalRow> = new Map();
                let totalRows: totalRow[] = [];

                let eventClasses: EventClass[] = [];
                const queryResult = await getEventClassesByEventDivisionId(currentEventDivision.id);
                if (queryResult.isSuccess) {
                    eventClasses = queryResult.result as EventClass[];
                }

                if (eventClasses.length > 0) { 
                    for (let i = 0; i < eventClasses.length; i++) {
                        const currentEventClass = eventClasses[i];
                        const eventResultsResult = await getEventResultsByEventClassIdWithPointTables(currentEventClass.id);
                        if (eventResultsResult.isSuccess) {
                            const eventResults: EventResult[] = eventResultsResult.result;
                            if (eventResults && eventResults.length > 0) {
                                for (let j = 0; j < eventResults.length; j++) {
                                    const currentResult: EventResult = eventResults[j];
            
                                    // Use the point table assigned to the specific class
                                    let eventClassPointTable = currentEventClass.pointTable;
                                    // Check if there are tiers on the point table from the query. If not, get the full point table with tiers.
                                    if (eventClassPointTable && (!eventClassPointTable.tiers || eventClassPointTable.tiers.length < 1)) {
                                        const queryPointTableResult = await getPointTableById(eventClassPointTable?.id);
                                        if (queryPointTableResult.isSuccess) {
                                            eventClassPointTable = queryPointTableResult.result;
                                        }
                                    }
            
                                    // Get any points already calculated for this entry
                                    const currentMapItem = totalMap.get(currentResult.entryId);
                                    let startingValue = 0;
                                    if (currentMapItem) startingValue = currentMapItem.pointTotal;
                                    
                                    // Get the point total from this result and add it to the entry's total points
                                    let currentPointValue = 0;
                                    if (currentResult.place) {
                                        currentPointValue = calculatePoints(currentResult.place, (eventClassPointTable || undefined)) || 0;
                                    }
            
                                    // Get the total info from the map
                                    const totalResult = totalMap.get(currentResult.entryId);
                                    if (totalResult) {
                                        let newTotalRow: totalRow = {
                                            ...totalResult,
                                            pointTotal: startingValue + currentPointValue,
                                        };
                                        totalMap.set(currentResult.entryId, newTotalRow);
                                    } else {
                                        let riderName = currentResult.eventClassEntry?.rider?.name || "";
                                        if (!riderName && currentResult.eventClassEntry?.riderId) {
                                            const quertRiderResult = await getRiderById(currentResult.eventClassEntry?.riderId);
                                            if (quertRiderResult.isSuccess) {
                                                riderName = quertRiderResult.result?.name || "";
                                            }
                                        }
                                        // Format the total row's data
                                        let newTotalRow: totalRow = {
                                            riderName: riderName,
                                            horseName: currentResult.entry.horseName || "",
                                            pointTotal: startingValue + currentPointValue,
                                            entryNumber: currentResult.entry.number || 0
                                        };
                                        totalMap.set(currentResult.entryId, newTotalRow);
                                    }
                                }
                            } 
                        }
                    }

                    if (totalMap.size > 0) {
                        totalMap.forEach((key) => {
                            totalRows.push(key);
                        });
                        const sorted = totalRows.sort((a, b) => b.pointTotal - a.pointTotal);
    
                        sorted.forEach((row, index) => {
                            const newRow = [
                                (index + 1).toString(),
                                currentEventDivision.name.trim(),
                                row.pointTotal.toString(),
                                row.entryNumber.toString(),
                                row.horseName,
                                row.riderName
                            ];
                            tableRows.push(newRow);
                        })
    
                        doc.autoTable(tableColumn, tableRows, { 
                            theme: "grid",
                            headStyles: {fillColor: "#73a4d3"},
                            rowPageBreak: "avoid", 
                            startY: currentYIndex,
                        });
                    } else {
                        doc.autoTable(tableColumn, [["", currentEventDivision.name, "No results found."]], { 
                            theme: "grid",
                            headStyles: {fillColor: "#73a4d3"},
                            rowPageBreak: "avoid", 
                            startY: currentYIndex,
                        });
                    }
                } else {
                    doc.autoTable(tableColumn, [["", currentEventDivision.name, "No classes found."]], { 
                        theme: "grid",
                        headStyles: {fillColor: "#73a4d3"},
                        rowPageBreak: "avoid", 
                        startY: currentYIndex,
                    });
                }
                currentYIndex = doc.lastAutoTable.finalY + 15;
                if (currentYIndex > 250) {
                    doc.addPage();
                    currentYIndex = 15;
                }
            }
        }
    }

    // page footer
    addFooters(doc);

    // we define the name of our PDF file.
    doc.save(`division_point_totals.pdf`);
}