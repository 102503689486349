import { API } from 'aws-amplify';
import { Result } from "../../interfaces/Result";

const env = process.env.USER_BRANCH || "dev";

/**
 * Check a phone number's data using Twilio
 * More details here: https://www.twilio.com/docs/lookup/api
 * 
 * @param {string} phoneNumber 
 * @param {string} isTest 
 */
export async function handleSendSMS(message: string, tophonenumber: string, isTest: boolean, mediaURL?: string) {
    try {
        const data = {
            body: {
                "message": message,
                "to": tophonenumber,
                "from": "+17572106404",
                "mediaURL": mediaURL,
                "isTest": isTest
            }
        };

        const path = "/sms/send-message";

        const response = await API.post('TwilioAPI', path, data);
        
        const responseBody = JSON.parse(response.body);

        if (responseBody.success) {
            let result: Result = {isSuccess: true, type: "SMS", result: responseBody, message: "Successfully sent the sms message."};
            return result; 
        } else {
            let result: Result = {isSuccess: false, type: "SMS", result: responseBody, message: "Could not send the sms message."};
            return result; 
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "SMS", result: error, message: "Error occurred while trying to send the sms message."};
        return result; 
    }
}

/**
 * Check a phone number's data using Twilio
 * More details here: https://www.twilio.com/docs/lookup/api
 * 
 * @param {string} phoneNumber 
 * @param {string} isTest 
 */
 export async function handleSendMassSMS(alertId: string, phoneNumbers: string[], blockIds: string[], isUnlimited: boolean, message?: string, mediaURL?: string) {
    try {

        const isTest = env === "stage" || env === "dev";

        const data = {
            body: {
                "alertId": alertId,
                "to": phoneNumbers,
                "from": "+17572106404",
                "blocks": blockIds,
                "isUnlimited": isUnlimited,
                "message": message,
                "mediaURL": mediaURL,
                "isTest": isTest
            }
        };

        const path = "/sms/send-mass-message";

        const response = await API.post('TwilioAPI', path, data);
        
        const responseBody = JSON.parse(response.body);

        if (responseBody.success) {
            let result: Result = {isSuccess: true, type: "SMS", result: responseBody, message: "Successfully sent the sms messages."};
            return result; 
        } else {
            let result: Result = {isSuccess: false, type: "SMS", result: responseBody, message: "Could not send the sms messages."};
            return result; 
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "SMS", result: error, message: "Error occurred while trying to send the sms messages."};
        return result; 
    }
}

/**
 * Check a phone number's data using Twilio
 * More details here: https://www.twilio.com/docs/lookup/api
 * 
 * @param {string} phoneNumber 
 * @param {string} isTest 
 */
export async function handleSendCanadianSMS(message: string, tophonenumber: string, isTest: boolean, mediaURL?: string) {
    try {
        const data = {
            body: {
                "message": message,
                "to": tophonenumber,
                "from": "+18662647670",
                "mediaURL": mediaURL,
                "isTest": isTest
            }
        };

        const path = "/sms/send-message";

        const response = await API.post('TwilioAPI', path, data);
        
        const responseBody = JSON.parse(response.body);

        if (responseBody.success) {
            let result: Result = {isSuccess: true, type: "SMS", result: responseBody, message: "Successfully sent the sms message."};
            return result; 
        } else {
            let result: Result = {isSuccess: false, type: "SMS", result: responseBody, message: "Could not send the sms message."};
            return result; 
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "SMS", result: error, message: "Error occurred while trying to send the sms message."};
        return result; 
    }
}

/**
 * Check a phone number's data using Twilio
 * More details here: https://www.twilio.com/docs/lookup/api
 * 
 * @param {string} phoneNumber 
 * @param {string} isTest 
 */
export async function handleSendCanadianMassSMS(alertId: string, phoneNumbers: string[], blockIds: string[], isUnlimited: boolean, message?: string, mediaURL?: string) {
    try {

        const isTest = env === "stage" || env === "dev";

        const data = {
            body: {
                "alertId": alertId,
                "to": phoneNumbers,
                "from": "+18662647670",
                "blocks": blockIds,
                "isUnlimited": isUnlimited,
                "message": message,
                "mediaURL": mediaURL,
                "isTest": isTest
            }
        };

        const path = "/sms/send-mass-message";

        const response = await API.post('TwilioAPI', path, data);
        
        const responseBody = JSON.parse(response.body);

        if (responseBody.success) {
            let result: Result = {isSuccess: true, type: "SMS", result: responseBody, message: "Successfully sent the sms messages."};
            return result; 
        } else {
            let result: Result = {isSuccess: false, type: "SMS", result: responseBody, message: "Could not send the sms messages."};
            return result; 
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "SMS", result: error, message: "Error occurred while trying to send the sms messages."};
        return result; 
    }
}

/**
 * Send SMS message from the GITNB phoneNumber
 * 
 * @param {string} phoneNumber 
 * @param {string} isTest 
 */
export async function handleSendGITNBSMS(message: string, tophonenumber: string, isTest: boolean, mediaURL?: string) {
    try {
        const data = {
            body: {
                "message": message,
                "to": tophonenumber,
                "from": "+17577202507",
                "mediaURL": mediaURL,
                "isTest": isTest
            }
        };

        const path = "/sms/send-message";

        const response = await API.post('TwilioAPI', path, data);
        
        const responseBody = JSON.parse(response.body);

        if (responseBody.success) {
            let result: Result = {isSuccess: true, type: "SMS", result: responseBody, message: "Successfully sent the sms message."};
            return result; 
        } else {
            let result: Result = {isSuccess: false, type: "SMS", result: responseBody, message: "Could not send the sms message."};
            return result; 
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "SMS", result: error, message: "Error occurred while trying to send the sms message."};
        return result; 
    }
}


/**
 * Send mass SMS message from the GITNB phoneNumber
 * 
 * @param {string} phoneNumber 
 * @param {string} isTest 
 */
 export async function handleSendMassGITNBSMS(alertId: string, phoneNumbers: string[], blockIds: string[], isUnlimited: boolean, message?: string, mediaURL?: string) {
    try {

        const isTest = env === "stage" || env === "dev";

        const data = {
            body: {
                "alertId": alertId,
                "to": phoneNumbers,
                "from": "+17577202507",
                "blocks": blockIds,
                "isUnlimited": isUnlimited,
                "message": message,
                "mediaURL": mediaURL,
                "isTest": isTest
            }
        };

        const path = "/sms/send-mass-message";

        const response = await API.post('TwilioAPI', path, data);
        
        const responseBody = JSON.parse(response.body);

        if (responseBody.success) {
            let result: Result = {isSuccess: true, type: "SMS", result: responseBody, message: "Successfully sent the sms messages."};
            return result; 
        } else {
            let result: Result = {isSuccess: false, type: "SMS", result: responseBody, message: "Could not send the sms messages."};
            return result; 
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "SMS", result: error, message: "Error occurred while trying to send the sms messages."};
        return result; 
    }
}

/**
 * Send SMS message from the Org phoneNumber
 * 
 * @param {string} phoneNumber 
 * @param {string} isTest 
 */
 export async function handleSendOrgSMS(message: string, tophonenumber: string, isTest: boolean) {
    try {
        const data = {
            body: {
                "message": message,
                "to": tophonenumber,
                "from": "+15407126447",
                "isTest": isTest
            }
        };

        const path = "/sms/send-message";

        const response = await API.post('TwilioAPI', path, data);
        
        const responseBody = JSON.parse(response.body);

        if (responseBody.success) {
            let result: Result = {isSuccess: true, type: "SMS", result: responseBody, message: "Successfully sent the sms message."};
            return result; 
        } else {
            let result: Result = {isSuccess: false, type: "SMS", result: responseBody, message: "Could not send the sms message."};
            return result; 
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "SMS", result: error, message: "Error occurred while trying to send the sms message."};
        return result; 
    }
}

/**
 * Send mass SMS message from the Org phoneNumber
 * 
 * @param {string} phoneNumber 
 * @param {string} isTest 
 */
 export async function handleSendMassOrgSMS(alertId: string, phoneNumbers: string[], blockIds: string[], isUnlimited: boolean, message?: string, mediaURL?: string) {
    try {

        const isTest = env === "stage" || env === "dev";

        const data = {
            body: {
                "alertId": alertId,
                "to": phoneNumbers,
                "from": "+15407126447",
                "blocks": blockIds,
                "isUnlimited": isUnlimited,
                "message": message,
                "mediaURL": mediaURL,
                "isTest": isTest
            }
        };

        const path = "/sms/send-mass-message";

        const response = await API.post('TwilioAPI', path, data);
        
        const responseBody = JSON.parse(response.body);

        if (responseBody.success) {
            let result: Result = {isSuccess: true, type: "SMS", result: responseBody, message: "Successfully sent the sms messages."};
            return result; 
        } else {
            let result: Result = {isSuccess: false, type: "SMS", result: responseBody, message: "Could not send the sms messages."};
            return result; 
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "SMS", result: error, message: "Error occurred while trying to send the sms messages."};
        return result; 
    }
}