/**
 * These values are specific to RingSide Pro and can be accessed at: https://api.usef.org/dashboard
 * - when the user is logged in as RingSide Pro on the USEF website
 * 
 * Info from USEF about the addition of a SoftwareKey as of 2022: 
 * "To improve security measures, we have released a series of new API calls which now require a unique security key. 
 * Competition management can find their individual software keys in their competition dashboard. 
 * The time frame allowed for utilizing the software key for a specific competition is 60 days before 
 * the start date of the competition through 30 days following the end date of the competition."
 * Note: The only valid Software Key for all Sandbox Calls is: '1a352d46'. 
 * This key can be used with ANY competition ID and year in Sandbox Calls. 
 * This Software Key is NOT valid for Production Calls.
 */

import CryptoJS from "crypto-js";

export const apiURL = "https://api.usef.org/api/cs/production/";
export const clientID = "83fde9d2df274e27a838658f20a7ea46";

export const apiURLTest = "https://api.usef.org/api/cs/sandbox/";
export const clientIDTest = "f3afbb68076c40f3b643bcb8a8f1314a";
export const clientSecretTest = "b2b7f8ff4f1e475081c50b2d8c517f2f";
export const softwareKeyTest = "1a352d46";

export function createUSEFSignature() {
    // Update if not dev
    var hash = CryptoJS.HmacSHA256(clientIDTest, clientSecretTest);
    var hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
    const authHeader = clientIDTest + ":" + hashInBase64;
    return authHeader;
}