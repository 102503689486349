import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
    isPlatform,
} from "@ionic/react";
import React, {useContext, useEffect, useState} from "react";
import { AuditorStatus, CreateAuditorInput, CreateEventAuditorFilterInput, CreateOrganizationAuditorInput, UpdateAuditorInput, UpdateOrganizationAuditorInput } from "../../API";
import { PersonContext } from "../../context/PersonContext";
import { Auditor, Organization, OrganizationAuditor, EventAuditorFilter, EventFilter } from "../../models";
import { createAuditor, getAuditorByPhoneNumber, updateAuditor } from "../../utilities/auditor/Auditor";
import { formatTwilioNumber, isValidPhoneNumber } from "../../utilities/contact/FormatPhoneNumber";
import { createOrganizationAuditor, deleteOrganizationAuditor, getOrganizationAuditorById, getOrganizationAuditorByPhoneNumber, updateOrganizationAuditor } from "../../utilities/organizationAuditor/OrganizationAuditor";
import { createEventAuditorFilter, deleteEventAuditorFilter, getEventAuditorFiltersByOrganizationAuditorId } from "../../utilities/eventAuditorFilter/EventAuditorFilter";
import { getEventFiltersByOrganizationId } from "../../utilities/eventFilter/EventFilter";
import { lookupPhoneNumber } from "../../utilities/twilio/TwilioLookup";
import ErrorAlert from "../Errors/ErrorAlert";
import SelectEventFiltersForm, { FormattedFilter, FormattedOption } from "../Event/Alerts/SelectEventAlertFiltersForm";
import Spinner from "../Spinners/Spinner";
import AlertsChoiceNavbar from "../Navbars/AlertsChoiceNavbar";
import SelectEventFilterRolesOptionForm, { FormattedRoleOption } from "../Event/Alerts/EventFilterRolesOptionForm";
import { sendOrganizationUpdateTexts, sendOrganizationWelcomeTexts } from "../../utilities/twilio/AuditorMessages";
import { TextAlertRoleFilter, TextAlertRolesFilters } from "../../utilities/roles/Roles";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    organization: Organization
    organizationAuditor?: (OrganizationAuditor | null)
    onCreate?: Function
    onEdit?: Function
    onDelete?: Function
    isAdminView?: Boolean
    onFindCurrentOrganizationAuditor?: Function
}

const OrganizationAuditorForm: React.FC<_Props> = ({organization, organizationAuditor, onCreate, onEdit, onDelete, isAdminView, onFindCurrentOrganizationAuditor}) => {
    const user = useContext(PersonContext);
    
    const [currentStep, setCurrentStep] = useState(1);
    const [totalSteps, setTotalSteps] = useState(4); //Base case is 1-PhoneNumber, 2-PersonName, 3-Roles, 4-Submit. Could include 3.1-EventFilters, 4-Submit+OptIn
    const [currentOrganizationAuditor, setCurrentOrganizationAuditor] = useState<OrganizationAuditor | null | undefined>();
    const [currentAuditor, setCurrentAuditor] = useState<Auditor | null | undefined>();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [name, setName] = useState("");
    const [formattedRoleOptions, setFormattedRoleOptions] = useState<FormattedRoleOption[] | null | undefined>();
    const [navbarState, setNavbarState] = useState<"all" | "filter" | "stop">("filter");
    const [eventFilters, setEventFilters] = useState<EventFilter[] | null | undefined>();
    const [selectedFilters, setSelectedFilters] = useState<EventAuditorFilter[] | null | undefined>();
    const [hasNoSelectedFilters, setHasNoSelectedFilters] = useState(true);
    const [formattedFilters, setFormattedFilters] = useState<FormattedFilter[] | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [isMainLoading, setIsMainLoading] = useState(false);
    const [error, setError] = useState("");
    const [displayLinkToStartTexts, setDisplayLinkToStartTexts] = useState(false);

    // Do this here to set it on mount, but do it again in filter form component
    const formatEventFilters = (eventFilters: EventFilter[], selectedFilters?: EventAuditorFilter[]) => {
        setIsLoading(true);
        let formattedArray: FormattedFilter[] = [];
        let selectedArray: string[] = [];
        if (eventFilters) {
            for (var i = 0; i < eventFilters.length; i++) {
                const currentEventFilter = eventFilters[i];
                if (currentEventFilter.name !== "Roles") {
                    if (currentEventFilter.options && currentEventFilter.options.length > 0) {
                        let options: FormattedOption[] = [];
                        // Use formatted options to track which options on a filter are selected
                        for (var j = 0; j < currentEventFilter.options.length; j++) {
                            const currentOption: string | null = currentEventFilter.options[j];
                            if (currentOption) {
                                // Normally, we want to select all filters to assume user will subscribe to all alerts (probably a new subscriber)
                                let isSelected = true; 
                                if (selectedFilters) {
                                    // If the user has already subscribed to alerts, assume each option is unselected until it is seen
                                    isSelected = false; 
                                    selectedFilters.forEach(currentFilter => {
                                        // Cycle through the EventAuditorFilter objects to determine which options to check
                                        const filterId = currentFilter.filterId;
                                        if (filterId === currentEventFilter.id) {
                                            // Once you find the EventAuditorFilter that matches the current filter, compare the options
                                            const currentFilterOptions = currentFilter.options;
                                            if (currentFilterOptions) {
                                                currentFilterOptions.forEach(option => {
                                                    if (option === currentOption) isSelected = true;
                                                });
                                            }
                                        }
                                    });
                                } else if (currentOrganizationAuditor && hasNoSelectedFilters) {
                                    // If this is a current subscriber who has selected to have 0 filters, set selected to false
                                    isSelected = false; 
                                }
                                const formattedOption: FormattedOption = {
                                    name: currentOption,
                                    isSelected: isSelected
                                };
                                options.push(formattedOption);
                                selectedArray.push(currentOption)
                            }
                        }
                        const formatted: FormattedFilter = {
                            name: currentEventFilter.name || "",
                            isExpanded: false,
                            options: options,
                            filter: currentEventFilter
                        };
                        formattedArray.push(formatted);
                    }
                }
            }
        }
        setFormattedFilters(formattedArray);
        setIsLoading(false);
    }

    // Do this here to set it on mount, but do it again in filter form component
    const formatOrganizationRoleFilters = (currentSelections?: (EventAuditorFilter[] | null)) => {
        let options: FormattedRoleOption[] = [];
        let selectedArray: string[] = [];
        if (TextAlertRolesFilters) {
            for (var i = 0; i < TextAlertRolesFilters.length; i++) {
                const currentEventFilter: TextAlertRoleFilter = TextAlertRolesFilters[i];

                // For the roles filter, we typically will start with none selected and make the person pick
                let isSelected = false; 

                // Check if this is an update, and the person has already selected certain roles
                if (currentSelections) {

                    // Cycle through the EventAuditorFilter objects to determine which options to check
                    currentSelections.forEach(currentSelection => {
                        
                        // If the current selection belongs to the roles filter
                        if (currentSelection.filter?.name === "Roles") {
                            if (currentSelection.options) {
                                currentSelection.options.forEach(option => {
                                    if (option === currentEventFilter.value) isSelected = true;
                                });
                            }
                        }
                    });
                }

                let childrenArray: (FormattedRoleOption[] | null) = null;

                if (currentEventFilter.children) {
                    childrenArray = [];
                    const childArray = currentEventFilter.children;
                    for (var j = 0; j < childArray.length; j++) {
                        const currentEventFilter: TextAlertRoleFilter = childArray[j];
        
                        // For the roles filter, we typically will start with none selected and make the person pick
                        let isChildSelected = false
        
                        // Check if this is an update, and the person has already selected certain roles
                        if (currentSelections) {
        
                            // Cycle through the EventAuditorFilter objects to determine which options to check
                            currentSelections.forEach(currentSelection => {
                                
                                // If the current selection belongs to the roles filter
                                if (currentSelection.filter?.name === "Roles") {
                                    if (currentSelection.options) {
                                        currentSelection.options.forEach(option => {
                                            if (option === currentEventFilter.value) {
                                                isChildSelected = true;
                                                isSelected = true; //Mark parent as selected too
                                            }
                                        });
                                    }
                                }
                            });
                        }

                        const formattedOption: FormattedRoleOption = {
                            name: currentEventFilter.name,
                            value: currentEventFilter.value,
                            isSelected: isChildSelected
                        };

                        childrenArray.push(formattedOption);
                    }
                }

                const formattedOption: FormattedRoleOption = {
                    name: currentEventFilter.name,
                    value: currentEventFilter.value,
                    isSelected: isSelected,
                    children: childrenArray || undefined
                };

                options.push(formattedOption);
                selectedArray.push(currentEventFilter.value);
            }
        }

        setFormattedRoleOptions(options);
    }

    async function getEventFilters(organization: Organization) {
        const queryResult = await getEventFiltersByOrganizationId(organization.id);
        if (queryResult.isSuccess) {
            const eventFiltersList = queryResult.result;

            if (eventFiltersList && eventFiltersList.length >= 1) {
                // If we need to include a step for organization specific filters, then include an extra page for that
                setTotalSteps(() => totalSteps + 1);
            }

            setEventFilters(eventFiltersList);
            if (selectedFilters) {
                formatOrganizationRoleFilters(selectedFilters);
                formatEventFilters(eventFiltersList, selectedFilters);
            }
            else {
                formatEventFilters(eventFiltersList);
            }
        }
    }

    async function getEventAuditorFilters(organizationAuditor: OrganizationAuditor) {
        const queryResult = await getEventAuditorFiltersByOrganizationAuditorId(organizationAuditor.id);
        if (queryResult.isSuccess) {
            const result = queryResult.result;
            setSelectedFilters(result);
            if (eventFilters) {
                formatEventFilters(eventFilters, result);
                formatOrganizationRoleFilters(result);
            } else {
                formatOrganizationRoleFilters(result);
            }
        } else {
            setHasNoSelectedFilters(true);
        }
    }

    useEffect(() => {
        if (organization) {
            formatOrganizationRoleFilters();
            getEventFilters(organization);
        }
    }, [organization]);

    useEffect(() => {
        if (organizationAuditor){
            getEventAuditorFilters(organizationAuditor);
            setCurrentOrganizationAuditor(organizationAuditor);
            setCurrentAuditor(organizationAuditor.auditor);
            setPhoneNumber(organizationAuditor.phoneNumber || "");
            setName(organizationAuditor.name || "");
            // TO DO
            if (organizationAuditor.filters) {
                setNavbarState("filter");
            }
        } else {
            setHasNoSelectedFilters(false);
        }
    }, [organizationAuditor]);

    const checkPhoneNumber = async () => {
        setIsLoading(true);
        setError("");

        // First, check that this is a valid format for a phone number
        try {
            const isValidFormat = isValidPhoneNumber(phoneNumber);
            if (!isValidFormat) {
                setError("The phone number format is invalid. It must be like: (XXX)XXX-XXXX");
                setIsLoading(false);
                return false;
            }
        } catch (error: any) {
            setIsLoading(false);
            return false;
        }

        // Second, verify there is not already an Organization Auditor with this number
        const queryOrganizationAuditorResult = await getOrganizationAuditorByPhoneNumber(formatTwilioNumber(phoneNumber), organization.id);
        if (queryOrganizationAuditorResult.isSuccess) {
            // Organization Auditor already exists, set the currentOrganizationAuditor and make an update operation
            const organizationAuditor: OrganizationAuditor = queryOrganizationAuditorResult.result;
            setCurrentOrganizationAuditor(organizationAuditor);
            // Tell parent component the organizationAuditor was found - changes "Add Subscriber" to "Edit Subscriber" wording
            if (onFindCurrentOrganizationAuditor) onFindCurrentOrganizationAuditor(organizationAuditor);
            
            // Set the other info for this organizationAuditor
            setCurrentAuditor(organizationAuditor.auditor);
            setName(organizationAuditor.name || "");
            await getEventAuditorFilters(organizationAuditor);

            setCurrentStep(2);
            if (organizationAuditor.filters) {
                setNavbarState("filter");
            }
            setIsLoading(false);
            return true;
        }

        // Third, verify there is not already an Auditor with this number
        const queryAuditorResult = await getAuditorByPhoneNumber(formatTwilioNumber(phoneNumber));
        if (queryAuditorResult.isSuccess) {
            // Organization Auditor already exists, set the currentOrganizationAuditor and make an update operation
            const auditor: Auditor = queryAuditorResult.result;
            setCurrentAuditor(auditor);
            setName(auditor.name || "");
            
            setCurrentStep(2);
            setIsLoading(false);
            return true;
        }

        // Fourth, verify there is not a person with this phone number as a contact
        // TO DO
       
        // Fifth, this is definitely a new phone number, verify it is a cell number //TO DO - fix canadian numbers
        const lookupResult = await lookupPhoneNumber(phoneNumber, true);
        if (lookupResult.isSuccess) {
            const carrier = lookupResult.result;
            if (carrier && carrier.type) {
                const type = carrier?.type;
                if (type !== "mobile") {
                    setError("Could not confirm this phone number is a cell phone number. It cannot be added.");
                    setIsLoading(false);
                    return false;
                }
            } else {
                setError("Could not confirm this phone number is a cell phone number. It cannot be added.");
                setIsLoading(false);
                return false;
            }
        } else {
            setError("Could not confirm this phone number is a cell phone number. It cannot be added.");
            setIsLoading(false);
            return false;
        }

        // Lastly, phone is valid, move on to step 2
        setCurrentStep(2);
        setIsLoading(false);
    }

    const handleFilterSelect = (formattedFilters: FormattedFilter[]) => {
        setFormattedFilters(formattedFilters);
    }

    const handleRoleFilterSelect = (formattedOptions: FormattedRoleOption[]) => {
        setError("");
        setFormattedRoleOptions(formattedOptions);
    }

    const handleCreateEventAuditorFilters = async (organizationAuditor: OrganizationAuditor) => {
        if (navbarState === "all") {
            // User is on "Get All Alerts" page, add all filters
            if (eventFilters) {
                // Will reset the formattedFilters array to have all options selected
                formatEventFilters(eventFilters);
            }
        }
        if (formattedFilters && formattedFilters.length > 0) {
            for (var i = 0; i < formattedFilters.length; i++) {
                const currentFilter = formattedFilters[i];
                let selectedOptions: string[] = [];
                currentFilter.options.forEach((element: any) => {
                    if (element.isSelected) selectedOptions.push(element.name);
                });
                if (selectedOptions && selectedOptions.length > 0) {
                    const eventAuditorFilterInput: CreateEventAuditorFilterInput = {
                        organizationId: organization.id,
                        eventAuditorFilterOrganizationId: organization.id,
                        organizationAuditorId: organizationAuditor.id,
                        // eventAuditorFilterOrganizationAuditorId: organizationAuditor.id,
                        filterId: currentFilter.filter.id,
                        // eventAuditorFilterFilterId: currentFilter.filter.id,
                        options: selectedOptions,
                        createdBy: user.id
                    };
                    await createEventAuditorFilter(eventAuditorFilterInput);
                }
            }
        }
    }

    const handleRemoveEventAuditorFilters = async () => {
        // Will remove role filters + other organization filters
        let didClearFilters = true;
        if (selectedFilters) {
            for (var i = 0; i < selectedFilters.length; i++) {
                const current = selectedFilters[i];
                const deleteResult = await deleteEventAuditorFilter({id: current.id});
                if (!deleteResult.isSuccess) {
                    didClearFilters = false;
                }
            }
        }
        return didClearFilters;
    }

    const handleCreateOrganizationAuditorRoleFilters = async (organizationId: string, newOrganizationAuditor: OrganizationAuditor) => {
        let currentEventFilters: (EventFilter[] | undefined) = undefined;
        const queryResult = await getEventFiltersByOrganizationId(organizationId);
        if (queryResult.isSuccess) {
            currentEventFilters = queryResult.result;
        }
        if (currentEventFilters) {
            const roleFilter: (EventFilter | undefined) = currentEventFilters.find(eventFilter => eventFilter.name === "Roles");
            if (roleFilter) {
                
                let selectedOptions: string[] = [];
                if (formattedRoleOptions && formattedRoleOptions.length > 0) {
                    for (var i = 0; i < formattedRoleOptions.length; i++) {
                        const currentRoleOption: FormattedRoleOption = formattedRoleOptions[i];
                        if (currentRoleOption.isSelected) {
                            if (currentRoleOption.children) {
                                currentRoleOption.children.forEach(child => {
                                    if (child.isSelected) {
                                        selectedOptions.push(child.value);
                                    }
                                });
                            } else {
                                selectedOptions.push(currentRoleOption.value);
                            }
                        }
                    }
                }
        
                const eventAuditorFilterInput: CreateEventAuditorFilterInput = {
                    organizationId: organization.id,
                    eventAuditorFilterOrganizationId: organization.id,
                    organizationAuditorId: newOrganizationAuditor.id,
                    // eventAuditorFilterOrganizationAuditorId: newOrganizationAuditor.id,
                    filterId: roleFilter.id,
                    // eventAuditorFilterFilterId: roleFilter.id,
                    options: selectedOptions,
                    createdBy: user.id
                };
                await createEventAuditorFilter(eventAuditorFilterInput);
            } 
        }
    }

    const handleCreateOrganizationAuditor = async () => {
        let auditor = currentAuditor;
        let organizationAuditor: OrganizationAuditor | null = null;
        if (!auditor) {
            // Create a new auditor
            const auditorInput: CreateAuditorInput = {
                name: name,
                phoneNumber: formatTwilioNumber(phoneNumber),
                status: AuditorStatus.verified,
                createdBy: user.id
            }
            const createAuditorResult = await createAuditor(auditorInput);
            if (createAuditorResult.isSuccess) {
                auditor = createAuditorResult.result;
                setCurrentAuditor(auditor);
            } else {
                setError("Could not create the auditor.");
            }
        } 
        if (auditor) {
            // Create a new organization auditor
            const organizationAuditorInput: CreateOrganizationAuditorInput = {
                organizationId: organization.id,
                organizationAuditorOrganizationId: organization.id,
                auditorId: auditor.id,
                // organizationAuditorAuditorId: auditor.id,
                name: name,
                phoneNumber: formatTwilioNumber(phoneNumber),
                status: "verified",
                createdBy: user.id,
                organizationAuditorPersonId: user.id
            };
            const createResult = await createOrganizationAuditor(organizationAuditorInput);
            organizationAuditor = createResult.result;
            if (createResult.isSuccess) {
                const newlyCreatedOrganizationAuditor = createResult.result;
                setCurrentOrganizationAuditor(newlyCreatedOrganizationAuditor);

                // Create the role filters for this organization auditor
                await handleCreateOrganizationAuditorRoleFilters(organization.id, newlyCreatedOrganizationAuditor);

                // Create the filters for this organization auditor
                await handleCreateEventAuditorFilters(newlyCreatedOrganizationAuditor);
                
                // Update the organization auditor now that it has it's filters
                const queryResult = await getOrganizationAuditorById(newlyCreatedOrganizationAuditor.id);
                if (queryResult.isSuccess) {
                    organizationAuditor = queryResult.result;
                }

                // Send a welcome message
                const sendSMSConfirmationMessagesResult = await sendOrganizationWelcomeTexts(auditor, organization, isAdminView);
                if (!sendSMSConfirmationMessagesResult.isSuccess) {
                    setError(sendSMSConfirmationMessagesResult.message);
                    if (sendSMSConfirmationMessagesResult.message.includes("text START to +1-757-210-6404")) {
                        setDisplayLinkToStartTexts(true);
                    }
                    setIsMainLoading(false);
                    return;
                }

                if (onCreate && organizationAuditor) {
                    onCreate(organizationAuditor);
                }
                setIsMainLoading(false);
            } else {
                setError("Could not create the organization auditor.");
                setIsMainLoading(false);
            }
        } else {
            setIsMainLoading(false);
        }
    }

    const handleUpdateOrganizationAuditor = async () => {
        let updatedOrganizationAuditor: OrganizationAuditor | undefined = undefined;
        if (currentAuditor) {
            // May need to update auditor
            const auditorInput: UpdateAuditorInput = {
                id: currentAuditor.id,
                name: name,
                phoneNumber: formatTwilioNumber(phoneNumber),
                status: AuditorStatus.verified,
                lastUpdatedBy: user.id
            };
            const updateAuditorResult = await updateAuditor(auditorInput);
            if (updateAuditorResult.isSuccess) {
                const auditor = updateAuditorResult.result;
                setCurrentAuditor(updateAuditorResult.result);
                if (currentOrganizationAuditor) {
                    const organizationAuditorInput: UpdateOrganizationAuditorInput = {
                        id: currentOrganizationAuditor.id,
                        organizationId: organization.id,
                        auditorId: auditor.id,
                        // organizationAuditorAuditorId: auditor.id,
                        name: name,
                        phoneNumber: formatTwilioNumber(phoneNumber),
                        status: "verified",
                        createdBy: user.id,
                        organizationAuditorPersonId: user.id
                    };
                    const updateResult = await updateOrganizationAuditor(organizationAuditorInput);
                    if (updateResult.isSuccess) {
                        // First, remove the old selected filters
                        const didSuccessfullyClearFilters = await handleRemoveEventAuditorFilters();
                        if (didSuccessfullyClearFilters) {
                            const newlyUpdatedOrganizationAuditor: OrganizationAuditor = updateResult.result;
                            updatedOrganizationAuditor = newlyUpdatedOrganizationAuditor;

                            // Create the role filters for this organization auditor
                            await handleCreateOrganizationAuditorRoleFilters(organization.id, newlyUpdatedOrganizationAuditor);
                            
                            // Create the filters for this organization auditor
                            await handleCreateEventAuditorFilters(newlyUpdatedOrganizationAuditor);
                        } else {
                            setError("Sorry, we were not able to update your filters.");
                        }
                        
                        // Send an update message
                        const sendSMSUpdateMessagesResult = await sendOrganizationUpdateTexts(auditor, organization, isAdminView);
                        if (!sendSMSUpdateMessagesResult.isSuccess) {
                            setError(sendSMSUpdateMessagesResult.message);
                            if (sendSMSUpdateMessagesResult.message.includes("text START to +1-757-210-6404")) {
                                setDisplayLinkToStartTexts(true);
                            }
                            setIsMainLoading(false);
                            return;
                        }

                        if (onEdit) onEdit(updateResult.result);
                        setIsMainLoading(false);
                    } else {
                        setError("Could not update the organization auditor.");
                        setIsMainLoading(false);
                    }
                } else {
                    setError("Could not save the organization auditor.");
                    setIsMainLoading(false);
                }
            } else {
                setError("Could not save the auditor.");
                setIsMainLoading(false);
            }
        }  else {
            setError("No current auditor.");
            setIsMainLoading(false);
        }
    }

    const handleRemoveOrganizationAuditor = async () => {
        if (currentOrganizationAuditor) {
            setIsMainLoading(true);
            const deleteInput = {id: currentOrganizationAuditor.id};
            const deleteResult = await deleteOrganizationAuditor(deleteInput);
            if (deleteResult.isSuccess) {
                await handleRemoveEventAuditorFilters();
                if (onDelete) onDelete(deleteResult.result);
                setIsMainLoading(false);
            } else {
                setError("Could not remove this subscriber from the organization.");
                console.error("Delete Result failed: ", deleteResult.result);
            }
        } 
    }

    const handleSubmit = async () => {
        setIsMainLoading(true);
        if (currentOrganizationAuditor) {
            if (navbarState === "stop") {
                await handleRemoveOrganizationAuditor();
            } else {
                await handleUpdateOrganizationAuditor();
            }
        } else {
            await handleCreateOrganizationAuditor();
        }
    }

    const handleButtonClick = async () => {
        setDisplayLinkToStartTexts(false);
        if (currentStep === 1) {
            if (!phoneNumber) {
                setError("Error: You did not include a phone number.");
                return;
            }
            checkPhoneNumber();
        } else if (currentStep === 2) {
            if (!name) {
                setError("Error: You must include a name so that we know who has signed up for alerts.");
                return;
            }
            setCurrentStep(3)
        } else if (currentStep === 3) {
            let selectedAtLeastOneRole = false; 
            if (formattedRoleOptions && formattedRoleOptions.length) {
                formattedRoleOptions.forEach(option => {
                    if (option.children) {
                        option.children.forEach((child: any) => {
                            if (child.isSelected) selectedAtLeastOneRole = true;
                        })
                    } else {
                        if (option.isSelected) selectedAtLeastOneRole = true;
                    }
                })
            }
            if (!selectedAtLeastOneRole) {
                setError("Error: You must select at least one of the options.");
                return;
            }
            setCurrentStep(4);
        } else if (totalSteps === 4 && currentStep === 4) {
            handleSubmit();
        } else if (totalSteps === 5 && currentStep === 4) {
            setCurrentStep(5);
        } else if (currentStep === 5) {
            handleSubmit();
        } else {
            console.error("Error! Current step: ", currentStep);
        }
    }

    return (
        <>
            {(isMainLoading || isLoading) ?
                <Spinner />
                :
                <form>
                    {error && <ErrorAlert width="12" error={error} />}
                    {(displayLinkToStartTexts && (isPlatform('ios') || isPlatform('android'))) && (
                        <IonRow className="ion-justify-content-center">
                            <IonCol className="ion-text-center">
                                <a className="link" href={isPlatform('ios') ? "sms:+15407126447&body:START" : "sms:+15407126447?body:START"}>Click Here to START messages.</a>
                            </IonCol>
                        </IonRow>
                    )}
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            <p className="text-light">Step {currentStep} of {totalSteps}</p>
                        </IonCol>
                    </IonRow>
                    {currentStep === 1 && (
                        <>
                            <IonRow>
                                <IonCol>
                                    <IonItem color="white">
                                        <IonLabel position="floating">Phone Number <RequiredInputIndicator showWordRequired/></IonLabel>
                                        <IonInput 
                                            type="text"
                                            value={phoneNumber}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setPhoneNumber(e.detail.value!)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                    {currentStep === 2 && (
                        <>
                            <IonRow>
                                <IonCol>
                                    <IonItem color="white">
                                        <IonLabel position="stacked">Name <RequiredInputIndicator showWordRequired/></IonLabel>
                                        <IonInput 
                                            type="text"
                                            value={name}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setName(e.detail.value!)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                    {currentStep === 3 && (
                        <>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size="12" className="ion-text-center">
                                    <p className="description">(Select at least one)</p>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size="12" className="ion-text-center">
                                    <h4>I am a ... </h4>
                                </IonCol>
                            </IonRow>
                            <IonRow className="pt-1 ion-justify-content-center scrollable">
                                <IonCol size="12">
                                    <SelectEventFilterRolesOptionForm startWithoutSelections={true} currentSelectedFilters={selectedFilters} isExpandedOnStart={true} isCollapsable={false} onSelect={handleRoleFilterSelect} />
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                    {totalSteps === 5 && currentStep === 4 && (
                        <>
                            <IonRow className="pt-1 ion-justify-content-center">
                                <IonCol size="12" className="ion-text-center">
                                    <p>Select one:</p>
                                </IonCol>
                            </IonRow>
                            <IonRow className="pt-1 ion-justify-content-center">
                                <IonCol size="12" className="ion-text-center">
                                    <AlertsChoiceNavbar active={navbarState} onSelect={(selected: ("all" | "filter" | "stop")) => setNavbarState(selected)}  />
                                </IonCol>
                            </IonRow>
                            {navbarState === "all" && (
                                <IonRow className="ion-justify-content-center">
                                    <IonCol size="6" className="ion-text-center">
                                        <p className="description">You will receive all alerts for this organization.</p>
                                    </IonCol>
                                </IonRow>
                            )}
                            {navbarState === "filter" && 
                                <SelectEventFiltersForm organization={organization} currentSelectedFilters={selectedFilters} isExpandedOnStart={true} onSelect={handleFilterSelect} />
                            }
                            {navbarState === "stop" && (
                                <IonRow className="ion-justify-content-center">
                                    <IonCol size="6" className="ion-text-center">
                                        <p className="description">You will stop receiving text alerts for this organization.</p>
                                    </IonCol>
                                </IonRow>
                            )}
                        </>
                    )}
                    {(displayLinkToStartTexts && (isPlatform('ios') || isPlatform('android'))) && (
                        <IonRow className="ion-justify-content-center">
                            <IonCol className="ion-text-center">
                                <a className="link" href={isPlatform('ios') ? "sms:+17572106404&body:START" : "sms:+17572106404?body:START"}>Click Here to START messages.</a>
                            </IonCol>
                        </IonRow>
                    )}
                    <IonRow className="ion-justify-content-center">
                        {((currentStep !== 5 && (currentStep !== 4 && totalSteps === 4)) && error) && <ErrorAlert width="12" error={error} />}
                        {currentStep !== 1 && (
                            <IonCol size="6">
                                {isLoading ?
                                    <></>
                                    :
                                    <IonButton
                                        className="ion-margin-top"
                                        color="light"
                                        expand="block"
                                        onClick={() => {setCurrentStep((prevState) => prevState-1); setError("")}}
                                    >
                                        Go Back 
                                    </IonButton>
                                }
                            </IonCol>
                        )}
                        <IonCol size="6">
                            {isLoading ?
                                <Spinner />
                                :
                                <IonButton
                                    className="ion-margin-top"
                                    color={(currentStep === 5 || (currentStep === 4 && totalSteps === 4)) ? "success" : (navbarState === "stop" ? "danger" : "tertiary")}
                                    disabled={!!error}
                                    expand="block"
                                    onClick={() => handleButtonClick()}
                                >
                                    <span className="ion-text-wrap">
                                        {(currentStep === 5 || (currentStep === 4 && totalSteps === 4)) ? "Complete Alert " + (organizationAuditor ? "Update" : (navbarState === "stop" ? "Cancel" : "Sign Up")) : "Next"}
                                    </span>
                                </IonButton>
                            }
                        </IonCol>
                    </IonRow>
                    {isAdminView && currentOrganizationAuditor && (currentStep === 1 || currentStep === 5 || (currentStep === 4 && totalSteps === 4)) && (
                        <>
                            <div className="pt-5">
                                <hr />
                            </div>
                            <IonRow className="ion-justify-content-center pt-5">
                                <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                    {isLoading ?
                                        <Spinner />
                                        :
                                        <IonButton
                                            className="ion-margin-top"
                                            color="danger"
                                            disabled={!!error}
                                            expand="block"
                                            onClick={() => handleRemoveOrganizationAuditor()}
                                        >
                                            <p className="ion-text-wrap">
                                                Remove Subscriber
                                            </p>
                                        </IonButton>
                                    }
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                    <hr/>
                    {((currentStep === 4) && !(navbarState === "stop")) && (
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="10" className="ion-text-center">
                                <IonText><p className="description">You'll receive a confirmation text message after you submit.</p></IonText>
                            </IonCol>
                        </IonRow>
                    )}
                </form>
            }
        </>
    );
};

export default OrganizationAuditorForm;
