import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    isPlatform,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isWindows } from "../../../utilities/platform/Platform";
import { Address, Membership, Organization, Contact, OrganizationMembershipType, Person, MembershipTypeSchedule, Barn, Rider, MembershipTypeCategory } from "../../../models";
import ErrorAlert from "../../Errors/ErrorAlert";
import PhoneNumberTypeSelect from "../../Contact/PhoneNumberTypeSelect";
import AddressFormGroup from "../../Address/AddressFormGroup";
import SelectFromAllBarns from "../../Barn/SelectFromAllBarns";
import { CreateAddressInput, CreateContactInput, CreateMembershipInput, CreateRiderInput, UpdateAddressInput, UpdateContactInput,  UpdateMembershipInput, UpdateOrganizationMembershipTypeInput } from "../../../API";
import { createMembership, updateMembership, getMembershipsByPersonName } from "../../../utilities/membership/Membership";
import { createAddress, getAddressById, updateAddress } from "../../../utilities/address/Address";
import { createContact, updateContact } from "../../../utilities/contact/Contact";
import Spinner from "../../Spinners/Spinner";
import { createRider, getRiderById, getRiderByPersonId, getRidersByBarnId } from "../../../utilities/rider/Rider";
import { formatAllCountryPhoneNumber } from "../../../utilities/contact/FormatPhoneNumber";
import CONSTANT from "../../../constant/constant";
import { getNextAvailableMembershipId } from "../../../utilities/organizationMembershipType/MembershipIdValues";
import { updateOrganizationMembershipType } from "../../../utilities/organizationMembershipType/OrganizationMembershipType";
import { getBarnById } from "../../../utilities/barn/Barn";
import { formatDisplayName } from "../../../utilities/person/PersonNameFormat";
import RequiredInputIndicator from "../../Forms/RequiredInputIndicator";

interface _Props {
    isDisabled?: (boolean | undefined)
    membershipType: OrganizationMembershipType;
    memberships: Membership[] | undefined;
    personId: string;
    user: Person | undefined;
    setMemberships: Function;
    organization: Organization | undefined;
}

interface GroupMember {
    name: string;
    birthDate: string;
    backNumber?: number;
    membership?: Membership;
}

const MembershipApplicationBasicInfo: React.FC<_Props> = ({isDisabled, membershipType, memberships, setMemberships, personId, user, organization}) => {

    // const membership: (Membership | undefined) = (memberships?.length) ? memberships[0] : undefined;
    const [membership, setMembership] = useState<Membership | undefined>();
    const [groupMembers, setGroupMembers] = useState<(GroupMember | null)[]>([]);    
    const [barn, setBarn] = useState<Barn | undefined | null>();

    // Group/Team name
    const [groupTeamName, setGroupTeamName] = useState<string>("");
    const [groupOrTeam, setGroupOrTeam] = useState<string>("");

    // Group/Team contact
    const [contact, setContact] = useState<Contact>();
    const [email, setEmail] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [phoneType, setPhoneType] = useState<string>("");
    
    // Group/Team address
    const [address, setAddress] = useState<Address>();
    const [streetAddressLine1, setStreetAddressLine1] = useState("");
    const [streetAddressLine2, setStreetAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [provState, setProvState] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");

    // Group/Team Leader name and leader contact info 
    const [leaderName, setLeaderName] = useState<string>("");

    // Group/Team Leader contact
    const [leaderContact, setLeaderContact] = useState<Contact>();
    const [leaderEmail, setLeaderEmail] = useState<string>("");
    const [leaderPhoneNumber, setLeaderPhoneNumber] = useState<string>("");
    const [leaderPhoneType, setLeaderPhoneType] = useState<string>("");

    // Group/Team Leader address
    const [leaderAddress, setLeaderAddress] = useState<Address>();
    const [leaderStreetAddressLine1, setLeaderStreetAddressLine1] = useState("");
    const [leaderStreetAddressLine2, setLeaderStreetAddressLine2] = useState("");
    const [leaderCity, setLeaderCity] = useState("");
    const [leaderProvState, setLeaderProvState] = useState("");
    const [leaderZip, setLeaderZip] = useState("");
    const [leaderCountry, setLeaderCountry] = useState("");

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [isAELOrganization, setIsAELOrganization] = useState(false);

    useEffect(() => {
        if (organization?.id === "ddc2fd3c-0bde-4b39-bbbb-5e00d2a35ba0") setIsAELOrganization(true);
    }, [organization]);

    useEffect(() => {
        if (membershipType.applicationFields?.collectMemberDetailsTogether &&
            membershipType.applicationFields?.maxNumberOfGroupMembers &&
            membershipType.applicationFields?.maxNumberOfGroupMembers !== groupMembers?.length) {
            const blankMembersCount = membershipType.applicationFields?.maxNumberOfGroupMembers - groupMembers?.length;
            for(let i = 0; i < blankMembersCount; i++) {
                groupMembers.push(null);
            }
            setGroupMembers([...groupMembers]);
        }
    }, [groupMembers]);

    useEffect(() => {   
        const getPrePopulatedDataForGroupMembership = async () => {
            setIsLoading(true);
    
            // Check if this is a Group or Team group membership
            if(membershipType.applicationFields?.isTeam) setGroupOrTeam("Team"); 
            else setGroupOrTeam("Group");
    
            // Check if Barn is needed
            if (membershipType && membershipType.applicationFields?.barnName) await getPrePopulatedBarn();
    
            // Check if GroupMembers are needed (ex: a Family Membership where the application collects up to 4 family members at once)
            if (membershipType && membershipType.applicationFields?.collectMemberDetailsTogether && membershipType.applicationFields.maxNumberOfGroupMembers) {
                if (memberships?.length === 0) {
                    // Populate the group member array with the max number of objects that can be added
                    const emptyGroupMemberArray = Array(membershipType.applicationFields?.maxNumberOfGroupMembers).fill(null);
                    setGroupMembers([...emptyGroupMemberArray]);
                } else {
                    // Use the memberships to populate the Group Memberships
                    await getPrePopulatedGroupMembers();
                }
                
            }
    
            if(membership) {
                await getPrePopulatedGroupInfo(membership);
                await getPrePopulatedGroupLeaderInfo(membership);
            }
            setIsLoading(false);
        }
        
        clearForm();   
        getPrePopulatedDataForGroupMembership();
    }, [membershipType]);

    useEffect(() => {   
        if (memberships && memberships.length > 0) setMembership(memberships[0]);
        else setMembership(undefined);
    }, [memberships]);

    const clearForm = () => {
        setGroupMembers([]);
        setBarn(undefined);
        setGroupTeamName("");
        setGroupOrTeam("");
        setContact(undefined);
        setEmail("");
        setPhoneNumber("");
        setPhoneType("");
        setAddress(undefined);
        setStreetAddressLine1("");
        setStreetAddressLine2("");
        setCity("");
        setProvState("");
        setZip("");
        setCountry("");
        setLeaderName("");
        setLeaderAddress(undefined);
        setLeaderContact(undefined);
        setLeaderEmail("");
        setLeaderPhoneNumber("");
        setLeaderPhoneType("");
        setLeaderStreetAddressLine1("");
        setLeaderStreetAddressLine2("");
        setLeaderCity("");
        setLeaderProvState("");
        setLeaderZip("");
        setLeaderCountry("");
        setError("");
    }

    const getPrePopulatedBarn = async () => {
        // Try to get the barn from the membership, then from the rider, then from the current user
        if (membership?.barn) {
            setBarn(barn);
        } else if (membership?.barnId) {
            const queryBarnResult = await getBarnById(membership?.barnId);
            if (queryBarnResult.isSuccess) {
                const barn: Barn = queryBarnResult.result;
                setBarn(barn);
            }
        } else if (membership?.rider?.id) {
            const queryResult = await getRiderById(membership?.rider?.id);
            if (queryResult.isSuccess) {
                const riderData: Rider = queryResult.result;
                const barn: (Barn | null | undefined) = riderData.barn;
                setBarn(barn);
            }
        } else {
            const queryResult = await getRiderByPersonId(personId);
            if (queryResult.isSuccess) {
                const riderData: Rider = queryResult.result;
                const barn: (Barn | null | undefined) = riderData.barn;
                setBarn(barn);
            }
        }
    }

    const getPrePopulatedGroupMembers = async () => {
        // Format the GroupMembers if there are multiple memberships
        if (memberships && memberships.length > 0) {
            let updatedGroupMembers = groupMembers;
            for (let i = 0; i < memberships.length; i++) {
                const membership: Membership = memberships[i];
                // Create the new group member and update the array in state
                const groupMember: GroupMember = {
                    name: membership.personName || "",
                    birthDate: membership.rider?.birthdate || "",
                    backNumber: membership.backNumber || 0,
                    membership: membership
                };
                updatedGroupMembers[i] = groupMember;
            }
            setGroupMembers([...updatedGroupMembers])
        }
    }

    const getPrePopulatedGroupInfo = async (membership: Membership) => {
        // Check if Group Name is Needed
        if (membershipType.applicationFields?.groupName) {
            setGroupTeamName(membership.groupContact?.name || "");
        }

        // Check if Group Contact is Needed
        if (membershipType.applicationFields?.contact) {
            const contactData = membership.groupContact;
            setEmail(contactData?.personalEmail || contactData?.workEmail || "");
            setPhoneType(
                contactData && contactData.cell
                ? "cell"
                : contactData && contactData.home
                ? "home"
                : contactData && contactData.work
                ? "work"
                : ""
            );
            setPhoneNumber(
                contactData && contactData.cell
                ? contactData.cell
                : contactData && contactData.home
                ? contactData.home
                : contactData && contactData.work
                ? contactData.work
                : ""
            );
            if(contactData?.mailingAddress) {
                const groupAddressResult = await getAddressById(contactData?.mailingAddress);
                const groupAddress = groupAddressResult.result;
                setAddress(groupAddress);
                setStreetAddressLine1(groupAddress.streetAddress1 ? groupAddress.streetAddress1 : "");
                setStreetAddressLine2(groupAddress.streetAddress2 ? groupAddress.streetAddress2 : "");
                setCity(groupAddress.city ? groupAddress.city : "");
                setProvState(groupAddress.provState ? groupAddress.provState : "");
                setZip(groupAddress.zip ? groupAddress.zip : "");
                setCountry(groupAddress.country ? groupAddress.country : "");
            }
        }
    }

    const getPrePopulatedGroupLeaderInfo = async (membership: Membership) => {
        // Check if Group Leader is Needed
        if (membershipType.applicationFields?.groupLeader) {
            setLeaderName(membership.groupLeaderContact?.name || "");
        }

        // Check if Group Leader Contact is Needed
        if (membershipType.applicationFields?.groupLeaderContactInfo) {
            setLeaderEmail(membership.groupLeaderContact?.personalEmail || "");
            const leaderContactData = membership.groupLeaderContact;
            setLeaderPhoneType(
                leaderContactData && leaderContactData.cell
                ? "cell"
                : leaderContactData && leaderContactData.home
                ? "home"
                : leaderContactData && leaderContactData.work
                ? "work"
                : ""
            );
            setLeaderPhoneNumber(
                leaderContactData && leaderContactData.cell
                ? leaderContactData.cell
                : leaderContactData && leaderContactData.home
                ? leaderContactData.home
                : leaderContactData && leaderContactData.work
                ? leaderContactData.work
                : ""
            );
            if(leaderContactData?.mailingAddress) {
                const groupLeaderAddressResult = await getAddressById(leaderContactData?.mailingAddress);
                const groupLeaderAddress = groupLeaderAddressResult.result;
                setLeaderAddress(groupLeaderAddress);
                setLeaderStreetAddressLine1(groupLeaderAddress.streetAddress1 ? groupLeaderAddress.streetAddress1 : "");
                setLeaderStreetAddressLine2(groupLeaderAddress.streetAddress2 ? groupLeaderAddress.streetAddress2 : "");
                setLeaderCity(groupLeaderAddress.city ? groupLeaderAddress.city : "");
                setLeaderProvState(groupLeaderAddress.provState ? groupLeaderAddress.provState : "");
                setLeaderZip(groupLeaderAddress.zip ? groupLeaderAddress.zip : "");
                setLeaderCountry(groupLeaderAddress.country ? groupLeaderAddress.country : "");
            }
        }
    }

    const handleGroupMemberNameChange = async (index: number, name: string) => {
        // TO DO - removed the pre-populate member back number and DOB from previous memberships
        // Can add that back in for RHSC memberships
        if (groupMembers) {
            let currentGroupMember = groupMembers[index];
            if (currentGroupMember) {
                // If the current group member already exists, only update the applicable field
                currentGroupMember.name = name;
            } else {
                // If the current group member does not already exists, create it with the data we currently have
                currentGroupMember = {
                    name: name || "",
                    birthDate: "",
                    backNumber: 0
                };
            }
            // Update the group member array
            const updatedGroupMemberArray = [
                ...groupMembers.slice(0, index),
                currentGroupMember,
                ...groupMembers.slice(index + 1)
            ];
            setGroupMembers([...updatedGroupMemberArray]);
        }
    };

    const handleGroupMemberBirthDateChange = async (index: number, birthDate: string) => {
        if (groupMembers) {
            let currentGroupMember = groupMembers[index];
            if (currentGroupMember) {
                if (birthDate) {
                    currentGroupMember.birthDate = birthDate;
                } else {
                    currentGroupMember.birthDate = "";  
                }
            } else {
                // If the current group member does not already exists, create it with the data we currently have
                currentGroupMember = {
                    name: "",
                    birthDate: birthDate || "",
                    backNumber: undefined
                };
            }
            // Update the group member array
            const updatedGroupMemberArray = [
                ...groupMembers.slice(0, index),
                currentGroupMember,
                ...groupMembers.slice(index + 1)
            ];
            setGroupMembers([...updatedGroupMemberArray]);
        }
    };

    const handlePhoneNumberTypeSelectInput = (phoneNumberTypeInput: string) => {
        if (phoneNumberTypeInput && phoneNumberTypeInput !== "-"){
            setPhoneType(phoneNumberTypeInput);
        } else {
            setPhoneType("");
        }
    };

    const handleLeaderPhoneNumberTypeSelectInput = (phoneNumberTypeInput: string) => {
        if (phoneNumberTypeInput && phoneNumberTypeInput !== "-"){
            setLeaderPhoneType(phoneNumberTypeInput);
        } else {
            setLeaderPhoneType("");
        }
    };

    const handleAddressInputChange = (type: string, value: string) => {
        setError("");
        if (type === "streetAddressLine1") setStreetAddressLine1(value);
        if (type === "streetAddressLine2") setStreetAddressLine2(value);
        if (type === "city") setCity(value);
        if (type === "provState") setProvState(value);
        if (type === "zip") setZip(value);
        if (type === "country") setCountry(value);
    };

    const handleLeaderAddressInputChange = (type: string, value: string) => {
        setError("");
        if (type === "streetAddressLine1") setLeaderStreetAddressLine1(value);
        if (type === "streetAddressLine2") setLeaderStreetAddressLine2(value);
        if (type === "city") setLeaderCity(value);
        if (type === "provState") setLeaderProvState(value);
        if (type === "zip") setLeaderZip(value);
        if (type === "country") setLeaderCountry(value);
    };

    const handleSelectBarn = async (barn?: Barn) => {
        setError("");
        if (barn && user) {
            setBarn(barn);
        } else {
            setBarn(undefined);
        }
    };

    const handleAddress = async (name: string, id?: (string | null)) => {
        try {
            let addressData: Address | null = null;
        
            if (id) {
                const addressInput: UpdateAddressInput = {
                    id: id,
                    name: name,
                    type: "mailing",
                    isDefault: true,
                    streetAddress1: streetAddressLine1,
                    streetAddress2: streetAddressLine2,
                    city: city,
                    provState: provState,
                    zip: zip,
                    country: country,
                    notes: ""
                };
                const updateAddressResult = await updateAddress(addressInput);
                if (updateAddressResult.isSuccess) {
                    addressData = updateAddressResult.result;
                    setAddress(updateAddressResult.result);
                } else {
                    setError(updateAddressResult.message);
                }
            } else {
                const addressInput: CreateAddressInput = {
                    name: name,
                    type: "mailing",
                    isDefault: true,
                    streetAddress1: streetAddressLine1,
                    streetAddress2: streetAddressLine2,
                    city: city,
                    provState: provState,
                    zip: zip,
                    country: country,
                    notes: ""
                };
                const createAddressResult = await createAddress(addressInput);
                if (createAddressResult.isSuccess) {
                    addressData = createAddressResult.result;
                    setAddress(createAddressResult.result);
                } else {
                    setError(createAddressResult.message);
                }
            }
            return addressData;
        } catch (error) {
            const message = "Could not save the address information: " + error;
            setError(message);
        }
    }

    const handleGroupLeaderAddress = async (name: string, id: string| undefined | null) => {
        try {
            let addressData: Address | null = null;
        
            if (id) {
                const addressInput: UpdateAddressInput = {
                    id: id,
                    name: name,
                    type: "mailing",
                    isDefault: true,
                    streetAddress1: leaderStreetAddressLine1,
                    streetAddress2: leaderStreetAddressLine2,
                    city: leaderCity,
                    provState: leaderProvState,
                    zip: leaderZip,
                    country: leaderCountry,
                    notes: ""
                };
                const updateAddressResult = await updateAddress(addressInput);
                if (updateAddressResult.isSuccess) {
                    addressData = updateAddressResult.result;
                    setLeaderAddress(updateAddressResult.result);
                } else {
                    setError(updateAddressResult.message);
                }
            } else {
                const addressInput: CreateAddressInput = {
                    name: name,
                    type: "mailing",
                    isDefault: true,
                    streetAddress1: leaderStreetAddressLine1,
                    streetAddress2: leaderStreetAddressLine2,
                    city: leaderCity,
                    provState: leaderProvState,
                    zip: leaderZip,
                    country: leaderCountry,
                    notes:""
                };
                const createAddressResult = await createAddress(addressInput);
                if (createAddressResult.isSuccess) {
                    addressData = createAddressResult.result;
                    setLeaderAddress(createAddressResult.result);
                } else {
                    setError(createAddressResult.message);
                }
            }
            return addressData;
        } catch (error) {
            const message = "Could not save the address information: " + error;
            setError(message);
        }
    }

    const handleContact = async (name: string, id: string| undefined | null, addressId?: string) => {
        try {
            let contactData: Contact | null = null;
            if (id) {
                const contactInput: UpdateContactInput = {
                    name: name,
                    id: id,
                    cell: phoneType === "cell" ? formatAllCountryPhoneNumber(phoneNumber) : undefined,
                    home: phoneType === "home" ? formatAllCountryPhoneNumber(phoneNumber) : undefined,
                    work: phoneType === "work" ? formatAllCountryPhoneNumber(phoneNumber) : undefined,
                    personalEmail: email ? email : undefined,
                    mailingAddress: addressId
                };
                const updateContactResult = await updateContact(contactInput);
                if (updateContactResult.isSuccess) {
                    setContact(updateContactResult.result);
                    contactData = updateContactResult.result;
                } else {
                    setError(updateContactResult.message);
                }
            } else {
                const contactInput: CreateContactInput = {
                    name: name,
                    cell: phoneType === "cell" ? formatAllCountryPhoneNumber(phoneNumber) : undefined,
                    home: phoneType === "home" ? formatAllCountryPhoneNumber(phoneNumber) : undefined,
                    work: phoneType === "work" ? formatAllCountryPhoneNumber(phoneNumber) : undefined,
                    personalEmail: email ? email : undefined,
                    mailingAddress: addressId
                };
                const createContactResult = await createContact(contactInput);
                if (createContactResult.isSuccess) {
                    setContact(createContactResult.result);
                    contactData = createContactResult.result;
                } else {
                    setError(createContactResult.message);
                }
            }
            return contactData;
        } catch (error) {
            const message = "Could not save the contact information: " + error;
            setError(message);
        }
    }

    const handleGroupLeaderContact = async (leaderName: string, id: string| undefined | null, addressId?: string) => {
        try {
            let contactData: Contact | null = null;
            if (id) {
                const contactInput: UpdateContactInput = {
                    name: leaderName,
                    id: id,
                    cell: leaderPhoneType === "cell" ? formatAllCountryPhoneNumber(leaderPhoneNumber) : undefined,
                    home: leaderPhoneType === "home" ? formatAllCountryPhoneNumber(leaderPhoneNumber) : undefined,
                    work: leaderPhoneType === "work" ? formatAllCountryPhoneNumber(leaderPhoneNumber) : undefined,
                    personalEmail: leaderEmail ? leaderEmail : undefined,
                    mailingAddress: addressId
                };
                const updateContactResult = await updateContact(contactInput);
                if (updateContactResult.isSuccess) {
                    setLeaderContact(updateContactResult.result);
                    contactData = updateContactResult.result;
                } else {
                    setError(updateContactResult.message);
                }
            } else {
                const contactInput: CreateContactInput = {
                    name: leaderName,
                    cell: leaderPhoneType === "cell" ? formatAllCountryPhoneNumber(leaderPhoneNumber) : undefined,
                    home: leaderPhoneType === "home" ? formatAllCountryPhoneNumber(leaderPhoneNumber) : undefined,
                    work: leaderPhoneType === "work" ? formatAllCountryPhoneNumber(leaderPhoneNumber) : undefined,
                    personalEmail: leaderEmail ? leaderEmail : undefined,
                    mailingAddress: addressId
                };
                const createContactResult = await createContact(contactInput);
                if (createContactResult.isSuccess) {
                    setLeaderContact(createContactResult.result);
                    contactData = createContactResult.result;
                } else {
                    setError(createContactResult.message);
                }
            }
            return contactData;
        } catch (error) {
            const message = "Could not save the contact information: " + error;
            setError(message);
        }
    }

    const handleGroupMemberRider = async (groupMember: GroupMember) => {
        try { 
            let foundRider: Rider | null = null;
            
            // Try to find a rider in the DB that matches this groupMember by name
            if (groupMember.name !== "") {
                const queryByPersonNameResult = await getMembershipsByPersonName(groupMember.name);
                if (queryByPersonNameResult.isSuccess) {
                    const membershipArray: Membership[] = queryByPersonNameResult.result;
                    if (membershipArray && membershipArray.length > 0) {
                        for (let i = 0; i < membershipArray.length; i++) {
                            const currentMembership = membershipArray[i];
                            // Check if this barn matches the membership barn
                            if (barn && currentMembership.barnId === barn?.id) {
                                if (currentMembership.rider) foundRider = currentMembership.rider;
                            }
                            // Check if this organization matched the membership organization
                            else if (organization && currentMembership.organizationId === organization?.id) {
                                if (currentMembership.rider) foundRider = currentMembership.rider;
                            } 
                        }
                    }
                }
                if (!foundRider && barn) {
                    const queryResult = await getRidersByBarnId(barn.id);
                    if (queryResult.isSuccess) {
                        const riders: Rider[] = queryResult.result;
                        if (riders && riders.length > 0) {
                            for (let i = 0; i < riders.length; i++) {
                                const currentRider = riders[i];
                                if (currentRider.name.toLowerCase() === groupMember.name.toLowerCase()) {
                                    foundRider = currentRider;
                                }
                            }
                        }
                    }
                }
            }

            if (foundRider) {
                return foundRider;
            } else {
                const riderInput: CreateRiderInput = {
                    name: groupMember.name,
                    location: "",
                    riderContactId: "",
                    addressId: "",
                    personId: personId,
                    age: moment().diff(groupMember.birthDate, 'years'),
                    birthdate: groupMember.birthDate,
                    barnId: barn?.id,
                    barnName: barn?.name,
                    createdBy: personId,
                    createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                    updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                };
                const createRiderResult = await createRider(riderInput);
                if (createRiderResult.isSuccess) {
                    return createRiderResult.result;
                } else {
                    console.error(createRiderResult.message);
                }
            }
        } catch (error) {
            const message = "Could not save the rider information: " + error;
            setError(message);
        }
    }

    const getDateMembershipEnds = () => {
        try {
            const schedule =  membershipType.schedule;
            const isStartAtTimeOfPurchase = membershipType.isStartAtTimeOfPurchase;
            const setStartDate = membershipType.setStartDate;
            let dateMembershipEnds;
            if(schedule === MembershipTypeSchedule.ANNUAL) {
                if(isStartAtTimeOfPurchase) {
                    // End date should be one year after today's date (because the membership starts on the date of purchase)
                    dateMembershipEnds = moment().add(1, "year").format("YYYY-MM-DD");
                } else {
                    // End date should be one year after the set start date
                    dateMembershipEnds = moment(setStartDate).year((new Date()).getFullYear()).add(1, "year").format("YYYY-MM-DD");
                }
            } else if (schedule === MembershipTypeSchedule.LIFE) {
                dateMembershipEnds = "";
            }
            return dateMembershipEnds;
        } catch (error) {
            const message = "Could not get the date the membership should end: " + error;
            setError(message);
        }
    }

    const handleCreateGroupMembership = async (membershipId: string, groupContactId: string, groupLeaderContactId: string, groupMember?: GroupMember, riderData?: Rider) => {
        try {
            const dateMembershipEnds = getDateMembershipEnds();
            const personOnMembershipName = groupMember?.name || formatDisplayName(user?.firstName || "", "", user?.lastName || "") || "";
            const input: CreateMembershipInput = {
                name: organization && organization.name ? organization.name : "",
                membershipId: membershipId,
                backNumber: groupMember?.backNumber || 0,
                membershipStatus: CONSTANT.MEMBERSHIP.APPLICATION_STATUS.IN_PROGRESS,
                organizationMembershipTypeId: membershipType.id,
                type: membershipType.name,
                amountPaid: 0,
                personId: personId,
                createdBy: personId,
                personName: personOnMembershipName,
                personEmail: email,
                organizationName: organization?.name,
                organizationId: organization?.id,
                barnId: barn && barn.id ? barn.id : null,
                riderId: riderData && riderData.id ? riderData.id : null,
                dateMembershipEnds: dateMembershipEnds,
                groupContactId,
                groupLeaderContactId
            };
            const createMembershipResult = await createMembership(input);
            if (createMembershipResult.isSuccess) {
                return createMembershipResult.result;
            } else {
                setError(createMembershipResult.message);
            }
        } catch (error) {
            const message = "Could not create the membership: " + error;
            setError(message);
        }
    }

    const handleUpdateGroupMembership = async (currentMembership: Membership, groupContactId: string, groupLeaderContactId: string, groupMember?: GroupMember, riderData?: Rider) => {
        try {
            const personOnMembershipName = groupMember?.name || formatDisplayName(user?.firstName || "", "", user?.lastName || "") || "";
            const input: UpdateMembershipInput = {
                id: currentMembership.id,
                name: organization && organization.name ? organization.name : "",
                organizationMembershipTypeId: membershipType.id,
                type: membershipType.name,
                personId: personId,
                personName: personOnMembershipName,
                personEmail: email,
                organizationName: organization?.name,
                organizationId: organization?.id,
                barnId: barn && barn.id ? barn.id : null,
                riderId: riderData && riderData.id ? riderData.id : null,
                groupContactId,
                groupLeaderContactId
            };
            const updateMembershipResult = await updateMembership(input);
            if (updateMembershipResult.isSuccess) {
                return updateMembershipResult.result;
            } else {
                setError(updateMembershipResult.message);
            }
        } catch (error) {
            const message = "Could not save the membership: " + error;
            setError(message);
        }
    }

    const handleGroupMembership = async () => {
        // Use the same membership id for all of the memberships
        let membershipId = "";
        if(memberships?.length) membershipId = memberships[0].membershipId;
        else if (membershipType.applicationOptions?.autoAssignIds) membershipId = membershipType.nextAvailableMembershipId || "";

        let groupContactId = "";
        let groupLeaderContactId = "";
        const membershipArray: Membership[] = [];

        if (groupMembers && groupMembers.length > 0) {
            // Loop through the members and create a membership for each
            for (let i = 0; i < groupMembers.length; i++) {
                const currentGroupMember = groupMembers[i];
                // do not create membership if name is absent
                if(!currentGroupMember?.name) {
                    continue;
                }
                let currentMembership: Membership | undefined | void;
                if(memberships && memberships?.length > 0) {
                    currentMembership = memberships?.find((m) => {
                        return m.personName?.toLowerCase() === currentGroupMember?.name?.toLowerCase();
                    });
                }
                if (currentGroupMember) {
                    // Second, create and update the Contact Object
                    let contactData;
                    let leaderContactData;
                    let addressData;
                    let leaderAddressData;
                    if (i === 0 && (membershipType.applicationFields?.groupName || membershipType.applicationFields?.contact)) {
                        // create/update address record and store the address id in contact's mailingAddress field
                        if(membershipType.applicationFields?.contact) {
                            addressData = await handleAddress(
                                groupTeamName, contact?.mailingAddress
                            );
                        }
                        contactData = await handleContact(
                            groupTeamName, currentMembership?.groupContactId, addressData?.id
                        );
                        groupContactId = contactData?.id || "";
                    }
                    if (i === 0 && (membershipType.applicationFields?.groupLeader || membershipType.applicationFields?.groupLeaderContactInfo)) {
                        if(membershipType.applicationFields?.groupLeaderContactInfo) {
                            leaderAddressData = await handleGroupLeaderAddress(
                                leaderName, leaderContact?.mailingAddress
                            );
                        }
                        leaderContactData = await handleGroupLeaderContact(
                            leaderName, currentMembership?.groupLeaderContactId, leaderAddressData?.id
                        );
                        groupLeaderContactId = leaderContactData?.id || "";
                    }

                    const riderData: Rider = await handleGroupMemberRider(currentGroupMember);
                    if (currentMembership) {
                        const membershipResult = await handleUpdateGroupMembership(currentMembership, groupContactId, groupLeaderContactId, currentGroupMember, riderData);
                        membershipArray.push(membershipResult);
                    } else {
                        const membershipResult = await handleCreateGroupMembership(membershipId, groupContactId, groupLeaderContactId, currentGroupMember, riderData);
                        membershipArray.push(membershipResult);
                    }
                }
            }
        } else {
            let contactData;
            let addressData;
            let leaderContactData;
            let leaderAddressData;

            if (membershipType.applicationFields?.groupName || membershipType.applicationFields?.contact) {
                // create/update address record and store the address id in contact's mailingAddress field
                if(membershipType.applicationFields?.contact) {
                    addressData = await handleAddress(groupTeamName, contact?.mailingAddress);
                }
                contactData = await handleContact(
                    groupTeamName, membership?.groupContactId, addressData?.id
                );
                groupContactId = contactData?.id || "";
            }

            if (membershipType.applicationFields?.groupLeader || membershipType.applicationFields?.groupLeaderContactInfo) {
                if (membershipType.applicationFields?.groupLeaderContactInfo) {
                    leaderAddressData = await handleGroupLeaderAddress(
                        leaderName, leaderContact?.mailingAddress
                    );
                }
                leaderContactData = await handleGroupLeaderContact(
                    leaderName, membership?.groupLeaderContactId, leaderAddressData?.id
                );
                groupLeaderContactId = leaderContactData?.id || "";
            }

            if (membership) {
                const membershipResult = await handleUpdateGroupMembership(membership, groupContactId, groupLeaderContactId, undefined, undefined);
                membershipArray.push(membershipResult);
            } else {
                const membershipResult = await handleCreateGroupMembership(membershipId, groupContactId, groupLeaderContactId, undefined, undefined);
                membershipArray.push(membershipResult);
            }
        }
        
        // Finally, update the nextAvailableMembershipId
        if(membershipType.applicationOptions?.autoAssignIds && membershipType?.membershipIdValues && memberships && memberships?.length === 0) {
            const newMembershipId = getNextAvailableMembershipId(membershipType?.membershipIdValues, membershipType.nextAvailableMembershipId || "");
            const updateInput:  UpdateOrganizationMembershipTypeInput = {
                id: membershipType.id,
                nextAvailableMembershipId: newMembershipId
            };
            await updateOrganizationMembershipType(updateInput);
        }

        setMemberships(membershipArray);
    }

    const verifyIsValid = () => {
        if(membershipType.applicationFields?.groupName && !groupTeamName) {
            setError(`A required field is missing data: ${groupOrTeam} Name`);
            return false;
        }
        if (membershipType.applicationFields?.collectMemberDetailsTogether && (!groupMembers || groupMembers.length === 0 || !groupMembers[0]?.name)) {
            setError("A required field is missing data: Group Member #1 Full Name");
            return false;
        }
        if(membershipType.applicationFields?.contact) {
            if (!email) {
                setError(`A required field is missing data(${groupOrTeam} Contact Info): Email Address`);
                return false;
            }
            if (!phoneType) {
                setError(`A required field is missing data(${groupOrTeam} Contact Info): Phone Number Type`);
                return false;
            }
            if (!phoneNumber) {
                setError(`A required field is missing data(${groupOrTeam} Contact Info): Phone Number`);
                return false;
            }
            if (!streetAddressLine1) {
                setError(`A required field is missing data(${groupOrTeam} Contact Info): Street Address Line1`);
                return false;
            }
            if (!city) {
                setError(`A required field is missing data(${groupOrTeam} Contact Info): City`);
                return false;
            }
            if (!provState) {
                setError(`A required field is missing data(${groupOrTeam} Contact Info): State`);
                return false;
            }
            if (!zip) {
                setError(`A required field is missing data(${groupOrTeam} Contact Info): Postal Code`);
                return false;
            }
            if (!country) {
                setError(`A required field is missing data(${groupOrTeam} Contact Info): Country`);
                return false;
            }
        }
        if(membershipType.applicationFields?.groupLeader && !leaderName) {
            setError(`A required field is missing data: ${isAELOrganization ? "Head Coach" : groupOrTeam + "Leader"} Name`);
            return false;
        }
        if(membershipType.applicationFields?.groupLeaderContactInfo) {
            if (!leaderEmail) {
                setError(`A required field is missing data(${groupOrTeam} Leader Contact Info): Email Address`);
                return false;
            }
            if (!leaderPhoneType) {
                setError(`A required field is missing data(${groupOrTeam} Leader Contact Info): Phone Number Type`);
                return false;
            }
            if (!leaderPhoneNumber) {
                setError(`A required field is missing data(${groupOrTeam} Leader Contact Info): Phone Number`);
                return false;
            }
            if (!leaderStreetAddressLine1) {
                setError(`A required field is missing data(${groupOrTeam} Leader Contact Info): Street Address Line1`);
                return false;
            }
            if (!leaderCity) {
                setError(`A required field is missing data(${groupOrTeam} Leader Contact Info): City`);
                return false;
            }
            if (!leaderProvState) {
                setError(`A required field is missing data(${groupOrTeam} Leader Contact Info): State`);
                return false;
            }
            if (!leaderZip) {
                setError(`A required field is missing data(${groupOrTeam} Leader Contact Info): Postal Code`);
                return false;
            }
            if (!leaderCountry) {
                setError(`A required field is missing data(${groupOrTeam} Leader Contact Info): Country`);
                return false;
            }
        }

        return true;
    };

    const handleSubmit = async () => {
        setError("");
        const isValid = verifyIsValid();
        if (isValid) {
            setIsLoading(true);
            try {
                await handleGroupMembership();
            } catch (error: any) {
                const message = "Could not create or update the membership: " + error;
                setError(message);
            }
            setIsLoading(false);   
        }
    };

    return (
        <div className={isDisabled ? "bg-light" : ""}>
            {isLoading ?
                <Spinner />
                :
                
                <>
                    {error && <ErrorAlert width="12" error={error} />}

                    {/* Group Membership - Collect Basic Info */}
                    {membershipType && membershipType.category === MembershipTypeCategory.GROUP && 
                        <IonRow className="mt-3 mb-0">
                            <IonCol className="ion-text-center">
                                <h4>{groupOrTeam} Basic Info</h4>
                            </IonCol>
                            {membershipType.applicationFields?.groupName && 
                                <IonCol sizeXs="12" sizeMd="12">
                                    <IonItem color="white">
                                        <IonLabel position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                                            {groupOrTeam} Name{" "}
                                            <RequiredInputIndicator />
                                        </IonLabel>
                                        <IonInput
                                            id="group-team-name"
                                            type="text"
                                            value={groupTeamName}
                                            aria-required={true}
                                            onIonChange={(e) => {
                                                setError("");
                                                setGroupTeamName(e.detail.value!);
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            }
                        </IonRow>
                    }

                    {/* Group Membership - collect all names and birthdays */}
                    {((groupMembers && groupMembers.length > 0) && !isAELOrganization && (
                        <>
                            {groupMembers.map((groupMember: (GroupMember | null), i: number) => {
                                return (
                                    <>
                                        <IonRow key={i}>
                                            <IonCol sizeXs="12" sizeMd="4">
                                                <IonItem color="white">
                                                    <IonLabel position="stacked">
                                                        {organization?.id === "ddc2fd3c-0bde-4b39-bbbb-5e00d2a35ba0" ? "Your" : "Member #" + i+1} Full Name
                                                    </IonLabel>
                                                    <IonInput
                                                        class="membership-app-basicInfo-groupMember-fullName"
                                                        type="text"
                                                        value={groupMember?.name || ""}
                                                        aria-required={true}
                                                        onIonChange={(e) => {
                                                            setError("");
                                                            handleGroupMemberNameChange(i, e.detail.value!);
                                                        }}
                                                    />
                                                </IonItem>
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="4">
                                                <IonItem color="white">
                                                    <IonLabel position="stacked">
                                                        {organization?.id === "ddc2fd3c-0bde-4b39-bbbb-5e00d2a35ba0" ? "Your" : "Member #" + i+1} Birth Date
                                                    </IonLabel>
                                                    <IonInput 
                                                        class="membership-app-basicInfo-groupMember-birthDate"
                                                        type="date"
                                                        value={groupMember?.birthDate || ""}
                                                        aria-required={true}
                                                        onIonChange={e => {
                                                            handleGroupMemberBirthDateChange(i, e.detail.value!)
                                                        }}
                                                    />
                                                </IonItem>
                                            </IonCol>
                                            {membershipType &&
                                                membershipType.applicationFields?.backNumber && (
                                                    <IonCol sizeXs="12" sizeMd="4">
                                                        <IonItem color="white">
                                                            <IonLabel position="stacked">
                                                                Member #{i+1} Back Number(s)
                                                            </IonLabel>
                                                            <IonInput 
                                                                type="text"
                                                                value={groupMember?.backNumber ? groupMember.backNumber : ""}
                                                                aria-required={true}
                                                                disabled={true}
                                                            />
                                                        </IonItem>
                                                    </IonCol>
                                            )}
                                        </IonRow>
                                    </>
                                )
                            })}
                            {membershipType && 
                                membershipType.category === MembershipTypeCategory.GROUP && 
                                membershipType.applicationFields?.backNumber && (
                                <p className="ion-text-wrap description">
                                    (You cannot edit back numbers. The admin will handle this. 
                                    This will show at most one back number - the admin will add any additional back numbers later.)
                                </p>
                            )}
                        </>
                    ))}

                    {/* Group Membership - Collect Group Contact Info */}
                    {membershipType && membershipType.applicationFields?.contact && 
                        <>
                            <IonRow className="mt-3 mb-0">
                                <IonCol className="ion-text-center">
                                    <hr />
                                    <h4>{groupOrTeam} Contact Info</h4>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mb-3">
                                <IonCol sizeXs="12" sizeMd="12">
                                    <IonItem color="white">
                                        <IonLabel
                                            position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                                            Email Address{" "}
                                            <RequiredInputIndicator />
                                        </IonLabel>
                                        <IonInput
                                            id="membership-app-basicInfo-email"
                                            type="email"
                                            required={true}
                                            aria-required={true}
                                            value={email}
                                            onIonChange={(e) => {
                                                setError("");
                                                setEmail(e.detail.value!);
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mb-3">
                                <IonCol sizeXs="12" sizeMd="6">
                                    <PhoneNumberTypeSelect
                                        id="membership-app-phone-number"
                                        isRequired={true}
                                        selectedType={phoneType}
                                        onChange={handlePhoneNumberTypeSelectInput}
                                    />
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonLabel className="pl-3 text-darker" position="stacked">
                                        Phone Number{" "}
                                        <RequiredInputIndicator />
                                    </IonLabel>
                                    <PhoneInput
                                        inputProps={{
                                            name: "phone",
                                            required: true,
                                            autoFocus: true,
                                            id:"membership-app-basicInfo-phone-number"
                                        }}
                                        placeholder="Enter phone number"
                                        country={"us"}
                                        enableSearch
                                        enableAreaCodes={false}
                                        inputStyle={{
                                            width: "100%",
                                            height: "auto",
                                        }}
                                        value={phoneNumber}
                                        onChange={(phoneNumber) => {
                                            setError("");
                                            setPhoneNumber(phoneNumber);
                                        }}
                                    />
                                </IonCol>
                            </IonRow>
                            <AddressFormGroup
                                id="membership-app-basicInfo"
                                isRequired={true}
                                isTextDarker={true}
                                onChange={handleAddressInputChange}
                                address={address}
                                setIsDisabled={() => {}}
                            />
                        </>
                    }

                    {/* Group Membership - Collect Group Leader Info */}
                    {membershipType && membershipType.applicationFields?.groupLeader && 
                        <IonRow className="mt-3 mb-0">
                            <IonCol className="ion-text-center">
                                <h4>
                                    {isAELOrganization ?
                                        `Head Coach`
                                        :
                                        `${groupOrTeam} Leader Info`
                                    }
                                </h4>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="12">
                                <IonItem color="white">
                                    <IonLabel position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                                        {isAELOrganization ?
                                            `Head Coach Name`
                                            :
                                            `${groupOrTeam} Leader Name`
                                        }
                                        <RequiredInputIndicator />
                                    </IonLabel>
                                    <IonInput
                                        id="group-team-leader-name"
                                        type="text"
                                        value={leaderName}
                                        aria-required={true}
                                        onIonChange={(e) => {
                                            setError("");
                                            setLeaderName(e.detail.value!);
                                        }}
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    }

                    {/* Group Membership - Collect Group Leader Contact Info */}
                    {membershipType && membershipType.applicationFields?.groupLeaderContactInfo && 
                        <>
                            <IonRow className="mt-3 mb-0">
                                <IonCol className="ion-text-center">
                                    <hr />
                                    <h4>{groupOrTeam} Leader Contact Info</h4>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mb-3">
                                <IonCol sizeXs="12" sizeMd="12">
                                        <IonItem color="white">
                                            <IonLabel
                                                position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                                                Email Address{" "}
                                                <RequiredInputIndicator />
                                            </IonLabel>
                                            <IonInput
                                                id="membership-app-leader-contact-email"
                                                type="email"
                                                required={true}
                                                aria-required={true}
                                                value={leaderEmail}
                                                onIonChange={(e) => {
                                                    setError("");
                                                    setLeaderEmail(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <PhoneNumberTypeSelect
                                            id="membership-app-leader-phone-number"
                                            isRequired={true}
                                            selectedType={leaderPhoneType}
                                            onChange={handleLeaderPhoneNumberTypeSelectInput}
                                        />
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="pl-3 text-darker" position="stacked">
                                            Phone Number{" "}
                                            <RequiredInputIndicator />
                                        </IonLabel>
                                        <PhoneInput
                                            inputProps={{
                                                name: "phone",
                                                required: true,
                                                autoFocus: true,
                                                id:"membership-app-leader-phone-number"
                                            }}
                                            placeholder="Enter phone number"
                                            country={"us"}
                                            enableSearch
                                            enableAreaCodes={false}
                                            inputStyle={{
                                                width: "100%",
                                                height: "auto",
                                            }}
                                            value={leaderPhoneNumber}
                                            onChange={(phoneNumber) => {
                                                setError("");
                                                setLeaderPhoneNumber(phoneNumber);
                                            }}
                                        />
                                    </IonCol>
                                    <AddressFormGroup
                                        id="membership-app-basicInfo-group-leader"
                                        isRequired={true}
                                        isTextDarker={true}
                                        onChange={handleLeaderAddressInputChange}
                                        address={leaderAddress}
                                        setIsDisabled={() => {}}
                                    />
                            </IonRow>
                        </>
                    }

                    {/* Header for Additional Info */}
                    {membershipType && (membershipType.applicationFields?.barnName) && (
                        <IonRow className="mt-3 mb-0">
                            <IonCol className="ion-text-center">
                                <hr />
                                <h4>Additional Info</h4>
                            </IonCol>
                        </IonRow>
                    )}

                     {/* Group Membership - Collect Additional Info */}
                    <IonRow>
                        {membershipType.applicationFields?.barnName && (
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonLabel className="pl-3 text-darker" position="stacked">
                                    Barn{" "}
                                </IonLabel>
                                <SelectFromAllBarns
                                    barn={barn}
                                    selectedValue={barn?.id}
                                    isHeightRequired={true}
                                    height={"45px"}
                                    onSelect={(barn: Barn) =>
                                        handleSelectBarn(barn)
                                    }
                                />
                            </IonCol>)}
                    </IonRow>

                    <hr/>
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                            <IonButton
                                id="mt-basic-info-save-btn"
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                {membership ? "Edit" : "Save"}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
            }
        </div>
    );
};

export default MembershipApplicationBasicInfo;
