import { adminMenuOptions, eventMenuOptions, judgeMenuOptions, ownerMenuOptions, Page, riderMenuOptions, trainerMenuOptions } from "../../interfaces/Page";

export interface Role {
    id: number
    name: string
    value: string
    displayInWelcomeModal: boolean
    color: string
    isChild: boolean
    parent?: Role,
    routes?: Page[]
}

export interface TextAlertRoleFilter {
    id: number
    name: string
    value: string
    display: boolean,
    children?: TextAlertRoleFilter[] | null
}

export const blankRoles: Role[] = [];

export const Roles = [
    {
        id: 1,
        name: "Rider",
        value: "rider",
        displayInWelcomeModal: true,
        // color: "#004E89",
        color: "success",
        isChild: false,
        routes: riderMenuOptions
    },
    {
        id: 2,
        name: "Horse Owner",
        value: "owner",
        displayInWelcomeModal: true,
        // color: "#501537",
        color: "tertiary",
        isChild: false,
        routes: ownerMenuOptions
    },
    {
        id: 3,
        name: "Parent",
        value: "parent",
        displayInWelcomeModal: true,
        // color: "#922D50",
        color: "warning",
        isChild: false
    },
    {
        id: 4,
        name: "Trainer or Coach",
        value: "trainer",
        displayInWelcomeModal: true,
        // color: "#CAF7E2",
        color: "danger",
        isChild: false,
        routes: trainerMenuOptions
    },
    {
        id: 5,
        name: "Barn Staff",
        value: "barn",
        displayInWelcomeModal: true,
        // color: "#D88373",
        color: "medium",
        isChild: false
    },
    {
        id: 6,
        name: "Event Staff",
        value: "show",
        displayInWelcomeModal: true,
        // color: "004E89",
        color: "dark",
        isChild: false,
        routes: eventMenuOptions
    },
    {
        id: 7,
        name: "Judge",
        value: "judge",
        displayInWelcomeModal: true,
        // color: "#DAF7DC",
        color: "primary",
        isChild: false,
        routes: judgeMenuOptions
    },
    {
        id: 8,
        name: "Assoc. Rep.",
        value: "assoc",
        displayInWelcomeModal: true,
        // color: "#D88373",
        color: "secondary",
        isChild: false
    },
    {
        id: 9,
        name: "Show Sponsor",
        value: "sponsor",
        displayInWelcomeModal: true,
        // color: "#004E89",
        color: "primary",
        isChild: false
    },
    {
        id: 10,
        name: "Admin",
        value: "admin",
        displayInWelcomeModal: false,
        // color: "#004E89",
        color: "primary",
        isChild: false,
        routes: adminMenuOptions
    },
];

export const roles = [
    "Rider",
    "Horse Owner",
    "Parent",
    "Trainer or Coach",
    "Barn Manager",
    "Event Staff",
    "Judge",
    "Assoc. Rep"
];

export const TextAlertRolesFilters: TextAlertRoleFilter[] = [
    {
        id: 1,
        name: "Trainer or Coach",
        value: "trainer",
        display: true,
        children: null
    },
    {
        id: 2,
        name: "Rider",
        value: "rider",
        display: true,
        children: [
            {
                id: 2.1,
                name: "Professional",
                value: "professional",
                display: true,
                children: null
            },
            {
                id: 2.2,
                name: "Amateur",
                value: "amateur",
                display: true,
                children: null
            },
            {
                id: 2.3,
                name: "Junior",
                value: "junior",
                display: true,
                children: null
            }
        ]
    },
    {
        id: 3,
        name: "Parent",
        value: "parent",
        display: true,
        children: null
    },
    {
        id: 4,
        name: "Horse Owner",
        value: "owner",
        display: true,
        children: null
    },
    {
        id: 5,
        name: "Barn Owner / Barn Staff",
        value: "barn",
        display: true,
        children: [
            {
                id: 5.1,
                name: "Barn Owner",
                value: "barn owner",
                display: true,
                children: null
            },
            {
                id: 5.2,
                name: "Barn Staff",
                value: "barn worker",
                display: true,
                children: null
            }
        ]
    },
    {
        id: 6,
        name: "Event Staff",
        value: "event staff",
        display: true,
        children: null
    },
    {
        id: 7,
        name: "Spectator",
        value: "spectator",
        display: true,
        children: null
    },
    {
        id: 8,
        name: "Vendor",
        value: "vendor",
        display: true,
        children: null
    },
    {
        id: 9,
        name: "Other",
        value: "other",
        display: true,
        children: null
    }
];

export const TextAlertRolesFiltersForPonyFinals: TextAlertRoleFilter[] = [
    {
        id: 1,
        name: "Trainer or Coach",
        value: "trainer",
        display: true,
        children: null
    },
    {
        id: 2,
        name: "Rider",
        value: "rider",
        display: true,
        children: null
    },
    {
        id: 3,
        name: "Parent",
        value: "parent",
        display: true,
        children: null
    },
    {
        id: 4,
        name: "Horse Owner",
        value: "owner",
        display: true,
        children: null
    },
    {
        id: 5,
        name: "Barn Owner / Barn Staff",
        value: "barn",
        display: true,
        children: [
            {
                id: 5.1,
                name: "Barn Owner",
                value: "barn owner",
                display: true,
                children: null
            },
            {
                id: 5.2,
                name: "Barn Staff",
                value: "barn worker",
                display: true,
                children: null
            }
        ]
    },
    {
        id: 6,
        name: "Event Staff",
        value: "event staff",
        display: true,
        children: null
    },
    {
        id: 7,
        name: "Spectator",
        value: "spectator",
        display: true,
        children: null
    },
    {
        id: 8,
        name: "Vendor",
        value: "vendor",
        display: true,
        children: null
    },
    {
        id: 9,
        name: "Other",
        value: "other",
        display: true,
        children: null
    }
];

export const TextAlertRolesFiltersForIHSAFinals: TextAlertRoleFilter[] = [
    {
        id: 1,
        name: "Coach",
        value: "trainer",
        display: true,
        children: null
    },
    {
        id: 2,
        name: "Rider",
        value: "rider",
        display: true,
        children: null
    },
    {
        id: 3,
        name: "Parent",
        value: "parent",
        display: true,
        children: null
    },
    {
        id: 4,
        name: "Horse Provider",
        value: "owner",
        display: true,
        children: null
    },
    {
        id: 5,
        name: "Event Volunteer",
        value: "volunteer",
        display: true,
        children: null
    },
    {
        id: 6,
        name: "Event Staff",
        value: "event staff",
        display: true,
        children: null
    },
    {
        id: 7,
        name: "Spectator",
        value: "spectator",
        display: true,
        children: null
    },
    {
        id: 8,
        name: "Vendor",
        value: "vendor",
        display: true,
        children: null
    },
    {
        id: 9,
        name: "Other",
        value: "other",
        display: true,
        children: null
    }
];

export const TextAlertRolesFiltersForLAECOrg: TextAlertRoleFilter[] = [
    {
        id: 1,
        name: "Trainer",
        value: "trainer",
        display: true,
        children: null
    },
    {
        id: 2,
        name: "Rider",
        value: "rider",
        display: true,
        children: null
    },
    {
        id: 3,
        name: "Parent/Guardian",
        value: "parent",
        display: true,
        children: null
    },
    {
        id: 4,
        name: "Horse Owner/Boarder",
        value: "owner",
        display: true,
        children: null
    },
    {
        id: 5,
        name: "Barn Staff",
        value: "barn worker",
        display: true,
        children: null
    },
    {
        id: 6,
        name: "LAEC Staff",
        value: "event staff",
        display: true,
        children: null
    },
    {
        id: 7,
        name: "Other",
        value: "other",
        display: true,
        children: null
    }
];