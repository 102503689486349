import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import Spinner from "../../Spinners/Spinner";
import { Alert, Block, Event, OrganizationAuditor, EventFilter, Organization } from "../../../models";
import ErrorAlert from "../../Errors/ErrorAlert";
import { getEventFiltersByEventId, getEventFiltersByOrganizationId } from "../../../utilities/eventFilter/EventFilter";
import { close } from "ionicons/icons";
import InformationBanner from "../../Banners/InformationBanner";
import { getActiveOrganizationAuditorsWithFilterByOrganizationId } from "../../../utilities/organizationAuditor/OrganizationAuditor";
import { Badge, FormGroup, Input, UncontrolledTooltip } from "reactstrap";
import { getValidBlocksByOrganizationId } from "../../../utilities/block/Block";
import { getCreditCountFromBlocks } from "../../../utilities/block/CreditCount";
import SelectEventAuditors from "../../EventAuditor/SelectEventAuditor";
import SelectEventAlertFiltersForm, { FormattedFilter } from "./SelectEventAlertFiltersForm";
import { getOrganizationAuditorsByFilters } from "../../../utilities/eventFilter/FilterOrganizationAuditors";
import { createAlert } from "../../../utilities/alerts/Alerts";
import { PersonContext } from "../../../context/PersonContext";
import { handleSendMassOrgSMS } from "../../../utilities/twilio/TwilioSMS";
import SuccessBanner from "../../Banners/SuccessBanner";
import BuyCreditsForm from "./BuyCreditsForm";
import { CreateAlertInput } from "../../../API";
import { useSubscriptionByItself } from "../../../utilities/subscription/Subscription";
import { onCreateBlock, onCreateOrganizationAuditor } from "../../../graphql/subscriptions";
import { sortBlocksByDateOldest } from "../../../utilities/block/SortBlocks";
import AlertImageForm from "./AlertImageForm";
import { createMMSPictureFileName, handleUploadS3Image } from "../../../utilities/s3Object/s3Object";
import awsmobile from "../../../aws-exports";
import SelectEventFilterRolesOptionForm, { FormattedRoleOption } from "./EventFilterRolesOptionForm";
import { TextAlertRoleFilter, TextAlertRolesFilters } from "../../../utilities/roles/Roles";

interface _Props {
    organization: Organization
}

export interface FormattedBlock {
    id: string
    creditsToUse: number
    setBlockToEmpty: boolean
    block: Block
}

const SendOrgAlertForm: React.FC<_Props> = ({organization}) => {
    const user = useContext(PersonContext);

    const blockSubscription = useSubscriptionByItself({
        config: {
            query: onCreateBlock,
            key: "onCreateBlock"
        }
    });

    const auditorSubscription = useSubscriptionByItself({
        config: {
            query: onCreateOrganizationAuditor,
            key: "onCreateOrganizationAuditor"
        }
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isCountingCreditsNeeded, setIsCountingCredits] = useState(false);
    const [isUnlimitedCredits, setIsUnlimitedCredits] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [currentBlockSubscriptionItem, setCurrentBlockSubscriptionItem] = useState<any>();
    const [currentAuditorSubscriptionItem, setCurrentAuditorSubscriptionItem] = useState<any>();
    const [isGeneral, setIsGeneral] = useState(true);
    const [isFiltered, setIsFiltered] = useState(false);
    const [isCustom, setIsCustom] = useState(false);
    const [eventFilters, setEventFilters] = useState<EventFilter[] | null | undefined>();
    const [formattedRoleOptions, setFormattedRoleOptions] = useState<FormattedRoleOption[] | null | undefined>();
    const [formattedFilters, setFormattedFilters] = useState<FormattedFilter[] | null | undefined>();
    const [organizationAuditors, setOrganizationAuditors] = useState<(OrganizationAuditor | OrganizationAuditor)[] | null | undefined>();
    const [selectedOrganizationAuditors, setSelectedOrganizationAuditors] = useState<(OrganizationAuditor | OrganizationAuditor)[] | null | undefined>();
    // const [currentSentCount, setCurrentSentCount] = useState(0);
    const [messageBody, setMessageBody] = useState("");
    const [characterCount, setCharacterCount] = useState(0);
    const [messageSegmentCount, setMessageSegmentCount] = useState(1);
    const [image, setImage] = useState<File | null | undefined>();
    const [creditsNeeded, setCreditsNeeded] = useState(0);
    const [validOrgBlocks, setValidOrgBlocks] = useState<Block[] | null | undefined>();
    const [formattedBlocks, setFormattedBlocks] = useState<FormattedBlock[] | null | undefined>();
    const [totalCreditCount, setTotalCreditCount] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showCreditsModal, setShowCreditModal] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const formatEventRoleFilters = () => {
        let options: FormattedRoleOption[] = [];
        let selectedArray: string[] = [];
        if (TextAlertRolesFilters) {
            for (var i = 0; i < TextAlertRolesFilters.length; i++) {
                const currentEventFilter: TextAlertRoleFilter = TextAlertRolesFilters[i];

                // For the roles filter, we typically will start with none selected and make the person pick
                let isSelected = true; 

                let childrenArray: (FormattedRoleOption[] | null) = null;

                if (currentEventFilter.children) {
                    childrenArray = [];
                    const childArray = currentEventFilter.children;
                    for (var j = 0; j < childArray.length; j++) {
                        const currentEventFilter: TextAlertRoleFilter = childArray[j];
        
                        // For the roles filter, we typically will start with none selected and make the person pick
                        let isChildSelected = true;

                        const formattedOption: FormattedRoleOption = {
                            name: currentEventFilter.name,
                            value: currentEventFilter.value,
                            isSelected: isChildSelected
                        };

                        childrenArray.push(formattedOption);
                    }
                }

                const formattedOption: FormattedRoleOption = {
                    name: currentEventFilter.name,
                    value: currentEventFilter.value,
                    isSelected: isSelected,
                    children: childrenArray || undefined
                };

                options.push(formattedOption);
                selectedArray.push(currentEventFilter.value);
            }
        }
        setFormattedRoleOptions(options);
    }

    async function getEventFiltersByOrganization(organization: Organization) {
        const queryResult = await getEventFiltersByOrganizationId(organization.id);
        if (queryResult.isSuccess) {
            setEventFilters(queryResult.result);
        }
    }

    async function getOrganizationAuditors(org: Organization) {
        const queryResult = await getActiveOrganizationAuditorsWithFilterByOrganizationId(org.id);
        if (queryResult.isSuccess) {
            setOrganizationAuditors(queryResult.result);

            // Start with all event auditors "selected"
            setSelectedOrganizationAuditors(queryResult.result);
        }
    }

    async function getOrganizationAuditorsAndFilter(event: Event) {
        const queryResult = await getActiveOrganizationAuditorsWithFilterByOrganizationId(event.id);
        if (queryResult.isSuccess) {
            const organizationAuditors = queryResult.result;
            setOrganizationAuditors(organizationAuditors);
            if (organizationAuditors) {
                if (isGeneral) {
                    setSelectedOrganizationAuditors(organizationAuditors);
                    calculateValues(undefined, organizationAuditors);
                } 
                else if (isFiltered && formattedRoleOptions && formattedFilters) {
                    const queryFilterResult = await getOrganizationAuditorsByFilters(organizationAuditors, formattedRoleOptions, formattedFilters);
                    if (queryFilterResult.isSuccess) {
                        setSelectedOrganizationAuditors(queryFilterResult.result);
                        calculateValues(undefined, queryFilterResult.result);
                    }
                } 
                else {
                    // No change
                }
            }
        }
    }

    function formatCreditBlocks(blocks: Block[]) {
        let results: FormattedBlock[] = [];
        blocks.forEach(block => {
            const formatted: FormattedBlock = {
                id: block.id,
                creditsToUse: 0,
                setBlockToEmpty: false,
                block: block
            };
            results.push(formatted);
        });
        setFormattedBlocks(results);
    }

    async function getBlocksByOrganizationId(organizationId: string) {
        if (organizationId) {
            const queryResult = await getValidBlocksByOrganizationId(organizationId);
            if (queryResult.isSuccess) {
                const sorted = sortBlocksByDateOldest(queryResult.result);
                setValidOrgBlocks(sorted || queryResult.result);
                formatCreditBlocks(sorted || queryResult.result);
                setTotalCreditCount(getCreditCountFromBlocks(queryResult.result));
            }
        }
    }

    useEffect(() => {
        if (organization) {
            formatEventRoleFilters();
            getEventFiltersByOrganization(organization);
            getOrganizationAuditors(organization);
            getBlocksByOrganizationId(organization.id);
        }
    }, [organization]);

    useEffect(() => {
        if (blockSubscription && blockSubscription[0] !== undefined && blockSubscription[0] !== currentBlockSubscriptionItem) {
            setCurrentBlockSubscriptionItem(blockSubscription[0]);
            if (organization) {
                getBlocksByOrganizationId(organization.id);
            }
        }
    }, [blockSubscription]);

    useEffect(() => {
        if (auditorSubscription && auditorSubscription[0] !== undefined && auditorSubscription[0] !== currentAuditorSubscriptionItem) {
            setCurrentAuditorSubscriptionItem(auditorSubscription[0]);
            if (organization) {
                getOrganizationAuditorsAndFilter(organization);
            }
        }
    }, [auditorSubscription]);

    const calculateValues = (currentMessageBody?: (string | null), currentSelectedOrganizationAuditors?: (OrganizationAuditor[] | null), file?: (File | null)) => {
        setIsCountingCredits(true);

        let body = currentMessageBody || messageBody;
        let subscribers = currentSelectedOrganizationAuditors || selectedOrganizationAuditors;
        let imageFile = file || image;
        const charCount = body.length;
        const messageSegmentCount = Math.ceil(charCount/160);

        if (body === "" && !imageFile) {
            setCharacterCount(0);
            setMessageSegmentCount(0);
            setCreditsNeeded(0);
        } else if (body === "" && imageFile) {
            const newSegmentCount = 2;
            setMessageSegmentCount(newSegmentCount);
            if (subscribers) {
                setCreditsNeeded(subscribers?.length * newSegmentCount);
            }
        } else if (body !== "" && !imageFile) {
            setCharacterCount(charCount);
            setMessageSegmentCount(messageSegmentCount);
            if (subscribers) {
                setCreditsNeeded(subscribers?.length * messageSegmentCount);
            }
        } else {
            const newSegmentCount = messageSegmentCount + 2;
            setCharacterCount(charCount);
            setMessageSegmentCount(newSegmentCount);
            if (subscribers) {
                setCreditsNeeded(subscribers?.length * newSegmentCount);
            }
        }
        setIsCountingCredits(false);
    }

    const handleOptionChange = (value: string) => {
        setIsGeneral(value === "general");
        setIsFiltered(value === "filtered");
        setIsCustom(value === "custom");

        if (value === "custom") {
            // If custom, start with no selected auditors
            setSelectedOrganizationAuditors([]);
            calculateValues(undefined, []);
        } else {
            // otherwise, set Auditors to "all"
            setSelectedOrganizationAuditors(organizationAuditors);
            calculateValues(undefined, organizationAuditors);
        }
    }

    const handleRoleFilterSelect = async (formattedOptions: FormattedRoleOption[]) => {
        setIsCountingCredits(true);
        setFormattedRoleOptions(formattedOptions);
        if (organizationAuditors) {
            const queryResult = await getOrganizationAuditorsByFilters(organizationAuditors, formattedOptions, (formattedFilters || undefined));
            if (queryResult.isSuccess) {
                setSelectedOrganizationAuditors(queryResult.result);
                calculateValues(undefined, queryResult.result);
            }
        }
        else {
            setError("Could not find any subscribers to filter. Try reloading the page.");
        }
        setIsDisabled(false);
        setIsCountingCredits(false);
    }

    const handleFilterSelect = async (formattedFilters: FormattedFilter[]) => {
        setIsCountingCredits(true);
        setFormattedFilters(formattedFilters);
        if (organizationAuditors && formattedRoleOptions) {
            const queryResult = await getOrganizationAuditorsByFilters(organizationAuditors, formattedRoleOptions, formattedFilters);
            if (queryResult.isSuccess) {
                setSelectedOrganizationAuditors(queryResult.result);
                calculateValues(undefined, queryResult.result);
            }
        }
        else {
            setError("Could not find any subscribers to filter. Try reloading the page.");
        }
        setIsDisabled(false);
        setIsCountingCredits(false);
    }

    const handleSelectCustomOrganizationAuditors = (selected: OrganizationAuditor[]) => {
        setSelectedOrganizationAuditors(selected);
        calculateValues(undefined, selected);
    }

    const handleMessageBodyChange = (value: string) => {
        setMessageBody(value);
        calculateValues(value);
    }

    const handleImageChange = (file: File | null | undefined) => {
        if (file) {
            const fileSizeInBytes = file.size;
            if (fileSizeInBytes >= 5000000) {
                setError("Sorry, this file is too large to send. Please use a file smaller than 5MB.");
            } else {
                setImage(file);
                calculateValues(messageBody, organizationAuditors, file);
            }
        } else {
            setImage(undefined);
            calculateValues(messageBody, organizationAuditors, file);
        }
    }

    const sendAlertToAuditors = async () => {
        // setCurrentSentCount(0);
        
        // Confirm there are enough credits to send the alert
        if (!isUnlimitedCredits && totalCreditCount < creditsNeeded) {
            setError("You do not have enough credits to send this message.");
            return false;
        }

        // Track the credit blocks remaining credits
        let blockArray = formattedBlocks;
        let blockIdArray: string[] = [];
        blockArray?.forEach((block: FormattedBlock) => {
            blockIdArray.push(block.id);
        });

        // Handle image if it is included
        let imageURL = "";
        let imageKey = "";
        if (image) {
            const imageTitle = createMMSPictureFileName(organization, image);
            const imageUploadResult = await handleUploadS3Image(imageTitle, image);
            if (imageUploadResult.isSuccess) {
                const key = imageUploadResult.result;
                if (key) {
                    imageKey = key;
                    imageURL = "https://" + awsmobile.aws_user_files_s3_bucket + ".s3.amazonaws.com/public/" + key;
                }
            }
        }

        // Create the alert
        const alertInput: CreateAlertInput = {
            body: messageBody,
            imageKey: imageKey,
            organizationId: organization.id || "",
            messageSegments: messageSegmentCount,
            subscriberCount: selectedOrganizationAuditors?.length || 0,
            createdBy: user.id
        };
        const createAlertResult = await createAlert(alertInput);
        if (createAlertResult.isSuccess) {
            const alert: Alert = createAlertResult.result;

            // Remove the assumed number of credits. If any texts fail to send - will be handled within the Lambda Function
            setTotalCreditCount(totalCreditCount - creditsNeeded);

            if (selectedOrganizationAuditors) {
                let phoneNumberList: string[] = [];
                selectedOrganizationAuditors.forEach(organizationAuditor => {
                    if (organizationAuditor.phoneNumber) phoneNumberList.push(organizationAuditor.phoneNumber);
                });

                // Mass data to Lambda Function
                const sendMassSMSResult = handleSendMassOrgSMS(alert.id, phoneNumberList, blockIdArray, isUnlimitedCredits, messageBody, imageURL);
            }    

            setSuccess("Successfully created the text alert!");  
            setMessageBody("");
            setImage(undefined);
            calculateValues("");     
        } else {
            const errorMessage = "Sorry, we could not create this message. " + createAlertResult.message;
            setError(errorMessage);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        await sendAlertToAuditors();
        setIsLoading(false);
        setShowModal(false);
    }

    return (
        <form>
            {error && <ErrorAlert width="12" error={error} />}
            {success && <SuccessBanner width="12" success={success} />}
            <IonRow>
                <IonCol className="ion-text-center">
                    <p className="description">Remember to refresh the page throughout the day to see new subscribers.</p>
                </IonCol>
            </IonRow>
            <IonRow className="pt-4 ion-justify-content-center">
                <IonCol sizeXs="12" sizeMd="4" className="ion-text-center">
                    <IonButton color={isGeneral ? "primary" : "light"} onClick={() => handleOptionChange("general")}>All</IonButton>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="4" className="ion-text-center">
                    <IonButton color={isFiltered ? "primary" : "light"} onClick={() => handleOptionChange("filtered")}>Filter</IonButton>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="4" className="ion-text-center">
                    <IonButton color={isCustom ? "primary" : "light"} onClick={() => handleOptionChange("custom")}>Custom</IonButton>
                </IonCol>
            </IonRow>
            <hr/>
            {isCustom && (
                <>
                    {organizationAuditors && (
                        <>
                            <SelectEventAuditors organizationAuditors={organizationAuditors} onSelect={handleSelectCustomOrganizationAuditors} />
                            <hr/>
                        </>
                    )}
                </>
            )}
            {isFiltered && (
                <>
                    {organizationAuditors && (
                        <>
                            <InformationBanner info={"If a subscriber has selected at least 1 filter that matches your selections, they will get the alert."} />
                            <SelectEventFilterRolesOptionForm startWithoutSelections={false} isCollapsable={true} isExpandedOnStart={false} onSelect={handleRoleFilterSelect} />
                            <SelectEventAlertFiltersForm organization={organization} onSelect={handleFilterSelect} />
                            <hr/>
                        </>
                    )}
                </>
            )}
            {isCountingCreditsNeeded ?
                <Spinner />
                :
                <IonRow className="ion-justify-content-center">
                    <IonCol size="10" className="ion-text-center">
                        <p className="description text-primary">Credits you will use: {creditsNeeded.toLocaleString()}</p>
                    </IonCol>
                </IonRow>
            }
            {isFiltered && (
                <IonRow className="ion-justify-content-center">
                    <IonCol size="10" className="ion-text-center">
                        <p className="description link" onClick={() => setShowModal(true)}>View who will receive the alert.</p>
                    </IonCol>
                </IonRow>
            )}
            {isUnlimitedCredits ?
                <IonRow className="ion-justify-content-center">
                    <IonCol size="10" className="ion-text-center">
                        <p className="description text-primary">Event has unlimited credits</p>
                    </IonCol>
                </IonRow>
                :
                <>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="10" className="ion-text-center">
                            <p className="description text-primary">Credits you have left: {totalCreditCount.toLocaleString()}</p>
                        </IonCol>
                    </IonRow>
                    {((organizationAuditors?.length ? organizationAuditors?.length > 0 : "") && (totalCreditCount < (organizationAuditors?.length ? (organizationAuditors?.length + organizationAuditors?.length*.5) : 0))) && (
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="10" className="ion-text-center">
                                <p className="description text-danger">Running low on credits: <span className="link" onClick={() => setShowCreditModal(!showCreditsModal)}>Add Credits</span></p>
                            </IonCol>
                        </IonRow>
                    )}
                </>
            }
            <hr/>
            <IonRow>
                <IonCol>
                    <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="body"
                        >
                            Message <Badge color="info" data-placement="top" id="charCountExplanation">160 characters</Badge>
                        </label>
                        <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target="charCountExplanation"
                        >
                            An alert has 160 characters or less. If you use more than 160 characters, the system will count it as multiple alerts. This will increase the number of credits you are using.
                        </UncontrolledTooltip>
                        <Input
                            id="body"
                            placeholder="..."
                            rows="4"
                            type="textarea"
                            name="body"
                            className={characterCount > 160 ? "is-invalid " : ""}
                            value={messageBody}
                            onChange={(event) => handleMessageBodyChange(event.target.value)}
                        />
                        <div>
                            <p className="text-right">{characterCount}/160</p>
                        </div>
                        { characterCount > 160 ?
                            <>
                                {((organizationAuditors?.length ? organizationAuditors?.length > 0 : "") && (totalCreditCount < (organizationAuditors?.length ? (organizationAuditors?.length + organizationAuditors?.length*.5) : 0))) ?
                                    <p className="description text-center text-danger">You do not have enough credits to send this alert. You can either delete some of the characters or purchase more credits.</p>
                                    :
                                    <p className="description text-center">You can send this alert and users will see it as one text message, but it will count as {Math.ceil(characterCount/160)} alerts in our system.</p>
                                }
                            </>
                            :
                            <></>
                        }
                    </FormGroup>
                </IonCol>
            </IonRow>
            <AlertImageForm selectedImage={image} onSelect={handleImageChange}/>
            <IonRow className="ion-justify-content-center">
                <IonCol sizeMd="12">
                    {isLoading ?
                        <IonRow className="ion-justify-content-center">
                            <IonCol className="ion-text-center">
                                <p>Sending alerts, do not refresh page</p>
                                {/* <p>{currentSentCount}/{selectedOrganizationAuditors?.length}</p> */}
                                <Spinner />
                            </IonCol>
                        </IonRow>
                        :
                        <IonButton
                            disabled={creditsNeeded === 0}
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={handleSubmit}
                        >
                            {creditsNeeded === 0 ? "Type Message" : "Send"}
                        </IonButton>
                    }
                </IonCol>
            </IonRow>
            <IonModal backdropDismiss={false} isOpen={showModal} id="viewSubscribersModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Subscribers to Receive Alert
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeSubscriberModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading ?
                        <Spinner />
                        :
                        <>
                            <InformationBanner info="Based on the current filters you've selected, these are the subscribers that will get the alert" />
                            {selectedOrganizationAuditors ?
                                <IonList className="bg-white">
                                    {selectedOrganizationAuditors.map((organizationAuditor: OrganizationAuditor, index: number) => (
                                        <IonItem key={index}>
                                            <IonLabel>
                                                <IonRow>
                                                    <IonCol size="1">
                                                        <p>{index+1}</p>
                                                    </IonCol>
                                                    <IonCol size="6" className="ion-text-wrap">
                                                        <p>{organizationAuditor.name}</p>
                                                    </IonCol>
                                                    <IonCol size="5" className="ion-text-wrap">
                                                        <p>{organizationAuditor.phoneNumber}</p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonLabel>
                                        </IonItem>
                                    ))}
                                </IonList>
                                :
                                <p>There are no selected subscribers.</p>
                            }
                        </>
                    }
                </IonContent>
            </IonModal>
            <IonModal backdropDismiss={false} isOpen={showCreditsModal} id="addCreditsModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Add Credits
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowCreditModal(false)}
                        >
                            <p id="closeCreditsModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading ?
                        <Spinner />
                        :
                        <>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size="8">
                                   {(organization) && 
                                        <BuyCreditsForm organizationId={organization.id} />
                                   }
                                </IonCol>
                            </IonRow>
                        </>
                    }
                </IonContent>
            </IonModal>
        </form>
    );
};

export default SendOrgAlertForm;