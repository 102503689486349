import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCheckbox,
    IonCol,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRouterLink,
    IonRow,
    IonText,
} from "@ionic/react";
import React, {useRef, useState} from "react";

import AuthNavbar from "../../components/Navbars/AuthNavbar";
import ErrorAlert from "../../components/Errors/ErrorAlert";
import Footer from "../../components/Footers/Footer";
import {Person} from "../../models";
import Spinner from "../../components/Spinners/Spinner";
import TermsAndConditionsModal from "../../components/Modals/TermsAndConditionsModal";
import {getUser, updateUserPersonId} from "../../utilities/user/User";
import {logIn} from "../../utilities/auth/LoginUtil";
import {signUp} from "../../utilities/auth/RegisterUtil";
import {useHistory} from "react-router";
import { createStripeCustomer } from "../../utilities/stripe/CreateCustomer";
import { createPerson, updatePerson } from "../../utilities/person/Person";
import { CreatePersonInput, UpdatePersonInput } from "../../API";
import RequiredInputIndicator from "../../components/Forms/RequiredInputIndicator";

const RegisterPage: React.FC = () => {
    const history = useHistory();

    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] = useState(false);

    const emailInputRef = useRef<HTMLIonInputElement>(null);
    const passwordInputRef = useRef<HTMLIonInputElement>(null);

    const validateRegisterForm = () => {
    // Using a type assertion to ensure that the inputs contain strings
        const email = (emailInputRef.current!.value as string);
        const password = (passwordInputRef.current!.value as string);

        if (!email) {
            setError("Please enter your email address.");
            return false;
        }
        if (!password) {
            setError("Please enter your password.");
            return false;
        }
        if (password && password.length < 8) {
            setError("Password must have 8 characters or more.");
            return false;
        }
        if (!isTermsAndConditionsChecked) {
            setError("Please agree to the Terms and Conditions.");
            return false;
        }
        setError("");
        return true;
    };

    const handleCreateAccount = async () => {
        try {
            setIsLoading(true);
            // Validate the form fields
            const isValid = validateRegisterForm();
            if (isValid) {
                // Using a type assertion to ensure that the inputs contain strings
                const email = (emailInputRef.current!.value as string);
                const password = (passwordInputRef.current!.value as string);

                // Sign up the user with AWS Cognito
                const signUpResult = await signUp(email, password);
                if (signUpResult.isSuccess) {
                    await handleLogin(email, password);
                } else {
                    setError(signUpResult.message);
                }
            }
            setIsLoading(false);
        } catch (error: any) {
            setError(error);
            setIsLoading(false);
        }
    };

    const handleLogin = async (email: string, password: string) => {
        try {
            const logInResult = await logIn(email, password);
            if (logInResult.isSuccess) {
                const user = await getUser();
                if (user && user.username) {
                    // If successfully created a user, create a person in the DB
                    const personId = await handleCreatePerson(email);
                    if (personId) {
                        // If successfully added the person to the DB, add the unique id to the cognito user
                        const updateResult = await updateUserPersonId(user, personId);
                        if (updateResult) {
                            navigateToDashboard();
                            // setIsLoading(false);
                        } else {
                            setError("Sorry, a problem occurred. Please contact hello@ringsidepro.com.");
                            // setIsLoading(false);
                        }
                    } else {
                        setError("Sorry, a problem occurred. Please try again or contact hello@ringsidepro.com.");
                        // setIsLoading(false);
                    }
                } else {
                    setError("Sorry, a problem occurred. Please try again or contact hello@ringsidepro.com.");
                    // setIsLoading(false);
                }
            } else {
                setError(logInResult.message);
                // setIsLoading(false);
            }
        } catch (error: any) {
            setError(error);
            // setIsLoading(false);
        }
    };

    const handleCreatePerson = async (email: string) => {
        try {
            const input: CreatePersonInput = {
                email: email,
                isVerified: false
            };
            const createResult = await createPerson(input);
            if (createResult.isSuccess) {
                const person = createResult.result;
                // After creating the person, add the person to Stripe
                await handleCreateStripeAccount(email, person); 
                return person.id;
            }
        } catch (error: any) {
            console.error("Could not create a person.", error);
            return "";
        }
    };

    const handleCreateStripeAccount = async (email: string, person: Person) => {
        try {
            const customerResult = await createStripeCustomer("", email, person.id);
            if (customerResult.isSuccess) {
                const stripeCustomer = customerResult.result;
                if (stripeCustomer.id) {
                    const personInput: UpdatePersonInput = {
                        id: person.id,
                        stripeId: stripeCustomer.id
                    }
                    await updatePerson(personInput);
                }
            }
            return person.id;
        } catch (error: any) {
            console.error("Could not create a person.", error);
            return "";
        }
    };

    const navigateToDashboard = () => {
        // Check if the user is trying to get to a specific dashboard page
        const previousPath = history.location.pathname;
        const previousState = history.location?.state as any; //State is used from Login Page to Register Page
        const previousPathFromState = previousState?.prevPath || "";
        if (previousPath && previousPath.includes("index")) {
            // Send the user to the specific Dashboard page the user is trying to get to
            history.push(previousPath);
        } else if (previousPathFromState && previousPathFromState.includes("index")) {
            // Send the user to the specific Dashboard page the user was trying to get to when routed to Login and user clicked Register 
            history.push(previousPathFromState);
        } else {
            // Otw, send the user to the general dashboard page
            history.push("/index/home");
        }
        window.location.reload();
    };

    return (
        <IonPage id="registerPage">
            <IonContent color="primary">
                <AuthNavbar />
                <section className="section bg-primary">
                    <div>
                        <IonRow class="ion-justify-content-center ion-padding-bottom">
                            <IonCol sizeSm="8" sizeMd="6">
                                <IonCard color="light">
                                    <IonCardHeader className="ion-margin-top">
                                        <IonCardTitle color="primary" className="ion-text-center h3">
                                            Create An Account
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    {isLoading ?
                                        <IonRow>
                                            <IonCol className="ion-text-center">
                                                <Spinner/>
                                            </IonCol>
                                        </IonRow>
                                        :
                                        <IonCardContent>
                                            {error && (
                                                <ErrorAlert width="12" error={error} />
                                            )}
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol sizeSm="12" sizeMd="8">
                                                    <form>
                                                        <IonItem color="light">
                                                            <IonLabel position="stacked">Email Address <RequiredInputIndicator/></IonLabel>
                                                            <IonInput 
                                                                id="register-email"
                                                                name="register-email"
                                                                type="email"
                                                                required={true}
                                                                aria-required={true}
                                                                ref={emailInputRef} 
                                                            />
                                                        </IonItem>
                                                        <IonItem color="light">
                                                            <IonLabel position="stacked">Password <RequiredInputIndicator/></IonLabel>
                                                            <IonInput 
                                                                id="register-password"
                                                                type="password"
                                                                minlength={8}
                                                                required={true}
                                                                aria-required={true}
                                                                ref={passwordInputRef} 
                                                            />
                                                        </IonItem>
                                                        <p className="description-smaller text-medium">Password must be 8 characters or longer.</p>
                                                        <IonItem
                                                            button={false}
                                                            lines="none"
                                                            color="light"
                                                            className="ion-no-padding"
                                                        >
                                                            <IonCheckbox
                                                                id="termsAndConditionsCheckbox"
                                                                checked={isTermsAndConditionsChecked}
                                                                onIonChange={e => setIsTermsAndConditionsChecked(e.detail.checked)}
                                                                slot="start"
                                                                color="tertiary"
                                                                className="ion-margin-end"
                                                                aria-required={true}
                                                            />
                                                            <TermsAndConditionsModal handleAgreement={setIsTermsAndConditionsChecked}/>
                                                        </IonItem>
                                                        <IonButton
                                                            id="registerBtn"
                                                            className="ion-margin-top"
                                                            color="tertiary"
                                                            expand="block"
                                                            onClick={handleCreateAccount}
                                                        >
                                                            Create Account
                                                        </IonButton>
                                                    </form>
                                                </IonCol>
                                            </IonRow>
                                            <hr />
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol sizeSm="12" sizeMd="8" className="ion-text-center">
                                                    <IonText>Already have an account? <IonRouterLink id="loginLink" routerLink="/login"><span className="link">Log In</span></IonRouterLink></IonText>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    }
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </div>
                </section>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default RegisterPage;
