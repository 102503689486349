import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import Header from "../../../../../components/Headers/Header";
import { Event, Organization, Membership, EventEntry, EventResult, BacklogEventResult } from "../../../../../models";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import Spinner from "../../../../../components/Spinners/Spinner";
import { PersonContext } from "../../../../../context/PersonContext";
import { getOrganizationById } from "../../../../../utilities/organization/Organization";
import SelectEvent from "../../../../../components/Event/SelectEvent";
import { Table } from "reactstrap";
import SelectYear from "../../../../../components/DatePicker/SelectYear";
import { getEventsByOrganizationIdByYear } from "../../../../../utilities/events/Event";
import { getEventEntryByEventIdByBackNumber } from "../../../../../utilities/eventEntry/EventEntry";
import { getEventResultsByEntryId } from "../../../../../utilities/eventResult/EventResult";
import { getMembershipsByOrganizationId } from "../../../../../utilities/membership/Membership";
import { getPointsFromBacklogEventResult, getPointsFromEventResult } from "../../../../../utilities/eventResult/OrganizationPointData";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import { getBacklogEventResultsByEventId } from "../../../../../utilities/backlogEventResult/BacklogEventResult";
import { calculateRHSCPlaceFromPoints } from "../../../../../utilities/pointTable/CaculatePointsFromTable";
import moment from "moment";

interface OrganizationPageProps extends RouteComponentProps<{
    id: string;
    memberId: string; //using back number currently
}> {}

interface formattedMemberResultData {
    eventResultId: string
    eventId: string
    eventName: string
    eventStartDate: string
    isDivisionResult: boolean
    eventClassId?: string
    eventClassName?: string
    eventClassNumber?: string
    eventDivisionId?: string
    eventDivisionName?: string
    orgClassId?: string
    orgClassName?: string
    orgDivisionId?: string
    orgDivisionName?: string
    place: string
    points: string
}

const yearChoices = [
    moment(new Date()).subtract(2, "year").format("YYYY"),
    moment(new Date()).subtract(1, "year").format("YYYY"),
    moment(new Date()).format("YYYY"),
    moment(new Date()).add(1, "year").format("YYYY"),
];

const EventOrganizationViewMemberPointsPage: React.FC<OrganizationPageProps> = ({match}) => {
    const user = useContext(PersonContext);

    const [organization, setOrganization] = useState<Organization>();
    const [membership, setMembership] = useState<Membership | null | undefined>();
    const [selectedYear, setSelectedYear] = useState(moment(new Date()).format("YYYY")); 
    const [selectedEvent, setSelectedEvent] = useState<Event | null | undefined>(); 
    const [organizationEvents, setOrganizationEvents] = useState<Event[] | null | undefined>(); 
    const [filteredFormattedClassEventResults, setFilteredFormattedClassEventResults] = useState<formattedMemberResultData[] | null | undefined>(); 
    const [filteredFormattedDivisionEventResults, setFilteredFormattedDivisionEventResults] = useState<formattedMemberResultData[] | null | undefined>(); 
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    
    const handleSelectedYear = (year: string) => {
        setSelectedYear(year);
        getOrganizationEvents(year);
    }

    const handleSelectedEvent = (event: Event) => {
        setSelectedEvent(event);
        getMemberPointsByEvent(event);
    }

    async function formatResults(resultsArray: EventResult[], event: Event) {
        let formattedArray: formattedMemberResultData[] = [];
        for (let i = 0; i < resultsArray.length; i++) {
            const currentResult = resultsArray[i];

            const eventClass = currentResult.eventClass;
            const orgClass = eventClass?.organizationClass;
            const eventDivision = currentResult.eventDivision;
            const orgDivision = eventDivision?.organizationDivision;

            const isDivisionResult = ((currentResult.eventDivision !== null) && (currentResult.eventDivision !== undefined));

            const pointsEarned = await getPointsFromEventResult(currentResult);

            const formattedResult: formattedMemberResultData = {
                eventResultId: currentResult.id,
                isDivisionResult: isDivisionResult,
                eventId: event.id,
                eventName: event.name,
                eventStartDate: event.startDate || "",
                eventClassId: currentResult.eventClass?.id || undefined,
                eventClassName: currentResult.eventClassName || currentResult.eventClass?.name,
                eventClassNumber: currentResult.eventClass?.number ? currentResult.eventClass?.number.toString() : "",
                eventDivisionId: currentResult.eventDivision?.id || undefined,
                eventDivisionName: currentResult.eventDivisionName || currentResult.eventDivision?.name,
                orgClassId: orgClass?.id || undefined,
                orgClassName: orgClass?.name || "",
                orgDivisionId: orgDivision?.id || undefined,
                orgDivisionName: orgDivision?.name || "",
                place: currentResult.place ? currentResult.place.toString() : "",
                points: pointsEarned ? pointsEarned.toString() : ""
            }

            formattedArray.push(formattedResult);
        }
        return formattedArray;
    }

    async function formatBacklogResults(resultsArray: BacklogEventResult[], event: Event) {
        let formattedArray: formattedMemberResultData[] = [];
        for (let i = 0; i < resultsArray.length; i++) {
            const currentResult = resultsArray[i];

            const isDivisionResult = false;

            const pointsEarned = await getPointsFromBacklogEventResult(currentResult);
            const placeValue = await calculateRHSCPlaceFromPoints(pointsEarned);
            const placeString = placeValue?.toString();

            const formattedResult: formattedMemberResultData = {
                eventResultId: currentResult.id,
                isDivisionResult: isDivisionResult,
                eventId: event.id,
                eventName: event.name,
                eventStartDate: event.startDate || "",
                eventClassId: currentResult.eventClassId || undefined,
                eventClassName: currentResult.eventClassName || "",
                eventDivisionId: currentResult.eventDivisionId || undefined,
                eventDivisionName: currentResult.eventDivisionName || "",
                orgClassId: currentResult.organizationClassId || undefined,
                orgClassName: currentResult.organizationClass?.name || "",
                orgDivisionId: currentResult.organizationDivisionId || undefined,
                orgDivisionName: currentResult.organizationDivision?.name || "",
                place: placeString || "",
                points: pointsEarned ? pointsEarned.toString() : ""
            }

            formattedArray.push(formattedResult);
        }
        return formattedArray;
    }

    async function getMemberPointsByEvent(currentEvent: Event, orgEvents?: Event[]) {
        // Get event entry by back number
        setIsLoading(true);
        const backNumber = match.params.memberId;
        const number = parseInt(backNumber);
        if (number) {
            let eventArray: Event[] = [];

            if (currentEvent.name === "All Events") {
                if (orgEvents) eventArray = orgEvents;
                else if (organizationEvents && organizationEvents.length) eventArray = organizationEvents;
            } else {
                eventArray = [currentEvent];
            }

            let classFormattedResults: formattedMemberResultData[] = [];
            let divisionFormattedResults: formattedMemberResultData[] = [];

            for (let i = 0; i < eventArray.length; i++) {
                const event = eventArray[i];
                const entryQuery = await getEventEntryByEventIdByBackNumber(event.id, number);
                if (entryQuery.isSuccess) {
                    const entry: EventEntry = entryQuery.result;
        
                    if (entry) {
                        // get event results by eventEntryId
                        const resultsQuery = await getEventResultsByEntryId(entry.id);
                        if (resultsQuery.isSuccess) {
                            const resultsList: EventResult[] = resultsQuery.result;
                            const formattedArray: formattedMemberResultData[] = await formatResults(resultsList, event);
                            const newClassFormattedResults: formattedMemberResultData[] = formattedArray.filter(data => data.isDivisionResult === false);
                            const newDivisionFormattedResults: formattedMemberResultData[] = formattedArray.filter(data => data.isDivisionResult === true);
                            classFormattedResults = classFormattedResults.concat(newClassFormattedResults);
                            divisionFormattedResults = divisionFormattedResults.concat(newDivisionFormattedResults);
                        }
                    }
                }

                const backlogEventResults = await getBacklogEventResultsByEventId(event.id);
                if (backlogEventResults.isSuccess) {
                    const backlogResultArray: BacklogEventResult[] = backlogEventResults.result;
                    const currentBackNumber = match.params.memberId;
                    const filteredBacklogEventResults = backlogResultArray.filter(ber => ber.organizationMemberId === currentBackNumber);   
                    const formattedArray: formattedMemberResultData[] = await formatBacklogResults(filteredBacklogEventResults, event);
                    const newClassFormattedResults: formattedMemberResultData[] = formattedArray.filter(data => data.isDivisionResult === false);
                    const newDivisionFormattedResults: formattedMemberResultData[] = formattedArray.filter(data => data.isDivisionResult === true);
                    classFormattedResults = classFormattedResults.concat(newClassFormattedResults);
                    divisionFormattedResults = divisionFormattedResults.concat(newDivisionFormattedResults);
                }
            }

            const sortedClassResults = classFormattedResults.sort((a, b) => (a.eventClassName || "")?.localeCompare((b.eventClassName || "")));
            const sortedDivisionResults = divisionFormattedResults.sort((a, b) => (a.eventDivisionName || "")?.localeCompare((b.eventDivisionName || "")));

            setFilteredFormattedClassEventResults(sortedClassResults);
            setFilteredFormattedDivisionEventResults(sortedDivisionResults);

            // const totalClassPointValue = calculateTotalPointValue(classFormattedResults);
            // setTotalClassPointValue(totalClassPointValue);

            // const totalDivisionPointValue = calculateTotalPointValue(divisionFormattedResults);
            // setTotalDivisionPointValue(totalDivisionPointValue);
        } else {
            setError("No member number found.")
        }
        setIsLoading(false);
    }

    async function getOrganizationEvents(organizationId: string, year?: string) {
        let events: Event[] = [];
        const queryResult = await getEventsByOrganizationIdByYear(organizationId, year || selectedYear);
        if (queryResult.isSuccess) {
            events = queryResult.result;
 
            const newEvent: Event = {
                id: "1",
                name: "All Events",
                createdBy: "",
                createdOn: "",
                updatedOn: ""
            };

            await getMemberPointsByEvent(newEvent, events);

            events = [newEvent].concat(events);

            setOrganizationEvents(events);
            setSelectedEvent(newEvent);
        }
    }

    async function getMemberById() {
        setError("");
        if (match.params.memberId) {
            const queryResult = await getMembershipsByOrganizationId(match.params.id);
            if (queryResult.isSuccess) {
                const allOrgMemberships: Membership[] = queryResult.result;
                if (allOrgMemberships) {
                    const memberNumber = parseInt(match.params.memberId);
                    const currentMembership = allOrgMemberships.find((m: Membership) => m.backNumber === memberNumber);
                    setMembership(currentMembership);
                }
            }
        } else {
            setError("No member id found.");
        }
    }

    async function getOrganization() {
        try {
            setIsLoading(true);
            const queryResult = await getOrganizationById(match.params.id);
            if (queryResult.isSuccess) {
                const organization: Organization = queryResult.result;
                setOrganization(organization);
                await getOrganizationEvents(organization.id);
                await getMemberById();
                setIsLoading(false);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
                setIsLoading(false);
            }
        } catch (error: any) {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    useEffect(() => {
        getOrganization();
    }, [user, match.params.id]);

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Organization Points" />
                {isLoading ?
                    <Spinner />
                    :   
                    <>
                        {error && <ErrorAlert error={error} width="12" />}
                        {organization ?
                            <IonRow>
                                <IonCol>
                                    <IonCard mode="md" className="ion-padding bg-white">
                                        <IonCardTitle>Member Event Points</IonCardTitle>
                                        {/* <IonCardSubtitle>
                                            <p className="link" onClick={() => handlePrintYearlyPoints()}>
                                                Print Yearly Points
                                            </p>
                                        </IonCardSubtitle> */}
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol sizeXs="12" sizeMd="4">
                                                    <p className="ion-text-wrap font-weight-bold">Back Number: <span className="font-weight-normal">{membership?.backNumber}</span></p>
                                                </IonCol>
                                                <IonCol sizeXs="12" sizeMd="4">
                                                    <p className="ion-text-wrap font-weight-bold">Horse Name: <span className="font-weight-normal">{membership?.horse?.name}</span></p>
                                                </IonCol>
                                                <IonCol sizeXs="12" sizeMd="4">
                                                    <p className="ion-text-wrap font-weight-bold">Rider Name: <span className="font-weight-normal">{membership?.personName}</span></p>
                                                </IonCol>
                                            </IonRow>

                                            {organizationEvents && (
                                                <IonRow className="ion-justify-content-center">
                                                    <IonCol sizeXs="12" sizeMd="6" className="text-center">
                                                        <SelectYear selectedValue={selectedYear} years={yearChoices} onSelect={(year: string) => handleSelectedYear(year)} />
                                                    </IonCol>
                                                    <IonCol sizeXs="12" sizeMd="6" className="text-center">
                                                        <SelectEvent selectedValue={selectedEvent?.id} events={organizationEvents} onSelect={(event: Event) => handleSelectedEvent(event)} />
                                                    </IonCol>
                                                </IonRow>
                                            )}
                                            {(filteredFormattedClassEventResults && filteredFormattedClassEventResults.length > 0) ?
                                                <>
                                                    <h1 className="ion-text-wrap">Class Results</h1>
                                                    <Table responsive hover>
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <p className="description ion-text-wrap font-weight-bold">Event Name</p>
                                                                </th>
                                                                <th>
                                                                    <p className="description ion-text-wrap font-weight-bold">Class Number</p>
                                                                </th>
                                                                <th>
                                                                    <p className="description ion-text-wrap font-weight-bold">Class Name</p>
                                                                </th>
                                                                <th>
                                                                    <p className="description ion-text-wrap font-weight-bold">Place</p>
                                                                </th>
                                                                <th>
                                                                    <p className="description ion-text-wrap font-weight-bold">Points Earned</p>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {filteredFormattedClassEventResults && filteredFormattedClassEventResults.map((result, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <p className="description ion-text-wrap">{result.eventName}</p>
                                                                </td>
                                                                <td>
                                                                    <p className="description ion-text-wrap">{result.eventClassNumber ? result.eventClassNumber : ""}</p>
                                                                </td>
                                                                <td>
                                                                    <p className="description ion-text-wrap">{result.eventClassName}</p>
                                                                </td>
                                                                <td>
                                                                    <p className="description ion-text-wrap">{result.place || ""}</p>
                                                                </td>
                                                                <td>
                                                                    <p className="description ion-text-wrap">{result.points}</p>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                            {/* <tr>
                                                                <td>
                                                                    <p className="description ion-text-wrap"></p>
                                                                </td>
                                                                <td>
                                                                    <p className="description ion-text-wrap"></p>
                                                                </td>
                                                                <td>
                                                                    <p className="description ion-text-wrap font-weight-bold">Total Points</p>
                                                                </td>
                                                                <td>
                                                                    <p className="description ion-text-wrap font-weight-bold">{totalClassPointValue}</p>
                                                                </td>
                                                            </tr> */}
                                                        </tbody>
                                                    </Table>
                                                </>
                                                :
                                                <>
                                                    <p>No class results found.</p>
                                                </>
                                            }
                                            {(filteredFormattedDivisionEventResults && filteredFormattedDivisionEventResults.length > 0) ?
                                                <>
                                                    <h1 className="ion-text-wrap">Division Championships</h1>
                                                    <Table responsive hover>
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <p className="description ion-text-wrap font-weight-bold">Event Name</p>
                                                                </th>
                                                                <th>
                                                                    <p className="description ion-text-wrap font-weight-bold">Division Name</p>
                                                                </th>
                                                                <th>
                                                                    <p className="description ion-text-wrap font-weight-bold">Place</p>
                                                                </th>
                                                                <th>
                                                                    <p className="description ion-text-wrap font-weight-bold">Points Earned</p>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {filteredFormattedDivisionEventResults && filteredFormattedDivisionEventResults.map((result, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <p className="description ion-text-wrap">{result.eventName}</p>
                                                                </td>
                                                                <td>
                                                                    <p className="description ion-text-wrap">{result.eventDivisionName}</p>
                                                                </td>
                                                                <td>
                                                                    <p className="description ion-text-wrap">{result.place === "1" ? "Champion" : result.place === "2" ? "Reserve" : ""}</p>
                                                                </td>
                                                                <td>
                                                                    <p className="description ion-text-wrap">{result.points}</p>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                            {/* <tr>
                                                                <td>
                                                                    <p className="description ion-text-wrap"></p>
                                                                </td>
                                                                <td>
                                                                    <p className="description ion-text-wrap"></p>
                                                                </td>
                                                                <td>
                                                                    <p className="description ion-text-wrap font-weight-bold">Total Points</p>
                                                                </td>
                                                                <td>
                                                                    <p className="description ion-text-wrap font-weight-bold">{totalDivisionPointValue}</p>
                                                                </td>
                                                            </tr> */}
                                                        </tbody>
                                                    </Table>
                                                </>
                                                :
                                                <>
                                                    <p>No division results found.</p>
                                                </>
                                            }
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            :
                            <p>No organization found</p>
                        }
                    </>
                }

            </IonContent>
        </IonPage>
    );
};

export default EventOrganizationViewMemberPointsPage;