import { Event, EventEntry } from "../../models";
import {
    IonButton,
    IonCol,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import EntryFormHeader from "./EntryFormHeader";
import EntryFormNavbar from "../Navbars/EntryFormNavbar";
import EntryCard from "./EntryCard";
import { updateEventEntry } from "../../utilities/eventEntry/EventEntry";
import EntryClassForm from "./EntryClassForm";
import EntryFeeForm from "./EventFeeForm";
import EntryRuleForm from "./EntryRulesForm";
import EntrySummaryCard from "./EntrySummaryCard";
import Spinner from "../Spinners/Spinner";
import EntryStablingForm from "./NewEventStablingForm";
import DisplayStablingInfo from "./EntrySummary/DisplayStablingInfo";
import { updateBeddingRequestStatusByEntryId, updateStablingRequestStatusByEntryId, updateStablingRequestStatusByPersonByEvent } from "../../utilities/stablingRequest/StablingRequestStatus";
import { updateEventEntryFeeStatusByEventByEntry } from "../../utilities/eventEntryFee/UpdateEventEntryFeeStatus";
import { UpdateEventEntryInput } from "../../API";
import { useHistory } from "react-router";

interface _Props {
    event?: Event
    entry?: EventEntry
    onSubmit?: Function
}

const QuickAddEntry: React.FC<_Props> = ({event, entry, onSubmit}) => {
    const user = useContext(PersonContext);
    const history = useHistory();

    const [currentEntries, setCurrentEntries] = useState<EventEntry[] | undefined>();
    const [currentEntry, setCurrentEntry] = useState<EventEntry | undefined>();
    const [activeTab, setActiveTab] = useState<string>("info");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        setCurrentEntry(entry);
    }, []);

    const handleNavbarSelect = (selection: string) => {
        setActiveTab(selection);
    }

    const handleSubmitEntry = async (entry: EventEntry) => {
        setCurrentEntry(entry);
        setActiveTab("classes");
    }

    const handleNextPage = () => {
        if (activeTab === "info") setActiveTab("classes");
        if (activeTab === "classes") setActiveTab("stalls");
        if (activeTab === "stalls") setActiveTab("fees");
        if (activeTab === "fees") setActiveTab("rules");
        if (activeTab === "rules") setActiveTab("submit");
    }

    const handleUpdateStabling = async () => {
        if (entry) {
            await updateBeddingRequestStatusByEntryId(entry.id, event?.eventOptions?.autoAcceptNewEntries ? "accepted" : "submitted");
            return await updateStablingRequestStatusByEntryId(entry.eventId, entry.id, event?.eventOptions?.autoAcceptNewEntries ? "accepted" : "submitted");
        } else {
            setError("Stabling Error");
        }
    }

    const handleUpdateFees = async (entry: EventEntry) => {
        // const createResult = await addRequiredEventEntryFeesByEventByEntry(user.id, entry.eventId, entry);
        // if (!createResult.isSuccess) {
        //     return createResult;
        // }
        const updateResult = await updateEventEntryFeeStatusByEventByEntry(entry.eventId, entry.id, "accepted");
        return updateResult;
    } 

    const handleUpdateEntryStatus = async (entry: EventEntry): Promise<EventEntry | undefined> => {
        const eventEntryInput: UpdateEventEntryInput = {
            id: entry.id,
            status: "complete"
        };
        const updateResult = await updateEventEntry(eventEntryInput);
        if (updateResult.isSuccess) {
            const updatedEntry = updateResult.result;
            return updatedEntry;
        }
    }

    const handleUpdateEntries = async () => {
        let isSuccess = true;
        if (currentEntry) {
            setIsLoading(true);
            // First update any stabling requests
            const updateStablingResult = await handleUpdateStabling();
            if (updateStablingResult && !updateStablingResult.isSuccess) {
                isSuccess = false;
                setError(updateStablingResult.message);
                setIsLoading(false);
            }

            // Next, update entry info
            if (currentEntry.status !== "complete") {
                const updateFeesResult = await handleUpdateFees(currentEntry);
                if (!updateFeesResult.isSuccess) {
                    isSuccess = false;
                    setError(updateFeesResult.message);
                    setIsLoading(false);
                }
                const updateEntryStatusResult = await handleUpdateEntryStatus(currentEntry);
                if (updateEntryStatusResult) {
                    // await sendEntryStatuscompleteEmailWithInvoice(currentEntry, user.email, user.firstName || "");
                }
            }  else {
                setIsLoading(false);
            }
            if (isSuccess) {
                setIsLoading(false);
                if (onSubmit) onSubmit();
                else await navigateToEntriesPage();
            }  else {
                setIsLoading(false);
            }
        } else {
            setError("Could not find any entries to submit.");
            setIsLoading(false);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        await handleUpdateEntries();
    }

    const navigateToEntriesPage = async () => {
        if (event) {
            const path = "/index/event/entries/" + event.id;
            history.push(path);
        } else {
            setError("Sorry, we could not load the new page.")
        }
    }

    return (
        <>
            {isLoading ?
                <Spinner />
                :
                <>
                    <EntryFormNavbar active={activeTab} event={event} onSubmit={handleNavbarSelect}/>
                    {event && (
                        <div className="ion-padding-top">
                            {activeTab === "info" && (
                                <>
                                    <EntryCard isQuickAdd={true} event={event} entry={currentEntry} onSubmit={handleSubmitEntry}/>
                                </>
                            )}
                            {activeTab === "classes" && (
                                <>
                                    <EntryClassForm isQuickAdd={true} event={event} entry={currentEntry} />
                                </>
                            )}
                            {activeTab === "stalls" && (
                                <>
                                    <EntryStablingForm event={event} entry={currentEntry}/>
                                </>
                            )}
                            {activeTab === "fees" && (
                                <>
                                    <EntryFeeForm event={event} entry={currentEntry} isAdminView={true} />
                                </>
                            )}
                            {activeTab === "rules" && (
                                <>
                                    <EntryRuleForm event={event} entry={currentEntry} />
                                </>
                            )}
                            {activeTab === "submit" && (
                                <>
                                    <IonText className="ion-text-center">Entry Summary</IonText>
                                    {currentEntry ?
                                        <EntrySummaryCard event={event} entry={currentEntry} />
                                        :
                                        <p>Could not find entry information.</p>
                                    }
                                    <hr/>
                                    <IonText className="ion-text-center">Stabling Summary</IonText>
                                    <DisplayStablingInfo event={event} />
                                </>
                            )}
                        </div>
                    )}
                    <hr/>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="text-center" sizeMd="4">
                            {activeTab === "submit" ?
                                <IonButton
                                    className="ion-margin-top"
                                    color="success"
                                    expand="block"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </IonButton>
                                :
                                <IonButton
                                    className="ion-margin-top"
                                    color="tertiary"
                                    expand="block"
                                    onClick={handleNextPage}
                                >
                                    Next
                                </IonButton>
                            }
                            
                        </IonCol>
                    </IonRow>
                </>
            }
        </>
    );
};

export default QuickAddEntry;