import {
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRadio,
    IonReorder,
    IonRow,
    isPlatform,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Membership, PersonalInformation, Team, TeamMember } from "../../models";
import { Table } from "reactstrap";
import { getTeamMembersByTeamId } from "../../utilities/teamMember/TeamMember";
import { sortTeamMembersByPersonName } from "../../utilities/teamMember/SortTeamMembers";
import { getMembershipsByPersonIdByOrganizationId } from "../../utilities/membership/Membership";
import { formatDisplayName } from "../../utilities/person/PersonNameFormat";
import moment from "moment";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import { isWindows } from "../../utilities/platform/Platform";
import { getPersonalInformationByPersonId } from "../../utilities/personalInformation/PersonalInformation";

interface _Props {
    team: Team
    onSelect: Function
}

interface FormattedCoachRow {
    teamMemberId: string
    teamMemberName: string
    role: string
    emailAddress: string
    phoneNumber: string
    status: string
    dateCreated: string
}

const ViewTeamMembersCoachesTable: React.FC<_Props> = ({team, onSelect}) => {

    const [formattedRows, setFormattedRows] = useState<FormattedCoachRow[] | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const getTeamMemberRole = (role: string) => {
        if (role === "headCoach") return "Head Coach";
        if (role === "assistantCoach") return "Assistant Coach";
        if (role === "parentVolunteer") return "Parent Volunteer";
        return "";
    }

    const getTeamMembersOnRoster = async (teamMemberList: TeamMember[]) => {
        let teamMembersOnRoster: FormattedCoachRow[] = [];
        for (let i = 0; i < teamMemberList.length; i++) {
            const currentTeamMember = teamMemberList[i];
            // Get the membership for this team member (team functionality is currently just for AEL organization)
            const membershipResult = await getMembershipsByPersonIdByOrganizationId(currentTeamMember.personId, "ddc2fd3c-0bde-4b39-bbbb-5e00d2a35ba0");
            if (membershipResult.isSuccess) {
                const activeMembershipsForPersonForTeam: Membership[] = membershipResult.result;
                if (activeMembershipsForPersonForTeam && activeMembershipsForPersonForTeam.length > 0) {
                    for (let j = 0; j < activeMembershipsForPersonForTeam.length; j++) {
                        const currentMembership = activeMembershipsForPersonForTeam[j];
                        
                        // On the Team Coaches Page, only include coach
                        if (currentMembership.organizationMembershipType?.name.toLowerCase().includes("coach")) {
                            let personalInfo: PersonalInformation | null = null;
                            if (currentMembership.contactPersonId) {
                                const contactQueryResult = await getPersonalInformationByPersonId(currentMembership.contactPersonId);
                                if (contactQueryResult.isSuccess) {
                                    personalInfo = contactQueryResult.result;
                                }
                            }
                            
                            let emailAddress = personalInfo?.contact?.personalEmail || personalInfo?.contact?.workEmail || currentMembership.personEmail;
                            let phoneNumber = personalInfo?.contact?.cell || personalInfo?.contact?.work || personalInfo?.contact?.home;

                            const formattedRow: FormattedCoachRow = {
                                teamMemberId: currentTeamMember.id,
                                teamMemberName: formatDisplayName((currentTeamMember.person?.firstName || ""), "", (currentTeamMember.person?.lastName || "")),
                                role: currentTeamMember.role ? getTeamMemberRole(currentTeamMember.role) : "",
                                emailAddress: emailAddress || "",
                                phoneNumber: phoneNumber || "",
                                status: currentTeamMember.status || "",
                                dateCreated: currentTeamMember.createdOn ? moment(currentTeamMember.createdOn).format("MM/DD/YYYY") : ""
                            };
    
                            teamMembersOnRoster.push(formattedRow);
                        }
                    }
                }
            }
        }
        return teamMembersOnRoster;
    }

    async function getTeamMembers() {
        if (team) {
            setIsLoading(true);
            const queryResult = await getTeamMembersByTeamId(team.id);
            if (queryResult.isSuccess) {
                const teamMembers: TeamMember[] = queryResult.result;
                const sorted = sortTeamMembersByPersonName(teamMembers);
                const formattedRows: FormattedCoachRow[] = await getTeamMembersOnRoster(sorted || teamMembers);
                setFormattedRows(formattedRows);
            } else {
                const message = "An error occurred. " + queryResult.message;
                setError(message);
            }
            setIsLoading(false);
        } else {
            setError("No team was found.");
        }
    }

    useEffect(() => {
        getTeamMembers();
    }, [team]);

    const onSelectTeamMember = (row: FormattedCoachRow) => {
        onSelect(row.teamMemberId);
    }

    return (
        <IonCard mode="md" className="ion-padding bg-white">
            <IonCardTitle>
                <IonRow>
                    <IonCol sizeXs="12" sizeMd="4">
                        Coaches
                    </IonCol>
                </IonRow>
            </IonCardTitle>
            {error && <ErrorAlert error={error} />}
            <IonCardSubtitle>
                <IonRow>
                    <IonCol>
                        Click on a row to view more about the coach.
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        Count: {formattedRows ? formattedRows.length : 0}
                    </IonCol>
                </IonRow>
            </IonCardSubtitle>
            {isLoading ?
                <Spinner />
                :
                <IonCardContent>
                    {((!isWindows()) && isPlatform("mobile")) ?
                        <IonList className="bg-white">
                            {(formattedRows && formattedRows.length > 0) && (
                                <>
                                    {formattedRows.map((formattedRow, index) => (
                                        <IonItem key={index} onClick={() => onSelectTeamMember(formattedRow)}>
                                            <IonLabel>
                                                <p className="ion-text-wrap">{formattedRow.teamMemberName}</p>
                                            </IonLabel>
                                            <IonRadio slot="start" color="tertiary" value={formattedRow.teamMemberId} />
                                            <IonReorder slot="start" />
                                        </IonItem>
                                    ))}
                                </>
                            )}
                        </IonList>
                        :
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Position</th>
                                    <th>Email Address</th>
                                    <th>Phone Number</th>
                                    <th>Status</th>
                                    <th>Date Joined</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(formattedRows && formattedRows.length > 0) && (
                                    <>
                                        {formattedRows.map((formattedRow, index) => (
                                            <tr key={index} onClick={() => onSelectTeamMember(formattedRow)}>
                                                <td className="ion-text-wrap">
                                                    {formattedRow.teamMemberName}
                                                </td>
                                                <td className="ion-text-wrap">
                                                    {formattedRow.role}
                                                </td>
                                                <td className="ion-text-wrap">
                                                    {formattedRow.emailAddress}
                                                </td>
                                                <td className="ion-text-wrap">
                                                    {formattedRow.phoneNumber}
                                                </td>
                                                <td className="ion-text-wrap">
                                                    {formattedRow.status}
                                                </td>
                                                <td className="ion-text-wrap">
                                                    {formattedRow.dateCreated}
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                            </tbody>
                        </Table>
                    }
                </IonCardContent>
            }
        </IonCard>
    );
};

export default ViewTeamMembersCoachesTable;