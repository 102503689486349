import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateInvitationInput, CreateInvitationMutation, DeleteInvitationInput, DeleteInvitationMutation, GetInvitationQuery, InvitationStatus, ListInvitationsQuery, UpdateInvitationInput, UpdateInvitationMutation } from "../../API";
import moment from "moment";
import { Invitation } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: invitation. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created invitation.
*/
export async function createInvitation(input: CreateInvitationInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Invitation", "No input", "Create Invitation received no input.");
   try {
        const fullInput: CreateInvitationInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createInvitation, { input: fullInput }))) as GraphQLResult<CreateInvitationMutation>;
       else result = (await API.graphql({
           query: mutations.createInvitation,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateInvitationMutation>;
       const invitation = result.data?.createInvitation;
       return formatResult(true, "Invitation", invitation, "Successfully created the invitation.");
   } catch (error: any) {
       return formatResult(false, "Invitation", error, "Error creating record in the database for type: invitation");
   }
}

/**
* Handle updating a new record in the database for type: invitation. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated invitation.
*/
export async function updateInvitation(input: UpdateInvitationInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Invitation", "No input", "Update Invitation received no input.");
   try {
        const fullInput: UpdateInvitationInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateInvitation, { input: fullInput }))) as GraphQLResult<UpdateInvitationMutation>;
       else result = (await API.graphql({
           query: mutations.updateInvitation,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateInvitationMutation>;
       const invitation = result.data?.updateInvitation;
       return formatResult(true, "Invitation", invitation, "Successfully updated the invitation.");
   } catch (error: any) {
       return formatResult(false, "Invitation", error, "Error updating record in the database for type: invitation");
   }
}

/**
* Handle deleting a new record in the database for type: invitation. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted invitation.
*/
export async function deleteInvitation(input: DeleteInvitationInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Invitation", "No input", "Delete Invitation received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteInvitation, { input: input }))) as GraphQLResult<DeleteInvitationMutation>;
       else result = (await API.graphql({
           query: mutations.deleteInvitation,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteInvitationMutation>;
       const invitation = result.data?.deleteInvitation;
       return formatResult(true, "Invitation", invitation, "Successfully deleted the invitation.");
   } catch (error: any) {
       return formatResult(false, "Invitation", error, "Error deleting record in the database for type: invitation");
   }
}

/**
* Get all records in the database for type: invitation. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the invitation object
*/
export async function getAllInvitations(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listInvitations,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListInvitationsQuery>;
        
        let items = result.data?.listInvitations?.items as Invitation[];
        let nextToken = result.data?.listInvitations?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listInvitations,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListInvitationsQuery>;

            const nextItems = nextResult.data?.listInvitations?.items as Invitation[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listInvitations?.nextToken;
        }

        return formatResult(true, "Invitation", items, "Successfully got the Invitations.");
    } catch (error: any) {
        return formatResult(false, "Invitation", error, "Error reading record in the database for type: invitations");
    }
}

/**
* Read a specific record in the database for type: invitation. 
* 
* @param {string}  id                  The invitation id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the invitation object
*/
export async function getInvitationById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getInvitation,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetInvitationQuery>;
       const invitation = result.data?.getInvitation;
       return formatResult(true, "Invitation", invitation, "Successfully got the invitation.");
   } catch (error: any) {
       return formatResult(false, "Invitation", error, "Error reading record in the database for type: invitation");
   }
}

/**
* Get all records in the database that match the given criteria for type: invitation. 
* 
* @param {string}  personId            The person id of the "owner" of the invitation
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the invitation object
*/
export async function getInvitationsByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listInvitations,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListInvitationsQuery>;
        
        let items = result.data?.listInvitations?.items as Invitation[];
        let nextToken = result.data?.listInvitations?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listInvitations,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListInvitationsQuery>;

            const nextItems = nextResult.data?.listInvitations?.items as Invitation[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listInvitations?.nextToken;
        }

        return formatResult(true, "Invitation", items, "Successfully got the Invitations.");
    } catch (error: any) {
        return formatResult(false, "Invitation", error, "Error reading record in the database for type: invitations");
    }
}

/**
* Get all records in the database that match the given criteria for type: invitation. 
* 
* @param {string}  personId            The person id of the "owner" of the invitation
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the invitation object
*/
export async function getPendingInvitationsByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}, status: {eq: "pending"}};
        const result = (await API.graphql({
            query: queries.listInvitations,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListInvitationsQuery>;

        let items = result.data?.listInvitations?.items as Invitation[];
        let nextToken = result.data?.listInvitations?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listInvitations,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListInvitationsQuery>;

            const nextItems = nextResult.data?.listInvitations?.items as Invitation[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listInvitations?.nextToken;
        }

        return formatResult(true, "Invitation", items, "Successfully got the Invitations.");
    } catch (error: any) {
        return formatResult(false, "Invitation", error, "Error reading record in the database for type: invitations");
    }
}

/**
* Get all records in the database that match the given criteria for type: invitation. 
* 
* @param {string}  email               The email address
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the invitation object
*/
export async function getPendingInvitationsByEmail(email: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {inviteeEmail: {eq: email}, status: {eq: "pending"}};
        const result = (await API.graphql({
            query: queries.listInvitations,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListInvitationsQuery>;

        let items = result.data?.listInvitations?.items as Invitation[];
        let nextToken = result.data?.listInvitations?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listInvitations,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListInvitationsQuery>;

            const nextItems = nextResult.data?.listInvitations?.items as Invitation[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listInvitations?.nextToken;
        }

        return formatResult(true, "Invitation", items, "Successfully got the Invitations.");
    } catch (error: any) {
        return formatResult(false, "Invitation", error, "Error reading record in the database for type: invitations");
    }
}

/**
* Get all records in the database that match the given criteria for type: invitation. 
* 
* @param {string}  organizationId      The organization id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the invitation object
*/
export async function getPendingAndDeclinedInvitationsByOrganizationId(organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {
            and: [
                { organizationId: {eq: organizationId} },
                { or: [
                    { status: {eq: "pending"} },
                    { status: {eq: "declined"} }
                ] }
            ]
        };
        const pendingResults = (await API.graphql({
            query: queries.listInvitations,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListInvitationsQuery>;

        let items = pendingResults.data?.listInvitations?.items as Invitation[];
        let nextToken = pendingResults.data?.listInvitations?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listInvitations,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListInvitationsQuery>;

            const nextItems = nextResult.data?.listInvitations?.items as Invitation[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listInvitations?.nextToken;
        }

        return formatResult(true, "Invitation", items, "Successfully got the Invitations.");
    } catch (error: any) {
        return formatResult(false, "Invitation", error, "Error reading record in the database for type: invitations");
    }
}

/**
* Get all records in the database that match the given criteria for type: invitation. 
* 
* @param {string}  barnId              The barn id
* @param {string}  personId            The person id of the "owner" of the invitation
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the invitation object
*/
export async function getInvitationsByBarnIdInvitor(barnId: string, personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.byBarnByInvitor,
            variables: {
                limit: 1000,
                barnId: barnId,
                createdBy: {eq: personId},
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;
        
        let items = result.data?.ByBarnByInvitor?.items as Invitation[];
        let nextToken = result.data?.ByBarnByInvitor?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.byBarnByInvitor,
                variables: {
                    limit: 100,
                    barnId: barnId,
                    createdBy: {eq: personId},
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.ByBarnByInvitor?.items as Invitation[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.ByBarnByInvitor?.nextToken;
        }

        return formatResult(true, "Invitation", items, "Successfully got the Invitations.");
    } catch (error: any) {
        return formatResult(false, "Invitation", error, "Error reading record in the database for type: invitations");
    }
}

/**
* Get all records in the database that match the given criteria for type: invitation. 
* 
* @param {string}  barnId              The barn id
* @param {string}  personId            The person id of the invitee
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the invitation object
*/
export async function getPendingInvitationsByBarnIdInvitee(barnId: string, personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {status: {eq: InvitationStatus.pending}};
        const result = (await API.graphql({
            query: queries.byBarnByInvitee,
            variables: {
                limit: 1000,
                barnId: barnId,
                invitee: {eq: personId},
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;
        
        let items = result.data?.ByBarnByInvitee?.items as Invitation[];
        let nextToken = result.data?.ByBarnByInvitee?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.byBarnByInvitee,
                variables: {
                    limit: 100,
                    barnId: barnId,
                    invitee: {eq: personId},
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.ByBarnByInvitee?.items as Invitation[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.ByBarnByInvitee?.nextToken;
        }

        return formatResult(true, "Invitation", items, "Successfully got the Invitations.");
    } catch (error: any) {
        return formatResult(false, "Invitation", error, "Error reading record in the database for type: invitations");
    }
}