import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";

import { createPersonName, getPersonNameByPersonId, updatePersonName } from "../../utilities/person/PersonName";
import { updateUserFamilyName, updateUserGivenName, updateUserMiddleName } from "../../utilities/user/User";

import { Auth } from "aws-amplify";
import Spinner from "../Spinners/Spinner";
import { PersonContext } from "../../context/PersonContext";
import { CreatePersonNameInput, UpdatePersonInput, UpdatePersonNameInput } from "../../API";
import { formatDisplayName } from "../../utilities/person/PersonNameFormat";
import { updatePerson } from "../../utilities/person/Person";

const PersonNameForm: React.FC = () => {
    const user = useContext(PersonContext);

    const [isMounted, setIsMounted] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [personNameId, setPersonNameId] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");

    useEffect(() => {
        // Create an scoped async function in the hook
        async function setNameInState() {
            const personNameResult = await getPersonNameByPersonId(user.id);
            if (personNameResult.isSuccess) {
                const personName = personNameResult.result;
                setPersonNameId(personName.id);
                if (personName.firstName) setFirstName(personName.firstName);
                if (personName.middleName) setMiddleName(personName.middleName);
                if (personName.lastName) setLastName(personName.lastName);
            }
        }
        // Execute the created function directly
        setNameInState();
      }, []);

    const handleSubmit = async () => {
        setIsLoading(true);
        setIsDisabled(true);
        await updateUser();
        await updateName();
        setIsLoading(false);
    }

    const updateUser = async () => {
        Auth.currentAuthenticatedUser()
            .then(async (user) => {
                if (firstName) await updateUserGivenName(user, firstName);
                if (middleName) await updateUserMiddleName(user, middleName);
                if (lastName) await updateUserFamilyName(user, lastName);
            });
    };

    const updateName = async () => {
        if (personNameId) {
            const input: UpdatePersonNameInput = {
                id: personNameId,
                firstName,
                middleName,
                lastName,
                displayName: formatDisplayName(firstName, "", lastName)
            };
            await updatePersonName(input);
        } else {
            const input: CreatePersonNameInput = {
                personId: user.id,
                firstName: firstName,
                lastName: lastName,
                displayName: formatDisplayName(firstName, "", lastName)
            };
            await createPersonName(input);
        }
        if (user) {
            const personInput: UpdatePersonInput = {
                id: user.id,
                firstName,
                lastName
            };
            await updatePerson(personInput);
        }
    }; 

    return (
        <form>
            <IonRow>
                <IonCol>
                    <IonItem color="white">
                        <IonLabel position="stacked">First Name</IonLabel>
                        <IonInput 
                            type="text"
                            value={firstName}
                            aria-required={true}
                            onIonChange={e => {
                                if(isMounted) setIsDisabled(false);
                                setFirstName(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
                <IonCol>
                    <IonItem color="white">
                        <IonLabel position="stacked">Middle Name</IonLabel>
                        <IonInput 
                            type="text"
                            value={middleName}
                            aria-required={true}
                            onIonChange={e => {
                                if(isMounted) setIsDisabled(false);
                                setMiddleName(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
                <IonCol>
                    <IonItem color="white">
                        <IonLabel position="stacked">Last Name</IonLabel>
                        <IonInput 
                            type="text"
                            value={lastName}
                            aria-required={true}
                            onIonChange={e => {
                                if(isMounted) setIsDisabled(false);
                                else setIsMounted(true);
                                setLastName(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="4">
                    {isLoading ?
                        <Spinner />
                        :
                        <IonButton
                            disabled={isDisabled}
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={handleSubmit}
                        >
                            {isDisabled ? "Saved" : "Save"}
                        </IonButton>
                    }
                </IonCol>
            </IonRow>
        </form>
    );
};

export default PersonNameForm;