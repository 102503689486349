import { IonButton, IonCol, IonLabel, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Input } from "reactstrap";
import { CreateStablingRequestInput } from "../../API";
import { PersonContext } from "../../context/PersonContext";
import { Barn, Event, EventEntry, EventStallType, StablingRequest, Trainer } from "../../models";
import { getBarnsByPersonId } from "../../utilities/barn/Barn";
import { createStablingRequest } from "../../utilities/stablingRequest/StablingRequest";
import BarnEntryForm from "../Barn/BarnEntryForm";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import TrainerEntryForm from "../Trainer/TrainerEntryForm";
import EntryStablingFormRow from "./EntryStabling/EntryStablingFormRow";
import EntryStablingTotalRow from "./EntryStabling/EntryStablingTotalRow";

interface FormRow {
    entry?: EventEntry
    options: FormattedOption[]
}

interface FormattedOption {
    eventStallType: EventStallType
    quantity: number
}

interface _Props {
    event: Event
    entry?: EventEntry
    stablingRequest?: StablingRequest
    onSubmit: Function
}

const StablingRequestInfoForm: React.FC<_Props> = ({stablingRequest, entry, event, onSubmit}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");
    const [note, setNote] = useState("");
    const [formRows, setFormRows] = useState<any>();
    const [trainer, setTrainer] = useState<Trainer | null | undefined>();
    const [barn, setBarn] = useState<Barn | null | undefined>();
    const [currentStablingRequest, setCurrentStablingRequest] = useState<StablingRequest | null | undefined>();

    useEffect(() => {
        async function getBarns() {
            const queryResult = await getBarnsByPersonId(user.id);
            if (queryResult.isSuccess) {
                setBarn(queryResult.result[0]); //To Do: make alphabetical or chronological?
            }
            setIsLoading(false);
        }

        getBarns();
    }, [user, user.id]);

    useEffect(() => {
        if (stablingRequest) {
            setCurrentStablingRequest(stablingRequest);
        }
    }, [stablingRequest]);

    useEffect(() => {
        if (entry) {
            if (entry.barn) setBarn(entry.barn);
            if (entry.trainer) setTrainer(entry.trainer);
        }
    }, [entry]);

    const verifyForm = () => {
        if (!trainer) {
            setError("Please include a name for the trainer. If you do not have a trainer, please write: self");
            return false;
        }
        return true;
    }

    const handleRowInput = (entry: EventEntry, options: any) => {
        const row: FormRow = {
            entry,
            options
        };
        if (formRows && formRows.length) {
            let found = false;
            let index = 0;
            for (var i = 0; i < formRows.length; i ++) {
                const formRow = formRows[i];
                if (formRow.entry && entry) {
                    if (formRow.entry.id === entry.id) {
                        found = true;
                        index = i;
                    }
                } else if (!formRow.entry && !entry) {
                    found = true;
                    index = i;
                }
            }
            if (found) {
                const updatedRow: FormRow = {
                    entry,
                    options
                };
                const updatedArray = [
                    ...formRows.slice(0, index),
                    updatedRow,
                    ...formRows.slice(index + 1)
                ];
                setFormRows(updatedArray);
            } else {
                const newRows = formRows.concat([row]);
                setFormRows(newRows);
            }
        } else {
            setFormRows([row]);
        }
    }

    const handleTrainerInputChange = (trainer: Trainer) => {
        setTrainer(trainer);
    }

    const handleBarnInputChange = (barn: Barn) => {
        setBarn(barn);
    }

    const calculatePrice = (pricePerStall: number, tax: number, quantity: number) => {
        const basePrice = pricePerStall * quantity;
        const taxRate = tax / 100;
        const fullPrice = basePrice * taxRate + basePrice;
        return fullPrice;
    }

    const getEventEntryIdArray = () => {
        let result: string[] = [];
        if (entry) {
            result.push(entry.id);
        }
        return result;
    }

    const saveEventStablingRequest = async () => {
        if (formRows && formRows.length) {
            for (var i = 0; i < formRows.length; i++) {
                const currentRow: FormRow = formRows[i];
                const currentEntry = currentRow.entry;
                const currentOptions: FormattedOption[] = currentRow.options;
                if (currentOptions && currentOptions.length) {
                    for (var j = 0; j < currentOptions.length; j++) {
                        const option = currentOptions[j];
                        const stallType = option.eventStallType;
                        const pricePerStall = parseFloat(stallType.pricePerStall || "0");
                        const tax = parseFloat(stallType.taxPerStall || "0");
                        const quantity = option.quantity;
                        const totalPrice = calculatePrice(pricePerStall, tax, quantity);
                        if (totalPrice > 0 && trainer) {
                            const input: CreateStablingRequestInput = {
                                eventId: event.id,
                                personId: user.id,
                                stallTypeId: stallType.id,
                                // stablingRequestStallTypeId: stallType.id,
                                quantityNeeded: quantity,
                                trainerId: trainer.id,
                                // stablingRequestTrainerId: trainer.id,
                                basePrice: pricePerStall,
                                taxA: tax,
                                totalPrice: totalPrice,
                                status: "saved",
                                requestNote: note, 
                                barnId: barn?.id || entry?.barnId,
                                // stablingRequestBarnId: barn?.id || entry?.barnId,
                                entryIds: currentEntry ? [currentEntry.id] : getEventEntryIdArray()
                            };
                            const createResult = await createStablingRequest(input);
                        }
                    }
                }
            }
            onSubmit();
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const isValid = verifyForm();
        if (isValid) await saveEventStablingRequest();
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {isLoading ?
                <Spinner/>
                :
                <>
                    <EntryStablingFormRow event={event} isHeaderRow={true} onSubmit={() => console.log("Row saw a change")}/>
                    {entry ? 
                        <div>
                            <EntryStablingFormRow event={event} entry={entry} onSubmit={handleRowInput}/>
                        </div>
                        :
                        <>
                            <EntryStablingFormRow event={event} onSubmit={handleRowInput}/>
                        </>
                    }
                    {/* <hr/> */}
                    {/* <EntryStablingTotalRow rows={formRows} /> */}
                    <hr/>
                    <IonRow>
                        <IonCol>
                            <TrainerEntryForm trainer={trainer} barn={barn} onChange={handleTrainerInputChange} />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <BarnEntryForm barn={barn} label="Stable With (barn)" onChange={handleBarnInputChange} />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonLabel>Stabling Notes</IonLabel>
                            <p>Include any arrival and departure info, mare and stallion info, etc. You may include barn or stall location preferences, but they are not guarenteed to be met.</p>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <Input
                                placeholder="Add notes for this entry."
                                rows="5"
                                type="textarea"
                                name="note"
                                value={note}
                                spellCheck="true"
                                onChange={(event) => setNote(event.target.value)}
                                data-hj-whitelist
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="text-center" sizeMd="4">
                            <IonButton
                                className="ion-margin-top"
                                color="success"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                Save Stabling Request
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
            }
        </>
    );
};

export default StablingRequestInfoForm;