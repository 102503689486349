import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import {Event, EventClass, EventClassEntry, EventDivision, EventResult, PointTable, PrizeMoneyTable } from "../../../../../models";
import Header from "../../../../../components/Headers/Header";
import { PersonContext } from "../../../../../context/PersonContext";
import { RouteComponentProps, useHistory } from "react-router";
import {getEventById} from "../../../../../utilities/events/Event";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import { getEventDivisionById } from "../../../../../utilities/eventDivision/EventDivision";
import Spinner from "../../../../../components/Spinners/Spinner";
import { CreateEventResultInput } from "../../../../../API";
import moment from "moment";
import { createEventResult, deleteEventResult, getEventResultsByEventClassIdWithPointTables, getEventResultsByEventDivisionId } from "../../../../../utilities/eventResult/EventResult";
import SelectEventClassEntry from "../../../../../components/EventClassEntry/SelectEventClassEntry";
import { getEventClassesByEventDivisionId } from "../../../../../utilities/eventClass/EventClass";
import EventResultTable from "../../../../../components/EventResult/EventResultTable";
import { isWindows } from "../../../../../utilities/platform/Platform";
import { isPlatform } from "@ionic/core";
import { Table } from "reactstrap";
import { getPointTableById } from "../../../../../utilities/pointTable/PointTable";
import { getRiderById } from "../../../../../utilities/rider/Rider";

interface totalRow {
    pointTotal: number
    entryNumber: number
    riderName: string
    horseName: string
}

interface EventPageProps extends RouteComponentProps<{
    id: string;
    divisionId: string;
}> {}

const EventAddDivisionResultsPage: React.FC<EventPageProps> = ({match}) => {
    const user = useContext(PersonContext);
    const history = useHistory();

    const [currentClasses, setCurrentClasses] = useState<EventClass[] | null | undefined>();
    const [currentClass, setCurrentClass] = useState<EventClass | null | undefined>();
    const [totalRows, setTotalRows] = useState<totalRow[] | undefined>();
    const [currentClassEntries, setCurrentClassEntries] = useState<EventClassEntry[] | null | undefined>();
    const [firstPlace, setFirstPlace] = useState<EventClassEntry | null | undefined>();
    const [secondPlace, setSecondPlace] = useState<EventClassEntry | null | undefined>();
    const [firstPlaceResult, setFirstPlaceResult] = useState<EventResult | null | undefined>();
    const [secondPlaceResult, setSecondPlaceResult] = useState<EventResult | null | undefined>();
    const [pointTable, setPointTable] = useState<PointTable | null | undefined>();
    const [prizeMoneyTable, setPrizeMoneyTable] = useState<PrizeMoneyTable | null | undefined>();
    const [prizeMoney, setPrizeMoney] = useState<number | null | undefined>();
    const [event, setEvent] = useState<Event>();
    const [eventDivision, setEventDivision] = useState<EventDivision | null | undefined>();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    async function getEvent(eventId: string) {
        const queryResult = await getEventById(eventId);
        if (queryResult.isSuccess) {
            setEvent(queryResult.result);
            return queryResult.result;
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    function setResult(currentEventResult: EventResult) {
        if (currentEventResult.place === 1) setFirstPlaceResult(currentEventResult);
        if (currentEventResult.place === 2) setSecondPlaceResult(currentEventResult);
    }

    async function getEventDivisionResults(eventDivisionId: string) {
        const queryResult = await getEventResultsByEventDivisionId(eventDivisionId);
        if (queryResult.isSuccess) {
            const resultsArray: EventResult[] = queryResult.result;
            resultsArray.forEach(currentEventResult => {
                setResult(currentEventResult);
            });
        }
    }

    async function getEventDivision(divisionId: string) {
        const queryResult = await getEventDivisionById(divisionId);
        if (queryResult.isSuccess) {
            const eventDivision: EventDivision = queryResult.result;
            setEventDivision(eventDivision);

            if (eventDivision.pointTable) setPointTable(eventDivision.pointTable);
            if (eventDivision.prizeMoneyTable) setPrizeMoneyTable(eventDivision.prizeMoneyTable);
            if (eventDivision.prizeMoney) setPrizeMoney(eventDivision.prizeMoney ? parseFloat(eventDivision.prizeMoney) : 0);

            return eventDivision;
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    async function getEventDivisionClasses(divisionId: string) {
        const queryResult = await getEventClassesByEventDivisionId(divisionId);
        if (queryResult.isSuccess) {
            const eventClasses: EventClass[] = queryResult.result;
            setCurrentClasses(eventClasses);
            if (eventClasses && eventClasses.length > 0) setCurrentClass(eventClasses[0]);
            await getTotalRows(eventClasses);
            return eventDivision;
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    const calculatePoints = (placeValue: number, currentPointTable?: PointTable) => {
        const selectedPointTable = currentPointTable || pointTable || eventDivision?.pointTable;
        if (selectedPointTable) {
            let pointValue = "0";
            if (selectedPointTable && selectedPointTable.tiers && selectedPointTable.tiers[0]) {
                const tier = selectedPointTable.tiers[0];
                if (placeValue === 1) pointValue = tier["first"] || "0";
                if (placeValue === 2) pointValue = tier["second"] || "0";
                if (placeValue === 3) pointValue = tier["third"] || "0";
                if (placeValue === 4) pointValue = tier["fourth"] || "0";
                if (placeValue === 5) pointValue = tier["fifth"] || "0";
                if (placeValue === 6) pointValue = tier["sixth"] || "0";
                if (placeValue === 7) pointValue = tier["seventh"] || "0";
                if (placeValue === 8) pointValue = tier["eighth"] || "0";
                if (placeValue === 9) pointValue = tier["ninth"] || "0";
                if (placeValue === 10) pointValue = tier["tenth"] || "0";
                if (placeValue === 11) pointValue = tier["eleventh"] || "0";
                if (placeValue === 12) pointValue = tier["twelfth"] || "0";
            }
            return parseFloat(pointValue);
        }
    }

    async function getTotalRows(eventClasses: EventClass[]) {
        let totalMap: Map<string, totalRow> = new Map();
        let totalRows: totalRow[] = [];

        for (let i = 0; i < eventClasses.length; i++) {
            const currentEventClass = eventClasses[i];
            const eventResultsResult = await getEventResultsByEventClassIdWithPointTables(currentEventClass.id);
            if (eventResultsResult.isSuccess) {
                const eventResults: EventResult[] = eventResultsResult.result;
                if (eventResults && eventResults.length > 0) {
                    for (let j = 0; j < eventResults.length; j++) {
                        const currentResult: EventResult = eventResults[j];

                        // Use the point table assigned to the specific class
                        let eventClassPointTable = currentEventClass.pointTable;
                        // Check if there are tiers on the point table from the query. If not, get the full point table with tiers.
                        if (eventClassPointTable && (!eventClassPointTable.tiers || eventClassPointTable.tiers.length < 1)) {
                            const queryPointTableResult = await getPointTableById(eventClassPointTable?.id);
                            if (queryPointTableResult.isSuccess) {
                                eventClassPointTable = queryPointTableResult.result;
                            }
                        }

                        // Get any points already calculated for this entry
                        const currentMapItem = totalMap.get(currentResult.entryId);
                        let startingValue = 0;
                        if (currentMapItem) startingValue = currentMapItem.pointTotal;
                        
                        // Get the point total from this result and add it to the entry's total points
                        let currentPointValue = 0;
                        if (currentResult.place) {
                            currentPointValue = calculatePoints(currentResult.place, (eventClassPointTable || undefined)) || 0;
                        }

                        // Get the total info from the map
                        const totalResult = totalMap.get(currentResult.entryId);
                        if (totalResult) {
                            let newTotalRow: totalRow = {
                                ...totalResult,
                                pointTotal: startingValue + currentPointValue,
                            };
                            totalMap.set(currentResult.entryId, newTotalRow);
                        } else {
                            let riderName = currentResult.eventClassEntry?.rider?.name || "";
                            if (!riderName && currentResult.eventClassEntry?.riderId) {
                                const quertRiderResult = await getRiderById(currentResult.eventClassEntry?.riderId);
                                if (quertRiderResult.isSuccess) {
                                    riderName = quertRiderResult.result?.name || "";
                                }
                            }
                            // Format the total row's data
                            let newTotalRow: totalRow = {
                                riderName: riderName,
                                horseName: currentResult.entry.horseName || "",
                                pointTotal: startingValue + currentPointValue,
                                entryNumber: currentResult.entry.number || 0
                            };
                            totalMap.set(currentResult.entryId, newTotalRow);
                        }
                    }
                }
            }
        }
        totalMap.forEach((key) => {
            totalRows.push(key);
        });
        const sorted = totalRows.sort((a, b) => b.pointTotal - a.pointTotal);
        setTotalRows(sorted || totalRows);
    }

    const getData = async (eventId: string, eventDivisionId: string) => {
        setIsLoading(true);
        await getEventDivision(eventDivisionId);
        await getEventDivisionResults(eventDivisionId);
        await getEvent(eventId);
        await getEventDivisionClasses(eventDivisionId);
        setIsLoading(false);
    }

    useEffect(() => {
        getData(match.params.id, match.params.divisionId);
    }, [match, match.params.id, match.params.divisionId]);

    const verifyForm = () => {
        try {
            return true;
        } catch (error: any) {
            return false;
        }
    }

    const handleSelectedEventClassEntry = (place: string, eventClassEntry: EventClassEntry) => {
        if (place && eventClassEntry) {
            if (place === "first") setFirstPlace(eventClassEntry);
            if (place === "second") setSecondPlace(eventClassEntry);
        }
    }

    const createResult = async (place: number, eventClassEntry: EventClassEntry) => {
        if (event && eventDivision) {
            const input: CreateEventResultInput = {
                eventId: event.id,
                entryId: eventClassEntry.eventEntry?.id || "",
                eventResultEntryId: eventClassEntry.eventEntry?.id || "",
                eventClassEntryId: eventClassEntry.id,
                eventResultEventClassEntryId: eventClassEntry.id,
                eventDivisionId: eventDivision.id,
                // eventResultEventDivisionId: eventDivision.id,
                eventDivisionName: eventDivision.name,
                place: place,
                prizeMoney: 0,
                createdBy: user.id,
                createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
            };
            const createResult = await createEventResult(input);
            if (createResult.isSuccess) setResult(createResult.result);
            
        } else {
            setError("Could not find the division.");
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);

        const isValid = verifyForm();
        if (!isValid) {
            setIsLoading(false);
            return;
        }

        if (firstPlaceResult) await deleteEventResult({id: firstPlaceResult.id});
        if (secondPlaceResult) await deleteEventResult({id: secondPlaceResult.id});

        if (firstPlace) await createResult(1, firstPlace);
        if (secondPlace) await createResult(2, secondPlace);

        setIsLoading(false);
    }

    const navigateToClassResultsPage = (currentClass: EventClass) => {
        const path = "/index/staff/event/" + currentClass?.eventId + "/results/class/" + currentClass.id;
        history.push(path);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={event ? event.name : "results"} />
                {isLoading ?
                    <Spinner />
                    :
                    <>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white">
                                    <IonCardTitle>
                                        <IonRow>
                                            <IonCol>{eventDivision?.number ? eventDivision.number + " - ": ""} {eventDivision?.name ? eventDivision?.name : "Loading..."}</IonCol>
                                        </IonRow>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <IonRow>
                                            <IonCol>
                                                This is where you will add results to a division. The points and prize money templates will auto-calulate values as you enter your results. 
                                            </IonCol>
                                        </IonRow>                                
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            {error && <ErrorAlert width="12" error={error} />}
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white">
                                    <IonCardTitle>
                                        <IonRow>
                                            <IonCol>Results</IonCol>
                                        </IonRow>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <form>
                                            <IonRow>
                                                <IonCol>
                                                <IonLabel>Champion</IonLabel>
                                                    {currentClass ?
                                                        <SelectEventClassEntry eventClassId={currentClass.id} values={currentClassEntries} selectedValue={firstPlace?.id || firstPlaceResult?.eventClassEntryId} onSelect={(event: any) => handleSelectedEventClassEntry("first", event)} />
                                                        :
                                                        <p>Error</p>
                                                    }
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                <IonLabel>Reserve</IonLabel>
                                                    {currentClass ?
                                                        <SelectEventClassEntry eventClassId={currentClass.id} values={currentClassEntries} selectedValue={secondPlace?.id || secondPlaceResult?.eventClassEntryId} onSelect={(event: any) => handleSelectedEventClassEntry("second", event)} />
                                                        :
                                                        <p>Error</p>
                                                    }
                                                </IonCol>
                                            </IonRow>
                                            {error && <ErrorAlert width="12" error={error}/>}
                                            <IonRow>
                                                <IonCol sizeMd="12">
                                                    {isLoading ?
                                                        <Spinner />
                                                        :
                                                        <IonButton
                                                            className="ion-margin-top"
                                                            color="tertiary"
                                                            expand="block"
                                                            onClick={handleSubmit}
                                                        >
                                                            Save Results
                                                        </IonButton>
                                                    }
                                                </IonCol>
                                            </IonRow>
                                        </form>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white">
                                    <IonCardTitle>
                                        <IonRow>
                                            <IonCol>Point Totals</IonCol>
                                        </IonRow>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <IonRow>
                                            <IonCol>
                                                {(!isWindows() && isPlatform("mobile")) ?
                                                    <>
                                                        <IonList>
                                                            {totalRows && (
                                                                <>
                                                                    {totalRows.map((totalRow, index) => (
                                                                        <IonItem key={index}>
                                                                            <IonRow>
                                                                                <IonCol>
                                                                                    Total Points: {totalRow.pointTotal}
                                                                                </IonCol>
                                                                            </IonRow>
                                                                            <IonRow>
                                                                                <IonCol>
                                                                                    Entry Number: {totalRow.entryNumber}
                                                                                </IonCol>
                                                                            </IonRow>
                                                                        </IonItem>
                                                                    ))}
                                                                </>
                                                            )}
                                                        </IonList>
                                                    </>
                                                    :
                                                    <>
                                                        <Table hover responsive>
                                                            <thead>
                                                                <tr>
                                                                    <th><p>Total Points</p></th>
                                                                    <th><p>Entry Number</p></th>
                                                                    <th><p>Rider Name</p></th>
                                                                    <th><p>Horse Name</p></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {(totalRows && totalRows.length > 0) ?
                                                                    <>
                                                                        {totalRows.map((totalRow, index) => (
                                                                            <tr key={index}>
                                                                                <td>{totalRow.pointTotal}</td>
                                                                                <td>{totalRow.entryNumber}</td>
                                                                                <td>{totalRow.riderName}</td>
                                                                                <td>{totalRow.horseName}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </>
                                                                    :
                                                                    <tr>
                                                                        <td>No results found</td>
                                                                    </tr>
                                                                }
                                                            </tbody> 
                                                        </Table>
                                                    </>
                                                }
                                            </IonCol>
                                        </IonRow>
                                        {/* <hr />
                                        <h3>Division Templates:</h3>
                                        <IonRow>
                                            <IonCol size="12">
                                                {event?.organizationId && <ClassPointTable organizationId={event?.organizationId} selectedPointTable={pointTable} onSelectPointTable={(type: string, pointTable: PointTable) => handleSelectPointTable(type, pointTable)} />}
                                            </IonCol>
                                            <IonCol size="12">
                                                {event?.organizationId && <ClassPrizeMoney organizationId={event?.organizationId} selectedPrizeMoney={prizeMoney} selectedPrizeMoneyTable={prizeMoneyTable} onSelectPrizeMoney={handleSelectPrizeMoney} onSelectPrizeMoneyTable={(type: string, prizeMoneyTable: PrizeMoneyTable) => handleSelectPrizeMoneyTable(type, prizeMoneyTable)} />}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-align-items-center">
                                            <IonCol sizeXs="12" sizeMd="6" offsetMd="3" className="ion-text-center">
                                                <IonButton color="success" expand="full" onClick={handleSaveTemplates}>Save</IonButton>
                                            </IonCol>
                                        </IonRow> */}
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        {(currentClasses && currentClasses.length > 0) && (
                            <>
                                {currentClasses.map((currentClass, index) => (
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol size="12">
                                            <IonCard mode="md" className="ion-padding bg-white">
                                                <IonCardTitle>
                                                    <IonRow>
                                                        <IonCol><span className="link" onClick={() => navigateToClassResultsPage(currentClass)}>{currentClass.number} - {currentClass.name} Results</span></IonCol>
                                                    </IonRow>
                                                </IonCardTitle>
                                                <IonCardSubtitle>
                                                    <IonRow>
                                                        <IonCol>Point Table: <span className="link" onClick={() => navigateToClassResultsPage(currentClass)}>{currentClass.pointTable?.name || "none"}</span></IonCol>
                                                    </IonRow>
                                                </IonCardSubtitle>
                                                <IonCardContent>
                                                    <IonRow>
                                                        <IonCol>
                                                            {currentClass ?
                                                                <EventResultTable pointTable={currentClass.pointTable} prizeMoney={prizeMoney} prizeMoneyTable={prizeMoneyTable} eventClass={currentClass} />
                                                                :
                                                                <p>Loading ... </p>
                                                            }
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                ))}
                            </>
                        )}
                    </>
                }
            </IonContent>
        </IonPage>
    );
};

export default EventAddDivisionResultsPage;