import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useState } from "react";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import { getECHorseById } from "../../utilities/ECAPI/ECHorse";

interface _Props {
    onSubmit: Function
}

const ECHorseForm: React.FC<_Props> = ({onSubmit}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [number, setNumber] = useState<string | null | undefined>();
    const [error, setError] = useState<string>("");

    const checkECNumber = async () => {
        if (number) {
            const queryResult = await getECHorseById(number);
            if (queryResult.isSuccess) {
                onSubmit(queryResult.result);
                setNumber("");
            }
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");
        if (number) await checkECNumber();
        else onSubmit();
        setIsDisabled(true);
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <form>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Membership Number</IonLabel>
                            <IonInput 
                                type="text"
                                value={number}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setNumber(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeMd="4" className="ion-text-center">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                disabled={isDisabled}
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                {isDisabled ? "Saved" : "Save"}
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </form>
        </>
    );
};

export default ECHorseForm;