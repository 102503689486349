import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import ErrorAlert from "../Errors/ErrorAlert";
import { Event, Horse } from "../../models";
import Spinner from "../Spinners/Spinner";
import MembershipInfoForm from "./CreateMembershipForm";
import USEFHorseForm from "./USEFHorseForm";
import ECHorseForm from "./ECHorseForm";
import { USEFHorse } from "../../interfaces/USEFData";
import { CreateMembershipInput } from "../../API";
import { createMembership } from "../../utilities/membership/Membership";
import { ECHorse } from "../../interfaces/ECData";
import { capitalizeName } from "../../utilities/person/PersonNameFormat";
import SuccessBanner from "../Banners/SuccessBanner";

interface _Props {
    event?: Event
    horse: Horse
    onSubmit?: Function
}

const HorseMembershipForm: React.FC<_Props> = ({horse, event, onSubmit}) => {
    const user = useContext(PersonContext);
    
    const [isLoading, setIsLoading] = useState(false);
    const [isUSEF, setIsUSEF] = useState(false);
    const [isEC, setIsEC] = useState(false);
    const [isTIP, setIsTIP] = useState(false);
    const [isOther, setIsOther] = useState(false);
    const [success, setSuccess] = useState<string>("");
    const [error, setError] = useState<string>("");

    const handleButtonSelect = (value: string) => {
        if (value === "usef") {
            setIsUSEF(true);
            setIsEC(false);
            setIsTIP(false);
            setIsOther(false);
            setSuccess("");
            setError("");
        } else if (value === "ec") {
            setIsUSEF(false);
            setIsEC(true);
            setIsTIP(false);
            setIsOther(false);
            setSuccess("");
            setError("");
        } else if (value === "tip") {
            setIsUSEF(false);
            setIsEC(false);
            setIsTIP(true);
            setIsOther(false);
            setSuccess("");
            setError("");
        } else if (value === "other") {
            setIsUSEF(false);
            setIsEC(false);
            setIsTIP(false);
            setIsOther(true);
            setSuccess("");
            setError("");
        }
    }

    const handleUSEFMembership = async (usefNumber?: string, usefData?: USEFHorse) => {
        const input: CreateMembershipInput = {
            personId: user.id,
            name: "USEF Horse",
            membershipId: usefNumber || usefData?.Id.toString() || "",
            membershipStatus: usefData?.USEFStatus || "",
            type: usefData?.USEFStatus || "",
            dateMembershipEnds: (usefData?.USEFEndDate ? usefData?.USEFEndDate : undefined),
            horseId: horse.id
        };
        const createResult = await createMembership(input);
        if (createResult.isSuccess) {
            setSuccess("Successfully added the USEF membership.");
            setError("");
            if (onSubmit) onSubmit();
        } else {
            setError("Could not add the membership.");
            setSuccess("");
            if (onSubmit) onSubmit();
        }
    }

    const handleECMembership = async (ecData: ECHorse) => {
        const input: CreateMembershipInput = {
            personId: user.id,
            name: "EC Horse",
            membershipId: ecData.PassportNum,
            membershipStatus: capitalizeName(ecData?.Status || ""),
            horseId: horse.id
        };
        const createResult = await createMembership(input);
        if (createResult.isSuccess) {
            setSuccess("Successfully added the EC membership.");
            setError("");
        } else {
            setError("Could not add the membership.");
            setSuccess("");
        }
    }

    const handleSubmit = () => {
        setSuccess("Successfully added the membership.");
        setError("");
        if (onSubmit) onSubmit();
    }

    return (
        <>
            {isLoading ?
                <Spinner/>
                :
                <>
                    {success && <SuccessBanner width="12" success={success} />}
                    {error && <ErrorAlert width="12" error={error} />}
                    <IonRow className="ion-justify-content-center">
                        {/* TO DO - add ability to search for horse USEF membership */}
                        {/* <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                            <IonButton color={isUSEF ? "primary" : "light"} onClick={() => handleButtonSelect("usef")}>USEF</IonButton>
                        </IonCol> */}
                        <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                            <IonButton color={isEC ? "primary" : "light"} onClick={() => handleButtonSelect("ec")}>EC</IonButton>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                            <IonButton color={isTIP ? "primary" : "light"} onClick={() => handleButtonSelect("tip")}>TIP</IonButton>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                            <IonButton color={isOther ? "primary" : "light"} onClick={() => handleButtonSelect("other")}>Other</IonButton>
                        </IonCol>
                    </IonRow>
                    {isUSEF && (
                        <>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem color="white">
                                        <IonLabel position="stacked">Organization Name</IonLabel>
                                        <IonInput 
                                            type="text"
                                            value="United States Equestrian Federation"
                                            aria-required={true}
                                            disabled
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <USEFHorseForm event={event} onSubmit={handleUSEFMembership} />
                        </>
                    )}
                    {isEC && (
                        <>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem color="white">
                                        <IonLabel position="stacked">Organization Name</IonLabel>
                                        <IonInput 
                                            type="text"
                                            value="Equestrian Canada"
                                            aria-required={true}
                                            disabled
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <ECHorseForm onSubmit={handleECMembership} />
                        </>
                    )}
                    {isTIP && (
                        <>
                            <p className="description ion-text-wrap">If you need to lookup a TIP number, you can do that here: <a className="link" href="https://tjctip.com/TIPLOOKUP" target="_blank" rel="noopener noreferrer">https://tjctip.com/TIPLOOKUP</a></p>
                            <MembershipInfoForm membershipName="TIP" horseId={horse.id} onSubmit={handleSubmit} />
                        </>
                    )}
                    {isOther && (
                        <>
                            <MembershipInfoForm horseId={horse.id} onSubmit={handleSubmit} />
                        </>
                    )}
                </>
            }
        </>
    );
};

export default HorseMembershipForm;