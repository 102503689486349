import { ExcelRenderer } from "react-excel-renderer";
import { isCSV } from "../files/File";
import { Result } from "../../interfaces/Result";
import { formatTwilioNumber, stripPhoneNumberToDigits } from "../contact/FormatPhoneNumber";
import { formattedOption } from "../../pages/dashboard/roles/eventStaff/events/EventBulkAddSubscribers";
import { lookupPhoneNumber } from "./TwilioLookup";
import { Event, EventAuditor } from "../../models";
import { getEventAuditorByPhoneNumber } from "../eventAuditor/EventAuditor";

interface IndexObject {
    nameColumnIndex: number
    phoneNumberColumnIndex: number
    phoneNumberSecondColumnIndex: number 
    sectionColumnIndex: number
    daysColumnIndex: number
    rolesColumnIndex: number
}

interface FormattedFileRow {
    name: string
    phoneNumber: string
    secondPhoneNumber?: string
    section?: string
    days?: string
    roles?: string
}

export async function handleBulkUploadFromCSV(file: File, event: Event, callbackFunction: Function, currentEventSubscribers?: (EventAuditor[] | null)) {
    let result: Result = {isSuccess: true, type: "", result: null, message: ""};

    const progressMsg = "Checking file is the correct type.";
    callbackFunction(progressMsg);

    // Check the file is a .csv
    const fileIsCSV = isCSV(file);
    if (!fileIsCSV) {
        const errorMsg = "Error: the file must have a .csv, .xlsx, .xls, or .xlt file extension.";
        result = {isSuccess: false, type: "", result: null, message: errorMsg};
        return result;
    }

    // Read in the data from the file
    const readInFileResult = await readCSVFile(file, callbackFunction);
    let fileRows: FormattedFileRow[] = [];
    if (readInFileResult.isSuccess) {
        fileRows = readInFileResult.result;
    } else {
        result = {isSuccess: false, type: "", result: null, message: readInFileResult.message};
        return result;
    }

    // Validate and Format the data
    let dataResult: Result = await validateAndFormatRowData(fileRows, event, callbackFunction, currentEventSubscribers);
    let data: formattedOption[] = [];
    if (dataResult.isSuccess) {
        data = dataResult.result;
    } else {
        result = {isSuccess: false, type: "", result: null, message: dataResult.message};
        return result;
    }

    // Sort the Data
    let sortedData = data.sort((a, b) => b.phoneNumber.localeCompare(a.phoneNumber));

    result = {isSuccess: true, type: "", result: sortedData, message: "Successfully uploaded in the file."};
    return result;
}

/**
 * Function to check the given file names against valid column names 
 * @param columnNameArray file column names
 * @returns Result object where the result field contains the indices as an object
 */
function getFileColumns(columnNameArray: string[]): Result {
    // Set up a result
    let result: Result = {isSuccess: true, type: "", result: null, message: ""};

    // Set up the ColumnOptions
    const nameColumnOptions = ["name", "names", "first name", "first", "firstnames", "first names", "trainer name", "coach name", "trainer", "coach", "last name", "last names", "lastname", "lastnames", "last", "full names", "full name"];
    const phoneNumberColumnOptions = ["phone number", "phone numbers", "phonenumber", "phonenumbers", "phone", "number", "numbers", "cell phone number", "cell phone numbers", "cell phone", "cell number", "cell numbers", "cell", "mobile phone number", "mobile phone", "mobile number", "mobile numbers", "mobile", "trainer number", "trainer numbers", "trainer phone number", "trainer phone numbers", "trainer cell", "workphone", "work phone", "work phone number", "work phone numbers", "homephone", "home phone", "home phone number", "home phone numbers"];
    const sectionColumnOptions = ["section"];
    const rolesColumnOptions = ["roles"];
    const daysColumnOptions = ["days"];

    // Set up the index variables to track any found columns
    let nameColumnIndex = -1;
    let phoneNumberColumnIndex = -1;
    let phoneNumberSecondColumnIndex = -1; //Some files will have two columns with phone numbers (example: one for work phone and one for home phone)
    let sectionColumnIndex = -1;
    let daysColumnIndex = -1;
    let rolesColumnIndex = -1;

    try {
        // Loop through the given file's column name array to try to match each column to a known column
        for (var index = 0; index < columnNameArray.length; index++) {
            let currentColumnName = columnNameArray[index];

            // Check for an empty column name
            if (!currentColumnName || currentColumnName === "" ) continue;

            // Check the current column name is a string
            if (typeof(currentColumnName) !== "string") {
                const errorMsg = "Please double check that the column titles are text (not numbers)";
                return {isSuccess: false, type: "", result: null, message: errorMsg};
            }

            // Clean the current column name
            currentColumnName = currentColumnName.trim();
            currentColumnName = currentColumnName.toLowerCase();

            // Start checking this current column name against the ColumnOptions
            if (nameColumnOptions.includes(currentColumnName)) nameColumnIndex = index;
            if (phoneNumberColumnOptions.includes(currentColumnName)) {
                // If already found a column for phone numbers, try finding a second column
                if (phoneNumberColumnIndex > -1) phoneNumberSecondColumnIndex = index;
                else phoneNumberColumnIndex = index;
            }
            if (sectionColumnOptions.includes(currentColumnName)) sectionColumnIndex = index;
            if (daysColumnOptions.includes(currentColumnName)) daysColumnIndex = index;
            if (rolesColumnOptions.includes(currentColumnName)) rolesColumnIndex = index;
        }

        // Check that all required columns have been found.
        if (nameColumnIndex === -1) {
            const errorMsg = "Please use a file with a column titled: Name.";
            return {isSuccess: false, type: "", result: null, message: errorMsg};
        }
        if (phoneNumberColumnIndex === -1) {
            const errorMsg = "Please use a file with a column titled: Phone Number.";
            return {isSuccess: false, type: "", result: null, message: errorMsg};
        } 

        // Return the formatted data
        const indices: IndexObject = {
            "phoneNumberColumnIndex": phoneNumberColumnIndex,
            "phoneNumberSecondColumnIndex": phoneNumberSecondColumnIndex,
            "nameColumnIndex": nameColumnIndex,
            "sectionColumnIndex": sectionColumnIndex,
            "daysColumnIndex": daysColumnIndex,
            "rolesColumnIndex": rolesColumnIndex
        };
        result = {isSuccess: true, type: "", result: indices, message: "Found the column indices."};
        return result;
    } catch (error: any) {
        const errorMsg = "Sorry, we could not read the file's column names.";
        return {isSuccess: false, type: "", result: null, message: errorMsg};
    }
}

/**
 * Function to read in the file and format the data into a usable structure.
 * @param file 
 * @param callbackFunction takes in a string to display the progress message
 * @returns Result object where the result is an array of type FormattedFileRow
 */
async function readCSVFile(file: File, callbackFunction: Function): Promise<Result> {
    const progressMsg = "Reading in the file...";
    callbackFunction(progressMsg);

    // Return a result object
    let result: Result = {isSuccess: true, type: "", result: null, message: ""};

    try {
        await ExcelRenderer(file, async (err: any, resp: any) => {
            if(err){
                const errorMsg = "Sorry, we could not upload the file. If the issue persists, contact customer service.";
                result = {isSuccess: false, type: "", result: null, message: errorMsg};
            }
            else {
                // Get the rows and columns from the file.
                let rows = resp.rows;
                let columns = rows.shift();

                // Get the indices of the file columns
                const indexCheckResult: Result = getFileColumns(columns);
                let indexObject: IndexObject | null = null;
                if (indexCheckResult.isSuccess) {
                    indexObject = indexCheckResult.result; 
                } else {
                    const errorMsg = indexCheckResult.message;
                    throw new Error(errorMsg); 
                }

                // Format each row of data based on the column (Name, Phone Number, etc.)
                if (indexObject) {
                    const phoneNumberColumnIndex = indexObject.phoneNumberColumnIndex;
                    const phoneNumberSecondColumnIndex = indexObject.phoneNumberSecondColumnIndex;
                    const nameColumnIndex = indexObject.nameColumnIndex;
                    const sectionColumnIndex = indexObject.sectionColumnIndex;
                    const rolesColumnIndex = indexObject.rolesColumnIndex;
                    const daysColumnIndex = indexObject.daysColumnIndex;

                    const formattedFileRows: FormattedFileRow[] = [];

                    for (let i = 0; i < rows.length; i++) {
                        const currentRow = rows[i];

                        let unformattedPhoneNumber: string = currentRow[phoneNumberColumnIndex] ? (currentRow[phoneNumberColumnIndex]).toString() : "";
                        let unformattedSecondPhoneNumber: string = currentRow[phoneNumberSecondColumnIndex] ? (currentRow[phoneNumberSecondColumnIndex]).toString() : undefined;
                        const name = (currentRow[nameColumnIndex] ? currentRow[nameColumnIndex] : "");
                        const section = ((sectionColumnIndex && currentRow[sectionColumnIndex]) ? currentRow[sectionColumnIndex] : undefined);
                        const roles = ((rolesColumnIndex && currentRow[rolesColumnIndex]) ? currentRow[rolesColumnIndex] : undefined);
                        const days = ((daysColumnIndex && currentRow[daysColumnIndex]) ? currentRow[daysColumnIndex] : undefined);

                        const formattedRow: FormattedFileRow = {
                            name: name,
                            phoneNumber: unformattedPhoneNumber,
                            secondPhoneNumber: unformattedSecondPhoneNumber,
                            section: section,
                            roles: roles,
                            days: days
                        };
                        formattedFileRows.push(formattedRow);
                    } 

                    result = {isSuccess: true, type: "", result: formattedFileRows, message: "Successfully read in the file rows."};
                    return formattedFileRows;
                } else {
                    const errorMsg = "No file columns matched the required column names.";
                    result = {isSuccess: false, type: "", result: null, message: errorMsg};
                }
            }
        });
    } catch (error) {
        const errorMsg = "An error was caught: " + error;
        result = {isSuccess: false, type: "", result: null, message: errorMsg};
    }

    return result;
}

/**
 * Test a formatted twilio phone number to determine if:
 * - the event auditor was already added to the show
 * - the phone number is a valid cell phone number
 * @param phoneNumber 
 * @param event 
 * @param currentEventSubscribers 
 * @returns boolean or throws an error
 */
async function testPhoneNumberShouldBeAdded(phoneNumber: string, event: Event, currentEventSubscribers?: (EventAuditor[] | null)) {
    
    // Check if this phone number has already been subscribed to this event
    if (event) {
        if (currentEventSubscribers && currentEventSubscribers.length > 0) {
            const foundCurrentEventSubscriber = currentEventSubscribers.find(currentSubscriber => currentSubscriber.phoneNumber?.includes(phoneNumber));
            if (!!(foundCurrentEventSubscriber)) {
                throw new Error("Phone number is already subscribed to this event.");
            }
        } else {
            const eventAuditorQueryResult = await getEventAuditorByPhoneNumber(phoneNumber, event?.id);
            if (eventAuditorQueryResult.isSuccess) {
                throw new Error("Phone number is already subscribed to this event.");
            }
        }
    }

    // Check that the phone number is a cell phone number
    // Uses Twilio LookUp tool - costs $0.005 per lookup
    // Update in prod
    const twilioCheck = await lookupPhoneNumber(phoneNumber, true);
    if (twilioCheck.isSuccess) {
        const carrier = twilioCheck.result;
        if (carrier && carrier.type) {
            const type = carrier?.type;
            if (type !== "mobile") {
                const error = "Could not confirm this phone number is a cell phone number. It cannot be added.";
                throw new Error(error);
            }
        } else {
            const error = "Could not confirm this phone number is a cell phone number. It cannot be added.";
            throw new Error(error);
        }
    } else {
        const error = "The lookup failed. Please check that this is a valid phone number.";
        throw new Error(error);
    } 

    return true;
}

/**
 * Format each row and check that the phone number is valid
 * @param rows 
 * @param callbackFunction takes in a string to display the progress message
 * @returns 
 */
async function validateAndFormatRowData(rows: FormattedFileRow[], event: Event, callbackFunction: Function, currentEventSubscribers?: (EventAuditor[] | null)) {
    // Return a result object
    let result: Result = {isSuccess: true, type: "", result: null, message: ""};

    // Track the seen phone numbers to avoid duplicates
    const seenPhoneNumbers: string[] = [];

    // Track the formatted data
    let options: formattedOption[] = [];

    if (rows && rows.length > 0) {
        for (let i = 0; i < rows.length; i++) {
            const currentRow = rows[i];
            
            // Get the basic info for this row
            const sectionLabel = currentRow.section || "";
            const rolesLabel = currentRow.roles || "";
            const daysLabel = currentRow.days || "";
            const filterLabel = (rolesLabel ? (" - Roles: " + rolesLabel) : "") + (daysLabel ? (" - Days: " + daysLabel) : "") + (sectionLabel ? (" - Sections: " + sectionLabel) : "");
            let unformattedPhoneNumber: string = currentRow.phoneNumber;
            let unformattedSecondPhoneNumber: string = currentRow.secondPhoneNumber || "";
    
            // If there is at least one phone number is in this row, process the row
            if (unformattedPhoneNumber || unformattedSecondPhoneNumber) {

                // Track all phone numbers found in this row
                let phoneNumbers: string[] = [];
    
                // Check if first column with a phone number has multiple pieces of data separated by a comma
                if (unformattedPhoneNumber) {
                    const initialPhoneNumbers = unformattedPhoneNumber.split(",");
                    if (initialPhoneNumbers && initialPhoneNumbers.length > 0) {
                        phoneNumbers = phoneNumbers.concat(initialPhoneNumbers);
                    }
                }
                
                // Also check if there is a second column that contains numbers
                if (unformattedSecondPhoneNumber) {
                    const additionalPhoneNumbers = unformattedSecondPhoneNumber.split(",");
                    if (additionalPhoneNumbers && additionalPhoneNumbers.length > 0) {
                        phoneNumbers = phoneNumbers.concat(additionalPhoneNumbers);
                    }
                }

                // Update the progress message and display each phone number that is being validated
                const message = "Validating #" + (i+1) + " of " + rows.length + " - Phone Number: " + unformattedPhoneNumber || unformattedSecondPhoneNumber;
                callbackFunction(message);
    
                for (let j = 0; j < phoneNumbers.length; j++) {
                    let phoneNumber = phoneNumbers[j];

                    // Start by assuming this is a valid phone number and then test to see if it is invalid
                    let isDisabled = false;
                    let invalidReason = "";

                    // Format the phone number and test that it is valid
                    try {
                        phoneNumber = stripPhoneNumberToDigits(phoneNumber);

                        phoneNumber = formatTwilioNumber(phoneNumber);
                            
                        // Check if we have already seen this phone number in the file
                        const found = seenPhoneNumbers.findIndex(ph => ph === phoneNumber);
                        if (found > -1) {
                            isDisabled = true;
                            invalidReason = "This phone number is a duplicate of another number in the file.";
                        } else {
                            seenPhoneNumbers.push(phoneNumber);
                        }

                        if (!isDisabled) await testPhoneNumberShouldBeAdded(phoneNumber, event, currentEventSubscribers);
                    } catch (error: any) {
                        isDisabled = true;
                        invalidReason = error.toString() || "The phone number given is not formatted correctly - Example: +1(123)456-7890";
                    }
                    
                    const option: formattedOption = {
                        isChecked: false,
                        isDisabled: isDisabled,
                        invalidReason: invalidReason,
                        label: "",
                        phoneNumber: phoneNumber,
                        name: currentRow.name || "",
                        rolesOptionString: rolesLabel,
                        daysOptionString: daysLabel,
                        sectionOptionString: sectionLabel,
                        filters: filterLabel
                    };
    
                    options.push(option);
                }
            } else {
                // No phone number was found in this row.
                const option: formattedOption = {
                    isChecked: false,
                    isDisabled: true,
                    invalidReason: "No phone number found.",
                    label: currentRow.name + " - no phone number found.",
                    phoneNumber: "",
                    name: currentRow.name || ""
                };
                options.push(option);
            }
        }
    } else {
        const errorMsg = "No file rows were found.";
        result = {isSuccess: false, type: "", result: null, message: errorMsg};
    }

    result = {isSuccess: true, type: "", result: options, message: "Successfully validated and formatted the data."};
    return result;
}