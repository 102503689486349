import {
    IonHeader,
    IonLabel,
    IonSegment,
    IonSegmentButton,
} from "@ionic/react";
import React, { useEffect, useState } from "react";

interface _Props {
    active: string
    onSelect: Function
}

const DivisionClassNavbar: React.FC<_Props> = ({active, onSelect}) => {
    const [currentActiveBtn, setCurrentActiveBtn] = useState(active || "classes");

    useEffect(() => {
        setCurrentActiveBtn(active);
    }, [active]);

    const handleSelection = async (selection: string) => {
        await setCurrentActiveBtn(selection);
        onSelect(selection);
    }

    return (
        <IonHeader id="viewEventMenu">
            <IonSegment color="primary" mode="md" value={currentActiveBtn || active} scrollable={true} onIonChange={e => {handleSelection(e.detail.value || "classes");}}>
                <IonSegmentButton value="classes">
                    <IonLabel>Classes</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="divisions">
                    <IonLabel>Divisions</IonLabel>
                </IonSegmentButton>
            </IonSegment>
        </IonHeader>
    );
};

export default DivisionClassNavbar;
