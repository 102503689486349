import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { Event } from "../../../../../models";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import Spinner from "../../../../../components/Spinners/Spinner";
import { RouteComponentProps, useHistory } from "react-router";
import CreateEventPage from "./CreateEventPage";
import { getEventById } from "../../../../../utilities/events/Event";
import { formatStartEndDatesStrings } from "../../../../../utilities/dates/FormatDates";
import { bookmarksOutline, calendarOutline, close, cogOutline, documentOutline, informationCircleOutline, peopleOutline, phonePortraitOutline, pricetagsOutline, reorderThreeOutline, ribbonOutline, trashOutline } from "ionicons/icons";
import EventStatusForm from "../../../../../components/Event/About/EventStatusForm";
import { Organization } from "../../../../../API";
import { getOrganizationById } from "../../../../../utilities/organization/Organization";
import { deleteEntireEvent } from "../../../../../utilities/events/DeleteEvent";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventMenuPage: React.FC<EventPageProps> = ({match}) => {
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [currentOrganization, setCurrentOrganization] = useState<Organization>();
    const [isStatusCollapsed, setIsStatusCollapsed] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEvent() {
            setIsLoading(true);
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                const event: Event = queryResult.result;
                setCurrentEvent(event);

                if (event.status === "draft") setIsStatusCollapsed(false);

                if (event && event.organizationId) {
                    const organizationQueryResult = await getOrganizationById(event.organizationId);
                    if (organizationQueryResult.isSuccess) {
                        setCurrentOrganization(organizationQueryResult.result);
                    }
                }
                
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
            setIsLoading(false);
        }
        getEvent();
    }, [match, match.params.id]);

    const navigateToCopySettingsPage = () => {
        if (currentEvent) {
            const path = "/index/staff/event/" + currentEvent?.id + "/settings/duplicate";
            history.push(path);
        }
    }

    const deleteEvent = async () => {
        setIsLoading(true);
        setError("");
        if (currentEvent) {
            const deleteEventResult = await deleteEntireEvent(currentEvent);
            setShowModal(false);
            if (deleteEventResult.isSuccess) {
                const path = "/index/staff/events/";
                history.replace(path);
            } else {
                setIsLoading(false);
                setError("Could not delete the event.");
            }
        } else {
            setIsLoading(false);
            setShowModal(false);
            setError("No event found.")
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={currentEvent ? (currentEvent.nickname ? currentEvent.nickname : currentEvent.name) : "Event"} />
                {error && <ErrorAlert error={error} width="12"/>}
                {currentEvent ?
                    <>
                        <IonRow className="ion-text-center ion-justify-content-center">
                            <IonCol sizeSm="12" sizeMd="8">
                                <IonCard className="bg-white ion-padding">
                                    <IonText className="ion-justify-content-center">
                                        <h3 className="text-center">{currentEvent.name}</h3>
                                        <p className="text-center">{formatStartEndDatesStrings(currentEvent.startDate, currentEvent.endDate)}</p>
                                        <p className="text-center">Organization: {currentOrganization?.name}</p>
                                    </IonText>
                                    {(currentEvent.status === "draft" && currentEvent.eventOptions?.usesEventManagement) && (
                                        <IonRow id="eventDuplicate" className="ion-text-center ion-justify-content-center">
                                            <IonCol sizeSm="12" sizeMd="8">
                                                <IonButton color="tertiary" onClick={navigateToCopySettingsPage}>
                                                    Copy From Past Event
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    )}
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow id="eventStatus" className="ion-text-center ion-justify-content-center">
                            <IonCol sizeSm="12" sizeMd="8">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        <IonRow onClick={() => setIsStatusCollapsed(!isStatusCollapsed)}>
                                            <IonCol className="ion-text-left">
                                                Status: {currentEvent.status}
                                            </IonCol>
                                            <IonCol className="ion-text-right">
                                                <p>{isStatusCollapsed ? "Expand" : "Collapse"}</p>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardTitle>
                                    {!isStatusCollapsed && (
                                        <IonCardContent>
                                            <EventStatusForm event={currentEvent} />
                                        </IonCardContent>
                                    )}
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-text-center ion-justify-content-center">
                            <IonCol sizeSm="12" sizeMd="8">
                                <IonCard className="bg-white ion-padding">
                                    <IonList className="bg-white">
                                        <IonItem
                                            detail={true}
                                            routerLink={"/index/staff/event/" + currentEvent.id + "/about"}
                                            routerDirection="none"
                                        >
                                            <IonIcon icon={informationCircleOutline} slot="start" />
                                            <IonLabel>
                                                <h2 className="ion-text-wrap">About Info</h2>
                                                <p className="ion-text-wrap">Affilitations, Location, Contact Info, Documents, etc.</p>
                                            </IonLabel>
                                        </IonItem>
                                        {currentEvent.eventOptions?.usesTextAlerts && (
                                            <IonItem
                                                detail={true}
                                                routerLink={"/index/staff/event/" + currentEvent.id + "/alerts"}
                                                routerDirection="none"
                                            >
                                                <IonIcon icon={phonePortraitOutline} slot="start" />
                                                <IonLabel>
                                                    <h2 className="ion-text-wrap">Text Alerts</h2>
                                                    <p className="ion-text-wrap">Send Alerts, View Subscribers, Check Credits</p>
                                                </IonLabel>
                                            </IonItem>
                                        )}
                                        {currentEvent.eventOptions?.usesJudgeApp && (
                                            <IonItem
                                                detail={true}
                                                routerLink={"/index/staff/event/" + currentEvent.id + "/judge-game"}
                                                routerDirection="none"
                                            >
                                                <IonIcon icon={ribbonOutline} slot="start" />
                                                <IonLabel>
                                                    <h2 className="ion-text-wrap">Judging Game</h2>
                                                    <p className="ion-text-wrap">Enter the correct scores here</p>
                                                </IonLabel>
                                            </IonItem>
                                        )}
                                        {currentEvent.eventOptions?.usesEventManagement && (
                                            <>
                                                <IonItem
                                                    detail={true}
                                                    routerLink={"/index/staff/event/" + currentEvent.id + "/classes"}
                                                    routerDirection="none"
                                                >
                                                    <IonIcon icon={reorderThreeOutline} slot="start" />
                                                    <IonLabel>
                                                        <h2 className="ion-text-wrap">Classes</h2>
                                                        <p className="ion-text-wrap">List All Classes and Divisions</p>
                                                    </IonLabel>
                                                </IonItem>
                                                <IonItem
                                                    detail={true}
                                                    routerLink={"/index/staff/event/" + currentEvent.id + "/schedule"}
                                                    routerDirection="none"
                                                >
                                                    <IonIcon icon={calendarOutline} slot="start" />
                                                    <IonLabel>
                                                        <h2 className="ion-text-wrap">Schedule</h2>
                                                        <p className="ion-text-wrap">Daily Schedules, Rings</p>
                                                    </IonLabel>
                                                </IonItem>
                                                <IonItem
                                                    detail={true}
                                                    routerLink={"/index/staff/event/" + currentEvent.id + "/fees"}
                                                    routerDirection="none"
                                                >
                                                    <IonIcon icon={pricetagsOutline} slot="start" />
                                                    <IonLabel>
                                                        <h2 className="ion-text-wrap">Event Fees</h2>
                                                        <p className="ion-text-wrap">Set Event Fees, Include Stabling, Handle Taxes</p>
                                                    </IonLabel>
                                                </IonItem>
                                                <IonItem
                                                    detail={true}
                                                    routerLink={"/index/staff/event/" + currentEvent.id + "/participants"}
                                                    routerDirection="none"
                                                >
                                                    <IonIcon icon={peopleOutline} slot="start" />
                                                    <IonLabel>
                                                        <h2 className="ion-text-wrap">Entries</h2>
                                                        <p className="ion-text-wrap">Review Pending Entries, Check In, Edit Entries, Check Out</p>
                                                    </IonLabel>
                                                </IonItem>
                                                <IonItem
                                                    detail={true}
                                                    routerLink={"/index/staff/event/" + currentEvent.id + "/results"}
                                                    routerDirection="none"
                                                >
                                                    <IonIcon icon={ribbonOutline} slot="start" />
                                                    <IonLabel>
                                                        <h2 className="ion-text-wrap">Results</h2>
                                                        <p className="ion-text-wrap">Class Results, Division Results, Prize Money</p>
                                                    </IonLabel>
                                                </IonItem>
                                                <IonItem
                                                    detail={true}
                                                    routerLink={"/index/staff/event/" + currentEvent.id + "/settings"}
                                                    routerDirection="none"
                                                >
                                                    <IonIcon icon={cogOutline} slot="start" />
                                                    <IonLabel>
                                                        <h2 className="ion-text-wrap">Settings</h2>
                                                        <p className="ion-text-wrap">Settings for Text Alerts, Entries, Stabling, Rules, etc</p>
                                                    </IonLabel>
                                                </IonItem>
                                            </>
                                        )}
                                        <IonItem
                                            detail={true}
                                            routerLink={"/index/staff/event/" + currentEvent.id + "/reports"}
                                            routerDirection="none"
                                        >
                                            <IonIcon icon={documentOutline} slot="start" />
                                            <IonLabel>
                                                <h2 className="ion-text-wrap">Reports</h2>
                                                {(currentEvent.eventOptions?.usesTextAlerts && currentEvent.eventOptions.usesEventManagement) ? (
                                                    <p className="ion-text-wrap">Text Alert Reports, Class Sheets, Daily Schedules, Results and Financial Reports</p>
                                                )
                                                    :
                                                    <>
                                                        {(currentEvent.eventOptions?.usesTextAlerts) && (
                                                            <p className="ion-text-wrap">Subscriber Report, Alert Report, Credit Report</p>
                                                        )}
                                                        {(currentEvent.eventOptions?.usesEventManagement) && (
                                                            <p className="ion-text-wrap">Class Sheets, Daily Schedules, Results and Financial Reports</p>
                                                        )}
                                                    </>
                                                }
                                            </IonLabel>
                                        </IonItem>
                                        <IonItem
                                            detail={true}
                                            routerLink={"/index/staff/event/" + currentEvent.id + "/resources"}
                                            routerDirection="none"
                                        >
                                            <IonIcon icon={bookmarksOutline} slot="start" />
                                            <IonLabel>
                                                <h2 className="ion-text-wrap">Resources</h2>
                                                <p className="ion-text-wrap">QR Code, Flyers</p>
                                            </IonLabel>
                                        </IonItem>
                                    </IonList>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-text-center ion-justify-content-center">
                            <IonCol sizeSm="12" sizeMd="8">
                                <IonCard className="bg-white ion-padding">
                                    <IonList className="bg-white">
                                        <IonItem onClick={() => setShowModal(true)}>
                                            <IonIcon icon={trashOutline} slot="start" />
                                            <IonLabel>
                                                <h2 className="ion-text-wrap font-weight-bold text-danger">Delete Event</h2>
                                                <p className="ion-text-wrap">Permanently deletes this event and all data associated with it.</p>
                                            </IonLabel>
                                        </IonItem>
                                    </IonList>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonModal backdropDismiss={false} isOpen={showModal} id="termsAndConditionsModal">
                            <IonToolbar color="light">
                                <IonTitle className="ion-text-center">
                                    Delete Event
                                </IonTitle>
                                <IonButtons slot="end">
                                    <IonButton
                                        fill="clear"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <p id="closeAddressModalBtn" className="font-weight-normal text-medium text-capitalize">
                                            <IonIcon icon={close} />
                                        </p>
                                    </IonButton>
                                </IonButtons>
                            </IonToolbar>
                            <IonContent className="ion-padding">
                                {currentEvent && (
                                    <>
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol size="10" className="ion-text-center">
                                                <h3>Are you sure you want to delete:</h3>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol size="10" className="ion-text-center">
                                                <h2>{currentEvent.name}</h2>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol size="10" className="ion-text-center">
                                                <h3>{currentEvent.startDate ? formatStartEndDatesStrings(currentEvent.startDate, currentEvent.endDate) : ""}</h3>
                                            </IonCol>
                                        </IonRow>
                                    </>
                                )}
                            </IonContent>
                            {isLoading ? 
                                <Spinner />
                                :
                                <IonRow className="ion-justify-content-center ion-padding-top">
                                    <IonCol size="6" className="ion-text-center">
                                        <IonButton expand="block" color="light" onClick={() => setShowModal(false)}>
                                            Cancel
                                        </IonButton>
                                    </IonCol>
                                    <IonCol size="6" className="ion-text-center">
                                        <IonButton expand="block" color="danger" onClick={deleteEvent}>
                                            Delete
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            }
                        </IonModal>
                    </>
                    :
                    <>
                        {isLoading ?
                            <Spinner />
                            :
                            <CreateEventPage />
                        }
                    </>
                }
            </IonContent>
        </IonPage>
    );
};

export default EventMenuPage;