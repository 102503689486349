export interface BlockTier {
    id: number,
    numberCredits: number,
    pricePerCredit: number,
    cost: number,
    useCase: string
    priceId?: string
}


export const creditBlockPricingStructure = [
    {
        "id": 1,
        "numberCredits": 1000,
        "pricePerCredit": 0.05,
        "cost": 49,
        "useCase": "Add On: Extra credits in case you need them.",
        "priceId": "price_1MLqBkGFC3nLvhl68YKImB0H"
    },
    {
        "id": 2,
        "numberCredits": 5000,
        "pricePerCredit": 0.04,
        "cost": 199,
        "useCase": "Ideal for small shows.",
        "priceId": "price_1MLqCpGFC3nLvhl6vIYyA6MB"
    },
    {
        "id": 3, 
        "numberCredits": 10000,
        "pricePerCredit": 0.035,
        "cost": 349,
        "useCase": "Ideal for medium shows.",
        "priceId": "price_1MLqDJGFC3nLvhl6lPOeGvyF"
    },
    {
        "id": 4, 
        "numberCredits": 25000,
        "pricePerCredit": 0.03,
        "cost": 749,
        "useCase": "Ideal for large shows sending a few alerts each day.",
        "priceId": "price_1K7mrTGFC3nLvhl6wDdqq3oT"
    },
    {
        "id": 5, 
        "numberCredits": 40000,
        "pricePerCredit": 0.025,
        "cost": 999,
        "useCase": "Ideal for large shows sending consistent alerts each day.",
        "priceId": "price_1K7msLGFC3nLvhl6VS2pUu05"
    }
];