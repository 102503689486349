import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { UpdateEventEntryInput, UpdateOwnerInput, UpdateContactInput, CreateContactInput } from "../../../../API";
import { EventEntry, Owner, Membership } from "../../../../models";
import { updateEventEntry } from "../../../../utilities/eventEntry/EventEntry";
import { updateOwner } from "../../../../utilities/owner/Owner";
import { getMembershipsByOwnerId } from "../../../../utilities/membership/Membership";
import SuccessBanner from "../../../Banners/SuccessBanner";
import ErrorAlert from "../../../Errors/ErrorAlert";
import Spinner from "../../../Spinners/Spinner";
import { formatTwilioNumber } from "../../../../utilities/contact/FormatPhoneNumber";
import { createContact, updateContact } from "../../../../utilities/contact/Contact";
import { Table } from "reactstrap";
import { close } from "ionicons/icons";
import moment from "moment";

interface _Props {
    entry: EventEntry
}

const EntryInfoOwner: React.FC<_Props> = ({entry}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();
    const [ownerName, setOwnerName] = useState("");
    const [ownerLocation, setOwnerLocation] = useState("");
    const [ownerEmail, setOwnerEmail] = useState("");
    const [ownerPhone, setOwnerPhone] = useState("");
    const [membershipData, setMembershipData] = useState<Membership[] | null | undefined>();
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [showModal, setShowModal] = useState(false);

    async function setMemberships(owner: Owner) {
        let memberships: Membership[] = [];
        const queryResult = await getMembershipsByOwnerId(owner.id);
        if (queryResult.isSuccess) {
            const membershipResults: Membership[] = queryResult.result;
            memberships = membershipResults.sort((a, b) => a.name.localeCompare(b.name));
        }
        setMembershipData(memberships);
    }

    const getEntryInformation = (entry: EventEntry) => {
        setCurrentEntry(entry);
        setOwnerName(entry.ownerName || "");
        setOwnerLocation(entry?.owner?.location || "");
        setOwnerEmail(entry.owner?.contact?.personalEmail || "");
        setOwnerPhone(entry?.owner?.contact?.cell || "");
        if (entry.owner) setMemberships(entry.owner);
    }

    useEffect(() => {
        if (entry) {
            getEntryInformation(entry);
        }
    }, [entry]);

    const handleEdit = async () => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        let contactId = "";
        if (entry.owner?.contact?.id) {
            const updateContactInput: UpdateContactInput = {
                id: entry.owner.contact.id,
                personalEmail: ownerEmail,
                cell: formatTwilioNumber(ownerPhone) || ""
            };
            const updateContactResult = await updateContact(updateContactInput);
            if (updateContactResult) {
                contactId = updateContactResult.result.id;
            } else {
                setError("Could not update the contact info.");
                setIsLoading(false);
                return;
            }
        } else {
            const createContactInput: CreateContactInput = {
                personalEmail: ownerEmail,
                cell: ownerPhone ? formatTwilioNumber(ownerPhone) : undefined
            };
            const createContactResult = await createContact(createContactInput);
            if (createContactResult) {
                contactId = createContactResult.result.id;
            } else {
                setError("Could not create the contact info.");
                setIsLoading(false);
                return;
            }
        }
        if (entry.ownerId) {
            const input: UpdateOwnerInput = {
                id: entry.ownerId,
                name: ownerName,
                location: ownerLocation,
                ownerContactId: contactId
            };
            const updateResult = await updateOwner(input);
            if (updateResult.isSuccess) {
                const updateEntryInput: UpdateEventEntryInput = {
                    id: entry.id,
                    ownerName: ownerName,
                    ownerId: updateResult.result.id
                };
                const updateEntryResult = await updateEventEntry(updateEntryInput);
                if (updateEntryResult.isSuccess) {
                    const updatedEntry = updateEntryResult.result;
                    setCurrentEntry(updatedEntry);
                    getEntryInformation(updatedEntry);
                    setMemberships(updatedEntry.owner);
                    setSuccess("Updated the owner info.");
                    setIsEdit(false);
                    setIsLoading(false);
                } else {
                    setError("Could not update the entry.");
                    setIsLoading(false);
                    return;
                }
            } else {
                setError("Could not update the owner");
                setIsLoading(false);
            }
        } else {
            setError("Could not find the owner in the database.");
            setIsLoading(false);
        }
    }

    return (
        <>
            <IonCard color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            <h3>Owner</h3>
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {error && <ErrorAlert width="12" error={error} />}
                        {success && <SuccessBanner width="12" success={success} />}
                        {isEdit ?
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner Name</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={ownerName}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setOwnerName(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner Location</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={ownerLocation}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setOwnerLocation(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner Email</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={ownerEmail}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setOwnerEmail(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner Cell Phone</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={ownerPhone}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setOwnerPhone(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </>
                            :
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner Name</IonLabel>
                                            <IonText><p>{ownerName}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner Location</IonLabel>
                                            <IonText><p>{ownerLocation}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner Email</IonLabel>
                                            <IonText><p>{ownerEmail}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner Cell Phone</IonLabel>
                                            <IonText><p>{ownerPhone}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </>
                        }
                        {/* {isEdit && (
                            <IonRow>
                                <IonCol>
                                    <IonButton
                                        color="success"
                                        size="small"
                                        onClick={() => setShowModal(true)}
                                    >
                                        <IonIcon icon={addCircleOutline} />
                                        Add Membership
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        )} */}
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="11">
                                {(membershipData && membershipData.length > 0) ?
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Membership</th>
                                                <th>ID</th>
                                                <th>Type</th>
                                                <th>Date Expires</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {membershipData.map((membership, index) => (
                                                <tr key={index}>
                                                    <td>{membership.name}</td>
                                                    <td>{membership.membershipId}</td>
                                                    <td>{membership.type}</td>
                                                    <td>{membership.dateMembershipEnds ? moment(membership.dateMembershipEnds).format("MMM DD, YYYY") : ""}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    :
                                    <p>No owner memberships were found.</p>
                                }
                            </IonCol>
                        </IonRow>
                        {isLoading ?
                            <Spinner />
                            :
                            <IonRow className="ion-justify-content-center">
                                {isEdit && (
                                    <IonCol sizeMd="4" className="ion-text-center">
                                        <IonButton
                                            className="ion-margin-top"
                                            color="primary"
                                            expand="block"
                                            onClick={() => setIsEdit(false)}
                                        >
                                            Cancel
                                        </IonButton>
                                    </IonCol>
                                )}
                                <IonCol sizeMd="4" className="ion-text-center">
                                    <IonButton
                                        className="ion-margin-top"
                                        color="tertiary"
                                        expand="block"
                                        onClick={isEdit ? handleEdit : (() => setIsEdit(true))}
                                    >
                                        {!isEdit && "Allow "}Edit
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        }
                    </IonCardContent>
                )}
            </IonCard>
            {/* Create New Owner Membership */}
            <IonModal backdropDismiss={false} isOpen={showModal} id="createOwnerMembershipModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Create Membership
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="createOwnerMembershipCloseBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {entry.owner ?
                        <></>
                        :
                        <p>No owner found.</p>
                    }
                </IonContent>
            </IonModal>
        </>
    );
};

export default EntryInfoOwner;