import { Result } from "../../interfaces/Result";
import { USEFHorse } from "../../interfaces/USEFData";
import { apiURLTest, createUSEFSignature, softwareKeyTest } from "./USEFValues";

function createURLtoGetHorseById() {
    return apiURLTest + "horse/getbyid";
}

/**
 * Returns the USEF data about a horse found for a USEF Id
 * 
 * Requires a Software Key in production
 * 
 * @param id the USEF id of the horse
 * @param softwareKey acquired from the show manager (in dev it can be a test key)
 * @returns Result object with horse data or null
 */
export async function getUSEFHorseById(id: string, softwareKey?: string): Promise<Result> {
    try {
        // Pass in the USEF Id and software key
        const requestBody: any = {
            "Id": parseInt(id),
            "SoftwareKey": softwareKey || softwareKeyTest
        };

        // Create the needed URL for this API call
        const currentAPIURL = createURLtoGetHorseById();

        // Use a POST request
        const response = await fetch(currentAPIURL, {
            method: "POST",
            body: JSON.stringify(requestBody),
            headers: {
                'Authorization': createUSEFSignature(),
                'Content-Type': "application/json"
            }
        });

        // Handle the response
        if (response) {
            const myJson = await response.json();
            if (myJson.horse) {
                const horse: USEFHorse = myJson.horse[0];
                let result: Result = {isSuccess: true, type: "USEFSearch", result: horse, message: "We found your USEF record."};
                return result;
            } else {
                let result: Result = {isSuccess: false, type: "USEFSearch", result: null, message: "Please ensure you have the correct USEF Id or contact hello@ringsidepro.com."};
                return result;
            }
        } else {
            let result: Result = {isSuccess: false, type: "USEFSearch", result: null, message: "An error occurred. Please ensure you have the correct USEF Id or contact hello@ringsidepro.com."};
            return result;
        }
    } catch (error) {
        console.error("Caught an error: ", error);
        let result: Result = {isSuccess: false, type: "USEFSearch", result: null, message: "An error was caught. Please ensure you have the correct USEF Id or contact hello@ringsidepro.com."};
        return result;
    }
}