import React, { useEffect, useState } from "react";
import { EventClass } from "../../models";
import { getClassById } from "../../utilities/class/Class";
import { getEventClassesByEventIdWithClassesAndEventDivisions } from "../../utilities/eventClass/EventClass";
import { getEventClassTypeAbbreviation } from "../../utilities/eventClass/EventClassTypes";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";

interface _Props {
    eventId: string
    menuPlacement?: string
    selectedValue?: string
    onSelect: Function
}

const SelectEventClass: React.FC<_Props> = ({eventId, selectedValue, menuPlacement, onSelect}) => {

    const [selected, setSelected] = useState<string | null | undefined>();
    const [formattedEventClasses, setFormattedEventClasses] = useState<formattedOption[] | null | undefined>();

    const formatEventClasses = async (eventClasses: EventClass[]) => {
        let formattedEventClasses = [];
        for (var i = 0; i < eventClasses.length; i++) {
            const currentEventClass = eventClasses[i];
            let currentClass = currentEventClass.class;
            if (!currentClass) {
                const classId = eventClasses[i].classId;
                const queryResult = await getClassById(classId);
                if (queryResult.isSuccess) {
                    currentClass = queryResult.result;
                }
            }
            if (currentClass) {
                const classType = (currentClass.type ? (getEventClassTypeAbbreviation(currentClass.type)) : "");
                const label = currentEventClass.number + " - " + currentEventClass.name + " " + classType;
                let object = {
                    value: currentEventClass.id,
                    label: label,
                    object: currentEventClass
                };
                formattedEventClasses.push(object);
            }
        }
        setFormattedEventClasses(formattedEventClasses);
    }

    useEffect(() => {
        async function getEventClasses(eventId: string) {
            const queryResult = await getEventClassesByEventIdWithClassesAndEventDivisions(eventId);
            if (queryResult.isSuccess) {
                formatEventClasses(queryResult.result);
            }
        }

        if (eventId) getEventClasses(eventId);
    }, [eventId]);

    useEffect(() => {
        if (selectedValue) {
            setSelected(selectedValue);
        } else {
            setSelected(undefined);
        }
    }, [selectedValue]);

    const handleOnChange = (event?: any) => {
        if (event) {
            onSelect(event);
        } else {
            onSelect();
        }
    }

    return (
        <>
            {formattedEventClasses ?
                <BasicSelect formattedValues={formattedEventClasses} selectedValue={selected} menuPlacement={menuPlacement} onSelect={handleOnChange}/>
                :
                <p>Loading options...</p>
            }
        </>
    );
};

export default SelectEventClass;
