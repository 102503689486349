import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventAuditorInput, CreateEventAuditorMutation, DeleteEventAuditorInput, DeleteEventAuditorMutation, GetEventAuditorQuery, ListEventAuditorsQuery, UpdateEventAuditorInput, UpdateEventAuditorMutation } from "../../API";
import moment from "moment";
import { eventAuditorByPhoneNumberByEventWithFilters, listEventAuditorsWithFilters } from "./EventAuditorCustomQueries";
import { sortEventAuditorsByName } from "./SortEventAuditors";
import { EventAuditor } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventAuditor. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventAuditor.
*/
export async function createEventAuditor(input: CreateEventAuditorInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventAuditor", "No input", "Create EventAuditor received no input.");
   try {
        const fullInput: CreateEventAuditorInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEventAuditor, { input: fullInput }))) as GraphQLResult<CreateEventAuditorMutation>;
       else result = (await API.graphql({
           query: mutations.createEventAuditor,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateEventAuditorMutation>;
       const eventAuditor = result.data?.createEventAuditor;
       return formatResult(true, "EventAuditor", eventAuditor, "Successfully created the eventAuditor.");
   } catch (error: any) {
       return formatResult(false, "EventAuditor", error, "Error creating record in the database for type: eventAuditor");
   }
}

/**
* Handle updating a new record in the database for type: eventAuditor. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventAuditor.
*/
export async function updateEventAuditor(input: UpdateEventAuditorInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventAuditor", "No input", "Update EventAuditor received no input.");
   try {
        const fullInput: UpdateEventAuditorInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEventAuditor, { input: fullInput }))) as GraphQLResult<UpdateEventAuditorMutation>;
       else result = (await API.graphql({
           query: mutations.updateEventAuditor,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateEventAuditorMutation>;
       const eventAuditor = result.data?.updateEventAuditor;
       return formatResult(true, "EventAuditor", eventAuditor, "Successfully updated the eventAuditor.");
   } catch (error: any) {
       return formatResult(false, "EventAuditor", error, "Error updating record in the database for type: eventAuditor");
   }
}

/**
* Handle deleting a new record in the database for type: eventAuditor. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventAuditor.
*/
export async function deleteEventAuditor(input: DeleteEventAuditorInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventAuditor", "No input", "Delete EventAuditor received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEventAuditor, { input: input }))) as GraphQLResult<DeleteEventAuditorMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEventAuditor,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventAuditorMutation>;
       const eventAuditor = result.data?.deleteEventAuditor;
       return formatResult(true, "EventAuditor", eventAuditor, "Successfully deleted the eventAuditor.");
   } catch (error: any) {
       return formatResult(false, "EventAuditor", error, "Error deleting record in the database for type: eventAuditor");
   }
}

/**
* Get all records in the database for type: eventAuditor. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventAuditor object
*/
export async function getAllEventAuditors(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEventAuditors,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventAuditorsQuery>;

        let items = result.data?.listEventAuditors?.items as EventAuditor[];
        let nextToken = result.data?.listEventAuditors?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventAuditors,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventAuditorsQuery>;

            const nextItems = nextResult.data?.listEventAuditors?.items as EventAuditor[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventAuditors?.nextToken;
        }

        return formatResult(true, "EventAuditor", items, "Successfully got the eventAuditors.");
    } catch (error: any) {
        return formatResult(false, "EventAuditor", error, "Error reading record in the database for type: eventAuditors");
    }
}

/**
* Read a specific record in the database for type: eventAuditor. 
* 
* @param {string}  id                  The eventAuditor id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventAuditor object
*/
export async function getEventAuditorById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEventAuditor,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventAuditorQuery>;
       const eventAuditor = result.data?.getEventAuditor;
       return formatResult(true, "EventAuditor", eventAuditor, "Successfully got the eventAuditor.");
   } catch (error: any) {
       return formatResult(false, "EventAuditor", error, "Error reading record in the database for type: eventAuditor");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventAuditor. 
* 
* @param {string}  phoneNumber         The phone number of the event auditor.
* @param {string}  eventId             The event id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventAuditor object
*/
export async function getEventAuditorsByPhoneNumberSearch(phoneNumber: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {phoneNumber: {contains: phoneNumber}};
        const result = (await API.graphql({
            query: queries.listEventAuditors,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventAuditorsQuery>;

        let items = result.data?.listEventAuditors?.items as EventAuditor[];
        let nextToken = result.data?.listEventAuditors?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventAuditors,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventAuditorsQuery>;

            const nextItems = nextResult.data?.listEventAuditors?.items as EventAuditor[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventAuditors?.nextToken;
        }

        const eventAuditors = items;
        const sorted = eventAuditors.sort((a, b) => (b.updatedOn || b.createdOn).localeCompare((a.updatedOn || a.createdOn)));
        return formatResult(true, "EventAuditor", sorted || eventAuditors, "Successfully got the eventAuditors.");
    } catch (error: any) {
        return formatResult(false, "EventAuditor", error, "Error reading record in the database for type: eventAuditor");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventAuditor. 
* 
* @param {string}  phoneNumber         The phone number of the event auditor.
* @param {string}  eventId             The event id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventAuditor object
*/
export async function getEventAuditorByPhoneNumber(phoneNumber: string, eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventAuditorByPhoneNumberByEvent,
            variables: {
                limit: 1000,
                phoneNumber: phoneNumber,
                eventId: {eq: eventId}
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventAuditorByPhoneNumberByEvent?.items as EventAuditor[];
        let nextToken = result.data?.eventAuditorByPhoneNumberByEvent?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventAuditorByPhoneNumberByEvent,
                variables: {
                    limit: 100,
                    phoneNumber: phoneNumber,
                    eventId: {eq: eventId},
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventAuditorByPhoneNumberByEvent?.items as EventAuditor[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventAuditorByPhoneNumberByEvent?.nextToken;
        }

        const eventAuditors = items;
        if (eventAuditors && eventAuditors[0]) return formatResult(true, "EventAuditor", eventAuditors[0], "Successfully got the eventAuditor.");
        else return formatResult(false, "EventAuditor", null, "Could not find the eventAuditor.");
    } catch (error: any) {
        return formatResult(false, "EventAuditor", error, "Error reading record in the database for type: eventAuditor");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventAuditor. 
* 
* @param {string}  phoneNumber         The phone number of the event auditor.
* @param {string}  eventId             The event id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventAuditor object
*/
export async function getEventAuditorWithFiltersByPhoneNumber(phoneNumber: string, eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: eventAuditorByPhoneNumberByEventWithFilters,
            variables: {
                limit: 1000,
                phoneNumber: phoneNumber,
                eventId: {eq: eventId}
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventAuditorByPhoneNumberByEvent?.items as EventAuditor[];
        let nextToken = result.data?.eventAuditorByPhoneNumberByEvent?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: eventAuditorByPhoneNumberByEventWithFilters,
                variables: {
                    limit: 100,
                    phoneNumber: phoneNumber,
                    eventId: {eq: eventId},
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventAuditorByPhoneNumberByEvent?.items as EventAuditor[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventAuditorByPhoneNumberByEvent?.nextToken;
        }

        const eventAuditors = items;
        if (eventAuditors && eventAuditors[0]) return formatResult(true, "EventAuditor", eventAuditors[0], "Successfully got the eventAuditor.");
        else return formatResult(false, "EventAuditor", null, "Could not find the eventAuditor.");
    } catch (error: any) {
        return formatResult(false, "EventAuditor", error, "Error reading record in the database for type: eventAuditor");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventAuditor. 
* 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventAuditor object
*/
export async function getEventAuditorsByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {createdBy: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listEventAuditors,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventAuditorsQuery>;

        let items = result.data?.listEventAuditors?.items as EventAuditor[];
        let nextToken = result.data?.listEventAuditors?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventAuditors,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventAuditorsQuery>;

            const nextItems = nextResult.data?.listEventAuditors?.items as EventAuditor[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventAuditors?.nextToken;
        }

        return formatResult(true, "EventAuditor", items, "Successfully got the eventAuditors.");
    } catch (error: any) {
        return formatResult(false, "EventAuditor", error, "Error reading record in the database for type: eventAuditors");
    }
}



/**
* Get all records in the database that match the given criteria for type: eventAuditor. 
* 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventAuditor object
*/
export async function getEventAuditorsByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventAuditorsByEvent,
            variables: {
                limit: 1000,
                eventId: eventId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventAuditorsByEvent?.items as EventAuditor[];
        let nextToken = result.data?.eventAuditorsByEvent?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventAuditorsByEvent,
                variables: {
                    limit: 100,
                    eventId: eventId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventAuditorsByEvent?.items as EventAuditor[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventAuditorsByEvent?.nextToken;
        }

        const sorted = sortEventAuditorsByName(items);
        if (sorted) {
            return formatResult(true, "EventAuditor", sorted, "Successfully got the eventAuditors.");
        } else {
            return formatResult(true, "EventAuditor", items, "Successfully got the eventAuditors.");
        }
    } catch (error: any) {
        return formatResult(false, "EventAuditor", error, "Error reading record in the database for type: eventAuditors");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventAuditor. 
* 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventAuditor object
*/
export async function getEventAuditorsWithFilterByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: listEventAuditorsWithFilters,
            variables: {
                limit: 1000,
                eventId: eventId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventAuditorsByEvent?.items as EventAuditor[];
        let nextToken = result.data?.eventAuditorsByEvent?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: listEventAuditorsWithFilters,
                variables: {
                    limit: 100,
                    eventId: eventId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventAuditorsByEvent?.items as EventAuditor[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventAuditorsByEvent?.nextToken;
        }

        return formatResult(true, "EventAuditor", items, "Successfully got the EventAuditors.");
    } catch (error: any) {
        return formatResult(false, "EventAuditor", error, "Error reading record in the database for type: eventAuditors");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventAuditor. 
* 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventAuditor object
*/
export async function getActiveEventAuditorsWithFilterByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {
            and: [
                { status: {ne: "paused"} },
                { status: {ne: "stopped"} }
            ]
        };
        const result = (await API.graphql({
            query: listEventAuditorsWithFilters,
            variables: {
                limit: 1000,
                eventId: eventId,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventAuditorsByEvent?.items as EventAuditor[];
        let nextToken = result.data?.eventAuditorsByEvent?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: listEventAuditorsWithFilters,
                variables: {
                    limit: 100,
                    eventId: eventId,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventAuditorsByEvent?.items as EventAuditor[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventAuditorsByEvent?.nextToken;
        }

        return formatResult(true, "EventAuditor", items, "Successfully got the EventAuditors.");
    } catch (error: any) {
        return formatResult(false, "EventAuditor", error, "Error reading record in the database for type: eventAuditors");
    }
}