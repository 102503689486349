/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://ij2q9bmj37.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "Emails",
            "endpoint": "https://esaf41fota.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "Payments",
            "endpoint": "https://6teaspr87k.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "TwilioAPI",
            "endpoint": "https://ov8o6flrqa.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://566d327cbzf3xaq65v7d763vpy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-nk6f7rdmljfvbgpyal4n77awhm",
    "aws_cognito_identity_pool_id": "us-east-1:56ae2df2-edef-4484-a406-0b9d8ef5f76f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Z7e2UXE2X",
    "aws_user_pools_web_client_id": "7f67gtpk4qfemclod9cj4sgpjm",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "rspbucket165135-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
