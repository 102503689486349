const constants = {
    ORGANIZATION: {
        BRANDING_MAX_COLORS: 2,
        BRANDING_CUSTOM_URL_LENGTH: 10,
        S3_FILE_URL_VALIDITY: 900
    },
    RINGSIDE_PRO_ORGANIZATION: {
        id: "ringSidePro",
        name: "RingSide Pro",
        createdBy: "",
        createdOn: "",
        updatedOn: ""
    },
    ADD_NEW_ORGANIZATION: {
        id: "addNewOrganization",
        name: "Add New Organization",
        createdBy: "",
        createdOn: "",
        updatedOn: ""
    },
    RHSC_ORGANIZATION: {
        id: "a14875cf-b06d-49bf-a030-ca76ab32f539",
        name: "RHSC / ABC Show Management (name in dev)",
        createdBy: "",
        createdOn: "",
        updatedOn: ""
    },
    MOHSS_ORGANIZATION: {
        id: "eae43c0b-0867-4bbf-9819-70d517439e31",
        name: "MOHSS / VHSA (name in dev)",
        createdBy: "",
        createdOn: "",
        updatedOn: ""
    },
    IHSA_ORGANIZATION: {
        id: "24611fd5-9648-40f3-9e84-7d10771c2625",
        name: "Intercollegiate Horse Shows Association",
        createdBy: "",
        createdOn: "",
        updatedOn: ""
    },
    SHOT_ORGANIZATION: {
        id: "24611fd5-9648-40f3-9e84-7d10771c2625",
        name: "Stock Horse of Texas",
        createdBy: "",
        createdOn: "",
        updatedOn: ""
    },
    Coveside_ORGANIZATION: {
        id: "9b544253-19a5-425e-956f-8b50414818c1",
        name: "Coveside Stables - Canadian Horse Shows",
        createdBy: "",
        createdOn: "",
        updatedOn: ""
    },
    European_Classic_Event_Id: "",
    COLORS: {
        PRIMARY: "#3a4454",
        SECONDARY: "#425974",
        TERTIARY: "#73a4d3"
    },
    SEASON_STATUS: {
        ACTIVE: "ACTIVE",
        IN_ACTIVE: "IN ACTIVE"
    },
    MEMBERSHIP: {
        MEMBERSHIP_ID_VALUES_ERROR_MSG: "The Membership Id Values are not formatted properly. You can include a list of increasing number values or ranges separated by commas.",
        MEMBERSHIP_NEXT_AVAILABLE_ID_ERR_MSG_1: "Please adjust the Membership Id Values. You cannot remove values that have already been assigned to memberships.",
        MEMBERSHIP_NEXT_AVAILABLE_ID_ERR_MSG_2: "Please adjust the Membership Id Values. You cannot add values that are less than the Next Unused Membership Id.",
        ONLINE_APPLICATION: {
            YES: "Yes",
            NO: "No"
        },
        START_MEMBERSHIP_ON_PURCHASE_DATE: {
            YES: "Yes",
            NO: "No"
        },
        GRADE_LEVEL: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        ONLINE_PAYMENT_FEE: 1.50, //Currently will leave this as $1.50 as RHSC tests the membership system
        APPLICATION_STATUS: {
            IN_PROGRESS: "in_progress",
            COMPLETE: "complete",
            IN_PROGRESS_TEXT: "In Progress",
            COMPLETE_TEXT: "Completed",
            APPLICATION_INCOMPLETE: "Application Incomplete",
            ACTIVE: "Active",
            EXPIRED: "Expired"

        },
        APPLICATION_STAGES: {
            BASIC: "Basic",
            UPLOADS: "Uploads",
            WAIVERS: "Waivers",
            PAYMENT: "Payment",
            CONFIRMATION: "Confirmation"
        },
        APPLICATION_EDIT_STAGES: {
            GROUP: "Group",
            BASIC: "Basic",
            CONTACT: "Contact",
            UPLOADS: "Uploads",
            WAIVERS: "Waivers",
            PAYMENT: "Payment"
        }
    }
};

export default constants;