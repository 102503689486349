import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Event, EventAuditor} from "../../../../../models";
import Header from "../../../../../components/Headers/Header";
import { RouteComponentProps, useHistory } from "react-router";
import CreditCalculator from "../../../../../components/Event/Alerts/CreditCalculator";
import {getEventById} from "../../../../../utilities/events/Event";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import EventAlertSettingsForm from "../../../../../components/Event/Alerts/AlertSettingsForm";
import EventFiltersForm from "../../../../../components/Event/Alerts/AlertFiltersForm";
import BuyCreditsForm from "../../../../../components/Event/Alerts/BuyCreditsForm";
import ViewOrganizationBlocks from "../../../../../components/Event/Alerts/ViewOrganizationBlocks";
import InformationBanner from "../../../../../components/Banners/InformationBanner";
import EventAuditorModal from "../../../../../components/EventAuditor/EventAuditorModal";
import EditEventAuditorsTable from "../../../../../components/Event/Alerts/EditEventAuditorsTable";
import SendAlertForm from "../../../../../components/Event/Alerts/SendAlertForm";
import ViewEventAlertsTable from "../../../../../components/Event/Alerts/ViewEventAlertsTable";
import EventAlertsNavbar from "../../../../../components/Navbars/EventAlertsNavbar";
import EditEventAuditorsFullTable from "../../../../../components/Event/Alerts/EditEventAuditorsFullTable";
import Spinner from "../../../../../components/Spinners/Spinner";
import DownloadLink from "../../../../../components/PDF/DownloadLink";
import { generateEventSubscriberReport } from "../../../../../utilities/reports/AlertsSubscriberReport";
import { generateEventAlertReport } from "../../../../../utilities/reports/AlertsReports";
import { getCheckoutSessionInfo } from "../../../../../utilities/stripe/Checkout";
import { creditBlockPricingStructure } from "../../../../../data/content/CreditBlockPricing";
import { CreateBlockInput, BlockStatus } from "../../../../../API";
import { PersonContext } from "../../../../../context/PersonContext";
import { createBlock } from "../../../../../utilities/block/Block";

interface EventPageProps extends RouteComponentProps<{
    id: string;
    session_id?: string;
}> {}

const EventAlertsPage: React.FC<EventPageProps> = ({match}) => {    
    const history = useHistory();
    const user = useContext(PersonContext);

    const [event, setEvent] = useState<Event>();
    const [currentEventAuditor, setCurrentEventAuditor] = useState<EventAuditor | undefined>();
    const [hasCreatedCreditBlock, setHasCreatedCreditBlock] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentTab, setCurrentTab] = useState("alerts");

    const [isDownloading, setIsDownloading] = useState(false);
    const [error, setError] = useState("");

    async function getEvent() {
        setError("");
        const queryResult = await getEventById(match.params.id);
        if (queryResult.isSuccess) {
            setEvent(queryResult.result);
            return queryResult.result;
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }
 
    useEffect(() => {
        getEvent();
    }, [match, match.params.id]);

    const getStripeSessionInfo = async (sessionId: string) => {
        const session = await getCheckoutSessionInfo(sessionId);
        if (session.isSuccess) {
            const sessionObject: any = session.result;
            if (sessionObject.payment_status === "paid") {
                // Create the new credit block
                const priceId = sessionObject.metadata.priceId;
                let selectedPricingOption = null;
                for (var i = 0; i < creditBlockPricingStructure.length; i++) {
                    const currentOption = creditBlockPricingStructure[i];
                    if (currentOption.priceId === priceId) {
                        selectedPricingOption = currentOption;
                    }
                }
                if (selectedPricingOption) {
                    const isPromo = sessionObject.total_details.amount_discount > 0;
                    let currentEvent = event;
                    if (!currentEvent) {
                        currentEvent = await getEvent();
                    }
                    if (currentEvent && currentEvent.organizationId) {
                        const blockInput: CreateBlockInput = {
                            organizationId: currentEvent.organizationId,
                            personid: user.id,
                            name: selectedPricingOption.useCase,
                            totalCredits: selectedPricingOption.numberCredits,
                            usedCredits: 0,
                            status: BlockStatus.valid,
                            price: selectedPricingOption.cost,
                            amountPaid: selectedPricingOption.cost - (sessionObject.total_details.amount_discount ? sessionObject.total_details.amount_discount/100 : 0),
                            isPromotion: isPromo.toString(),
                            isFree: "false"
                        };
                        const createResult = await createBlock(blockInput);
                        if (createResult.isSuccess) {
                            // Need to get rid of the URL parameter to avoid creating the credit block more than once.
                            const path = "/index/staff/event/" + currentEvent.id + "/alerts";
                            history.push(path);
                        } else {
                            setError("Sorry a problem occurred. The credit block was not created. Please contact customer service at: hello@ringsidepro.com");
                        }
                    } else {
                        setError("Sorry a problem occurred. The organization id was unknown. Please contact customer service at: hello@ringsidepro.com");
                    }
                } else {
                    setError("Sorry a problem occurred. The tier was unknown. Please contact customer service at: hello@ringsidepro.com");
                }
            }
        }
    }

    useEffect(() => {
        if (match.params.session_id) {
            if (!hasCreatedCreditBlock) {
                setHasCreatedCreditBlock(true);
                getStripeSessionInfo(match.params.session_id);
            }
        }
    }, [match, match.params.session_id]);

    const handleDownloadSubscriberReport = async () => {
        setError("");
        setIsDownloading(true);
        if (event) await generateEventSubscriberReport(event);
        else setError("There is no event.");
        setIsDownloading(false);
    }

    const handleDownloadAlertReport = async () => {
        setError("");
        setIsDownloading(true);
        if (event) await generateEventAlertReport(event);
        else setError("There is no event.");
        setIsDownloading(false);
    }

    const handleEventAuditorChange = (action: string, eventAuditor: EventAuditor) => {
        setCurrentEventAuditor(eventAuditor);
        setShowModal(false);
    }

    const handleTabSelection = (tab: string) => {
        setCurrentTab(tab);
    }

    const navigateToBulkAdd = () => {
        if (event) {
            const path = "/index/staff/event/" + event.id + "/alerts/subscribers/add";
            history.push(path);
        } else {
            setError("No event found.");
        }
    }

    return (
        <IonPage className="bg-light">
            <Header isEventPage={event ? true : false} event={event ? event : undefined} />
            <IonContent>
                <PageTitle title={event ? event.name : "alerts"} />
                {event && (
                    <>
                        <EventAlertsNavbar active={currentTab} onSelect={handleTabSelection} />
                        {error && <ErrorAlert width="12" error={error} />}
                        {currentTab === "alerts" && (
                            <>
                                {event.status === "draft" ?
                                    <>
                                        <IonRow id="alertSettings" className="ion-align-items-stretch">
                                            <IonCol sizeXs="12" sizeMd="6">
                                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                                    <IonCardTitle>Types of Alerts</IonCardTitle>
                                                    <IonCardContent>
                                                        <EventAlertSettingsForm event={event} />
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="6">
                                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                                    <IonCardTitle>Filters for Alerts</IonCardTitle>
                                                    <IonCardContent>
                                                        <InformationBanner info={"It's helpful to allow subscribers to choose filters on the alerts. This reduces the number of credits you need."} />
                                                        <EventFiltersForm event={event} />
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonCol>
                                        </IonRow>
                                    </>
                                    :
                                    <>
                                        <IonRow id="eventName" className="ion-align-items-stretch">
                                            <IonCol sizeXs="12" sizeMd="6">
                                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                                    <IonCardTitle>Send Alert</IonCardTitle>
                                                    <IonCardContent>
                                                        <SendAlertForm event={event} />
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="6">
                                                <IonCard mode="md" className="ion-padding pb-5 bg-white stretch card-min-height bg-white">
                                                    <IonCardTitle>
                                                        <IonRow>
                                                            <IonCol size="6">
                                                                Subscribers
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardTitle>
                                                    <IonCardSubtitle>
                                                        Scroll to see the subscribers.
                                                    </IonCardSubtitle>
                                                    <IonContent color="white">
                                                        <IonCardContent>
                                                            <EditEventAuditorsTable event={event} eventAuditor={currentEventAuditor} />
                                                        </IonCardContent>
                                                    </IonContent>
                                                </IonCard>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow id="eventName" className="ion-align-items-stretch pb-5">
                                            <IonCol size="12">
                                                <IonCard mode="md" className="ion-padding pb-5 bg-white stretch card-large-height">
                                                    <IonCardTitle>Alerts</IonCardTitle>
                                                    <IonCardSubtitle>
                                                        <IonRow>
                                                            <IonCol sizeXs="12" sizeMd="6">
                                                                <IonText className="ion-text-wrap">
                                                                    Scroll to see all alerts. Click an alert to see details.
                                                                </IonText>
                                                            </IonCol>
                                                            <IonCol sizeXs="12" sizeMd="6">
                                                                <IonText className="ion-text-wrap link" onClick={handleDownloadAlertReport}>
                                                                    {isDownloading ?
                                                                        <Spinner />
                                                                        :
                                                                        <p>Download Alerts</p>
                                                                    }
                                                                </IonText>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardSubtitle>
                                                    <IonContent color="white">
                                                        <IonCardContent>
                                                            <ViewEventAlertsTable event={event} />
                                                        </IonCardContent>
                                                    </IonContent>
                                                </IonCard>
                                            </IonCol>
                                        </IonRow>
                                    </>
                                }
                            </>
                        )}
                        {currentTab === "subscribers" && (
                            <>
                                <IonRow id="subscriber">
                                    <IonCol size="12">
                                        <IonCard mode="md" className="ion-padding bg-white">
                                            <IonCardTitle>
                                                <IonRow>
                                                    <IonCol sizeXs="12" sizeMd="6">
                                                        Subscribers
                                                    </IonCol>
                                                    <IonCol sizeXs="6" sizeMd="2" className="ion-text-right">
                                                        <IonButton color="success" onClick={navigateToBulkAdd}>Bulk Add</IonButton>
                                                    </IonCol>
                                                    <IonCol sizeXs="6" sizeMd="2" className="ion-text-right">
                                                        <IonButton onClick={() => setShowModal(!showModal)}>Add</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardTitle>
                                            <IonCardSubtitle>
                                                <IonRow>
                                                    <IonCol sizeXs="12" sizeMd="3">
                                                        <IonText className="ion-text-wrap">
                                                            Click a subscriber to edit it.
                                                        </IonText>
                                                    </IonCol>
                                                    <IonCol sizeXs="12" sizeMd="3">
                                                        <IonText className="ion-text-wrap" onClick={handleDownloadSubscriberReport}>
                                                            Click here to download list 
                                                            {isDownloading ?
                                                                <Spinner />
                                                                :
                                                                <DownloadLink />
                                                            }
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardSubtitle>
                                            <EventAuditorModal event={event} show={showModal} isAdminView={true} onChange={handleEventAuditorChange}/>
                                            <IonCardContent>
                                                <EditEventAuditorsFullTable event={event} eventAuditor={currentEventAuditor} />
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                            </>
                        )}
                        {currentTab === "credits" && (
                            <>
                                <IonRow id="blocks" className="ion-align-items-stretch">
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonCard mode="md" className="ion-padding bg-white stretch card-min-height">
                                            <IonCardTitle>Your Credit Blocks</IonCardTitle>
                                            <IonCardSubtitle>
                                                Scroll to see your organization's previous purchases.
                                            </IonCardSubtitle>
                                            <IonContent color="white">
                                                <IonCardContent>
                                                    <ViewOrganizationBlocks organizationId={event.organizationId}/>
                                                </IonCardContent>
                                            </IonContent>
                                        </IonCard>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonCard mode="md" className="ion-padding bg-white stretch card-min-height bg-white">
                                            <IonCardTitle>Buy Credits</IonCardTitle>
                                            <IonCardSubtitle>
                                                Scroll to see the options.
                                            </IonCardSubtitle>
                                            <IonContent color="white">
                                                <IonCardContent className="bg-white">
                                                    <BuyCreditsForm organizationId={event.organizationId} eventId={event.id}/>
                                                </IonCardContent>
                                            </IonContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow id="blocks" className="ion-align-items-stretch">
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonCard mode="md" className="ion-padding bg-white stretch">
                                            <IonCardTitle>Credit Calculator</IonCardTitle>
                                            <IonCardContent>
                                                <CreditCalculator event={event} />
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                            </>
                        )}
                    </>
                )}                
            </IonContent>
        </IonPage>
    );
};

export default EventAlertsPage;