import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    isPlatform,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import { isWindows } from "../../../utilities/platform/Platform";
import { Membership, Organization, OrganizationMembershipType, Person, PersonalInformation, Contact, Address } from "../../../models";
import { CreateAddressInput, CreateContactInput, CreatePersonalInformationInput, UpdateAddressInput, UpdateContactInput, UpdateMembershipInput, UpdatePersonInput, UpdatePersonalInformationInput } from "../../../API";
import { getPersonByPersonId, updatePerson } from "../../../utilities/person/Person";
import { createPersonalInformation, getPersonalInformationByPersonId, updatePersonalInformation } from "../../../utilities/personalInformation/PersonalInformation";
import Spinner from "../../Spinners/Spinner";
import SelectPersonForMembership from "../SelectPersonForMembership";
import ErrorAlert from "../../Errors/ErrorAlert";
import PhoneNumberTypeSelect from "../../Contact/PhoneNumberTypeSelect";
import PhoneInput from "react-phone-input-2";
import AddressFormGroup from "../../Address/AddressFormGroup";
import { createAddress, updateAddress } from "../../../utilities/address/Address";
import { formatAllCountryPhoneNumber } from "../../../utilities/contact/FormatPhoneNumber";
import { createContact, updateContact } from "../../../utilities/contact/Contact";
import { updateMembership } from "../../../utilities/membership/Membership";
import RequiredInputIndicator from "../../Forms/RequiredInputIndicator";

interface _Props {
    isDisabled?: (boolean | undefined)
    membershipType: OrganizationMembershipType;
    memberships: Membership[] | undefined;
    personId: string;
    user: Person | null | undefined;
    setMemberships: Function;
    organization: Organization | null | undefined;
}

const SingleMemberContactInfo: React.FC<_Props> = ({isDisabled, membershipType, memberships, setMemberships, personId, user, organization}) => {

    const membership: (Membership | undefined) = (memberships?.length) ? memberships[0] : undefined;
    const [isAELOrganization, setIsAELOrganization] = useState(false);

    const [error, setError] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCurrentlyDisabled, setIsCurrentlyDisabled] = useState<boolean>(false);

    const [selectedPerson, setSelectedPerson] = useState<Person | undefined | null>();
    
    const [personInfo, setPersonInfo] = useState<PersonalInformation>();
    const [email, setEmail] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [phoneType, setPhoneType] = useState<string>("");
    const [contact, setContact] = useState<Contact>();
    const [address, setAddress] = useState<Address>();
    const [streetAddressLine1, setStreetAddressLine1] = useState("");
    const [streetAddressLine2, setStreetAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [provState, setProvState] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");

    const handleSelectPerson = async (newlySelectedPerson?: Person) => {
        // AEL memberships will require selecting a person OR selecting an option to create a new person
        setSelectedPerson(newlySelectedPerson);

        if (newlySelectedPerson) {
            // If a person was selected, pre-populate form fields with as much data as you can
            await getPrePopulatedDataForIndividualMembership(newlySelectedPerson);
        } else {
            // If the option to create a new person was selected, then clear any data out of the form
            clearForm();
        }
    }

    const handlePhoneNumberTypeSelectInput = (phoneNumberTypeInput: string) => {
        if (phoneNumberTypeInput && phoneNumberTypeInput !== "-"){
            setPhoneType(phoneNumberTypeInput);
        } else {
            setPhoneType("");
        }
    };

    const handleAddressInputChange = (type: string, value: string) => {
        setError("");
        if (type === "streetAddressLine1") setStreetAddressLine1(value);
        if (type === "streetAddressLine2") setStreetAddressLine2(value);
        if (type === "city") setCity(value);
        if (type === "provState") setProvState(value);
        if (type === "zip") setZip(value);
        if (type === "country") setCountry(value);
    };

    const getPrePopulatedDataForPersonalInformation = async (currentPersonId: string) => {
        try {
            const queryResult = await getPersonalInformationByPersonId(currentPersonId);
            if (queryResult.isSuccess) {
                const personalInformation: PersonalInformation = queryResult.result;
                setPersonInfo(personalInformation);  
                if (personalInformation.address) {
                    setAddress(personalInformation.address);
                    if (personalInformation.address.streetAddress1) setStreetAddressLine1(personalInformation.address.streetAddress1);
                    if (personalInformation.address.streetAddress2) setStreetAddressLine2(personalInformation.address.streetAddress2);
                    if (personalInformation.address.city) setCity(personalInformation.address.city);
                    if (personalInformation.address.provState) setProvState(personalInformation.address.provState);
                    if (personalInformation.address.zip) setZip(personalInformation.address.zip);
                    if (personalInformation.address.country) setCountry(personalInformation.address.country);
                } else {
                    // Clear out address form fields
                    setAddress(undefined);
                    setStreetAddressLine1("");
                    setStreetAddressLine2("");
                    setCity("");
                    setProvState("");
                    setZip("");
                    setCountry("");
                }
                if (personalInformation.contact) {
                    setContact(personalInformation.contact);
                    if (personalInformation.contact.cell) {
                        setPhoneType("cell");
                        setPhoneNumber(personalInformation.contact.cell);
                    }
                    else if (personalInformation.contact.home) {
                        setPhoneType("home");
                        setPhoneNumber(personalInformation.contact.home);
                    }
                    else if (personalInformation.contact.work) {
                        setPhoneType("work");
                        setPhoneNumber(personalInformation.contact.work);
                    }
                    if (!email && personalInformation.contact.personalEmail) setEmail(personalInformation.contact.personalEmail);
                } else {
                    // Clear out contact form fields
                    setPhoneType("-");
                    setPhoneNumber("");
                    setEmail(selectedPerson?.email || "");
                }
            } else {
                // We will need to create the personal information in handleSubmit
                // No need to show an error to the user in this case
                clearForm();
            }
        } catch (error: any) {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    const getPrePopulatedDataForIndividualMembership = async (person?: Person) => {
        // Keep track of the Person record for this Membership
        let contactPersonOnMembership: Person | undefined = person;

        // If not AEL - person was not selected - so get the full record from the DB
        // If not AEL - then the person on the membership is assumed to be the current user
        if (!person) {
            const queryResult = await getPersonByPersonId(personId);
            if (queryResult.isSuccess) {
                contactPersonOnMembership = queryResult.result;
            }
        }

        // Now, get the data for the person on this membership to pre-populate the form as much as possible
        if (contactPersonOnMembership) {
            // First, try to get info by the current person id
            await getPrePopulatedDataForPersonalInformation(contactPersonOnMembership.id);

            // Check if the email has not been filled out yet, then try to pre-populate it
            if (!membership?.personEmail && contactPersonOnMembership.email) {
                setEmail(contactPersonOnMembership.email);
            }
        }
    }

    const clearForm = () => {
        setPersonInfo(undefined);
        setEmail("");
        setPhoneType("");
        setPhoneNumber("");
        setAddress(undefined);
        setContact(undefined);
        setStreetAddressLine1("");
        setStreetAddressLine2("");
        setCity("");
        setProvState("");
        setZip("");
        setCountry("");
    }

    const getPrePopulatedData = async () => {
        // Check if there is already a membership - pull in data from it
        if (membership) {
            const contactPersonIdOnMembership = membership.contactPersonId;
            if (contactPersonIdOnMembership) {
                const queryResult = await getPersonByPersonId(contactPersonIdOnMembership);
                if (queryResult.isSuccess) {
                    const contactPerson = queryResult.result;
                    setSelectedPerson(contactPerson);
                    await getPrePopulatedDataForIndividualMembership(contactPerson);
                }
            }
        } else {
            await getPrePopulatedDataForIndividualMembership();
        }
    };

    useEffect(() => {
        getPrePopulatedData();
    }, [membershipType, organization]);

    useEffect(() => {
        if (membership) getPrePopulatedData();
    }, [membership, memberships]);

    useEffect(() => {
        if (organization?.id === "ddc2fd3c-0bde-4b39-bbbb-5e00d2a35ba0") setIsAELOrganization(true);
    }, [organization]);

    const handleAddress = async (name: string, addressId?: (string | null | undefined)) => {
        try {
            let addressData: Address | null = null;
        
            if (addressId) {
                const addressInput: UpdateAddressInput = {
                    id: addressId,
                    name: name,
                    type: "mailing",
                    isDefault: true,
                    streetAddress1: streetAddressLine1,
                    streetAddress2: streetAddressLine2,
                    city: city,
                    provState: provState,
                    zip: zip,
                    country: country,
                    notes: ""
                };
                const updateAddressResult = await updateAddress(addressInput);
                if (updateAddressResult.isSuccess) {
                    addressData = updateAddressResult.result;
                    setAddress(updateAddressResult.result);
                } else {
                    setError(updateAddressResult.message);
                }
            } else {
                const addressInput: CreateAddressInput = {
                    name: name,
                    type: "mailing",
                    isDefault: true,
                    streetAddress1: streetAddressLine1,
                    streetAddress2: streetAddressLine2,
                    city: city,
                    provState: provState,
                    zip: zip,
                    country: country,
                    notes:""
                };
                const createAddressResult = await createAddress(addressInput);
                if (createAddressResult.isSuccess) {
                    addressData = createAddressResult.result;
                    setAddress(createAddressResult.result);
                } else {
                    setError(createAddressResult.message);
                }
            }
            return addressData;
        } catch (error) {
            const message = "Could not save the address information: " + error;
            setError(message);
        }
    }

    const handleContact = async (name: string, id?: (string | null | undefined), addressId?: (string | null | undefined)) => {
        try {
            let contactData: Contact | null = null;
            if (id) {
                let contactInput: UpdateContactInput = {
                    id: id,
                    name: name,
                };
                if (phoneType === "cell") {
                    contactInput["cell"] = formatAllCountryPhoneNumber(phoneNumber);
                } else if (phoneType === "home") {
                    contactInput["home"] = formatAllCountryPhoneNumber(phoneNumber);
                } else if (phoneType === "work") {
                    contactInput["work"] = formatAllCountryPhoneNumber(phoneNumber);
                }
                if (email) {
                    contactInput["personalEmail"] = email;
                }
                if (addressId) {
                    contactInput["mailingAddress"] = addressId;
                }
                const updateContactResult = await updateContact(contactInput);
                if (updateContactResult.isSuccess) {
                    setContact(updateContactResult.result);
                    contactData = updateContactResult.result;
                } else {
                    setError(updateContactResult.message);
                }
            } else {
                const contactInput: CreateContactInput = {
                    name: name,
                    cell: phoneType === "cell" ? formatAllCountryPhoneNumber(phoneNumber) : undefined,
                    home: phoneType === "home" ? formatAllCountryPhoneNumber(phoneNumber) : undefined,
                    work: phoneType === "work" ? formatAllCountryPhoneNumber(phoneNumber) : undefined,
                    personalEmail: email ? email : undefined,
                    mailingAddress: addressId || ""
                };
                const createContactResult = await createContact(contactInput);
                if (createContactResult.isSuccess) {
                    setContact(createContactResult.result);
                    contactData = createContactResult.result;
                } else {
                    setError(createContactResult.message);
                }
            }
            return contactData;
        } catch (error) {
            const message = "Could not save the contact information: " + error;
            setError(message);
        }
    }

    const handlePersonalInformation = async (addressData?: (Address | null | undefined), contactData?: (Contact | null | undefined)) => {
        try {
            // Check if the person needs personal information updated or created
            // Note - if a New Person was created - check if the personalInformationId would be present
            if (personInfo?.id){
                const personalInformationInput: UpdatePersonalInformationInput = {
                    id: personInfo?.id,
                    contactId: contactData?.id,
                    addressId: addressData?.id,
                };
                const updatePersonInfoResult = await updatePersonalInformation(personalInformationInput);
                if (updatePersonInfoResult.isSuccess) {
                    setPersonInfo(updatePersonInfoResult.result);
                } else {
                    setError(updatePersonInfoResult.message);
                }
            } else {
                const personalInformationInput: CreatePersonalInformationInput = {
                    personId: selectedPerson?.id || user?.id || "",
                    contactId: contactData?.id,
                    addressId: addressData?.id,
                };
                const createPersonInfoResult = await createPersonalInformation(personalInformationInput);
                if (createPersonInfoResult.isSuccess) {
                    const newPersonalInformationRecord = createPersonInfoResult.result;
                    setPersonInfo(newPersonalInformationRecord);
                    
                    if (selectedPerson) {
                        // Then, update the person record to include the new personal info record
                        const updatePersonInput: UpdatePersonInput = {
                            id: selectedPerson?.id,
                            personalInformationId: newPersonalInformationRecord.id
                        };
                        const updatePersonResult = await updatePerson(updatePersonInput);
                        if (!updatePersonResult.isSuccess) {
                            const message = "An error occurred. The address could not be added to your profile.";
                            setError(message);
                        }
                    }
                } else {
                    setError(createPersonInfoResult.message);
                }
            }
        } catch (error) {
            const message = "Could not save the personal information: " + error;
            setError(message);
        }
    }

    const verifyIsValid = () => {
        if (!email) {
            setError("A required field is missing data: Email");
            return false;
        }
        return true;
    };

    const createFullName = (firstName?: (string | null), lastName?: (string | null)) => {
        const fullNameFormatted = (firstName ? firstName.trim() : "") + 
        (firstName && lastName ? " " : "") + 
        (lastName ? lastName.trim() : "");
        return fullNameFormatted;
    }

    const getPersonFullName = () => {
        let person = selectedPerson || user;
        const fullName = createFullName(person?.firstName, person?.lastName);
        return fullName;
    }

    const handleUpdateMembership = async () => {
        try {
            if (membership) {
                let input: UpdateMembershipInput = {
                    id: membership.id,
                    contactPersonId: selectedPerson?.id
                };
                const updateMembershipResult = await updateMembership(input);
                if (updateMembershipResult.isSuccess) {
                    setMemberships([updateMembershipResult.result]);
                } else {
                    setError(updateMembershipResult.message);
                }
            }
        } catch (error) {
            const message = "Could not update the membership: " + error;
            setError(message);
        }
    }
    
    const handleSubmit = async () => {
        const isValid = verifyIsValid();
        if (isValid) {
            setIsLoading(true);
            try {
                const fullName = getPersonFullName();
                // First, create and update the Address Object
                const addressData = await handleAddress(fullName, personInfo?.addressId);
                // Second, create and update the Contact Object
                const contactData = await handleContact(fullName, personInfo?.contactId);
                // Third, create/update the PersonalInformation Object
                await handlePersonalInformation(addressData, contactData);
                // Fourth, update the membership with the person whose contact and address should be used
                await handleUpdateMembership();
            } catch (error: any) {
                const message = "Could not create or update the membership contact info: " + error;
                setError(message);
            }
            setIsLoading(false);   
        }
    };

    return (
        <div className={isDisabled ? "bg-light" : ""}>
            {isLoading ?
                <Spinner />
                :
                <>
                    {error && <ErrorAlert error={error} />}

                    {/* If AEL, ask which person this membership will be for */}
                    {isAELOrganization && (
                        <>
                            <IonRow className="mb-3">
                                <IonCol sizeXs="12" sizeMd="12">
                                    <SelectPersonForMembership 
                                        user={user}
                                        isDisabled={isDisabled}
                                        membership={membership}
                                        selectedValue={selectedPerson?.id}
                                        label={"Select who the contact is"}
                                        onSelect={handleSelectPerson}
                                        includeNewPersonOption={false}
                                    />
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                    <IonRow className="mb-3">  
                        {membershipType && membershipType.applicationFields?.contact && (
                            <IonCol sizeXs="12" sizeMd="12">
                                <IonItem color="white">
                                    <IonLabel
                                        position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                                        Email Address{" "}
                                        <RequiredInputIndicator />
                                    </IonLabel>
                                    <IonInput
                                        id="membership-app-basicInfo-email"
                                        type="email"
                                        required={true}
                                        aria-required={true}
                                        disabled={isDisabled}
                                        value={email}
                                        onIonChange={(e) => {
                                            setError("");
                                            setEmail(e.detail.value!);
                                        }}
                                    />
                                </IonItem>
                            </IonCol>
                        )}
                    </IonRow>
                    <IonRow className="mb-3">
                        {membershipType && membershipType.applicationFields?.contact && (
                            <>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <PhoneNumberTypeSelect
                                        id="membership-app-phone-number"
                                        isRequired={true}
                                        selectedType={phoneType}
                                        onChange={handlePhoneNumberTypeSelectInput}
                                    />
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonLabel className="pl-3 text-darker" position="stacked">
                                        Phone Number{" "}
                                        <RequiredInputIndicator />
                                    </IonLabel>
                                    <PhoneInput
                                        inputProps={{
                                            name: "phone",
                                            required: true,
                                            autoFocus: true,
                                            id:"membership-app-basicInfo-phone-number"
                                        }}
                                        placeholder="Enter phone number"
                                        country={"us"}
                                        enableSearch
                                        enableAreaCodes={false}
                                        inputStyle={{
                                            width: "100%",
                                            height: "auto",
                                        }}
                                        value={phoneNumber}
                                        disabled={isDisabled}
                                        onChange={(phoneNumber) => {
                                            setError("");
                                            setPhoneNumber(phoneNumber);
                                        }}
                                    />
                                </IonCol>
                            </>
                        )}
                    </IonRow>
                    {membershipType && membershipType.applicationFields?.contact && (
                        <AddressFormGroup
                            id="membership-app-basicInfo"
                            isRequired={true}
                            isTextDarker={true}
                            onChange={handleAddressInputChange}
                            address={address}
                            setIsDisabled={setIsCurrentlyDisabled}
                        />
                    )}
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                            <IonButton
                                id="mt-single-member-info-save-btn"
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                disabled={isDisabled}
                                onClick={handleSubmit}
                            >
                                Save
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
            }
        </div>
    );
};

export default SingleMemberContactInfo;
