/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAddress = /* GraphQL */ `
  mutation CreateAddress(
    $input: CreateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    createAddress(input: $input, condition: $condition) {
      id
      name
      type
      isDefault
      streetAddress1
      streetAddress2
      city
      provState
      zip
      country
      notes
      createdAt
      updatedAt
    }
  }
`;
export const updateAddress = /* GraphQL */ `
  mutation UpdateAddress(
    $input: UpdateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    updateAddress(input: $input, condition: $condition) {
      id
      name
      type
      isDefault
      streetAddress1
      streetAddress2
      city
      provState
      zip
      country
      notes
      createdAt
      updatedAt
    }
  }
`;
export const deleteAddress = /* GraphQL */ `
  mutation DeleteAddress(
    $input: DeleteAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    deleteAddress(input: $input, condition: $condition) {
      id
      name
      type
      isDefault
      streetAddress1
      streetAddress2
      city
      provState
      zip
      country
      notes
      createdAt
      updatedAt
    }
  }
`;
export const createAlert = /* GraphQL */ `
  mutation CreateAlert(
    $input: CreateAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    createAlert(input: $input, condition: $condition) {
      id
      type
      title
      body
      imageKey
      category
      organizationId
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      messageSegments
      subscriberCount
      totalCost
      blockIdsUsed
      fromUnlimitedEvent
      isIncoming
      sentFromPhoneNumber
      recipientPhoneNumbers
      successSIDList
      errorSIDList
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      alertEventId
    }
  }
`;
export const updateAlert = /* GraphQL */ `
  mutation UpdateAlert(
    $input: UpdateAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    updateAlert(input: $input, condition: $condition) {
      id
      type
      title
      body
      imageKey
      category
      organizationId
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      messageSegments
      subscriberCount
      totalCost
      blockIdsUsed
      fromUnlimitedEvent
      isIncoming
      sentFromPhoneNumber
      recipientPhoneNumbers
      successSIDList
      errorSIDList
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      alertEventId
    }
  }
`;
export const deleteAlert = /* GraphQL */ `
  mutation DeleteAlert(
    $input: DeleteAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    deleteAlert(input: $input, condition: $condition) {
      id
      type
      title
      body
      imageKey
      category
      organizationId
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      messageSegments
      subscriberCount
      totalCost
      blockIdsUsed
      fromUnlimitedEvent
      isIncoming
      sentFromPhoneNumber
      recipientPhoneNumbers
      successSIDList
      errorSIDList
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      alertEventId
    }
  }
`;
export const createAuditor = /* GraphQL */ `
  mutation CreateAuditor(
    $input: CreateAuditorInput!
    $condition: ModelAuditorConditionInput
  ) {
    createAuditor(input: $input, condition: $condition) {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      name
      phoneNumber
      email
      status
      statusNote
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const updateAuditor = /* GraphQL */ `
  mutation UpdateAuditor(
    $input: UpdateAuditorInput!
    $condition: ModelAuditorConditionInput
  ) {
    updateAuditor(input: $input, condition: $condition) {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      name
      phoneNumber
      email
      status
      statusNote
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const deleteAuditor = /* GraphQL */ `
  mutation DeleteAuditor(
    $input: DeleteAuditorInput!
    $condition: ModelAuditorConditionInput
  ) {
    deleteAuditor(input: $input, condition: $condition) {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      name
      phoneNumber
      email
      status
      statusNote
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const createBacklogEventResult = /* GraphQL */ `
  mutation CreateBacklogEventResult(
    $input: CreateBacklogEventResultInput!
    $condition: ModelBacklogEventResultConditionInput
  ) {
    createBacklogEventResult(input: $input, condition: $condition) {
      id
      organizationId
      organizationMemberId
      organizationResultId
      eventId
      eventName
      eventStartDate
      eventEndDate
      eventDivisionId
      eventDivisionName
      organizationDivisionId
      organizationDivision {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      eventClassId
      eventClassName
      eventClassNumber
      organizationClassId
      organizationClass {
        id
        name
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationId
        organizationDivisionId
        hasChampionship
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryFee
        prizeMoney
        minNumberEntries
        maxNumberEntries
        jumpHeight
        estimatedTimePerRound
        createdBy
        displayOrder
        lastUpdatedBy
        createdOn
        updatedOn
        organizationClassPointTableId
        organizationClassPrizeMoneyTableId
      }
      riderName
      horseName
      place
      pointsEarned
      score
      time
      didNotCompete
      prizeMoney
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      backlogEventResultPrizeMoneyTableId
    }
  }
`;
export const updateBacklogEventResult = /* GraphQL */ `
  mutation UpdateBacklogEventResult(
    $input: UpdateBacklogEventResultInput!
    $condition: ModelBacklogEventResultConditionInput
  ) {
    updateBacklogEventResult(input: $input, condition: $condition) {
      id
      organizationId
      organizationMemberId
      organizationResultId
      eventId
      eventName
      eventStartDate
      eventEndDate
      eventDivisionId
      eventDivisionName
      organizationDivisionId
      organizationDivision {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      eventClassId
      eventClassName
      eventClassNumber
      organizationClassId
      organizationClass {
        id
        name
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationId
        organizationDivisionId
        hasChampionship
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryFee
        prizeMoney
        minNumberEntries
        maxNumberEntries
        jumpHeight
        estimatedTimePerRound
        createdBy
        displayOrder
        lastUpdatedBy
        createdOn
        updatedOn
        organizationClassPointTableId
        organizationClassPrizeMoneyTableId
      }
      riderName
      horseName
      place
      pointsEarned
      score
      time
      didNotCompete
      prizeMoney
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      backlogEventResultPrizeMoneyTableId
    }
  }
`;
export const deleteBacklogEventResult = /* GraphQL */ `
  mutation DeleteBacklogEventResult(
    $input: DeleteBacklogEventResultInput!
    $condition: ModelBacklogEventResultConditionInput
  ) {
    deleteBacklogEventResult(input: $input, condition: $condition) {
      id
      organizationId
      organizationMemberId
      organizationResultId
      eventId
      eventName
      eventStartDate
      eventEndDate
      eventDivisionId
      eventDivisionName
      organizationDivisionId
      organizationDivision {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      eventClassId
      eventClassName
      eventClassNumber
      organizationClassId
      organizationClass {
        id
        name
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationId
        organizationDivisionId
        hasChampionship
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryFee
        prizeMoney
        minNumberEntries
        maxNumberEntries
        jumpHeight
        estimatedTimePerRound
        createdBy
        displayOrder
        lastUpdatedBy
        createdOn
        updatedOn
        organizationClassPointTableId
        organizationClassPrizeMoneyTableId
      }
      riderName
      horseName
      place
      pointsEarned
      score
      time
      didNotCompete
      prizeMoney
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      backlogEventResultPrizeMoneyTableId
    }
  }
`;
export const createBarn = /* GraphQL */ `
  mutation CreateBarn(
    $input: CreateBarnInput!
    $condition: ModelBarnConditionInput
  ) {
    createBarn(input: $input, condition: $condition) {
      id
      name
      nickname
      description
      type
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      ownerName
      ownerPersonId
      secondaryOwnerName
      secondaryOwnerPersonId
      members {
        items {
          id
          name
          barnId
          personId
          permissionLevel
          status
          roles
          createdBy
          createdOn
          updatedOn
        }
        nextToken
      }
      location {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      media {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      lastUpdatedBy
      createdOn
      updatedOn
      barnContactId
      barnLocationId
    }
  }
`;
export const updateBarn = /* GraphQL */ `
  mutation UpdateBarn(
    $input: UpdateBarnInput!
    $condition: ModelBarnConditionInput
  ) {
    updateBarn(input: $input, condition: $condition) {
      id
      name
      nickname
      description
      type
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      ownerName
      ownerPersonId
      secondaryOwnerName
      secondaryOwnerPersonId
      members {
        items {
          id
          name
          barnId
          personId
          permissionLevel
          status
          roles
          createdBy
          createdOn
          updatedOn
        }
        nextToken
      }
      location {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      media {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      lastUpdatedBy
      createdOn
      updatedOn
      barnContactId
      barnLocationId
    }
  }
`;
export const deleteBarn = /* GraphQL */ `
  mutation DeleteBarn(
    $input: DeleteBarnInput!
    $condition: ModelBarnConditionInput
  ) {
    deleteBarn(input: $input, condition: $condition) {
      id
      name
      nickname
      description
      type
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      ownerName
      ownerPersonId
      secondaryOwnerName
      secondaryOwnerPersonId
      members {
        items {
          id
          name
          barnId
          personId
          permissionLevel
          status
          roles
          createdBy
          createdOn
          updatedOn
        }
        nextToken
      }
      location {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      media {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      lastUpdatedBy
      createdOn
      updatedOn
      barnContactId
      barnLocationId
    }
  }
`;
export const createBarnMember = /* GraphQL */ `
  mutation CreateBarnMember(
    $input: CreateBarnMemberInput!
    $condition: ModelBarnMemberConditionInput
  ) {
    createBarnMember(input: $input, condition: $condition) {
      id
      name
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      permissionLevel
      status
      roles
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const updateBarnMember = /* GraphQL */ `
  mutation UpdateBarnMember(
    $input: UpdateBarnMemberInput!
    $condition: ModelBarnMemberConditionInput
  ) {
    updateBarnMember(input: $input, condition: $condition) {
      id
      name
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      permissionLevel
      status
      roles
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const deleteBarnMember = /* GraphQL */ `
  mutation DeleteBarnMember(
    $input: DeleteBarnMemberInput!
    $condition: ModelBarnMemberConditionInput
  ) {
    deleteBarnMember(input: $input, condition: $condition) {
      id
      name
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      permissionLevel
      status
      roles
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const createBlock = /* GraphQL */ `
  mutation CreateBlock(
    $input: CreateBlockInput!
    $condition: ModelBlockConditionInput
  ) {
    createBlock(input: $input, condition: $condition) {
      id
      name
      type
      personid
      organizationId
      purchasedOn
      expiresOn
      totalCredits
      usedCredits
      status
      price
      amountPaid
      isPromotion
      isFree
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const updateBlock = /* GraphQL */ `
  mutation UpdateBlock(
    $input: UpdateBlockInput!
    $condition: ModelBlockConditionInput
  ) {
    updateBlock(input: $input, condition: $condition) {
      id
      name
      type
      personid
      organizationId
      purchasedOn
      expiresOn
      totalCredits
      usedCredits
      status
      price
      amountPaid
      isPromotion
      isFree
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const deleteBlock = /* GraphQL */ `
  mutation DeleteBlock(
    $input: DeleteBlockInput!
    $condition: ModelBlockConditionInput
  ) {
    deleteBlock(input: $input, condition: $condition) {
      id
      name
      type
      personid
      organizationId
      purchasedOn
      expiresOn
      totalCredits
      usedCredits
      status
      price
      amountPaid
      isPromotion
      isFree
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const createClass = /* GraphQL */ `
  mutation CreateClass(
    $input: CreateClassInput!
    $condition: ModelClassConditionInput
  ) {
    createClass(input: $input, condition: $condition) {
      id
      name
      nickname
      divisionId
      type
      description
      discipline
      ushjaName
      usefNumber
      ecNumber
      ecClassTypeNumber
      ecClassType
      code
      specs
      judgedOn
      rules {
        horseSex
        horseBreed
        horseMinHeight
        horseMaxHeight
        horseMinAge
        horseMaxAge
        riderMinAge
        riderMaxAge
        riderProfessionalStatus
        riderRecord
        horseRecord
        crossEntry
        other
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const updateClass = /* GraphQL */ `
  mutation UpdateClass(
    $input: UpdateClassInput!
    $condition: ModelClassConditionInput
  ) {
    updateClass(input: $input, condition: $condition) {
      id
      name
      nickname
      divisionId
      type
      description
      discipline
      ushjaName
      usefNumber
      ecNumber
      ecClassTypeNumber
      ecClassType
      code
      specs
      judgedOn
      rules {
        horseSex
        horseBreed
        horseMinHeight
        horseMaxHeight
        horseMinAge
        horseMaxAge
        riderMinAge
        riderMaxAge
        riderProfessionalStatus
        riderRecord
        horseRecord
        crossEntry
        other
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const deleteClass = /* GraphQL */ `
  mutation DeleteClass(
    $input: DeleteClassInput!
    $condition: ModelClassConditionInput
  ) {
    deleteClass(input: $input, condition: $condition) {
      id
      name
      nickname
      divisionId
      type
      description
      discipline
      ushjaName
      usefNumber
      ecNumber
      ecClassTypeNumber
      ecClassType
      code
      specs
      judgedOn
      rules {
        horseSex
        horseBreed
        horseMinHeight
        horseMaxHeight
        horseMinAge
        horseMaxAge
        riderMinAge
        riderMaxAge
        riderProfessionalStatus
        riderRecord
        horseRecord
        crossEntry
        other
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const createClassOrder = /* GraphQL */ `
  mutation CreateClassOrder(
    $input: CreateClassOrderInput!
    $condition: ModelClassOrderConditionInput
  ) {
    createClassOrder(input: $input, condition: $condition) {
      id
      eventId
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      entryId
      eventEntry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      order
      isRandom
      createdOn
      updatedOn
    }
  }
`;
export const updateClassOrder = /* GraphQL */ `
  mutation UpdateClassOrder(
    $input: UpdateClassOrderInput!
    $condition: ModelClassOrderConditionInput
  ) {
    updateClassOrder(input: $input, condition: $condition) {
      id
      eventId
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      entryId
      eventEntry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      order
      isRandom
      createdOn
      updatedOn
    }
  }
`;
export const deleteClassOrder = /* GraphQL */ `
  mutation DeleteClassOrder(
    $input: DeleteClassOrderInput!
    $condition: ModelClassOrderConditionInput
  ) {
    deleteClassOrder(input: $input, condition: $condition) {
      id
      eventId
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      entryId
      eventEntry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      order
      isRandom
      createdOn
      updatedOn
    }
  }
`;
export const createClinician = /* GraphQL */ `
  mutation CreateClinician(
    $input: CreateClinicianInput!
    $condition: ModelClinicianConditionInput
  ) {
    createClinician(input: $input, condition: $condition) {
      id
      name
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      judgeId
      contactId
      rating {
        type
        license
      }
      usefNumber
      ecNumber
      price
      status
      paymentChoice {
        id
        personId
        organizationId
        type
        stripeId
        altId
        value
        createdOn
        updatedOn
      }
      paymentVerified
      stripeAccountId
      totalEarned
      location
      disciplines
      experience
      personal
      preferences
      hasIHSAExperience
      hasIEAExperience
      createdOn
      updatedOn
      clinicianPaymentChoiceId
    }
  }
`;
export const updateClinician = /* GraphQL */ `
  mutation UpdateClinician(
    $input: UpdateClinicianInput!
    $condition: ModelClinicianConditionInput
  ) {
    updateClinician(input: $input, condition: $condition) {
      id
      name
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      judgeId
      contactId
      rating {
        type
        license
      }
      usefNumber
      ecNumber
      price
      status
      paymentChoice {
        id
        personId
        organizationId
        type
        stripeId
        altId
        value
        createdOn
        updatedOn
      }
      paymentVerified
      stripeAccountId
      totalEarned
      location
      disciplines
      experience
      personal
      preferences
      hasIHSAExperience
      hasIEAExperience
      createdOn
      updatedOn
      clinicianPaymentChoiceId
    }
  }
`;
export const deleteClinician = /* GraphQL */ `
  mutation DeleteClinician(
    $input: DeleteClinicianInput!
    $condition: ModelClinicianConditionInput
  ) {
    deleteClinician(input: $input, condition: $condition) {
      id
      name
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      judgeId
      contactId
      rating {
        type
        license
      }
      usefNumber
      ecNumber
      price
      status
      paymentChoice {
        id
        personId
        organizationId
        type
        stripeId
        altId
        value
        createdOn
        updatedOn
      }
      paymentVerified
      stripeAccountId
      totalEarned
      location
      disciplines
      experience
      personal
      preferences
      hasIHSAExperience
      hasIEAExperience
      createdOn
      updatedOn
      clinicianPaymentChoiceId
    }
  }
`;
export const createCompetition = /* GraphQL */ `
  mutation CreateCompetition(
    $input: CreateCompetitionInput!
    $condition: ModelCompetitionConditionInput
  ) {
    createCompetition(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      organizationId
      teamId
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      competitionOrganizerName
      competitionOrganizerContactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      judgeName
      secretaryName
      StewardName
      isOpenOffered
      isIntermediateOffered
      isNoviceOffered
      isAdvancedBeginnerOffered
      isBeginnerOffered
      isMinisOffered
      isAdultOffered
      isAELMedalOffered
      entryFeePerRider
      entryFeePerRiderForMedal
      feePaymentOptions
      entryClosingDate
      entryClosingTime
      isEntryLimitInPlace
      entryLimitDescription
      otherNotes
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const updateCompetition = /* GraphQL */ `
  mutation UpdateCompetition(
    $input: UpdateCompetitionInput!
    $condition: ModelCompetitionConditionInput
  ) {
    updateCompetition(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      organizationId
      teamId
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      competitionOrganizerName
      competitionOrganizerContactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      judgeName
      secretaryName
      StewardName
      isOpenOffered
      isIntermediateOffered
      isNoviceOffered
      isAdvancedBeginnerOffered
      isBeginnerOffered
      isMinisOffered
      isAdultOffered
      isAELMedalOffered
      entryFeePerRider
      entryFeePerRiderForMedal
      feePaymentOptions
      entryClosingDate
      entryClosingTime
      isEntryLimitInPlace
      entryLimitDescription
      otherNotes
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const deleteCompetition = /* GraphQL */ `
  mutation DeleteCompetition(
    $input: DeleteCompetitionInput!
    $condition: ModelCompetitionConditionInput
  ) {
    deleteCompetition(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      organizationId
      teamId
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      competitionOrganizerName
      competitionOrganizerContactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      judgeName
      secretaryName
      StewardName
      isOpenOffered
      isIntermediateOffered
      isNoviceOffered
      isAdvancedBeginnerOffered
      isBeginnerOffered
      isMinisOffered
      isAdultOffered
      isAELMedalOffered
      entryFeePerRider
      entryFeePerRiderForMedal
      feePaymentOptions
      entryClosingDate
      entryClosingTime
      isEntryLimitInPlace
      entryLimitDescription
      otherNotes
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      name
      cell
      home
      work
      fax
      personalEmail
      personalEmailIsVerified
      workEmail
      workEmailIsVerified
      mailingAddress
      website
      facebookPage
      instagramHandle
      twitterHandle
      tiktokHandle
      emergencyContactName
      emergencyContactPhone
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      name
      cell
      home
      work
      fax
      personalEmail
      personalEmailIsVerified
      workEmail
      workEmailIsVerified
      mailingAddress
      website
      facebookPage
      instagramHandle
      twitterHandle
      tiktokHandle
      emergencyContactName
      emergencyContactPhone
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      name
      cell
      home
      work
      fax
      personalEmail
      personalEmailIsVerified
      workEmail
      workEmailIsVerified
      mailingAddress
      website
      facebookPage
      instagramHandle
      twitterHandle
      tiktokHandle
      emergencyContactName
      emergencyContactPhone
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const createDivision = /* GraphQL */ `
  mutation CreateDivision(
    $input: CreateDivisionInput!
    $condition: ModelDivisionConditionInput
  ) {
    createDivision(input: $input, condition: $condition) {
      id
      name
      nickname
      description
      discipline
      isSearchable
      ushjaName
      usefNumber
      ecNumber
      ecClassTypeNumber
      ecClassType
      code
      specs
      judgedOn
      rules {
        horseSex
        horseBreed
        horseMinHeight
        horseMaxHeight
        horseMinAge
        horseMaxAge
        riderMinAge
        riderMaxAge
        riderProfessionalStatus
        riderRecord
        horseRecord
        crossEntry
        other
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const updateDivision = /* GraphQL */ `
  mutation UpdateDivision(
    $input: UpdateDivisionInput!
    $condition: ModelDivisionConditionInput
  ) {
    updateDivision(input: $input, condition: $condition) {
      id
      name
      nickname
      description
      discipline
      isSearchable
      ushjaName
      usefNumber
      ecNumber
      ecClassTypeNumber
      ecClassType
      code
      specs
      judgedOn
      rules {
        horseSex
        horseBreed
        horseMinHeight
        horseMaxHeight
        horseMinAge
        horseMaxAge
        riderMinAge
        riderMaxAge
        riderProfessionalStatus
        riderRecord
        horseRecord
        crossEntry
        other
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const deleteDivision = /* GraphQL */ `
  mutation DeleteDivision(
    $input: DeleteDivisionInput!
    $condition: ModelDivisionConditionInput
  ) {
    deleteDivision(input: $input, condition: $condition) {
      id
      name
      nickname
      description
      discipline
      isSearchable
      ushjaName
      usefNumber
      ecNumber
      ecClassTypeNumber
      ecClassType
      code
      specs
      judgedOn
      rules {
        horseSex
        horseBreed
        horseMinHeight
        horseMaxHeight
        horseMinAge
        horseMaxAge
        riderMinAge
        riderMaxAge
        riderProfessionalStatus
        riderRecord
        horseRecord
        crossEntry
        other
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      name
      nickname
      createdBy
      manager
      startDate
      endDate
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      contactId
      contactIds
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      type
      disciplines
      eventOptions {
        autoAcceptNewEntries
        usesTextAlerts
        acceptsIncomingTextAlerts
        hasUnlimitedCredits
        usesEventManagement
        usesStablingManagement
        usesVendorManagement
        usesMediaTools
        usesMarketingTools
        usesFinanceTools
        usesJudgeApp
      }
      paymentOptions {
        acceptsChecks
        acceptsCash
        acceptsACH
        acceptsCC
      }
      invoiceOptions {
        classFeeTax
        sendEmails
      }
      entryOptions {
        displayHorse2Input
        displayRider2Input
        displayRider3Input
        requiresCogginsDocument
        requiresCogginsData
        maximumNumberClassesOnEntry
      }
      officials {
        items {
          id
          name
          position
          eventId
          personId
          contactId
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      entriesStatus
      showStatus
      usefID
      usefSoftwareKey
      ecID
      ecPassword
      documents {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      lastUpdatedBy
      createdOn
      updatedOn
      eventAddressId
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      name
      nickname
      createdBy
      manager
      startDate
      endDate
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      contactId
      contactIds
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      type
      disciplines
      eventOptions {
        autoAcceptNewEntries
        usesTextAlerts
        acceptsIncomingTextAlerts
        hasUnlimitedCredits
        usesEventManagement
        usesStablingManagement
        usesVendorManagement
        usesMediaTools
        usesMarketingTools
        usesFinanceTools
        usesJudgeApp
      }
      paymentOptions {
        acceptsChecks
        acceptsCash
        acceptsACH
        acceptsCC
      }
      invoiceOptions {
        classFeeTax
        sendEmails
      }
      entryOptions {
        displayHorse2Input
        displayRider2Input
        displayRider3Input
        requiresCogginsDocument
        requiresCogginsData
        maximumNumberClassesOnEntry
      }
      officials {
        items {
          id
          name
          position
          eventId
          personId
          contactId
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      entriesStatus
      showStatus
      usefID
      usefSoftwareKey
      ecID
      ecPassword
      documents {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      lastUpdatedBy
      createdOn
      updatedOn
      eventAddressId
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      name
      nickname
      createdBy
      manager
      startDate
      endDate
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      contactId
      contactIds
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      type
      disciplines
      eventOptions {
        autoAcceptNewEntries
        usesTextAlerts
        acceptsIncomingTextAlerts
        hasUnlimitedCredits
        usesEventManagement
        usesStablingManagement
        usesVendorManagement
        usesMediaTools
        usesMarketingTools
        usesFinanceTools
        usesJudgeApp
      }
      paymentOptions {
        acceptsChecks
        acceptsCash
        acceptsACH
        acceptsCC
      }
      invoiceOptions {
        classFeeTax
        sendEmails
      }
      entryOptions {
        displayHorse2Input
        displayRider2Input
        displayRider3Input
        requiresCogginsDocument
        requiresCogginsData
        maximumNumberClassesOnEntry
      }
      officials {
        items {
          id
          name
          position
          eventId
          personId
          contactId
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      entriesStatus
      showStatus
      usefID
      usefSoftwareKey
      ecID
      ecPassword
      documents {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      lastUpdatedBy
      createdOn
      updatedOn
      eventAddressId
    }
  }
`;
export const createEventAdd = /* GraphQL */ `
  mutation CreateEventAdd(
    $input: CreateEventAddInput!
    $condition: ModelEventAddConditionInput
  ) {
    createEventAdd(input: $input, condition: $condition) {
      id
      eventId
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      eventDivisionName
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventClassName
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const updateEventAdd = /* GraphQL */ `
  mutation UpdateEventAdd(
    $input: UpdateEventAddInput!
    $condition: ModelEventAddConditionInput
  ) {
    updateEventAdd(input: $input, condition: $condition) {
      id
      eventId
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      eventDivisionName
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventClassName
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const deleteEventAdd = /* GraphQL */ `
  mutation DeleteEventAdd(
    $input: DeleteEventAddInput!
    $condition: ModelEventAddConditionInput
  ) {
    deleteEventAdd(input: $input, condition: $condition) {
      id
      eventId
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      eventDivisionName
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventClassName
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const createEventAlertSetting = /* GraphQL */ `
  mutation CreateEventAlertSetting(
    $input: CreateEventAlertSettingInput!
    $condition: ModelEventAlertSettingConditionInput
  ) {
    createEventAlertSetting(input: $input, condition: $condition) {
      id
      eventId
      categories
      useDescription
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const updateEventAlertSetting = /* GraphQL */ `
  mutation UpdateEventAlertSetting(
    $input: UpdateEventAlertSettingInput!
    $condition: ModelEventAlertSettingConditionInput
  ) {
    updateEventAlertSetting(input: $input, condition: $condition) {
      id
      eventId
      categories
      useDescription
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const deleteEventAlertSetting = /* GraphQL */ `
  mutation DeleteEventAlertSetting(
    $input: DeleteEventAlertSettingInput!
    $condition: ModelEventAlertSettingConditionInput
  ) {
    deleteEventAlertSetting(input: $input, condition: $condition) {
      id
      eventId
      categories
      useDescription
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const createEventAuditor = /* GraphQL */ `
  mutation CreateEventAuditor(
    $input: CreateEventAuditorInput!
    $condition: ModelEventAuditorConditionInput
  ) {
    createEventAuditor(input: $input, condition: $condition) {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      auditorId
      auditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        name
        phoneNumber
        email
        status
        statusNote
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      filters {
        items {
          id
          personId
          eventId
          eventAuditorId
          organizationId
          organizationAuditorId
          filterId
          options
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventAuditorFilterOrganizationId
        }
        nextToken
      }
      name
      phoneNumber
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventAuditorEventId
    }
  }
`;
export const updateEventAuditor = /* GraphQL */ `
  mutation UpdateEventAuditor(
    $input: UpdateEventAuditorInput!
    $condition: ModelEventAuditorConditionInput
  ) {
    updateEventAuditor(input: $input, condition: $condition) {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      auditorId
      auditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        name
        phoneNumber
        email
        status
        statusNote
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      filters {
        items {
          id
          personId
          eventId
          eventAuditorId
          organizationId
          organizationAuditorId
          filterId
          options
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventAuditorFilterOrganizationId
        }
        nextToken
      }
      name
      phoneNumber
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventAuditorEventId
    }
  }
`;
export const deleteEventAuditor = /* GraphQL */ `
  mutation DeleteEventAuditor(
    $input: DeleteEventAuditorInput!
    $condition: ModelEventAuditorConditionInput
  ) {
    deleteEventAuditor(input: $input, condition: $condition) {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      auditorId
      auditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        name
        phoneNumber
        email
        status
        statusNote
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      filters {
        items {
          id
          personId
          eventId
          eventAuditorId
          organizationId
          organizationAuditorId
          filterId
          options
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventAuditorFilterOrganizationId
        }
        nextToken
      }
      name
      phoneNumber
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventAuditorEventId
    }
  }
`;
export const createOrganizationAuditor = /* GraphQL */ `
  mutation CreateOrganizationAuditor(
    $input: CreateOrganizationAuditorInput!
    $condition: ModelOrganizationAuditorConditionInput
  ) {
    createOrganizationAuditor(input: $input, condition: $condition) {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      howSignedUp
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      auditorId
      auditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        name
        phoneNumber
        email
        status
        statusNote
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      filters {
        items {
          id
          personId
          eventId
          eventAuditorId
          organizationId
          organizationAuditorId
          filterId
          options
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventAuditorFilterOrganizationId
        }
        nextToken
      }
      name
      phoneNumber
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      organizationAuditorPersonId
      organizationAuditorOrganizationId
    }
  }
`;
export const updateOrganizationAuditor = /* GraphQL */ `
  mutation UpdateOrganizationAuditor(
    $input: UpdateOrganizationAuditorInput!
    $condition: ModelOrganizationAuditorConditionInput
  ) {
    updateOrganizationAuditor(input: $input, condition: $condition) {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      howSignedUp
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      auditorId
      auditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        name
        phoneNumber
        email
        status
        statusNote
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      filters {
        items {
          id
          personId
          eventId
          eventAuditorId
          organizationId
          organizationAuditorId
          filterId
          options
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventAuditorFilterOrganizationId
        }
        nextToken
      }
      name
      phoneNumber
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      organizationAuditorPersonId
      organizationAuditorOrganizationId
    }
  }
`;
export const deleteOrganizationAuditor = /* GraphQL */ `
  mutation DeleteOrganizationAuditor(
    $input: DeleteOrganizationAuditorInput!
    $condition: ModelOrganizationAuditorConditionInput
  ) {
    deleteOrganizationAuditor(input: $input, condition: $condition) {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      howSignedUp
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      auditorId
      auditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        name
        phoneNumber
        email
        status
        statusNote
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      filters {
        items {
          id
          personId
          eventId
          eventAuditorId
          organizationId
          organizationAuditorId
          filterId
          options
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventAuditorFilterOrganizationId
        }
        nextToken
      }
      name
      phoneNumber
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      organizationAuditorPersonId
      organizationAuditorOrganizationId
    }
  }
`;
export const createEventAuditorFilter = /* GraphQL */ `
  mutation CreateEventAuditorFilter(
    $input: CreateEventAuditorFilterInput!
    $condition: ModelEventAuditorFilterConditionInput
  ) {
    createEventAuditorFilter(input: $input, condition: $condition) {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      eventAuditorId
      eventAuditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventAuditorEventId
      }
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      organizationAuditorId
      organizationAuditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        howSignedUp
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        organizationAuditorPersonId
        organizationAuditorOrganizationId
      }
      filterId
      filter {
        id
        eventId
        organizationID
        name
        options
        isRequired
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      options
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventAuditorFilterOrganizationId
    }
  }
`;
export const updateEventAuditorFilter = /* GraphQL */ `
  mutation UpdateEventAuditorFilter(
    $input: UpdateEventAuditorFilterInput!
    $condition: ModelEventAuditorFilterConditionInput
  ) {
    updateEventAuditorFilter(input: $input, condition: $condition) {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      eventAuditorId
      eventAuditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventAuditorEventId
      }
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      organizationAuditorId
      organizationAuditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        howSignedUp
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        organizationAuditorPersonId
        organizationAuditorOrganizationId
      }
      filterId
      filter {
        id
        eventId
        organizationID
        name
        options
        isRequired
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      options
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventAuditorFilterOrganizationId
    }
  }
`;
export const deleteEventAuditorFilter = /* GraphQL */ `
  mutation DeleteEventAuditorFilter(
    $input: DeleteEventAuditorFilterInput!
    $condition: ModelEventAuditorFilterConditionInput
  ) {
    deleteEventAuditorFilter(input: $input, condition: $condition) {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      eventAuditorId
      eventAuditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventAuditorEventId
      }
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      organizationAuditorId
      organizationAuditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        howSignedUp
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        organizationAuditorPersonId
        organizationAuditorOrganizationId
      }
      filterId
      filter {
        id
        eventId
        organizationID
        name
        options
        isRequired
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      options
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventAuditorFilterOrganizationId
    }
  }
`;
export const createEventBeddingType = /* GraphQL */ `
  mutation CreateEventBeddingType(
    $input: CreateEventBeddingTypeInput!
    $condition: ModelEventBeddingTypeConditionInput
  ) {
    createEventBeddingType(input: $input, condition: $condition) {
      id
      eventId
      organizationId
      organizationBeddingTypeId
      name
      description
      type
      pricePerBag
      taxPerBag
      createdOn
      updatedOn
    }
  }
`;
export const updateEventBeddingType = /* GraphQL */ `
  mutation UpdateEventBeddingType(
    $input: UpdateEventBeddingTypeInput!
    $condition: ModelEventBeddingTypeConditionInput
  ) {
    updateEventBeddingType(input: $input, condition: $condition) {
      id
      eventId
      organizationId
      organizationBeddingTypeId
      name
      description
      type
      pricePerBag
      taxPerBag
      createdOn
      updatedOn
    }
  }
`;
export const deleteEventBeddingType = /* GraphQL */ `
  mutation DeleteEventBeddingType(
    $input: DeleteEventBeddingTypeInput!
    $condition: ModelEventBeddingTypeConditionInput
  ) {
    deleteEventBeddingType(input: $input, condition: $condition) {
      id
      eventId
      organizationId
      organizationBeddingTypeId
      name
      description
      type
      pricePerBag
      taxPerBag
      createdOn
      updatedOn
    }
  }
`;
export const createEventBreak = /* GraphQL */ `
  mutation CreateEventBreak(
    $input: CreateEventBreakInput!
    $condition: ModelEventBreakConditionInput
  ) {
    createEventBreak(input: $input, condition: $condition) {
      id
      eventId
      name
      time
      isDisplayedOnSchedule
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const updateEventBreak = /* GraphQL */ `
  mutation UpdateEventBreak(
    $input: UpdateEventBreakInput!
    $condition: ModelEventBreakConditionInput
  ) {
    updateEventBreak(input: $input, condition: $condition) {
      id
      eventId
      name
      time
      isDisplayedOnSchedule
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const deleteEventBreak = /* GraphQL */ `
  mutation DeleteEventBreak(
    $input: DeleteEventBreakInput!
    $condition: ModelEventBreakConditionInput
  ) {
    deleteEventBreak(input: $input, condition: $condition) {
      id
      eventId
      name
      time
      isDisplayedOnSchedule
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const createEventClass = /* GraphQL */ `
  mutation CreateEventClass(
    $input: CreateEventClassInput!
    $condition: ModelEventClassConditionInput
  ) {
    createEventClass(input: $input, condition: $condition) {
      id
      name
      eventId
      classId
      class {
        id
        name
        nickname
        divisionId
        type
        description
        discipline
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      organizationClassId
      organizationClass {
        id
        name
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationId
        organizationDivisionId
        hasChampionship
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryFee
        prizeMoney
        minNumberEntries
        maxNumberEntries
        jumpHeight
        estimatedTimePerRound
        createdBy
        displayOrder
        lastUpdatedBy
        createdOn
        updatedOn
        organizationClassPointTableId
        organizationClassPrizeMoneyTableId
      }
      organizationDivisionId
      organizationDivision {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      eventResults {
        items {
          id
          eventId
          entryId
          eventClassEntryId
          eventDivisionId
          eventDivisionName
          eventClassId
          eventClassName
          resultSet
          place
          score
          time
          didNotCompete
          prizeMoney
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultEntryId
          eventResultEventClassEntryId
          eventResultPrizeMoneyTableId
        }
        nextToken
      }
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      type
      number
      entryOption
      entryFee
      fees {
        items {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        nextToken
      }
      prizeMoney
      entries {
        items {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      completedVerifyEntries
      currentNumberEntries
      numberEntriesThatCompeted
      minNumberEntries
      maxNumberEntries
      estimatedTimePerRound
      isCaliforniaSplit
      isCombined
      combinedEventClassId
      combinedEventDivisionId
      jumpHeight
      isScheduled
      note
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventClassPointTableId
      eventClassPrizeMoneyTableId
    }
  }
`;
export const updateEventClass = /* GraphQL */ `
  mutation UpdateEventClass(
    $input: UpdateEventClassInput!
    $condition: ModelEventClassConditionInput
  ) {
    updateEventClass(input: $input, condition: $condition) {
      id
      name
      eventId
      classId
      class {
        id
        name
        nickname
        divisionId
        type
        description
        discipline
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      organizationClassId
      organizationClass {
        id
        name
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationId
        organizationDivisionId
        hasChampionship
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryFee
        prizeMoney
        minNumberEntries
        maxNumberEntries
        jumpHeight
        estimatedTimePerRound
        createdBy
        displayOrder
        lastUpdatedBy
        createdOn
        updatedOn
        organizationClassPointTableId
        organizationClassPrizeMoneyTableId
      }
      organizationDivisionId
      organizationDivision {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      eventResults {
        items {
          id
          eventId
          entryId
          eventClassEntryId
          eventDivisionId
          eventDivisionName
          eventClassId
          eventClassName
          resultSet
          place
          score
          time
          didNotCompete
          prizeMoney
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultEntryId
          eventResultEventClassEntryId
          eventResultPrizeMoneyTableId
        }
        nextToken
      }
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      type
      number
      entryOption
      entryFee
      fees {
        items {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        nextToken
      }
      prizeMoney
      entries {
        items {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      completedVerifyEntries
      currentNumberEntries
      numberEntriesThatCompeted
      minNumberEntries
      maxNumberEntries
      estimatedTimePerRound
      isCaliforniaSplit
      isCombined
      combinedEventClassId
      combinedEventDivisionId
      jumpHeight
      isScheduled
      note
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventClassPointTableId
      eventClassPrizeMoneyTableId
    }
  }
`;
export const deleteEventClass = /* GraphQL */ `
  mutation DeleteEventClass(
    $input: DeleteEventClassInput!
    $condition: ModelEventClassConditionInput
  ) {
    deleteEventClass(input: $input, condition: $condition) {
      id
      name
      eventId
      classId
      class {
        id
        name
        nickname
        divisionId
        type
        description
        discipline
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      organizationClassId
      organizationClass {
        id
        name
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationId
        organizationDivisionId
        hasChampionship
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryFee
        prizeMoney
        minNumberEntries
        maxNumberEntries
        jumpHeight
        estimatedTimePerRound
        createdBy
        displayOrder
        lastUpdatedBy
        createdOn
        updatedOn
        organizationClassPointTableId
        organizationClassPrizeMoneyTableId
      }
      organizationDivisionId
      organizationDivision {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      eventResults {
        items {
          id
          eventId
          entryId
          eventClassEntryId
          eventDivisionId
          eventDivisionName
          eventClassId
          eventClassName
          resultSet
          place
          score
          time
          didNotCompete
          prizeMoney
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultEntryId
          eventResultEventClassEntryId
          eventResultPrizeMoneyTableId
        }
        nextToken
      }
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      type
      number
      entryOption
      entryFee
      fees {
        items {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        nextToken
      }
      prizeMoney
      entries {
        items {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      completedVerifyEntries
      currentNumberEntries
      numberEntriesThatCompeted
      minNumberEntries
      maxNumberEntries
      estimatedTimePerRound
      isCaliforniaSplit
      isCombined
      combinedEventClassId
      combinedEventDivisionId
      jumpHeight
      isScheduled
      note
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventClassPointTableId
      eventClassPrizeMoneyTableId
    }
  }
`;
export const createEventClassEntry = /* GraphQL */ `
  mutation CreateEventClassEntry(
    $input: CreateEventClassEntryInput!
    $condition: ModelEventClassEntryConditionInput
  ) {
    createEventClassEntry(input: $input, condition: $condition) {
      id
      eventId
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventEntryId
      eventEntry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      riderId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      status
      selectedEntryOption
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const updateEventClassEntry = /* GraphQL */ `
  mutation UpdateEventClassEntry(
    $input: UpdateEventClassEntryInput!
    $condition: ModelEventClassEntryConditionInput
  ) {
    updateEventClassEntry(input: $input, condition: $condition) {
      id
      eventId
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventEntryId
      eventEntry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      riderId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      status
      selectedEntryOption
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const deleteEventClassEntry = /* GraphQL */ `
  mutation DeleteEventClassEntry(
    $input: DeleteEventClassEntryInput!
    $condition: ModelEventClassEntryConditionInput
  ) {
    deleteEventClassEntry(input: $input, condition: $condition) {
      id
      eventId
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventEntryId
      eventEntry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      riderId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      status
      selectedEntryOption
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const createEventDay = /* GraphQL */ `
  mutation CreateEventDay(
    $input: CreateEventDayInput!
    $condition: ModelEventDayConditionInput
  ) {
    createEventDay(input: $input, condition: $condition) {
      id
      eventId
      awsdate
      dayofweek
      date
      month
      year
      createdOn
      updatedOn
    }
  }
`;
export const updateEventDay = /* GraphQL */ `
  mutation UpdateEventDay(
    $input: UpdateEventDayInput!
    $condition: ModelEventDayConditionInput
  ) {
    updateEventDay(input: $input, condition: $condition) {
      id
      eventId
      awsdate
      dayofweek
      date
      month
      year
      createdOn
      updatedOn
    }
  }
`;
export const deleteEventDay = /* GraphQL */ `
  mutation DeleteEventDay(
    $input: DeleteEventDayInput!
    $condition: ModelEventDayConditionInput
  ) {
    deleteEventDay(input: $input, condition: $condition) {
      id
      eventId
      awsdate
      dayofweek
      date
      month
      year
      createdOn
      updatedOn
    }
  }
`;
export const createEventDivision = /* GraphQL */ `
  mutation CreateEventDivision(
    $input: CreateEventDivisionInput!
    $condition: ModelEventDivisionConditionInput
  ) {
    createEventDivision(input: $input, condition: $condition) {
      id
      eventId
      name
      number
      divisionId
      division {
        id
        name
        nickname
        description
        discipline
        isSearchable
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      organizationDivisionId
      organizationDivision {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      eventClasses {
        items {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        nextToken
      }
      eventResults {
        items {
          id
          eventId
          entryId
          eventClassEntryId
          eventDivisionId
          eventDivisionName
          eventClassId
          eventClassName
          resultSet
          place
          score
          time
          didNotCompete
          prizeMoney
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultEntryId
          eventResultEventClassEntryId
          eventResultPrizeMoneyTableId
        }
        nextToken
      }
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      entryFee
      prizeMoney
      jumpHeight
      minNumberEntriesPerClass
      maxNumberEntriesPerClass
      isCaliforniaSplit
      isCombined
      combinedEventDivisionId
      combinedEventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventDivisionOrganizationDivisionId
      eventDivisionPointTableId
      eventDivisionPrizeMoneyTableId
    }
  }
`;
export const updateEventDivision = /* GraphQL */ `
  mutation UpdateEventDivision(
    $input: UpdateEventDivisionInput!
    $condition: ModelEventDivisionConditionInput
  ) {
    updateEventDivision(input: $input, condition: $condition) {
      id
      eventId
      name
      number
      divisionId
      division {
        id
        name
        nickname
        description
        discipline
        isSearchable
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      organizationDivisionId
      organizationDivision {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      eventClasses {
        items {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        nextToken
      }
      eventResults {
        items {
          id
          eventId
          entryId
          eventClassEntryId
          eventDivisionId
          eventDivisionName
          eventClassId
          eventClassName
          resultSet
          place
          score
          time
          didNotCompete
          prizeMoney
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultEntryId
          eventResultEventClassEntryId
          eventResultPrizeMoneyTableId
        }
        nextToken
      }
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      entryFee
      prizeMoney
      jumpHeight
      minNumberEntriesPerClass
      maxNumberEntriesPerClass
      isCaliforniaSplit
      isCombined
      combinedEventDivisionId
      combinedEventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventDivisionOrganizationDivisionId
      eventDivisionPointTableId
      eventDivisionPrizeMoneyTableId
    }
  }
`;
export const deleteEventDivision = /* GraphQL */ `
  mutation DeleteEventDivision(
    $input: DeleteEventDivisionInput!
    $condition: ModelEventDivisionConditionInput
  ) {
    deleteEventDivision(input: $input, condition: $condition) {
      id
      eventId
      name
      number
      divisionId
      division {
        id
        name
        nickname
        description
        discipline
        isSearchable
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      organizationDivisionId
      organizationDivision {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      eventClasses {
        items {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        nextToken
      }
      eventResults {
        items {
          id
          eventId
          entryId
          eventClassEntryId
          eventDivisionId
          eventDivisionName
          eventClassId
          eventClassName
          resultSet
          place
          score
          time
          didNotCompete
          prizeMoney
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultEntryId
          eventResultEventClassEntryId
          eventResultPrizeMoneyTableId
        }
        nextToken
      }
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      entryFee
      prizeMoney
      jumpHeight
      minNumberEntriesPerClass
      maxNumberEntriesPerClass
      isCaliforniaSplit
      isCombined
      combinedEventDivisionId
      combinedEventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventDivisionOrganizationDivisionId
      eventDivisionPointTableId
      eventDivisionPrizeMoneyTableId
    }
  }
`;
export const createEventEntry = /* GraphQL */ `
  mutation CreateEventEntry(
    $input: CreateEventEntryInput!
    $condition: ModelEventEntryConditionInput
  ) {
    createEventEntry(input: $input, condition: $condition) {
      id
      eventId
      status
      statusNote
      personId
      number
      isCheckedIn
      isCheckedOut
      contactId
      primarycontact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      riderName
      riderId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      secondRiderName
      secondRiderId
      secondRider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      thirdRiderName
      thirdRiderId
      thirdRider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      horseName
      horseId
      horse {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      ownerName
      ownerId
      owner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      secondOwnerName
      secondOwnerId
      secondOwner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      thirdOwnerName
      thirdOwnerId
      thirdOwner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      trainerName
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      secondTrainerName
      secondTrainerId
      secondTrainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      thirdTrainerName
      thirdTrainerId
      thirdTrainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      stablingRequestId
      stablingRequest {
        id
        eventId
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        arrivalDateTime
        departureDateTime
        stallTypeId
        stallType {
          id
          eventId
          organizationId
          organizationStallTypeId
          name
          description
          type
          pricePerStall
          taxPerStall
          createdOn
          updatedOn
        }
        quantityNeeded
        basePrice
        taxA
        taxB
        totalPrice
        status
        statusNote
        requestNote
        stallSetId
        stallSet {
          id
          eventId
          organizationId
          organizationStallSetId
          name
          location
          available
          totalAvailable
          used
          totalUsed
          createdOn
          updatedOn
        }
        stallLocation
        stallNumbers
        payerId
        payerName
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        splitAcrossEntries
        entries {
          nextToken
        }
        entryIds
        submittedEntry
        lastUpdatedBy
        createdOn
        updatedOn
      }
      beddingRequestId
      beddingRequest {
        id
        eventId
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        arrivalDateTime
        dateTimeNeeded
        deliveryInfo
        beddingType
        quantityNeeded
        basePrice
        taxA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        totalPrice
        status
        statusNote
        payerId
        payerName
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        splitAcrossEntries
        entries {
          nextToken
        }
        entryIds
        submittedEntry
        lastUpdatedBy
        createdOn
        updatedOn
        beddingRequestTaxAId
        beddingRequestTaxBId
      }
      markedDoesNotNeedStalls
      payerName
      payerId
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      prizeMoneyRecipientName
      prizeMoneyRecipientId
      prizeMoneyRecipient {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      prizeMoneyRecipientContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      prizeMoneyRecipientType
      eventRulesAgreements {
        items {
          id
          eventId
          eventRuleId
          eventEntryId
          name
          personId
          personType
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      note
      divisionList
      classList {
        items {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      fees {
        id
        name
        amount
        description
        eventId
        createdBy
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        feeId
        fee {
          id
          name
          description
          createdBy
          organizationId
          stripeId
          altId
          isFixed
          isPercentage
          totalPrice
          quantity
          isPerEntry
          isPerHorse
          isPerRider
          isPerClass
          isPerTrainer
          isRefundable
          isChargedAtTimeOfEntry
          isStartedOnEntryDate
          isHiddenFromEntryForm
          startDate
          startTime
          taxA
          taxB
          createdOn
          updatedOn
          feeTaxRateAId
          feeTaxRateBId
        }
        splitAcrossEntries
        entries {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        createdOn
        updatedOn
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventEntryPrizeMoneyRecipientContactId
    }
  }
`;
export const updateEventEntry = /* GraphQL */ `
  mutation UpdateEventEntry(
    $input: UpdateEventEntryInput!
    $condition: ModelEventEntryConditionInput
  ) {
    updateEventEntry(input: $input, condition: $condition) {
      id
      eventId
      status
      statusNote
      personId
      number
      isCheckedIn
      isCheckedOut
      contactId
      primarycontact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      riderName
      riderId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      secondRiderName
      secondRiderId
      secondRider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      thirdRiderName
      thirdRiderId
      thirdRider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      horseName
      horseId
      horse {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      ownerName
      ownerId
      owner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      secondOwnerName
      secondOwnerId
      secondOwner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      thirdOwnerName
      thirdOwnerId
      thirdOwner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      trainerName
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      secondTrainerName
      secondTrainerId
      secondTrainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      thirdTrainerName
      thirdTrainerId
      thirdTrainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      stablingRequestId
      stablingRequest {
        id
        eventId
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        arrivalDateTime
        departureDateTime
        stallTypeId
        stallType {
          id
          eventId
          organizationId
          organizationStallTypeId
          name
          description
          type
          pricePerStall
          taxPerStall
          createdOn
          updatedOn
        }
        quantityNeeded
        basePrice
        taxA
        taxB
        totalPrice
        status
        statusNote
        requestNote
        stallSetId
        stallSet {
          id
          eventId
          organizationId
          organizationStallSetId
          name
          location
          available
          totalAvailable
          used
          totalUsed
          createdOn
          updatedOn
        }
        stallLocation
        stallNumbers
        payerId
        payerName
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        splitAcrossEntries
        entries {
          nextToken
        }
        entryIds
        submittedEntry
        lastUpdatedBy
        createdOn
        updatedOn
      }
      beddingRequestId
      beddingRequest {
        id
        eventId
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        arrivalDateTime
        dateTimeNeeded
        deliveryInfo
        beddingType
        quantityNeeded
        basePrice
        taxA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        totalPrice
        status
        statusNote
        payerId
        payerName
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        splitAcrossEntries
        entries {
          nextToken
        }
        entryIds
        submittedEntry
        lastUpdatedBy
        createdOn
        updatedOn
        beddingRequestTaxAId
        beddingRequestTaxBId
      }
      markedDoesNotNeedStalls
      payerName
      payerId
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      prizeMoneyRecipientName
      prizeMoneyRecipientId
      prizeMoneyRecipient {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      prizeMoneyRecipientContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      prizeMoneyRecipientType
      eventRulesAgreements {
        items {
          id
          eventId
          eventRuleId
          eventEntryId
          name
          personId
          personType
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      note
      divisionList
      classList {
        items {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      fees {
        id
        name
        amount
        description
        eventId
        createdBy
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        feeId
        fee {
          id
          name
          description
          createdBy
          organizationId
          stripeId
          altId
          isFixed
          isPercentage
          totalPrice
          quantity
          isPerEntry
          isPerHorse
          isPerRider
          isPerClass
          isPerTrainer
          isRefundable
          isChargedAtTimeOfEntry
          isStartedOnEntryDate
          isHiddenFromEntryForm
          startDate
          startTime
          taxA
          taxB
          createdOn
          updatedOn
          feeTaxRateAId
          feeTaxRateBId
        }
        splitAcrossEntries
        entries {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        createdOn
        updatedOn
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventEntryPrizeMoneyRecipientContactId
    }
  }
`;
export const deleteEventEntry = /* GraphQL */ `
  mutation DeleteEventEntry(
    $input: DeleteEventEntryInput!
    $condition: ModelEventEntryConditionInput
  ) {
    deleteEventEntry(input: $input, condition: $condition) {
      id
      eventId
      status
      statusNote
      personId
      number
      isCheckedIn
      isCheckedOut
      contactId
      primarycontact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      riderName
      riderId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      secondRiderName
      secondRiderId
      secondRider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      thirdRiderName
      thirdRiderId
      thirdRider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      horseName
      horseId
      horse {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      ownerName
      ownerId
      owner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      secondOwnerName
      secondOwnerId
      secondOwner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      thirdOwnerName
      thirdOwnerId
      thirdOwner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      trainerName
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      secondTrainerName
      secondTrainerId
      secondTrainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      thirdTrainerName
      thirdTrainerId
      thirdTrainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      stablingRequestId
      stablingRequest {
        id
        eventId
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        arrivalDateTime
        departureDateTime
        stallTypeId
        stallType {
          id
          eventId
          organizationId
          organizationStallTypeId
          name
          description
          type
          pricePerStall
          taxPerStall
          createdOn
          updatedOn
        }
        quantityNeeded
        basePrice
        taxA
        taxB
        totalPrice
        status
        statusNote
        requestNote
        stallSetId
        stallSet {
          id
          eventId
          organizationId
          organizationStallSetId
          name
          location
          available
          totalAvailable
          used
          totalUsed
          createdOn
          updatedOn
        }
        stallLocation
        stallNumbers
        payerId
        payerName
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        splitAcrossEntries
        entries {
          nextToken
        }
        entryIds
        submittedEntry
        lastUpdatedBy
        createdOn
        updatedOn
      }
      beddingRequestId
      beddingRequest {
        id
        eventId
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        arrivalDateTime
        dateTimeNeeded
        deliveryInfo
        beddingType
        quantityNeeded
        basePrice
        taxA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        totalPrice
        status
        statusNote
        payerId
        payerName
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        splitAcrossEntries
        entries {
          nextToken
        }
        entryIds
        submittedEntry
        lastUpdatedBy
        createdOn
        updatedOn
        beddingRequestTaxAId
        beddingRequestTaxBId
      }
      markedDoesNotNeedStalls
      payerName
      payerId
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      prizeMoneyRecipientName
      prizeMoneyRecipientId
      prizeMoneyRecipient {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      prizeMoneyRecipientContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      prizeMoneyRecipientType
      eventRulesAgreements {
        items {
          id
          eventId
          eventRuleId
          eventEntryId
          name
          personId
          personType
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      note
      divisionList
      classList {
        items {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      fees {
        id
        name
        amount
        description
        eventId
        createdBy
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        feeId
        fee {
          id
          name
          description
          createdBy
          organizationId
          stripeId
          altId
          isFixed
          isPercentage
          totalPrice
          quantity
          isPerEntry
          isPerHorse
          isPerRider
          isPerClass
          isPerTrainer
          isRefundable
          isChargedAtTimeOfEntry
          isStartedOnEntryDate
          isHiddenFromEntryForm
          startDate
          startTime
          taxA
          taxB
          createdOn
          updatedOn
          feeTaxRateAId
          feeTaxRateBId
        }
        splitAcrossEntries
        entries {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        createdOn
        updatedOn
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventEntryPrizeMoneyRecipientContactId
    }
  }
`;
export const createEventEntryFee = /* GraphQL */ `
  mutation CreateEventEntryFee(
    $input: CreateEventEntryFeeInput!
    $condition: ModelEventEntryFeeConditionInput
  ) {
    createEventEntryFee(input: $input, condition: $condition) {
      id
      name
      description
      status
      amount
      quantity
      taxA
      taxB
      eventId
      feeId
      fee {
        id
        name
        description
        createdBy
        organizationId
        stripeId
        altId
        isFixed
        isPercentage
        totalPrice
        quantity
        isPerEntry
        isPerHorse
        isPerRider
        isPerClass
        isPerTrainer
        isRefundable
        isChargedAtTimeOfEntry
        isStartedOnEntryDate
        isHiddenFromEntryForm
        startDate
        startTime
        taxA
        taxB
        taxRateA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxRateB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        feeTaxRateAId
        feeTaxRateBId
      }
      eventFeeId
      eventFee {
        id
        name
        amount
        description
        eventId
        createdBy
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        feeId
        fee {
          id
          name
          description
          createdBy
          organizationId
          stripeId
          altId
          isFixed
          isPercentage
          totalPrice
          quantity
          isPerEntry
          isPerHorse
          isPerRider
          isPerClass
          isPerTrainer
          isRefundable
          isChargedAtTimeOfEntry
          isStartedOnEntryDate
          isHiddenFromEntryForm
          startDate
          startTime
          taxA
          taxB
          createdOn
          updatedOn
          feeTaxRateAId
          feeTaxRateBId
        }
        splitAcrossEntries
        entries {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        createdOn
        updatedOn
      }
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const updateEventEntryFee = /* GraphQL */ `
  mutation UpdateEventEntryFee(
    $input: UpdateEventEntryFeeInput!
    $condition: ModelEventEntryFeeConditionInput
  ) {
    updateEventEntryFee(input: $input, condition: $condition) {
      id
      name
      description
      status
      amount
      quantity
      taxA
      taxB
      eventId
      feeId
      fee {
        id
        name
        description
        createdBy
        organizationId
        stripeId
        altId
        isFixed
        isPercentage
        totalPrice
        quantity
        isPerEntry
        isPerHorse
        isPerRider
        isPerClass
        isPerTrainer
        isRefundable
        isChargedAtTimeOfEntry
        isStartedOnEntryDate
        isHiddenFromEntryForm
        startDate
        startTime
        taxA
        taxB
        taxRateA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxRateB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        feeTaxRateAId
        feeTaxRateBId
      }
      eventFeeId
      eventFee {
        id
        name
        amount
        description
        eventId
        createdBy
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        feeId
        fee {
          id
          name
          description
          createdBy
          organizationId
          stripeId
          altId
          isFixed
          isPercentage
          totalPrice
          quantity
          isPerEntry
          isPerHorse
          isPerRider
          isPerClass
          isPerTrainer
          isRefundable
          isChargedAtTimeOfEntry
          isStartedOnEntryDate
          isHiddenFromEntryForm
          startDate
          startTime
          taxA
          taxB
          createdOn
          updatedOn
          feeTaxRateAId
          feeTaxRateBId
        }
        splitAcrossEntries
        entries {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        createdOn
        updatedOn
      }
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const deleteEventEntryFee = /* GraphQL */ `
  mutation DeleteEventEntryFee(
    $input: DeleteEventEntryFeeInput!
    $condition: ModelEventEntryFeeConditionInput
  ) {
    deleteEventEntryFee(input: $input, condition: $condition) {
      id
      name
      description
      status
      amount
      quantity
      taxA
      taxB
      eventId
      feeId
      fee {
        id
        name
        description
        createdBy
        organizationId
        stripeId
        altId
        isFixed
        isPercentage
        totalPrice
        quantity
        isPerEntry
        isPerHorse
        isPerRider
        isPerClass
        isPerTrainer
        isRefundable
        isChargedAtTimeOfEntry
        isStartedOnEntryDate
        isHiddenFromEntryForm
        startDate
        startTime
        taxA
        taxB
        taxRateA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxRateB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        feeTaxRateAId
        feeTaxRateBId
      }
      eventFeeId
      eventFee {
        id
        name
        amount
        description
        eventId
        createdBy
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        feeId
        fee {
          id
          name
          description
          createdBy
          organizationId
          stripeId
          altId
          isFixed
          isPercentage
          totalPrice
          quantity
          isPerEntry
          isPerHorse
          isPerRider
          isPerClass
          isPerTrainer
          isRefundable
          isChargedAtTimeOfEntry
          isStartedOnEntryDate
          isHiddenFromEntryForm
          startDate
          startTime
          taxA
          taxB
          createdOn
          updatedOn
          feeTaxRateAId
          feeTaxRateBId
        }
        splitAcrossEntries
        entries {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        createdOn
        updatedOn
      }
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const createEventEntryNumbers = /* GraphQL */ `
  mutation CreateEventEntryNumbers(
    $input: CreateEventEntryNumbersInput!
    $condition: ModelEventEntryNumbersConditionInput
  ) {
    createEventEntryNumbers(input: $input, condition: $condition) {
      id
      eventId
      name
      available
      totalAvailable
      used
      totalUsed
      createdOn
      updatedOn
    }
  }
`;
export const updateEventEntryNumbers = /* GraphQL */ `
  mutation UpdateEventEntryNumbers(
    $input: UpdateEventEntryNumbersInput!
    $condition: ModelEventEntryNumbersConditionInput
  ) {
    updateEventEntryNumbers(input: $input, condition: $condition) {
      id
      eventId
      name
      available
      totalAvailable
      used
      totalUsed
      createdOn
      updatedOn
    }
  }
`;
export const deleteEventEntryNumbers = /* GraphQL */ `
  mutation DeleteEventEntryNumbers(
    $input: DeleteEventEntryNumbersInput!
    $condition: ModelEventEntryNumbersConditionInput
  ) {
    deleteEventEntryNumbers(input: $input, condition: $condition) {
      id
      eventId
      name
      available
      totalAvailable
      used
      totalUsed
      createdOn
      updatedOn
    }
  }
`;
export const createEventFee = /* GraphQL */ `
  mutation CreateEventFee(
    $input: CreateEventFeeInput!
    $condition: ModelEventFeeConditionInput
  ) {
    createEventFee(input: $input, condition: $condition) {
      id
      name
      amount
      description
      eventId
      createdBy
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      feeId
      fee {
        id
        name
        description
        createdBy
        organizationId
        stripeId
        altId
        isFixed
        isPercentage
        totalPrice
        quantity
        isPerEntry
        isPerHorse
        isPerRider
        isPerClass
        isPerTrainer
        isRefundable
        isChargedAtTimeOfEntry
        isStartedOnEntryDate
        isHiddenFromEntryForm
        startDate
        startTime
        taxA
        taxB
        taxRateA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxRateB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        feeTaxRateAId
        feeTaxRateBId
      }
      splitAcrossEntries
      entries {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      createdOn
      updatedOn
    }
  }
`;
export const updateEventFee = /* GraphQL */ `
  mutation UpdateEventFee(
    $input: UpdateEventFeeInput!
    $condition: ModelEventFeeConditionInput
  ) {
    updateEventFee(input: $input, condition: $condition) {
      id
      name
      amount
      description
      eventId
      createdBy
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      feeId
      fee {
        id
        name
        description
        createdBy
        organizationId
        stripeId
        altId
        isFixed
        isPercentage
        totalPrice
        quantity
        isPerEntry
        isPerHorse
        isPerRider
        isPerClass
        isPerTrainer
        isRefundable
        isChargedAtTimeOfEntry
        isStartedOnEntryDate
        isHiddenFromEntryForm
        startDate
        startTime
        taxA
        taxB
        taxRateA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxRateB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        feeTaxRateAId
        feeTaxRateBId
      }
      splitAcrossEntries
      entries {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      createdOn
      updatedOn
    }
  }
`;
export const deleteEventFee = /* GraphQL */ `
  mutation DeleteEventFee(
    $input: DeleteEventFeeInput!
    $condition: ModelEventFeeConditionInput
  ) {
    deleteEventFee(input: $input, condition: $condition) {
      id
      name
      amount
      description
      eventId
      createdBy
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      feeId
      fee {
        id
        name
        description
        createdBy
        organizationId
        stripeId
        altId
        isFixed
        isPercentage
        totalPrice
        quantity
        isPerEntry
        isPerHorse
        isPerRider
        isPerClass
        isPerTrainer
        isRefundable
        isChargedAtTimeOfEntry
        isStartedOnEntryDate
        isHiddenFromEntryForm
        startDate
        startTime
        taxA
        taxB
        taxRateA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxRateB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        feeTaxRateAId
        feeTaxRateBId
      }
      splitAcrossEntries
      entries {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      createdOn
      updatedOn
    }
  }
`;
export const createEventFilter = /* GraphQL */ `
  mutation CreateEventFilter(
    $input: CreateEventFilterInput!
    $condition: ModelEventFilterConditionInput
  ) {
    createEventFilter(input: $input, condition: $condition) {
      id
      eventId
      organizationID
      name
      options
      isRequired
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const updateEventFilter = /* GraphQL */ `
  mutation UpdateEventFilter(
    $input: UpdateEventFilterInput!
    $condition: ModelEventFilterConditionInput
  ) {
    updateEventFilter(input: $input, condition: $condition) {
      id
      eventId
      organizationID
      name
      options
      isRequired
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const deleteEventFilter = /* GraphQL */ `
  mutation DeleteEventFilter(
    $input: DeleteEventFilterInput!
    $condition: ModelEventFilterConditionInput
  ) {
    deleteEventFilter(input: $input, condition: $condition) {
      id
      eventId
      organizationID
      name
      options
      isRequired
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const createEventInvoice = /* GraphQL */ `
  mutation CreateEventInvoice(
    $input: CreateEventInvoiceInput!
    $condition: ModelEventInvoiceConditionInput
  ) {
    createEventInvoice(input: $input, condition: $condition) {
      id
      eventId
      status
      statusNote
      number
      stripeInvoiceNumber
      entryNumber
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      payerName
      payerId
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      payerType
      payerStripeId
      payerContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      prizeMoneyRecipientName
      prizeMoneyRecipientId
      prizeMoneyRecipientStripeId
      prizeMoneyRecipient {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      prizeMoneyRecipientContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      prizeMoneyRecipientType
      entryFeeTotalAmount
      otherFeeTotalAmount
      stablingAmount
      beddingAmount
      feedAmount
      results {
        id
        eventId
        entryId
        entry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        eventClassEntryId
        eventClassEntry {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        eventDivisionName
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventClassName
        resultSet
        place
        score
        time
        didNotCompete
        prizeMoney
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        pointTables {
          nextToken
        }
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultEntryId
        eventResultEventClassEntryId
        eventResultPrizeMoneyTableId
      }
      prizeMoneyAmount
      amountToDeductFromInvoice
      amountToPayRecipientViaCheck
      totalDue
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventInvoicePayerContactId
      eventInvoicePrizeMoneyRecipientContactId
    }
  }
`;
export const updateEventInvoice = /* GraphQL */ `
  mutation UpdateEventInvoice(
    $input: UpdateEventInvoiceInput!
    $condition: ModelEventInvoiceConditionInput
  ) {
    updateEventInvoice(input: $input, condition: $condition) {
      id
      eventId
      status
      statusNote
      number
      stripeInvoiceNumber
      entryNumber
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      payerName
      payerId
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      payerType
      payerStripeId
      payerContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      prizeMoneyRecipientName
      prizeMoneyRecipientId
      prizeMoneyRecipientStripeId
      prizeMoneyRecipient {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      prizeMoneyRecipientContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      prizeMoneyRecipientType
      entryFeeTotalAmount
      otherFeeTotalAmount
      stablingAmount
      beddingAmount
      feedAmount
      results {
        id
        eventId
        entryId
        entry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        eventClassEntryId
        eventClassEntry {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        eventDivisionName
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventClassName
        resultSet
        place
        score
        time
        didNotCompete
        prizeMoney
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        pointTables {
          nextToken
        }
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultEntryId
        eventResultEventClassEntryId
        eventResultPrizeMoneyTableId
      }
      prizeMoneyAmount
      amountToDeductFromInvoice
      amountToPayRecipientViaCheck
      totalDue
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventInvoicePayerContactId
      eventInvoicePrizeMoneyRecipientContactId
    }
  }
`;
export const deleteEventInvoice = /* GraphQL */ `
  mutation DeleteEventInvoice(
    $input: DeleteEventInvoiceInput!
    $condition: ModelEventInvoiceConditionInput
  ) {
    deleteEventInvoice(input: $input, condition: $condition) {
      id
      eventId
      status
      statusNote
      number
      stripeInvoiceNumber
      entryNumber
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      payerName
      payerId
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      payerType
      payerStripeId
      payerContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      prizeMoneyRecipientName
      prizeMoneyRecipientId
      prizeMoneyRecipientStripeId
      prizeMoneyRecipient {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      prizeMoneyRecipientContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      prizeMoneyRecipientType
      entryFeeTotalAmount
      otherFeeTotalAmount
      stablingAmount
      beddingAmount
      feedAmount
      results {
        id
        eventId
        entryId
        entry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        eventClassEntryId
        eventClassEntry {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        eventDivisionName
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventClassName
        resultSet
        place
        score
        time
        didNotCompete
        prizeMoney
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        pointTables {
          nextToken
        }
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultEntryId
        eventResultEventClassEntryId
        eventResultPrizeMoneyTableId
      }
      prizeMoneyAmount
      amountToDeductFromInvoice
      amountToPayRecipientViaCheck
      totalDue
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventInvoicePayerContactId
      eventInvoicePrizeMoneyRecipientContactId
    }
  }
`;
export const createEventJudge = /* GraphQL */ `
  mutation CreateEventJudge(
    $input: CreateEventJudgeInput!
    $condition: ModelEventJudgeConditionInput
  ) {
    createEventJudge(input: $input, condition: $condition) {
      id
      name
      eventId
      personId
      judgeId
      contactId
      rating {
        type
        license
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEventJudge = /* GraphQL */ `
  mutation UpdateEventJudge(
    $input: UpdateEventJudgeInput!
    $condition: ModelEventJudgeConditionInput
  ) {
    updateEventJudge(input: $input, condition: $condition) {
      id
      name
      eventId
      personId
      judgeId
      contactId
      rating {
        type
        license
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEventJudge = /* GraphQL */ `
  mutation DeleteEventJudge(
    $input: DeleteEventJudgeInput!
    $condition: ModelEventJudgeConditionInput
  ) {
    deleteEventJudge(input: $input, condition: $condition) {
      id
      name
      eventId
      personId
      judgeId
      contactId
      rating {
        type
        license
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEventOfficial = /* GraphQL */ `
  mutation CreateEventOfficial(
    $input: CreateEventOfficialInput!
    $condition: ModelEventOfficialConditionInput
  ) {
    createEventOfficial(input: $input, condition: $condition) {
      id
      name
      position
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      personId
      contactId
      createdAt
      updatedAt
    }
  }
`;
export const updateEventOfficial = /* GraphQL */ `
  mutation UpdateEventOfficial(
    $input: UpdateEventOfficialInput!
    $condition: ModelEventOfficialConditionInput
  ) {
    updateEventOfficial(input: $input, condition: $condition) {
      id
      name
      position
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      personId
      contactId
      createdAt
      updatedAt
    }
  }
`;
export const deleteEventOfficial = /* GraphQL */ `
  mutation DeleteEventOfficial(
    $input: DeleteEventOfficialInput!
    $condition: ModelEventOfficialConditionInput
  ) {
    deleteEventOfficial(input: $input, condition: $condition) {
      id
      name
      position
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      personId
      contactId
      createdAt
      updatedAt
    }
  }
`;
export const createEventResult = /* GraphQL */ `
  mutation CreateEventResult(
    $input: CreateEventResultInput!
    $condition: ModelEventResultConditionInput
  ) {
    createEventResult(input: $input, condition: $condition) {
      id
      eventId
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      eventClassEntryId
      eventClassEntry {
        id
        eventId
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventEntryId
        eventEntry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        status
        selectedEntryOption
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      eventDivisionName
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventClassName
      resultSet
      place
      score
      time
      didNotCompete
      prizeMoney
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      pointTables {
        items {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        nextToken
      }
      note
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventResultEntryId
      eventResultEventClassEntryId
      eventResultPrizeMoneyTableId
    }
  }
`;
export const updateEventResult = /* GraphQL */ `
  mutation UpdateEventResult(
    $input: UpdateEventResultInput!
    $condition: ModelEventResultConditionInput
  ) {
    updateEventResult(input: $input, condition: $condition) {
      id
      eventId
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      eventClassEntryId
      eventClassEntry {
        id
        eventId
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventEntryId
        eventEntry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        status
        selectedEntryOption
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      eventDivisionName
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventClassName
      resultSet
      place
      score
      time
      didNotCompete
      prizeMoney
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      pointTables {
        items {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        nextToken
      }
      note
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventResultEntryId
      eventResultEventClassEntryId
      eventResultPrizeMoneyTableId
    }
  }
`;
export const deleteEventResult = /* GraphQL */ `
  mutation DeleteEventResult(
    $input: DeleteEventResultInput!
    $condition: ModelEventResultConditionInput
  ) {
    deleteEventResult(input: $input, condition: $condition) {
      id
      eventId
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      eventClassEntryId
      eventClassEntry {
        id
        eventId
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventEntryId
        eventEntry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        status
        selectedEntryOption
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      eventDivisionName
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventClassName
      resultSet
      place
      score
      time
      didNotCompete
      prizeMoney
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      pointTables {
        items {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        nextToken
      }
      note
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventResultEntryId
      eventResultEventClassEntryId
      eventResultPrizeMoneyTableId
    }
  }
`;
export const createEventRing = /* GraphQL */ `
  mutation CreateEventRing(
    $input: CreateEventRingInput!
    $condition: ModelEventRingConditionInput
  ) {
    createEventRing(input: $input, condition: $condition) {
      id
      name
      eventId
      ringId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      ring {
        id
        name
        displayOrder
        organizationId
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
    }
  }
`;
export const updateEventRing = /* GraphQL */ `
  mutation UpdateEventRing(
    $input: UpdateEventRingInput!
    $condition: ModelEventRingConditionInput
  ) {
    updateEventRing(input: $input, condition: $condition) {
      id
      name
      eventId
      ringId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      ring {
        id
        name
        displayOrder
        organizationId
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
    }
  }
`;
export const deleteEventRing = /* GraphQL */ `
  mutation DeleteEventRing(
    $input: DeleteEventRingInput!
    $condition: ModelEventRingConditionInput
  ) {
    deleteEventRing(input: $input, condition: $condition) {
      id
      name
      eventId
      ringId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      ring {
        id
        name
        displayOrder
        organizationId
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
    }
  }
`;
export const createEventRules = /* GraphQL */ `
  mutation CreateEventRules(
    $input: CreateEventRulesInput!
    $condition: ModelEventRulesConditionInput
  ) {
    createEventRules(input: $input, condition: $condition) {
      id
      eventId
      title
      text
      requiresAgreementPerEntry
      agreements {
        items {
          id
          eventId
          eventRuleId
          eventEntryId
          name
          personId
          personType
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const updateEventRules = /* GraphQL */ `
  mutation UpdateEventRules(
    $input: UpdateEventRulesInput!
    $condition: ModelEventRulesConditionInput
  ) {
    updateEventRules(input: $input, condition: $condition) {
      id
      eventId
      title
      text
      requiresAgreementPerEntry
      agreements {
        items {
          id
          eventId
          eventRuleId
          eventEntryId
          name
          personId
          personType
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const deleteEventRules = /* GraphQL */ `
  mutation DeleteEventRules(
    $input: DeleteEventRulesInput!
    $condition: ModelEventRulesConditionInput
  ) {
    deleteEventRules(input: $input, condition: $condition) {
      id
      eventId
      title
      text
      requiresAgreementPerEntry
      agreements {
        items {
          id
          eventId
          eventRuleId
          eventEntryId
          name
          personId
          personType
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const createEventRulesAgreement = /* GraphQL */ `
  mutation CreateEventRulesAgreement(
    $input: CreateEventRulesAgreementInput!
    $condition: ModelEventRulesAgreementConditionInput
  ) {
    createEventRulesAgreement(input: $input, condition: $condition) {
      id
      eventId
      eventRuleId
      eventRule {
        id
        eventId
        title
        text
        requiresAgreementPerEntry
        agreements {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      eventEntryId
      eventEntry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      name
      personId
      personType
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const updateEventRulesAgreement = /* GraphQL */ `
  mutation UpdateEventRulesAgreement(
    $input: UpdateEventRulesAgreementInput!
    $condition: ModelEventRulesAgreementConditionInput
  ) {
    updateEventRulesAgreement(input: $input, condition: $condition) {
      id
      eventId
      eventRuleId
      eventRule {
        id
        eventId
        title
        text
        requiresAgreementPerEntry
        agreements {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      eventEntryId
      eventEntry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      name
      personId
      personType
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const deleteEventRulesAgreement = /* GraphQL */ `
  mutation DeleteEventRulesAgreement(
    $input: DeleteEventRulesAgreementInput!
    $condition: ModelEventRulesAgreementConditionInput
  ) {
    deleteEventRulesAgreement(input: $input, condition: $condition) {
      id
      eventId
      eventRuleId
      eventRule {
        id
        eventId
        title
        text
        requiresAgreementPerEntry
        agreements {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      eventEntryId
      eventEntry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      name
      personId
      personType
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const createEventScratch = /* GraphQL */ `
  mutation CreateEventScratch(
    $input: CreateEventScratchInput!
    $condition: ModelEventScratchConditionInput
  ) {
    createEventScratch(input: $input, condition: $condition) {
      id
      eventId
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      eventDivisionName
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventClassName
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const updateEventScratch = /* GraphQL */ `
  mutation UpdateEventScratch(
    $input: UpdateEventScratchInput!
    $condition: ModelEventScratchConditionInput
  ) {
    updateEventScratch(input: $input, condition: $condition) {
      id
      eventId
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      eventDivisionName
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventClassName
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const deleteEventScratch = /* GraphQL */ `
  mutation DeleteEventScratch(
    $input: DeleteEventScratchInput!
    $condition: ModelEventScratchConditionInput
  ) {
    deleteEventScratch(input: $input, condition: $condition) {
      id
      eventId
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          pictureURL
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      eventDivisionName
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventClassName
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const createEventStallSet = /* GraphQL */ `
  mutation CreateEventStallSet(
    $input: CreateEventStallSetInput!
    $condition: ModelEventStallSetConditionInput
  ) {
    createEventStallSet(input: $input, condition: $condition) {
      id
      eventId
      organizationId
      organizationStallSetId
      name
      location
      available
      totalAvailable
      used
      totalUsed
      createdOn
      updatedOn
    }
  }
`;
export const updateEventStallSet = /* GraphQL */ `
  mutation UpdateEventStallSet(
    $input: UpdateEventStallSetInput!
    $condition: ModelEventStallSetConditionInput
  ) {
    updateEventStallSet(input: $input, condition: $condition) {
      id
      eventId
      organizationId
      organizationStallSetId
      name
      location
      available
      totalAvailable
      used
      totalUsed
      createdOn
      updatedOn
    }
  }
`;
export const deleteEventStallSet = /* GraphQL */ `
  mutation DeleteEventStallSet(
    $input: DeleteEventStallSetInput!
    $condition: ModelEventStallSetConditionInput
  ) {
    deleteEventStallSet(input: $input, condition: $condition) {
      id
      eventId
      organizationId
      organizationStallSetId
      name
      location
      available
      totalAvailable
      used
      totalUsed
      createdOn
      updatedOn
    }
  }
`;
export const createEventStallType = /* GraphQL */ `
  mutation CreateEventStallType(
    $input: CreateEventStallTypeInput!
    $condition: ModelEventStallTypeConditionInput
  ) {
    createEventStallType(input: $input, condition: $condition) {
      id
      eventId
      organizationId
      organizationStallTypeId
      name
      description
      type
      pricePerStall
      taxPerStall
      createdOn
      updatedOn
    }
  }
`;
export const updateEventStallType = /* GraphQL */ `
  mutation UpdateEventStallType(
    $input: UpdateEventStallTypeInput!
    $condition: ModelEventStallTypeConditionInput
  ) {
    updateEventStallType(input: $input, condition: $condition) {
      id
      eventId
      organizationId
      organizationStallTypeId
      name
      description
      type
      pricePerStall
      taxPerStall
      createdOn
      updatedOn
    }
  }
`;
export const deleteEventStallType = /* GraphQL */ `
  mutation DeleteEventStallType(
    $input: DeleteEventStallTypeInput!
    $condition: ModelEventStallTypeConditionInput
  ) {
    deleteEventStallType(input: $input, condition: $condition) {
      id
      eventId
      organizationId
      organizationStallTypeId
      name
      description
      type
      pricePerStall
      taxPerStall
      createdOn
      updatedOn
    }
  }
`;
export const createFee = /* GraphQL */ `
  mutation CreateFee(
    $input: CreateFeeInput!
    $condition: ModelFeeConditionInput
  ) {
    createFee(input: $input, condition: $condition) {
      id
      name
      description
      createdBy
      organizationId
      stripeId
      altId
      isFixed
      isPercentage
      totalPrice
      quantity
      isPerEntry
      isPerHorse
      isPerRider
      isPerClass
      isPerTrainer
      isRefundable
      isChargedAtTimeOfEntry
      isStartedOnEntryDate
      isHiddenFromEntryForm
      startDate
      startTime
      taxA
      taxB
      taxRateA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxRateB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      feeTaxRateAId
      feeTaxRateBId
    }
  }
`;
export const updateFee = /* GraphQL */ `
  mutation UpdateFee(
    $input: UpdateFeeInput!
    $condition: ModelFeeConditionInput
  ) {
    updateFee(input: $input, condition: $condition) {
      id
      name
      description
      createdBy
      organizationId
      stripeId
      altId
      isFixed
      isPercentage
      totalPrice
      quantity
      isPerEntry
      isPerHorse
      isPerRider
      isPerClass
      isPerTrainer
      isRefundable
      isChargedAtTimeOfEntry
      isStartedOnEntryDate
      isHiddenFromEntryForm
      startDate
      startTime
      taxA
      taxB
      taxRateA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxRateB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      feeTaxRateAId
      feeTaxRateBId
    }
  }
`;
export const deleteFee = /* GraphQL */ `
  mutation DeleteFee(
    $input: DeleteFeeInput!
    $condition: ModelFeeConditionInput
  ) {
    deleteFee(input: $input, condition: $condition) {
      id
      name
      description
      createdBy
      organizationId
      stripeId
      altId
      isFixed
      isPercentage
      totalPrice
      quantity
      isPerEntry
      isPerHorse
      isPerRider
      isPerClass
      isPerTrainer
      isRefundable
      isChargedAtTimeOfEntry
      isStartedOnEntryDate
      isHiddenFromEntryForm
      startDate
      startTime
      taxA
      taxB
      taxRateA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxRateB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      feeTaxRateAId
      feeTaxRateBId
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback(
    $input: CreateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    createFeedback(input: $input, condition: $condition) {
      id
      clinicianId
      personId
      technical
      style
      turnout
      general
      score
      status
      submissionFeedback {
        id
        type
        submissionId
        submission {
          id
          title
          description
          videoLength
          riderId
          personId
          totalPrice
          status
          createdOn
          updatedOn
        }
        feedback {
          id
          clinicianId
          personId
          technical
          style
          turnout
          general
          score
          status
          createdOn
          updatedOn
          feedbackSubmissionFeedbackId
        }
        feedbackId
        personId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        riderId
        clinician {
          id
          name
          personId
          judgeId
          contactId
          usefNumber
          ecNumber
          price
          status
          paymentVerified
          stripeAccountId
          totalEarned
          location
          disciplines
          experience
          personal
          preferences
          hasIHSAExperience
          hasIEAExperience
          createdOn
          updatedOn
          clinicianPaymentChoiceId
        }
        clinicianId
        submissionStatus
        feedbackStatus
        clinicianAmount
        declineReason
        permissionToPost
        dateSubmitted
        dateCompleted
        createdOn
        updatedOn
        submissionFeedbackClinicianId
      }
      createdOn
      updatedOn
      feedbackSubmissionFeedbackId
    }
  }
`;
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback(
    $input: UpdateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    updateFeedback(input: $input, condition: $condition) {
      id
      clinicianId
      personId
      technical
      style
      turnout
      general
      score
      status
      submissionFeedback {
        id
        type
        submissionId
        submission {
          id
          title
          description
          videoLength
          riderId
          personId
          totalPrice
          status
          createdOn
          updatedOn
        }
        feedback {
          id
          clinicianId
          personId
          technical
          style
          turnout
          general
          score
          status
          createdOn
          updatedOn
          feedbackSubmissionFeedbackId
        }
        feedbackId
        personId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        riderId
        clinician {
          id
          name
          personId
          judgeId
          contactId
          usefNumber
          ecNumber
          price
          status
          paymentVerified
          stripeAccountId
          totalEarned
          location
          disciplines
          experience
          personal
          preferences
          hasIHSAExperience
          hasIEAExperience
          createdOn
          updatedOn
          clinicianPaymentChoiceId
        }
        clinicianId
        submissionStatus
        feedbackStatus
        clinicianAmount
        declineReason
        permissionToPost
        dateSubmitted
        dateCompleted
        createdOn
        updatedOn
        submissionFeedbackClinicianId
      }
      createdOn
      updatedOn
      feedbackSubmissionFeedbackId
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback(
    $input: DeleteFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    deleteFeedback(input: $input, condition: $condition) {
      id
      clinicianId
      personId
      technical
      style
      turnout
      general
      score
      status
      submissionFeedback {
        id
        type
        submissionId
        submission {
          id
          title
          description
          videoLength
          riderId
          personId
          totalPrice
          status
          createdOn
          updatedOn
        }
        feedback {
          id
          clinicianId
          personId
          technical
          style
          turnout
          general
          score
          status
          createdOn
          updatedOn
          feedbackSubmissionFeedbackId
        }
        feedbackId
        personId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        riderId
        clinician {
          id
          name
          personId
          judgeId
          contactId
          usefNumber
          ecNumber
          price
          status
          paymentVerified
          stripeAccountId
          totalEarned
          location
          disciplines
          experience
          personal
          preferences
          hasIHSAExperience
          hasIEAExperience
          createdOn
          updatedOn
          clinicianPaymentChoiceId
        }
        clinicianId
        submissionStatus
        feedbackStatus
        clinicianAmount
        declineReason
        permissionToPost
        dateSubmitted
        dateCompleted
        createdOn
        updatedOn
        submissionFeedbackClinicianId
      }
      createdOn
      updatedOn
      feedbackSubmissionFeedbackId
    }
  }
`;
export const createGameInput = /* GraphQL */ `
  mutation CreateGameInput(
    $input: CreateGameInputInput!
    $condition: ModelGameInputConditionInput
  ) {
    createGameInput(input: $input, condition: $condition) {
      id
      eventId
      eventClassId
      backNumber
      round
      score
      isMasterRecord
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const updateGameInput = /* GraphQL */ `
  mutation UpdateGameInput(
    $input: UpdateGameInputInput!
    $condition: ModelGameInputConditionInput
  ) {
    updateGameInput(input: $input, condition: $condition) {
      id
      eventId
      eventClassId
      backNumber
      round
      score
      isMasterRecord
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const deleteGameInput = /* GraphQL */ `
  mutation DeleteGameInput(
    $input: DeleteGameInputInput!
    $condition: ModelGameInputConditionInput
  ) {
    deleteGameInput(input: $input, condition: $condition) {
      id
      eventId
      eventClassId
      backNumber
      round
      score
      isMasterRecord
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const createHorse = /* GraphQL */ `
  mutation CreateHorse(
    $input: CreateHorseInput!
    $condition: ModelHorseConditionInput
  ) {
    createHorse(input: $input, condition: $condition) {
      id
      name
      nickname
      previousNames
      personId
      ownerId
      owner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      ownerName
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      contactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      pictureURL
      picture {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      microchipNumber
      passportNumber
      dateOfBirth
      breed
      sex
      color
      markings
      height
      foalState
      cogginsNumber
      cogginsDate
      cogginsState
      coggins {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      highestLevelCompeted
      greenEligibility
      createdOn
      updatedOn
    }
  }
`;
export const updateHorse = /* GraphQL */ `
  mutation UpdateHorse(
    $input: UpdateHorseInput!
    $condition: ModelHorseConditionInput
  ) {
    updateHorse(input: $input, condition: $condition) {
      id
      name
      nickname
      previousNames
      personId
      ownerId
      owner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      ownerName
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      contactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      pictureURL
      picture {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      microchipNumber
      passportNumber
      dateOfBirth
      breed
      sex
      color
      markings
      height
      foalState
      cogginsNumber
      cogginsDate
      cogginsState
      coggins {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      highestLevelCompeted
      greenEligibility
      createdOn
      updatedOn
    }
  }
`;
export const deleteHorse = /* GraphQL */ `
  mutation DeleteHorse(
    $input: DeleteHorseInput!
    $condition: ModelHorseConditionInput
  ) {
    deleteHorse(input: $input, condition: $condition) {
      id
      name
      nickname
      previousNames
      personId
      ownerId
      owner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      ownerName
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      contactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      pictureURL
      picture {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      microchipNumber
      passportNumber
      dateOfBirth
      breed
      sex
      color
      markings
      height
      foalState
      cogginsNumber
      cogginsDate
      cogginsState
      coggins {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      highestLevelCompeted
      greenEligibility
      createdOn
      updatedOn
    }
  }
`;
export const createPedigree = /* GraphQL */ `
  mutation CreatePedigree(
    $input: CreatePedigreeInput!
    $condition: ModelPedigreeConditionInput
  ) {
    createPedigree(input: $input, condition: $condition) {
      id
      horseId
      horse {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      sireId
      sireName
      sire {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      damId
      damName
      dam {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      birthplace {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      breederName
      breederContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      breederId
      breedRegistryInfo
      createdOn
      updatedOn
      pedigreeBirthplaceId
      pedigreeBreederContactId
    }
  }
`;
export const updatePedigree = /* GraphQL */ `
  mutation UpdatePedigree(
    $input: UpdatePedigreeInput!
    $condition: ModelPedigreeConditionInput
  ) {
    updatePedigree(input: $input, condition: $condition) {
      id
      horseId
      horse {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      sireId
      sireName
      sire {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      damId
      damName
      dam {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      birthplace {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      breederName
      breederContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      breederId
      breedRegistryInfo
      createdOn
      updatedOn
      pedigreeBirthplaceId
      pedigreeBreederContactId
    }
  }
`;
export const deletePedigree = /* GraphQL */ `
  mutation DeletePedigree(
    $input: DeletePedigreeInput!
    $condition: ModelPedigreeConditionInput
  ) {
    deletePedigree(input: $input, condition: $condition) {
      id
      horseId
      horse {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      sireId
      sireName
      sire {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      damId
      damName
      dam {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      birthplace {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      breederName
      breederContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      breederId
      breedRegistryInfo
      createdOn
      updatedOn
      pedigreeBirthplaceId
      pedigreeBreederContactId
    }
  }
`;
export const createMeasurementRecord = /* GraphQL */ `
  mutation CreateMeasurementRecord(
    $input: CreateMeasurementRecordInput!
    $condition: ModelMeasurementRecordConditionInput
  ) {
    createMeasurementRecord(input: $input, condition: $condition) {
      id
      horseId
      horse {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      date
      status
      height
      heightOfHeel
      shoeingStatus
      steward
      official
      createdOn
      updatedOn
    }
  }
`;
export const updateMeasurementRecord = /* GraphQL */ `
  mutation UpdateMeasurementRecord(
    $input: UpdateMeasurementRecordInput!
    $condition: ModelMeasurementRecordConditionInput
  ) {
    updateMeasurementRecord(input: $input, condition: $condition) {
      id
      horseId
      horse {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      date
      status
      height
      heightOfHeel
      shoeingStatus
      steward
      official
      createdOn
      updatedOn
    }
  }
`;
export const deleteMeasurementRecord = /* GraphQL */ `
  mutation DeleteMeasurementRecord(
    $input: DeleteMeasurementRecordInput!
    $condition: ModelMeasurementRecordConditionInput
  ) {
    deleteMeasurementRecord(input: $input, condition: $condition) {
      id
      horseId
      horse {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      date
      status
      height
      heightOfHeel
      shoeingStatus
      steward
      official
      createdOn
      updatedOn
    }
  }
`;
export const createHorseShow = /* GraphQL */ `
  mutation CreateHorseShow(
    $input: CreateHorseShowInput!
    $condition: ModelHorseShowConditionInput
  ) {
    createHorseShow(input: $input, condition: $condition) {
      id
      name
      nickname
      eventId
      ratings {
        id
        eventId
        governingBody
        rating
        createdAt
        updatedAt
      }
      judges {
        id
        name
        eventId
        personId
        judgeId
        contactId
        rating {
          type
          license
        }
        createdAt
        updatedAt
      }
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const updateHorseShow = /* GraphQL */ `
  mutation UpdateHorseShow(
    $input: UpdateHorseShowInput!
    $condition: ModelHorseShowConditionInput
  ) {
    updateHorseShow(input: $input, condition: $condition) {
      id
      name
      nickname
      eventId
      ratings {
        id
        eventId
        governingBody
        rating
        createdAt
        updatedAt
      }
      judges {
        id
        name
        eventId
        personId
        judgeId
        contactId
        rating {
          type
          license
        }
        createdAt
        updatedAt
      }
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const deleteHorseShow = /* GraphQL */ `
  mutation DeleteHorseShow(
    $input: DeleteHorseShowInput!
    $condition: ModelHorseShowConditionInput
  ) {
    deleteHorseShow(input: $input, condition: $condition) {
      id
      name
      nickname
      eventId
      ratings {
        id
        eventId
        governingBody
        rating
        createdAt
        updatedAt
      }
      judges {
        id
        name
        eventId
        personId
        judgeId
        contactId
        rating {
          type
          license
        }
        createdAt
        updatedAt
      }
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const createHorseShowRating = /* GraphQL */ `
  mutation CreateHorseShowRating(
    $input: CreateHorseShowRatingInput!
    $condition: ModelHorseShowRatingConditionInput
  ) {
    createHorseShowRating(input: $input, condition: $condition) {
      id
      eventId
      governingBody
      rating
      createdAt
      updatedAt
    }
  }
`;
export const updateHorseShowRating = /* GraphQL */ `
  mutation UpdateHorseShowRating(
    $input: UpdateHorseShowRatingInput!
    $condition: ModelHorseShowRatingConditionInput
  ) {
    updateHorseShowRating(input: $input, condition: $condition) {
      id
      eventId
      governingBody
      rating
      createdAt
      updatedAt
    }
  }
`;
export const deleteHorseShowRating = /* GraphQL */ `
  mutation DeleteHorseShowRating(
    $input: DeleteHorseShowRatingInput!
    $condition: ModelHorseShowRatingConditionInput
  ) {
    deleteHorseShowRating(input: $input, condition: $condition) {
      id
      eventId
      governingBody
      rating
      createdAt
      updatedAt
    }
  }
`;
export const createInvitation = /* GraphQL */ `
  mutation CreateInvitation(
    $input: CreateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    createInvitation(input: $input, condition: $condition) {
      id
      createdBy
      creatorEmail
      invitee
      inviteeEmail
      status
      organizationId
      barnId
      permissionLevel
      roles
      createdOn
      updatedOn
    }
  }
`;
export const updateInvitation = /* GraphQL */ `
  mutation UpdateInvitation(
    $input: UpdateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    updateInvitation(input: $input, condition: $condition) {
      id
      createdBy
      creatorEmail
      invitee
      inviteeEmail
      status
      organizationId
      barnId
      permissionLevel
      roles
      createdOn
      updatedOn
    }
  }
`;
export const deleteInvitation = /* GraphQL */ `
  mutation DeleteInvitation(
    $input: DeleteInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    deleteInvitation(input: $input, condition: $condition) {
      id
      createdBy
      creatorEmail
      invitee
      inviteeEmail
      status
      organizationId
      barnId
      permissionLevel
      roles
      createdOn
      updatedOn
    }
  }
`;
export const createMembership = /* GraphQL */ `
  mutation CreateMembership(
    $input: CreateMembershipInput!
    $condition: ModelMembershipConditionInput
  ) {
    createMembership(input: $input, condition: $condition) {
      id
      name
      description
      membershipId
      backNumber
      membershipStatus
      amountPaid
      organizationMembershipTypeId
      organizationMembershipType {
        id
        name
        description
        order
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        membershipIdValues
        nextAvailableMembershipId
        price
        category
        schedule
        isStartAtTimeOfPurchase
        setStartDate
        acceptOnlineApplications
        applicationFields {
          firstName
          lastName
          dateOfBirth
          contact
          gradeLevel
          barnName
          teamName
          backNumber
          groupName
          groupLeader
          groupLeaderContactInfo
          collectMemberDetailsTogether
          maxNumberOfGroupMembers
          isTeam
          horseName
          horseHeight
          horseOwnerName
          cogginsInfo
          cogginsUpload
        }
        applicationOptions {
          requireApproval
          autoAssignIds
          acceptRSPPayment
          acceptOtherPayment
        }
        organizationDocuments {
          id
          name
        }
        organizationRules {
          id
          title
          body
          isRequired
        }
        seasons {
          nextToken
        }
        createdOn
        updatedOn
        organizationMembershipTypeOrganizationId
      }
      type
      personName
      personId
      riderId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      horseId
      horse {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      ownerId
      owner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      teamId
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      organizationName
      isJunior
      isProfessional
      dateMembershipEnds
      volunteerHours
      meetingsAttended
      showsAttended
      oneTimePassUseDate
      personEmail
      gradeLevel
      aelDivision
      aelTeamRole
      documents {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      organizationAgreements {
        organizationRuleId
        organizationRuleTitle
        firstName
        lastName
        personId
        createdOn
        updatedOn
      }
      contactPersonId
      groupContactId
      groupContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      groupLeaderContactId
      groupLeaderContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      createdBy
      createdOn
      updatedOn
      personMembershipsId
      membershipOrganizationId
    }
  }
`;
export const updateMembership = /* GraphQL */ `
  mutation UpdateMembership(
    $input: UpdateMembershipInput!
    $condition: ModelMembershipConditionInput
  ) {
    updateMembership(input: $input, condition: $condition) {
      id
      name
      description
      membershipId
      backNumber
      membershipStatus
      amountPaid
      organizationMembershipTypeId
      organizationMembershipType {
        id
        name
        description
        order
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        membershipIdValues
        nextAvailableMembershipId
        price
        category
        schedule
        isStartAtTimeOfPurchase
        setStartDate
        acceptOnlineApplications
        applicationFields {
          firstName
          lastName
          dateOfBirth
          contact
          gradeLevel
          barnName
          teamName
          backNumber
          groupName
          groupLeader
          groupLeaderContactInfo
          collectMemberDetailsTogether
          maxNumberOfGroupMembers
          isTeam
          horseName
          horseHeight
          horseOwnerName
          cogginsInfo
          cogginsUpload
        }
        applicationOptions {
          requireApproval
          autoAssignIds
          acceptRSPPayment
          acceptOtherPayment
        }
        organizationDocuments {
          id
          name
        }
        organizationRules {
          id
          title
          body
          isRequired
        }
        seasons {
          nextToken
        }
        createdOn
        updatedOn
        organizationMembershipTypeOrganizationId
      }
      type
      personName
      personId
      riderId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      horseId
      horse {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      ownerId
      owner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      teamId
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      organizationName
      isJunior
      isProfessional
      dateMembershipEnds
      volunteerHours
      meetingsAttended
      showsAttended
      oneTimePassUseDate
      personEmail
      gradeLevel
      aelDivision
      aelTeamRole
      documents {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      organizationAgreements {
        organizationRuleId
        organizationRuleTitle
        firstName
        lastName
        personId
        createdOn
        updatedOn
      }
      contactPersonId
      groupContactId
      groupContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      groupLeaderContactId
      groupLeaderContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      createdBy
      createdOn
      updatedOn
      personMembershipsId
      membershipOrganizationId
    }
  }
`;
export const deleteMembership = /* GraphQL */ `
  mutation DeleteMembership(
    $input: DeleteMembershipInput!
    $condition: ModelMembershipConditionInput
  ) {
    deleteMembership(input: $input, condition: $condition) {
      id
      name
      description
      membershipId
      backNumber
      membershipStatus
      amountPaid
      organizationMembershipTypeId
      organizationMembershipType {
        id
        name
        description
        order
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        membershipIdValues
        nextAvailableMembershipId
        price
        category
        schedule
        isStartAtTimeOfPurchase
        setStartDate
        acceptOnlineApplications
        applicationFields {
          firstName
          lastName
          dateOfBirth
          contact
          gradeLevel
          barnName
          teamName
          backNumber
          groupName
          groupLeader
          groupLeaderContactInfo
          collectMemberDetailsTogether
          maxNumberOfGroupMembers
          isTeam
          horseName
          horseHeight
          horseOwnerName
          cogginsInfo
          cogginsUpload
        }
        applicationOptions {
          requireApproval
          autoAssignIds
          acceptRSPPayment
          acceptOtherPayment
        }
        organizationDocuments {
          id
          name
        }
        organizationRules {
          id
          title
          body
          isRequired
        }
        seasons {
          nextToken
        }
        createdOn
        updatedOn
        organizationMembershipTypeOrganizationId
      }
      type
      personName
      personId
      riderId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      horseId
      horse {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      ownerId
      owner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      teamId
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      organizationName
      isJunior
      isProfessional
      dateMembershipEnds
      volunteerHours
      meetingsAttended
      showsAttended
      oneTimePassUseDate
      personEmail
      gradeLevel
      aelDivision
      aelTeamRole
      documents {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      organizationAgreements {
        organizationRuleId
        organizationRuleTitle
        firstName
        lastName
        personId
        createdOn
        updatedOn
      }
      contactPersonId
      groupContactId
      groupContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      groupLeaderContactId
      groupLeaderContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      createdBy
      createdOn
      updatedOn
      personMembershipsId
      membershipOrganizationId
    }
  }
`;
export const createOrganizationMembershipType = /* GraphQL */ `
  mutation CreateOrganizationMembershipType(
    $input: CreateOrganizationMembershipTypeInput!
    $condition: ModelOrganizationMembershipTypeConditionInput
  ) {
    createOrganizationMembershipType(input: $input, condition: $condition) {
      id
      name
      description
      order
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      membershipIdValues
      nextAvailableMembershipId
      price
      category
      schedule
      isStartAtTimeOfPurchase
      setStartDate
      acceptOnlineApplications
      applicationFields {
        firstName
        lastName
        dateOfBirth
        contact
        gradeLevel
        barnName
        teamName
        backNumber
        groupName
        groupLeader
        groupLeaderContactInfo
        collectMemberDetailsTogether
        maxNumberOfGroupMembers
        isTeam
        horseName
        horseHeight
        horseOwnerName
        cogginsInfo
        cogginsUpload
      }
      applicationOptions {
        requireApproval
        autoAssignIds
        acceptRSPPayment
        acceptOtherPayment
      }
      organizationDocuments {
        id
        name
      }
      organizationRules {
        id
        title
        body
        isRequired
      }
      seasons {
        items {
          id
          organizationMembershipTypeID
          seasonID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdOn
      updatedOn
      organizationMembershipTypeOrganizationId
    }
  }
`;
export const updateOrganizationMembershipType = /* GraphQL */ `
  mutation UpdateOrganizationMembershipType(
    $input: UpdateOrganizationMembershipTypeInput!
    $condition: ModelOrganizationMembershipTypeConditionInput
  ) {
    updateOrganizationMembershipType(input: $input, condition: $condition) {
      id
      name
      description
      order
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      membershipIdValues
      nextAvailableMembershipId
      price
      category
      schedule
      isStartAtTimeOfPurchase
      setStartDate
      acceptOnlineApplications
      applicationFields {
        firstName
        lastName
        dateOfBirth
        contact
        gradeLevel
        barnName
        teamName
        backNumber
        groupName
        groupLeader
        groupLeaderContactInfo
        collectMemberDetailsTogether
        maxNumberOfGroupMembers
        isTeam
        horseName
        horseHeight
        horseOwnerName
        cogginsInfo
        cogginsUpload
      }
      applicationOptions {
        requireApproval
        autoAssignIds
        acceptRSPPayment
        acceptOtherPayment
      }
      organizationDocuments {
        id
        name
      }
      organizationRules {
        id
        title
        body
        isRequired
      }
      seasons {
        items {
          id
          organizationMembershipTypeID
          seasonID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdOn
      updatedOn
      organizationMembershipTypeOrganizationId
    }
  }
`;
export const deleteOrganizationMembershipType = /* GraphQL */ `
  mutation DeleteOrganizationMembershipType(
    $input: DeleteOrganizationMembershipTypeInput!
    $condition: ModelOrganizationMembershipTypeConditionInput
  ) {
    deleteOrganizationMembershipType(input: $input, condition: $condition) {
      id
      name
      description
      order
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      membershipIdValues
      nextAvailableMembershipId
      price
      category
      schedule
      isStartAtTimeOfPurchase
      setStartDate
      acceptOnlineApplications
      applicationFields {
        firstName
        lastName
        dateOfBirth
        contact
        gradeLevel
        barnName
        teamName
        backNumber
        groupName
        groupLeader
        groupLeaderContactInfo
        collectMemberDetailsTogether
        maxNumberOfGroupMembers
        isTeam
        horseName
        horseHeight
        horseOwnerName
        cogginsInfo
        cogginsUpload
      }
      applicationOptions {
        requireApproval
        autoAssignIds
        acceptRSPPayment
        acceptOtherPayment
      }
      organizationDocuments {
        id
        name
      }
      organizationRules {
        id
        title
        body
        isRequired
      }
      seasons {
        items {
          id
          organizationMembershipTypeID
          seasonID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdOn
      updatedOn
      organizationMembershipTypeOrganizationId
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      nickname
      description
      type
      industry
      addresses
      contactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      stripeId
      stripeCustomerId
      paymentMethodId
      website
      media {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      createdBy
      lastUpdatedBy
      organizationOptions {
        usesTextAlerts
        acceptsIncomingTextAlerts
      }
      urlBackHalf
      colors
      logos {
        logoURL
        key
        logo {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
      }
      createdOn
      updatedOn
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      nickname
      description
      type
      industry
      addresses
      contactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      stripeId
      stripeCustomerId
      paymentMethodId
      website
      media {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      createdBy
      lastUpdatedBy
      organizationOptions {
        usesTextAlerts
        acceptsIncomingTextAlerts
      }
      urlBackHalf
      colors
      logos {
        logoURL
        key
        logo {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
      }
      createdOn
      updatedOn
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      name
      nickname
      description
      type
      industry
      addresses
      contactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      stripeId
      stripeCustomerId
      paymentMethodId
      website
      media {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      createdBy
      lastUpdatedBy
      organizationOptions {
        usesTextAlerts
        acceptsIncomingTextAlerts
      }
      urlBackHalf
      colors
      logos {
        logoURL
        key
        logo {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
      }
      createdOn
      updatedOn
    }
  }
`;
export const createOrganizationBeddingType = /* GraphQL */ `
  mutation CreateOrganizationBeddingType(
    $input: CreateOrganizationBeddingTypeInput!
    $condition: ModelOrganizationBeddingTypeConditionInput
  ) {
    createOrganizationBeddingType(input: $input, condition: $condition) {
      id
      organizationId
      name
      description
      type
      pricePerBag
      taxA
      taxB
      taxRateIdA
      taxRateIdB
      taxRateA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxRateB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
    }
  }
`;
export const updateOrganizationBeddingType = /* GraphQL */ `
  mutation UpdateOrganizationBeddingType(
    $input: UpdateOrganizationBeddingTypeInput!
    $condition: ModelOrganizationBeddingTypeConditionInput
  ) {
    updateOrganizationBeddingType(input: $input, condition: $condition) {
      id
      organizationId
      name
      description
      type
      pricePerBag
      taxA
      taxB
      taxRateIdA
      taxRateIdB
      taxRateA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxRateB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
    }
  }
`;
export const deleteOrganizationBeddingType = /* GraphQL */ `
  mutation DeleteOrganizationBeddingType(
    $input: DeleteOrganizationBeddingTypeInput!
    $condition: ModelOrganizationBeddingTypeConditionInput
  ) {
    deleteOrganizationBeddingType(input: $input, condition: $condition) {
      id
      organizationId
      name
      description
      type
      pricePerBag
      taxA
      taxB
      taxRateIdA
      taxRateIdB
      taxRateA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxRateB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
    }
  }
`;
export const createOrganizationClass = /* GraphQL */ `
  mutation CreateOrganizationClass(
    $input: CreateOrganizationClassInput!
    $condition: ModelOrganizationClassConditionInput
  ) {
    createOrganizationClass(input: $input, condition: $condition) {
      id
      name
      classId
      class {
        id
        name
        nickname
        divisionId
        type
        description
        discipline
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      organizationId
      organizationDivisionId
      hasChampionship
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      type
      number
      entryFee
      prizeMoney
      minNumberEntries
      maxNumberEntries
      jumpHeight
      estimatedTimePerRound
      createdBy
      displayOrder
      lastUpdatedBy
      createdOn
      updatedOn
      organizationClassPointTableId
      organizationClassPrizeMoneyTableId
    }
  }
`;
export const updateOrganizationClass = /* GraphQL */ `
  mutation UpdateOrganizationClass(
    $input: UpdateOrganizationClassInput!
    $condition: ModelOrganizationClassConditionInput
  ) {
    updateOrganizationClass(input: $input, condition: $condition) {
      id
      name
      classId
      class {
        id
        name
        nickname
        divisionId
        type
        description
        discipline
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      organizationId
      organizationDivisionId
      hasChampionship
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      type
      number
      entryFee
      prizeMoney
      minNumberEntries
      maxNumberEntries
      jumpHeight
      estimatedTimePerRound
      createdBy
      displayOrder
      lastUpdatedBy
      createdOn
      updatedOn
      organizationClassPointTableId
      organizationClassPrizeMoneyTableId
    }
  }
`;
export const deleteOrganizationClass = /* GraphQL */ `
  mutation DeleteOrganizationClass(
    $input: DeleteOrganizationClassInput!
    $condition: ModelOrganizationClassConditionInput
  ) {
    deleteOrganizationClass(input: $input, condition: $condition) {
      id
      name
      classId
      class {
        id
        name
        nickname
        divisionId
        type
        description
        discipline
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      organizationId
      organizationDivisionId
      hasChampionship
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      type
      number
      entryFee
      prizeMoney
      minNumberEntries
      maxNumberEntries
      jumpHeight
      estimatedTimePerRound
      createdBy
      displayOrder
      lastUpdatedBy
      createdOn
      updatedOn
      organizationClassPointTableId
      organizationClassPrizeMoneyTableId
    }
  }
`;
export const createOrganizationDivision = /* GraphQL */ `
  mutation CreateOrganizationDivision(
    $input: CreateOrganizationDivisionInput!
    $condition: ModelOrganizationDivisionConditionInput
  ) {
    createOrganizationDivision(input: $input, condition: $condition) {
      id
      name
      number
      divisionId
      division {
        id
        name
        nickname
        description
        discipline
        isSearchable
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      organizationId
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      jumpHeight
      minNumberEntries
      maxNumberEntries
      entryFee
      prizeMoney
      entryFeePerClass
      prizeMoneyPerClass
      createdBy
      lastUpdatedBy
      displayOrder
      createdOn
      updatedOn
      organizationDivisionPointTableId
      organizationDivisionPrizeMoneyTableId
    }
  }
`;
export const updateOrganizationDivision = /* GraphQL */ `
  mutation UpdateOrganizationDivision(
    $input: UpdateOrganizationDivisionInput!
    $condition: ModelOrganizationDivisionConditionInput
  ) {
    updateOrganizationDivision(input: $input, condition: $condition) {
      id
      name
      number
      divisionId
      division {
        id
        name
        nickname
        description
        discipline
        isSearchable
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      organizationId
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      jumpHeight
      minNumberEntries
      maxNumberEntries
      entryFee
      prizeMoney
      entryFeePerClass
      prizeMoneyPerClass
      createdBy
      lastUpdatedBy
      displayOrder
      createdOn
      updatedOn
      organizationDivisionPointTableId
      organizationDivisionPrizeMoneyTableId
    }
  }
`;
export const deleteOrganizationDivision = /* GraphQL */ `
  mutation DeleteOrganizationDivision(
    $input: DeleteOrganizationDivisionInput!
    $condition: ModelOrganizationDivisionConditionInput
  ) {
    deleteOrganizationDivision(input: $input, condition: $condition) {
      id
      name
      number
      divisionId
      division {
        id
        name
        nickname
        description
        discipline
        isSearchable
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      organizationId
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      jumpHeight
      minNumberEntries
      maxNumberEntries
      entryFee
      prizeMoney
      entryFeePerClass
      prizeMoneyPerClass
      createdBy
      lastUpdatedBy
      displayOrder
      createdOn
      updatedOn
      organizationDivisionPointTableId
      organizationDivisionPrizeMoneyTableId
    }
  }
`;
export const createOrganizationStallSet = /* GraphQL */ `
  mutation CreateOrganizationStallSet(
    $input: CreateOrganizationStallSetInput!
    $condition: ModelOrganizationStallSetConditionInput
  ) {
    createOrganizationStallSet(input: $input, condition: $condition) {
      id
      organizationId
      name
      location
      available
      createdOn
      updatedOn
    }
  }
`;
export const updateOrganizationStallSet = /* GraphQL */ `
  mutation UpdateOrganizationStallSet(
    $input: UpdateOrganizationStallSetInput!
    $condition: ModelOrganizationStallSetConditionInput
  ) {
    updateOrganizationStallSet(input: $input, condition: $condition) {
      id
      organizationId
      name
      location
      available
      createdOn
      updatedOn
    }
  }
`;
export const deleteOrganizationStallSet = /* GraphQL */ `
  mutation DeleteOrganizationStallSet(
    $input: DeleteOrganizationStallSetInput!
    $condition: ModelOrganizationStallSetConditionInput
  ) {
    deleteOrganizationStallSet(input: $input, condition: $condition) {
      id
      organizationId
      name
      location
      available
      createdOn
      updatedOn
    }
  }
`;
export const createOrganizationStallType = /* GraphQL */ `
  mutation CreateOrganizationStallType(
    $input: CreateOrganizationStallTypeInput!
    $condition: ModelOrganizationStallTypeConditionInput
  ) {
    createOrganizationStallType(input: $input, condition: $condition) {
      id
      organizationId
      name
      description
      type
      pricePerStall
      taxA
      taxB
      taxRateIdA
      taxRateIdB
      taxRateA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxRateB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
    }
  }
`;
export const updateOrganizationStallType = /* GraphQL */ `
  mutation UpdateOrganizationStallType(
    $input: UpdateOrganizationStallTypeInput!
    $condition: ModelOrganizationStallTypeConditionInput
  ) {
    updateOrganizationStallType(input: $input, condition: $condition) {
      id
      organizationId
      name
      description
      type
      pricePerStall
      taxA
      taxB
      taxRateIdA
      taxRateIdB
      taxRateA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxRateB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
    }
  }
`;
export const deleteOrganizationStallType = /* GraphQL */ `
  mutation DeleteOrganizationStallType(
    $input: DeleteOrganizationStallTypeInput!
    $condition: ModelOrganizationStallTypeConditionInput
  ) {
    deleteOrganizationStallType(input: $input, condition: $condition) {
      id
      organizationId
      name
      description
      type
      pricePerStall
      taxA
      taxB
      taxRateIdA
      taxRateIdB
      taxRateA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxRateB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
    }
  }
`;
export const createOwner = /* GraphQL */ `
  mutation CreateOwner(
    $input: CreateOwnerInput!
    $condition: ModelOwnerConditionInput
  ) {
    createOwner(input: $input, condition: $condition) {
      id
      name
      type
      location
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      personId
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      barnId
      createdBy
      createdOn
      updatedOn
      ownerContactId
    }
  }
`;
export const updateOwner = /* GraphQL */ `
  mutation UpdateOwner(
    $input: UpdateOwnerInput!
    $condition: ModelOwnerConditionInput
  ) {
    updateOwner(input: $input, condition: $condition) {
      id
      name
      type
      location
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      personId
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      barnId
      createdBy
      createdOn
      updatedOn
      ownerContactId
    }
  }
`;
export const deleteOwner = /* GraphQL */ `
  mutation DeleteOwner(
    $input: DeleteOwnerInput!
    $condition: ModelOwnerConditionInput
  ) {
    deleteOwner(input: $input, condition: $condition) {
      id
      name
      type
      location
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      personId
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      barnId
      createdBy
      createdOn
      updatedOn
      ownerContactId
    }
  }
`;
export const createPayer = /* GraphQL */ `
  mutation CreatePayer(
    $input: CreatePayerInput!
    $condition: ModelPayerConditionInput
  ) {
    createPayer(input: $input, condition: $condition) {
      id
      name
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      personId
      paymentMethod {
        id
        personId
        organizationId
        type
        stripeId
        altId
        value
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      payerContactId
      payerPaymentMethodId
    }
  }
`;
export const updatePayer = /* GraphQL */ `
  mutation UpdatePayer(
    $input: UpdatePayerInput!
    $condition: ModelPayerConditionInput
  ) {
    updatePayer(input: $input, condition: $condition) {
      id
      name
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      personId
      paymentMethod {
        id
        personId
        organizationId
        type
        stripeId
        altId
        value
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      payerContactId
      payerPaymentMethodId
    }
  }
`;
export const deletePayer = /* GraphQL */ `
  mutation DeletePayer(
    $input: DeletePayerInput!
    $condition: ModelPayerConditionInput
  ) {
    deletePayer(input: $input, condition: $condition) {
      id
      name
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      personId
      paymentMethod {
        id
        personId
        organizationId
        type
        stripeId
        altId
        value
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      payerContactId
      payerPaymentMethodId
    }
  }
`;
export const createPaymentMethod = /* GraphQL */ `
  mutation CreatePaymentMethod(
    $input: CreatePaymentMethodInput!
    $condition: ModelPaymentMethodConditionInput
  ) {
    createPaymentMethod(input: $input, condition: $condition) {
      id
      personId
      organizationId
      type
      stripeId
      altId
      value
      createdOn
      updatedOn
    }
  }
`;
export const updatePaymentMethod = /* GraphQL */ `
  mutation UpdatePaymentMethod(
    $input: UpdatePaymentMethodInput!
    $condition: ModelPaymentMethodConditionInput
  ) {
    updatePaymentMethod(input: $input, condition: $condition) {
      id
      personId
      organizationId
      type
      stripeId
      altId
      value
      createdOn
      updatedOn
    }
  }
`;
export const deletePaymentMethod = /* GraphQL */ `
  mutation DeletePaymentMethod(
    $input: DeletePaymentMethodInput!
    $condition: ModelPaymentMethodConditionInput
  ) {
    deletePaymentMethod(input: $input, condition: $condition) {
      id
      personId
      organizationId
      type
      stripeId
      altId
      value
      createdOn
      updatedOn
    }
  }
`;
export const createPerson = /* GraphQL */ `
  mutation CreatePerson(
    $input: CreatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    createPerson(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      isVerified
      roles
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      personalInformationId
      personalInformation {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        dateOfBirth
        gender
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      pictureURL
      picture {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      disciplines
      stripeId
      media {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const updatePerson = /* GraphQL */ `
  mutation UpdatePerson(
    $input: UpdatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    updatePerson(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      isVerified
      roles
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      personalInformationId
      personalInformation {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        dateOfBirth
        gender
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      pictureURL
      picture {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      disciplines
      stripeId
      media {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const deletePerson = /* GraphQL */ `
  mutation DeletePerson(
    $input: DeletePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    deletePerson(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      isVerified
      roles
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      personalInformationId
      personalInformation {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        dateOfBirth
        gender
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      pictureURL
      picture {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      disciplines
      stripeId
      media {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const createPersonName = /* GraphQL */ `
  mutation CreatePersonName(
    $input: CreatePersonNameInput!
    $condition: ModelPersonNameConditionInput
  ) {
    createPersonName(input: $input, condition: $condition) {
      id
      personId
      title
      firstName
      middleName
      maidenName
      lastName
      suffix
      legalName
      displayName
      createdAt
      updatedAt
    }
  }
`;
export const updatePersonName = /* GraphQL */ `
  mutation UpdatePersonName(
    $input: UpdatePersonNameInput!
    $condition: ModelPersonNameConditionInput
  ) {
    updatePersonName(input: $input, condition: $condition) {
      id
      personId
      title
      firstName
      middleName
      maidenName
      lastName
      suffix
      legalName
      displayName
      createdAt
      updatedAt
    }
  }
`;
export const deletePersonName = /* GraphQL */ `
  mutation DeletePersonName(
    $input: DeletePersonNameInput!
    $condition: ModelPersonNameConditionInput
  ) {
    deletePersonName(input: $input, condition: $condition) {
      id
      personId
      title
      firstName
      middleName
      maidenName
      lastName
      suffix
      legalName
      displayName
      createdAt
      updatedAt
    }
  }
`;
export const createPersonalInformation = /* GraphQL */ `
  mutation CreatePersonalInformation(
    $input: CreatePersonalInformationInput!
    $condition: ModelPersonalInformationConditionInput
  ) {
    createPersonalInformation(input: $input, condition: $condition) {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      dateOfBirth
      gender
      contactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePersonalInformation = /* GraphQL */ `
  mutation UpdatePersonalInformation(
    $input: UpdatePersonalInformationInput!
    $condition: ModelPersonalInformationConditionInput
  ) {
    updatePersonalInformation(input: $input, condition: $condition) {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      dateOfBirth
      gender
      contactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePersonalInformation = /* GraphQL */ `
  mutation DeletePersonalInformation(
    $input: DeletePersonalInformationInput!
    $condition: ModelPersonalInformationConditionInput
  ) {
    deletePersonalInformation(input: $input, condition: $condition) {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      dateOfBirth
      gender
      contactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPointTable = /* GraphQL */ `
  mutation CreatePointTable(
    $input: CreatePointTableInput!
    $condition: ModelPointTableConditionInput
  ) {
    createPointTable(input: $input, condition: $condition) {
      id
      name
      description
      displayOrder
      organizationId
      discipline
      isAddValueToRegularFromEntryCount
      isAddValueToChampionshipFromEntryCount
      isUseChampionRule
      isUseReserveRule
      tiers {
        lowEntryCount
        highEntryCount
        first
        second
        third
        fourth
        fifth
        sixth
        seventh
        eighth
        ninth
        tenth
        eleventh
        twelfth
        champion
        reserve
      }
      numberEntriesToReceiveSpecialClassPoints
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventResultPointTablesId
    }
  }
`;
export const updatePointTable = /* GraphQL */ `
  mutation UpdatePointTable(
    $input: UpdatePointTableInput!
    $condition: ModelPointTableConditionInput
  ) {
    updatePointTable(input: $input, condition: $condition) {
      id
      name
      description
      displayOrder
      organizationId
      discipline
      isAddValueToRegularFromEntryCount
      isAddValueToChampionshipFromEntryCount
      isUseChampionRule
      isUseReserveRule
      tiers {
        lowEntryCount
        highEntryCount
        first
        second
        third
        fourth
        fifth
        sixth
        seventh
        eighth
        ninth
        tenth
        eleventh
        twelfth
        champion
        reserve
      }
      numberEntriesToReceiveSpecialClassPoints
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventResultPointTablesId
    }
  }
`;
export const deletePointTable = /* GraphQL */ `
  mutation DeletePointTable(
    $input: DeletePointTableInput!
    $condition: ModelPointTableConditionInput
  ) {
    deletePointTable(input: $input, condition: $condition) {
      id
      name
      description
      displayOrder
      organizationId
      discipline
      isAddValueToRegularFromEntryCount
      isAddValueToChampionshipFromEntryCount
      isUseChampionRule
      isUseReserveRule
      tiers {
        lowEntryCount
        highEntryCount
        first
        second
        third
        fourth
        fifth
        sixth
        seventh
        eighth
        ninth
        tenth
        eleventh
        twelfth
        champion
        reserve
      }
      numberEntriesToReceiveSpecialClassPoints
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventResultPointTablesId
    }
  }
`;
export const createPoll = /* GraphQL */ `
  mutation CreatePoll(
    $input: CreatePollInput!
    $condition: ModelPollConditionInput
  ) {
    createPoll(input: $input, condition: $condition) {
      id
      title
      description
      responses {
        items {
          id
          pollId
          personId
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePoll = /* GraphQL */ `
  mutation UpdatePoll(
    $input: UpdatePollInput!
    $condition: ModelPollConditionInput
  ) {
    updatePoll(input: $input, condition: $condition) {
      id
      title
      description
      responses {
        items {
          id
          pollId
          personId
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePoll = /* GraphQL */ `
  mutation DeletePoll(
    $input: DeletePollInput!
    $condition: ModelPollConditionInput
  ) {
    deletePoll(input: $input, condition: $condition) {
      id
      title
      description
      responses {
        items {
          id
          pollId
          personId
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      title
      status
      rating
      content
      organizationId
      createdBy
      createdOn
      updatedOn
      createdAt
      updatedAt
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      title
      status
      rating
      content
      organizationId
      createdBy
      createdOn
      updatedOn
      createdAt
      updatedAt
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      title
      status
      rating
      content
      organizationId
      createdBy
      createdOn
      updatedOn
      createdAt
      updatedAt
    }
  }
`;
export const createPrizeMoneyTable = /* GraphQL */ `
  mutation CreatePrizeMoneyTable(
    $input: CreatePrizeMoneyTableInput!
    $condition: ModelPrizeMoneyTableConditionInput
  ) {
    createPrizeMoneyTable(input: $input, condition: $condition) {
      id
      name
      description
      displayOrder
      organizationId
      discipline
      isPercentage
      totalPot
      tiers {
        first
        second
        third
        fourth
        fifth
        sixth
        seventh
        eighth
        ninth
        tenth
        eleventh
        twelfth
        champion
        reserve
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const updatePrizeMoneyTable = /* GraphQL */ `
  mutation UpdatePrizeMoneyTable(
    $input: UpdatePrizeMoneyTableInput!
    $condition: ModelPrizeMoneyTableConditionInput
  ) {
    updatePrizeMoneyTable(input: $input, condition: $condition) {
      id
      name
      description
      displayOrder
      organizationId
      discipline
      isPercentage
      totalPot
      tiers {
        first
        second
        third
        fourth
        fifth
        sixth
        seventh
        eighth
        ninth
        tenth
        eleventh
        twelfth
        champion
        reserve
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const deletePrizeMoneyTable = /* GraphQL */ `
  mutation DeletePrizeMoneyTable(
    $input: DeletePrizeMoneyTableInput!
    $condition: ModelPrizeMoneyTableConditionInput
  ) {
    deletePrizeMoneyTable(input: $input, condition: $condition) {
      id
      name
      description
      displayOrder
      organizationId
      discipline
      isPercentage
      totalPot
      tiers {
        first
        second
        third
        fourth
        fifth
        sixth
        seventh
        eighth
        ninth
        tenth
        eleventh
        twelfth
        champion
        reserve
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const createPromoCode = /* GraphQL */ `
  mutation CreatePromoCode(
    $input: CreatePromoCodeInput!
    $condition: ModelPromoCodeConditionInput
  ) {
    createPromoCode(input: $input, condition: $condition) {
      id
      name
      stripeId
      isvalid
      isfundraiser
      ispercentdiscount
      isfixeddiscount
      percent
      fixed
      minamount
      startdate
      enddate
      createdOn
      updatedOn
    }
  }
`;
export const updatePromoCode = /* GraphQL */ `
  mutation UpdatePromoCode(
    $input: UpdatePromoCodeInput!
    $condition: ModelPromoCodeConditionInput
  ) {
    updatePromoCode(input: $input, condition: $condition) {
      id
      name
      stripeId
      isvalid
      isfundraiser
      ispercentdiscount
      isfixeddiscount
      percent
      fixed
      minamount
      startdate
      enddate
      createdOn
      updatedOn
    }
  }
`;
export const deletePromoCode = /* GraphQL */ `
  mutation DeletePromoCode(
    $input: DeletePromoCodeInput!
    $condition: ModelPromoCodeConditionInput
  ) {
    deletePromoCode(input: $input, condition: $condition) {
      id
      name
      stripeId
      isvalid
      isfundraiser
      ispercentdiscount
      isfixeddiscount
      percent
      fixed
      minamount
      startdate
      enddate
      createdOn
      updatedOn
    }
  }
`;
export const createResponse = /* GraphQL */ `
  mutation CreateResponse(
    $input: CreateResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    createResponse(input: $input, condition: $condition) {
      id
      pollId
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateResponse = /* GraphQL */ `
  mutation UpdateResponse(
    $input: UpdateResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    updateResponse(input: $input, condition: $condition) {
      id
      pollId
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteResponse = /* GraphQL */ `
  mutation DeleteResponse(
    $input: DeleteResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    deleteResponse(input: $input, condition: $condition) {
      id
      pollId
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      value
      createdAt
      updatedAt
    }
  }
`;
export const createRider = /* GraphQL */ `
  mutation CreateRider(
    $input: CreateRiderInput!
    $condition: ModelRiderConditionInput
  ) {
    createRider(input: $input, condition: $condition) {
      id
      name
      location
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      personId
      age
      birthdate
      isProfessional
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      createdBy
      createdOn
      updatedOn
      createdAt
      updatedAt
      riderContactId
    }
  }
`;
export const updateRider = /* GraphQL */ `
  mutation UpdateRider(
    $input: UpdateRiderInput!
    $condition: ModelRiderConditionInput
  ) {
    updateRider(input: $input, condition: $condition) {
      id
      name
      location
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      personId
      age
      birthdate
      isProfessional
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      createdBy
      createdOn
      updatedOn
      createdAt
      updatedAt
      riderContactId
    }
  }
`;
export const deleteRider = /* GraphQL */ `
  mutation DeleteRider(
    $input: DeleteRiderInput!
    $condition: ModelRiderConditionInput
  ) {
    deleteRider(input: $input, condition: $condition) {
      id
      name
      location
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      personId
      age
      birthdate
      isProfessional
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      createdBy
      createdOn
      updatedOn
      createdAt
      updatedAt
      riderContactId
    }
  }
`;
export const createRing = /* GraphQL */ `
  mutation CreateRing(
    $input: CreateRingInput!
    $condition: ModelRingConditionInput
  ) {
    createRing(input: $input, condition: $condition) {
      id
      name
      displayOrder
      organizationId
      createdOn
      updatedOn
    }
  }
`;
export const updateRing = /* GraphQL */ `
  mutation UpdateRing(
    $input: UpdateRingInput!
    $condition: ModelRingConditionInput
  ) {
    updateRing(input: $input, condition: $condition) {
      id
      name
      displayOrder
      organizationId
      createdOn
      updatedOn
    }
  }
`;
export const deleteRing = /* GraphQL */ `
  mutation DeleteRing(
    $input: DeleteRingInput!
    $condition: ModelRingConditionInput
  ) {
    deleteRing(input: $input, condition: $condition) {
      id
      name
      displayOrder
      organizationId
      createdOn
      updatedOn
    }
  }
`;
export const createScheduleItem = /* GraphQL */ `
  mutation CreateScheduleItem(
    $input: CreateScheduleItemInput!
    $condition: ModelScheduleItemConditionInput
  ) {
    createScheduleItem(input: $input, condition: $condition) {
      id
      eventId
      eventRingId
      eventDayId
      eventRing {
        id
        name
        eventId
        ringId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        ring {
          id
          name
          displayOrder
          organizationId
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
      }
      eventDay {
        id
        eventId
        awsdate
        dayofweek
        date
        month
        year
        createdOn
        updatedOn
      }
      displayOrder
      classId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      breakId
      eventBreak {
        id
        eventId
        name
        time
        isDisplayedOnSchedule
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      startTime
      createdOn
      updatedOn
    }
  }
`;
export const updateScheduleItem = /* GraphQL */ `
  mutation UpdateScheduleItem(
    $input: UpdateScheduleItemInput!
    $condition: ModelScheduleItemConditionInput
  ) {
    updateScheduleItem(input: $input, condition: $condition) {
      id
      eventId
      eventRingId
      eventDayId
      eventRing {
        id
        name
        eventId
        ringId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        ring {
          id
          name
          displayOrder
          organizationId
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
      }
      eventDay {
        id
        eventId
        awsdate
        dayofweek
        date
        month
        year
        createdOn
        updatedOn
      }
      displayOrder
      classId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      breakId
      eventBreak {
        id
        eventId
        name
        time
        isDisplayedOnSchedule
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      startTime
      createdOn
      updatedOn
    }
  }
`;
export const deleteScheduleItem = /* GraphQL */ `
  mutation DeleteScheduleItem(
    $input: DeleteScheduleItemInput!
    $condition: ModelScheduleItemConditionInput
  ) {
    deleteScheduleItem(input: $input, condition: $condition) {
      id
      eventId
      eventRingId
      eventDayId
      eventRing {
        id
        name
        eventId
        ringId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        ring {
          id
          name
          displayOrder
          organizationId
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
      }
      eventDay {
        id
        eventId
        awsdate
        dayofweek
        date
        month
        year
        createdOn
        updatedOn
      }
      displayOrder
      classId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      breakId
      eventBreak {
        id
        eventId
        name
        time
        isDisplayedOnSchedule
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      startTime
      createdOn
      updatedOn
    }
  }
`;
export const createSeason = /* GraphQL */ `
  mutation CreateSeason(
    $input: CreateSeasonInput!
    $condition: ModelSeasonConditionInput
  ) {
    createSeason(input: $input, condition: $condition) {
      id
      name
      description
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      organizationMembershipTypes {
        items {
          id
          organizationMembershipTypeID
          seasonID
          createdAt
          updatedAt
        }
        nextToken
      }
      isActive
      startDate
      endDate
      disciplines
      createdOn
      updatedOn
      createdAt
      updatedAt
      seasonOrganizationId
    }
  }
`;
export const updateSeason = /* GraphQL */ `
  mutation UpdateSeason(
    $input: UpdateSeasonInput!
    $condition: ModelSeasonConditionInput
  ) {
    updateSeason(input: $input, condition: $condition) {
      id
      name
      description
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      organizationMembershipTypes {
        items {
          id
          organizationMembershipTypeID
          seasonID
          createdAt
          updatedAt
        }
        nextToken
      }
      isActive
      startDate
      endDate
      disciplines
      createdOn
      updatedOn
      createdAt
      updatedAt
      seasonOrganizationId
    }
  }
`;
export const deleteSeason = /* GraphQL */ `
  mutation DeleteSeason(
    $input: DeleteSeasonInput!
    $condition: ModelSeasonConditionInput
  ) {
    deleteSeason(input: $input, condition: $condition) {
      id
      name
      description
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      organizationMembershipTypes {
        items {
          id
          organizationMembershipTypeID
          seasonID
          createdAt
          updatedAt
        }
        nextToken
      }
      isActive
      startDate
      endDate
      disciplines
      createdOn
      updatedOn
      createdAt
      updatedAt
      seasonOrganizationId
    }
  }
`;
export const createStablingRequest = /* GraphQL */ `
  mutation CreateStablingRequest(
    $input: CreateStablingRequestInput!
    $condition: ModelStablingRequestConditionInput
  ) {
    createStablingRequest(input: $input, condition: $condition) {
      id
      eventId
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      arrivalDateTime
      departureDateTime
      stallTypeId
      stallType {
        id
        eventId
        organizationId
        organizationStallTypeId
        name
        description
        type
        pricePerStall
        taxPerStall
        createdOn
        updatedOn
      }
      quantityNeeded
      basePrice
      taxA
      taxB
      totalPrice
      status
      statusNote
      requestNote
      stallSetId
      stallSet {
        id
        eventId
        organizationId
        organizationStallSetId
        name
        location
        available
        totalAvailable
        used
        totalUsed
        createdOn
        updatedOn
      }
      stallLocation
      stallNumbers
      payerId
      payerName
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      splitAcrossEntries
      entries {
        items {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        nextToken
      }
      entryIds
      submittedEntry
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const updateStablingRequest = /* GraphQL */ `
  mutation UpdateStablingRequest(
    $input: UpdateStablingRequestInput!
    $condition: ModelStablingRequestConditionInput
  ) {
    updateStablingRequest(input: $input, condition: $condition) {
      id
      eventId
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      arrivalDateTime
      departureDateTime
      stallTypeId
      stallType {
        id
        eventId
        organizationId
        organizationStallTypeId
        name
        description
        type
        pricePerStall
        taxPerStall
        createdOn
        updatedOn
      }
      quantityNeeded
      basePrice
      taxA
      taxB
      totalPrice
      status
      statusNote
      requestNote
      stallSetId
      stallSet {
        id
        eventId
        organizationId
        organizationStallSetId
        name
        location
        available
        totalAvailable
        used
        totalUsed
        createdOn
        updatedOn
      }
      stallLocation
      stallNumbers
      payerId
      payerName
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      splitAcrossEntries
      entries {
        items {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        nextToken
      }
      entryIds
      submittedEntry
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const deleteStablingRequest = /* GraphQL */ `
  mutation DeleteStablingRequest(
    $input: DeleteStablingRequestInput!
    $condition: ModelStablingRequestConditionInput
  ) {
    deleteStablingRequest(input: $input, condition: $condition) {
      id
      eventId
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      arrivalDateTime
      departureDateTime
      stallTypeId
      stallType {
        id
        eventId
        organizationId
        organizationStallTypeId
        name
        description
        type
        pricePerStall
        taxPerStall
        createdOn
        updatedOn
      }
      quantityNeeded
      basePrice
      taxA
      taxB
      totalPrice
      status
      statusNote
      requestNote
      stallSetId
      stallSet {
        id
        eventId
        organizationId
        organizationStallSetId
        name
        location
        available
        totalAvailable
        used
        totalUsed
        createdOn
        updatedOn
      }
      stallLocation
      stallNumbers
      payerId
      payerName
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      splitAcrossEntries
      entries {
        items {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        nextToken
      }
      entryIds
      submittedEntry
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const createBeddingRequest = /* GraphQL */ `
  mutation CreateBeddingRequest(
    $input: CreateBeddingRequestInput!
    $condition: ModelBeddingRequestConditionInput
  ) {
    createBeddingRequest(input: $input, condition: $condition) {
      id
      eventId
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      arrivalDateTime
      dateTimeNeeded
      deliveryInfo
      beddingType
      quantityNeeded
      basePrice
      taxA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      totalPrice
      status
      statusNote
      payerId
      payerName
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      splitAcrossEntries
      entries {
        items {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        nextToken
      }
      entryIds
      submittedEntry
      lastUpdatedBy
      createdOn
      updatedOn
      beddingRequestTaxAId
      beddingRequestTaxBId
    }
  }
`;
export const updateBeddingRequest = /* GraphQL */ `
  mutation UpdateBeddingRequest(
    $input: UpdateBeddingRequestInput!
    $condition: ModelBeddingRequestConditionInput
  ) {
    updateBeddingRequest(input: $input, condition: $condition) {
      id
      eventId
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      arrivalDateTime
      dateTimeNeeded
      deliveryInfo
      beddingType
      quantityNeeded
      basePrice
      taxA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      totalPrice
      status
      statusNote
      payerId
      payerName
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      splitAcrossEntries
      entries {
        items {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        nextToken
      }
      entryIds
      submittedEntry
      lastUpdatedBy
      createdOn
      updatedOn
      beddingRequestTaxAId
      beddingRequestTaxBId
    }
  }
`;
export const deleteBeddingRequest = /* GraphQL */ `
  mutation DeleteBeddingRequest(
    $input: DeleteBeddingRequestInput!
    $condition: ModelBeddingRequestConditionInput
  ) {
    deleteBeddingRequest(input: $input, condition: $condition) {
      id
      eventId
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      arrivalDateTime
      dateTimeNeeded
      deliveryInfo
      beddingType
      quantityNeeded
      basePrice
      taxA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      totalPrice
      status
      statusNote
      payerId
      payerName
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      splitAcrossEntries
      entries {
        items {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        nextToken
      }
      entryIds
      submittedEntry
      lastUpdatedBy
      createdOn
      updatedOn
      beddingRequestTaxAId
      beddingRequestTaxBId
    }
  }
`;
export const createSubmission = /* GraphQL */ `
  mutation CreateSubmission(
    $input: CreateSubmissionInput!
    $condition: ModelSubmissionConditionInput
  ) {
    createSubmission(input: $input, condition: $condition) {
      id
      title
      description
      video {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      videoLength
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      riderId
      personId
      clinicians {
        id
        name
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        judgeId
        contactId
        rating {
          type
          license
        }
        usefNumber
        ecNumber
        price
        status
        paymentChoice {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        paymentVerified
        stripeAccountId
        totalEarned
        location
        disciplines
        experience
        personal
        preferences
        hasIHSAExperience
        hasIEAExperience
        createdOn
        updatedOn
        clinicianPaymentChoiceId
      }
      totalPrice
      status
      submissionFeedback {
        items {
          id
          type
          submissionId
          feedbackId
          personId
          riderId
          clinicianId
          submissionStatus
          feedbackStatus
          clinicianAmount
          declineReason
          permissionToPost
          dateSubmitted
          dateCompleted
          createdOn
          updatedOn
          submissionFeedbackClinicianId
        }
        nextToken
      }
      createdOn
      updatedOn
    }
  }
`;
export const updateSubmission = /* GraphQL */ `
  mutation UpdateSubmission(
    $input: UpdateSubmissionInput!
    $condition: ModelSubmissionConditionInput
  ) {
    updateSubmission(input: $input, condition: $condition) {
      id
      title
      description
      video {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      videoLength
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      riderId
      personId
      clinicians {
        id
        name
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        judgeId
        contactId
        rating {
          type
          license
        }
        usefNumber
        ecNumber
        price
        status
        paymentChoice {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        paymentVerified
        stripeAccountId
        totalEarned
        location
        disciplines
        experience
        personal
        preferences
        hasIHSAExperience
        hasIEAExperience
        createdOn
        updatedOn
        clinicianPaymentChoiceId
      }
      totalPrice
      status
      submissionFeedback {
        items {
          id
          type
          submissionId
          feedbackId
          personId
          riderId
          clinicianId
          submissionStatus
          feedbackStatus
          clinicianAmount
          declineReason
          permissionToPost
          dateSubmitted
          dateCompleted
          createdOn
          updatedOn
          submissionFeedbackClinicianId
        }
        nextToken
      }
      createdOn
      updatedOn
    }
  }
`;
export const deleteSubmission = /* GraphQL */ `
  mutation DeleteSubmission(
    $input: DeleteSubmissionInput!
    $condition: ModelSubmissionConditionInput
  ) {
    deleteSubmission(input: $input, condition: $condition) {
      id
      title
      description
      video {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      videoLength
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      riderId
      personId
      clinicians {
        id
        name
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        judgeId
        contactId
        rating {
          type
          license
        }
        usefNumber
        ecNumber
        price
        status
        paymentChoice {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        paymentVerified
        stripeAccountId
        totalEarned
        location
        disciplines
        experience
        personal
        preferences
        hasIHSAExperience
        hasIEAExperience
        createdOn
        updatedOn
        clinicianPaymentChoiceId
      }
      totalPrice
      status
      submissionFeedback {
        items {
          id
          type
          submissionId
          feedbackId
          personId
          riderId
          clinicianId
          submissionStatus
          feedbackStatus
          clinicianAmount
          declineReason
          permissionToPost
          dateSubmitted
          dateCompleted
          createdOn
          updatedOn
          submissionFeedbackClinicianId
        }
        nextToken
      }
      createdOn
      updatedOn
    }
  }
`;
export const createSubmissionFeedback = /* GraphQL */ `
  mutation CreateSubmissionFeedback(
    $input: CreateSubmissionFeedbackInput!
    $condition: ModelSubmissionFeedbackConditionInput
  ) {
    createSubmissionFeedback(input: $input, condition: $condition) {
      id
      type
      submissionId
      submission {
        id
        title
        description
        video {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        videoLength
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        riderId
        personId
        clinicians {
          id
          name
          personId
          judgeId
          contactId
          usefNumber
          ecNumber
          price
          status
          paymentVerified
          stripeAccountId
          totalEarned
          location
          disciplines
          experience
          personal
          preferences
          hasIHSAExperience
          hasIEAExperience
          createdOn
          updatedOn
          clinicianPaymentChoiceId
        }
        totalPrice
        status
        submissionFeedback {
          nextToken
        }
        createdOn
        updatedOn
      }
      feedback {
        id
        clinicianId
        personId
        technical
        style
        turnout
        general
        score
        status
        submissionFeedback {
          id
          type
          submissionId
          feedbackId
          personId
          riderId
          clinicianId
          submissionStatus
          feedbackStatus
          clinicianAmount
          declineReason
          permissionToPost
          dateSubmitted
          dateCompleted
          createdOn
          updatedOn
          submissionFeedbackClinicianId
        }
        createdOn
        updatedOn
        feedbackSubmissionFeedbackId
      }
      feedbackId
      personId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      riderId
      clinician {
        id
        name
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        judgeId
        contactId
        rating {
          type
          license
        }
        usefNumber
        ecNumber
        price
        status
        paymentChoice {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        paymentVerified
        stripeAccountId
        totalEarned
        location
        disciplines
        experience
        personal
        preferences
        hasIHSAExperience
        hasIEAExperience
        createdOn
        updatedOn
        clinicianPaymentChoiceId
      }
      clinicianId
      submissionStatus
      feedbackStatus
      clinicianAmount
      declineReason
      permissionToPost
      dateSubmitted
      dateCompleted
      createdOn
      updatedOn
      submissionFeedbackClinicianId
    }
  }
`;
export const updateSubmissionFeedback = /* GraphQL */ `
  mutation UpdateSubmissionFeedback(
    $input: UpdateSubmissionFeedbackInput!
    $condition: ModelSubmissionFeedbackConditionInput
  ) {
    updateSubmissionFeedback(input: $input, condition: $condition) {
      id
      type
      submissionId
      submission {
        id
        title
        description
        video {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        videoLength
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        riderId
        personId
        clinicians {
          id
          name
          personId
          judgeId
          contactId
          usefNumber
          ecNumber
          price
          status
          paymentVerified
          stripeAccountId
          totalEarned
          location
          disciplines
          experience
          personal
          preferences
          hasIHSAExperience
          hasIEAExperience
          createdOn
          updatedOn
          clinicianPaymentChoiceId
        }
        totalPrice
        status
        submissionFeedback {
          nextToken
        }
        createdOn
        updatedOn
      }
      feedback {
        id
        clinicianId
        personId
        technical
        style
        turnout
        general
        score
        status
        submissionFeedback {
          id
          type
          submissionId
          feedbackId
          personId
          riderId
          clinicianId
          submissionStatus
          feedbackStatus
          clinicianAmount
          declineReason
          permissionToPost
          dateSubmitted
          dateCompleted
          createdOn
          updatedOn
          submissionFeedbackClinicianId
        }
        createdOn
        updatedOn
        feedbackSubmissionFeedbackId
      }
      feedbackId
      personId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      riderId
      clinician {
        id
        name
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        judgeId
        contactId
        rating {
          type
          license
        }
        usefNumber
        ecNumber
        price
        status
        paymentChoice {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        paymentVerified
        stripeAccountId
        totalEarned
        location
        disciplines
        experience
        personal
        preferences
        hasIHSAExperience
        hasIEAExperience
        createdOn
        updatedOn
        clinicianPaymentChoiceId
      }
      clinicianId
      submissionStatus
      feedbackStatus
      clinicianAmount
      declineReason
      permissionToPost
      dateSubmitted
      dateCompleted
      createdOn
      updatedOn
      submissionFeedbackClinicianId
    }
  }
`;
export const deleteSubmissionFeedback = /* GraphQL */ `
  mutation DeleteSubmissionFeedback(
    $input: DeleteSubmissionFeedbackInput!
    $condition: ModelSubmissionFeedbackConditionInput
  ) {
    deleteSubmissionFeedback(input: $input, condition: $condition) {
      id
      type
      submissionId
      submission {
        id
        title
        description
        video {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        videoLength
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        riderId
        personId
        clinicians {
          id
          name
          personId
          judgeId
          contactId
          usefNumber
          ecNumber
          price
          status
          paymentVerified
          stripeAccountId
          totalEarned
          location
          disciplines
          experience
          personal
          preferences
          hasIHSAExperience
          hasIEAExperience
          createdOn
          updatedOn
          clinicianPaymentChoiceId
        }
        totalPrice
        status
        submissionFeedback {
          nextToken
        }
        createdOn
        updatedOn
      }
      feedback {
        id
        clinicianId
        personId
        technical
        style
        turnout
        general
        score
        status
        submissionFeedback {
          id
          type
          submissionId
          feedbackId
          personId
          riderId
          clinicianId
          submissionStatus
          feedbackStatus
          clinicianAmount
          declineReason
          permissionToPost
          dateSubmitted
          dateCompleted
          createdOn
          updatedOn
          submissionFeedbackClinicianId
        }
        createdOn
        updatedOn
        feedbackSubmissionFeedbackId
      }
      feedbackId
      personId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      riderId
      clinician {
        id
        name
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        judgeId
        contactId
        rating {
          type
          license
        }
        usefNumber
        ecNumber
        price
        status
        paymentChoice {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        paymentVerified
        stripeAccountId
        totalEarned
        location
        disciplines
        experience
        personal
        preferences
        hasIHSAExperience
        hasIEAExperience
        createdOn
        updatedOn
        clinicianPaymentChoiceId
      }
      clinicianId
      submissionStatus
      feedbackStatus
      clinicianAmount
      declineReason
      permissionToPost
      dateSubmitted
      dateCompleted
      createdOn
      updatedOn
      submissionFeedbackClinicianId
    }
  }
`;
export const createTaxRate = /* GraphQL */ `
  mutation CreateTaxRate(
    $input: CreateTaxRateInput!
    $condition: ModelTaxRateConditionInput
  ) {
    createTaxRate(input: $input, condition: $condition) {
      id
      name
      createdBy
      organizationId
      stripeTaxRateId
      percentage
      createdOn
      updatedOn
    }
  }
`;
export const updateTaxRate = /* GraphQL */ `
  mutation UpdateTaxRate(
    $input: UpdateTaxRateInput!
    $condition: ModelTaxRateConditionInput
  ) {
    updateTaxRate(input: $input, condition: $condition) {
      id
      name
      createdBy
      organizationId
      stripeTaxRateId
      percentage
      createdOn
      updatedOn
    }
  }
`;
export const deleteTaxRate = /* GraphQL */ `
  mutation DeleteTaxRate(
    $input: DeleteTaxRateInput!
    $condition: ModelTaxRateConditionInput
  ) {
    deleteTaxRate(input: $input, condition: $condition) {
      id
      name
      createdBy
      organizationId
      stripeTaxRateId
      percentage
      createdOn
      updatedOn
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      name
      nickname
      description
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      location {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      members {
        items {
          id
          teamId
          personId
          status
          role
          permissionLevel
          creatorId
          createdOn
          updatedOn
          teamMemberCreatorId
        }
        nextToken
      }
      creatorId
      creator {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      teamContactId
      teamLocationId
      teamCreatorId
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      nickname
      description
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      location {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      members {
        items {
          id
          teamId
          personId
          status
          role
          permissionLevel
          creatorId
          createdOn
          updatedOn
          teamMemberCreatorId
        }
        nextToken
      }
      creatorId
      creator {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      teamContactId
      teamLocationId
      teamCreatorId
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      name
      nickname
      description
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      location {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      members {
        items {
          id
          teamId
          personId
          status
          role
          permissionLevel
          creatorId
          createdOn
          updatedOn
          teamMemberCreatorId
        }
        nextToken
      }
      creatorId
      creator {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      teamContactId
      teamLocationId
      teamCreatorId
    }
  }
`;
export const createTeamMember = /* GraphQL */ `
  mutation CreateTeamMember(
    $input: CreateTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    createTeamMember(input: $input, condition: $condition) {
      id
      teamId
      team {
        id
        name
        nickname
        description
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        members {
          nextToken
        }
        creatorId
        creator {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        teamContactId
        teamLocationId
        teamCreatorId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      status
      role
      permissionLevel
      creatorId
      creator {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      teamMemberCreatorId
    }
  }
`;
export const updateTeamMember = /* GraphQL */ `
  mutation UpdateTeamMember(
    $input: UpdateTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    updateTeamMember(input: $input, condition: $condition) {
      id
      teamId
      team {
        id
        name
        nickname
        description
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        members {
          nextToken
        }
        creatorId
        creator {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        teamContactId
        teamLocationId
        teamCreatorId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      status
      role
      permissionLevel
      creatorId
      creator {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      teamMemberCreatorId
    }
  }
`;
export const deleteTeamMember = /* GraphQL */ `
  mutation DeleteTeamMember(
    $input: DeleteTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    deleteTeamMember(input: $input, condition: $condition) {
      id
      teamId
      team {
        id
        name
        nickname
        description
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        members {
          nextToken
        }
        creatorId
        creator {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        teamContactId
        teamLocationId
        teamCreatorId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      status
      role
      permissionLevel
      creatorId
      creator {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      teamMemberCreatorId
    }
  }
`;
export const createText = /* GraphQL */ `
  mutation CreateText(
    $input: CreateTextInput!
    $condition: ModelTextConditionInput
  ) {
    createText(input: $input, condition: $condition) {
      id
      eventId
      blockId
      block {
        id
        name
        type
        personid
        organizationId
        purchasedOn
        expiresOn
        totalCredits
        usedCredits
        status
        price
        amountPaid
        isPromotion
        isFree
        lastUpdatedBy
        createdOn
        updatedOn
      }
      alertId
      alert {
        id
        type
        title
        body
        imageKey
        category
        organizationId
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        messageSegments
        subscriberCount
        totalCost
        blockIdsUsed
        fromUnlimitedEvent
        isIncoming
        sentFromPhoneNumber
        recipientPhoneNumbers
        successSIDList
        errorSIDList
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        alertEventId
      }
      auditorId
      auditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventAuditorEventId
      }
      status
      statusNote
      errorCode
      twilioSID
      twilioMessagingServiceSID
      twilioNote
      createdBy
      lastUpdatedBy
      timeSent
      timeDelivered
      createdOn
      updatedOn
    }
  }
`;
export const updateText = /* GraphQL */ `
  mutation UpdateText(
    $input: UpdateTextInput!
    $condition: ModelTextConditionInput
  ) {
    updateText(input: $input, condition: $condition) {
      id
      eventId
      blockId
      block {
        id
        name
        type
        personid
        organizationId
        purchasedOn
        expiresOn
        totalCredits
        usedCredits
        status
        price
        amountPaid
        isPromotion
        isFree
        lastUpdatedBy
        createdOn
        updatedOn
      }
      alertId
      alert {
        id
        type
        title
        body
        imageKey
        category
        organizationId
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        messageSegments
        subscriberCount
        totalCost
        blockIdsUsed
        fromUnlimitedEvent
        isIncoming
        sentFromPhoneNumber
        recipientPhoneNumbers
        successSIDList
        errorSIDList
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        alertEventId
      }
      auditorId
      auditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventAuditorEventId
      }
      status
      statusNote
      errorCode
      twilioSID
      twilioMessagingServiceSID
      twilioNote
      createdBy
      lastUpdatedBy
      timeSent
      timeDelivered
      createdOn
      updatedOn
    }
  }
`;
export const deleteText = /* GraphQL */ `
  mutation DeleteText(
    $input: DeleteTextInput!
    $condition: ModelTextConditionInput
  ) {
    deleteText(input: $input, condition: $condition) {
      id
      eventId
      blockId
      block {
        id
        name
        type
        personid
        organizationId
        purchasedOn
        expiresOn
        totalCredits
        usedCredits
        status
        price
        amountPaid
        isPromotion
        isFree
        lastUpdatedBy
        createdOn
        updatedOn
      }
      alertId
      alert {
        id
        type
        title
        body
        imageKey
        category
        organizationId
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        messageSegments
        subscriberCount
        totalCost
        blockIdsUsed
        fromUnlimitedEvent
        isIncoming
        sentFromPhoneNumber
        recipientPhoneNumbers
        successSIDList
        errorSIDList
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        alertEventId
      }
      auditorId
      auditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventAuditorEventId
      }
      status
      statusNote
      errorCode
      twilioSID
      twilioMessagingServiceSID
      twilioNote
      createdBy
      lastUpdatedBy
      timeSent
      timeDelivered
      createdOn
      updatedOn
    }
  }
`;
export const createTrainer = /* GraphQL */ `
  mutation CreateTrainer(
    $input: CreateTrainerInput!
    $condition: ModelTrainerConditionInput
  ) {
    createTrainer(input: $input, condition: $condition) {
      id
      name
      location
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      personId
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      createdBy
      createdOn
      updatedOn
      trainerContactId
    }
  }
`;
export const updateTrainer = /* GraphQL */ `
  mutation UpdateTrainer(
    $input: UpdateTrainerInput!
    $condition: ModelTrainerConditionInput
  ) {
    updateTrainer(input: $input, condition: $condition) {
      id
      name
      location
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      personId
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      createdBy
      createdOn
      updatedOn
      trainerContactId
    }
  }
`;
export const deleteTrainer = /* GraphQL */ `
  mutation DeleteTrainer(
    $input: DeleteTrainerInput!
    $condition: ModelTrainerConditionInput
  ) {
    deleteTrainer(input: $input, condition: $condition) {
      id
      name
      location
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      personId
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      createdBy
      createdOn
      updatedOn
      trainerContactId
    }
  }
`;
export const createOrganizationMembershipTypeSeasons = /* GraphQL */ `
  mutation CreateOrganizationMembershipTypeSeasons(
    $input: CreateOrganizationMembershipTypeSeasonsInput!
    $condition: ModelOrganizationMembershipTypeSeasonsConditionInput
  ) {
    createOrganizationMembershipTypeSeasons(
      input: $input
      condition: $condition
    ) {
      id
      organizationMembershipTypeID
      seasonID
      organizationMembershipType {
        id
        name
        description
        order
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        membershipIdValues
        nextAvailableMembershipId
        price
        category
        schedule
        isStartAtTimeOfPurchase
        setStartDate
        acceptOnlineApplications
        applicationFields {
          firstName
          lastName
          dateOfBirth
          contact
          gradeLevel
          barnName
          teamName
          backNumber
          groupName
          groupLeader
          groupLeaderContactInfo
          collectMemberDetailsTogether
          maxNumberOfGroupMembers
          isTeam
          horseName
          horseHeight
          horseOwnerName
          cogginsInfo
          cogginsUpload
        }
        applicationOptions {
          requireApproval
          autoAssignIds
          acceptRSPPayment
          acceptOtherPayment
        }
        organizationDocuments {
          id
          name
        }
        organizationRules {
          id
          title
          body
          isRequired
        }
        seasons {
          nextToken
        }
        createdOn
        updatedOn
        organizationMembershipTypeOrganizationId
      }
      season {
        id
        name
        description
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        organizationMembershipTypes {
          nextToken
        }
        isActive
        startDate
        endDate
        disciplines
        createdOn
        updatedOn
        createdAt
        updatedAt
        seasonOrganizationId
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOrganizationMembershipTypeSeasons = /* GraphQL */ `
  mutation UpdateOrganizationMembershipTypeSeasons(
    $input: UpdateOrganizationMembershipTypeSeasonsInput!
    $condition: ModelOrganizationMembershipTypeSeasonsConditionInput
  ) {
    updateOrganizationMembershipTypeSeasons(
      input: $input
      condition: $condition
    ) {
      id
      organizationMembershipTypeID
      seasonID
      organizationMembershipType {
        id
        name
        description
        order
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        membershipIdValues
        nextAvailableMembershipId
        price
        category
        schedule
        isStartAtTimeOfPurchase
        setStartDate
        acceptOnlineApplications
        applicationFields {
          firstName
          lastName
          dateOfBirth
          contact
          gradeLevel
          barnName
          teamName
          backNumber
          groupName
          groupLeader
          groupLeaderContactInfo
          collectMemberDetailsTogether
          maxNumberOfGroupMembers
          isTeam
          horseName
          horseHeight
          horseOwnerName
          cogginsInfo
          cogginsUpload
        }
        applicationOptions {
          requireApproval
          autoAssignIds
          acceptRSPPayment
          acceptOtherPayment
        }
        organizationDocuments {
          id
          name
        }
        organizationRules {
          id
          title
          body
          isRequired
        }
        seasons {
          nextToken
        }
        createdOn
        updatedOn
        organizationMembershipTypeOrganizationId
      }
      season {
        id
        name
        description
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        organizationMembershipTypes {
          nextToken
        }
        isActive
        startDate
        endDate
        disciplines
        createdOn
        updatedOn
        createdAt
        updatedAt
        seasonOrganizationId
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrganizationMembershipTypeSeasons = /* GraphQL */ `
  mutation DeleteOrganizationMembershipTypeSeasons(
    $input: DeleteOrganizationMembershipTypeSeasonsInput!
    $condition: ModelOrganizationMembershipTypeSeasonsConditionInput
  ) {
    deleteOrganizationMembershipTypeSeasons(
      input: $input
      condition: $condition
    ) {
      id
      organizationMembershipTypeID
      seasonID
      organizationMembershipType {
        id
        name
        description
        order
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        membershipIdValues
        nextAvailableMembershipId
        price
        category
        schedule
        isStartAtTimeOfPurchase
        setStartDate
        acceptOnlineApplications
        applicationFields {
          firstName
          lastName
          dateOfBirth
          contact
          gradeLevel
          barnName
          teamName
          backNumber
          groupName
          groupLeader
          groupLeaderContactInfo
          collectMemberDetailsTogether
          maxNumberOfGroupMembers
          isTeam
          horseName
          horseHeight
          horseOwnerName
          cogginsInfo
          cogginsUpload
        }
        applicationOptions {
          requireApproval
          autoAssignIds
          acceptRSPPayment
          acceptOtherPayment
        }
        organizationDocuments {
          id
          name
        }
        organizationRules {
          id
          title
          body
          isRequired
        }
        seasons {
          nextToken
        }
        createdOn
        updatedOn
        organizationMembershipTypeOrganizationId
      }
      season {
        id
        name
        description
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        organizationMembershipTypes {
          nextToken
        }
        isActive
        startDate
        endDate
        disciplines
        createdOn
        updatedOn
        createdAt
        updatedAt
        seasonOrganizationId
      }
      createdAt
      updatedAt
    }
  }
`;
