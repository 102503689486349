import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { UpdateEventEntryInput, UpdateTrainerInput, UpdateContactInput, CreateContactInput } from "../../../../API";
import { EventEntry, Trainer, Membership } from "../../../../models";
import { updateEventEntry } from "../../../../utilities/eventEntry/EventEntry";
import { updateTrainer } from "../../../../utilities/trainer/Trainer";
import { getMembershipsByTrainerId } from "../../../../utilities/membership/Membership";
import SuccessBanner from "../../../Banners/SuccessBanner";
import ErrorAlert from "../../../Errors/ErrorAlert";
import Spinner from "../../../Spinners/Spinner";
import { formatTwilioNumber } from "../../../../utilities/contact/FormatPhoneNumber";
import { createContact, updateContact } from "../../../../utilities/contact/Contact";
import { Table } from "reactstrap";
import moment from "moment";

interface _Props {
    entry: EventEntry
}

const EntryInfoTrainer: React.FC<_Props> = ({entry}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();
    const [trainerName, setTrainerName] = useState("");
    const [trainerLocation, setTrainerLocation] = useState("");
    const [trainerEmail, setTrainerEmail] = useState("");
    const [trainerPhone, setTrainerPhone] = useState("");
    const [membershipData, setMembershipData] = useState<Membership[] | null | undefined>();
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    async function setMemberships(trainer: Trainer) {
        let memberships: Membership[] = [];
        const queryResult = await getMembershipsByTrainerId(trainer.id);
        if (queryResult.isSuccess) {
            const membershipResults: Membership[] = queryResult.result;
            memberships = membershipResults.sort((a, b) => a.name.localeCompare(b.name));
        }
        setMembershipData(memberships);
    }

    const getEntryInformation = (entry: EventEntry) => {
        setCurrentEntry(entry);
        setTrainerName(entry.trainerName || "");
        setTrainerLocation(entry?.trainer?.location || "");
        setTrainerEmail(entry.trainer?.contact?.personalEmail || "");
        setTrainerPhone(entry?.trainer?.contact?.cell || "");
        if (entry.trainer) setMemberships(entry.trainer);
    }

    useEffect(() => {
        if (entry) {
            getEntryInformation(entry);
        }
    }, [entry]);

    const handleEdit = async () => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        let contactId = "";
        if (entry.trainer?.contact?.id) {
            const updateContactInput: UpdateContactInput = {
                id: entry.trainer.contact.id,
                personalEmail: trainerEmail,
                cell: trainerPhone ? formatTwilioNumber(trainerPhone) : undefined
            };
            const updateContactResult = await updateContact(updateContactInput);
            if (updateContactResult) {
                contactId = updateContactResult.result.id;
            } else {
                setError("Could not update the contact info.");
                setIsLoading(false);
                return;
            }
        } else {
            const createContactInput: CreateContactInput = {
                personalEmail: trainerEmail,
                cell: trainerPhone ? formatTwilioNumber(trainerPhone) : undefined
            };
            const createContactResult = await createContact(createContactInput);
            if (createContactResult) {
                contactId = createContactResult.result.id;
            } else {
                setError("Could not create the contact info.");
                setIsLoading(false);
                return;
            }
        }
        if (entry.trainerId) {
            const input: UpdateTrainerInput = {
                id: entry.trainerId,
                name: trainerName,
                location: trainerLocation,
                trainerContactId: contactId
            };
            const updateResult = await updateTrainer(input);
            if (updateResult.isSuccess) {
                const updateEntryInput: UpdateEventEntryInput = {
                    id: entry.id,
                    trainerName: trainerName,
                    trainerId: updateResult.result.id
                };
                const updateEntryResult = await updateEventEntry(updateEntryInput);
                if (updateEntryResult.isSuccess) {
                    const updatedEntry = updateEntryResult.result;
                    setCurrentEntry(updatedEntry);
                    getEntryInformation(updatedEntry);
                    setMemberships(updatedEntry.trainer);
                    setSuccess("Updated the trainer info.");
                    setIsEdit(false);
                    setIsLoading(false);
                } else {
                    setError("Could not update the entry.");
                    setIsLoading(false);
                    return;
                }
            } else {
                setError("Could not update the trainer");
                setIsLoading(false);
            }
        } else {
            setError("Could not find the trainer in the database.");
            setIsLoading(false);
        }
    }

    return (
        <>
            <IonCard color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            <h3>Trainer</h3>
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {error && <ErrorAlert width="12" error={error} />}
                        {success && <SuccessBanner width="12" success={success} />}
                        {isEdit ?
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer Name</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={trainerName}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setTrainerName(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer Location</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={trainerLocation}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setTrainerLocation(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer Email</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={trainerEmail}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setTrainerEmail(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer Cell Phone</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={trainerPhone}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setTrainerPhone(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </>
                            :
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer Name</IonLabel>
                                            <IonText><p>{trainerName}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer Location</IonLabel>
                                            <IonText><p>{trainerLocation}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer Email</IonLabel>
                                            <IonText><p>{trainerEmail}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer Cell Phone</IonLabel>
                                            <IonText><p>{trainerPhone}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </>
                        }
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="11">
                                {(membershipData && membershipData.length > 0) ?
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Membership</th>
                                                <th>ID</th>
                                                <th>Type</th>
                                                <th>Date Expires</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {membershipData.map((membership, index) => (
                                                <tr key={index}>
                                                    <td>{membership.name}</td>
                                                    <td>{membership.membershipId}</td>
                                                    <td>{membership.type}</td>
                                                    <td>{membership.dateMembershipEnds ? moment(membership.dateMembershipEnds).format("MMM DD, YYYY") : ""}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    :
                                    <p>No rider memberships were found.</p>
                                }
                            </IonCol>
                        </IonRow>
                        {isLoading ?
                            <Spinner />
                            :
                            <IonRow className="ion-justify-content-center">
                                {isEdit && (
                                    <IonCol sizeMd="4" className="ion-text-center">
                                        <IonButton
                                            className="ion-margin-top"
                                            color="primary"
                                            expand="block"
                                            onClick={() => setIsEdit(false)}
                                        >
                                            Cancel
                                        </IonButton>
                                    </IonCol>
                                )}
                                <IonCol sizeMd="4" className="ion-text-center">
                                    <IonButton
                                        className="ion-margin-top"
                                        color="tertiary"
                                        expand="block"
                                        onClick={isEdit ? handleEdit : (() => setIsEdit(true))}
                                    >
                                        {!isEdit && "Allow "}Edit
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        }
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default EntryInfoTrainer;