import { Address, Event, EventClass, EventResult, Owner, Rider, Trainer } from "../../models";
import { getEventClassesByEventId } from "../eventClass/EventClass";
import { sortEventClassesByNumber } from "../eventClass/SortEventClass";
import { getEventResultsByEventClassId } from "../eventResult/EventResult";
import { getMembershipsByHorseId, getMembershipsByOwnerId, getMembershipsByRiderId, getMembershipsByTrainerId } from "../membership/Membership";
import { getAddressById } from "../address/Address";
import { getRiderById } from "../rider/Rider";
import { getOwnerById } from "../owner/Owner";
import { getTrainerById } from "../trainer/Trainer";
import { getEventClassEntriesCountByEventClassId } from "../eventClassEntry/EventClassEntry";
import { downloadCSVFile } from "./ReportDownloadCSV";
import { downloadDATFile } from "./ReportDownloadDAT";
require('jspdf-autotable');

let riderUSEFIdMap = new Map<string, string>();
let ownerUSEFIdMap = new Map<string, string>();
let trainerUSEFIdMap = new Map<string, string>();
let horseUSEFIdMap = new Map<string, string>();

async function getUSEFNumberForRider(rider: Rider) {
    const mapResult = riderUSEFIdMap.get(rider.id);
    if (mapResult) {
        return mapResult;
    }
    const queryResult = await getMembershipsByRiderId(rider.id);
    if (queryResult.isSuccess) {
        const memberships = queryResult.result;
        if (memberships) {
            for (let i = 0; i < memberships.length; i++) {
                const membership = memberships[i];
                if (membership?.name === "USEF" || membership?.name === "USEF Rider" || membership?.name === "USHJA" || membership?.name === "USHJA Rider") {
                    riderUSEFIdMap.set(rider.id, membership.membershipId);
                    return membership.membershipId;
                }
            }
        }
    }
}

async function getUSEFNumberForOwner(ownerId: string) {
    const mapResult = ownerUSEFIdMap.get(ownerId);
    if (mapResult) {
        return mapResult;
    }
    const queryResult = await getMembershipsByOwnerId(ownerId);
    if (queryResult.isSuccess) {
        const memberships = queryResult.result;
        if (memberships) {
            for (let i = 0; i < memberships.length; i++) {
                const membership = memberships[i];
                if (membership?.name === "USEF" || membership?.name === "USEF Owner" || membership?.name === "USHJA" || membership?.name === "USHJA Owner") {
                    ownerUSEFIdMap.set(ownerId, membership.membershipId);
                    return membership.membershipId;
                }
            }
        }
    }
}

async function getUSEFNumberForTrainer(trainerId: string) {
    const mapResult = trainerUSEFIdMap.get(trainerId);
    if (mapResult) {
        return mapResult;
    }
    const queryResult = await getMembershipsByTrainerId(trainerId);
    if (queryResult.isSuccess) {
        const memberships = queryResult.result;
        if (memberships) {
            for (let i = 0; i < memberships.length; i++) {
                const membership = memberships[i];
                if (membership?.name === "USEF" || membership?.name === "USEF Trainer" || membership?.name === "USHJA" || membership?.name === "USHJA Trainer") {
                    trainerUSEFIdMap.set(trainerId, membership.membershipId);
                    return membership.membershipId;
                }
            }
        }
    }
}

async function getUSEFNumberForHorse(horseId: string) {
    const mapResult = horseUSEFIdMap.get(horseId);
    if (mapResult) {
        return mapResult;
    }
    const queryResult = await getMembershipsByHorseId(horseId);
    if (queryResult.isSuccess) {
        const memberships = queryResult.result;
        if (memberships) {
            for (let i = 0; i < memberships.length; i++) {
                const membership = memberships[i];
                if (membership?.name === "USEF" || membership?.name === "USEF Horse" || membership?.name === "USHJA" || membership?.name === "USHJA Horse") {
                    horseUSEFIdMap.set(horseId, membership.membershipId);
                    return membership.membershipId;
                }
            }
        }
    }
}

function getUSHJAOutreachClassName(eventClass: EventClass) {
    if (eventClass.name.includes("Introductory Hunter"))return("USHJA Outreach WT Poles");
    else if (eventClass.name.includes("Introductory Equitation"))return("USHJA Outreach Walk, Trot Equitation");
    else if (eventClass.name.includes("Baby Green Hunter"))return(`USHJA Outreach Hunters 18"`);
    else if (eventClass.name.includes("Short/Long Stirrup Hunter"))return(`USHJA Outreach Hunters 18"`);
    else if (eventClass.name.includes("Short/Long Stirrup Equitation"))return(`USHJA Equitation Hunters 18"`);
    else if (eventClass.name.includes("Low Adult Hunter"))return(`USHJA Outreach Hunters 2'`);
    else if (eventClass.name.includes("Very Low Hunter"))return(`USHJA Outreach Hunters 2'`);
    else if (eventClass.name.includes("Beginner Hunter"))return(`USHJA Outreach Hunters 2'3"`);
    else if (eventClass.name.includes("Beginner Scholarship Equitation"))return(`USHJA Outreach Equitation 2'3"`);
    else if (eventClass.name.includes("Open Hunter"))return(`USHJA Outreach Hunters 2' / 2'3" / 2'6" / 2'9" / 3'`);
    else if (eventClass.name.includes("NWAHJA Hunter"))return(`USHJA Outreach Hunters 2'6"`);
    else if (eventClass.name.includes("NWAHJA Scholarship Equitation"))return(`USHJA Outreach Equitation 2'6" / 2'9"`);
    else if (eventClass.name.includes("Modified Child/Adult Hunter"))return(`USHJA Outreach Hunters 2'9"`);
    else if (eventClass.name.includes("Child/Adult Hunter"))return(`USHJA Outreach Hunters 3'`);
    else if (eventClass.name.includes("0.60m Jumper"))return("USHJA Outreach .60 m");
    else if (eventClass.name.includes("0.75m Jumper"))return("USHJA Outreach .75-.80 m");
    else if (eventClass.name.includes("0.85m Jumper"))return("USHJA Outreach .85 m");
    else if (eventClass.name.includes("0.95m Jumper"))return("USHJA Outreach .90-.95 m");
    else return "";
}

function formatAddress(address: Address) {
    const City = address?.city;
    const State = address?.provState;
    const Zip = address?.zip;
    const FormattedAddress = (City ? City + ", " : "") +  (State ? State + ", " : "") + (Zip ? Zip : "");
    return FormattedAddress;
}

export async function generateUSEFResultsReportDataRows(event: Event, customUSEFEventId?: string, isCSV?: boolean) {
    let riderMap = new Map<string, Rider>();
    let ownerMap = new Map<string, Owner>();
    let trainerMap = new Map<string, Trainer>();

    let rows = [];

    rows.push(headerRow);
    
    const queryResult = await getEventClassesByEventId(event.id);
    if (queryResult.isSuccess) {
        const eventClasses = queryResult.result as EventClass[];
        const sorted = sortEventClassesByNumber(eventClasses);
        if (sorted && sorted.length > 0) {
            for (let i = 0; i < sorted.length; i++) {
                const eventClass = sorted[i];
                const eventClassResultsResult = await getEventResultsByEventClassId(eventClass.id);
                const resultsArray: EventResult[] = eventClassResultsResult.result;
                if (eventClassResultsResult.isSuccess && resultsArray && resultsArray.length > 0) {
                    if (resultsArray && resultsArray.length > 0) {
                        for (let i = 0; i < resultsArray.length; i++) {
                            const currentEventClassResult = resultsArray[i];
                            let currentRow: string[] = [];
                            // Comp ID
                            currentRow.push(customUSEFEventId || event.usefID || "");
                            // Class Number
                            currentRow.push(eventClass?.number ? eventClass.number.toString() : "");
                            // Class Name
                            const outreachClassName = getUSHJAOutreachClassName(eventClass);
                            currentRow.push(outreachClassName);
                            // Alt. Class Name
                            currentRow.push(eventClass.name);
                            // Section
                            currentRow.push("");
                            // HJO
                            currentRow.push("");
                            // Entry Count
                            let entryCount = eventClass.currentNumberEntries;
                            if (!entryCount) {
                                const result = await getEventClassEntriesCountByEventClassId(eventClass.id);
                                if (result.isSuccess) {
                                    entryCount = result.result;
                                }
                            }
                            currentRow.push(entryCount ? entryCount?.toString() : "");
                            // Placing
                            currentRow.push(currentEventClassResult.place ? currentEventClassResult.place.toString() : "");
                            // Horse USEF ID
                            if (currentEventClassResult.entry.horseId) {
                                const usefMemershipId = await getUSEFNumberForHorse(currentEventClassResult.entry.horseId);
                                currentRow.push(usefMemershipId || "");
                            } else {
                                currentRow.push("");
                            }
                            // Horse Name
                            currentRow.push(currentEventClassResult.entry.horseName || "");
                            // Rider USEF ID
                            let riderId = currentEventClassResult.entry.riderId;
                            if (currentEventClassResult.entry.secondRiderId) {
                                const eventClassEntry = currentEventClassResult.eventClassEntry;
                                if (eventClassEntry && eventClassEntry.riderId) {
                                    riderId = eventClassEntry.riderId;
                                }
                            }
                            let rider: Rider | undefined = undefined;
                            if (riderId) {
                                const mapResult = riderMap.get(riderId);
                                if (mapResult) {
                                    rider = mapResult;
                                } else {
                                    const riderResult = await getRiderById(riderId);
                                    if (riderResult.isSuccess) {
                                        rider = riderResult.result;
                                        if (rider) riderMap.set(riderId, rider);
                                    }
                                }
                            }
                            if (rider) {
                                const usefMemershipId = await getUSEFNumberForRider(rider);
                                currentRow.push(usefMemershipId || "");
                            } else {
                                currentRow.push("");
                            }
                            // Rider Name
                            currentRow.push(rider?.name || "");
                            // Rider Street Address
                            let riderAddress = rider?.address;
                            if (!riderAddress && rider?.addressId) {
                                const riderAddressId = rider?.addressId;
                                const addressResult = await getAddressById(riderAddressId);
                                if (addressResult.isSuccess) {
                                    riderAddress = addressResult.result;
                                }
                            }
                            currentRow.push(riderAddress?.streetAddress1 || "");
                            // Rider City, State, Zip
                            if (riderAddress) {
                                const riderFormattedAddress = formatAddress(riderAddress);
                                currentRow.push(riderFormattedAddress);
                            } else {
                                currentRow.push("");
                            }
                            
                            // Owner USEF ID
                            const ownerId = currentEventClassResult.entry.ownerId;
                            let owner: Owner | undefined = undefined;
                            if (ownerId) {
                                const mapResult = ownerMap.get(ownerId);
                                if (mapResult) {
                                    owner = mapResult;
                                } else {
                                    const ownerResult = await getOwnerById(ownerId);
                                    if (ownerResult.isSuccess) {
                                        owner = ownerResult.result;
                                        if (owner) ownerMap.set(ownerId, owner);
                                    }
                                }
                            }
                            if (ownerId) {
                                const usefMemershipId = await getUSEFNumberForOwner(ownerId);
                                currentRow.push(usefMemershipId || "");
                            } else {
                                currentRow.push("");
                            }
                            // Owner Name
                            currentRow.push(owner?.name || "");
                            // Owner Street Address
                            let ownerAddress = owner?.address; 
                            if (!ownerAddress && owner?.addressId) {
                                const ownerAddressId = owner?.addressId;
                                const addressResult = await getAddressById(ownerAddressId);
                                if (addressResult.isSuccess) {
                                    ownerAddress = addressResult.result;
                                }
                            }
                            currentRow.push(ownerAddress?.streetAddress1 || "");
                            // Owner City, State, Zip
                            if (ownerAddress) {
                                const ownerFormattedAddress = formatAddress(ownerAddress);
                                currentRow.push(ownerFormattedAddress);
                            } else {
                                currentRow.push("");
                            }
                            // Height
                            if (eventClass.name.includes("Introductory Hunter")) currentRow.push("poles");
                            else if (eventClass.name.includes("Introductory Equitation")) currentRow.push("poles");
                            else if (eventClass.name.includes("Crossrail Hunter")) currentRow.push(`12"`);
                            else if (eventClass.name.includes("Crossrail Equitation")) currentRow.push(`12"`);
                            else if (eventClass.name.includes("Baby Green Hunter")) currentRow.push(`18"`);
                            else if (eventClass.name.includes("Short/Long Stirrup Hunter")) currentRow.push(`18"`);
                            else if (eventClass.name.includes("Short/Long Stirrup Equitation")) currentRow.push(`18"`);
                            else if (eventClass.name.includes("Low Adult Hunter")) currentRow.push(`24"`);
                            else if (eventClass.name.includes("Low Adult Medal")) currentRow.push(`24"`);
                            else if (eventClass.name.includes("Very Low Hunter")) currentRow.push(`24"`);
                            else if (eventClass.name.includes("2' Medal")) currentRow.push(`24"`);
                            else if (eventClass.name.includes("Beginner Hunter")) currentRow.push(`27"`);
                            else if (eventClass.name.includes("Beginner Scholarship Equitation")) currentRow.push(`27"`);
                            else if (eventClass.name.includes("Open Hunter")) currentRow.push(`24"/27"/30"/33"/36"`);
                            else if (eventClass.name.includes("NWAHJA Medal")) currentRow.push(`30"`);
                            else if (eventClass.name.includes("NWAHJA Hunter")) currentRow.push(`30"`);
                            else if (eventClass.name.includes("NWAHJA Scholarship Equitation")) currentRow.push(`30"/33"`);
                            else if (eventClass.name.includes("Modified Child/Adult Hunter")) currentRow.push(`33"`);
                            else if (eventClass.name.includes("Child/Adult Medal")) currentRow.push(`36"`);
                            else if (eventClass.name.includes("Child/Adult Hunter")) currentRow.push(`36"`);
                            else if (eventClass.name.includes("0.60m Jumper")) currentRow.push(".60");
                            else if (eventClass.name.includes("0.75m Jumper")) currentRow.push(".75");
                            else if (eventClass.name.includes("0.85m Jumper")) currentRow.push(".85");
                            else if (eventClass.name.includes("0.95m Jumper")) currentRow.push(".95");
                            else currentRow.push("");
                            // e/m
                            currentRow.push("");
                            // Trainer USEF ID
                            const trainerId = currentEventClassResult.entry.trainerId;
                            let trainer: Trainer | undefined = undefined;
                            if (trainerId) {
                                const mapResult = trainerMap.get(trainerId);
                                if (mapResult) {
                                    trainer = mapResult;
                                } else {
                                    const trainerResult = await getTrainerById(trainerId);
                                    if (trainerResult.isSuccess) {
                                        trainer = trainerResult.result;
                                        if (trainer) trainerMap.set(trainerId, trainer);
                                    }
                                }
                            }
                            if (trainerId) {
                                const usefMemershipId = await getUSEFNumberForTrainer(trainerId);
                                currentRow.push(usefMemershipId || "");
                            } else {
                                currentRow.push("");
                            }
                            // Trainer Name
                            currentRow.push(trainer?.name || "");
                            // Trainer Street Address
                            let trainerAddress = trainer?.address; 
                            if (!trainerAddress && trainer?.addressId) {
                                const trainerAddressId = trainer?.addressId;
                                const addressResult = await getAddressById(trainerAddressId);
                                if (addressResult.isSuccess) {
                                    trainerAddress = addressResult.result;
                                }
                            }
                            currentRow.push(trainerAddress?.streetAddress1 || "");
                            // Trainer City, State, Zip
                            if (trainerAddress) {
                                const trainerFormattedAddress = formatAddress(trainerAddress);
                                currentRow.push(trainerFormattedAddress);
                            } else {
                                currentRow.push("");
                            }
                            
                            rows.push(currentRow);
                        }
                    }
                } else {
                    // If no results, still inclue the row with the class info
                    let currentRow: string[] = [];
                    // Comp ID
                    currentRow.push(customUSEFEventId || event.usefID || "");
                    // Class Number
                    currentRow.push(eventClass?.number ? eventClass.number.toString() : "");
                    // Class Name
                    const outreachClassName = getUSHJAOutreachClassName(eventClass);
                    currentRow.push(outreachClassName);
                    // Alt. Class Name
                    currentRow.push(eventClass.name);
                    // Section
                    currentRow.push("");
                    // HJO
                    currentRow.push("");
                    // Entry Count
                    currentRow.push(eventClass.currentNumberEntries ? eventClass.currentNumberEntries.toString() : "");
                    rows.push(currentRow);
                }
            }
        }
    }

    if (isCSV) {
        const fileName = `${customUSEFEventId || event.usefID}_USEF_Results_ringsidepro.csv`;
        downloadCSVFile(fileName, rows);
    } else {
        const fileName = `${customUSEFEventId || event.usefID}_USEF_Results_ringsidepro.dat`;
        downloadDATFile(fileName, rows);
    }
}

export const headerRow = [
    "Comp ID",
    "Class",
    "Class Name",
    "Alt. Class Name",
    "Section",
    "HJO",
    "Entries",
    "Placing",
    "USEF Horse ID",
    "Horse Name",
    "USEF Rider ID",
    "Rider Name",
    "Rider Address",
    "Rider City, State, Zip",
    "USEF Owner ID",
    "Owner Name",
    "Owner Address",
    "Owner City, State, Zip",
    "Height",
    "e/m",
    "USEF Trainer ID",
    "Trainer Name",
    "Trainer Address",
    "Trainer City, State, Zip",
];