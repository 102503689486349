import { 
    IonCard,
    IonCol,
    IonContent, 
    IonHeader,   
    IonPage,
    IonRow, 
} from "@ionic/react";
import React from "react";
import WebNavbar from "../../components/Navbars/WebNavbar";

const ClinicPage: React.FC = () => {
    return (
        <IonPage id="clinicPage">
            <IonHeader>
                <WebNavbar />
            </IonHeader>
            <IonContent>
                <IonRow className="bg-primary ion-justify-content-center">
                    <IonCol sizeXs="12" sizeMd="8">
                        <IonCard color="white" className="ion-padding">
                            <iframe src="https://www.cognitoforms.com/f/vkwORLOc0UGKamqDyd9Vew/3" title="Clinic Form" className="full-width" height="1484"></iframe>
                            <script src="https://www.cognitoforms.com/f/iframe.js"></script>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default ClinicPage;