import moment from "moment";
import { CreateAddressInput, CreateBarnInput, CreateContactInput, CreateMembershipInput, CreatePersonInput, CreatePersonNameInput, CreatePersonalInformationInput, CreateRiderInput, UpdateOrganizationMembershipTypeInput, UpdatePersonalInformationInput, UpdateRiderInput } from "../../API";
import { Address, Barn, Contact, Membership, OrganizationMembershipType, Person, PersonalInformation, Rider } from "../../models";
import { createBarn, getBarnByName } from "../barn/Barn";
import { createPerson, getPersonByPersonId } from "../person/Person";
import { createPersonName } from "../person/PersonName";
import { createRider, getRiderByPersonId, updateRider } from "../rider/Rider";
import { createMembership, getMembershipsByOrganizationIdByActiveStatus } from "./Membership";
import { InputRow } from "../../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationMembersBulkAddPage";
import { getOrganizationMembershipTypesByOrganizationId, updateOrganizationMembershipType } from "../organizationMembershipType/OrganizationMembershipType";
import { createAddress } from "../address/Address";
import { createContact } from "../contact/Contact";
import { getNextAvailableMembershipId } from "../organizationMembershipType/MembershipIdValues";
import { createPersonalInformation, getPersonalInformationByPersonId, updatePersonalInformation } from "../personalInformation/PersonalInformation";
import { formatTwilioNumber } from "../contact/FormatPhoneNumber";
import constants from "../../constant/constant";

let organizationId = constants.RHSC_ORGANIZATION.id;

export async function bulkAddRHSCMembershipfromCSV(inputRows: InputRow[], user: Person, progressFunction: Function) {
    progressFunction(0);

    // Get the RHSC Membership Types
    let membershipTypes: OrganizationMembershipType[] | null = null;
    const membershipTypesResult = await getOrganizationMembershipTypesByOrganizationId(organizationId);
    if (membershipTypesResult.isSuccess) {
        membershipTypes = membershipTypesResult.result;
    } else {
        console.error("No membership types were found.");
        return;
    }

    // Get the RHSC Past Memberships
    let pastMemberships: Membership[] | null = null;
    const pastMembershipsResult = await getMembershipsByOrganizationIdByActiveStatus(organizationId, "expired");
    if (pastMembershipsResult.isSuccess) {
        pastMemberships = pastMembershipsResult.result;
    } else {
        console.error("No membership types were found.");
        return;
    }

    // When retrieving data, store it in a map so it can easily be accessed
    let barnMap = new Map<string, Barn>();

    for (let i = 0; i < inputRows.length; i++) {
        const currentRow = inputRows[i];
        const progressNumber = (((i+1) / inputRows.length) * 100);
        progressFunction(progressNumber);

        // Check for empty row
        if (currentRow["First Name"] === "" || !currentRow["First Name"]) continue;

        // Determine the membership type - TO DO - Check this in prod
        const currentMembershipType = currentRow["Membership Type"].includes("Family") ? membershipTypes?.find(memType => memType.name.includes("Group") || memType.name.includes("Family")) : membershipTypes?.find(memType => memType.name.includes("Individual"));
        if (!currentMembershipType) {
            return;
        }
        
        const arrayOfAmounts = currentRow.Payment.match(/\d+\.\d+/g);
        const amountPaid = (arrayOfAmounts && arrayOfAmounts.length > 0) ? parseFloat(arrayOfAmounts[0]) : currentMembershipType?.price ? currentMembershipType?.price : 0;

        // Find the Barn
        const currentBarn = await getBarnInfoFromBarnName(currentRow["Barn / Farm"], barnMap);

        // Create the Address
        let address: Address | null = null;
        const addressInput: CreateAddressInput = {
            name: "Membership Address for " + currentRow["First Name"] + " " + currentRow["Last Name"],
            type: "Mailing",
            streetAddress1: currentRow["Street Address"],
            streetAddress2: currentRow["Street Address Line 2"],
            city: currentRow["City"],
            provState: currentRow["State"] === "Georgia" ? "GA" : (currentRow["State"] === "North Carolina" ? "NC" : currentRow["State"]),
            zip: currentRow["Zip Code"],
            country: "USA",
        };
        const createAddressResult = await createAddress(addressInput);
        if (createAddressResult.isSuccess) {
            address = createAddressResult.result;
        }

        // Create the Contact
        let newContact: Contact | null = null;
        const contactInput: CreateContactInput = {
            name: "Membership Contact for " + currentRow["First Name"] + " " + currentRow["Last Name"],
            home: currentRow["Phone Number"] ? formatTwilioNumber(currentRow["Phone Number"]) : "",
            personalEmail: currentRow.Email,
            mailingAddress: address?.id || ""
        };
        const createContactResult = await createContact(contactInput);
        if (createContactResult.isSuccess) {
            newContact = createContactResult.result;
        }

        // Determine the person for the membership - If it is a family membership, create multiple
        if (currentRow["First Name"] && currentRow["Last Name"]) {
            await createRHSCMembership(currentRow["First Name"], currentRow["Last Name"], currentMembershipType, amountPaid, currentRow, user, newContact, pastMemberships, currentBarn, currentRow["Rider Date of Birth"]);
        }
        if (currentRow["Membership Type"].includes("Family") && currentRow["First Name 1"] && currentRow["First Name 1"] !== currentRow["First Name"] && currentRow["Last Name 1"]) {
            await createRHSCMembership(currentRow["First Name 1"], currentRow["Last Name 1"], currentMembershipType, amountPaid, currentRow, user, newContact, pastMemberships, currentBarn, currentRow["Date of Birth 1 (If under 18)"]);
        }
        if (currentRow["Membership Type"].includes("Family") && currentRow["First Name 2"] && currentRow["First Name 2"] !== currentRow["First Name"] && currentRow["Last Name 2"]) {
            await createRHSCMembership(currentRow["First Name 2"], currentRow["Last Name 2"], currentMembershipType, amountPaid, currentRow, user, newContact, pastMemberships, currentBarn, currentRow["Date of Birth 2 (If under 18)"]);
        }
        if (currentRow["Membership Type"].includes("Family") && currentRow["First Name 3"] && currentRow["First Name 3"] !== currentRow["First Name"] && currentRow["Last Name 3"]) {
            await createRHSCMembership(currentRow["First Name 3"], currentRow["Last Name 3"], currentMembershipType, amountPaid, currentRow, user, newContact, pastMemberships, currentBarn, currentRow["Date of Birth 3 (If under 18)"]);
        }
        if (currentRow["Membership Type"].includes("Family") && currentRow["First Name 4"] && currentRow["First Name 4"] !== currentRow["First Name"] && currentRow["Last Name 4"]) {
            await createRHSCMembership(currentRow["First Name 4"], currentRow["Last Name 4"], currentMembershipType, amountPaid, currentRow, user, newContact, pastMemberships, currentBarn, currentRow["Date of Birth 4 (If under 18)"]);
        }
    }
}

async function createRHSCMembership(firstName: string, lastName: string, currentMembershipType: OrganizationMembershipType, amountPaid: number, currentRow: InputRow, user: Person, contact?: (Contact | null), pastMemberships?: (Membership[] | null), currentBarn?: Barn, dateOfBirth?: string) {
    const personInfo = await getPersonInfoFromName(firstName, lastName, pastMemberships, dateOfBirth, contact);
    const person: Person | null = personInfo?.person;
    if (person) {
        // Get Rider from Person
        const rider = await getRiderInfoFromPersonName(firstName, lastName, person, currentBarn, dateOfBirth);

        // Create the date the membership should end
        const lastDayOfCurrentYear = moment(new Date()).month("December").date(31).format("YYYY-MM-DD");

        // Create Membership for Person
        let input: CreateMembershipInput = {
            name: "RHSC",
            membershipId: currentMembershipType?.nextAvailableMembershipId || "",
            backNumber: 0,
            membershipStatus: "complete",
            amountPaid: amountPaid,
            organizationMembershipTypeId: currentMembershipType?.id || "",
            type: currentMembershipType?.name || "",
            personName: firstName + " " + lastName || "",
            personEmail: currentRow["Email"] || "",
            personId: person.id || "",
            riderId: rider?.id || "",
            barnId: currentBarn?.id || "",
            organizationId: organizationId,
            membershipOrganizationId: organizationId,
            organizationName: "RHSC",
            dateMembershipEnds: lastDayOfCurrentYear,
            groupLeaderContactId: contact?.id || "",
            groupContactId: contact?.id || "",
            volunteerHours: 0,
            meetingsAttended: 0,
            createdBy: user.id,
            createdOn: moment(currentRow["Submission Date"]).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
        const createMembershipResult = await createMembership(input);
        if (createMembershipResult.isSuccess) {
            if(currentMembershipType?.applicationOptions?.autoAssignIds && currentMembershipType?.membershipIdValues) {
                const newMembershipId = getNextAvailableMembershipId(currentMembershipType?.membershipIdValues, currentMembershipType.nextAvailableMembershipId || "");
                const updateInput:  UpdateOrganizationMembershipTypeInput = {
                    id: currentMembershipType.id,
                    nextAvailableMembershipId: newMembershipId
                };
                await updateOrganizationMembershipType(updateInput);
            }
        } else {
            console.error("Error creating membership: ", createMembershipResult);
        }
    }
}

async function getPersonInfoFromName(firstName: string, lastName: string, pastMemberships?: (Membership[] | undefined | null), dateOfBirth?: string, contact?: (Contact | null)) {
    // Standardize the name
    const fullName = firstName.trim() + " " + lastName.trim();
    const lowerCaseFirstName = firstName.toLowerCase().trim();
    const lowerCaseLastName = lastName.toLowerCase().trim();

    // Make sure the birthdate gets attached to the Personal Information
    let personalInformation: PersonalInformation | null = null;

    // Check if this person was previously a member of the organization
    const foundPastMembership = pastMemberships?.find(mem => mem.personName?.toLowerCase().includes(lowerCaseFirstName) && mem.personName?.toLowerCase().includes(lowerCaseLastName));
    if (foundPastMembership) {
        // Use the person from this membership
        const personId = foundPastMembership.personId;
        const personResult = await getPersonByPersonId(personId);
        if (personResult.isSuccess) {
            const person: Person = personResult.result;

            // Check for any personal info
            const personalInfoResult = await getPersonalInformationByPersonId(person.id);
            if (personalInfoResult.isSuccess) {
                personalInformation = personalInfoResult.result;
                if (personalInformation) {
                    let updatePersonalInfoInput: UpdatePersonalInformationInput = {
                        id: personalInformation.id,
                        dateOfBirth: dateOfBirth ? moment(dateOfBirth).format("YYYY-MM-DD") : null
                    };
                    if (!personalInformation.addressId || personalInformation.addressId === "") updatePersonalInfoInput["addressId"] = contact?.mailingAddress || "";
                    if (!personalInformation.contactId || personalInformation.contactId === "") updatePersonalInfoInput["contactId"] = contact?.id || "";
                    await updatePersonalInformation(updatePersonalInfoInput);
                }
            } else {
                const createPersonalInfoInput: CreatePersonalInformationInput = {
                    personId: person.id,
                    dateOfBirth: dateOfBirth ? moment(dateOfBirth).format("YYYY-MM-DD") : null,
                    contactId: contact?.id || "",
                    addressId: contact?.mailingAddress || ""
                };
                await createPersonalInformation(createPersonalInfoInput);
            }

            return {
                person: person,
                pastMembership: foundPastMembership
            };
        }
    }

    // Otherwise, create a new person
    const personInput: CreatePersonInput = {
        email: contact?.personalEmail || "",
        firstName: firstName || "",
        lastName: lastName || "",
        isVerified: false,
        roles: "rider;",
        createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
    };
    const createPersonResult = await createPerson(personInput);
    if (createPersonResult.isSuccess) {
        const person = createPersonResult.result;
        if (person) {
            const personNameInput: CreatePersonNameInput = {
                personId: person.id,
                firstName: firstName || "",
                lastName: lastName || "",
                displayName: fullName || ""
            };
            await createPersonName(personNameInput);
            const createPersonalInfoInput: CreatePersonalInformationInput = {
                personId: person.id,
                dateOfBirth: dateOfBirth ? moment(dateOfBirth).format("YYYY-MM-DD") : null,
                contactId: contact?.id || "",
                addressId: contact?.mailingAddress || ""
            };
            await createPersonalInformation(createPersonalInfoInput);
            return {
                person: person,
                pastMembership: null
            };
        }
    } else {
        console.error("Error creating a person: ", createPersonResult);
        return {
            person: null,
            pastMembership: null
        };
    }
}
async function getBarnInfoFromBarnName(barnName: string, barnMap: Map<string, Barn>) {
    let barn: Barn | undefined = undefined;
    if (barnName) {
        const mapResult = barnMap.get(barnName);
        if (mapResult) barn = mapResult;

        if (!barn) {
            const barnQueryResult = await getBarnByName(barnName);
            if (barnQueryResult.isSuccess) {
                barn = barnQueryResult.result;
                if (barn) barnMap.set(barnName, barn);
            }
        }

        if (!barn) {
            const barnInput: CreateBarnInput = {
                name: barnName
            };
            const createBarnResult = await createBarn(barnInput);
            if (createBarnResult.isSuccess) {
                barn = createBarnResult.result;
                if (barn) barnMap.set(barnName, barn);
            }
        }
    }
    return barn;
}
async function getRiderInfoFromPersonName(firstName: string, lastName: string, person?: Person, barn?: Barn, dateOfBirth?: (string | null)) {
    let rider: Rider | undefined = undefined;

    if (person) {
        const riderResult = await getRiderByPersonId(person.id);
        if (riderResult.isSuccess) {
            rider = riderResult.result;

            if (rider) {
                let updateRiderInput: UpdateRiderInput = {
                    id: rider?.id,
                    birthdate: dateOfBirth ? moment(dateOfBirth).format("YYYY-MM-DD") : null
                };
                updateRider(updateRiderInput);
            }
        }
    }
    
    if (!rider) {
        let riderInput: CreateRiderInput = {
            name: firstName.trim() + " " + lastName.trim(),
            barnId: barn?.id || "",
            personId: person?.id || "",
            birthdate: dateOfBirth ? moment(dateOfBirth).format("YYYY-MM-DD") : null,
            createdBy: person?.id || "", 
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };

        const riderResult = await createRider(riderInput);
        if (riderResult.isSuccess) {
            rider = riderResult.result;
        } else {
            console.error("Error creating rider: ", riderResult);
        }
    }
    return rider;
}