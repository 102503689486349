import moment from "moment";
import { BeddingRequest, Event, StablingRequest } from "../../models";
import { getBeddingRequestsByEventId } from "../beddingRequest/BeddingRequest";
import { getStablingRequestsByEventId } from "../stablingRequest/StablingRequest";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

interface stallData {
    name: string
    quantity: number
    amount: number
    totalAmount: number
    tax: number
}

export async function generateRevenueFromStallFees(event: Event) {
    // initialize jsPDF
    const doc = new jsPDF();

    // page title. and margin-top + margin-left
    doc.text(`${event.name}`, 14, 15);
    doc.setFontSize(10);
    doc.text(`Stabling Fee Analysis Report`, 14, 20);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);

    // define the columns we want and their titles
    const tableColumn = ["Stall Type", "Quantity", "Price", "Total (no tax)", "Tax", "Total (tax)"];
    // define an empty array of rows
    const tableRows = [];

    let totalStallFeeAmount = 0;
    let totalStallFeeWithTaxAmount = 0;

    let stallMap = new Map();

    const queryResult = await getStablingRequestsByEventId(event.id);
    if (queryResult.isSuccess) {
        const stablingRequests = queryResult.result;
        if (stablingRequests) {
            for (let i = 0; i < stablingRequests.length; i++) {
                const stablingRequest: StablingRequest = stablingRequests[i];
                const stallTypeId = stablingRequest.stallTypeId;
                const result = stallMap.get(stallTypeId);
                const name = stablingRequest.stallType?.name || "unknown stall type";
                const quantity = (result?.quantity ? result?.quantity : 0) + 1;
                const amount = stablingRequest.basePrice || 0;
                const currentStallTotal = (result?.totalAmount ? result?.totalAmount : 0) + stablingRequest.basePrice;
                const tax = (stablingRequest.taxA || 0) + (stablingRequest.taxB || 0);
                const stallData: stallData = {
                    name: name,
                    quantity: quantity,
                    amount: amount,
                    totalAmount: currentStallTotal,
                    tax: tax
                };
                stallMap.set(stallTypeId, stallData);

                totalStallFeeAmount = totalStallFeeAmount + amount;
                totalStallFeeWithTaxAmount = totalStallFeeWithTaxAmount + (amount + (amount * tax/100));
            }
        }
    }

    stallMap.forEach(element => {
        const name = element?.name || "unknown name";
        const quantity = element?.quantity.toString() || "unknown quantity";
        const amount = "$" + element?.amount.toLocaleString() || "unknown amount";
        const total = "$" + element?.totalAmount.toLocaleString();
        const tax = element?.tax.toString() + "%";
        const totalWithTax = "$" + (element?.totalAmount + (element?.totalAmount * (element?.tax || 0)/100)).toLocaleString();
        const row = [
            name, 
            quantity,
            amount,
            total,
            tax,
            totalWithTax
        ];
        tableRows.push(row);
    });

    doc.setFontSize(12);

    const totalStallFeeRow = [
        "Total Stall Fees",
        "",
        "",
        "$" + totalStallFeeAmount.toLocaleString(),
        "",
        "$" + totalStallFeeWithTaxAmount.toLocaleString(),
    ];

    tableRows.push(totalStallFeeRow);
    
    // startY is basically margin-top
    doc.autoTable(tableColumn, tableRows, { 
        theme: "grid",
        headStyles: {fillColor: "#73a4d3"},
        startY: 35,
        drawCell: function(cell: any, data: any) {
            if (data.row.index === data.table.rows.length - 1) {
                doc.setFontStyle('bold');
            }
        } 
    });

    // define the columns we want and their titles
    const beddingTableColumns = ["Bedding Type", "Quantity", "Price", "Total (no tax)", "Tax", "Total (tax)"];
    // define an empty array of rows
    const beddingTableRows = [];

    let totalBeddingFeeAmount = 0;
    let totalBeddingFeeWithTaxAmount = 0;

    let beddingMap = new Map();

    const queryBeddingResult = await getBeddingRequestsByEventId(event.id);
    if (queryBeddingResult.isSuccess) {
        const beddingRequests: BeddingRequest[] = queryBeddingResult.result;
        if (beddingRequests && beddingRequests.length > 0) {
            for (let i = 0; i < beddingRequests.length; i++) {
                const beddingRequest: BeddingRequest = beddingRequests[i];
                const beddingTypeId = beddingRequest.beddingType;
                const result = beddingMap.get(beddingTypeId);
                const name = beddingRequest.beddingType || "unknown bedding type";
                const quantity = (result?.quantity ? result?.quantity : 0) + 1;
                const amount = beddingRequest.basePrice || 0;
                const currentStallTotal = (result?.totalAmount ? result?.totalAmount : 0) + beddingRequest.basePrice;
                const tax = (beddingRequest.taxA?.percentage || 0) + (beddingRequest.taxB?.percentage || 0);
                const beddingData: stallData = {
                    name: name,
                    quantity: quantity,
                    amount: amount,
                    totalAmount: currentStallTotal,
                    tax: tax
                };
                beddingMap.set(beddingTypeId, beddingData);

                totalBeddingFeeAmount = totalStallFeeAmount + amount;
                totalBeddingFeeWithTaxAmount = totalStallFeeWithTaxAmount + (amount + (amount * tax/100));
            }
        }
    }

    beddingMap.forEach(element => {
        const name = element?.name || "unknown name";
        const quantity = element?.quantity.toString() || "unknown quantity";
        const amount = "$" + element?.amount.toLocaleString() || "unknown amount";
        const total = "$" + element?.totalAmount.toLocaleString();
        const tax = element?.tax.toString() + "%";
        const totalWithTax = "$" + (element?.totalAmount + (element?.totalAmount * (element?.tax || 0)/100)).toLocaleString();
        const row = [
            name, 
            quantity,
            amount,
            total,
            tax,
            totalWithTax
        ];
        beddingTableRows.push(row);
    });

    doc.setFontSize(12);

    const totalBeddingFeeRow = [
        "Total Bedding Fees",
        "",
        "",
        "$" + totalBeddingFeeAmount.toLocaleString(),
        "",
        "$" + totalBeddingFeeWithTaxAmount.toLocaleString(),
    ];

    beddingTableRows.push(totalBeddingFeeRow);
    
    // startY is basically margin-top
    doc.autoTable(beddingTableColumns, beddingTableRows, { 
        theme: "grid",
        headStyles: {fillColor: "#73a4d3"},
        startY: doc.lastAutoTable.finalY + 25,
        drawCell: function(cell: any, data: any) {
            if (data.row.index === data.table.rows.length - 1) {
                doc.setFontStyle('bold');
            }
        }
    });

    // page footer
    doc.setFontSize(10);
    doc.text("Created using RingSide Pro: www.ringsidepro.com", 14, doc.internal.pageSize.height - 10);

    // we define the name of our PDF file.
    doc.save(`revenue_from_stall_fees.pdf`);
}