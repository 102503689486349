import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import { USEFHorse } from "../../interfaces/USEFData";
import {  ECIndividual } from "../../interfaces/ECData";
import SuccessBanner from "../Banners/SuccessBanner";
import { BarnPerson } from "../../interfaces/Person";
import USEFPersonForm from "./USEFPersonForm";
import ECPersonForm from "./ECPersonForm";
import MembershipInfoForm from "./CreateMembershipForm";
import { CreateMembershipInput } from "../../API";
import { createMembership } from "../../utilities/membership/Membership";

interface _Props {
    person: BarnPerson
    onSubmit?: Function
}

const PersonMembershipForm: React.FC<_Props> = ({onSubmit, person}) => {
    const user = useContext(PersonContext);
    
    const [isLoading, setIsLoading] = useState(false);
    const [isUSEF, setIsUSEF] = useState(false);
    const [isEC, setIsEC] = useState(false);
    const [isOther, setIsOther] = useState(false);
    const [riderId, setRiderId] = useState("");
    const [ownerId, setOwnerId] = useState("");
    const [trainerId, setTrainerId] = useState("");
    const [success, setSuccess] = useState<string>("");
    const [error, setError] = useState<string>("");

    const getIds = (person: BarnPerson) => {
        if (person.riderId) setRiderId(person.riderId);
        if (person.trainerId) setTrainerId(person.trainerId);
        if (person.ownerId) setOwnerId(person.ownerId);
    }

    useEffect(() => {
        if (person) {
            getIds(person);
        } else {
            setRiderId("");
            setOwnerId("");
            setTrainerId("");
        }
    }, [person]);

    const handleButtonSelect = (value: string) => {
        if (value === "usef") {
            setIsUSEF(true);
            setIsEC(false);
            setIsOther(false);
            setSuccess("");
            setError("");
        } else if (value === "ec") {
            setIsUSEF(false);
            setIsEC(true);
            setIsOther(false);
            setSuccess("");
            setError("");
        } else if (value === "other") {
            setIsUSEF(false);
            setIsEC(false);
            setIsOther(true);
            setSuccess("");
            setError("");
        }
    }

    const handleUSEFMembership = async (usefNumber?: string, usefData?: USEFHorse) => {
        let input: CreateMembershipInput = {
            personId: user.id,
            name: "USEF",
            membershipId: usefNumber || "",
            membershipStatus: usefData?.USEFStatus || "",
            type: usefData?.USEFStatus || "",
            dateMembershipEnds: (usefData?.USEFEndDate ? usefData?.USEFEndDate : undefined),
        };
        if (riderId) {
            input["riderId"] = riderId;
        }
        if (ownerId) {
            input["ownerId"] = ownerId;
        }
        if (trainerId) {
            input["trainerId"] = trainerId;
        }
        const createResult = await createMembership(input);
        if (createResult.isSuccess) {
            setSuccess("Successfully added the USEF membership.");
            setError("");
            if (onSubmit) onSubmit();
        } else {
            setError("Could not add the membership.");
            setSuccess("");
        }
    }

    const handleECMembership = async (ecData: ECIndividual) => {
        let input: CreateMembershipInput = {
            personId: user.id,
            name: "EC",
            membershipId: ecData.EC_Sport_License_Number.toString(),
            membershipStatus: ecData.Not_In_Good_Standing ? "Not in Good Standing" : "In Good Standing"
        };
        if (riderId) {
            input["riderId"] = riderId;
        }
        if (ownerId) {
            input["ownerId"] = ownerId;
        }
        if (trainerId) {
            input["trainerId"] = trainerId;
        }
        const createResult = await createMembership(input);
        if (createResult.isSuccess) {
            setSuccess("Successfully added the EC membership.");
            setError("");
            if (onSubmit) onSubmit();
        } else {
            setError("Could not add the membership.");
            setSuccess("");
        }
    }

    const handleSubmit = () => {
        setSuccess("Successfully added the membership.");
        setError("");
        if (onSubmit) onSubmit();
    }

    return (
        <>
            {isLoading ?
                <Spinner/>
                :
                <>
                    {success && <SuccessBanner width="12" success={success} />}
                    {error && <ErrorAlert width="12" error={error} />}
                    <IonRow className="ion-justify-content-center">
                        {/* <IonCol sizeXs="12" sizeMd="4" className="ion-text-center">
                            <IonButton color={isUSEF ? "primary" : "light"} onClick={() => handleButtonSelect("usef")}>USEF</IonButton>
                        </IonCol> */}
                        <IonCol sizeXs="12" sizeMd="4" className="ion-text-center">
                            <IonButton color={isEC ? "primary" : "light"} onClick={() => handleButtonSelect("ec")}>EC</IonButton>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="4" className="ion-text-center">
                            <IonButton color={isOther ? "primary" : "light"} onClick={() => handleButtonSelect("other")}>Other</IonButton>
                        </IonCol>
                    </IonRow>
                    {isUSEF && (
                        <>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem color="white">
                                        <IonLabel position="stacked">Organization Name</IonLabel>
                                        <IonInput 
                                            type="text"
                                            value="United States Equestrian Federation"
                                            aria-required={true}
                                            disabled
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <USEFPersonForm onSubmit={handleUSEFMembership} />
                        </>
                    )}
                    {isEC && (
                        <>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem color="white">
                                        <IonLabel position="stacked">Organization Name</IonLabel>
                                        <IonInput 
                                            type="text"
                                            value="Equestrian Canada"
                                            aria-required={true}
                                            disabled
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <ECPersonForm onSubmit={handleECMembership} />
                        </>
                    )}
                    {isOther && (
                        <>
                            <MembershipInfoForm riderId={riderId} ownerId={ownerId} trainerId={trainerId} onSubmit={handleSubmit} />
                        </>
                    )}
                </>
            }
        </>
    );
};

export default PersonMembershipForm;