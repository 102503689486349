import {
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonRouterLink,
    IonRow,
    isPlatform,
} from "@ionic/react";
import {finalMenuOptions, mainMenuOptions} from "../../interfaces/Page";
import React, { useContext } from "react";
import {Role} from "../../utilities/roles/Roles";
import logo from "./../../assets/img/brand/MainLogo.png";
import {useHistory} from "react-router";
import { isWindows } from "../../utilities/platform/Platform";
import { OrganizationContext } from "../../context/OrganizationContext";
import CONSTANT from '../../constant/constant';
import { homeSharp, peopleSharp, personSharp } from "ionicons/icons";

interface _Props {
    roles?: Role[]
}

const Menu: React.FC<_Props> = ({roles}) => {
    const history = useHistory();

    const organization = useContext(OrganizationContext);
    let logoUrl = "";
    if (organization?.logos?.length) {
        logoUrl = organization.logos[0]?.logoURL ?? "";
    }

    // White labelling color change code. The below code replaces the values of --ion-color-tertiary and
    // --ion-color-primary color variables with dynamic colors selected for the organization. This will
    // affect for all pages within dashboard.
    const colors = organization?.colors;
    if(colors?.length) {
        document.documentElement.style.setProperty('--ion-color-tertiary', colors[0]);
        if(colors.length === CONSTANT.ORGANIZATION.BRANDING_MAX_COLORS) {
            document.documentElement.style.setProperty('--ion-color-primary', colors[1]);
        }
    } else {
        document.documentElement.style.setProperty('--ion-color-tertiary', CONSTANT.COLORS.TERTIARY);
        document.documentElement.style.setProperty('--ion-color-primary', CONSTANT.COLORS.PRIMARY);
    }
    return (
        <IonMenu contentId="main" id="side-menu" type="overlay" side={isPlatform("mobile") ? "end" : "start"}>
            <IonContent>
                {(organization && organization.id === "ddc2fd3c-0bde-4b39-bbbb-5e00d2a35ba0") ?
                    <>
                        <IonList id="menu-list" className="ion-margin-top">
                            {/* This row is the logo in the side menu */}
                            <IonRow className="ion-justify-content-center ion-margin-bottom">
                                <IonCol sizeMd="6" className="text-center">  
                                    <IonListHeader>
                                        {(isWindows() || isPlatform("desktop")) ?
                                            <>
                                                <IonRouterLink
                                                    routerDirection="none"
                                                    routerLink={organization?.urlBackHalf ? `/index/${organization?.urlBackHalf}/home` : "/index/home"}
                                                >
                                                    <img
                                                        className="main-img"
                                                        src={logoUrl.length > 0 ? logoUrl : logo}
                                                        alt={organization?.name ? organization.name : "RingSide Pro"}
                                                    />
                                                </IonRouterLink>
                                            </>
                                            :
                                            <IonMenuToggle autoHide={false}>
                                                <IonRouterLink
                                                    routerDirection="none"
                                                    routerLink={organization?.urlBackHalf ? `/index/${organization?.urlBackHalf}/home` : "/index/home"}
                                                >
                                                    <img
                                                        className="main-img"
                                                        src={logoUrl.length > 0 ? logoUrl : logo}
                                                        alt={organization?.name ? organization.name : "RingSide Pro"}
                                                    />
                                                </IonRouterLink>
                                            </IonMenuToggle>
                                        }
                                    </IonListHeader>
                                </IonCol>
                            </IonRow>
                            {/* This area is the top section of items in the side menu */}
                            {(isWindows() || isPlatform("desktop")) ?
                                <>
                                    <IonItem 
                                        className={history?.location.pathname === "/index/ael/home" ? "selected" : ""} 
                                        routerLink="/index/ael/home"
                                        routerDirection="none"
                                        lines="none"
                                        detail={false}
                                    >
                                        <IonIcon slot="start" ios={homeSharp} md={homeSharp} />
                                        <IonLabel>Dashboard</IonLabel>
                                    </IonItem>
                                    <IonItem 
                                        className={history?.location.pathname === "/index/team" ? "selected" : ""} 
                                        routerLink="/index/team"
                                        routerDirection="none"
                                        lines="none"
                                        detail={false}
                                    >
                                        <IonIcon slot="start" ios={peopleSharp} md={peopleSharp} />
                                        <IonLabel>My Team</IonLabel>
                                    </IonItem>
                                </>
                                :
                                <>
                                    <IonMenuToggle autoHide={false}>
                                        <IonItem
                                            className={history?.location.pathname === "/index/team" ? "selected" : ""} 
                                            routerLink={"/index/team"} 
                                            routerDirection="none"
                                            lines="none" 
                                        >
                                            <IonIcon slot="start" icon={peopleSharp} />
                                            <IonLabel>My Team</IonLabel>
                                        </IonItem>
                                    </IonMenuToggle>
                                </>
                            }
                        </IonList>
                        <IonList id="roles-list">
                            <IonListHeader>My Info</IonListHeader>
                            {(isWindows() || isPlatform("desktop")) ?
                                <>
                                    <IonItem 
                                        className={history?.location.pathname === "/index/profile" ? "selected" : ""} 
                                        routerLink="/index/profile"
                                        routerDirection="none"
                                        lines="none"
                                        detail={false}
                                    >
                                        <IonIcon slot="start" ios={personSharp} md={personSharp} />
                                        <IonLabel>My Profile</IonLabel>
                                    </IonItem>
                                </>
                                :
                                <>
                                    <IonMenuToggle autoHide={false}>
                                        <IonItem
                                            className={history?.location.pathname === "/index/profile" ? "selected" : ""} 
                                            routerLink={"/index/profile"} 
                                            routerDirection="none"
                                            lines="none" 
                                        >
                                            <IonIcon slot="start" icon={personSharp} />
                                            <IonLabel>My Profile</IonLabel>
                                        </IonItem>
                                    </IonMenuToggle>
                                </>
                            }
                        </IonList>
                    </>
                    :
                    <>
                        <IonList id="menu-list" className="ion-margin-top">
                            <IonRow className="ion-justify-content-center ion-margin-bottom">
                                <IonCol sizeMd="6" className="text-center">  
                                    <IonListHeader>
                                        {(isWindows() || isPlatform("desktop")) ?
                                            <>
                                                <IonRouterLink
                                                    routerDirection="none"
                                                    routerLink={organization?.urlBackHalf ? `/index/${organization?.urlBackHalf}/home` : "/index/home"}
                                                >
                                                    <img
                                                        className="main-img"
                                                        src={logoUrl.length > 0 ? logoUrl : logo}
                                                        alt={organization?.name ? organization.name : "RingSide Pro"}
                                                    />
                                                </IonRouterLink>
                                            </>
                                            :
                                            <IonMenuToggle autoHide={false}>
                                                <IonRouterLink
                                                    routerDirection="none"
                                                    routerLink={organization?.urlBackHalf ? `/index/${organization?.urlBackHalf}/home` : "/index/home"}
                                                >
                                                    <img
                                                        className="main-img"
                                                        src={logoUrl.length > 0 ? logoUrl : logo}
                                                        alt={organization?.name ? organization.name : "RingSide Pro"}
                                                    />
                                                </IonRouterLink>
                                            </IonMenuToggle>
                                        }
                                    </IonListHeader>
                                </IonCol>
                            </IonRow>
                            {mainMenuOptions.map((appPage, index) => {
                                return (
                                    <div key={index}>
                                        {((!isWindows()) && isPlatform("mobile")) ? 
                                            <IonMenuToggle autoHide={false}>
                                                <IonItem 
                                                    className={history?.location.pathname === appPage.path ? "selected" : ""} 
                                                    routerLink={organization?.urlBackHalf && appPage.isOrganizationPagePresent ? `/index/${organization?.urlBackHalf}/${appPage.path}` : `/index/${appPage.path}`}
                                                    routerDirection="none"
                                                    lines="none"
                                                    detail={false}
                                                >
                                                    {appPage.icon ?
                                                        <IonIcon slot="start" ios={appPage.icon} md={appPage.icon} />
                                                        :
                                                        <IonIcon slot="start" src={appPage.img} />
                                                    }
                                                    <IonLabel>{appPage.title}</IonLabel>
                                                </IonItem>
                                            </IonMenuToggle>
                                            :
                                            <IonItem 
                                                className={history?.location.pathname === appPage.path ? "selected" : ""} 
                                                routerLink={organization?.urlBackHalf && appPage.isOrganizationPagePresent ? `/index/${organization?.urlBackHalf}/${appPage.path}` : `/index/${appPage.path}`}
                                                routerDirection="none"
                                                lines="none"
                                                detail={false}
                                            >
                                                {appPage.icon ?
                                                    <IonIcon slot="start" ios={appPage.icon} md={appPage.icon} />
                                                    :
                                                    <IonIcon slot="start" src={appPage.img} />
                                                }
                                                <IonLabel>{appPage.title}</IonLabel>
                                            </IonItem>
                                        }
                                    </div>
                                );
                            })}
                        </IonList>
                        {roles && roles.map((role, index) => (
                            <div key={index}>
                                <IonList id="roles-list">
                                    <IonListHeader>{role ? role.name : ""}</IonListHeader>
                                    {(role && role.routes) && role.routes.map((option, index) => (
                                        <div key={index}>
                                            {((!isWindows()) && isPlatform("mobile")) ? 
                                                <IonMenuToggle autoHide={false}>
                                                    <IonItem 
                                                        className={history?.location.pathname === option.path ? "selected" : ""} 
                                                        routerLink={option.path} 
                                                        routerDirection="none"
                                                        lines="none" 
                                                        key={index}
                                                    >
                                                        <IonIcon slot="start" icon={option.icon} />
                                                        <IonLabel>{option.title}</IonLabel>
                                                    </IonItem>
                                                </IonMenuToggle>
                                                :
                                                <IonItem 
                                                    className={history?.location.pathname === option.path ? "selected" : ""} 
                                                    routerLink={option.path} 
                                                    routerDirection="none"
                                                    lines="none" 
                                                    key={index}
                                                >
                                                    <IonIcon slot="start" icon={option.icon} />
                                                    <IonLabel>{option.title}</IonLabel>
                                                </IonItem>
                                            }
                                        </div>
                                    ))}
                                </IonList>
                            </div>
                        ))}
                    </>
                }

                <hr className="ion-no-margin"></hr>
                <IonList>
                    {finalMenuOptions.map((appPage, index) => {
                        return (
                            <div key={index}>
                                {((!isWindows()) && isPlatform("mobile")) ?
                                    <IonMenuToggle autoHide={false}>
                                        <IonItem 
                                            className={history?.location.pathname === appPage.path ? "selected" : ""} 
                                            routerLink={appPage.path} 
                                            routerDirection="none"
                                            lines="none"
                                            detail={false}
                                        >
                                            <IonIcon slot="start" ios={appPage.icon} md={appPage.icon} />
                                            <IonLabel>{appPage.title}</IonLabel>
                                        </IonItem>
                                    </IonMenuToggle>
                                    :
                                    <IonItem 
                                        className={history?.location.pathname === appPage.path ? "selected" : ""} 
                                        routerLink={appPage.path} 
                                        routerDirection="none"
                                        lines="none"
                                        detail={false}
                                    >
                                        <IonIcon slot="start" ios={appPage.icon} md={appPage.icon} />
                                        <IonLabel>{appPage.title}</IonLabel>
                                    </IonItem>
                                }
                            </div>
                        );
                    })}
                </IonList>

            </IonContent>
        </IonMenu>
    );
};
  
export default Menu;
  