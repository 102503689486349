import { Event } from "../../models";
import {
    IonCol,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { getStartDate, getEndDate } from "../../utilities/dates/EventDates";
import { formatDateShortFormat } from "../../utilities/dates/FormatDates";
import { getEventDaysByEventId } from "../../utilities/eventDay/EventDay";
import { formatAddress } from "../../utilities/address/FormatAddress";

interface _Props {
    event?: Event
}

const EntryFormHeader: React.FC<_Props> = ({event}) => {

    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");

    useEffect(() => {
        async function getEventDates(eventId: string) {
            const queryResult = await getEventDaysByEventId(eventId);
            if (queryResult.isSuccess) {
                const dates = queryResult.result;
                if (dates && dates.length) {
                    const startDateObject = getStartDate(dates);
                    const endDateObject = getEndDate(dates); 
                    if (startDateObject) setStartDate(formatDateShortFormat(startDateObject));
                    if (endDateObject) setEndDate(formatDateShortFormat(endDateObject));
                }
            }
        }
        if (event) getEventDates(event.id);
    }, [event]);

    return (
        <>
            <IonRow className="ion-justify-content-center">
                <IonCol sizeMd="10" className="text-center">
                    <IonText><h1>{event?.name}</h1></IonText>
                </IonCol>
            </IonRow>
            {event?.address && (
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeMd="10" className="text-center">
                        <IonText>{event.address && formatAddress(event.address)}</IonText>
                    </IonCol>
                </IonRow>
            )}
            {startDate && (startDate !== endDate) ? 
                <>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            <IonText>Dates: {startDate} - {endDate}</IonText>
                        </IonCol>
                    </IonRow>
                </>
                :
                <IonRow className="ion-justify-content-center">
                    <IonCol className="ion-text-center">
                        <IonText>Date: {startDate}</IonText>
                    </IonCol>
                </IonRow>
            }
        </>
    );
};

export default EntryFormHeader;