import moment from "moment";
import { BeddingRequest, Contact, Event, EventEntry, EventEntryFee, Person, StablingRequest, Trainer } from "../../models";
import { getAcceptedBeddingRequestsByEventId, getNotAcceptedBeddingRequestsByEventId } from "../beddingRequest/BeddingRequest";
import { getEventEntriesByEventId, getEventEntryById } from "../eventEntry/EventEntry";
import { sortEventEntriesByUpdatedDate } from "../eventEntry/SortEventEntry";
import { getEventEntryFeesByEventIdByEntryId } from "../eventEntryFee/EventEntryFee";
import { getPersonByPersonId } from "../person/Person";
import { sortStablingRequestsByStallType, sortStablingRequestsByTrainer } from "../stablingRequest/SortStablingRequests";
import { getAcceptedStablingRequestsByEventId, getNotAcceptedStablingRequestsByEventId, getStablingRequestsByEventId} from "../stablingRequest/StablingRequest";
import { getTrainerById } from "../trainer/Trainer";
import { addFooters } from "./ReportFooter";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

export async function generateStablingPDF(event: Event, isByStallType?: boolean, notAccepted?: boolean) {
    // initialize jsPDF
    const doc = new jsPDF("l");

    // page title. and margin-top + margin-left
    doc.text(`${event.name}`, 14, 15);
    doc.setFontSize(10);
    doc.text(`Stabling Requests By Stall Type`, 14, 20);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);

    // Track total stalls needed of different types
    let stallTypeMap = new Map<string, number>();

    let totalShavingsNeeded = 0;
    // Track shavings event fees already listed in report
    let beddingMap = new Map<string, BeddingRequest>();
    let shavingsMap = new Map<string, EventEntryFee>();

    // Track person or trainer's contact info
    let trainerContactMap = new Map<string, string>();

    // define the columns we want and their titles
    let tableColumns = [];
    if (isByStallType) {
        tableColumns = [
            {
                title: "Stall Type",
                dataKey: "stall_type"
            },
            {
                title: "Created By",
                dataKey: "submitted_by"
            },
            {
                title: "Trainer",
                dataKey: "trainer_name"
            },
            {
                title: "Barn",
                dataKey: "barn_name"
            },
            {
                title: "Contact Info",
                dataKey: "contact_info"
            },
            {
                title: "Stalls #",
                dataKey: "quantity"
            },
            {
                title: "Status",
                dataKey: "status"
            },
            {
                title: "Shavings #",
                dataKey: "shavings"
            },
            {
                title: "Horse",
                dataKey: "horse_name"
            },
            {
                title: "Note",
                dataKey: "note"
            }
        ];
    } else {
        tableColumns = [
            {
                title: "Trainer",
                dataKey: "trainer_name"
            },
            {
                title: "Barn",
                dataKey: "barn_name"
            },
            {
                title: "Created By",
                dataKey: "submitted_by"
            },
            {
                title: "Contact Info",
                dataKey: "contact_info"
            },
            {
                title: "Stall Type",
                dataKey: "stall_type"
            },            
            {
                title: "Stalls #",
                dataKey: "quantity"
            },
            {
                title: "Status",
                dataKey: "status"
            },
            {
                title: "Shavings #",
                dataKey: "shavings"
            },
            {
                title: "Horse",
                dataKey: "horse_name"
            },
            {
                title: "Note",
                dataKey: "note"
            }
        ];
    }
    // define an empty array of rows
    const tableRowsSets = [];
    let tableRows = [];

    let beddingRequests: BeddingRequest[] = [];
    if (notAccepted) {
        const beddingRequestsResult = await getNotAcceptedBeddingRequestsByEventId(event.id);
        if (beddingRequestsResult.isSuccess) {
            beddingRequests = beddingRequestsResult.result;
        }
    } else {
        const beddingRequestsResult = await getAcceptedBeddingRequestsByEventId(event.id);
        if (beddingRequestsResult.isSuccess) {
            beddingRequests = beddingRequestsResult.result;
        }
    }

    let queryResult: any;
    if (notAccepted) queryResult = await getNotAcceptedStablingRequestsByEventId(event.id);
    else queryResult = await getAcceptedStablingRequestsByEventId(event.id);
    if (queryResult.isSuccess) {
        const stablingRequests = queryResult.result;
        const sorted = isByStallType ? sortStablingRequestsByStallType(stablingRequests) : sortStablingRequestsByTrainer(stablingRequests);
        
        // If sort by trainer, track trainer groups
        let previousTrainerName = "";
        let trainerStallsTotal = 0;
        let trainerShavingsTotal = 0;

        if (sorted) {
            for (let index = 0; index < sorted.length; index++) {
                const stablingRequest = sorted[index];

                const stallStatus = stablingRequest.status || "";

                // Stall Type Column
                if (stablingRequest.stallType?.name) {
                    const quantity = stablingRequest.quantityNeeded || 0;
                    const mapResult = stallTypeMap.get(stablingRequest.stallType?.name);
                    if (mapResult) {
                        const updatedMapResult = mapResult + quantity;
                        stallTypeMap.set(stablingRequest.stallType?.name, updatedMapResult);
                    } else {
                        stallTypeMap.set(stablingRequest.stallType?.name, quantity);
                    }
                }
                let stallTypeColumn = stablingRequest.stallType?.name || "";

                // Submitted By Column
                let person: Person | null | undefined = stablingRequest.person;
                let submittedByColumn = "";
                if (!person) {
                    const personId = stablingRequest.personId;
                    if (personId) {
                        const queryResult = await getPersonByPersonId(personId);
                        if (queryResult.isSuccess) {
                            person = queryResult.result;
                        }
                    }
                }
                if (person) {
                    const personName = person.firstName + " " + person.lastName;
                    submittedByColumn = personName || "";
                }

                // Trainer Name Column
                let trainerNameColumn = (stablingRequest.trainer?.name || "");

                // Check if we are on a new trainer now
                if (!isByStallType && previousTrainerName !== "" && previousTrainerName !== trainerNameColumn) {
                    const totalRow = [
                        "TOTAL",
                        "",
                        "",
                        "",
                        "",
                        trainerStallsTotal,
                        "",
                        trainerShavingsTotal,
                        "", 
                        ""
                    ];
                    tableRows.push(totalRow);
                    tableRowsSets.push(tableRows);
                    tableRows = [];
                    trainerStallsTotal = 0;
                    trainerShavingsTotal = 0;
                }
                previousTrainerName = trainerNameColumn;

                // Barn Name Column
                let barnNameColumn = (stablingRequest.barn?.name || "");

                // Contact Info Column
                let contact: Contact | undefined = undefined;
                let contactInfo: string = "";
                if (stablingRequest.trainerId) {
                    // First, try to get the trainer's contact info
                    const trainerContactMapResult = trainerContactMap.get(stablingRequest?.trainerId);
                    if (trainerContactMapResult) {
                        contactInfo = trainerContactMapResult;
                    } else {
                        if (stablingRequest.trainer?.contact) {
                            contact = stablingRequest.trainer?.contact;
                        } else if (stablingRequest.trainerId) {
                            const queryResult = await getTrainerById(stablingRequest.trainerId);
                            if (queryResult.isSuccess) {
                                const trainer: Trainer = queryResult.result;
                                if (trainer && trainer.contact) {
                                    contact = trainer.contact;
                                }
                            }
                        }
                        
                        if (contact) {
                            const emailAddress = contact.workEmail || contact.personalEmail;
                            const phoneNumber = contact.work || contact.cell || contact.home;
                            contactInfo = emailAddress + ((emailAddress && phoneNumber) ? " - " : "") + phoneNumber;
                            trainerContactMap.set(stablingRequest.trainerId, contactInfo);
                        } else {
                            // If there is no trainer contact info, try to get the submitted person's contact info
                            if (stablingRequest.personId) {
                                const personContactMapResult = trainerContactMap.get(stablingRequest?.personId);
                                if (personContactMapResult) {
                                    contactInfo = personContactMapResult;
                                } else {
                                    if (person && person.email) {
                                        contactInfo = person.email;
                                        trainerContactMap.set(person.id, contactInfo);
                                    }
                                }
                            }
                        }
                    }
                }
                

                // Stall Quantity Needed Column
                let stallQuantityColumn = (stablingRequest.quantityNeeded ? stablingRequest.quantityNeeded.toString() : "");
                trainerStallsTotal = trainerStallsTotal + (stablingRequest.quantityNeeded || 0);

                // Horse Name Column & Shavings Column
                let horseNameColumn = "";
                let shavingsQuantityColumn = "";
                if (stablingRequest.entryIds && stablingRequest.entryIds.length > 1) {
                    horseNameColumn = "Split across entries";
                } else if (stablingRequest.entryIds && stablingRequest.entryIds[0]) {
                    const entryResult = await getEventEntryById(stablingRequest.entryIds[0]);
                    if (entryResult.isSuccess) {
                        const entry = entryResult.result;
                        if (entry && entry.horseName) horseNameColumn = entry.horseName;

                        // Look for bedding / shavings
                        let entryBeddingRequests = [];
                        if (beddingRequests) {
                            for (let i = 0; i < beddingRequests.length; i++) {
                                const current = beddingRequests[i];
                                if (current.entryIds && current.entryIds.length > 0) {
                                    for (let j = 0; j < current.entryIds.length; j++) {
                                        const entryId = current.entryIds[j];
                                        if (entryId && entryId === entry.id) {
                                            entryBeddingRequests.push(current);
                                            const mapResult = beddingMap.get(current.id);
                                            if (mapResult) {
                                                // Already been put on the report, ignore it
                                            } else {
                                                shavingsQuantityColumn = current.quantityNeeded ? current.quantityNeeded.toString() : "";
                                                trainerShavingsTotal = trainerShavingsTotal + (current.quantityNeeded || 0);
                                                beddingMap.set(current.id, current);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        let shavingFee: EventEntryFee | undefined = undefined;
                        const queryFeeResult = await getEventEntryFeesByEventIdByEntryId(event.id, entry.id);
                        if (queryFeeResult.isSuccess) {
                            const eventEntryFees: EventEntryFee[] = queryFeeResult.result;
                            if (eventEntryFees && eventEntryFees.length > 0) {
                                for (let i = 0; i < eventEntryFees.length; i++) {
                                    const eventEntryFee: EventEntryFee = eventEntryFees[i];
                                    if (eventEntryFee && eventEntryFee.name.toLowerCase().includes("shaving")) {
                                        shavingFee = eventEntryFee;
                                        break;
                                    }
                                }
                                if (shavingFee) {
                                    const mapResult = shavingsMap.get(shavingFee.id);
                                    if (mapResult) {
                                        // Already been put on the report, ignore it
                                    } else {
                                        shavingsQuantityColumn = (shavingFee.quantity ? shavingFee.quantity.toString() : "");
                                        totalShavingsNeeded = totalShavingsNeeded + (shavingFee.quantity || 0);
                                        trainerShavingsTotal = trainerShavingsTotal + (shavingFee.quantity || 0);
                                        shavingsMap.set(shavingFee.id, shavingFee);
                                    }
                                }
                            } 
                        }
                    }
                }

                // Stall Request Note Column
                let noteColumn = stablingRequest.requestNote || "";

                let tableRow: string[] = [];
                if (isByStallType) {
                    tableRow = [
                        stallTypeColumn,
                        submittedByColumn,
                        trainerNameColumn,
                        barnNameColumn,
                        contactInfo,
                        stallQuantityColumn,
                        stallStatus,
                        shavingsQuantityColumn,
                        horseNameColumn, 
                        noteColumn
                    ];
                } else {
                    tableRow = [
                        trainerNameColumn,
                        barnNameColumn,
                        submittedByColumn,
                        contactInfo,
                        stallTypeColumn,
                        stallQuantityColumn,
                        stallStatus,
                        shavingsQuantityColumn,
                        horseNameColumn, 
                        noteColumn
                    ];
                }
                tableRows.push(tableRow);
            }
        }
    }

    tableRowsSets.push(tableRows);

    let yCoord = 35;

    // First, print out the total number needed for each stall type
    doc.setFontSize(12);
    stallTypeMap.forEach((key, value) => {
        doc.text(`Total ${value}: ${key}`, 14, yCoord);
        yCoord = yCoord + 5;
    });

    if (totalShavingsNeeded !== 0) {
        doc.text(`Total Shavings: ${totalShavingsNeeded}`, 14, yCoord);
        yCoord = yCoord + 5;
    }

    // Break with a line
    doc.line(0, yCoord, doc.internal.pageSize.getWidth(), yCoord);

    yCoord = yCoord + 5;
    
    // Next, print out the table(s) of stall info
    if (isByStallType) {
        doc.autoTable(tableColumns, tableRows, { 
            theme: "grid",
            headStyles: {fillColor: "#73a4d3"},
            startY: yCoord,
            rowPageBreak: "avoid",
            columnStyles: {
                stall_type: { cellWidth: 'auto' }, 
                submitted_by: { cellWidth: 'auto' },
                trainer_name: { cellWidth: 'auto' },
                barn_name: { cellWidth: 'auto' },
                contact_info: { cellWidth: 25 },
                quantity: { cellWidth: 'auto' },
                shavings: { cellWidth: 'auto' },
                horse_name: { cellWidth: 'auto' },
                note: { cellWidth: 'auto' },
            }
        });
    } else {
        if (tableRowsSets && tableRowsSets.length > 0) {
            for (let i = 0; i < tableRowsSets.length; i++) {
                const currentSet = tableRowsSets[i];
                doc.autoTable(tableColumns, currentSet, { 
                    theme: "grid",
                    headStyles: {fillColor: "#73a4d3"},
                    startY: i === 0 ? yCoord : doc.lastAutoTable.finalY + 15,
                    rowPageBreak: "avoid",
                    columnStyles: {
                        stall_type: { cellWidth: 'auto' }, 
                        submitted_by: { cellWidth: 'auto' },
                        trainer_name: { cellWidth: 'auto' },
                        barn_name: { cellWidth: 'auto' },
                        contact_info: { cellWidth: 25 },
                        quantity: { cellWidth: 'auto' },
                        shavings: { cellWidth: 'auto' },
                        horse_name: { cellWidth: 'auto' },
                        note: { cellWidth: 'auto' },
                    },
                    drawCell: function(cell: any, data: any) {
                        if (data.row.index === data.table.rows.length - 1) {
                            doc.setFontStyle('bold');
                        }
                    } 
                });
            }
        }
    }

    // page footer
    addFooters(doc);

    // we define the name of our PDF file.
    doc.save(`${notAccepted ? "not_accepted" : "accepted"}_stabling_by_${isByStallType ? "stall_type" : "trainer"}.pdf`);
}

const getStablingRequestsByEventByEntry = async (eventStablingRequests: StablingRequest[], entry: EventEntry) => {
    let requestList: StablingRequest[] = [];
    eventStablingRequests.forEach((sr: StablingRequest) => {
        const currentEntries = sr.entryIds;
        if (currentEntries && currentEntries.length) {
            currentEntries.forEach(currentEntry => {
                if (currentEntry === entry.id) requestList.push(sr);
            });
        }
    });
    return requestList;
}

export async function generateEntriesWithoutStablingPDF(event: Event) {
    // initialize jsPDF
    const doc = new jsPDF("l");

    // page title. and margin-top + margin-left
    doc.text(`${event.name}`, 14, 15);
    doc.setFontSize(10);
    doc.text(`Entries without Stabling Requests`, 14, 20);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);

    // Track person or trainer's contact info
    let trainerContactMap = new Map<string, string>();

    // define the columns we want and their titles
    let tableColumns = [
        {
            title: "Trainer Name",
            dataKey: "trainer_name"
        },
        {
            title: "Barn Name",
            dataKey: "barn_name"
        },
        {
            title: "Submitted By",
            dataKey: "submitted_by"
        },
        {
            title: "Contact Info",
            dataKey: "contact_info"
        },
        {
            title: "Horse Name",
            dataKey: "horse_name"
        },
        {
            title: "Back #",
            dataKey: "back_number"
        },
        {
            title: "Entry Status",
            dataKey: "entry_status"
        },
        {
            title: "Last Updated",
            dataKey: "last_updated"
        }
    ];
    // define an empty array of rows
    const tableRows = [];

    const queryStablingResult = await getStablingRequestsByEventId(event.id);
    if (queryStablingResult.isSuccess) {
        const stablingRequests: StablingRequest[] = queryStablingResult.result;
        if (stablingRequests && stablingRequests.length > 0) {
            const queryResult = await getEventEntriesByEventId(event.id);
            if (queryResult.isSuccess) {
                const eventEntries: EventEntry[] = queryResult.result;
                const sorted = sortEventEntriesByUpdatedDate(eventEntries);
                const entryArray = sorted || eventEntries;
                for (let i = 0; i < entryArray.length; i++) {
                    const eventEntry: EventEntry = entryArray[i];
                    if (eventEntry) {
                        const stablingRequestList = await getStablingRequestsByEventByEntry(stablingRequests, eventEntry);
                        if (stablingRequestList && stablingRequestList.length > 0) {
                            // This entry has stabling requested, ignore them
                        } else {
                            // Add this entry to the report
                            
                            // Submitted By Column
                            let submittedByColumn = "";
                            let person: Person | undefined = undefined;
                            const personId = eventEntry.personId;
                            if (personId) {
                                const queryResult = await getPersonByPersonId(personId);
                                if (queryResult.isSuccess) {
                                    person = queryResult.result;
                                }
                            }
                            if (person) {
                                const personName = person.firstName + " " + person.lastName;
                                submittedByColumn = personName || "";
                            }

                            // Contact Info Column
                            let contact: Contact | undefined = undefined;
                            let contactInfo: string = "";
                            if (eventEntry.trainerId) {
                                // First, try to get the trainer's contact info
                                const trainerContactMapResult = trainerContactMap.get(eventEntry?.trainerId);
                                if (trainerContactMapResult) {
                                    contactInfo = trainerContactMapResult;
                                } else {
                                    if (eventEntry.trainer?.contact) {
                                        contact = eventEntry.trainer?.contact;
                                    } else if (eventEntry.trainerId) {
                                        const queryResult = await getTrainerById(eventEntry.trainerId);
                                        if (queryResult.isSuccess) {
                                            const trainer: Trainer = queryResult.result;
                                            if (trainer && trainer.contact) {
                                                contact = trainer.contact;
                                            }
                                        }
                                    }
                                    
                                    if (contact) {
                                        const emailAddress = contact.workEmail || contact.personalEmail;
                                        const phoneNumber = contact.work || contact.cell || contact.home;
                                        contactInfo = emailAddress + ((emailAddress && phoneNumber) ? " - " : "") + phoneNumber;
                                        trainerContactMap.set(eventEntry.trainerId, contactInfo);
                                    } else {
                                        // If there is no trainer contact info, try to get the submitted person's contact info
                                        if (eventEntry.personId) {
                                            const personContactMapResult = trainerContactMap.get(eventEntry?.personId);
                                            if (personContactMapResult) {
                                                contactInfo = personContactMapResult;
                                            } else {
                                                if (person && person.email) {
                                                    contactInfo = person.email;
                                                    trainerContactMap.set(person.id, contactInfo);
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            let entryStatus = "";
                            if (eventEntry.status) {
                                if (eventEntry.status === "in_progress") entryStatus = "In Progress";
                                if (eventEntry.status === "submitted") entryStatus = "Submitted";
                                if (eventEntry.status === "complete") entryStatus = "Accepted";
                                if (eventEntry.status === "declined") entryStatus = "Declined";
                            }
                            
                            const tableRow = [
                                eventEntry.trainerName,
                                eventEntry.barnName || eventEntry.barn?.name || "",
                                submittedByColumn,
                                contactInfo,
                                eventEntry.horseName,
                                eventEntry.number || "",
                                entryStatus,
                                moment(eventEntry.updatedOn).format("MM-DD-YYYY h:mm a")
                            ];
                            tableRows.push(tableRow);
                        }
                    }
                }
            }
        }
    } 

    let yCoord = 35;

    // Next, print out the table of stall info
    doc.autoTable(tableColumns, tableRows, { 
        theme: "grid",
        headStyles: {fillColor: "#73a4d3"},
        startY: yCoord,
        rowPageBreak: "avoid"
    });

    // page footer
    addFooters(doc);

    // we define the name of our PDF file.
    doc.save(`entries_without_stabling.pdf`);
}