import React, { useEffect, useState } from "react";
import { Team } from "../../models";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";
import { getAllTeams } from "../../utilities/team/Team";
import { IonLabel, isPlatform } from "@ionic/react";
import { isWindows } from "../../utilities/platform/Platform";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";
import moment from "moment";

interface _Props {
    isDisabled?: boolean
    menuPlacement?: (string | null)
    selectedValue?: (string | null)
    onSelect: Function
}

const SelectTeam: React.FC<_Props> = ({isDisabled, selectedValue, menuPlacement, onSelect}) => {

    const [selected, setSelected] = useState<string | null | undefined>();
    const [formattedTeams, setFormattedTeams] = useState<formattedOption[] | null | undefined>();

    const getTeams = async () => {
        const queryResult = await getAllTeams();
        if (queryResult.isSuccess) {
            const teams: Team[] = queryResult.result;
            const currentTeams = teams.filter(t => moment(t.createdOn).isAfter("07-31-2024"));
            formatTeams(currentTeams);
        }
    }

    const formatTeams = (teams: Team[]) => {
        let formattedTeams = [];
        for (var i = 0; i < teams.length; i++) {
            const team = teams[i];
            const value = team.id;
            const label = team.name;
            let object: formattedOption = {
                value: value,
                label: label,
                object: teams[i]
            };
            formattedTeams.push(object);
        }
        setFormattedTeams(formattedTeams);
    }

    useEffect(() => {
        getTeams();
    }, []);

    useEffect(() => {
        if (selectedValue) setSelected(selectedValue);
        else setSelected("");
    }, [selectedValue]);

    const handleOnChange = (team?: any) => {
        if (team) {
            onSelect(team);
        } else {
            onSelect();
        }
    }

    return (
        <>
            <IonLabel position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>Select Team <RequiredInputIndicator/></IonLabel>
            {formattedTeams ?
                <BasicSelect formattedValues={formattedTeams} menuPlacement={menuPlacement} selectedValue={selected} isClearable={false} isDisabled={isDisabled} onSelect={handleOnChange} />
                :
                <p>Loading options...</p>
            }
        </>
    )
};

export default SelectTeam;
