import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import Header from "../../../components/Headers/Header";
import PageTitle from "../../../components/PageTitle/PageTitle";
import React from "react";
import SubmissionFeedbackList from "../../../components/Submission/SubmissionFeedbackList";
import { useHistory } from "react-router";

const PreviousSubmissionsPage: React.FC = () => {
    const history = useHistory();

    const navigateToPage = () => {
        const path = "/index/submissions/new";
        history.push(path);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Submission" />
                <IonRow id="name" className="ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="10">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Submit a Video</IonCardTitle>
                            <IonCardContent>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol className="ion-text-center">
                                        <p>Get custom feedback from certified clinicians that want to help you reach your goals.</p>
                                        <IonButton onClick={navigateToPage}>Create New Submission</IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonCardContent>
                        </IonCard>
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Your Submissions</IonCardTitle>
                            <IonCardSubtitle>Click on a row to see the submission.</IonCardSubtitle>
                            <IonCardContent>
                                <SubmissionFeedbackList />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default PreviousSubmissionsPage;