import { IonButton, IonCard, IonCardContent, IonCardTitle, IonCol, IonContent, IonPage, IonRow, isPlatform } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { Organization } from "../../../models";
import { PersonContext } from "../../../context/PersonContext";
import { getOrganizationByBackHalf } from "../../../utilities/organization/Organization";
import Header from "../../../components/Headers/Header";
import PageTitle from "../../../components/PageTitle/PageTitle";
import VerifyEmailAlert from "../../../components/Banners/VerifyEmailBanner";
import ErrorAlert from "../../../components/Errors/ErrorAlert";
import Spinner from "../../../components/Spinners/Spinner";
import DashboardPosts from "../../../components/Posts/DashboardPosts";
import MembershipApplicationsListNew from "../../../components/Applications/MembershipApplicationsListNew";
interface IProps extends RouteComponentProps<{
    orgId: string;
}> {
    setOrganizationHandler: Function;
}

const OrganizationDashboardPage: React.FC<IProps> = ({match, setOrganizationHandler}) => {
    const user = useContext(PersonContext);
    const [isLoading, setIsLoading] = useState(false);
    const [currentOrganization, setCurrentOrganization] = useState<Organization | null>(null);
    const [error, setError] = useState<string>("");
    const [isAELOrganization, setIsAELOrganization] = useState(false);

    useEffect(() => {
        const getOrganization = async (id: string) => {
            setIsLoading(true);
            setError("");
            const queryResult = await getOrganizationByBackHalf(id);
            if (queryResult.isSuccess) {
                const org: Organization = queryResult.result;
                setCurrentOrganization(queryResult.result);
                setOrganizationHandler(queryResult.result);
                if (org?.id === "ddc2fd3c-0bde-4b39-bbbb-5e00d2a35ba0") setIsAELOrganization(true);
            } else {
                setError("Error: please check that you have the correct URL or try navigating to www.ringsidepro.com/register");
            }
            setIsLoading(false);
        }
        
        getOrganization(match.params.orgId);
    }, [match.params.orgId]);

    return (
        <IonPage id="organizationDashboardPage" className="bg-light">
            <IonContent>
                {isPlatform("mobile") && <Header setOrganizationHandler={setOrganizationHandler}/>}

                {!user.id && <ErrorAlert error="A problem occurred and we could not find your data. Please contact hello@ringsidepro.com." />}

                <VerifyEmailAlert />

                <PageTitle title="Dashboard" setOrganizationHandler={setOrganizationHandler} showSwitchDropdown={true}/>

                {error && <ErrorAlert width="12" error={error} />}
                {isLoading && (
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <Spinner />
                        </IonCol>
                    </IonRow>
                )}
                <IonRow className="ion-align-items-stretch">
                    <IonCol sizeXs="12" sizeMd="6">
                        <IonCard color="white" className="ion-padding">
                            <IonCardTitle>
                                <h3>{currentOrganization?.nickname || currentOrganization?.name} Posts</h3>
                            </IonCardTitle>
                            <IonCardContent>
                                <DashboardPosts organization={currentOrganization}/>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                    <IonCol sizeXs="12" sizeMd="6">
                        <IonCard color="white" className="ion-padding">
                            <IonCardTitle>
                                <h3>Membership Applications</h3>
                            </IonCardTitle>
                            {isAELOrganization ? 
                                <IonCardContent>
                                    <MembershipApplicationsListNew organization={currentOrganization}/>
                                </IonCardContent>
                                :
                                <>
                                    <IonCardContent>
                                        <MembershipApplicationsListNew organization={currentOrganization}/>
                                    </IonCardContent>
                                    <hr/>
                                    <IonCardTitle>
                                        <h3>{`${(currentOrganization?.nickname || currentOrganization?.name) ?? ""} Memberships`}</h3>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <Link
                                            to={`/index/${currentOrganization?.urlBackHalf}/view-memberships/${currentOrganization?.id}`}
                                            className="link text-info"
                                        >
                                            View All Memberships
                                        </Link>
                                    </IonCardContent>
                                </>
                            }
                        </IonCard>
                    </IonCol>
                    {currentOrganization?.id !== "ddc2fd3c-0bde-4b39-bbbb-5e00d2a35ba0" && (
                        <IonCol sizeXs="12" sizeMd="6">
                            <IonCard color="white" className="ion-padding">
                                <IonCardTitle>
                                    <h3>Online Entries</h3>
                                </IonCardTitle>
                                <IonCardContent>
                                    <p>Find events, submit online entries and view prior entries.</p>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                            <Link
                                                to={`/index/events`}
                                                className="link text-info"
                                            >
                                                <IonButton color="tertiary">
                                                    Find Events
                                                </IonButton>
                                            </Link>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                            <Link
                                                to={`/index/entries`}
                                                className="link text-info"
                                            >
                                                <IonButton color="tertiary">
                                                    Your Entries
                                                </IonButton>
                                            </Link>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    )}
                    <IonCol sizeMd="6">
                        <IonCard color="white" className="ion-padding">
                            <IonCardTitle>
                                <h3>Want some help?</h3>
                            </IonCardTitle>
                            <IonCardContent>
                                {isAELOrganization ?
                                    <p>Contact AEL for help! Email us at jenna@athleticequestrian.com.</p>
                                    :
                                    <p>Contact RingSide Pro customer service! We're always happy to help. Email us at hello@ringsidepro.com.</p>
                                }
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>

            </IonContent>
        </IonPage>
    );
}

export default OrganizationDashboardPage;
