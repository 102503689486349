import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonTextarea,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Table } from "reactstrap";
import { CreateEventEntryFeeInput, CreateMembershipInput, UpdateEventClassEntryInput, UpdateEventEntryInput, UpdateMembershipInput, UpdateOrganizationMembershipTypeInput } from "../../../API";
import { PersonContext } from "../../../context/PersonContext";
import { Event, EventEntry, EventEntryFee, EventFee, Membership, OrganizationMembershipType, Rider } from "../../../models";
import { getEventClassEntriesByEventIdEntryId, updateEventClassEntry } from "../../../utilities/eventClassEntry/EventClassEntry";
import { updateEventEntry } from "../../../utilities/eventEntry/EventEntry";
import { sendEntryStatusDeclinedEmail } from "../../../utilities/eventEntry/EventEntryEmail";
import { createEventEntryFee, getEventEntryFeesByEventIdByEntryId } from "../../../utilities/eventEntryFee/EventEntryFee";
import { updateEventEntryFeeStatusByEventByEntry } from "../../../utilities/eventEntryFee/UpdateEventEntryFeeStatus";
import { getEventFeesByEventId } from "../../../utilities/eventFee/EventFee";
import { createMembership, updateMembership } from "../../../utilities/membership/Membership";
import { updateBeddingRequestStatusByEntryId, updateStablingRequestStatusByEntryId } from "../../../utilities/stablingRequest/StablingRequestStatus";
import ErrorAlert from "../../Errors/ErrorAlert";
import Spinner from "../../Spinners/Spinner";
import CONSTANT from "../../../constant/constant";
import { getOrganizationMembershipTypesByOrganizationId, updateOrganizationMembershipType } from "../../../utilities/organizationMembershipType/OrganizationMembershipType";
import { getNextAvailableMembershipId } from "../../../utilities/organizationMembershipType/MembershipIdValues";
import RequiredInputIndicator from "../../Forms/RequiredInputIndicator";
import { getDateMembershipEnds, getMembershipStatus } from "../../../utilities/membership/MembershipStatus";
import { checkHorseNamesMatchOnMembershipAndEntry, checkRiderNamesMatchOnMembershipAndEntry, getEventEntryRiderAndHorseActiveMemberships } from "../../../utilities/membership/EventEntryMembership";
import constants from "../../../constant/constant";

interface _Props {
    entry: EventEntry
    event: Event
    onCalculate?: Function
}

interface FormattedMembership {
    id: string
    backNumber: number
    status: string
    horseName: string
    personName: string
    showsAttended: number
    membershipType: string
    membership: Membership
    organizationMembershipType?: OrganizationMembershipType
    isSelected: boolean
}

const AcceptEntryForm: React.FC<_Props> = ({entry, event}) => {
    const history = useHistory();
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null | undefined>();
    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();
    const [memberships, setMemberships] = useState<Membership[] | null | undefined>();
    const [formattedMemberships, setFormattedMemberships] = useState<FormattedMembership[] | null | undefined>();
    const [selectedMembership, setSelectedMembership] = useState<Membership | undefined | null>();
    const [addNonMemberFee, setAddNonMemberFee] = useState(true);
    const [addNonMemberMembership, setAddNonMemberMembership] = useState(true);
    const [isAccepted, setIsAccepted] = useState<string | null | undefined>("true");
    const [numberShowsAttended, setNumberShowsAttended] = useState<number | null | undefined>();
    const [number, setNumber] = useState<number | null | undefined>();
    const [declineReason, setDeclineReason] = useState<string | null | undefined>();

    const verifyForm = () => {
        if (isAccepted === "false" && !declineReason) {
            setError("Please include a reason you are declining this entry.");
            return false;
        } 
        setError("");
        return true;
    }

    const handleSelectMembership = (index: number, value: boolean) => {
        const formattedMemberships: FormattedMembership[] = [];
        if (memberships) {
            let shoudlAddNonMemberFields = false;

            for (let i = 0; i < memberships.length; i++) {
                const currentMembership = memberships[i];

                if (i === index && currentMembership.name.toLowerCase().includes("non")) {
                    shoudlAddNonMemberFields = true;
                }

                const formatted: FormattedMembership = {
                    id: currentMembership.id,
                    backNumber: currentMembership.backNumber || 0,
                    status: getMembershipStatus(currentMembership),
                    horseName: currentMembership.horse?.name || "",
                    personName: currentMembership.rider?.name || "",
                    showsAttended: currentMembership.showsAttended || 0,
                    membership: currentMembership,
                    membershipType: currentMembership.type || currentMembership.organizationMembershipType?.name || "",
                    organizationMembershipType: currentMembership.organizationMembershipType || undefined,
                    isSelected: (i === index) ? value : false
                };
                formattedMemberships.push(formatted);

                // Make updates to the UI based on which membership was selected
                if (i === index) {
                    setNumber(value === true ? currentMembership.backNumber : 0);
                    setNumberShowsAttended(value === true ? ((currentMembership.showsAttended || 0) + 1) : 0);
                    setSelectedMembership(value === true ? currentMembership : undefined);
                }
            }

            setAddNonMemberFee(shoudlAddNonMemberFields);
            setAddNonMemberMembership(shoudlAddNonMemberFields);
        }
        setFormattedMemberships(formattedMemberships);
    }

    const checkIfMembershipShouldBeSelected = (mem: Membership, entry: EventEntry) => {
        const riderNamesMatch = checkRiderNamesMatchOnMembershipAndEntry(mem, entry);
        const riderIdsMatch = mem.rider?.id === entry.rider?.id || mem.rider?.id === entry.riderId;
        const horseNamesMatch = checkHorseNamesMatchOnMembershipAndEntry(mem, entry);
        const horseIdsMatch = mem.horse?.id === entry.horse?.id || mem.rider?.id === entry.horseId;
        return (riderNamesMatch || riderIdsMatch) && (horseNamesMatch || horseIdsMatch);
    }

    const formatMemberships = (memberships: Membership[]) => {
        const formattedMemberships: FormattedMembership[] = [];
        if (memberships) {
            let foundASelectedRow = false;
            for (let i = 0; i < memberships.length; i++) {
                const currentMembership = memberships[i];
                let isSelected = false;
                const membershipStatus = getMembershipStatus(currentMembership);

                // If the no row has been found (aka selected), check if this row should be selected
                if (!foundASelectedRow) {
                    isSelected = checkIfMembershipShouldBeSelected(currentMembership, entry);
                }
                const formatted: FormattedMembership = {
                    id: currentMembership.id,
                    backNumber: currentMembership.backNumber || 0,
                    status: membershipStatus,
                    horseName: currentMembership.horse?.name || "",
                    personName: currentMembership.rider?.name || "",
                    showsAttended: currentMembership.showsAttended || 0,
                    membership: currentMembership,
                    membershipType: currentMembership.type || currentMembership.organizationMembershipType?.name || "",
                    organizationMembershipType: currentMembership.organizationMembershipType || undefined,
                    isSelected: isSelected
                };

                foundASelectedRow = isSelected;

                if (isSelected) {
                    setSelectedMembership(currentMembership);
                    setNumberShowsAttended((currentMembership.showsAttended || 0) + 1);
                    setAddNonMemberFee(false);
                    setAddNonMemberMembership(false);
                    setNumber(currentMembership.backNumber);
                }

                formattedMemberships.push(formatted);
            }

            // If no membership matches, then make sure the form is set to correct values
            if (!foundASelectedRow) {
                setSelectedMembership(null);
                setNumberShowsAttended(1);
                setAddNonMemberFee(true);
                setAddNonMemberMembership(true);
                setNumber(entry.number || null);
            }
        }
        setFormattedMemberships(formattedMemberships);
    }

    const getEntryMemberships = async (entry: EventEntry) => {
        setIsLoading(true);
        if (event.organizationId) {
            const membershipList: Membership[] = await getEventEntryRiderAndHorseActiveMemberships(entry, event.organizationId);
            setMemberships(membershipList);
            formatMemberships(membershipList);
        } else {
            setError("No event organization id was found.");
        }
        setIsLoading(false);
    }

    useEffect(() => {
        setSelectedMembership(undefined);
        if (entry) {
            setCurrentEntry(entry);
            getEntryMemberships(entry);
        } else {
            setFormattedMemberships([]);
        }
    }, [entry]);

    const handleUpdateStabling = async () => {
        if (entry) {
            const status = "accepted";
            await updateBeddingRequestStatusByEntryId(entry.id, status);
            return await updateStablingRequestStatusByEntryId(entry.eventId, entry.id, status);
        } else {
            setError("Stabling Error");
        }
    }

    async function updateEventClassEntriesStatus(entry: EventEntry) {
        const queryResult = await getEventClassEntriesByEventIdEntryId(entry.eventId, entry.id);
        const currentEventEntryClasses = queryResult.result;
        let result = true;
        if (currentEventEntryClasses && currentEventEntryClasses.length) {
            for (var i = 0; i < currentEventEntryClasses.length; i++) {
                const currentEventClassEntry = currentEventEntryClasses[i];
                const updateInput: UpdateEventClassEntryInput = {
                    id: currentEventClassEntry.id,
                    status: "accepted"
                };
                const updateResult = await updateEventClassEntry(updateInput);
                if (!updateResult.isSuccess) {
                    result = false;
                    setError(updateResult.message);
                }
            }
        }
        return result;
    }

    const createNewMembership = async (membershipType: OrganizationMembershipType) => {
        const dateMembershipEnds = getDateMembershipEnds(membershipType);
        const rider: (Rider | null | undefined) = entry.rider;
        const personId: string = rider?.personId || "";
        const personName: string = rider?.name || "";
        const personEmail: string = rider?.contact?.personalEmail || "";
        const input: CreateMembershipInput = {
            name: "RHSC",
            membershipId: membershipType.nextAvailableMembershipId || "",
            backNumber: number || undefined,
            membershipStatus: membershipType.acceptOnlineApplications ? CONSTANT.MEMBERSHIP.APPLICATION_STATUS.IN_PROGRESS : CONSTANT.MEMBERSHIP.APPLICATION_STATUS.COMPLETE,
            organizationMembershipTypeId: membershipType.id,
            type: membershipType.name,
            amountPaid: 0,
            personId: personId,
            personName: personName,
            personEmail: personEmail,
            organizationName: "RHSC",
            organizationId: event.organizationId,
            gradeLevel: "",
            barnId: entry.barn && entry.barn.id ? entry.barn.id : null,
            riderId: entry.rider && entry.rider.id ? entry.rider.id : null,
            horseId: entry.horse && entry.horse.id ? entry.horse.id : null,
            dateMembershipEnds: dateMembershipEnds,
            showsAttended: numberShowsAttended
        };
        const createMembershipResult = await createMembership(input);
        if (createMembershipResult.isSuccess) {
            if(membershipType?.membershipIdValues) {
                const newMembershipId = getNextAvailableMembershipId(membershipType?.membershipIdValues, membershipType.nextAvailableMembershipId || "");
                const updateInput:  UpdateOrganizationMembershipTypeInput = {
                    id: membershipType.id,
                    nextAvailableMembershipId: newMembershipId
                };
                await updateOrganizationMembershipType(updateInput);
            }
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const isValid = verifyForm();
        if (isValid) {
            if (isAccepted === "true") {
                // First, accept the entry
                const eventEntryInput: UpdateEventEntryInput = {
                    id: entry.id,
                    status: "complete",
                    number: number || undefined
                };
                const updateResult = await updateEventEntry(eventEntryInput);
                if (updateResult.isSuccess) {
                    // Next, update the entry fees status
                    await updateEventEntryFeeStatusByEventByEntry(entry.eventId, entry.id, "accepted");
                    
                    // Next, update the entry stabing status
                    await handleUpdateStabling();

                    // Next, update the membership info
                    if (selectedMembership) {
                        // Update the current membership
                        const horseId = entry?.horse?.id || selectedMembership?.horse?.id || "";
                        let updateMembershipInput: UpdateMembershipInput = {
                            id: selectedMembership.id,
                            showsAttended: numberShowsAttended,
                            horseId: horseId,
                            backNumber: number
                        };
                        await updateMembership(updateMembershipInput);
                    } else {
                        if (currentEntry && addNonMemberFee) {
                            const currentEntryEventFeesResult = await getEventEntryFeesByEventIdByEntryId(currentEntry?.eventId, currentEntry?.id);
                            if (currentEntryEventFeesResult.isSuccess) {
                                const currentEntryEventFees: EventEntryFee[] = currentEntryEventFeesResult.result;
                                if (currentEntryEventFees && currentEntryEventFees.length > 0) {
                                    let found = false;
                                    for (let i = 0; i < currentEntryEventFees.length; i++) {
                                        const element = currentEntryEventFees[i];
                                        if (element.name.toLowerCase() === "non-member fee" || element.name.toLowerCase() === "nonmember fee" || element.name.toLowerCase() === "non member fee") found = true;
                                    }
                                    if (found === false) {
                                        const eventFeesResult = await getEventFeesByEventId(currentEntry.eventId);
                                        if (eventFeesResult.isSuccess) {
                                            let nonMemberFee: EventFee | undefined = undefined;
                                            const eventFeesList: EventFee[] = eventFeesResult.result;
                                            for (let i = 0; i < eventFeesList.length; i++) {
                                                const currentFee: EventFee = eventFeesList[i];
                                                if (currentFee.name.toLowerCase() === "non-member fee" || currentFee.name.toLowerCase() === "nonmember fee" || currentFee.name.toLowerCase() === "non member fee") {
                                                    nonMemberFee = currentFee;
                                                    break;
                                                }
                                            }
    
                                            if (nonMemberFee) {
                                                const input: CreateEventEntryFeeInput = {
                                                    eventId: event.id,
                                                    name: nonMemberFee.name,
                                                    status: "accepted",
                                                    amount: nonMemberFee.amount,
                                                    quantity: 1,
                                                    entryId: currentEntry?.id, 
                                                    eventFeeId: nonMemberFee.id,
                                                    feeId: nonMemberFee.feeId, 
                                                    taxA: nonMemberFee.fee?.taxA,
                                                    taxB: nonMemberFee.fee?.taxB,
                                                    description: nonMemberFee.description,
                                                    createdBy: user.id
                                                };
                                                await createEventEntryFee(input);
                                            }
                                        }
                                    }
                                }
                            }
                        }
    
                        if (currentEntry && addNonMemberMembership && event?.organizationId) {
                            const queryResult = await getOrganizationMembershipTypesByOrganizationId(event.organizationId);
                            if (queryResult.isSuccess) {
                                const membershipTypes: OrganizationMembershipType[] = queryResult.result;
                                const nonMemberType = membershipTypes.find(memType => memType.name.toLocaleLowerCase().includes("non-member"));
                                if (nonMemberType) {
                                    await createNewMembership(nonMemberType);
                                }
                            }
                        }
                    }

                    // Finally. update the entry classes statuses
                    const result = await updateEventClassEntriesStatus(entry);
                    
                    if (result) {
                        // Navigate back to the All Entries Page
                        navigateToPage();
                    }
                } else {
                    setError(updateResult.message);
                }
            } else {
                // Decline entry, set reason
                const eventEntryInput: UpdateEventEntryInput = {
                    id: entry.id,
                    status: "declined",
                    statusNote: declineReason
                };
                const updateResult = await updateEventEntry(eventEntryInput);
                if (updateResult.isSuccess) {
                    if (currentEntry && declineReason) await sendEntryStatusDeclinedEmail(currentEntry, event, declineReason);
                    
                    navigateToPage();
                } else {
                    setError(updateResult.message);
                }
            }
        }
        setIsLoading(false);
    }

    const navigateToPage = () => {
        const path = "/index/staff/event/" + entry.eventId + "/participants";
        history.push(path);
    } 

    return (
        <>
            {isLoading ? 
                <Spinner />
                :
                <form>
                    {error && <ErrorAlert width="12" error={error} />}
                    <IonRow>
                        <IonCol>
                            <IonRadioGroup
                                id="acceptEntryGroup"
                                value={isAccepted}
                                onIonChange={e => {setIsAccepted(e.detail.value);}}
                            >
                                <IonItem>
                                    <IonLabel>Accept</IonLabel>
                                    <IonRadio slot="start" value="true" color="tertiary" />
                                </IonItem>  
                                <IonItem>
                                    <IonLabel>Decline</IonLabel>
                                    <IonRadio slot="start" value="false" color="tertiary" />
                                </IonItem>                     
                            </IonRadioGroup>
                        </IonCol>
                    </IonRow>
                    {(isAccepted === "true") && (
                        <>
                            {(event.organizationId === constants.RHSC_ORGANIZATION.id) && (
                                <>
                                    <IonRow className="pt-5 ion-justify-content-center">
                                        <IonCol sizeXs="12" sizeMd="12">
                                            {(formattedMemberships && formattedMemberships.length > 0) ? 
                                                <>
                                                    <h3>Membership Info Found - Select the correct row for this entry</h3>
                                                    <Table responsive hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Selected</th>
                                                                <th>Back #</th>
                                                                <th>Status</th>
                                                                <th>Horse Name</th>
                                                                <th>Person Name</th>
                                                                {/* <th># Shows Attended</th> */}
                                                                <th>Membership Type</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {formattedMemberships.map((membership, index) => (
                                                                <tr key={index}>
                                                                    <td><IonCheckbox slot="start" checked={membership.isSelected} onIonChange={e => handleSelectMembership(index, e.detail.checked)} /></td>
                                                                    <td className="ion-text-wrap">{membership.backNumber}</td>
                                                                    <td className="ion-text-wrap">{membership.status}</td>
                                                                    <td className="ion-text-wrap">{membership.horseName || ""}</td>
                                                                    <td className="ion-text-wrap">{membership.personName}</td>
                                                                    {/* <td className="ion-text-wrap">{membership.showsAttended}</td> */}
                                                                    <td className="ion-text-wrap">{membership.membershipType}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </>
                                                :
                                                <>
                                                    <p className="description ion-text-wrap">No membership info found.</p>
                                                </>
                                            }
                                        </IonCol>
                                    </IonRow>
                                    {(selectedMembership && getMembershipStatus(selectedMembership) === "Expired") && 
                                        <p className="ion-text-center ion-text-wrap text-warning">The system will generate an active membership with the same rider, horse, barn details.</p>
                                    }
                                    {!selectedMembership && (
                                        <IonRow>
                                            <IonCol sizeXs="12" sizeMd="12">
                                                <IonItem>
                                                    <IonCheckbox slot="start" checked={addNonMemberMembership} onIonChange={e => setAddNonMemberMembership(e.detail.checked)} />
                                                    <IonLabel>Add non-member membership?</IonLabel>
                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                    )}
                                    <IonRow>
                                        <IonCol sizeXs="12" sizeMd="12">
                                            <IonItem>
                                                <IonCheckbox slot="start" checked={addNonMemberFee} onIonChange={e => setAddNonMemberFee(e.detail.checked)} />
                                                <IonLabel>Add a non-member fee?</IonLabel>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem color="white">
                                                <IonLabel position="stacked">Set member's # Shows Attended to:</IonLabel>
                                                <IonInput 
                                                    type="number"
                                                    value={numberShowsAttended}
                                                    aria-required={true}
                                                    onIonChange={e => {
                                                        setNumberShowsAttended(parseInt(e.detail.value!))
                                                    }}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                </>
                            )}
                            <IonRow>
                                <IonCol>
                                    <IonItem color="white">
                                        <IonLabel position="stacked">Entry Number (back number)</IonLabel>
                                        <IonInput 
                                            type="text"
                                            value={number}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setNumber(e.detail.value! ? parseInt(e.detail.value!) : null)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                    {(isAccepted === "false") && (
                        <IonRow>
                            <IonCol>
                                <IonItem color="white">
                                    <IonLabel position="stacked"><RequiredInputIndicator /> Decline Reason</IonLabel>
                                    <IonTextarea 
                                        spellCheck={true}
                                        value={declineReason}
                                        onIonChange={e => {
                                            setDeclineReason(e.detail.value!)
                                        }}
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    )}
                    {isAccepted && (
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="6" className="ion-text-center">
                                <IonButton color="success" expand="block" onClick={handleSubmit}>
                                    Submit
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    )}
                </form>
            }
        </>
    );
};

export default AcceptEntryForm;