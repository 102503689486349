import React, { useEffect, useState } from "react";
import { Organization } from "../../models";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";
import CONSTANT from "../../constant/constant";

interface _Props {
    currentOrganization: Organization
    organizations: Organization[]
    onInputChange: Function
    isClearable?: boolean
}

const SelectOrganization: React.FC<_Props> = ({organizations, currentOrganization, onInputChange, isClearable}) => {
    const [selected, setSelected] = useState<string | null | undefined>();
    const [formattedOrganizations, setFormattedOrganizations] = useState<formattedOption[] | null | undefined>();

    const formatOrganizations = (organizations: Organization[]) => {
        let formattedArray: formattedOption[] = [];
        if (organizations) {
            for (var i = 0; i < organizations.length; i++) {
                const value = organizations[i].id.toString();
                const label = organizations[i].name;
                let object: formattedOption = {
                    value: value,
                    label: label,
                    object: organizations[i]
                };
                formattedArray.push(object);
            }
        }
        // Sort the organizations alphabetically, but make sure Add New Organization is last in the list if it exists
        const sorted = formattedArray.sort(function(a, b): number {
            if (a.label === CONSTANT.ADD_NEW_ORGANIZATION.name) {
                return 0;
            } else {
                const nameResult = a.label.localeCompare(b.label);
                return nameResult;
            }
        });
        setFormattedOrganizations(sorted || formattedArray);
    }

    useEffect(() => {
        if (currentOrganization) setSelected(currentOrganization.id)
    }, [currentOrganization]);

    useEffect(() => {
        if (organizations) {
            formatOrganizations(organizations);
        }
    }, [organizations]);

    const handleOnChange = (event?: any) => {
        // Event is the "object" value
        if (event) {
            onInputChange(event);
        } else {
            onInputChange();
        }
    }

    return (
        <>
            {formattedOrganizations ?
                <BasicSelect formattedValues={formattedOrganizations} selectedValue={selected} onSelect={handleOnChange} isClearable={isClearable} />
                :
                <p>Loading options...</p>
            }
        </>
    );
};

export default SelectOrganization;