import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonLabel,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import {Clinician, Feedback, PaymentMethod, Person, S3Object, Submission, SubmissionFeedback} from "../../../../models";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { PersonContext } from "../../../../context/PersonContext";
import { RouteComponentProps, useHistory } from "react-router";
import {getSubmissionFeedbackById, updateSubmissionFeedback} from "../../../../utilities/submissionFeedback/SubmissionFeedback";
import { getSubmissionById, updateSubmission } from "../../../../utilities/submission/Submission";
import { getFeedbackById, updateFeedback } from "../../../../utilities/feedback/Feedback";
import VideoPlayer from "../../../../components/s3Object/VideoPlayer";
import { Input } from "reactstrap";
import { FeedbackStatus, SubmissionStatus, UpdateFeedbackInput, UpdateSubmissionFeedbackInput, UpdateSubmissionInput } from "../../../../API";
import Spinner from "../../../../components/Spinners/Spinner";
import ErrorBanner from "../../../../components/Banners/ErrorBanner";
import SuccessBanner from "../../../../components/Banners/SuccessBanner";
import {sendRiderFeedbackCompleteEmail, sendRiderFeedbackDeclinedEmail} from "../../../../utilities/emails/ClinicsEmail";
import { getPersonByPersonId } from "../../../../utilities/person/Person";
import { getClinicianByPersonId } from "../../../../utilities/clinician/Clinician";
import { createTransfer } from "../../../../utilities/stripe/CreateTransfer";
import moment from "moment";

interface SubmissionFeedbackPageProps extends RouteComponentProps<{
    id: string;
}> {}

const ClinicianVideoFeedbackPage: React.FC<SubmissionFeedbackPageProps> = ({match}) => {
    const user = useContext(PersonContext);
    const history = useHistory();

    const [clinician, setClinician] = useState<Clinician | null | undefined>();
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null | undefined>();

    const [currentSubmissionFeedback, setCurrentSubmissionFeedback] = useState<SubmissionFeedback | null | undefined>();
    const [currentSubmission, setCurrentSubmission] = useState<Submission | null | undefined>();
    const [currentFeedback, setCurrentFeedback] = useState<Feedback | null | undefined>();
    const [currentVideo, setCurrentVideo] = useState<S3Object | null | null | undefined>();

    const [technical, setTechnical] = useState("");
    const [style, setStyle] = useState("");
    const [turnout, setTurnout] = useState("");
    const [general, setGeneral] = useState("");
    const [declineReason, setDeclineReason] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    async function getClinician() {
        const queryResult = await getClinicianByPersonId(user.id);
        if (queryResult.isSuccess) {
            const clinician = queryResult.result;
            setClinician(clinician);

            const paymentMethod: PaymentMethod = clinician.paymentChoice;
            setPaymentMethod(paymentMethod);
        }
    }

    async function getSubmissionFeedback() {
        const queryResult = await getSubmissionFeedbackById(match.params.id);
        if (queryResult.isSuccess) {
            const submissionFeedback: SubmissionFeedback = queryResult.result;
            if (submissionFeedback) {
                setCurrentSubmissionFeedback(submissionFeedback);
                if (submissionFeedback.submission?.id) getSubmission(submissionFeedback.submission?.id);
                if (submissionFeedback.feedbackId) getFeedback(submissionFeedback.feedbackId);
            }
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    async function getSubmission(id: string) {
        const queryResult = await getSubmissionById(id);
        if (queryResult.isSuccess) {
            const submission: Submission = queryResult.result;
            setCurrentSubmission(submission);
            setCurrentVideo(submission.video);
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    async function getFeedback(id: string) {
        const queryResult = await getFeedbackById(id);
        if (queryResult.isSuccess) {
            const feedback: Feedback = queryResult.result;
            setCurrentFeedback(feedback);
            if (feedback.technical) setTechnical(feedback.technical);
            if (feedback.style) setStyle(feedback.style);
            if (feedback.turnout) setTurnout(feedback.turnout);
            if (feedback.general) setGeneral(feedback.general);
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    useEffect(() => {
        getClinician();
        getSubmissionFeedback();
    }, [match, match.params.id]);
    
    const verifyForm = () => {
        if (!technical) {
            setError("Please include technical feedback.");
            return false;
        }
        if (!style) {
            setError("Please include style feedback.");
            return false;
        }
        if (!turnout) {
            setError("Please include turnout feedback.");
            return false;
        } 
        return true;
    }

    const handleSaveDraft = async () => {
        setSuccess("");
        setError("");
        if (currentFeedback) {
            setIsLoading(true);
            // Update current feedback
            const input: UpdateFeedbackInput = {
                id: currentFeedback.id,
                technical,
                style,
                turnout,
                general,
                status: FeedbackStatus.draft
            };
            const updateResult = await updateFeedback(input);
            if (updateResult.isSuccess) {
                if (currentSubmissionFeedback && clinician) {
                    const updateSubmissionFeedbackInput: UpdateSubmissionFeedbackInput = {
                        id: currentSubmissionFeedback?.id,
                        // submissionFeedbackFeedbackId: currentSubmissionFeedback.feedbackId,
                        feedbackStatus: FeedbackStatus.draft,
                        clinicianId: clinician.id 
                    };
                    const result = await updateSubmissionFeedback(updateSubmissionFeedbackInput);
                    if (result.isSuccess) {
                        setSuccess("Successfully saved the draft");
                    } else {
                        setError(result.message);
                    }
                }
            } else {
                setError("Could not save the draft.")
            }
            setIsLoading(false);
        } else {
            setError("An error occurred and the progress could not be saved.");
        }
    }

    const handleSubmit = async () => {
        setSuccess("");
        setError("");
        const isValid = verifyForm();
        if (isValid) {
            if (currentFeedback) {
                setIsLoading(true);
                // Update current feedback
                const input: UpdateFeedbackInput = {
                    id: currentFeedback.id,
                    technical,
                    style,
                    turnout,
                    general,
                    status: FeedbackStatus.complete
                };
                const updateResult = await updateFeedback(input);
                if (updateResult.isSuccess) {
                    if (currentSubmissionFeedback && clinician) {
                        const updateSubmissionFeedbackInput: UpdateSubmissionFeedbackInput = {
                            id: currentSubmissionFeedback?.id,
                            // submissionFeedbackFeedbackId: currentSubmissionFeedback.feedbackId,
                            feedbackStatus: FeedbackStatus.complete,
                            submissionStatus: SubmissionStatus.complete,
                            clinicianId: clinician.id,
                            dateCompleted: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                        };
                        const result = await updateSubmissionFeedback(updateSubmissionFeedbackInput);
                        if (!result.isSuccess) {
                            setError(result.message);
                        }

                        const personId = currentSubmissionFeedback.personId;
                        if (personId) {
                            const personResult = await getPersonByPersonId(personId);
                            if (personResult.isSuccess) {
                                const sender: Person = personResult.result;
                                await sendRiderFeedbackCompleteEmail(sender.email, sender.firstName || "", currentSubmissionFeedback.clinician?.name || "Your clinician");
                            }
                            
                        }
                    }

                    if (currentSubmission) {
                        const submissionFeedbackInput: UpdateSubmissionInput = {
                            id: currentSubmission.id,
                            status: SubmissionStatus.complete,
                        };
                        await updateSubmission(submissionFeedbackInput);
                    }

                    if (paymentMethod && paymentMethod.type && paymentMethod.type === "ach") {
                        const stripeId = paymentMethod.stripeId;
                        if (stripeId) {
                            await createTransfer(stripeId, clinician?.price || 0);
                        }
                    }

                    navigateToPage();
                } else {
                    setError("Could not submit the feedback.");
                }
                setIsLoading(false);
            } else {
                setError("An error occurred and the feedback could not be submitted");
            }
        }
    }

    const handleDecline = async () => {
        setSuccess("");
        setError("");
        if (!declineReason) {
            if (currentFeedback) {
                setIsLoading(true);
                const input: UpdateFeedbackInput = {
                    id: currentFeedback.id,
                    status: FeedbackStatus.declined
                };
                const updateResult = await updateFeedback(input);
                if (updateResult.isSuccess) {
                    if (currentSubmissionFeedback && clinician) {
                        const updateSubmissionFeedbackInput: UpdateSubmissionFeedbackInput = {
                            id: currentSubmissionFeedback?.id,
                            // submissionFeedbackFeedbackId: currentSubmissionFeedback.feedbackId,
                            feedbackStatus: FeedbackStatus.declined,
                            submissionStatus: SubmissionStatus.declined,
                            clinicianId: clinician.id
                        };
                        const result = await updateSubmissionFeedback(updateSubmissionFeedbackInput);
                        if (!result.isSuccess) {
                            setError(result.message);
                        }

                        const personId = currentSubmissionFeedback.personId;
                        if (personId) {
                            const personResult = await getPersonByPersonId(personId);
                            if (personResult.isSuccess) {
                                const sender: Person = personResult.result;
                                await sendRiderFeedbackDeclinedEmail(sender.email, sender.firstName || "", currentSubmissionFeedback.clinician?.name || "Your clinician");
                            }
                        }
                    }

                    if (currentSubmission) {
                        const submissionInput: UpdateSubmissionInput = {
                            id: currentSubmission.id,
                            status: SubmissionStatus.declined
                        };
                        await updateSubmission(submissionInput);
                    }

                    navigateToPage();
                } else {
                    setError("Could not decline the feedback.");
                }
                setIsLoading(false);
            } else {
                setError("An error occurred and the feedback could not be declined");
            }
        } else {
            setError("You must provide a reason for declining the video.")
        }
    }

    const navigateToPage = () => {
        const path = "/index/judge/clinics/videos";
        history.push(path);
    }
   
    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Feedback" />
                {currentFeedback && (
                    <>
                        <IonRow id="submissionFeedbackName">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        Status
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <h5 color={currentFeedback.status === "complete" ? "success" : "primary"}>{currentFeedback.status}</h5>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
                {currentVideo && (
                    <>
                        <IonRow id="submissionFeedbackName">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        Video
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <VideoPlayer videoObject={currentVideo} />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
                {currentSubmission && (
                    <>
                        <IonRow id="submissionFeedbackName">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        Video Description
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <IonRow>
                                            <IonCol>
                                                <p>{currentSubmission.description}</p>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
                {currentFeedback && (
                    <>
                        <IonRow id="feedback">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        Feedback
                                    </IonCardTitle>
                                    {currentFeedback.status === "pending" || currentFeedback.status === "draft" ?
                                        <IonCardContent>
                                            {success && <SuccessBanner success={success}/>}
                                            {error && <ErrorBanner error={error}/>}
                                            <IonRow>
                                                <IonCol>
                                                    <IonLabel>Technical Feedback</IonLabel>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <Input
                                                        placeholder="What are the technical strong or weak points? What would you reward or take away points for?"
                                                        rows="5"
                                                        type="textarea"
                                                        name="technical"
                                                        value={technical}
                                                        spellCheck="true"
                                                        onChange={(event) => setTechnical(event.target.value)}
                                                        data-hj-whitelist
                                                    />
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonLabel>Style Feedback</IonLabel>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <Input
                                                        placeholder="As a judge, how did the style of horse or rider benefit or take away from the ride? What stuck out to you?"
                                                        rows="5"
                                                        type="textarea"
                                                        name="style"
                                                        value={style}
                                                        spellCheck="true"
                                                        onChange={(event) => setStyle(event.target.value)}
                                                        data-hj-whitelist
                                                    />
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonLabel>Turnout Feedback</IonLabel>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <Input
                                                        placeholder="What was your first impression of the pair? How can the turnout be improved?"
                                                        rows="5"
                                                        type="textarea"
                                                        name="turnout"
                                                        value={turnout}
                                                        spellCheck="true"
                                                        onChange={(event) => setTurnout(event.target.value)}
                                                        data-hj-whitelist
                                                    />
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonLabel>General Feedback</IonLabel>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <Input
                                                        placeholder="Advice for the rider. Any exercises you think could help the horse and rider. Words of encouragement."
                                                        rows="5"
                                                        type="textarea"
                                                        name="general"
                                                        value={general}
                                                        spellCheck="true"
                                                        onChange={(event) => setGeneral(event.target.value)}
                                                        data-hj-whitelist
                                                    />
                                                </IonCol>
                                            </IonRow>
                                            {isLoading ?
                                                <Spinner />
                                                :
                                                <IonRow className="ion-justify-content-center pt-3">
                                                    <IonCol className="ion-text-center">
                                                        <IonButton color="warning" onClick={handleSaveDraft}>Save Draft</IonButton>
                                                    </IonCol>
                                                    <IonCol className="ion-text-center">
                                                        <IonButton color="success" onClick={handleSubmit}>Send to Rider</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            }
                                        </IonCardContent>
                                        :
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol>
                                                    <h2>Technical Feedback</h2>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <p>{currentFeedback.technical}</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <h2>Style Feedback</h2>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <p>{currentFeedback.style}</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <h2>Turnout Feedback</h2>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <p>{currentFeedback.turnout}</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <h2>Other Feedback</h2>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <p>{currentFeedback.general}</p>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    }
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
                {currentFeedback && (currentFeedback.status === "pending" || currentFeedback?.status === "draft") && (
                    <IonRow id="submissionFeedbackName">
                        <IonCol size="12">
                            <IonCard mode="md" className="ion-padding bg-white stretch">
                                <IonCardTitle>
                                    Decline Video
                                </IonCardTitle>
                                <IonCardContent>
                                    <p>Note: you will not receive payment for declined videos.</p>
                                    <IonRow>
                                        <IonCol>
                                            <Input
                                                placeholder="You must provide a detailed explanation for your decision to decline the video. Tell the rider how to submit the video again so that you will review it."
                                                rows="5"
                                                type="textarea"
                                                name="declineReason"
                                                value={declineReason}
                                                spellCheck="true"
                                                onChange={(event) => setDeclineReason(event.target.value)}
                                                data-hj-whitelist
                                            />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol className="ion-text-center">
                                            <IonButton color="danger" onClick={handleDecline}>Decline</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                )}
            </IonContent>
        </IonPage>
    );
};

export default ClinicianVideoFeedbackPage;