import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from "@ionic/react";
import { chevronDown, chevronUp } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import { useHistory } from "react-router";
import { add, close } from "ionicons/icons";
import { isWindows } from "../../../utilities/platform/Platform";
import DataTable from "react-data-table-component";
import { createMembership, deleteMembership, getMembershipsByHorseId, getMembershipsByOrganizationIdByMembershipIdByMembershipTypeByActiveStatus, getMembershipsByRiderId, updateMembership } from "../../../utilities/membership/Membership";
import { FormattedMembership } from "../../Memberships/OrganizationMembershipsTable";
import { CreateAddressInput, CreateBarnInput, CreateContactInput, CreateHorseInput, CreateMembershipInput, CreatePersonalInformationInput, CreatePersonInput, CreateRiderInput, UpdateAddressInput, UpdateContactInput, UpdateMembershipInput } from "../../../API";
import AddGroupMemberships from "../../Memberships/AddGroupMemberships";
import CONSTANT from "../../../constant/constant";
import { Membership, Organization, OrganizationMembershipType, Address, Barn, Rider, Horse, Person, MembershipTypeCategory } from "../../../models";
import AddressFormGroup from "../../Address/AddressFormGroup";
import PhoneNumberTypeSelect from "../../Contact/PhoneNumberTypeSelect";
import ErrorAlert from "../../Errors/ErrorAlert";
import Spinner from "../../Spinners/Spinner";
import { createAddress, getAddressById, updateAddress } from "../../../utilities/address/Address";
import { formatAllCountryPhoneNumber } from "../../../utilities/contact/FormatPhoneNumber";
import { createContact, updateContact } from "../../../utilities/contact/Contact";
import { handleDeleteS3File } from "../../../utilities/s3Object/s3Object";
import { getOrganizationMembershipTypesByOrganizationId } from "../../../utilities/organizationMembershipType/OrganizationMembershipType";
import SelectOrganizationMembershipType from "../../Memberships/SelectOrganizationMembershipType";
import SelectFromAllBarns from "../../Barn/SelectFromAllBarns";
import SelectHorse from "../../Horse/SelectHorse";
import SelectRiderFromBarn from "../../Rider/SelectRiderFromBarn";
import { createHorse, getHorsesByBarnId } from "../../../utilities/horse/Horse";
import { createPerson, getPersonByPersonId } from "../../../utilities/person/Person";
import { createPersonalInformation } from "../../../utilities/personalInformation/PersonalInformation";
import { createBarn, getBarnByName } from "../../../utilities/barn/Barn";
import { createRider, getRiderByPersonId } from "../../../utilities/rider/Rider";
import RequiredInputIndicator from "../../Forms/RequiredInputIndicator";
import { getDateMembershipEnds } from "../../../utilities/membership/MembershipStatus";

interface _Props {
    membership: Membership
    membershipType: OrganizationMembershipType | undefined;
    organization: Organization | undefined;
    setMembership: Function;
    personId: string
}

interface formattedOption {
    label: string
    value: string
    membership: Membership
}

const EditMembershipApplicationGroupInfo: React.FC<_Props> = ({membership, membershipType, organization, setMembership, personId}) => {
    const [error, setError] = useState<string>("");
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isExpandedGroupMembers, setIsExpandedGroupMembers] = useState(true);
    const [isExpandedGroupLeader, setIsExpandedGroupLeader] = useState(true);
    const [isExpandedGroupContact, setIsExpandedGroupContact] = useState(true);
    const [isExpandedDeleteGroupMemberships, setIsExpandedDeleteGroupMemberships] = useState(true);
    const [organizationMembershipTypes, setOrganizationMembershipTypes] = useState<OrganizationMembershipType[] | undefined | null>();
    // Group Members Card
    const SEGMENT_CONSTANT = {
        FIND: "find",
        CREATE: "create"
    };
    const history = useHistory();
    const [formattedMemberships, setFormattedMemberships] = useState<FormattedMembership[] | null | undefined>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [segmentValue, setSegmentValue] = useState<string>(SEGMENT_CONSTANT.FIND);
    const [selectedMembership, setSelectedMembership] = useState<Membership | undefined>();
    const [dialogError, setDialogError] = useState<string>("");
    const [groupMembers, setGroupMembers] = useState<Membership[] | null | undefined>();
    const [selectedOption, setSelectedOption] = useState<string>("");
    const [isModalLoading, setIsModalLoading] = useState<boolean>(false);

    const [barn, setBarn] = useState<Barn | undefined | null>();
    const [barnName, setBarnName] = useState<string>("");
    const [rider, setRider] = useState<Rider | null | undefined>();
    const [riderName, setRiderName] = useState<string>("");
    const [horseName, setHorseName] = useState<string>("");
    const [horse, setHorse] = useState<Horse | undefined | null>();
    const [horseOptions, setHorseOptions] = useState<Horse[] | undefined | null>();
    const [fullName, setFullName] = useState<string>("");
    const [person, setPerson] = useState<Person | undefined | null>();
    const [birthDate, setBirthDate] = useState<string>("");
    const [backNumber, setBackNumber] = useState<number>(0);

    //Group Leader Card
    const [groupLeaderName, setGroupLeaderName] = useState<string>("");
    const [groupLeaderEmail, setGroupLeaderEmail] = useState<string>("");
    const [groupLeaderPhoneType, setGroupLeaderPhoneType] = useState<string>("");
    const [groupLeaderPhoneNumber, setGroupLeaderPhoneNumber] = useState<string>("");
    const [groupLeaderStreetAddressLine1, setGroupLeaderStreetAddressLine1] = useState("");
    const [groupLeaderStreetAddressLine2, setGroupLeaderStreetAddressLine2] = useState("");
    const [groupLeaderCity, setGroupLeaderCity] = useState("");
    const [groupLeaderProvState, setGroupLeaderProvState] = useState("");
    const [groupLeaderZip, setGroupLeaderZip] = useState("");
    const [groupLeaderCountry, setGroupLeaderCountry] = useState("");
    const [groupLeaderAddress, setGroupLeaderAddress] = useState<Address>();

    //Group Contact Card
    const [groupContactGroupName, setGroupContactGroupName] = useState<string>("");
    const [groupContactEmail, setGroupContactEmail] = useState<string>("");
    const [groupContactPhoneType, setGroupContactPhoneType] = useState<string>("");
    const [groupContactPhoneNumber, setGroupContactPhoneNumber] = useState<string>("");
    const [groupContactStreetAddressLine1, setGroupContactStreetAddressLine1] = useState("");
    const [groupContactStreetAddressLine2, setGroupContactStreetAddressLine2] = useState("");
    const [groupContactCity, setGroupContactCity] = useState("");
    const [groupContactProvState, setGroupContactProvState] = useState("");
    const [groupContactZip, setGroupContactZip] = useState("");
    const [groupContactCountry, setGroupContactCountry] = useState("");
    const [groupContactAddress, setGroupContactAddress] = useState<Address>();

    // Delete Group Memberships
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const [isAELOrganization, setIsAELOrganization] = useState(false);

    useEffect(() => {
        if (organization && organization.id === "ddc2fd3c-0bde-4b39-bbbb-5e00d2a35ba0") setIsAELOrganization(true);
    }, [organization]);

    const handleSelectRider = async (rider?: Rider) => {
        if (rider) {
            setRider(rider);
            setRiderName(rider.name);
            setFullName(rider.name);
            setBirthDate(rider.birthdate || "");
            const personId = rider.personId;
            if (personId) {
                const personResult = await getPersonByPersonId(personId);
                if (personResult.isSuccess) {
                    const foundPerson: Person = personResult.result;
                    setPerson(foundPerson);
                }
            }

            const queryResult = await getMembershipsByRiderId(rider.id);
            if (queryResult.isSuccess) {
                const membershipList: Membership[] = queryResult.result;
                if (membershipList && membershipList.length > 0) {
                    const membership = membershipList.find((mem) => {
                        return mem.name === organization?.name && mem.backNumber;
                    });
                    if (membership && membership.backNumber) {
                        setBackNumber(membership.backNumber);
                    }
                }
            }
        } else {
            setRider(undefined);
            setRiderName("");
            setFullName("");
            setBirthDate("");
        }
    }

    const handleSelectBarn = async (barn?: Barn) => {
        if (barn) {
            setBarn(barn);
            setBarnName(barn.name);
            const queryResult = await getHorsesByBarnId(barn.id);
            if (queryResult.isSuccess) {
                setHorseOptions(queryResult.result);
            }
        } else {
            setBarn(undefined);
            setBarnName("");
            setHorseOptions(undefined);
        }
    }

    const handleSelectHorse = async (horse?: Horse) => {
        if (horse) {
            setHorse(horse);
            setHorseName(horse.name || horseName || "");

            const queryResult = await getMembershipsByHorseId(horse.id);
            if (queryResult.isSuccess) {
                const membershipList: Membership[] = queryResult.result;
                if (membershipList && membershipList.length > 0) {
                    const membership = membershipList.find(mem => rider ? mem.rider?.id === rider.id : true);
                    if (membership && membership.backNumber) {
                        setBackNumber(membership.backNumber);
                    }
                }
            }
        } else {
            setHorse(undefined);
            setHorseName("");
        }
    }

    const verifyIsValid = () => {
        if (membershipType?.category ===  MembershipTypeCategory.GROUP) {
            if (!fullName) {
                setError("A required field is missing data: Member Full Name");
                return false;
            }
            if (!birthDate) {
                setError("A required field is missing data: Member Birth Date");
                return false;
            }
            if (membershipType.applicationFields?.backNumber && !backNumber) {
                setError("A required field is missing data: Member Back Number");
                return false;
            }
        }
        return true;
    };

    const handleCreateMembership = async () => {
        const isValid = verifyIsValid();
        if (!isValid) {
            return;
        }
        setIsModalLoading(true);
        setError("");

        let currentPerson: Person | undefined | null = person;
        if (!currentPerson) {
            const splitName = fullName.split(' ');
            const personInput: CreatePersonInput = {
                email: "",
                firstName: splitName[0],
                lastName: splitName.length > 1 ? splitName[1] : "",
                isVerified: false,
                roles: "rider;",
                createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
            };
            const createPersonResult = await createPerson(personInput);
            if (createPersonResult.isSuccess) {
                currentPerson = createPersonResult.result;
                const personalInformationInput: CreatePersonalInformationInput = {
                    personId: createPersonResult.result.id,
                    dateOfBirth: birthDate
                };
                await createPersonalInformation(personalInformationInput);
            }
        }

        let currentBarn: Barn | undefined | null = barn;
        if (!currentBarn && barnName && barnName !== "") {
            const queryBarnResult = await getBarnByName(barnName);
            if (queryBarnResult.isSuccess) {
                currentBarn = queryBarnResult.result;
            } else {
                const barnInput: CreateBarnInput = {
                    name: barnName
                };
                const createBarnResult = await createBarn(barnInput);
                if (createBarnResult.isSuccess) {
                    currentBarn = createBarnResult.result;
                }
            }
        }

        let currentHorse: Horse | undefined | null = horse;
        if (!currentHorse) {
            if (horseName && currentBarn) {
                const queryResult = await getHorsesByBarnId(currentBarn.id);
                if (queryResult.isSuccess) {
                    const horsesInBarn: Horse[] = queryResult.result;
                    if (horsesInBarn && horsesInBarn.length > 0) {
                        for (let i = 0; i < horsesInBarn.length; i++) {
                            const current = horsesInBarn[i];
                            if (current.name.toLowerCase() === horseName.toLowerCase()) {
                                currentHorse = current;
                                break;
                            }
                        }
                    }
                }
            }
            if (!currentHorse && horseName) {
                const horseInput: CreateHorseInput = {
                    name: horseName,
                    ownerName: "",
                    barnId: currentBarn?.id || "",
                    barnName: currentBarn?.name || "",
                    personId: currentPerson?.id || ""
                };
                const createHorseResult = await createHorse(horseInput);
                if (createHorseResult.isSuccess) {
                    currentHorse = createHorseResult.result;
                }
            }
        }

        let currentRider: Rider | undefined | null = rider;
        if (!currentRider) {
            if (currentPerson) {
                const riderQueryResult = await getRiderByPersonId(currentPerson.id);
                if (riderQueryResult.isSuccess) {
                    currentRider = riderQueryResult.result;
                }
            } else {

            }

            if (!currentRider && fullName) {
                let riderInput: CreateRiderInput = {
                    name: fullName,
                    barnId: currentBarn?.id || "",
                    createdBy: personId, 
                    personId: currentPerson?.id || "",
                    createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                    updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                };
        
                const riderResult = await createRider(riderInput);
                if (riderResult.isSuccess) {
                    currentRider = riderResult.result;
                }
            }
        }

        let dateMembershipEnds = "";
        if (membershipType) {
            dateMembershipEnds = getDateMembershipEnds(membershipType);
        }

        let input: CreateMembershipInput = {
            name: organization?.name || organization?.nickname || "",
            membershipId: membership.membershipId,
            membershipStatus: CONSTANT.MEMBERSHIP.APPLICATION_STATUS.COMPLETE,
            organizationId: organization?.id || "",
            organizationMembershipTypeId: membershipType?.id || undefined,
            backNumber: backNumber || 0,
            amountPaid: 0,
            type: membershipType?.name || "",
            personName: fullName,
            personId: currentPerson?.id || "",
            dateMembershipEnds: dateMembershipEnds,
            createdBy: personId
        };
        if (currentRider) {
            input.riderId = currentRider?.id;
        }
        if (currentBarn) {
            input.barnId = currentBarn?.id;
        }
        if (currentHorse) {
            input.horseId = currentHorse?.id;
        }
        const createResult = await createMembership(input);
        if (createResult.isSuccess) {
            if (groupMembers?.length) {
                groupMembers.push(createResult.result);
                formatMemberships(groupMembers || []);
            }
        } else {
            setError(createResult.message);
        }
        setIsModalLoading(false);
        setShowModal(false);
    }

    const handleLeaderPhoneNumberTypeSelectInput = (phoneNumberTypeInput: string) => {
        setError("");
        if (phoneNumberTypeInput && phoneNumberTypeInput !== "-"){
            setGroupLeaderPhoneType(phoneNumberTypeInput);
        } else {
            setGroupLeaderPhoneType("");
        }
    };

    const handleLeaderAddressInputChange = (type: string, value: string) => {
        setError("");
        if (type === "streetAddressLine1") setGroupLeaderStreetAddressLine1(value);
        if (type === "streetAddressLine2") setGroupLeaderStreetAddressLine2(value);
        if (type === "city") setGroupLeaderCity(value);
        if (type === "provState") setGroupLeaderProvState(value);
        if (type === "zip") setGroupLeaderZip(value);
        if (type === "country") setGroupLeaderCountry(value);
    };

    const prePopulateGroupLeaderData = async () => {
        const leaderContactData = membership.groupLeaderContact;
        if (membershipType?.applicationFields?.groupLeader) {
            setGroupLeaderName(leaderContactData?.name || "");
        }
        if (membershipType?.applicationFields?.groupLeaderContactInfo) {
            if(leaderContactData?.mailingAddress) {
                const leaderAddressResult = await getAddressById(leaderContactData.mailingAddress)
                const leaderAddress = leaderAddressResult.result;
                setGroupLeaderAddress(leaderAddress);
                setGroupLeaderStreetAddressLine1(leaderAddress.streetAddress1);
                setGroupLeaderStreetAddressLine2(leaderAddress.streetAddress2);
                setGroupLeaderCity(leaderAddress.city);
                setGroupLeaderProvState(leaderAddress.provState);
                setGroupLeaderZip(leaderAddress.zip);
                setGroupLeaderCountry(leaderAddress.country);
            }
            setGroupLeaderEmail(leaderContactData?.personalEmail || "");
            setGroupLeaderPhoneType(
                leaderContactData && leaderContactData.cell
                ? "cell"
                : leaderContactData && leaderContactData.home
                ? "home"
                : leaderContactData && leaderContactData.work
                ? "work"
                : ""
            );
            setGroupLeaderPhoneNumber(
                leaderContactData && leaderContactData.cell
                ? leaderContactData.cell
                : leaderContactData && leaderContactData.home
                ? leaderContactData.home
                : leaderContactData && leaderContactData.work
                ? leaderContactData.work
                : ""
            );
        }
    }

    const handleGroupLeaderContactInfoSave = async () => {
        const isValid = verifyGroupLeaderForm();
        if(isValid) {
            setIsLoading(true);
            const leaderContactData = membership.groupLeaderContact;
            if (membershipType?.applicationFields?.groupLeaderContactInfo) {
                if (leaderContactData && membership.groupLeaderContactId) {
                    if(leaderContactData.mailingAddress) {
                        const addressInput: UpdateAddressInput = {
                            id: leaderContactData.mailingAddress,
                            name: groupLeaderName,
                            streetAddress1: groupLeaderStreetAddressLine1,
                            streetAddress2: groupLeaderStreetAddressLine2,
                            city: groupLeaderCity,
                            provState: groupLeaderProvState,
                            zip: groupLeaderZip,
                            country: groupLeaderCountry
                        };
                        const updateAddressResult = await updateAddress(addressInput);
                        if (updateAddressResult.isSuccess) {
                            setGroupLeaderAddress(updateAddressResult.result);
                        } else {
                            setError(updateAddressResult.message);
                        }
                    }
                    const contactInput: UpdateContactInput = {
                        id: membership.groupLeaderContactId,
                        name: groupLeaderName,
                        cell: groupLeaderPhoneType === "cell" ? formatAllCountryPhoneNumber(groupLeaderPhoneNumber) : undefined,
                        home: groupLeaderPhoneType === "home" ? formatAllCountryPhoneNumber(groupLeaderPhoneNumber) : undefined,
                        work: groupLeaderPhoneType === "work" ? formatAllCountryPhoneNumber(groupLeaderPhoneNumber) : undefined,
                        personalEmail: groupLeaderEmail ? groupLeaderEmail : undefined
                    };
                    const updateContactResult = await updateContact(contactInput);
                    if (!updateContactResult.isSuccess) {
                        setError(updateContactResult.message);
                    }
                } else {
                    const addressInput: CreateAddressInput = {
                        name: groupLeaderName,
                        type: "mailing",
                        isDefault: true,
                        streetAddress1: groupLeaderStreetAddressLine1,
                        streetAddress2: groupLeaderStreetAddressLine2,
                        city: groupLeaderCity,
                        provState: groupLeaderProvState,
                        zip: groupLeaderZip,
                        country: groupLeaderCountry,
                        notes:""
                    };
                    const createAddressResult = await createAddress(addressInput);
                    if (createAddressResult.isSuccess) {
                        setGroupLeaderAddress(createAddressResult.result);
                        const contactInput: CreateContactInput = {
                            name: groupLeaderName,
                            cell: groupLeaderPhoneType === "cell" ? formatAllCountryPhoneNumber(groupLeaderPhoneNumber) : undefined,
                            home: groupLeaderPhoneType === "home" ? formatAllCountryPhoneNumber(groupLeaderPhoneNumber) : undefined,
                            work: groupLeaderPhoneType === "work" ? formatAllCountryPhoneNumber(groupLeaderPhoneNumber) : undefined,
                            personalEmail: groupLeaderEmail ? groupLeaderEmail : undefined,
                            mailingAddress: createAddressResult.result.id
                        };
                        const createContactResult = await createContact(contactInput);
                        if (createContactResult.isSuccess) {
                            const input: UpdateMembershipInput = {
                                id: membership.id,
                                groupLeaderContactId: createContactResult.result.id
                            };
                            const updateMembershipResult = await updateMembership(input);
                            if (updateMembershipResult.isSuccess) {
                                setMembership(updateMembershipResult.result);
                            } else {
                                setError(updateMembershipResult.message);
                            }
                        } else {
                            setError(createContactResult.message);
                        }
                    }
                }
            }
            if (membershipType?.applicationFields?.groupLeaderContactInfo === false &&
                membershipType?.applicationFields?.groupLeader) {
                if (leaderContactData && membership.groupLeaderContactId) {
                    const contactInput: UpdateContactInput = {
                        id: membership.groupLeaderContactId,
                        name: groupLeaderName
                    };
                    const updateContactResult = await updateContact(contactInput);
                    if (!updateContactResult.isSuccess) {
                        setError(updateContactResult.message);
                    }
                } else {
                    const contactInput: CreateContactInput = {
                        name: groupLeaderName
                    };
                    const createContactResult = await createContact(contactInput);
                    if (createContactResult.isSuccess) {
                        const input: UpdateMembershipInput = {
                            id: membership.id,
                            groupLeaderContactId: createContactResult.result.id
                        };
                        const updateMembershipResult = await updateMembership(input);
                        if (updateMembershipResult.isSuccess) {
                            setMembership(updateMembershipResult.result);
                        } else {
                            setError(updateMembershipResult.message);
                        }
                    } else {
                        setError(createContactResult.message);
                    }
                }
            }
            setIsLoading(false);
        }
    };

    const verifyGroupLeaderForm = () => {
        if(membershipType?.applicationFields?.groupLeader && !groupLeaderName) {
            setError(isAELOrganization ? "Please include a value for Head Coach Name" : "Please include a value for Leader Name");
            return false;
        }
        if(membershipType?.applicationFields?.groupLeaderContactInfo) {
            if (!groupLeaderEmail) {
                setError("Please include a value for Email Address");
                return false;
            }
            if (!groupLeaderPhoneType) {
                setError("Please include a value for Phone Number Type");
                return false;
            }
            if (!groupLeaderPhoneNumber) {
                setError("Please include a value for Phone Number");
                return false;
            }
            if (!groupLeaderStreetAddressLine1) {
                setError("Please include a value for Street Address Line1");
                return false;
            }
            if (!groupLeaderCity) {
                setError("Please include a value for City");
                return false;
            }
            if (!groupLeaderProvState) {
                setError("Please include a value for State");
                return false;
            }
            if (!groupLeaderZip) {
                setError("Please include a value for Postal Code");
                return false;
            }
            if (!groupLeaderCountry) {
                setError("Please include a value for Country");
                return false;
            }
        }
        return true;
    };

    const handleContactAddressInputChange = (type: string, value: string) => {
        setError("");
        if (type === "streetAddressLine1") setGroupContactStreetAddressLine1(value);
        if (type === "streetAddressLine2") setGroupContactStreetAddressLine2(value);
        if (type === "city") setGroupContactCity(value);
        if (type === "provState") setGroupContactProvState(value);
        if (type === "zip") setGroupContactZip(value);
        if (type === "country") setGroupContactCountry(value);
    };
    
    const handleContactPhoneNumberTypeSelectInput = (phoneNumberTypeInput: string) => {
        setError("");
        if (phoneNumberTypeInput && phoneNumberTypeInput !== "-"){
            setGroupContactPhoneType(phoneNumberTypeInput);
        } else {
            setGroupContactPhoneType("");
        }
    };

    const prePopulateGroupContactData = async () => {
        const groupContactData = membership.groupContact;
        if (membershipType?.applicationFields?.groupName) {
            setGroupContactGroupName(groupContactData?.name || "");
        }
        if (membershipType?.applicationFields?.contact) {
            if(groupContactData?.mailingAddress) {
                const groupAddressResult = await getAddressById(groupContactData.mailingAddress)
                const groupAddress = groupAddressResult.result;
                setGroupContactAddress(groupAddress);
                setGroupContactStreetAddressLine1(groupAddress.streetAddress1);
                setGroupContactStreetAddressLine2(groupAddress.streetAddress2);
                setGroupContactCity(groupAddress.city);
                setGroupContactProvState(groupAddress.provState);
                setGroupContactZip(groupAddress.zip);
                setGroupContactCountry(groupAddress.country);
            }
            setGroupContactEmail(groupContactData?.personalEmail || "");
            setGroupContactPhoneType(
                groupContactData && groupContactData.cell
                ? "cell"
                : groupContactData && groupContactData.home
                ? "home"
                : groupContactData && groupContactData.work
                ? "work"
                : ""
            );
            setGroupContactPhoneNumber(
                groupContactData && groupContactData.cell
                ? groupContactData.cell
                : groupContactData && groupContactData.home
                ? groupContactData.home
                : groupContactData && groupContactData.work
                ? groupContactData.work
                : ""
            );
        }
    }

    const handleGroupContactSave = async () => {
        const isValid = verifyGroupContactForm();
        if(isValid) {
            setIsLoading(true);
            const groupContactData = membership.groupContact;
            if (membershipType?.applicationFields?.contact) {
                if (groupContactData && membership.groupContactId) {
                    if(groupContactData.mailingAddress) {
                        const addressInput: UpdateAddressInput = {
                            id: groupContactData.mailingAddress,
                            name: groupContactGroupName,
                            streetAddress1: groupContactStreetAddressLine1,
                            streetAddress2: groupContactStreetAddressLine2,
                            city: groupContactCity,
                            provState: groupContactProvState,
                            zip: groupContactZip,
                            country: groupContactCountry
                        };
                        const updateAddressResult = await updateAddress(addressInput);
                        if (updateAddressResult.isSuccess) {
                            setGroupContactAddress(updateAddressResult.result);
                        } else {
                            setError(updateAddressResult.message);
                        }
                    }
                    const contactInput: UpdateContactInput = {
                        id: membership.groupContactId,
                        name: groupContactGroupName,
                        cell: groupContactPhoneType === "cell" ? formatAllCountryPhoneNumber(groupContactPhoneNumber) : undefined,
                        home: groupContactPhoneType === "home" ? formatAllCountryPhoneNumber(groupContactPhoneNumber) : undefined,
                        work: groupContactPhoneType === "work" ? formatAllCountryPhoneNumber(groupContactPhoneNumber) : undefined,
                        personalEmail: groupContactEmail ? groupContactEmail : undefined
                    };
                    const updateContactResult = await updateContact(contactInput);
                    if (!updateContactResult.isSuccess) {
                        setError(updateContactResult.message);
                    }
                } else {
                    const addressInput: CreateAddressInput = {
                        name: groupContactGroupName,
                        type: "mailing",
                        isDefault: true,
                        streetAddress1: groupContactStreetAddressLine1,
                        streetAddress2: groupContactStreetAddressLine2,
                        city: groupContactCity,
                        provState: groupContactProvState,
                        zip: groupContactZip,
                        country: groupContactCountry,
                        notes:""
                    };
                    const createAddressResult = await createAddress(addressInput);
                    if (createAddressResult.isSuccess) {
                        setGroupContactAddress(createAddressResult.result);
                        const contactInput: CreateContactInput = {
                            name: groupContactGroupName,
                            cell: groupContactPhoneType === "cell" ? formatAllCountryPhoneNumber(groupContactPhoneNumber) : undefined,
                            home: groupContactPhoneType === "home" ? formatAllCountryPhoneNumber(groupContactPhoneNumber) : undefined,
                            work: groupContactPhoneType === "work" ? formatAllCountryPhoneNumber(groupContactPhoneNumber) : undefined,
                            personalEmail: groupContactEmail ? groupContactEmail : undefined,
                            mailingAddress: createAddressResult.result.id
                        };
                        const createContactResult = await createContact(contactInput);
                        if (createContactResult.isSuccess) {
                            const input: UpdateMembershipInput = {
                                id: membership.id,
                                groupContactId: createContactResult.result.id
                            };
                            const updateMembershipResult = await updateMembership(input);
                            if (updateMembershipResult.isSuccess) {
                                setMembership(updateMembershipResult.result);
                            } else {
                                setError(updateMembershipResult.message);
                            }
                        } else {
                            setError(createContactResult.message);
                        }
                    }
                }
            }
            if (membershipType?.applicationFields?.contact === false &&
                membershipType?.applicationFields?.groupName) {
                if (groupContactData && membership.groupContactId) {
                    const contactInput: UpdateContactInput = {
                        id: membership.groupContactId,
                        name: groupContactGroupName
                    };
                    const updateContactResult = await updateContact(contactInput);
                    if (!updateContactResult.isSuccess) {
                        setError(updateContactResult.message);
                    }
                } else {
                    const contactInput: CreateContactInput = {
                        name: groupContactGroupName
                    };
                    const createContactResult = await createContact(contactInput);
                    if (createContactResult.isSuccess) {
                        const input: UpdateMembershipInput = {
                            id: membership.id,
                            groupContactId: createContactResult.result.id
                        };
                        const updateMembershipResult = await updateMembership(input);
                        if (updateMembershipResult.isSuccess) {
                            setMembership(updateMembershipResult.result);
                        } else {
                            setError(updateMembershipResult.message);
                        }
                    } else {
                        setError(createContactResult.message);
                    }
                }
            }
            setIsLoading(false);
        }
    };

    const verifyGroupContactForm = () => {
        if(membershipType?.applicationFields?.groupName && !groupContactGroupName) {
            setError("Please include a value for Group Name");
            return false;
        }
        if(membershipType?.applicationFields?.contact) {
            if (!groupContactEmail) {
                setError("Please include a value for Email Address");
                return false;
            }
            if (!groupContactPhoneType) {
                setError("Please include a value for Phone Number Type");
                return false;
            }
            if (!groupContactPhoneNumber) {
                setError("Please include a value for Phone Number");
                return false;
            }
            if (!groupContactStreetAddressLine1) {
                setError("Please include a value for Street Address Line1");
                return false;
            }
            if (!groupContactCity) {
                setError("Please include a value for City");
                return false;
            }
            if (!groupContactProvState) {
                setError("Please include a value for State");
                return false;
            }
            if (!groupContactZip) {
                setError("Please include a value for Postal Code");
                return false;
            }
            if (!groupContactCountry) {
                setError("Please include a value for Country");
                return false;
            }
        }
        return true;
    };

    const formatSingleMembership = (currentMembership: Membership) => {
        let object: FormattedMembership = {
            id: currentMembership.id,
            personName: currentMembership.personName || "",
            membershipNumber: currentMembership.membershipId || "",
            backNumber: currentMembership.backNumber?.toString() || "",
            status: getMembershipStatus(currentMembership),
            type: currentMembership.type || currentMembership.organizationMembershipType?.name || "", 
            horseName: currentMembership.horse?.name || "",
            barnName: currentMembership.barn?.name || "",
            membershipStatus: currentMembership.membershipStatus || "",
            barnId: currentMembership.barnId || "",
            volunteerHours: currentMembership.volunteerHours ? currentMembership.volunteerHours.toString() : "0",
            meetingsAttended: currentMembership.meetingsAttended ? currentMembership.meetingsAttended.toString() : "0",
            membership: currentMembership,
            showsAttended: currentMembership.showsAttended ? currentMembership.showsAttended.toString() : "0",
            dateCreated: currentMembership.createdOn ? moment(currentMembership.createdOn).format("MM-DD-YY") : ""
        };
        return object;
    }

    const getMembershipStatus = (currentMembership: Membership) => {
        let status = "";
        // A membership is active if the today's date has not hit the date the membership ends OR if the membership is lifetime (aka no dateMembershipEnds)
        const isActive = (currentMembership.dateMembershipEnds) ? moment(new Date()).isSameOrBefore(moment(currentMembership.dateMembershipEnds)) : false;
        if (isActive) {
            status = currentMembership.membershipStatus === CONSTANT.MEMBERSHIP.APPLICATION_STATUS.COMPLETE ? 
                CONSTANT.MEMBERSHIP.APPLICATION_STATUS.ACTIVE : CONSTANT.MEMBERSHIP.APPLICATION_STATUS.APPLICATION_INCOMPLETE;
        } else {
            status = CONSTANT.MEMBERSHIP.APPLICATION_STATUS.EXPIRED;
        }
        return status;
    }

    const formatMemberships = (memberships: Membership[]) => {
        let formattedMemberships = [];
        for (var i = 0; i < memberships.length; i++) {
            const currentMembership = memberships[i];
            const formattedMembership: FormattedMembership = formatSingleMembership(currentMembership);
            formattedMemberships.push(formattedMembership);
        }
        setFormattedMemberships(formattedMemberships);
    }

    async function getMemberships(organizationId: string, membershipId: string, membershipTypeId: string) {
        if (isAELOrganization) {

        } else {
            if (membership.membershipId.length === 0) {
                const groupMemberships = [];
                groupMemberships.push(membership);
                formatMemberships(groupMemberships);
            } else {
                setIsLoading(true);
                const queryResult = await getMembershipsByOrganizationIdByMembershipIdByMembershipTypeByActiveStatus(organizationId, membershipId, membershipTypeId);
                if (queryResult.isSuccess) {
                    setGroupMembers(queryResult.result);
                    const groupMemberships = queryResult.result;
                    if (groupMemberships.length) {
                        const selectedMembershipIndex = groupMemberships.findIndex((m: Membership) => m.id === membership.id);
                        if (selectedMembershipIndex !== -1) {
                            groupMemberships[selectedMembershipIndex] = groupMemberships[0];
                            groupMemberships[0] = membership;
                            formatMemberships(groupMemberships);
                        }
                    }
                } else {
                    setError(queryResult.message);
                }
                setIsLoading(false);
            }
        }
    }

    async function getOrganizationMembershipTypes(organizationId: string) {
        try {
            setIsLoading(true);
            const queryResult = await getOrganizationMembershipTypesByOrganizationId(organizationId);
            if (queryResult.isSuccess) {
                const organizationMembershipTypes: OrganizationMembershipType[] = queryResult.result;
                setOrganizationMembershipTypes(organizationMembershipTypes);
                setIsLoading(false);
            }
        } catch (error: any) {
            console.error(error);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (organization?.id && membershipType?.id && !formattedMemberships) {
            getMemberships(organization.id, membership.membershipId, membershipType.id);
            getOrganizationMembershipTypes(organization.id);
        }
        if (membership && membershipType) {
            prePopulateGroupLeaderData();
            prePopulateGroupContactData();
        }
    }, [organization, membership, membershipType]);

    const onSelectMembership = (m: Membership) => {
        if (membership.id !== m.id) {
            const path = "/index/staff/organization/membership/edit/" + m.id;
            history.push(path);
        }
    }

    const handleSelectMembership = async (e: formattedOption) => {
        if(e.membership === null && e.value === "-") {
            setSelectedOption(e.value);
        } else {
            setSelectedOption("");
            setSelectedMembership(e.membership);
            setDialogError("");
        }
    }

    const handleAddMembership = async () => {
        if (selectedOption === "-") {
            setDialogError("Please select a membership to add or click Cancel.");
        } else {
            if (selectedMembership) {
                const input: UpdateMembershipInput = {
                    id: selectedMembership.id,
                    membershipId: membership.membershipId,
                    organizationMembershipTypeId: membership.organizationMembershipTypeId,
                    type: membership.type
                };
                setIsModalLoading(true);
                const updateResult = await updateMembership(input);
                if (updateResult.isSuccess) {
                    const updatedMembership = updateResult.result;
                    if (groupMembers?.length) {
                        groupMembers.push(updatedMembership);
                        formatMemberships(groupMembers || []);
                    }
                } else {
                    setError("The membership could not be updated. Please try again or contact hello@ringsidepro.com.");
                }
            } else {
                setError("The membership could not be updated. Please try again or contact hello@ringsidepro.com.");
            }
            setIsModalLoading(false);
            setShowModal(false);
        }
    };

    const handleDeleteGroupMembers = async () => {
        setIsLoading(true);
        setError("");
        if (formattedMemberships?.length) {
            const deletedMemberships = [];
            for(const m of formattedMemberships) {
                const deletedDocs = [];
                if(m.membership.documents?.length) {
                    for(const s3Object of m.membership.documents) {
                        const deleteResult = await handleDeleteS3File(s3Object?.key || "");
                        if(deleteResult.isSuccess) {
                            deletedDocs.push(deleteResult);
                        }
                    }
                }
                if(membership.documents && deletedDocs.length !== membership.documents?.length) {
                    setError("A problem occurred while deleting the membership's documents. Please contact hello@ringsidepro.com for help.");
                    break;
                }
                const deleteResult = await deleteMembership({id: m.id});
                if (deleteResult.isSuccess) {
                    deletedMemberships.push(deleteResult);
                } else {
                    setError("The membership(s) could not be deleted. Please try again or contact hello@ringsidepro.com.");
                }
            }
            if (deletedMemberships.length === formattedMemberships.length && organization?.id) {
                const path = "/index/staff/organization/members/general/" + organization.id;
                history.push(path);
            }
        }
        setShowDeleteModal(false);
        setIsLoading(false);
    }

    // Group member Table Columns
    const columns = [{
        id: 1,
        name: (<IonText>Back #</IonText>),
        selector: (row: FormattedMembership) => row.backNumber,
        cell: (membership: FormattedMembership) => (
                <IonText onClick={() => onSelectMembership(membership.membership)} className="description ion-text-wrap">
                    {membership.backNumber}
                </IonText>
            ),
        center: true,
        width: "8rem",
        sortable: true
    }, {
        id: 2,
        name: (<IonText>Member Id</IonText>),
        selector: (membership: FormattedMembership) => membership.membership.membershipId,
        center: true,
        width: "8rem",
        sortable: true
    }, {
        id: 3,
        name: (<IonText>Date Created</IonText>),
        selector: (membership: FormattedMembership) => membership.dateCreated,
        center: true,
        sortable: true
    }, {
        id: 4,
        name: (<IonText>Rider</IonText>),
        selector: (membership: FormattedMembership) => membership.personName,
        cell: (membership: FormattedMembership) => (
            <IonText onClick={() => onSelectMembership(membership.membership)} className="description ion-text-wrap">
                {membership.personName}
            </IonText>
        ),
        width: "11rem",
        sortable: true
    }, {
        id: 5,
        name: (<IonText>Horse</IonText>),
        selector: (membership: FormattedMembership) => membership.horseName,
        cell: (membership: FormattedMembership) => (
            <IonText onClick={() => onSelectMembership(membership.membership)} className="description ion-text-wrap">
                {membership.horseName}
            </IonText>
        ),
        sortable: true
    }, {
        id: 6,
        name: (<IonText >Barn</IonText>),
        selector: (membership: FormattedMembership) => membership.barnName,
        cell: (membership: FormattedMembership) => (
            <IonText onClick={() => onSelectMembership(membership.membership)} className="description ion-text-wrap">
                {membership.barnName}
            </IonText>
        ),
        sortable: true
    }, {
        id: 7,
        name: (<IonText>Status</IonText>),
        selector: (membership: FormattedMembership) => membership.status,
        cell: (membership: FormattedMembership) => (
            membership.status === CONSTANT.MEMBERSHIP.APPLICATION_STATUS.APPLICATION_INCOMPLETE ?
            <IonText onClick={() => onSelectMembership(membership.membership)} color="warning">{CONSTANT.MEMBERSHIP.APPLICATION_STATUS.APPLICATION_INCOMPLETE}</IonText> :
            membership.status === CONSTANT.MEMBERSHIP.APPLICATION_STATUS.ACTIVE ?
            <IonText onClick={() => onSelectMembership(membership.membership)}>{CONSTANT.MEMBERSHIP.APPLICATION_STATUS.ACTIVE}</IonText> :
            <IonText onClick={() => onSelectMembership(membership.membership)}>{CONSTANT.MEMBERSHIP.APPLICATION_STATUS.EXPIRED}</IonText>
        ),
        sortable: true
    }, {
        id: 8,
        name: (<IonText>Type</IonText>),
        selector: (membership: FormattedMembership) => membership.type,
        cell: (membership: FormattedMembership) => (
            <IonText onClick={() => onSelectMembership(membership.membership)} className="description ion-text-wrap">
                {membership.type}
            </IonText>
        ),
        center: true,
        width:"15rem",
        sortable: true
    }, {
        id: 9,
        name: (<IonText >Hours</IonText>),
        selector: (membership: FormattedMembership) => membership.volunteerHours,
        cell: (membership: FormattedMembership) => (
                <IonText onClick={() => onSelectMembership(membership.membership)} className="description ion-text-wrap">
                    {membership.volunteerHours}
                </IonText>
            ),
        center: true,
        sortable: true
    }, {
        id: 10,
        name: (<IonText>Meetings</IonText>),
        selector: (membership: FormattedMembership) => membership.meetingsAttended,
        cell: (membership: FormattedMembership) => (
                <IonText onClick={() => onSelectMembership(membership.membership)} className="description ion-text-wrap">
                    {membership.meetingsAttended}
                </IonText>
            ),
        center: true,
        sortable: true
    }, {
        id: 11,
        name: (<IonText>Shows</IonText>),
        selector: (membership: FormattedMembership) => membership.showsAttended,
        cell: (membership: FormattedMembership) => (
                <IonText onClick={() => onSelectMembership(membership.membership)} className="description ion-text-wrap">
                    {membership.showsAttended}
                </IonText>
            ),
        center: true,
        sortable: true
    }];

    const tableCustomStyles = {
        headCells: {
            style: {
                width:"9rem",
                fontSize: "15px",
                fontWeight: "bolder",
            }
        }
    };

    return(
        <IonRow className="ion-justify-content-center">
            {error && <ErrorAlert width="12" error={error} />}
            {isLoading ?
                <Spinner/>
            :
                <IonCol size="12">
                    {/* Group Members Card */}
                    {!isAELOrganization && (
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonRow onClick={() => setIsExpandedGroupMembers(!isExpandedGroupMembers)}>
                                <IonCol size="10" className="ion-text-left">
                                    <h5>Group Members</h5>
                                </IonCol>
                                <IonCol size="2" className="ion-text-right">
                                    <IonIcon size="large" icon={isExpandedGroupMembers? chevronUp : chevronDown} />
                                </IonCol>
                            </IonRow>
                            {isExpandedGroupMembers && (<IonCardContent>
                                <IonRow className="ion-justify-content-between mb-3">
                                    <IonCol>
                                        <h5>Click on a row to view that membership.</h5>
                                    </IonCol>
                                    <IonButton 
                                        id="add-new-group-btn"
                                        onClick={()=>setShowModal(true)}>
                                        {(isWindows() || (isPlatform("desktop")))  && <IonIcon icon={add}/>}
                                        {(isWindows() || (isPlatform("desktop"))) ? "Add New" : <IonIcon icon={add}/>}
                                    </IonButton>
                                </IonRow>
                                <DataTable
                                    columns={columns}
                                    data={formattedMemberships || []}
                                    customStyles={tableCustomStyles}
                                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                                    paginationComponentOptions={{
                                        selectAllRowsItem: true,
                                        selectAllRowsItemText: "ALL",
                                    }}
                                    onRowClicked={(row: FormattedMembership) => {
                                        onSelectMembership(row.membership);
                                    }}
                                    pagination
                                    highlightOnHover
                                />
                                <IonModal backdropDismiss={false} isOpen={showModal}>
                                    <IonToolbar color="light">
                                        <IonTitle className="ion-text-center">
                                            Add Membership
                                        </IonTitle>
                                        <IonButtons slot="end">
                                            <IonButton
                                                id="add-group-membership-modal-close-icon"
                                                fill="clear"
                                                onClick={() => {
                                                    setShowModal(false);
                                                    setDialogError("");
                                                }}
                                            >
                                                <p id="closeAddGroupMembershipModalBtn" className="font-weight-normal text-medium text-capitalize">
                                                    <IonIcon icon={close} />
                                                </p>
                                            </IonButton>
                                        </IonButtons>
                                    </IonToolbar>
                                    {dialogError && <ErrorAlert width="12" error={dialogError} />}
                                    <IonContent className="ion-padding">
                                        <IonSegment value={segmentValue} onIonChange={(e) => setSegmentValue(e.detail.value!)}>
                                            <IonSegmentButton value={SEGMENT_CONSTANT.FIND}>
                                                <IonLabel>Find</IonLabel>
                                            </IonSegmentButton>
                                            <IonSegmentButton value={SEGMENT_CONSTANT.CREATE}>
                                                <IonLabel>Create</IonLabel>
                                            </IonSegmentButton>
                                        </IonSegment>
                                        {segmentValue === SEGMENT_CONSTANT.FIND && (
                                            <>
                                                <IonRow className="justify-content-center mt-5">
                                                    <IonCol sizeMd="10" sizeXs="12" className="text-center">
                                                        <h5>Select the membership you want to add:</h5>
                                                    </IonCol>
                                                    <IonCol sizeMd="8" sizeXs="10" className="text-center">
                                                        <AddGroupMemberships
                                                            membership={membership}
                                                            organizationId={organization?.id}
                                                            selectedValue={
                                                                selectedMembership?.id
                                                            }
                                                            onSelect={(e: formattedOption) =>
                                                                handleSelectMembership(e)
                                                            }
                                                        />
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="justify-content-center mt-5">
                                                    <IonCol sizeMd="8" sizeXs="12" className="text-center">
                                                        <h6>Note: this will change the selected membership's membership type and id to match the current group.</h6>
                                                    </IonCol>
                                                </IonRow>
                                                {isModalLoading ? (
                                                    <Spinner />
                                                ) : (
                                                    <IonRow className="ion-justify-content-center ion-padding-top mt-6">
                                                        <IonCol size="6" className="ion-text-center">
                                                            <IonButton
                                                                id="add-group-membership-modal-cancel-btn"
                                                                expand="block"
                                                                color="light"
                                                                onClick={() => {
                                                                    setShowModal(false);
                                                                    setDialogError("");
                                                                }}
                                                            >
                                                                Cancel
                                                            </IonButton>
                                                        </IonCol>
                                                        <IonCol size="6" className="ion-text-center">
                                                            <IonButton
                                                                id="add-group-membership-modal-add-btn" 
                                                                expand="block" color="success" 
                                                                onClick={handleAddMembership}>
                                                                Add
                                                            </IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                )}
                                            </>
                                        )}
                                        {segmentValue === SEGMENT_CONSTANT.CREATE && (
                                            <>
                                                <IonRow className="pb-3">
                                                    <IonCol size="12">
                                                        <IonLabel position="stacked">Membership Type <RequiredInputIndicator /></IonLabel>
                                                        <SelectOrganizationMembershipType membershipTypes={organizationMembershipTypes} selectedValue={membershipType?.id} isDisabled={true} onSelect={(value: OrganizationMembershipType | undefined) => { }} />
                                                    </IonCol>
                                                </IonRow>
                                                <p className="text-info description ion-text-wrap">Try finding the barn in the select. If it doesn't exist, type in the name of the barn.</p>
                                                <IonRow className="pb-3">
                                                    <IonCol size="12">
                                                    <IonLabel position="stacked">Barn</IonLabel>
                                                        <SelectFromAllBarns barn={barn} onSelect={(barn: Barn) => handleSelectBarn(barn)} />
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="pb-3">
                                                    <IonCol size="12">
                                                        <IonItem color="white">
                                                            <IonLabel position="stacked">Barn Name</IonLabel>
                                                            <IonInput
                                                                type="text"
                                                                value={barnName}
                                                                aria-required={true}
                                                                onIonChange={e => {
                                                                    setBarnName(e.detail.value!)
                                                                }}
                                                            />
                                                        </IonItem>
                                                    </IonCol>
                                                </IonRow>
                                                <p className="text-info description ion-text-wrap">Try finding the rider in the select. If the rider doesn't exist, type in the name of the rider.</p>
                                                <IonRow className="pb-3">
                                                    <IonCol size="12">
                                                        <IonLabel position="stacked">Rider</IonLabel>
                                                        <SelectRiderFromBarn barn={barn || undefined} selectedValue={rider?.id} onSelect={(rider: Rider) => handleSelectRider(rider)} />
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="pb-3">
                                                    <IonCol size="12">
                                                        <IonItem color="white">
                                                            <IonLabel position="stacked">Rider Name</IonLabel>
                                                            <IonInput
                                                                type="text"
                                                                value={riderName}
                                                                aria-required={true}
                                                                onIonChange={e => {
                                                                    setRiderName(e.detail.value!)
                                                                }}
                                                            />
                                                        </IonItem>
                                                    </IonCol>
                                                </IonRow>
                                                <p className="text-info description ion-text-wrap">Try finding the horse in the select. Horses load after selecting the barn. If the horse doesn't exist, type in the name of the horse.</p>
                                                <IonRow className="pb-3">
                                                    <IonCol size="12">
                                                    <IonLabel position="stacked">Horse</IonLabel>
                                                        <SelectHorse horses={horseOptions} selectedValue={horse?.id} onSelect={(horse: Horse) => {handleSelectHorse(horse)}} />
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="pb-3">
                                                    <IonCol size="12">
                                                        <IonItem color="white">
                                                            <IonLabel position="stacked">Horse Name</IonLabel>
                                                            <IonInput
                                                                type="text"
                                                                value={horseName}
                                                                aria-required={true}
                                                                onIonChange={e => {
                                                                    setHorseName(e.detail.value!)
                                                                }}
                                                            />
                                                        </IonItem>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="pb-3">
                                                    <IonCol size="12">
                                                        <IonItem color="white">
                                                            <IonLabel position="stacked">Member Full Name<RequiredInputIndicator /></IonLabel>
                                                            <IonInput
                                                                type="text"
                                                                value={fullName}
                                                                aria-required={true}
                                                                onIonChange={e => {
                                                                    setError("")
                                                                    setFullName(e.detail.value!)
                                                                }}
                                                            />
                                                        </IonItem>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="pb-3">
                                                    <IonCol size="12">
                                                        <IonItem color="white">
                                                            <IonLabel position="stacked">Member Birth Date<RequiredInputIndicator /></IonLabel>
                                                            <IonInput
                                                                type="date"
                                                                value={birthDate}
                                                                aria-required={true}
                                                                onIonChange={e => {
                                                                    setError("")
                                                                    setBirthDate(e.detail.value!)
                                                                }}
                                                            />
                                                        </IonItem>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="pb-3">
                                                    {membershipType && membershipType.applicationFields?.backNumber &&<IonCol size="12">
                                                        <IonItem color="white">
                                                            <IonLabel position="floating">Member Back Number</IonLabel>
                                                            <IonInput
                                                                type="text"
                                                                value={backNumber}
                                                                aria-required={true}
                                                                onIonChange={e => {
                                                                    setError("")
                                                                    setBackNumber(parseInt(e.detail.value!))
                                                                }}
                                                            />
                                                        </IonItem>
                                                    </IonCol>}
                                                </IonRow>
                                                {isModalLoading ? (
                                                    <Spinner />
                                                ) : (
                                                    <IonRow className="ion-justify-content-center ion-padding-top">
                                                        <IonCol size="6" className="ion-text-center">
                                                            <IonButton id="add-group-membership-modal-cancel-btn" expand="block" color="light" onClick={() => setShowModal(false)}>
                                                                Cancel
                                                            </IonButton>
                                                        </IonCol>
                                                        <IonCol size="6" className="ion-text-center">
                                                            <IonButton id="add-group-membership-modal-add-btn" expand="block" color="success" onClick={handleCreateMembership}>
                                                                Add
                                                            </IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                )}
                                            </>
                                        )}
                                    </IonContent>
                                </IonModal>
                            </IonCardContent>)}
                        </IonCard>
                    )}

                    {/* Group Leader Card */}
                    <IonCard mode="md" className="ion-padding bg-white">
                        <IonRow onClick={() => setIsExpandedGroupLeader(!isExpandedGroupLeader)}>
                            <IonCol size="10" className="ion-text-left">
                                <h5>{isAELOrganization ? "Head Coach" : "Group Leader"}</h5>
                            </IonCol>
                            <IonCol size="2" className="ion-text-right">
                                <IonIcon size="large" icon={isExpandedGroupLeader? chevronUp : chevronDown} />
                            </IonCol>
                        </IonRow>
                        {isExpandedGroupLeader && (<IonCardContent>
                            {membershipType?.applicationFields?.groupLeader || membershipType?.applicationFields?.groupLeaderContactInfo ?
                            <>
                                <IonRow>
                                    {membershipType?.applicationFields?.groupLeader &&
                                        <IonCol sizeXs="12" sizeMd="6">
                                            <IonItem color="white">
                                                <IonLabel position="stacked">
                                                    {isAELOrganization ? "Head Coach Name" : "Leader Name"}
                                                    <span className="description text-danger">
                                                        *
                                                    </span>
                                                </IonLabel>
                                                <IonInput
                                                    id={"group-leader-name"}
                                                    type="text"
                                                    name="LeaderName"
                                                    value={groupLeaderName}
                                                    aria-required={true}
                                                    onIonChange={(e) => {
                                                        setError("");
                                                        setGroupLeaderName(e.detail.value!);
                                                    } }
                                                />
                                            </IonItem>
                                        </IonCol>
                                    }
                                    {membershipType?.applicationFields?.groupLeaderContactInfo &&
                                        <IonCol sizeXs="12" sizeMd="6">
                                            <IonItem color="white">
                                                <IonLabel position="stacked">
                                                    Email Address{" "}
                                                    <RequiredInputIndicator />
                                                </IonLabel>
                                                <IonInput
                                                    id="group-leader-contact-email"
                                                    type="email"
                                                    required={true}
                                                    aria-required={true}
                                                    value={groupLeaderEmail}
                                                    onIonChange={(e) => {
                                                        setError("");
                                                        setGroupLeaderEmail(e.detail.value!);
                                                    } } />
                                            </IonItem>
                                        </IonCol>
                                    }
                                </IonRow>
                                {membershipType?.applicationFields?.groupLeaderContactInfo &&
                                    <>
                                        <IonRow>
                                            <IonCol sizeXs="12" sizeMd="6">
                                                <PhoneNumberTypeSelect
                                                    id="group-leader-edit-phone-number"
                                                    isRequired={true}
                                                    selectedType={groupLeaderPhoneType}
                                                    onChange={handleLeaderPhoneNumberTypeSelectInput} />
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="6">
                                                <IonLabel className="pl-3 text-darker" position="stacked">
                                                    Phone Number{" "}
                                                    <RequiredInputIndicator />
                                                </IonLabel>
                                                <PhoneInput
                                                    inputProps={{
                                                        name: "phone",
                                                        required: true,
                                                        autoFocus: true,
                                                        id: "group-leader-edit-phone-number"
                                                    }}
                                                    placeholder="Enter phone number"
                                                    country={"us"}
                                                    enableSearch
                                                    enableAreaCodes={false}
                                                    inputStyle={{
                                                        width: "100%",
                                                        height: "auto",
                                                    }}
                                                    value={groupLeaderPhoneNumber}
                                                    onChange={(groupLeaderPhoneNumber) => {
                                                        setError("");
                                                        setGroupLeaderPhoneNumber(groupLeaderPhoneNumber);
                                                    } }
                                                />
                                            </IonCol>
                                        </IonRow>
                                        <AddressFormGroup
                                            id="membership-app-editGroupInfo-group-leader"
                                            isRequired={true}
                                            isTextDarker={true}
                                            onChange={handleLeaderAddressInputChange}
                                            address={groupLeaderAddress}
                                            setIsDisabled={setIsDisabled}
                                        />
                                    </>
                                }
                                <IonRow className="ion-justify-content-center mb-2 mt-4">
                                    <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                                        <IonButton
                                            id="group-leader-contact-save-btn"
                                            color="tertiary"
                                            expand="block"
                                            onClick={handleGroupLeaderContactInfoSave}
                                        >
                                            Save
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </>
                            :
                                <IonText>This membership type does not include a leader name or leader contact.</IonText>
                            }
                        </IonCardContent>)}
                    </IonCard>

                    {/* Group Contact Card */}
                    <IonCard mode="md" className="ion-padding bg-white">
                        <IonRow onClick={() => setIsExpandedGroupContact(!isExpandedGroupContact)}>
                            <IonCol size="10" className="ion-text-left">
                                <h5>Group Contact</h5>
                            </IonCol>
                            <IonCol size="2" className="ion-text-right">
                                <IonIcon size="large" icon={isExpandedGroupContact? chevronUp : chevronDown} />
                            </IonCol>
                        </IonRow>
                        {isExpandedGroupContact && (<IonCardContent>
                            {membershipType?.applicationFields?.groupName || membershipType?.applicationFields?.contact ?
                            <>
                                <IonRow>
                                    {membershipType?.applicationFields?.groupName &&
                                        <IonCol sizeXs="12" sizeMd="6">
                                            <IonItem color="white">
                                                <IonLabel position="stacked">
                                                    Group Name
                                                    <span className="description text-danger">
                                                        *
                                                    </span>
                                                </IonLabel>
                                                <IonInput
                                                    id={"group-contact-name"}
                                                    type="text"
                                                    name="GroupName"
                                                    value={groupContactGroupName}
                                                    aria-required={true}
                                                    onIonChange={(e) => {
                                                        setError("");
                                                        setGroupContactGroupName(e.detail.value!);
                                                    } }
                                                />
                                            </IonItem>
                                        </IonCol>
                                    }
                                    {membershipType?.applicationFields?.contact &&
                                        <IonCol sizeXs="12" sizeMd="6">
                                            <IonItem color="white">
                                                <IonLabel position="stacked">
                                                    Email Address{" "}
                                                    <RequiredInputIndicator />
                                                </IonLabel>
                                                <IonInput
                                                    id="group-contact-email"
                                                    type="email"
                                                    required={true}
                                                    aria-required={true}
                                                    value={groupContactEmail}
                                                    onIonChange={(e) => {
                                                        setError("");
                                                        setGroupContactEmail(e.detail.value!);
                                                    } } />
                                            </IonItem>
                                        </IonCol>
                                    }
                                </IonRow>
                                {membershipType?.applicationFields?.contact &&
                                    <>
                                        <IonRow>
                                            <IonCol sizeXs="12" sizeMd="6">
                                                <PhoneNumberTypeSelect
                                                    id="group-contact-edit-phone-number"
                                                    isRequired={true}
                                                    selectedType={groupContactPhoneType}
                                                    onChange={handleContactPhoneNumberTypeSelectInput} />
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="6">
                                                <IonLabel className="pl-3 text-darker" position="stacked">
                                                    Phone Number{" "}
                                                    <RequiredInputIndicator />
                                                </IonLabel>
                                                <PhoneInput
                                                    inputProps={{
                                                        name: "phone",
                                                        required: true,
                                                        autoFocus: true,
                                                        id: "group-contact-edit-phone-number"
                                                    }}
                                                    placeholder="Enter phone number"
                                                    country={"us"}
                                                    enableSearch
                                                    enableAreaCodes={false}
                                                    inputStyle={{
                                                        width: "100%",
                                                        height: "auto",
                                                    }}
                                                    value={groupContactPhoneNumber}
                                                    onChange={(groupContactPhoneNumber) => {
                                                        setError("");
                                                        setGroupContactPhoneNumber(groupContactPhoneNumber);
                                                    } }
                                                />
                                            </IonCol>
                                        </IonRow>
                                        <AddressFormGroup
                                            id="membership-app-editGroupInfo-group-contact-info"
                                            isRequired={true}
                                            isTextDarker={true}
                                            onChange={handleContactAddressInputChange}
                                            address={groupContactAddress}
                                            setIsDisabled={setIsDisabled}
                                        />
                                    </>
                                }
                                <IonRow className="ion-justify-content-center mb-2 mt-4">
                                    <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                                        <IonButton
                                            id="group-contact-save-btn"
                                            color="tertiary"
                                            expand="block"
                                            onClick={handleGroupContactSave}
                                        >
                                            Save
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </>
                            :
                                <IonText>This membership type does not include a group name or group contact.</IonText>
                            }
                        </IonCardContent>)}
                    </IonCard>

                    {/* Delete Group Memberships Card */}
                    {!isAELOrganization && (
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonRow onClick={() => setIsExpandedDeleteGroupMemberships(!isExpandedDeleteGroupMemberships)}>
                                <IonCol size="10" className="ion-text-left">
                                    <h5> Delete Group Memberships</h5>
                                </IonCol>
                                <IonCol size="2" className="ion-text-right">
                                    <IonIcon size="large" icon={isExpandedDeleteGroupMemberships? chevronUp : chevronDown} />
                                </IonCol>
                            </IonRow>
                            {isExpandedDeleteGroupMemberships && (<IonCardContent>
                            <h5>If you delete these group memberships, they will be permanently removed. You cannot undo this action.</h5>
                            <IonRow className="ion-justify-content-center ion-padding-top">
                                    <IonCol sizeMd="3" sizeXs="6" className="ion-text-center">
                                        <IonButton id="delete-group-membership" expand="block" color="danger" onClick={() => setShowDeleteModal(true)}>
                                            Delete
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                                <IonModal
                                    backdropDismiss={false}
                                    isOpen={showDeleteModal}
                                    id="delete-group-membership-modal"
                                >
                                    <IonToolbar color="light">
                                        <IonTitle className="ion-text-center">
                                            Remove Group Memberships
                                        </IonTitle>
                                        <IonButtons slot="end">
                                            <IonButton
                                                id="delete-group-membership-Modal-close-Btn"
                                                fill="clear"
                                                onClick={() => setShowDeleteModal(false)}
                                            >
                                                <p
                                                    id="delete-group-membership-modal-close-icon"
                                                    className="font-weight-normal text-medium text-capitalize"
                                                >
                                                    <IonIcon icon={close} />
                                                </p>
                                            </IonButton>
                                        </IonButtons>
                                    </IonToolbar>
                                    <IonContent className="ion-padding">
                                        {error && <ErrorAlert width="12" error={error} />}
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol size="12">
                                                <h5>Are you sure you want to permanently remove these memberships:</h5>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol size="10" className="ion-text-center">
                                                <h4 className="font-weight-bold">Member Names : </h4>
                                            </IonCol>
                                            <IonCol size="10" className="ion-text-center">
                                                {formattedMemberships?.map((element, index) => {
                                                    return (
                                                        <h6 key={index} className="font-weight-bold">{element.membership.personName}</h6>
                                                    );
                                                })}
                                            </IonCol>
                                        </IonRow>
                                    </IonContent>
                                    {isLoading ?
                                        <Spinner/> 
                                        :
                                        <IonRow className="ion-justify-content-center ion-padding-top">
                                            <IonCol size="6" className="ion-text-center">
                                                <IonButton
                                                    id="delete-group-membership-modal-cancel-btn"
                                                    expand="block"
                                                    color="light"
                                                    onClick={() => setShowDeleteModal(false)}
                                                >
                                                    Cancel
                                                </IonButton>
                                            </IonCol>
                                            <IonCol size="6" className="ion-text-center">
                                                <IonButton
                                                    id="delete-group-membership-modal-remove-btn"
                                                    expand="block" 
                                                    color="danger" 
                                                    onClick={handleDeleteGroupMembers}
                                                >
                                                    Remove
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    }
                                </IonModal>
                            </IonCardContent>)}
                        </IonCard>
                    )}
                </IonCol>
            }
        </IonRow>
    )
}

export default EditMembershipApplicationGroupInfo;
