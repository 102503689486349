import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import Spinner from "../../Spinners/Spinner";
import { Alert, Block, Event, EventAuditor, EventFilter, Organization, OrganizationAuditor } from "../../../models";
import ErrorAlert from "../../Errors/ErrorAlert";
import { getEventFiltersByEventId, getEventFiltersByOrganizationId } from "../../../utilities/eventFilter/EventFilter";
import { close } from "ionicons/icons";
import InformationBanner from "../../Banners/InformationBanner";
import { getActiveEventAuditorsWithFilterByEventId } from "../../../utilities/eventAuditor/EventAuditor";
import { Badge, FormGroup, Input, UncontrolledTooltip } from "reactstrap";
import { getValidBlocksByOrganizationId } from "../../../utilities/block/Block";
import { getCreditCountFromBlocks } from "../../../utilities/block/CreditCount";
import SelectEventAuditors from "../../EventAuditor/SelectEventAuditor";
import SelectEventAlertFiltersForm, { FormattedFilter } from "./SelectEventAlertFiltersForm";
import { getEventAuditorsByFilters } from "../../../utilities/eventFilter/FilterEventAuditors";
import { createAlert } from "../../../utilities/alerts/Alerts";
import { PersonContext } from "../../../context/PersonContext";
import { handleSendCanadianMassSMS, handleSendMassSMS } from "../../../utilities/twilio/TwilioSMS";
import SuccessBanner from "../../Banners/SuccessBanner";
import BuyCreditsForm from "./BuyCreditsForm";
import { CreateAlertInput } from "../../../API";
import { useSubscriptionByItself } from "../../../utilities/subscription/Subscription";
import { onCreateBlock, onCreateEventAuditor } from "../../../graphql/subscriptions";
import { sortBlocksByDateOldest } from "../../../utilities/block/SortBlocks";
import AlertImageForm from "./AlertImageForm";
import { createMMSPDFFileName, createMMSPictureFileName, handleUploadS3Image } from "../../../utilities/s3Object/s3Object";
import awsmobile from "../../../aws-exports";
import SelectEventFilterRolesOptionForm, { FormattedRoleOption } from "./EventFilterRolesOptionForm";
import { TextAlertRoleFilter, TextAlertRolesFilters } from "../../../utilities/roles/Roles";
import { getActiveOrganizationAuditorsWithFilterByOrganizationId } from "../../../utilities/organizationAuditor/OrganizationAuditor";
import moment from "moment";
import { Result } from "../../../interfaces/Result";
import { sortEventAuditorsByName } from "../../../utilities/eventAuditor/SortEventAuditors";
import { sortOrganizationAuditorsByName } from "../../../utilities/organizationAuditor/SortOrganizationAuditors";
import constants from "../../../constant/constant";

interface _Props {
    event?: Event
    organization?: Organization
}

export interface FormattedBlock {
    id: string
    creditsToUse: number
    setBlockToEmpty: boolean
    block: Block
}

const SendAlertForm: React.FC<_Props> = ({event, organization}) => {
    const user = useContext(PersonContext);

    const blockSubscription = useSubscriptionByItself({
        config: {
            query: onCreateBlock,
            key: "onCreateBlock"
        }
    });

    const auditorSubscription = useSubscriptionByItself({
        config: {
            query: onCreateEventAuditor,
            key: "onCreateEventAuditor"
        }
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isCountingCreditsNeeded, setIsCountingCredits] = useState(false);
    const [isUnlimitedCredits, setIsUnlimitedCredits] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [currentBlockSubscriptionItem, setCurrentBlockSubscriptionItem] = useState<any>();
    const [currentAuditorSubscriptionItem, setCurrentAuditorSubscriptionItem] = useState<any>();
    const [isGeneral, setIsGeneral] = useState(true);
    const [isFiltered, setIsFiltered] = useState(false);
    const [isCustom, setIsCustom] = useState(false);
    const [eventFilters, setEventFilters] = useState<EventFilter[] | null | undefined>();
    const [formattedRoleOptions, setFormattedRoleOptions] = useState<FormattedRoleOption[] | null | undefined>();
    const [formattedFilters, setFormattedFilters] = useState<FormattedFilter[] | null | undefined>();
    const [eventAuditors, setEventAuditors] = useState<(EventAuditor | OrganizationAuditor)[] | null | undefined>();
    const [selectedEventAuditors, setSelectedEventAuditors] = useState<(EventAuditor | OrganizationAuditor)[] | null | undefined>();
    // const [currentSentCount, setCurrentSentCount] = useState(0);
    const [messageBody, setMessageBody] = useState("");
    const [characterCount, setCharacterCount] = useState(0);
    const [messageSegmentCount, setMessageSegmentCount] = useState(1);
    const [image, setImage] = useState<File | null | undefined>();
    const [imageMessage, setImageMessage] = useState("");
    const [creditsNeeded, setCreditsNeeded] = useState(0);
    const [validOrgBlocks, setValidOrgBlocks] = useState<Block[] | null | undefined>();
    const [formattedBlocks, setFormattedBlocks] = useState<FormattedBlock[] | null | undefined>();
    const [totalCreditCount, setTotalCreditCount] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showCreditsModal, setShowCreditModal] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const formatEventRoleFilters = () => {
        let options: FormattedRoleOption[] = [];
        let selectedArray: string[] = [];
        if (TextAlertRolesFilters) {
            for (var i = 0; i < TextAlertRolesFilters.length; i++) {
                const currentEventFilter: TextAlertRoleFilter = TextAlertRolesFilters[i];

                // For the roles filter, we typically will start with none selected and make the person pick
                let isSelected = true; 

                let childrenArray: (FormattedRoleOption[] | null) = null;

                if (currentEventFilter.children) {
                    childrenArray = [];
                    const childArray = currentEventFilter.children;
                    for (var j = 0; j < childArray.length; j++) {
                        const currentEventFilter: TextAlertRoleFilter = childArray[j];
        
                        // For the roles filter, we typically will start with none selected and make the person pick
                        let isChildSelected = true;

                        const formattedOption: FormattedRoleOption = {
                            name: currentEventFilter.name,
                            value: currentEventFilter.value,
                            isSelected: isChildSelected
                        };

                        childrenArray.push(formattedOption);
                    }
                }

                const formattedOption: FormattedRoleOption = {
                    name: currentEventFilter.name,
                    value: currentEventFilter.value,
                    isSelected: isSelected,
                    children: childrenArray || undefined
                };

                options.push(formattedOption);
                selectedArray.push(currentEventFilter.value);
            }
        }
        setFormattedRoleOptions(options);
    }

    async function getEventFilters(event: Event) {
        const queryResult = await getEventFiltersByEventId(event.id);
        if (queryResult.isSuccess) {
            setEventFilters(queryResult.result);
        }
    }

    async function getEventFiltersByOrganization(organization: Organization) {
        const queryResult = await getEventFiltersByOrganizationId(organization.id);
        if (queryResult.isSuccess) {
            setEventFilters(queryResult.result);
        }
    }

    async function getEventAuditors(event: Event) {
        const queryResult = await getActiveEventAuditorsWithFilterByEventId(event.id);
        if (queryResult.isSuccess) {
            const eventAuditorsList = queryResult.result;
            setEventAuditors(eventAuditorsList);

            // Start with all event auditors "selected"
            const sorted = sortEventAuditorsByName(eventAuditorsList);
            setSelectedEventAuditors(sorted || eventAuditorsList);
        }
    }

    async function getOrganizationAuditors(org: Organization) {
        const queryResult = await getActiveOrganizationAuditorsWithFilterByOrganizationId(org.id);
        if (queryResult.isSuccess) {
            const orgAuditorsList = queryResult.result;
            setEventAuditors(orgAuditorsList);

            // Start with all event auditors "selected"
            const sorted = sortOrganizationAuditorsByName(orgAuditorsList);
            setSelectedEventAuditors(sorted || orgAuditorsList);
        }
    }

    async function getEventAuditorsAndFilter(event: Event) {
        const queryResult = await getActiveEventAuditorsWithFilterByEventId(event.id);
        if (queryResult.isSuccess) {
            const eventAuditors = queryResult.result;
            setEventAuditors(eventAuditors);
            if (eventAuditors) {
                if (isGeneral) {
                    setSelectedEventAuditors(eventAuditors);
                    calculateValues(undefined, eventAuditors);
                } 
                else if (isFiltered && formattedRoleOptions && formattedFilters) {
                    const queryFilterResult = await getEventAuditorsByFilters(eventAuditors, formattedRoleOptions, formattedFilters);
                    if (queryFilterResult.isSuccess) {
                        const eventAuditorsList = queryFilterResult.result;
                        const sorted = sortEventAuditorsByName(eventAuditorsList);
                        setSelectedEventAuditors(sorted || eventAuditorsList);
                        calculateValues(undefined, eventAuditorsList);
                    }
                } 
                else {
                    // No change
                }
            }
        }
    }

    function formatCreditBlocks(blocks: Block[]) {
        let results: FormattedBlock[] = [];
        blocks.forEach(block => {
            const formatted: FormattedBlock = {
                id: block.id,
                creditsToUse: 0,
                setBlockToEmpty: false,
                block: block
            };
            results.push(formatted);
        });
        setFormattedBlocks(results);
    }

    async function getBlocksByOrganizationId(organizationId: string) {
        if (organizationId) {
            const queryResult = await getValidBlocksByOrganizationId(organizationId);
            if (queryResult.isSuccess) {
                const sorted = sortBlocksByDateOldest(queryResult.result);
                setValidOrgBlocks(sorted || queryResult.result);
                formatCreditBlocks(sorted || queryResult.result);
                setTotalCreditCount(getCreditCountFromBlocks(queryResult.result));
            }
        }
    }

    useEffect(() => {
        if (event) {
            if (event.eventOptions?.hasUnlimitedCredits) setIsUnlimitedCredits(true);
            formatEventRoleFilters();
            getEventFilters(event);
            getEventAuditors(event);
            if (event.organizationId) getBlocksByOrganizationId(event.organizationId);
        }

        if (organization) {
            formatEventRoleFilters();
            getEventFiltersByOrganization(organization);
            getOrganizationAuditors(organization);
            getBlocksByOrganizationId(organization.id);
        }
    }, [event, organization]);

    useEffect(() => {
        if (blockSubscription && blockSubscription[0] !== undefined && blockSubscription[0] !== currentBlockSubscriptionItem) {
            setCurrentBlockSubscriptionItem(blockSubscription[0]);
            if (event && event.organizationId) {
                getBlocksByOrganizationId(event.organizationId);
            }
        }
    }, [blockSubscription]);

    useEffect(() => {
        if (auditorSubscription && auditorSubscription[0] !== undefined && auditorSubscription[0] !== currentAuditorSubscriptionItem) {
            setCurrentAuditorSubscriptionItem(auditorSubscription[0]);
            if (event) {
                getEventAuditorsAndFilter(event);
            }
        }
    }, [auditorSubscription]);

    const calculateValues = (currentMessageBody?: (string | null), currentSelectedEventAuditors?: (EventAuditor[] | null), file?: (File | null)) => {
        setIsCountingCredits(true);

        let body = currentMessageBody || messageBody;
        
        // Count the characters taking the Twilio limitations into account
        // Emojis: https://www.twilio.com/blog/2017/03/what-the-heck-is-a-segment.html
        // Image: https://www.twilio.com/sms/pricing/us
        const emojiRegex = /\p{Extended_Pictographic}/u;
        const isEmoji = emojiRegex.test(body);
        const charCount = body.length * (isEmoji ? 2 : 1);
        let subscribers = currentSelectedEventAuditors || selectedEventAuditors;
        let imageFile = file || image;
        const messageSegmentCount = Math.ceil(charCount/160);

        if (body === "" && !imageFile) {
            setCharacterCount(0);
            setMessageSegmentCount(0);
            setCreditsNeeded(0);
        } else if (body === "" && imageFile) {
            const newSegmentCount = 2;
            setMessageSegmentCount(newSegmentCount);
            if (subscribers) {
                setCreditsNeeded(subscribers?.length * newSegmentCount);
            }
        } else if (body !== "" && !imageFile) {
            setCharacterCount(charCount);
            setMessageSegmentCount(messageSegmentCount);
            if (subscribers) {
                setCreditsNeeded(subscribers?.length * messageSegmentCount);
            }
        } else {
            const newSegmentCount = messageSegmentCount + 2;
            setCharacterCount(charCount);
            setMessageSegmentCount(newSegmentCount);
            if (subscribers) {
                setCreditsNeeded(subscribers?.length * newSegmentCount);
            }
        }
        setIsCountingCredits(false);
    }

    const handleOptionChange = (value: string) => {
        setIsGeneral(value === "general");
        setIsFiltered(value === "filtered");
        setIsCustom(value === "custom");

        if (value === "custom") {
            // If custom, start with no selected auditors
            setSelectedEventAuditors([]);
            calculateValues(undefined, []);
        } else {
            // otherwise, set Auditors to "all"
            setSelectedEventAuditors(eventAuditors);
            calculateValues(undefined, eventAuditors);
        }
    }

    const handleRoleFilterSelect = async (formattedOptions: FormattedRoleOption[]) => {
        setIsCountingCredits(true);
        setFormattedRoleOptions(formattedOptions);
        if (eventAuditors) {
            const queryResult = await getEventAuditorsByFilters(eventAuditors, formattedOptions, (formattedFilters || undefined));
            if (queryResult.isSuccess) {
                setSelectedEventAuditors(queryResult.result);
                calculateValues(undefined, queryResult.result);
            }
        }
        else {
            setError("Could not find any subscribers to filter. Try reloading the page.");
        }
        setIsDisabled(false);
        setIsCountingCredits(false);
    }

    const handleFilterSelect = async (formattedFilters: FormattedFilter[]) => {
        setIsCountingCredits(true);
        setFormattedFilters(formattedFilters);
        if (eventAuditors && formattedRoleOptions) {
            const queryResult = await getEventAuditorsByFilters(eventAuditors, formattedRoleOptions, formattedFilters);
            if (queryResult.isSuccess) {
                setSelectedEventAuditors(queryResult.result);
                calculateValues(undefined, queryResult.result);
            }
        }
        else {
            setError("Could not find any subscribers to filter. Try reloading the page.");
        }
        setIsDisabled(false);
        setIsCountingCredits(false);
    }

    const handleSelectCustomEventAuditors = (selected: EventAuditor[]) => {
        setSelectedEventAuditors(selected);
        calculateValues(undefined, selected);
    }

    const handleMessageBodyChange = (value: string) => {
        setMessageBody(value);
        calculateValues(value);
    }

    const handleAddTimeStamp = () => {
        const updatedMessage = messageBody + "\n\n" + "Sent: " + moment(new Date()).format("h:mma") + " by RingSide Pro";
        setMessageBody(updatedMessage);
        calculateValues(updatedMessage);
    }

    const getImageKey = (image: File) => {
        if (image.type === "application/pdf") {
            return createMMSPDFFileName(event || organization);
        } else {
            return createMMSPictureFileName(event || organization, image);
        }
    }

    const getImageURL = (imageKey: string) => {
        return "https://" + awsmobile.aws_user_files_s3_bucket + ".s3.amazonaws.com/public/" + imageKey;
    }

    const uploadImageToS3 = async (imageKey: string) => {
        let imageUploadResult: Result | undefined = await handleUploadS3Image(imageKey, image);
        if (imageUploadResult && imageUploadResult.isSuccess) {
            const key = imageUploadResult.result;
            if (key) {
                return key;
            }
        } else {
            return null;
        }
    }

    /**
     * Handle the addition or removal of a file to the message
     * 
     * From the Twilio docs:
     * If your message (including body text and media) is larger than 5 MB, your message request will fail. 
     * 
     * Note from a conversation with the support team at Twilio on 04-19-2023
     * "Our carrier partner has mentioned that the media files will be accepted with sizes up to 1 MB.
     * If the media has more than 1MB and less then 3MB, then it would be resized down to 1MB.
     * If it is not possible to resize it down to 1MB, then the media message will be dropped. 
     * Please ensure the media messages which you are sending are lesser than 3MB, ideally under 1MB.
     * 
     * https://support.twilio.com/hc/en-us/articles/360018832773-Twilio-Programmable-SMS-Supported-File-Types-and-Size-Limits-for-MMS-Media-Messages
     * "We recommend using attachments no larger than 600KB"
     * @param file 
     */
    const handleImageChange = async (file: File | null | undefined) => {
        setImageMessage("");
        setError("");
        if (file) {
            const fileSizeInBytes = file.size;
            if (fileSizeInBytes >= 3000000) {
                setError("Sorry, this file is too large to send. Please use a file smaller than 3MB.");
            } else {
                setImage(file);

                const fileSizeString = formatBytes(fileSizeInBytes);

                if (fileSizeInBytes >= 1000000) {
                    setImageMessage(`WARNING: this file may not be received by all users. Some carriers do not allow files of this size: ${fileSizeString}. We recommend trying a smaller size file (taking a screenshot or using jpg may make the file size smaller.)`);
                } else {
                    setImageMessage(`File size: ${fileSizeString}`);
                }

                // Allow an update to the message body if an image is uploaded
                let updatedMessageBody = messageBody;
                // Get the key and url
                const imageKey = getImageKey(file);
                if (imageKey) {
                    const imageURL = getImageURL(imageKey);
                    // Update the message body to include to new URL - user can choose to remove it 
                    updatedMessageBody = messageBody + (messageBody.length > 0 ? "\n" : "") + "(View: " + imageURL + ")"; 
                }
                setMessageBody(updatedMessageBody);
                calculateValues(updatedMessageBody, eventAuditors, file);
            }
        } else {
            setImage(undefined);
            let updatedMessageBody = messageBody;
            if (messageBody.includes("(View: ")) {
                const splitMessageBody = updatedMessageBody.split("(View: ");
                updatedMessageBody = splitMessageBody[0];
                setMessageBody(updatedMessageBody);
            }
            calculateValues(updatedMessageBody, eventAuditors);
        }
    }

    function formatBytes(bytes: number, decimals = 2) {
        if (!+bytes) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    }

    const sendAlertToAuditors = async () => {        
        // Confirm there are enough credits to send the alert
        if (!isUnlimitedCredits && totalCreditCount < creditsNeeded) {
            setError("You do not have enough credits to send this message.");
            return false;
        }

        // Track the credit blocks remaining credits
        let blockArray = formattedBlocks;
        let blockIdArray: string[] = [];
        blockArray?.forEach((block: FormattedBlock) => {
            blockIdArray.push(block.id);
        });

        // Handle image if it is included
        let imageURL = "";
        let imageKey = "";
        try {
            if (image) {
                imageKey = await getImageKey(image);
                await uploadImageToS3(imageKey);
                imageURL = getImageURL(imageKey);
            }
        } catch (error) {
            setError("Sorry - we could not add the file to the message. Please refresh the page and try again.");
            setIsLoading(false);
            return;
        }

        let updatedMessageBody = messageBody;
        if (imageURL) {
            updatedMessageBody = messageBody + " (View: " + imageURL + ")";
        }

        // Create the alert
        const alertInput: CreateAlertInput = {
            body: updatedMessageBody,
            imageKey: imageKey,
            eventId: event?.id || "",
            organizationId: event?.organizationId || "",
            alertEventId: event?.id || "",
            messageSegments: messageSegmentCount,
            subscriberCount: selectedEventAuditors?.length || 0,
            createdBy: user.id
        };
        const createAlertResult = await createAlert(alertInput);
        if (createAlertResult.isSuccess) {
            const alert: Alert = createAlertResult.result;

            // Remove the assumed number of credits. If any texts fail to send - will be handled within the Lambda Function
            setTotalCreditCount(totalCreditCount - creditsNeeded);

            if (selectedEventAuditors) {
                let phoneNumberList: string[] = [];
                selectedEventAuditors.forEach(eventAuditor => {
                    if (eventAuditor.phoneNumber) phoneNumberList.push(eventAuditor.phoneNumber);
                });

                // Mass send the data to Lambda Function - Handles it on the server
                // If Coveside Stables (Canada) use the toll free number
                if (organization?.id === constants.Coveside_ORGANIZATION.id || event?.id === constants.European_Classic_Event_Id) handleSendCanadianMassSMS(alert.id, phoneNumberList, blockIdArray, isUnlimitedCredits, updatedMessageBody, imageURL); 
                else handleSendMassSMS(alert.id, phoneNumberList, blockIdArray, isUnlimitedCredits, updatedMessageBody, imageURL);
            }    

            setSuccess("Successfully created the text alert!");  
            setMessageBody("");
            setImage(undefined);
            calculateValues("");     
        } else {
            const errorMessage = "Sorry, we could not create this message. " + createAlertResult.message;
            setError(errorMessage);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        await sendAlertToAuditors();
        setIsLoading(false);
        setShowModal(false);
    }

    return (
        <form>
            {error && <ErrorAlert width="12" error={error} />}
            {success && <SuccessBanner width="12" success={success} />}
            <IonRow>
                <IonCol className="ion-text-center">
                    <p className="description">Remember to refresh the page throughout the day to see new subscribers.</p>
                </IonCol>
            </IonRow>
            <IonRow className="pt-4 ion-justify-content-center">
                <IonCol sizeXs="12" sizeMd="4" className="ion-text-center">
                    <IonButton color={isGeneral ? "primary" : "light"} onClick={() => handleOptionChange("general")}>All</IonButton>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="4" className="ion-text-center">
                    <IonButton color={isFiltered ? "primary" : "light"} onClick={() => handleOptionChange("filtered")}>Filter</IonButton>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="4" className="ion-text-center">
                    <IonButton color={isCustom ? "primary" : "light"} onClick={() => handleOptionChange("custom")}>Custom</IonButton>
                </IonCol>
            </IonRow>
            <hr/>
            {isCustom && (
                <>
                    {eventAuditors && (
                        <>
                            <SelectEventAuditors eventAuditors={eventAuditors} onSelect={handleSelectCustomEventAuditors} />
                            <hr/>
                        </>
                    )}
                </>
            )}
            {isFiltered && (
                <>
                    {eventAuditors && (
                        <>
                            <InformationBanner info={"If a subscriber has selected at least 1 filter that matches your selections, they will get the alert."} />
                            <SelectEventFilterRolesOptionForm startWithoutSelections={false} isCollapsable={true} isExpandedOnStart={false} onSelect={handleRoleFilterSelect} />
                            <SelectEventAlertFiltersForm event={event} organization={organization} startWithoutSelections={true} isExpandedOnStart={true} onSelect={handleFilterSelect} />
                            <hr/>
                        </>
                    )}
                </>
            )}
            {isCountingCreditsNeeded ?
                <Spinner />
                :
                <IonRow className="ion-justify-content-center">
                    <IonCol size="10" className="ion-text-center">
                        <p className="description text-primary">Credits you will use: {creditsNeeded.toLocaleString()}</p>
                    </IonCol>
                </IonRow>
            }
            {isFiltered && (
                <IonRow className="ion-justify-content-center">
                    <IonCol size="10" className="ion-text-center">
                        <p className="description link" onClick={() => setShowModal(true)}>View who will receive the alert.</p>
                    </IonCol>
                </IonRow>
            )}
            {isUnlimitedCredits ?
                <IonRow className="ion-justify-content-center">
                    <IonCol size="10" className="ion-text-center">
                        <p className="description text-primary">Event has unlimited credits</p>
                    </IonCol>
                </IonRow>
                :
                <>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="10" className="ion-text-center">
                            <p className="description text-primary">Credits you have left: {totalCreditCount.toLocaleString()}</p>
                        </IonCol>
                    </IonRow>
                    {((eventAuditors?.length ? eventAuditors?.length > 0 : "") && (totalCreditCount < (eventAuditors?.length ? (eventAuditors?.length + eventAuditors?.length*.5) : 0))) && (
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="10" className="ion-text-center">
                                <p className="description text-danger">Running low on credits: <span className="link" onClick={() => setShowCreditModal(!showCreditsModal)}>Add Credits</span></p>
                            </IonCol>
                        </IonRow>
                    )}
                </>
            }
            <hr/>
            <IonRow>
                <IonCol>
                    <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="body"
                        >
                            Message 
                            <Badge color="info" data-placement="top" id="charCountExplanation">160 characters</Badge>
                        </label>
                        <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target="charCountExplanation"
                        >
                            An alert has 160 characters or less. If you use more, the system will count it as multiple alerts and will increase credits you are using. Emojis affect how the characters are counted.
                        </UncontrolledTooltip>
                        <Input
                            id="body"
                            placeholder="..."
                            rows="4"
                            type="textarea"
                            name="body"
                            className={characterCount > 160 ? "is-invalid " : ""}
                            value={messageBody}
                            onChange={(event) => handleMessageBodyChange(event.target.value)}
                        />
                        <IonRow>
                            <IonCol>
                                <Badge color="light" onClick={handleAddTimeStamp}>Add Time Stamp</Badge>
                            </IonCol>
                            <IonCol>
                                <p className="text-right">{characterCount}/160</p>
                            </IonCol>
                        </IonRow>
                        { characterCount > 160 ?
                            <>
                                {((eventAuditors?.length ? eventAuditors?.length > 0 : "") && (event && !event.eventOptions?.hasUnlimitedCredits) && (totalCreditCount < (eventAuditors?.length ? (eventAuditors?.length + eventAuditors?.length*.5) : 0))) ?
                                    <p className="description text-center text-danger">You do not have enough credits to send this alert. You can either delete some of the characters or purchase more credits.</p>
                                    :
                                    <p className="description text-center">You can send this alert and users will see it as one text message, but it will count as {Math.ceil(characterCount/160)} alerts in our system.</p>
                                }
                            </>
                            :
                            <></>
                        }
                    </FormGroup>
                </IonCol>
            </IonRow>
            <AlertImageForm selectedImage={image} onSelect={handleImageChange}/>
            {imageMessage && (
                <p className="description text-center text-warning">{imageMessage}</p>
            )}
            {error && <ErrorAlert width="12" error={error} />}
            {success && <SuccessBanner width="12" success={success} />}
            <IonRow className="ion-justify-content-center">
                <IonCol sizeMd="12">
                    {isLoading ?
                        <IonRow className="ion-justify-content-center">
                            <IonCol className="ion-text-center">
                                <p>Sending alerts, do not refresh page</p>
                                <Spinner />
                            </IonCol>
                        </IonRow>
                        :
                        <IonButton
                            disabled={creditsNeeded === 0}
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={handleSubmit}
                        >
                            {creditsNeeded === 0 ? "Type Message" : "Send"}
                        </IonButton>
                    }
                </IonCol>
            </IonRow>
            <IonModal backdropDismiss={false} isOpen={showModal} id="viewSubscribersModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Subscribers to Receive Alert
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeSubscriberModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading ?
                        <Spinner />
                        :
                        <>
                            <InformationBanner info="Based on the current filters you've selected, these are the subscribers that will get the alert" />
                            {selectedEventAuditors ?
                                <IonList className="bg-white">
                                    {selectedEventAuditors.map((eventAuditor: EventAuditor, index: number) => (
                                        <IonItem key={index}>
                                            <IonLabel>
                                                <IonRow>
                                                    <IonCol size="1">
                                                        <p>{index+1}</p>
                                                    </IonCol>
                                                    <IonCol size="6" className="ion-text-wrap">
                                                        <p>{eventAuditor.name}</p>
                                                    </IonCol>
                                                    <IonCol size="5" className="ion-text-wrap">
                                                        <p>{eventAuditor.phoneNumber}</p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonLabel>
                                        </IonItem>
                                    ))}
                                </IonList>
                                :
                                <p>There are no selected subscribers.</p>
                            }
                        </>
                    }
                </IonContent>
            </IonModal>
            <IonModal backdropDismiss={false} isOpen={showCreditsModal} id="addCreditsModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Add Credits
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowCreditModal(false)}
                        >
                            <p id="closeCreditsModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading ?
                        <Spinner />
                        :
                        <>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size="8">
                                   {(event && event.organizationId) ? 
                                        <BuyCreditsForm organizationId={event.organizationId} />
                                        :
                                        <>
                                            {organization ? 
                                                <BuyCreditsForm organizationId={organization.id} />
                                                :
                                                <></>
                                            }
                                        </>
                                   }
                                </IonCol>
                            </IonRow>
                        </>
                    }
                </IonContent>
            </IonModal>
        </form>
    );
};

export default SendAlertForm;