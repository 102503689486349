import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCheckbox,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import {BeddingRequest, Event, EventClassEntry, EventEntry, EventEntryFee, EventFee, EventRulesAgreement, StablingRequest} from "../../../models";
import ErrorAlert from "../../../components/Errors/ErrorAlert";
import Header from "../../../components/Headers/Header";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { PersonContext } from "../../../context/PersonContext";
import { RouteComponentProps, useHistory } from "react-router";
import {getEventById, getEventsByOrganizationId} from "../../../utilities/events/Event";
import SelectEvent from "../../../components/Event/SelectEvent";
import { getEventEntriesByEventIdPersonId, getEventEntriesByPersonId } from "../../../utilities/eventEntry/EventEntry";
import { getEventClassEntriesByEventIdEntryId } from "../../../utilities/eventClassEntry/EventClassEntry";
import { getEventEntryFeesByEventIdByEntryId } from "../../../utilities/eventEntryFee/EventEntryFee";
import { getStablingRequestsByPersonIdEventId } from "../../../utilities/stablingRequest/StablingRequest";
import { getEventRulesAgreementsByEntryId } from "../../../utilities/eventRules/EventRulesAgreement";
import { addEventEntriesFromTemplates, EntryTemplate } from "../../../utilities/eventEntry/EventEntryTemplates";
import Spinner from "../../../components/Spinners/Spinner";
import { getEventFeesByEventId } from "../../../utilities/eventFee/EventFee";
import { checkEventFeeStartDate } from "../../../utilities/fee/CheckFeeStartDate";
import { sortEventsByDate } from "../../../utilities/events/SortEvents";
import { Table } from "reactstrap";
import { getBeddingRequestsByEntryId } from "../../../utilities/beddingRequest/BeddingRequest";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

let entryMap = new Map<string, EventEntry[]>();

const EventCreateEntriesFromTemplatePage: React.FC<EventPageProps> = ({match}) => {
    const user = useContext(PersonContext);
    const history = useHistory();

    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [eventOptions, setEventOptions] = useState<Event[] | undefined | null>();
    const [selectedEvent, setSelectedEvent] = useState<Event>();
    const [entryTemplates, setEntryTemplates] = useState<EntryTemplate[] | undefined | null>();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");

    async function getEvent() {
        if (match.params.id) {
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                const currentEvent: Event = queryResult.result;
                setCurrentEvent(currentEvent);
                return currentEvent;
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }
    }

    const getEventOptions = async (e?: Event) => {
        setError("");
        if (e && e.organizationId) {
            const eventsQueryResult = await getEventsByOrganizationId(e.organizationId);
            if (eventsQueryResult.isSuccess) {
                const previousEventsList: Event[] = eventsQueryResult.result;
                const sorted = sortEventsByDate(previousEventsList) || previousEventsList;
                setEventOptions(sorted);
            } else {
                setError("An error occurred while looking for previous events within this organization.");
            }
        } else {
            setError("Could not find any previous events within this organization.");
        }
    } 

    const getEventEntriesFromEvent = async (e: Event) => {
        setError("");
        // Check for the entry map and if this event has entries mapped to it.
        if (entryMap) {
            const entryMapResult = entryMap.get(e.id);
            if (!entryMapResult) {
                // If there are no entries loaded yet, try to find them
                const queryResult = await getEventEntriesByEventIdPersonId(e.id, user.id);
                if (queryResult.isSuccess) {
                    const eventEntries: EventEntry[] = queryResult.result;
                    for (let i = 0; i < eventEntries.length; i++) {
                        const entry = eventEntries[i];
                        // Add the entry to the list
                        const entryMapResult = entryMap.get(e.id);
                        if (entryMapResult) {
                            const updatedList: EventEntry[] = entryMapResult.concat(entry);
                            entryMap.set(e.id, updatedList);
                        } else {
                            entryMap.set(e.id, [entry]);
                        }
                    }
                }
            }
        } else {
            setError("Could not load the entry data.");
        }
    }

    const getEntriesFromEvent = async (event: Event) => {
        setError("");
        setIsLoading(true);
        setLoadingMessage("10% Complete");
        // Get the current event's fees so that they can be used to validate any template fees
        let currentEventFees: EventFee[] = [];
        const queryCurrentEventFees = await getEventFeesByEventId(event.id);
        if (queryCurrentEventFees.isSuccess) {
            currentEventFees = queryCurrentEventFees.result;
        }

        setLoadingMessage("20% Complete");

        // Get the current event's entry info and load it into the entryMap
        await getEventEntriesFromEvent(event);

        setLoadingMessage("30% Complete");

        // Format the entry template data
        if (entryMap) {
            const entryList = entryMap.get(event.id);
            let formattedList: EntryTemplate[] = [];
            
            if (entryList && entryList.length > 0) {
                for (let i = 0; i < entryList.length; i++) {
                    const currentEntry = entryList[i];

                    const percentageDone = (i+1)/(entryList.length);
                    const percentageOfSeventy = percentageDone*70;
                    const percentageToDisplay = (30 + percentageOfSeventy).toFixed(2);
                    const loadingMsg = percentageToDisplay + "% Complete";
                    setLoadingMessage(loadingMsg);
                    
                    let classList: EventClassEntry[] = [];
                    const classResults = await getEventClassEntriesByEventIdEntryId(event.id, currentEntry.id);
                    if (classResults.isSuccess) {
                        const classes: EventClassEntry[] = classResults.result;
                        classList = classes.sort((a: EventClassEntry, b: EventClassEntry) => (a.eventClass?.number || 0) - (b.eventClass?.number || 0));
                    }

                    let feeList: EventEntryFee[] = [];
                    const feeResults = await getEventEntryFeesByEventIdByEntryId(event.id, currentEntry.id);
                    if (feeResults.isSuccess) {
                        const allEventEntryFeeList: EventEntryFee[] = feeResults.result;
                        for (let j = 0; j < allEventEntryFeeList.length; j++) {
                            const currentEventEntryFee = allEventEntryFeeList[j];

                            // Check if the current event has the same fee
                            const foundSameFee = currentEventFees.find(cef => cef.feeId === currentEventEntryFee.feeId || cef.name.toLowerCase() === currentEventEntryFee.name.toLowerCase());
                            
                            if (foundSameFee) {
                                // The fee from this old entry exists on the current horse show's fee list
                                const fee = foundSameFee.fee;

                                let includeFee = true;

                                if (fee) {
                                    // Check if this is fee has a date that it should start on
                                    includeFee = checkEventFeeStartDate(fee);
                                }
                                
                                if (includeFee) {
                                    feeList.push(currentEventEntryFee);
                                }

                            } else {
                                // The fee from this old entry does not exist on the current horse show's fee list
                                // Skip this fee and continue on
                            }
                        }
                    }

                    let stallList: StablingRequest[] = [];
                    const stallResults = await getStablingRequestsByPersonIdEventId(user.id, event.id);
                    if (stallResults.isSuccess) {
                        const allStalls = stallResults.result;
                        if (allStalls && allStalls.length > 0) {
                            for (let j = 0; j < allStalls.length; j++) {
                                const currentStallRequest: StablingRequest = allStalls[j];
                                if (currentStallRequest && currentStallRequest.entryIds?.includes(currentEntry.id)) {
                                    stallList.push(currentStallRequest);
                                }
                            }
                        }
                    }

                    let beddingList: BeddingRequest[] = [];
                    const beddingResults = await getBeddingRequestsByEntryId(currentEntry.id);
                    if (beddingResults.isSuccess) {
                        beddingList = beddingResults.result;
                    }

                    let ruleList: EventRulesAgreement[] = [];
                    const ruleResults = await getEventRulesAgreementsByEntryId(currentEntry.id);
                    if (ruleResults.isSuccess) {
                        ruleList = ruleResults.result;
                    }

                    let formatted: EntryTemplate = {
                        id: currentEntry.id,
                        entry: currentEntry,
                        isSelected: true,
                        classes: classList,
                        fees: feeList,
                        stabling: stallList,
                        bedding: beddingList,
                        ruleAgreements: ruleList
                    };

                    formattedList.push(formatted);
                }
            }
            setEntryTemplates(formattedList);
        } else {
            setError("Sorry, something went wrong.");
        }
        setIsLoading(false);
    }

    const getData = async () => {
        setIsLoading(true);
        const e = await getEvent();
        await getEventOptions(e);
        setIsLoading(false);
    }

    useEffect(() => {
        getData();
    }, [match.params.id]);

    const handleSelectedEvent = async (event?: Event) => {
        setSelectedEvent(event);
        if (event) await getEntriesFromEvent(event);
        else setEntryTemplates([]);
    }

    const onSelectAdd = () => {
        if (currentEvent) {
            const path = "/index/event/" + currentEvent.id + "/new-entry";
            history.push(path);
            window.location.reload();
        }
    }

    const handleOptionClick = (index: number) => {
        if (entryTemplates) {
            const selectedOption: EntryTemplate = entryTemplates[index];

            const updatedFormattedOption: EntryTemplate = {
                ...selectedOption,
                isSelected: !selectedOption.isSelected
            };

            const updatedList = [
                ...entryTemplates?.slice(0, index),
                updatedFormattedOption,
                ...entryTemplates.slice(index + 1)
            ];
            setEntryTemplates(updatedList);
        }
    }

    const handleSelectAll = (selectAll: boolean) => {
        if (entryTemplates) {
            let updatedList: EntryTemplate[] = [];
            for (let i = 0; i < entryTemplates.length; i++) {
                const template = entryTemplates[i];
                let updatedTemplate: EntryTemplate = {
                    ...template,
                    isSelected: selectAll
                };
                updatedList.push(updatedTemplate);
            }
            setEntryTemplates(updatedList);
        }
    }

    const handleAddNewEntries = async () => {
        setIsLoading(true);
        setLoadingMessage("");
        if (currentEvent && entryTemplates) {
            await addEventEntriesFromTemplates(currentEvent, entryTemplates, user);
            const path = "/index/event/entries/" + currentEvent.id;
            history.push(path);
        }
        setIsLoading(false);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={currentEvent ? (currentEvent.nickname ? currentEvent.nickname : currentEvent.name) : "Event"} />
                {currentEvent && (
                    <>
                        {error && <ErrorAlert width="12" error={error} />}
                        <IonRow id="eventName">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        Entries From Templates
                                    </IonCardTitle>
                                    {isLoading ?
                                        <>
                                            <Spinner />
                                            <p>{loadingMessage}</p>
                                        </>
                                        :
                                        <IonCardContent>
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol sizeXs="12" sizeMd="8" className="ion-text-center">
                                                    <p>First, select an event that you have previously entered.</p>
                                                    <p>Note: you can only access entries that were submitted by your user account.</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol sizeXs="12" sizeMd="8" className="ion-text-center">
                                                    {(eventOptions) ?
                                                        <SelectEvent selectedValue={selectedEvent?.id || null} events={eventOptions} onSelect={(event: Event) => handleSelectedEvent(event)}/>
                                                        :
                                                        <div>
                                                            <p>No previous event entries found. </p>
                                                            <IonButton color="success" onClick={() => onSelectAdd()}>Add New Entry</IonButton>
                                                        </div>
                                                    }
                                                </IonCol>
                                            </IonRow>
                                            {(eventOptions && selectedEvent) &&
                                                <>
                                                    <IonRow className="ion-justify-content-center">
                                                        <IonCol size="12" className="ion-text-center">
                                                            <IonButton color="success" onClick={handleAddNewEntries}>Add Selected Entries</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="ion-justify-content-center">
                                                        <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                                            <IonButton color="primary" size="small" onClick={() => handleSelectAll(true)}>Select All</IonButton>
                                                        </IonCol>
                                                        <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                                            <IonButton color="light" size="small" onClick={() => handleSelectAll(false)}>Deselect All</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="ion-justify-content-center">
                                                        <IonCol size="12" className="ion-text-center">
                                                            <p className="description ion-text-wrap text-info">After you add entries from templates, please review the new entries to ensure that they are correct.</p>
                                                            {(entryTemplates && entryTemplates.length > 0) ? 
                                                                <Table responsive>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Selected</th>
                                                                            <th>Entry Info</th>
                                                                            <th>Classes</th>
                                                                            <th>Stabling</th>
                                                                            <th>Fees</th>
                                                                            <th>Rules</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {entryTemplates.map((template, index) => (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <IonCheckbox checked={template.isSelected} color="primary" onClick={() => handleOptionClick(index)} />
                                                                                </td>
                                                                                <td>
                                                                                    <p className="ion-text-wrap">Back Number: {template.entry.number}</p>
                                                                                    <p className="ion-text-wrap">Horse: {template.entry.horse?.name || template.entry.horseName || ""}</p>
                                                                                    <p className="ion-text-wrap">Owner: {template.entry.owner?.name || template.entry.ownerName || ""}</p>
                                                                                    <p className="ion-text-wrap">Rider: {template.entry.rider?.name || template.entry.riderName || ""}</p>
                                                                                    {template.entry.secondRiderName && (
                                                                                        <p className="ion-text-wrap">Rider 2: {template.entry.secondRider?.name || template.entry.secondRiderName || ""}</p>
                                                                                    )}
                                                                                    <p className="ion-text-wrap">Trainer: {template.entry.trainer?.name || template.entry.trainerName || ""}</p>
                                                                                </td>
                                                                                <td>
                                                                                    {(template.classes && template.classes.length > 0) ? 
                                                                                        <>
                                                                                            {template.classes.map((classEntry, index) => (
                                                                                                <p key={index} className="ion-text-wrap">{classEntry.eventClass?.number}: {classEntry.eventClass?.name}</p>
                                                                                            ))}
                                                                                        </>
                                                                                        :
                                                                                        <p>No classes or divisions found.</p>
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {(template.stabling && template.stabling.length > 0) ? 
                                                                                        <>
                                                                                            {template.stabling.map((stablingRequest, index) => (
                                                                                                <p key={index} className="ion-text-wrap">{stablingRequest.stallType?.name} (x{stablingRequest.quantityNeeded || "0"})</p>
                                                                                            ))}
                                                                                        </>
                                                                                        :
                                                                                        <p>No stalls found.</p>
                                                                                    }
                                                                                    {(template.bedding && template.bedding.length > 0) ? 
                                                                                        <>
                                                                                            {template.bedding.map((beddingRequest, index) => (
                                                                                                <p key={index} className="ion-text-wrap">Bag (x{beddingRequest.quantityNeeded || "0"})</p>
                                                                                            ))}
                                                                                        </>
                                                                                        :
                                                                                        <p>No bedding found.</p>
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {(template.fees && template.fees.length > 0) ? 
                                                                                            <>
                                                                                                {template.fees.map((fee, index) => (
                                                                                                    <p key={index} className="ion-text-wrap">{fee.name} (${fee.amount.toFixed(2)} * {fee.quantity || 0} = ${(fee.amount * (fee.quantity || 0)).toFixed(2)})</p>
                                                                                                ))}
                                                                                            </>
                                                                                            :
                                                                                            <p>No event fees found.</p>
                                                                                        }
                                                                                </td>
                                                                                <td>
                                                                                    {(template.ruleAgreements && template.ruleAgreements.length > 0) ? 
                                                                                        <>
                                                                                            {template.ruleAgreements.map((ruleAgreement, index) => (
                                                                                                <p key={index} className="ion-text-wrap">{ruleAgreement.eventRule?.title}</p>
                                                                                            ))}
                                                                                        </>
                                                                                        :
                                                                                        <p>No rule agreements.</p>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </Table> 
                                                            :
                                                                <p>No entry templates were found.</p>
                                                            }
                                                        </IonCol>
                                                    </IonRow>
                                                </>
                                                
                                            }
                                        </IonCardContent>
                                    }
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default EventCreateEntriesFromTemplatePage;