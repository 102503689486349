import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import ErrorAlert from "../Errors/ErrorAlert";
import { Event, EventEntry, EventStallType, StablingRequest } from "../../models";
import Spinner from "../Spinners/Spinner";
import { IonCol, IonRow } from "@ionic/react";
import { getEventStallTypesByEventId } from "../../utilities/eventStallType/EventStallType";
import { getStablingRequestsByPersonIdEventId } from "../../utilities/stablingRequest/StablingRequest";
import StablingCard from "./StablingCard";
import ViewStablingRequestCard from "./EntryStabling/ViewStablingRequestCard";

interface _Props {
    event: Event
    entry?: EventEntry
    entries?: EventEntry[]
    onSubmit: Function
}

const EntryStablingForm: React.FC<_Props> = ({entry, entries, event, onSubmit}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");
    const [eventStallTypes, setEventStallTypes] = useState<EventStallType[] | null | undefined>();
    const [previousStablingRequests, setPreviousStablingRequests] = useState<StablingRequest[] | null | undefined>();

    const getEventStallTypes = async (event: Event) => {
        const queryResult = await getEventStallTypesByEventId(event.id);
        if (queryResult.isSuccess) {
            setEventStallTypes(queryResult.result);
        }
    }

    async function getPreviousRequests() {
        const queryResult = await getStablingRequestsByPersonIdEventId(user.id, event.id);
        if (queryResult.isSuccess) {
            setPreviousStablingRequests(queryResult.result);
        }
    }

    useEffect(() => {    
        getPreviousRequests();
    }, [user, user.id]);

    useEffect(() => {
        if (event) {
            getEventStallTypes(event);
        }
    }, [event]);

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {isLoading ?
                <Spinner/>
                :
                <>
                    {eventStallTypes ?
                        <>
                            {/* {(previousStablingRequests && previousStablingRequests?.length > 0) && 
                                <>
                                    {previousStablingRequests?.map((sr: StablingRequest, index: number) => (
                                        <div key={index}>
                                            <ViewStablingRequestCard event={event} stablingRequest={sr} onSubmit={() => getPreviousRequests()} />
                                        </div>
                                    ))}
                                </>
                            } */}
                            <StablingCard event={event} entry={entry} onSubmit={() => getPreviousRequests()} />
                        </>
                        :
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12" className="ion-text-center">
                                <p>This event does not offer stabling.</p>
                            </IonCol>
                        </IonRow>
                    }
                </> 
            }
        </>
    );
};

export default EntryStablingForm;