import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateBacklogEventResultInput, CreateBacklogEventResultMutation, DeleteBacklogEventResultInput, DeleteBacklogEventResultMutation, GetBacklogEventResultQuery, ListBacklogEventResultsQuery, UpdateBacklogEventResultInput, UpdateBacklogEventResultMutation } from "../../API";
import moment from "moment";
import { sortBacklogEventResults } from "./SortBacklogEventResult";
import { BacklogEventResult } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventResult. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventResult.
*/
export async function createBacklogEventResult(input: CreateBacklogEventResultInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "BacklogEventResult", "No input", "Create BacklogEventResult received no input.");
   try {
        const fullInput: CreateBacklogEventResultInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createBacklogEventResult, { input: fullInput }))) as GraphQLResult<CreateBacklogEventResultMutation>;
       else result = (await API.graphql({
           query: mutations.createBacklogEventResult,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateBacklogEventResultMutation>;
       const eventResult = result.data?.createBacklogEventResult;
       return formatResult(true, "BacklogEventResult", eventResult, "Successfully created the eventResult.");
   } catch (error: any) {
       return formatResult(false, "BacklogEventResult", error, "Error creating record in the database for type: eventResult");
   }
}

/**
* Handle updating a new record in the database for type: eventResult. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventResult.
*/
export async function updateBacklogEventResult(input: UpdateBacklogEventResultInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "BacklogEventResult", "No input", "Update BacklogEventResult received no input.");
   try {
        const fullInput: UpdateBacklogEventResultInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateBacklogEventResult, { input: fullInput }))) as GraphQLResult<UpdateBacklogEventResultMutation>;
       else result = (await API.graphql({
           query: mutations.updateBacklogEventResult,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateBacklogEventResultMutation>;
       const eventResult = result.data?.updateBacklogEventResult;
       return formatResult(true, "BacklogEventResult", eventResult, "Successfully updated the eventResult.");
   } catch (error: any) {
       return formatResult(false, "BacklogEventResult", error, "Error updating record in the database for type: eventResult");
   }
}

/**
* Handle deleting a new record in the database for type: eventResult. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventResult.
*/
export async function deleteBacklogEventResult(input: DeleteBacklogEventResultInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "BacklogEventResult", "No input", "Delete BacklogEventResult received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteBacklogEventResult, { input: input }))) as GraphQLResult<DeleteBacklogEventResultMutation>;
       else result = (await API.graphql({
           query: mutations.deleteBacklogEventResult,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteBacklogEventResultMutation>;
       const eventResult = result.data?.deleteBacklogEventResult;
       return formatResult(true, "BacklogEventResult", eventResult, "Successfully deleted the eventResult.");
   } catch (error: any) {
       return formatResult(false, "BacklogEventResult", error, "Error deleting record in the database for type: eventResult");
   }
}

/**
* Get all records in the database for type: eventResult. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventResult object
*/
export async function getAllBacklogEventResults(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listBacklogEventResults,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListBacklogEventResultsQuery>;

        let items = result.data?.listBacklogEventResults?.items as BacklogEventResult[];
        let nextToken = result.data?.listBacklogEventResults?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listBacklogEventResults,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListBacklogEventResultsQuery>;

            const nextItems = nextResult.data?.listBacklogEventResults?.items as BacklogEventResult[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listBacklogEventResults?.nextToken;
        }

        return formatResult(true, "BacklogEventResult", items, "Successfully got the eventResults.");
    } catch (error: any) {
        return formatResult(false, "BacklogEventResult", error, "Error reading record in the database for type: eventResults");
    }
}

/**
* Read a specific record in the database for type: eventResult. 
* 
* @param {string}  id                  The eventResult id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventResult object
*/
export async function getBacklogEventResultById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getBacklogEventResult,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetBacklogEventResultQuery>;
       const eventResult = result.data?.getBacklogEventResult;
       return formatResult(true, "BacklogEventResult", eventResult, "Successfully got the eventResult.");
   } catch (error: any) {
       return formatResult(false, "BacklogEventResult", error, "Error reading record in the database for type: eventResult");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventResult. 
* 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventResult object
*/
export async function getBacklogEventResultsByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: queries.listBacklogEventResults,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListBacklogEventResultsQuery>;

        let items = result.data?.listBacklogEventResults?.items as BacklogEventResult[];
        let nextToken = result.data?.listBacklogEventResults?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listBacklogEventResults,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListBacklogEventResultsQuery>;

            const nextItems = nextResult.data?.listBacklogEventResults?.items as BacklogEventResult[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listBacklogEventResults?.nextToken;
        }

        return formatResult(true, "BacklogEventResult", items, "Successfully got the eventResults.");
    } catch (error: any) {
        return formatResult(false, "BacklogEventResult", error, "Error reading record in the database for type: eventResults");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventResult. 
* 
* @param {string}  eventClassId        The class id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventResult object
*/
export async function getBacklogEventResultsByEventClassId(eventClassId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventClassResultsByEventClassId,
            variables: {
                limit: 100,
                eventClassId: eventClassId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventClassResultsByEventClassId?.items as BacklogEventResult[];
        let nextToken = result.data?.eventClassResultsByEventClassId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventClassResultsByEventClassId,
                variables: {
                    limit: 100,
                    eventClassId: eventClassId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventClassResultsByEventClassId?.items as BacklogEventResult[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventClassResultsByEventClassId?.nextToken;
        }

        return formatResult(true, "BacklogEventResult", items, "Successfully got the eventResults.");
    } catch (error: any) {
        return formatResult(false, "BacklogEventResult", error, "Error reading record in the database for type: eventResults");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventResult. 
* 
* @param {string}  eventId             The event id 
* @param {string}  classId             The class id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventResult object
*/
export async function getBacklogEventResultByEventIdClassIdEntryId(eventId: string, classId: string, entryId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}, eventClassId: {eq: classId}, entryId: {eq: entryId}};
        const result = (await API.graphql({
            query: queries.listBacklogEventResults,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListBacklogEventResultsQuery>;

        let items = result.data?.listBacklogEventResults?.items as BacklogEventResult[];
        let nextToken = result.data?.listBacklogEventResults?.nextToken;

        while (nextToken && (!items || (items && items.length === 0))) {
            const nextResult = await API.graphql({
                query: queries.listBacklogEventResults,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListBacklogEventResultsQuery>;

            const nextItems = nextResult.data?.listBacklogEventResults?.items as BacklogEventResult[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listBacklogEventResults?.nextToken;
        }

        const eventResults = items;
        if (eventResults && eventResults[0]) return formatResult(true, "BacklogEventResult", eventResults[0], "Successfully got the eventResult.");
        else return formatResult(false, "BacklogEventResult", null, "Could not find the eventResult.");
    } catch (error: any) {
        return formatResult(false, "BacklogEventResult", error, "Error reading record in the database for type: eventResults");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventResult. 
* 
* @param {string}  divisionId          The event division id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventResult object
*/
export async function getBacklogEventResultsByEventDivisionId(divisionId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventDivisionResultsByEventDivisionId,
            variables: {
                limit: 100,
                eventDivisionId: divisionId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventDivisionResultsByEventDivisionId?.items as BacklogEventResult[];
        let nextToken = result.data?.eventDivisionResultsByEventDivisionId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventDivisionResultsByEventDivisionId,
                variables: {
                    limit: 100,
                    eventDivisionId: divisionId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventDivisionResultsByEventDivisionId?.items as BacklogEventResult[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventDivisionResultsByEventDivisionId?.nextToken;
        }

        return formatResult(true, "BacklogEventResult", items, "Successfully got the eventResults.");
    } catch (error: any) {
        return formatResult(false, "BacklogEventResult", error, "Error reading record in the database for type: eventResults");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventResult. 
* 
* @param {string}  eventId             The event id 
* @param {string}  entryId             The entry id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventResult object
*/
export async function getBacklogEventResultsByEventIdEntryId(eventId: string, entryId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}, entryId: {eq: entryId}};
        const result = (await API.graphql({
            query: queries.listBacklogEventResults,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListBacklogEventResultsQuery>;
        
        let items = result.data?.listBacklogEventResults?.items as BacklogEventResult[];
        let nextToken = result.data?.listBacklogEventResults?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listBacklogEventResults,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListBacklogEventResultsQuery>;

            const nextItems = nextResult.data?.listBacklogEventResults?.items as BacklogEventResult[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listBacklogEventResults?.nextToken;
        }

        const eventResults = items;
        const sorted = sortBacklogEventResults(eventResults);
        if (sorted) {
            return formatResult(true, "BacklogEventResult", sorted, "Successfully got the eventResults.");
        } else {
            return formatResult(true, "BacklogEventResult", eventResults, "Successfully got the eventResults.");
        }
    } catch (error: any) {
        return formatResult(false, "BacklogEventResult", error, "Error reading record in the database for type: eventResults");
    }
}