import {Auth} from "aws-amplify";
import {
    IonAlert,
    IonButton,
    IonCol,
    IonRow,
    IonTitle,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { formatRolesStringToStringArray, formatRolesStringForUserAttribute, formatRolesToStringArray } from "../../utilities/roles/FormatRoles";
import Spinner from "../Spinners/Spinner";
import { PersonContext } from "../../context/PersonContext";
import SelectAllButtons from "../Select/SelectAllButtons";
import { UserAttributes } from "../../utilities/user/User";
import { createRoleRecords } from "../../utilities/roles/CreateRoleRecords";
import { getPersonByPersonId } from "../../utilities/person/Person";
import { updatePerson } from "../../utilities/person/Person";

const initialRoles: string[] = [];

const RolesForm: React.FC = () => {
    const user = useContext(PersonContext);

    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isRemovingRole, setIsRemovingRole] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [rolesToSelectFrom, setRolesToSelectFrom] = useState(initialRoles);
    const [selectedRoles, setSelectedRoles] = useState(initialRoles);

    useEffect(() => {
        async function getPersonRoles() {
            const roles = user.roles;
            if (roles) {
                const roleArray = formatRolesStringToStringArray(roles);
                if (roleArray) await setSelectedRoles(roleArray);
            }
        }

        setRolesToSelectFrom(formatRolesToStringArray());
        getPersonRoles();

    }, [user.roles]);
    
    const handleSelectRoles = (newSelectedRoles: string[]) => {
        if (newSelectedRoles.length < selectedRoles.length) setIsRemovingRole(true);
        else setIsRemovingRole(false);
        setSelectedRoles(newSelectedRoles);
        setIsDisabled(false);
    };

    const updateUser = async () => {
        await updateUserAttributes();
        await updatePersonRoles();
        await handleCreateRoleRecords();
    };

    const updateUserAttributes = async () => {
        const attributes: UserAttributes = {};
        if (selectedRoles && selectedRoles.length) attributes["custom:roles"] = formatRolesStringForUserAttribute(selectedRoles);
        Auth.currentAuthenticatedUser()
            .then(async (user) => {
                await Auth.updateUserAttributes(user, attributes);
            });
    };

    const updatePersonRoles = async () => {
        try {
            const queryResult = await getPersonByPersonId(user.id);
            if (queryResult.isSuccess) {
                const person = queryResult.result;
                if (person) {
                    await updatePerson({
                        id: user.id,
                        roles: formatRolesStringForUserAttribute(selectedRoles)
                    });
                }
            }
        } catch (error: any) {
            console.error("Could not update person roles: ", error);
        }
    };

    const handleCreateRoleRecords = async () => {
        await createRoleRecords(user, selectedRoles);
    }

    const handleSubmit = async () => {
        setShowAlert(true);
    };

    const handleSave = async () => {
        setShowAlert(false);
        setIsLoading(true);
        await updateUser();
        setIsLoading(false);
        setIsDisabled(true);
    };

    return (
        <form>
            <IonRow>
                <IonTitle className="ion-padding-top ion-text-center">
                    <h5>I am a:</h5>
                </IonTitle>
            </IonRow>
            <SelectAllButtons buttons={rolesToSelectFrom} selectedButtons={selectedRoles} onSelect={handleSelectRoles}/>
            <IonRow>
                <IonTitle className="ion-padding-top ion-text-center">
                    <p className="description">*select all that apply</p>
                </IonTitle>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="4">
                    {isLoading ?
                        <Spinner />
                        :
                        <IonButton
                            disabled={isDisabled}
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={isRemovingRole ? handleSubmit : handleSave}
                        >
                            {isDisabled ? "Saved" : "Save"}
                        </IonButton>
                    }
                </IonCol>
            </IonRow>
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={"Alert!"}
                subHeader={"This cannot be undone."}
                message={"You are about to remove a role from your account and all information linked to it. You will not be able to retrieve the data related to this role once its removed."}
                buttons={[
                    {
                        text: "Cancel",
                        role: "cancel",
                        cssClass: "secondary"
                    },
                    {
                        text: "Continue",
                        cssClass: "danger",
                        handler: () => {
                            handleSave();
                        }
                    }
                ]}
            />
        </form>
    );
};

export default RolesForm;