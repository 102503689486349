import { IonButton, IonCheckbox, IonCol, IonItem, IonLabel, IonList, IonRow, IonSearchbar } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { CreateEventClassEntryInput, UpdateEventEntryInput } from "../../API";
import { PersonContext } from "../../context/PersonContext";
import { EventClass, EventClassEntry, EventDivision, EventEntry } from "../../models";
import { getEventClassesByEventId } from "../../utilities/eventClass/EventClass";
import { getEventClassTypeAbbreviation } from "../../utilities/eventClass/EventClassTypes";
import { createEventClassEntry, deleteEventClassEntry, getEventClassEntriesByEventIdEntryId, getEventClassEntriesWithEventClassByEventEntryId } from "../../utilities/eventClassEntry/EventClassEntry";
import { getEventDivisionById } from "../../utilities/eventDivision/EventDivision";
import { updateEventEntry } from "../../utilities/eventEntry/EventEntry";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";

interface formattedOption {
    isExpanded?: boolean
    isChecked: boolean
    isDisabled: boolean
    isDivisionEntryOnly: boolean
    isClassEntryOnly: boolean
    entryFee: string
    name: string
    number?: string
    classType?: string
    note?: string
    // label: string
    value: string
    object: any
}

export interface formattedOptionGroup {
    division?: formattedOption
    classes?: formattedOption[]
    class?: formattedOption
}

interface _Props {
    eventId: string
    entry: EventEntry
    isQuickAdd?: Boolean
    onUpdateEntry?: Function
    onSelect: Function
}

const EventClassCheckList: React.FC<_Props> = ({eventId, entry, isQuickAdd, onUpdateEntry, onSelect}) => {
    const user = useContext(PersonContext);

    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();
    const [previousEventClassEntries, setPreviousEventClassEntries] = useState<EventClassEntry[] | null | undefined>();
    const [selectedEventClasses, setSelectedEventClasses] = useState<EventClass[] | null | undefined>();
    const [selectedDivisions, setSelectedDivisions] = useState<EventDivision[] | null | undefined>();
    const [formattedOptionGroups, setFormattedOptionGroups] = useState<formattedOptionGroup[] | null | undefined>();
    const [filteredOptionGroups, setFilteredOptionGroups] = useState<formattedOptionGroup[] | null | undefined>();
    const [searchNumberText, setSearchNumberText] = useState("");
    const [searchNameText, setSearchNameText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const handleSearchInput = (input: string, type: string) => {
        // If search input is empty, reset to all accepted entries
        if (!input || input === "") {
            setFilteredOptionGroups(formattedOptionGroups);
        }

        // Try search by entry number
        else if (type === "number") {
            let result: formattedOptionGroup[] = []; 
            if (formattedOptionGroups) {
                for (let i = 0; i < formattedOptionGroups.length; i++) {
                    const current = formattedOptionGroups[i];
                    if (current.class && current.class.object && current.class.object.number) {
                        if (current.class.object.number.toString().includes(input)) {
                            result.push(current);
                        }
                    } else if (current.classes) {
                        for (let j = 0; j < current.classes.length; j++) {
                            const currentClass = current.classes[j];
                            if (currentClass && currentClass.object && currentClass.object.number) {
                                if (currentClass.object.number.toString().includes(input)) {
                                    result.push(current);
                                }
                            }
                        }
                    }
                }
                setFilteredOptionGroups(result);
            }
        }

        // Try search by class name
        else if (type === "name") {
            let result: formattedOptionGroup[] = [];
            if (formattedOptionGroups) {
                for (let i = 0; i < formattedOptionGroups.length; i++) {
                    const current = formattedOptionGroups[i];
                    if (current.division && current.division.name.toLowerCase().includes(input.toLowerCase())) result.push(current);
                    if (current.class && current.class.name.toLowerCase().includes(input.toLowerCase())) result.push(current);
                }
                setFilteredOptionGroups(result);
            }
        }
    }

    const findClassInList = (eventClass: EventClass, eventClassList?: EventClass[]) => {
        let result = false;
        if (eventClassList) {
            for (var i = 0; i < eventClassList.length; i++) {
                const currentEventClass = eventClassList[i];
                if (currentEventClass && currentEventClass.id) {
                    if (eventClass.id === eventClassList[i].id) {
                        result = true;
                    }
                } 
            }
        }
        return result;
    }

    const formatEventClasses = async (eventClasses: EventClass[], selectedClassList?: EventClass[]) => {
        // Key = eventDivisionId OR eventClassId
        // Value = formattedOptionGroup
        let optionMap = new Map<string, formattedOptionGroup>();
        
        let formattedOptionGroups: formattedOptionGroup[] = [];
        const currentClassList = selectedClassList || selectedEventClasses;

        for (var i = 0; i < eventClasses.length; i++) {
            // Get the current class and it's label info
            const currentClass: EventClass = eventClasses[i];

            const classType = (currentClass.class && currentClass.class.type ? (getEventClassTypeAbbreviation(currentClass.class.type)) : "");
            const classLabel = currentClass.number + " - " + currentClass.name + " " + classType;
            
            // Check for any division info connected to this class
            let currentDivision: EventDivision | undefined = undefined;
            const currentDivisionId = currentClass.eventDivision?.id;
            if (currentDivisionId) {
                if (currentClass.eventDivision) {
                    currentDivision = currentClass.eventDivision;
                } else {
                    const queryResult = await getEventDivisionById(currentDivisionId);
                    if (queryResult.isSuccess) {
                        currentDivision = queryResult.result; 
                    }
                }
            }


            if (currentDivision) {
                // Format with Division on top and then classes listed beneath
                let canEnterByDivision = ((!!currentDivision.entryFee) && parseFloat(currentDivision.entryFee) > 0);
                let canEnterByClass = ((!!currentClass.entryFee) && parseFloat(currentClass.entryFee) > 0);

                const entryIsEnrolledInDivision = entry.divisionList?.includes(currentDivision.id);

                // First, check to see if this division is already in the OptionMap
                const result: (formattedOptionGroup | null | undefined) = optionMap.get(currentDivision.id);
                if (result) {
                    // Simply update the classes list in the option group
                    const currentClassArray = result.classes || [];
                    const formattedClass: formattedOption = {
                        isChecked: findClassInList(currentClass, currentClassList!),
                        isDisabled: (canEnterByDivision && !canEnterByClass) ? true : !!(entryIsEnrolledInDivision),
                        isDivisionEntryOnly: (canEnterByDivision && !canEnterByClass),
                        isClassEntryOnly: (!canEnterByDivision && canEnterByClass), 
                        entryFee: currentClass?.entryFee || "",
                        name: currentClass.name,
                        number: currentClass.number ? currentClass.number.toString() : "",
                        classType: classType,
                        note: "",
                        value: currentClass?.id,
                        object: currentClass
                    };
                    let updatedClassArray = currentClassArray.concat([formattedClass]);
                    const updatedFormattedOptionGroup : formattedOptionGroup = {
                        ...result,
                        classes: updatedClassArray
                    }
                    optionMap.set(currentDivision.id, updatedFormattedOptionGroup);
                } else {
                    // Need to format the division info and add it to the OptionMap
                    
                    const formattedDivision: formattedOption = {
                        isChecked: !!entryIsEnrolledInDivision,
                        isDisabled: false,
                        isDivisionEntryOnly: (canEnterByDivision && !canEnterByClass),
                        isClassEntryOnly: (!canEnterByDivision && canEnterByClass), 
                        entryFee: currentDivision?.entryFee || "",
                        name: currentDivision.name,
                        number: currentDivision.number ? currentDivision.number.toString() : "",
                        classType: "",
                        note: "(enter entire division or choose classes in this division)",
                        value: currentDivision?.id,
                        object: currentDivision
                    };
                    
                    const formattedClass: formattedOption = {
                        isChecked: findClassInList(currentClass, currentClassList!),
                        isDisabled: (canEnterByDivision && !canEnterByClass) ? true : !!(entryIsEnrolledInDivision),
                        isDivisionEntryOnly: (canEnterByDivision && !canEnterByClass),
                        isClassEntryOnly: (!canEnterByDivision && canEnterByClass), 
                        entryFee: currentClass?.entryFee || "",
                        name: currentClass.name,
                        number: currentClass.number ? currentClass.number.toString() : "",
                        classType: classType,
                        note: "",
                        value: currentClass?.id,
                        object: currentClass
                    };

                    const newFormattedOptionGroup: formattedOptionGroup = {
                        division: formattedDivision,
                        classes: [formattedClass]
                    };

                    optionMap.set(currentDivision.id, newFormattedOptionGroup);
                }
            } else {
                // There is no division for the class - just display the class
                const classType = (currentClass.class && currentClass.class.type ? (getEventClassTypeAbbreviation(currentClass.class.type)) : "");
                const classLabel = currentClass.number + " - " + currentClass.name + " " + classType;
                const formattedClass: formattedOption = {
                    isChecked: findClassInList(currentClass, currentClassList!),
                    isDisabled: false,
                    isDivisionEntryOnly: false,
                    isClassEntryOnly: true, 
                    entryFee: currentClass?.entryFee || "",
                    name: currentClass.name,
                    number: currentClass.number ? currentClass.number.toString() : "",
                    classType: classType,
                    note: "",
                    value: currentClass?.id,
                    object: currentClass
                };
                const newFormattedOptionGroup: formattedOptionGroup = {
                    class: formattedClass
                }; 
                optionMap.set(currentClass.id, newFormattedOptionGroup);
            }
        }

        optionMap.forEach(element => {
            formattedOptionGroups.push(element);
        });

        setFormattedOptionGroups(formattedOptionGroups);
        setFilteredOptionGroups(formattedOptionGroups);
    }

    const getEntryInfo = async (entry: EventEntry) => {
        const eventClassEntriesResult = await getEventClassEntriesWithEventClassByEventEntryId(entry.id);
        if (eventClassEntriesResult.isSuccess) {
            const eventClassEntries =  eventClassEntriesResult.result;
            if (eventClassEntries && eventClassEntries.length) {
                let entryClassList: EventClass[] = [];
                for (let i = 0; i < eventClassEntries.length; i++) {
                    const eventClassEntry: EventClassEntry = eventClassEntries[i];
                    if (eventClassEntry.eventClass) entryClassList.push(eventClassEntry.eventClass);
                }
                setSelectedEventClasses(entryClassList);             
                return entryClassList;
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    async function getData(eventId: string) {
        setIsLoading(true); 
        const queryResult = await getEventClassesByEventId(eventId);
        if (queryResult.isSuccess) {
            const eventClasses: EventClass[] = queryResult.result;
            let eventEntryClasses: EventClass[] = [];
            if (entry) {
                eventEntryClasses = await getEntryInfo(entry);
            }
            await formatEventClasses(eventClasses, eventEntryClasses);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }

    async function getPreviousEventClassEntries(eventId: string, entryId: string) {
        const eventEntriesResult = await getEventClassEntriesByEventIdEntryId(eventId, entryId);
        if (eventEntriesResult.isSuccess) {
            const eventClassEntriesList: EventClassEntry[] = eventEntriesResult.result;
            setPreviousEventClassEntries(eventClassEntriesList);
        }
    }

    useEffect(() => {
        if (eventId) {
            getData(eventId);
        }        
    }, [eventId]);

    useEffect(() => {
        setCurrentEntry(entry);  
        if (eventId && entry && entry.id) getPreviousEventClassEntries(eventId, entry.id);   
    }, [entry]);


    const handleOnChange = (index: number, type: string, option: formattedOptionGroup, classIndex?: number) => {
        setIsLoading(true);
        if (formattedOptionGroups && formattedOptionGroups.length) {
            let indexInFormattedOptionGroups = -1;

            let updatedOptionGroup: formattedOptionGroup = {};
            if (type === "division") {
                const oldFormattedOption = option.division;
                const divisionIsChecked = !oldFormattedOption?.isChecked;
                const newFormattedOption: formattedOption = {
                    isChecked: divisionIsChecked,
                    isDisabled: oldFormattedOption?.isDisabled || false,
                    isDivisionEntryOnly: oldFormattedOption?.isDivisionEntryOnly || false,
                    isClassEntryOnly: oldFormattedOption?.isClassEntryOnly || false, 
                    entryFee: oldFormattedOption?.entryFee || "",
                    name: oldFormattedOption?.name || "",
                    number: oldFormattedOption?.number ? oldFormattedOption?.number.toString() : "",
                    classType: oldFormattedOption?.classType,
                    note: oldFormattedOption?.note,
                    value: oldFormattedOption?.value || "",
                    object: oldFormattedOption?.object
                };
                const oldFormattedClasses = option.classes;
                let updatedFormattedClasses: formattedOption[] = [];
                if (oldFormattedClasses && oldFormattedClasses.length) {
                    for (var i = 0; i < oldFormattedClasses.length; i++) {
                        const oldFormattedClass = oldFormattedClasses[i];
                        const newFormattedOption: formattedOption = {
                            isChecked: divisionIsChecked,
                            isDisabled: oldFormattedClass.isDivisionEntryOnly ? true : divisionIsChecked,
                            isDivisionEntryOnly: oldFormattedClass?.isDivisionEntryOnly || false,
                            isClassEntryOnly: oldFormattedClass?.isClassEntryOnly || false, 
                            entryFee: oldFormattedClass?.entryFee || "",
                            name: oldFormattedClass?.name || "",
                            number: oldFormattedClass?.number ? oldFormattedClass?.number.toString() : "",
                            classType: oldFormattedClass?.classType,
                            note: oldFormattedClass?.note,
                            value: oldFormattedClass?.value || "",
                            object: oldFormattedClass?.object
                        };
                        updatedFormattedClasses.push(newFormattedOption);
                    }
                }
                updatedOptionGroup = {
                    division: newFormattedOption,
                    class: option.class,
                    classes: updatedFormattedClasses
                };
                indexInFormattedOptionGroups = formattedOptionGroups.findIndex(element => element.division?.value === option.division?.value);
            } else if (type === "divisionClass") {
                // Check a single class in a division
                const oldFormattedClasses = option.classes;
                if (oldFormattedClasses) {
                    const oldFormattedOption = oldFormattedClasses[classIndex || 0];
                    const newFormattedOption: formattedOption = {
                        isChecked: !oldFormattedOption?.isChecked,
                        isDisabled: oldFormattedOption?.isDisabled || false,
                        isDivisionEntryOnly: oldFormattedOption?.isDivisionEntryOnly || false,
                        isClassEntryOnly: oldFormattedOption?.isClassEntryOnly || false, 
                        entryFee: oldFormattedOption?.entryFee || "",
                        name: oldFormattedOption?.name || "",
                        number: oldFormattedOption?.number ? oldFormattedOption?.number.toString() : "",
                        classType: oldFormattedOption?.classType,
                        note: oldFormattedOption?.note,
                        value: oldFormattedOption?.value || "",
                        object: oldFormattedOption?.object
                    };
                    const newFormattedClasses = [
                        ...oldFormattedClasses.slice(0, classIndex),
                        newFormattedOption,
                        ...oldFormattedClasses.slice((classIndex || 0) + 1)
                    ];
                    updatedOptionGroup = {
                        division: option.division,
                        class: option.class,
                        classes: newFormattedClasses
                    };
                    for (let i = 0; i < formattedOptionGroups.length; i++) {
                        const current = formattedOptionGroups[i];
                        if (current.classes) {
                            if (indexInFormattedOptionGroups > -1) break;
                            for (let j = 0; j < current.classes.length; j++) {
                                const currentClass = current.classes[j];
                                if (currentClass.value === oldFormattedOption.value) {
                                    indexInFormattedOptionGroups = i;
                                    break;
                                }
                            }
                        }
                    }
                }
            } else {
                // Check a stand-alone class
                const oldFormattedOption = option.class;
                const newFormattedOption: formattedOption = {
                    isChecked: !oldFormattedOption?.isChecked,
                    isDisabled: oldFormattedOption?.isDisabled || false,
                    isDivisionEntryOnly: oldFormattedOption?.isDivisionEntryOnly || false,
                    isClassEntryOnly: oldFormattedOption?.isClassEntryOnly || false, 
                    entryFee: oldFormattedOption?.entryFee || "",
                    name: oldFormattedOption?.name || "",
                    number: oldFormattedOption?.number ? oldFormattedOption?.number.toString() : "",
                    classType: oldFormattedOption?.classType,
                    note: oldFormattedOption?.note,
                    value: oldFormattedOption?.value || "",
                    object: oldFormattedOption?.object
                };
                updatedOptionGroup = {
                    division: option.division,
                    class: newFormattedOption,
                    classes: option.classes
                };
                indexInFormattedOptionGroups = formattedOptionGroups.findIndex(element => element.class?.value === option.class?.value);
            }

            const newFormattedOptionGroups = [
                ...formattedOptionGroups.slice(0, indexInFormattedOptionGroups),
                updatedOptionGroup,
                ...formattedOptionGroups.slice(indexInFormattedOptionGroups + 1)
            ];
            setFormattedOptionGroups(newFormattedOptionGroups);
            
            if (filteredOptionGroups) {
                const newFilteredOptionGroups = [
                    ...filteredOptionGroups.slice(0, index),
                    updatedOptionGroup,
                    ...filteredOptionGroups.slice(index + 1)
                ];
                setFilteredOptionGroups(newFilteredOptionGroups);
            }

            let classList: EventClass[] = [];
            let divisionList: EventDivision[] = [];
    
            if (newFormattedOptionGroups && newFormattedOptionGroups.length) {
                for (let i = 0; i < newFormattedOptionGroups.length; i++) {
                    const current = newFormattedOptionGroups[i];
                    if (current.division && current.division.isChecked) {
                        const divisionObject: EventDivision = current.division.object;
                        divisionList.push(divisionObject);
                    } 
                    if (current.class && current.class.isChecked) {
                        const classObject: EventClass = current.class.object;
                        classList.push(classObject);
                    } 
                    if (current.classes) {
                        for (let j = 0; j < current.classes.length; j++) {
                            const currentClass = current.classes[j];
                            if (currentClass.isChecked) {
                                classList.push(currentClass.object);
                            }
                        }
                    }
                }
            }
            setSelectedEventClasses(classList);
            setSelectedDivisions(divisionList);

            console.log("newFormattedOptionGroups: ", newFormattedOptionGroups);

            onSelect(newFormattedOptionGroups);
            setIsLoading(false);
        }
    }

    const handleSaveChecklistClasses = async () => {
        // List of classes that were selected previously, that are not selected now, and should be removed
        let toBeRemoved: EventClassEntry[] = [];
        // List of classes that were selected previously, that are still selected now, and should not be touched
        let toBeKept: EventClassEntry[] = [];

        // Get the previous EventClassEntry objects for this entry
        let previousEventEntries = previousEventClassEntries;
        if (!previousEventEntries || previousEventEntries.length === 0) {
            const eventEntriesResult = await getEventClassEntriesByEventIdEntryId(eventId, entry.id);
            if (eventEntriesResult.isSuccess) {
                previousEventEntries = eventEntriesResult.result;
            }
        }

        // Get the lists of classes that need to be added or removed from this entry's EventClassEntry objects
        if (previousEventEntries) {
            toBeRemoved = previousEventEntries.filter((eventClassEntry: EventClassEntry) => {
                const foundIndex = selectedEventClasses?.findIndex((eventClass: EventClass) => eventClass.id === eventClassEntry.eventClass?.id);
                if (foundIndex !== undefined && foundIndex > -1) return false;
                else return true;
            });
    
            toBeKept = previousEventEntries.filter((eventClassEntry: EventClassEntry) => {
                const foundIndex = selectedEventClasses?.findIndex((eventClass: EventClass) => eventClass.id === eventClassEntry.eventClass?.id);
                if (foundIndex !== undefined && foundIndex > -1) return true;
                else return false;
            });
        }

        if (toBeRemoved && toBeRemoved.length) {
            for (let i = 0; i < toBeRemoved.length; i++) {
                const current: EventClassEntry = toBeRemoved[i];
                if (current) {
                    await deleteEventClassEntry({id: current.id});
                }
            }
        }

        // List of classes that were NOT selected previously, and should be added
        let toBeAdded: EventClass[] | undefined | null = selectedEventClasses?.filter((eventClass: EventClass) => {
            const foundIndex = toBeKept.findIndex((eventClassEntry: EventClassEntry) => eventClassEntry.eventClass?.id === eventClass.id);
            if (foundIndex !== undefined && foundIndex > -1) return false;
            else return true;
        });
        
        // Then add in the event entries from the selections
        if (toBeAdded && toBeAdded.length > 0) {
            for (let i = 0; i < toBeAdded.length; i++) {
                const current = toBeAdded[i];
                if (current && current.class) {
                    const eventEntryClassInput: CreateEventClassEntryInput = {
                        createdBy: user.id,
                        eventId: eventId,
                        eventClassId: current.id,
                        eventEntryId: entry.id,
                        status: (isQuickAdd ? "accepted" : (entry.status === "accepted" || entry.status === "complete") ? "accepted" : "submitted"),
                        riderId: entry.riderId,
                    };
                    const createResult = await createEventClassEntry(eventEntryClassInput);
                    if (!createResult.isSuccess) {
                        setError(createResult.message);
                    }
                }
            }
        }
    }

    const handleSaveChecklistDivisions = async () => {
        let newDivisionList: string[] = [];
        if (selectedDivisions && selectedDivisions.length > 0) {
            // Need to add unique divisionId to the entry's division list
            for (let i = 0; i < selectedDivisions.length; i++) {
                const current = selectedDivisions[i];
                if (!newDivisionList.includes(current.id)) newDivisionList.push(current.id);
            }
        }

        const updateInput: UpdateEventEntryInput = {
            id: entry.id,
            divisionList: newDivisionList
        };
        const updateEventEntryResult = await updateEventEntry(updateInput);
        if (updateEventEntryResult.isSuccess) {
            const updatedEntry = updateEventEntryResult.result;
            setCurrentEntry(updatedEntry);
            if (onUpdateEntry) onUpdateEntry(updatedEntry);
        } else {
            setError(updateEventEntryResult.message);
        }
    }

    const handleSaveChecklist = async () => {
        setIsLoading(true);

        await handleSaveChecklistClasses();

        await handleSaveChecklistDivisions();

        setIsLoading(false);
    }

    return (
        <>
            {isLoading ?
                <Spinner />
                :
                <>
                    {error && <ErrorAlert width="12" error={error} />}
                    {/* First Save Button */}
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="text-center">
                            <IonButton color="success" onClick={() => handleSaveChecklist()}>
                                Save Selections
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    {/* Search Section */}
                    <IonRow>
                        <IonCol sizeXs="12" sizeMd="6">
                            <IonSearchbar
                                color="white"  
                                placeholder="Search by #" 
                                value={searchNumberText} 
                                onIonChange={e => {
                                    setSearchNumberText(e.detail.value!)
                                    handleSearchInput(e.detail.value!, "number")
                                }}
                            />
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="6">
                            <IonSearchbar
                                color="white"  
                                placeholder="Search by Name" 
                                value={searchNameText} 
                                onIonChange={e => {
                                    setSearchNameText(e.detail.value!)
                                    handleSearchInput(e.detail.value!, "name")
                                }}
                            />
                        </IonCol>
                    </IonRow> 
                    {filteredOptionGroups ?
                        <IonList className="bg-white">
                            {filteredOptionGroups.map((option, index) => (
                                <div key={index}>
                                    {option.division && (
                                        <>
                                            <IonItem>
                                                <IonLabel className="ion-text-wrap">
                                                    <IonRow className="ion-align-items-center" onClick={() => handleOnChange(index, "division", option)}>
                                                        <IonCol sizeXs="2" sizeMd="auto">
                                                            <IonCheckbox disabled={option.division.isDisabled} value={option.division.value} checked={option.division.isChecked} />
                                                        </IonCol>
                                                        <IonCol sizeXs="10" sizeMd="11">
                                                            <IonRow>
                                                                <IonCol sizeXs="12" sizeMd="10">
                                                                <p className="text-primary ion-text-wrap">Division: {option.division.number ? (option.division.number + " - ") : ""}{option.division.name}</p>
                                                            </IonCol>
                                                            <IonCol sizeXs="12" sizeMd="2">
                                                                <p className="text-primary ion-text-wrap">{option.division.entryFee ? "$" + option.division.entryFee : ""} </p>
                                                            </IonCol>
                                                            <IonCol sizeXs="12" sizeMd="12">
                                                                <p className="description text-primary ion-text-wrap">{option.division.note}</p>
                                                            </IonCol>
                                                            </IonRow>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonLabel>
                                            </IonItem>
                                            {(option.classes) && (
                                                <>
                                                {option.classes.map((classOption, i) => (
                                                    <IonItem key={i}>
                                                            <IonLabel className="ion-text-wrap">
                                                                <IonRow className="ion-align-items-center" onClick={() => handleOnChange(index, "divisionClass", option, i)}>
                                                                    <IonCol size="auto" offset="1">
                                                                        <IonCheckbox disabled={classOption.isDisabled} value={classOption.value} checked={classOption.isChecked} />
                                                                    </IonCol>
                                                                    <IonCol sizeXs="9" sizeMd="10">
                                                                        <IonRow>
                                                                            <IonCol sizeXs="12" sizeMd="6">
                                                                                <p className="text-primary ion-text-wrap">Class: {classOption.number ? (classOption.number + " - ") : ""}{classOption.name}</p>
                                                                            </IonCol>
                                                                            <IonCol sizeXs="12" sizeMd="4">
                                                                                <p className="text-primary ion-text-wrap">{classOption.classType}</p>
                                                                            </IonCol>
                                                                            <IonCol sizeXs="12" sizeMd="2">
                                                                                <p className="text-primary ion-text-wrap">{classOption.entryFee ? "$" + classOption.entryFee : ""} </p>
                                                                            </IonCol>
                                                                        </IonRow>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonLabel>
                                                    </IonItem>
                                                ))}
                                                </>
                                            )}
                                        </>
                                    )}
                                    {option.class && (
                                        <IonItem>
                                            <IonLabel className="ion-text-wrap">
                                                <IonRow className="ion-align-items-center" onClick={() => handleOnChange(index, "class", option)}>
                                                    <IonCol sizeXs="2" sizeMd="auto">
                                                        <IonCheckbox disabled={option.class.isDisabled} value={option.class.value} checked={option.class.isChecked} />
                                                    </IonCol>
                                                    <IonCol sizeXs="10" sizeMd="11">
                                                        <IonRow>
                                                            <IonCol sizeXs="12" sizeMd="6">
                                                                <p className="text-primary ion-text-wrap">Class: {option.class?.number ? (option.class?.number + " - ") : ""}{option.class?.name}</p>
                                                            </IonCol>
                                                            <IonCol sizeXs="12" sizeMd="4">
                                                                <p className="text-primary ion-text-wrap">{option.class?.classType}</p>
                                                            </IonCol>
                                                            <IonCol sizeXs="12" sizeMd="2">
                                                                <p className="text-primary ion-text-wrap">{option.class.entryFee ? "$" + option.class.entryFee : ""} </p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCol>
                                                </IonRow>
                                            </IonLabel>
                                        </IonItem>
                                    )}
                                </div>
                            ))}
                        </IonList>
                        :
                        <>
                            <p>Loading options...</p>
                            <Spinner />
                        </>
                    }
                    {/* Second Save Button */}
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="text-center">
                            <IonButton color="success" onClick={() => handleSaveChecklist()}>
                                Save Selections
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
            }
        </>
    );
};

export default EventClassCheckList;
