import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol, 
    IonContent, 
    IonHeader,  
    IonIcon,  
    IonItem,  
    IonLabel,  
    IonPage, 
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import WebNavbar from "../../components/Navbars/WebNavbar";
import Footer from "../../components/Footers/Footer";
import { chatboxOutline, ellipsisVerticalCircleOutline, phonePortraitOutline } from "ionicons/icons";
import EventAuditorSignUpButton from "../../components/EventAuditor/EventAuditorSignUpButton";
import { Event } from "../../models";
import { getEventById } from "../../utilities/events/Event";
import gitnb from "../../assets/img/partners/GITNB/GITNBLogo.png";

const GITNBPage: React.FC = () => {

    const [currentEvent, setCurrentEvent] = useState<Event | null | undefined>();

    const getGITNBEvent = async () => {
        const queryResult = await getEventById("ba9c9de6-bf1d-4e5e-8479-88a0e82074d2");
        if (queryResult.isSuccess) {
            setCurrentEvent(queryResult.result);
        }
    }

    useEffect(() => {
        getGITNBEvent();
    }, []);

    return (
        <IonPage id="webGITNBPage" className="bg-light">
            <IonHeader>
                <WebNavbar />
            </IonHeader>
            <IonContent>
                {/* <IonRow className="ion-justify-content-center ion-padding-top">
                    <IonCol size="12" className="ion-text-center">
                        <h1 className="text-success">Green Is the New Blue</h1>
                    </IonCol>
                </IonRow> */}
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeXs="12" sizeMd="6">
                        <IonCard mode="md" className="ion-padding bg-white stretch">
                            <IonCardTitle>
                                <IonItem lines="none">
                                    <IonIcon icon={phonePortraitOutline} slot="start"/>
                                    <IonLabel> Text Alerts</IonLabel>
                                </IonItem>
                            </IonCardTitle>
                            <IonCardContent>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol sizeXs="6" sizeSm="8" sizeMd="6" className="ion-text-center">
                                        <img
                                            className="img"
                                            alt="Green Is the New Blue"
                                            src={gitnb}
                                        />
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center pt-3">
                                    <IonCol className="ion-text-center font-weight-bold">
                                        <h2>Click below to receive monthly Green ProTips, tailored especially for eco-minded equestrians.</h2>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center">
                                    {currentEvent && <EventAuditorSignUpButton event={currentEvent} />}
                                </IonRow>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <section className="py-5" id="aboutInfo">
                    <IonRow className="pb-5">
                        <IonCol sizeSm="12" sizeMd="6">
                            <IonCard mode="md" className="ion-padding bg-white stretch">
                                <IonCardTitle>
                                    <IonItem lines="none">
                                        <IonIcon icon={ellipsisVerticalCircleOutline} slot="start"/>
                                        <IonLabel> About</IonLabel>
                                    </IonItem>
                                </IonCardTitle>
                                <IonCardContent>
                                    <IonRow>
                                        <IonCol>
                                            <p>
                                                Founded in 2018 by amateur hunter rider Stephanie Riggio Bulger, Green Is the New Blue is a nonprofit organization dedicated to reducing the environmental impacts of equine-related events and activities. Learn more about Green Is the New Blue at <a className="link" href="https://www.greenisthenewblue.org/" target="_blank" rel="noopener noreferrer">www.greenisthenewblue.org</a>.
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol sizeSm="12" sizeMd="6">
                            <IonCard mode="md" className="ion-padding bg-white stretch">
                                <IonCardTitle>
                                    <IonItem lines="none">
                                        <IonIcon icon={chatboxOutline} slot="start"/>
                                        <IonLabel> Alerts</IonLabel>
                                    </IonItem>
                                </IonCardTitle>
                                <IonCardContent>
                                    <IonRow>
                                        <IonCol>
                                            <p>
                                                Given a shared commitment to sustainability, RingSide Pro and Green Is the New Blue have partnered to produce <span className="text-success font-weight-bold">Green ProTips</span>, a monthly text subscription service delivering sustainability with an equestrian focus. 🌍
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </section>
            <Footer />
        </IonContent>
    </IonPage>
    );
};

export default GITNBPage;
