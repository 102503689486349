import { Address, Event, Organization } from "../../models";
import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ErrorAlert from "../Errors/ErrorAlert";
import SelectEventType from "./SelectEventType";
import SelectEventOptions from "./SelectEventOptions";
import Spinner from "../Spinners/Spinner";
import { PersonContext } from "../../context/PersonContext";

import { createEvent } from "../../utilities/events/Event";
import { createOnlineEntryCheckoutSession } from "../../utilities/stripe/Checkout";
import {onlineEntriesProductPrice} from "../../data/content/OnlineEntriesPrice";

// Use Stripe to send user to Checkout if using online entries
import { loadStripe } from "@stripe/stripe-js";
import { getOrganizationsByPersonId } from "../../utilities/organization/Organization";
import CreateEventAddFinancialModal from "./CreateEventAddFinancialModal";
import moment from "moment";
import StartToEndDatePicker from "../DatePicker/StartToEndDatePicker";
import { formatUSDCurrency } from "../../utilities/currency/Currency";
import { formatEventOptions } from "../../utilities/events/EventOptions";
import { useHistory } from "react-router";
import { formatPaymentOptions } from "../../utilities/events/PaymentOptions";
import { createEventDay } from "../../utilities/eventDay/EventDay";
import { createHorseShow } from "../../utilities/horseShow/HorseShow";
import SelectCountry from "../Address/SelectCountry";
import SelectDisciplines from "../Discipline/SelectDisciplines";
import { createAddress, updateAddress } from "../../utilities/address/Address";
import { createEventFilter } from "../../utilities/eventFilter/EventFilter";
import { CreateAddressInput, CreateEventDayInput, CreateEventFilterInput, CreateEventInput, CreateHorseShowInput, EventOptionsInput, PaymentOptionsInput, UpdateAddressInput } from "../../API";
import { formatEventDayInput } from "../../utilities/eventDay/FormatEventDay";
import SelectOrganization from "../Organization/SelectOrganization";
import { sendNewCreatedHorseShowEmail } from "../../utilities/emails/EventEmail";
import { TextAlertRoleFilter, TextAlertRolesFilters } from "../../utilities/roles/Roles";
import SelectState from "../Address/SelectState";
import SelectAddressByOrganization from "../Address/SelectAddressByOrganization";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe('pk_test_sXlren9AWlEtWHVJwhKW6LbK00Q06Uoo4o'); //replace with process.env.pk
const stripePromise = loadStripe("pk_test_sXlren9AWlEtWHVJwhKW6LbK00Q06Uoo4o");

interface _Props {
    event?: Event
    onSubmit: Function
}

const EventForm: React.FC<_Props> = ({event, onSubmit}) => {
    const history = useHistory();
    const user = useContext(PersonContext);

    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingOrg, setIsLoadingOrg] = useState(false);
    const [organization, setOrganization] = useState<Organization | null>(null);
    const [userOrganizations, setUserOrganizations] = useState<Organization[] | null>(null);
    const [name, setName] = useState<string>(event?.name || "");
    const [eventType, setEventType] = useState(""); //to do - if event is passed in, use current event value
    const [eventDiscipline, setEventDiscipline] = useState("");
    const [selectedAddress, setSelectedAddress] = useState<Address | undefined | null>();
    const [streetAddressLine1, setStreetAddressLine1] = useState<string>("");
    const [streetAddressLine2, setStreetAddressLine2] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [provState, setProvState] = useState<string>("");
    const [zip, setZip] = useState<string>("");
    const [eventCountry, setEventCountry] = useState("");
    const [usefId, setUSEFId] = useState("");
    const [ecId, setECId] = useState("");
    const [ecPassword, setECPassword] = useState("");
    const [startDate, setStartDate] = useState(moment(new Date).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment(new Date).format("YYYY-MM-DD"));
    const [hasTextAlerts, setHasTextAlerts] = useState(true);
    const [hasOnlineEntries, setHasOnlineEntries] = useState(true);
    const [hasChecks, setHasChecks] = useState(true);
    const [hasCC, setHasCC] = useState(true);
    const [onlineEntriesPrice, setOnlineEntriesPrice] = useState("$35.00");
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState<string>("");
    

    useEffect(() => {
        async function getOrganizations() {
            setIsLoadingOrg(true);
            const queryResult = await getOrganizationsByPersonId(user.id);
            if (queryResult.isSuccess) {
                setUserOrganizations(queryResult.result);
                setOrganization(queryResult.result[0]); //To Do: make alphabetical or chronological?
            }
            setIsLoadingOrg(false);
        }

        getOrganizations();
    }, [user]);

    const handleOrganizationSelection = (selectedOrganization: Organization) => {
        setOrganization(selectedOrganization);
    }

    const handleEventTypeChange = (value: string) => {
        setIsDisabled(false);
        setEventType(value);
    }

    const handleEventOptionsChange = (hasTextAlerts: boolean, hasOnlineEntries: boolean, hasChecks: boolean, hasCC: boolean) => {
        setIsDisabled(false);
        setHasTextAlerts(hasTextAlerts);
        setHasOnlineEntries(hasOnlineEntries);
        setHasChecks(hasChecks);
        setHasCC(hasCC);
    }

    const handleEventDisciplineChange = (value: string[]) => {
        setIsDisabled(false);
        if (value) {
            let result = "";
            for (var i = 0; i < value.length; i++) {
                result = result + value[i] + ";";
            }
            setEventDiscipline(result);
        }
    }

    const handleAddressInputChange = (value: Address) => {
        setIsDisabled(false);
        if (value) {
            setSelectedAddress(value);
            if (value.streetAddress1) setStreetAddressLine1(value.streetAddress1);
            if (value.streetAddress2) setStreetAddressLine2(value.streetAddress2);
            if (value.city) setCity(value.city);
            if (value.provState) setProvState(value.provState);
            if (value.zip) setZip(value.zip);
            if (value.country) setEventCountry(value.country);
        } else {
            setSelectedAddress(undefined);
            setStreetAddressLine1("");
            setStreetAddressLine2("");
            setCity("");
            setProvState("");
            setZip("");
            setEventCountry("");
        }
    }
    
    const handleProvStateInputChange = (value: string) => {
        setIsDisabled(false);
        if (value && value !== "-") setProvState(value);
    }

    const handleCountryChange = (value: string) => {
        setIsDisabled(false);
        if (value) {
            setEventCountry(value);
        }
    }

    const handleDatesChange = (startDate: any, endDate: any) => {
        setIsDisabled(false);
        setStartDate(startDate);
        setEndDate(endDate);
        handleCalculateOnlineEntriesCost(startDate, endDate);
    }

    const handleCalculateOnlineEntriesCost = (startDate: string, endDate: string) => {
        // const numberDays = (moment(endDate).diff(moment(startDate), "days")) + 1;
        const numberDays = 1; //For now, keep the cost at a flat rate
        const rawPrice = numberDays * onlineEntriesProductPrice;       //TO DO: get price from Stripe?       
        const price = formatUSDCurrency(rawPrice); //TO DO: check currency type depending on location of org
        setOnlineEntriesPrice(price);
    }

    const validateForm = () => {
        if (!name) {
            setError("Please add a name for your event.");
            return false;
        }
        if (!eventType) {
            setError("Please add a type for your event.");
            return false;
        }
        if (!eventCountry) {
            setError("Please select a country for your event.");
            return false;
        }
        return true;
    }

    const updateEventAddress = async (id: string): Promise<Address | null | undefined> => {
        const addressName = name + " mailing address";
        const addressInput: UpdateAddressInput = {
            id: id,
            name: addressName,
            streetAddress1: streetAddressLine1,
            streetAddress2: streetAddressLine2,
            city: city,
            provState: provState,
            zip: zip,
            country: eventCountry,
            type: "mailing"
        };
        const updateAddressResult = await updateAddress(addressInput);
        if (updateAddressResult.isSuccess) {
            return updateAddressResult.result;
        } else {
            setError("Could not update the address info.");
        }
    }

    const createEventAddress = async (): Promise<Address | null | undefined> => {
        const addressName = name + "_mailing_address";
        const addressInput: CreateAddressInput = {
            name: addressName,
            streetAddress1: streetAddressLine1,
            streetAddress2: streetAddressLine2,
            city: city,
            provState: provState,
            zip: zip,
            country: eventCountry,
            type: "mailing"
        };
        const createAddressResult = await createAddress(addressInput);
        if (createAddressResult.isSuccess) {
            return createAddressResult.result;
        } else {
            setError("Could not create the contact info.");
        }
    }

    const handleCreateEvent = async () => {
        try {
            const isValid = validateForm();
            if (isValid) {
                // Create the event first
                if (organization) {
                    const eventOptions: EventOptionsInput = formatEventOptions(hasTextAlerts, hasOnlineEntries);
                    const paymentOptions: PaymentOptionsInput = formatPaymentOptions(hasChecks, false, false, hasCC);
                    let currentAddress = selectedAddress;
                    if (currentAddress) {
                        currentAddress = await updateEventAddress(currentAddress.id);
                    } else {
                        currentAddress = await createEventAddress();
                    }
                    const entriesStatus = hasOnlineEntries ? "will_accept" : "no_entries";
                    const eventInput: CreateEventInput = {
                        createdBy: user.id,
                        name: name,
                        organizationId: organization.id,
                        type: eventType,
                        disciplines: eventDiscipline,
                        eventOptions: eventOptions,
                        paymentOptions: paymentOptions,
                        eventAddressId: currentAddress?.id || "",
                        startDate: startDate,
                        endDate: endDate,
                        usefID: usefId,
                        ecID: ecId,
                        ecPassword: ecPassword,
                        status: "draft",
                        entriesStatus: entriesStatus,
                        showStatus: "scheduled"
                    };
                    const createResult = await createEvent(eventInput);
                    if (createResult.isSuccess) {
                        await handleCreateEventDays(createResult.result.id);

                        if (eventType === "horse_show") {
                            const input: CreateHorseShowInput = {
                                name,
                                eventId: createResult.result.id
                            };
                            await createHorseShow(input);
                        }

                        if (hasTextAlerts) {
                            // add a text alert filter for days of the week
                            await handleCreateEventFilterDays(createResult.result.id);
                            await handleCreateEventFilterRoles(createResult.result.id);
                        }
                        
                        if (hasOnlineEntries) {
                            // Needs to purchase online entry package
                            await handleCheckoutSession(createResult.result.id);
                        } else if (hasTextAlerts) {
                            // Option to purchase text alert credit blocks
                        } else {
                            // Just create the event
                        }

                        // Then send user to checkout form
                        if (hasCC) {
                            // If the organization is going to accept CC payments, it must have a Stripe Connect Account
                            const organizationHasSetUpConnectedAccount = !!organization?.stripeId;
                            if (organizationHasSetUpConnectedAccount) {
                                // Already set up their Express Account, can move on
                            } else {
                                setShowModal(true);
                            }
                        }

                        sendNewCreatedHorseShowEmail(name, user.email);
                        onSubmit(createResult.result);

                        // To Do: Only navigate after payment succeeds
                        navigateToShow(createResult.result.id);
                    } else {
                        setError(createResult.message);
                    }
                } else {
                    // Did not make the event because there was no organization to associate it with.
                }
            }
        } catch (error: any) {
            console.log("An error occurred");
        }
    }

    const handleCreateEventDays = async (eventId: string) => {
        // Going to create a EventDay record for each day
        const startDateMoment = moment(startDate);
        const endDateMoment = moment(endDate);

        // one day horse show
        if (startDateMoment === endDateMoment) {
            const input: CreateEventDayInput = formatEventDayInput(eventId, startDateMoment);
            const createResult = await createEventDay(input);
            if (!createResult) setError("Error: Could not create show day. Contact RingSide Pro at hello@ringsidepro.com");
        }

        // multi-day horse show
        let currentDate = startDateMoment;
        while (currentDate <= endDateMoment) {
            const input: CreateEventDayInput = formatEventDayInput(eventId, currentDate);
            const createResult = await createEventDay(input);
            if (!createResult) setError("Error: Could not create show day. Contact RingSide Pro at hello@ringsidepro.com");
            currentDate.add(1, "days");
        }
    }

    const handleCreateEventFilterDays = async (eventId: string) => {
        // Going to create a EventFilter record for each day
        const startDateMoment = moment(startDate);
        const endDateMoment = moment(endDate);

        // multi-day horse show
        if (startDateMoment.format("MM-DD-YYYY") !== endDateMoment.format("MM-DD-YYYY")) {
            let currentDate = startDateMoment;
            let options: string[] = [];
            while (currentDate <= endDateMoment) {
                options.push(currentDate.format("dddd"))
                currentDate.add(1, "days");
            }
            const input: CreateEventFilterInput = {
                eventId: eventId,
                name: "Days",
                options: options,
                isRequired: true
            };
            await createEventFilter(input);
        }
    }

    const handleCreateEventFilterRoles = async (eventId: string) => {
        const options: string[] = [];

        for (let i = 0; i < TextAlertRolesFilters.length; i++) {
            const current: TextAlertRoleFilter = TextAlertRolesFilters[i];
            // Example: If the current filter is Rider, only push the child options (Professional, Amateur or Junior)
            if (current.children) {
                current.children.forEach(child => {
                    options.push(child.value);
                });
            } else {
                options.push(current.value);
            }
        }

        const input: CreateEventFilterInput = {
            eventId: eventId,
            name: "Roles",
            options: options,
            isRequired: true
        };
        await createEventFilter(input);
    }

    const handleCheckoutSession = async (eventId: string) => {
        // Get Stripe.js instance
        const stripe = await stripePromise;

        // Get the number of days to charge the horse show for ... For now, keep it at a flat rate (1 day)
        let numberOfDays = 1;
        // const startDateMoment = moment(startDate);
        // const endDateMoment = moment(endDate);
        // if (startDateMoment.format("MM-DD-YYYY") !== endDateMoment.format("MM-DD-YYYY")) {
        //     let currentDate = startDateMoment;
        //     let options: string[] = [];
        //     while (currentDate <= endDateMoment) {
        //         numberOfDays = numberOfDays + 1;
        //         currentDate.add(1, "days");
        //     }
        // } else {
        //     numberOfDays = 1;
        // }

        // Call the backend to create the Checkout Session
        if (organization && organization.stripeCustomerId) {
            const sessionResult = await createOnlineEntryCheckoutSession(organization.stripeCustomerId, eventId, numberOfDays.toString());
            if (sessionResult.isSuccess) {
                const session = sessionResult.result;
                // const session = await result.json();

                // When the customer clicks on the button, redirect them to Checkout.
                if (stripe) {
                    const result = await stripe.redirectToCheckout({
                        sessionId: session.id,
                    });
                    if (result.error) {
                        // If `redirectToCheckout` fails due to a browser or network
                        // error, display the localized error message to your customer
                        // using `result.error.message`.
                        if (result.error.message) setError(result.error.message);
                    }
                }
            } else {
                console.log("Error: ", sessionResult)
                setError("Sorry, we could not perform a checkout out at this time.");
            }
        } else {
            console.log("Error: no organization or organization stripe customer id")
            setError("Sorry, we were not able to process a checkout for your organization.");
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        await handleCreateEvent();
        setIsDisabled(true);
        setIsLoading(false);
    }

    const navigateToShow = (eventId: string) => {
        const path = "/index/staff/event/" + eventId;
        history.push(path);
    };

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {isLoadingOrg ?
                <Spinner/>
                :   
                <form>
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeMd="10" className="text-center">
                        <IonText><h2>This event will belong to the organization: {organization?.name}</h2></IonText>
                        {(userOrganizations && userOrganizations?.length > 1) && (
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeXs="12" sizeSm="8">
                                    {organization && (
                                        <SelectOrganization currentOrganization={organization} organizations={userOrganizations} isClearable={false} onInputChange={handleOrganizationSelection} />
                                    )}
                                </IonCol>
                            </IonRow>
                        )}
                        </IonCol>
                    </IonRow>
                    <hr />
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeMd="10">
                            <h2>Basic Info</h2>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeMd="10">
                            <IonItem color="white">
                                <IonLabel position="stacked">Name</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={name}
                                    aria-required={true}
                                    onIonChange={e => {
                                        if(isMounted) setIsDisabled(false);
                                        else setIsMounted(true);
                                        setName(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top ion-justify-content-center">
                        <IonCol sizeMd="10">
                            <SelectEventType onInputChange={handleEventTypeChange} />
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeMd="10">
                            <IonLabel position="stacked">Discipline(s)</IonLabel>
                            <SelectDisciplines onSelect={handleEventDisciplineChange} />
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeMd="10">
                            <IonItem color="white">
                                <IonLabel position="stacked">USEF Competition Id</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={usefId}
                                    aria-required={true}
                                    onIonChange={e => {
                                        if(isMounted) setIsDisabled(false);
                                        else setIsMounted(true);
                                        setUSEFId(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeMd="5">
                            <IonItem color="white">
                                <IonLabel position="stacked">EC Competition Number</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={ecId}
                                    aria-required={true}
                                    onIonChange={e => {
                                        if(isMounted) setIsDisabled(false);
                                        else setIsMounted(true);
                                        setECId(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol sizeMd="5">
                            <IonItem color="white">
                                <IonLabel position="stacked">EC Competition Password</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={ecPassword}
                                    aria-required={true}
                                    onIonChange={e => {
                                        if(isMounted) setIsDisabled(false);
                                        else setIsMounted(true);
                                        setECPassword(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <hr />
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeMd="10">
                            <h2>Location</h2>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeXs="12" sizeMd="10">
                            {organization &&
                                <>
                                    <IonLabel>Option to select from past addresses:</IonLabel>
                                    <SelectAddressByOrganization organizationId={organization?.id} onSelect={handleAddressInputChange}/>
                                </>
                            }
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeXs="12" sizeMd="5">
                            <IonItem color="white">
                                <IonLabel position="stacked">Street Address Line 1</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={streetAddressLine1}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setStreetAddressLine1(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="5">
                            <IonItem color="white">
                                <IonLabel position="stacked">Street Address Line 2</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={streetAddressLine2}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setStreetAddressLine2(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeXs="12" sizeMd="6">
                            <IonItem color="white">
                                <IonLabel position="stacked">City</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={city}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setCity(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="4">
                            <SelectState selectedValue={provState} onInputChange={handleProvStateInputChange} />
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeXs="12" sizeMd="6">
                            <IonItem color="white">
                                <IonLabel position="stacked">Postal Code</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={zip}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setZip(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="4">
                            <SelectCountry countryValue={eventCountry} onInputChange={handleCountryChange} />
                        </IonCol>
                    </IonRow>
                    <hr />
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeMd="10">
                            <h2>Dates</h2>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeMd="10">
                            <StartToEndDatePicker onChange={handleDatesChange}/>
                        </IonCol>
                    </IonRow>
                    <hr />
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeMd="10">
                            <h2>Capabilities</h2>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeMd="10">
                            <SelectEventOptions onInputChange={handleEventOptionsChange} />
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="text-center" sizeMd="4">
                            {isLoading ?
                                <Spinner />
                                :
                                <IonButton
                                    disabled={isDisabled}
                                    className="ion-margin-top"
                                    color="tertiary"
                                    expand="block"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </IonButton>
                            }
                        </IonCol>
                    </IonRow>
                </form>
            }
            {organization &&
                <CreateEventAddFinancialModal organization={organization} isModalOpen={showModal} />
            }
        </>
    );
};

export default EventForm;